import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  makeStyles,
  Chip,
  DialogActions
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import LinkIcon from '@material-ui/icons/Link';
import CustomTextField from "../../../components/Common/CustomTextField";
import * as actions from "./actions";
import { toastSuccess } from "../../../utils";
import { useDispatch } from "react-redux";
import * as commonActions from "../../Common/actions";
import { vi } from "react-date-range/dist/locale";
import { BiUpload } from "react-icons/bi";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 12,
      minWidth: '400px',
    }
  },
  dialogTitle: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.white,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: theme.spacing(3),
  },
  linkList: {
    width: '100%',
    maxHeight: '400px',
    overflowY: 'auto',
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
  },
  linkItem: {
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    }
  }
}));
const AddUpdateComplaints = forwardRef((props, ref) => {
  const {
    setLoader = () => { },
    onCloseModal = () => { },
    getAllSavedResults = () => { },
    singleResults = {},
    isEdit = false,
    isAdminView = false,
  } = props;
  const [currentData, setCurrentData] = useState(isEdit ? singleResults : {});
  const {
    fullName = "",
    title = "",
    pageName = "",
    emailAddress = "",
    mobileNumber = "",
    mobileNumber2 = "",
    emailAddress2 = "",
    remarks = "",
    descriptions = "",
    current_status = "",
    images = ["-"]
  } = currentData || {};

  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(
    isEdit ? singleResults?.type : "Individual"
  );
  const [errorImages, setErrorImages] = useState([]);
  const fileInputRef = useRef(null);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const onOpenErrorAlert = (errors = []) => {
    dispatch(commonActions.openErrorAlert({ errorList: errors }));
  };

  useImperativeHandle(ref, () => ({
    async onSubmitQuery() {
      setLoader(true);

      // Create FormData for file upload
      const formData = new FormData();

      // Append all current data fields
      Object.keys(currentData).forEach(key => {
        formData.append(key, currentData[key] || '');
      });

      // Append error images
      errorImages.forEach((file, index) => {
        formData.append('files', file);
      });

      if (isEdit) {
        try {
          // Append ID for update
          let newQuery = {
            ...currentData,
            type: selectedValue,
            individual_gender: currentData?.individual_gender || "male",
          };

          actions
            .updateComplaints(formData)
            .then((res) => {
              toastSuccess("Details Updated successfully");
              onCloseModal();
              getAllSavedResults();
              setLoader(false);
            })
            .catch(() => {
              setLoader(false);
            });
        } catch (ex) {
          setLoader(false);
        }
        return;
      }

      actions
        .addComplaints(formData)
        .then((res) => {
          toastSuccess("Details added successfully");
          onCloseModal();
          getAllSavedResults();
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    },
  }));
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // Separate images and videos based on file extensions
  const mediaTypes = useMemo(() => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.bmp', '.tiff'];
    const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv', '.flv', '.webm', '.mkv'];

    const sortedMedia = {
      images: [],
      videos: []
    };

    images.forEach(url => {
      const extension = url.substring(url.lastIndexOf('.')).toLowerCase();

      if (imageExtensions.includes(extension)) {
        sortedMedia.images.push(url);
      } else if (videoExtensions.includes(extension)) {
        sortedMedia.videos.push(url);
      }
    });

    return sortedMedia;
  }, [images]);
  const onChangeHandler = (e) => {
    const { value = "", name = "" } = e.target || {};
    setCurrentData({ ...currentData, [name]: value });
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setErrorImages(prevImages => [...prevImages, ...files]);
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveImage = (item) => {
    if (typeof item === 'number') {
      setErrorImages((prevImages) => prevImages.filter((_, i) => i !== item));
    }
    // Check if the item is a URL (from mediaTypes.images)
    else if (typeof item === 'string') {
      // Remove from images in currentData
      const updatedImages = images.filter((url) => url !== item);

      setCurrentData((prevData) => ({
        ...prevData,
        images: updatedImages
      }));
    }
  };
  return (
    <>
      <Grid container className="add-order-container">
        <Grid item xs={12} className="lower-input-grid mt-4 cargo-details-wrap">
          <div className="input-div" style={{ width: "50%" }}>
            <div>
              {(!isAdminView) && <div>
                           <div className="upper-item-div" style={{
                             display: 'flex',
                             flexDirection: 'column',
                             alignItems: 'start',
                             gap: '10px',
                             backgroundColor: '# ',
                             padding: '15px',
                             borderRadius: '8px',
                             border: '1px solid #e0e0e0'
                           }}>
                             <div style={{
                               display: 'flex',
                               alignItems: 'center',
                               gap: '10px'
                             }}>
                               <button
                                 onClick={() => fileInputRef.current.click()}
                                 style={{
                                   padding: "8px 16px",
                                   background: "#3f51b5",
                                   color: "white",
                                   fontSize: "14px",
                                   border: "none",
                                   borderRadius: "4px",
                                   cursor: "pointer",
                                   display: 'flex',
                                   alignItems: 'center',
                                   gap: '5px',
                                   transition: 'background 0.3s ease'
                                 }}
                               >
                                <BiUpload/>
                                 Attachments
                               </button>
                               <input
                                 type="file"
                                 ref={fileInputRef}
                                 style={{ display: "none" }}
                                 multiple
                                 accept="image/*"
                                 onChange={handleFileSelect}
                               />
                               <span style={{
                                 color: '#666',
                                 fontSize: '12px'
                               }}>
                                 {errorImages.length} image(s) selected
                               </span>
                             </div>
               
                             {errorImages.length > 0 && (
                               <div style={{
                                 width: '100%',
                                 maxHeight: '200px',
                                 overflowY: 'auto',
                                 backgroundColor: 'white',
                                 border: '1px solid #e0e0e0',
                                 borderRadius: '4px',
                                 padding: '10px'
                               }}>
                                 {errorImages.map((file, index) => (
                                   <div
                                     key={index}
                                     style={{
                                       display: 'flex',
                                       justifyContent: 'space-between',
                                       alignItems: 'center',
                                       padding: '5px 10px',
                                       borderBottom: index < errorImages.length - 1 ? '1px solid #f0f0f0' : 'none'
                                     }}
                                   >
                                     <div style={{
                                       display: 'flex',
                                       alignItems: 'center',
                                       gap: '10px'
                                     }}>
                                       <i className="fas fa-file-image" style={{ color: '#4CAF50' }}></i>
                                       <span style={{
                                         fontSize: '13px',
                                         color: '#333'
                                       }}>
                                         {file.name}
                                       </span>
                                     </div>
                                     <button
                                       onClick={() => handleRemoveImage(index)}
                                       style={{
                                         color: '#f44336',
                                         background: 'none',
                                         border: 'none',
                                         cursor: 'pointer',
                                         display: 'flex',
                                         alignItems: 'center',
                                         gap: '5px',
                                         transition: 'color 0.3s ease'
                                       }}
                                       onMouseOver={(e) => e.target.style.color = '#d32f2f'}
                                       onMouseOut={(e) => e.target.style.color = '#f44336'}
                                     >
                                       <i className="fas fa-trash-alt"></i>
                                       Remove
                                     </button>
                                   </div>
                                 ))}
                               </div>
                             )}
                           </div>
              </div>}
              {(mediaTypes.images.length > 0 || mediaTypes.videos.length > 0 && current_status !== 'resolved') && (
                <Chip
                  icon={<LinkIcon />}
                  label={`Media: ${mediaTypes.images.length} Images, ${mediaTypes.videos.length} Videos`}
                  color="primary"
                  variant="outlined"
                  onClick={handleOpen}
                  className="cursor-pointer hover:bg-blue-50"
                />
              )}

              <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                className={classes.root}
              >
                <DialogTitle className={classes.dialogTitle}>
                  <div className="flex-grow">
                    Review Media
                  </div>
                  <IconButton
                    onClick={handleClose}
                    className={classes.closeButton}
                    style={{ position: 'absolute', right: 8, top: 8 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent dividers className={classes.dialogContent}>
                  {/* Images Section */}
                  {mediaTypes.images.length > 0 && (
                    <div className="mb-6">
                      <h3 className="text-lg font-semibold mb-4 w-max border-b pb-2">
                        Images ({mediaTypes.images.length})
                      </h3>
                      <div className="grid grid-cols-3 gap-4">
                        {mediaTypes.images.map((url, index) => (
                          <div
                            key={index}
                            className="relative shadow-md shadow-gray-700 hover:shadow-lg transition-shadow overflow-visible"
                          >
                            {(!isAdminView && current_status !== 'resolved') && (
                              <button
                                onClick={() => handleRemoveImage(url)}
                                className="absolute -top-2 -right-2 z-10 bg-rose-500 text-white rounded-full hover:bg-rose-600 transition-colors 
                                shadow-md hover:shadow-lg 
                                focus:outline-none focus:ring-2 focus:ring-rose-300"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              </button>
                            )}
                            <div
                              className="cursor-pointer"
                              onClick={() => handleOpenInNewTab(url)}
                            >
                              <img
                                src={url}
                                alt={`Image ${index + 1}`}
                                className="w-full h-[5rem] object-cover"
                              />
                              <div className="p-1 bg-gray-100 text-center">
                                <span className="text-sm truncate">
                                  Image {index + 1}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Videos Section */}
                  {mediaTypes.videos.length > 0 && (
                    <div>
                      <h3 className="text-lg font-semibold mb-4 w-max border-b pb-2">
                        Videos ({mediaTypes.videos.length})
                      </h3>
                      <div className={classes.linkList} style={{ overflow: "visible" }}>
                        {mediaTypes.videos.map((url, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between w-full p-3 border-b border-gray-200 hover:bg-gray-50 transition-colors duration-200 "
                          >
                            <div className="flex items-center space-x-3 relative">
                              {(!isAdminView && current_status !== 'resolved') && (
                                <button
                                  onClick={() => handleRemoveImage(url)}
                                  className="absolute -top-7 right-[-9.5rem] z-10 bg-rose-500 text-white rounded-full p-1 
                                hover:bg-rose-600 transition-colors 
                                  shadow-md hover:shadow-lg 
                                  focus:outline-none focus:ring-2 focus:ring-rose-300"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              )}
                              <div className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs font-semibold">
                                Video {index + 1}
                              </div>
                              <span
                                className="text-sm text-gray-700 max-w-[300px] truncate"
                                title={url}
                              >
                                {url.split('/').pop()}
                              </span>
                            </div>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              startIcon={<LaunchIcon />}
                              onClick={() => handleOpenInNewTab(url)}
                              className="ml-4"
                            >
                              Open Link
                            </Button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* No Media Message */}
                  {mediaTypes.images.length === 0 && mediaTypes.videos.length === 0 && (
                    <div className="text-center text-gray-500 py-4">
                      No media available
                    </div>
                  )}
                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className="upper-item-div pt-4">
              <p>Full Name*</p>
              <div className="inputs" style={{ width: "100%" }}>
                <CustomTextField
                  size="small"
                  placeholder="Enter Full Name"
                  value={fullName}
                  name="fullName"
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            <div className="upper-item-div">
              <p>Title</p>
              <div className="inputs" style={{ width: "100%" }}>
                <CustomTextField
                  size="small"
                  placeholder="Enter Error Title"
                  value={title}
                  name="title"
                  onChange={onChangeHandler}
                />
              </div>
            </div>

            <div className="upper-item-div">
              <p>Page Name </p>
              <div className="inputs" style={{ width: "100%" }}>
                <CustomTextField
                  size="small"
                  placeholder="Enter Page Name"
                  value={pageName}
                  name="pageName"
                  onChange={onChangeHandler}
                />
              </div>
            </div>

            <div className="upper-item-div">
              <p>Mobile No (official)</p>
              <div className="inputs" style={{ width: "100%" }}>
                <CustomTextField
                  size="small"
                  placeholder="Enter Mobile Number"
                  value={mobileNumber}
                  name="mobileNumber"
                  onChange={onChangeHandler}
                />
              </div>
            </div>

            <div className="upper-item-div">
              <p>Mobile No (official)2</p>
              <div className="inputs" style={{ width: "100%" }}>
                <CustomTextField
                  size="small"
                  placeholder="Enter Mobile No (official)"
                  value={mobileNumber2}
                  name="mobileNumber2"
                  onChange={onChangeHandler}
                />
              </div>
            </div>

            <div className="textarea-div upper-item-div">
              <p>Notes/Remarks:</p>
              <textarea
                className="add-order-textarea"
                style={{ minWidth: "auto" }}
                placeholder="Please enter your remarks here...."
                value={remarks}
                name="remarks"
                onChange={onChangeHandler}
              />
            </div>
            {isAdminView && (
              <div className="upper-div upper-item-div">
                <p>Status</p>
                <Select
                  size="small"
                  variant="outlined"
                  name="current_status"
                  fullWidth
                  className="custom-select"
                  value={current_status || ""}
                  onChange={onChangeHandler} // Pass the selected value
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="in-progress">In Progress</MenuItem>
                  <MenuItem value="resolved">Resolved</MenuItem>
                </Select>
              </div>
            )}
          </div>
          <div className="input-div" style={{ width: "50%" }}>
            <div className="upper-item-div pt-4">
              <p>E-mail (official)*</p>
              <div className="inputs" style={{ width: "100%" }}>
                <CustomTextField
                  size="small"
                  placeholder="Enter e-mail (official)"
                  value={emailAddress}
                  name="emailAddress"
                  onChange={onChangeHandler}
                />
              </div>
            </div>

            <div className="upper-item-div">
              <p>E-mail (official)2</p>
              <div className="inputs" style={{ width: "100%" }}>
                <CustomTextField
                  size="small"
                  placeholder="Enter e-mail (official)2"
                  value={emailAddress2}
                  name="emailAddress2"
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            <p style={{ fontSize: "14px" }}>Add Descriptions</p>
            <div className="textarea-div upper-item-div">
              <textarea
                className="add-order-textarea"
                style={{ minWidth: "auto", minHeight: "250px" }}
                placeholder="Please enter error Descriptions here...."
                value={descriptions}
                name="descriptions"
                onChange={onChangeHandler}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
});

export default AddUpdateComplaints;
