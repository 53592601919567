import React from "react";

const Logo = (props) => (
        <svg
            x="0"
            y="0"
            width="60"
            height="55.29648130057325"
            className={props.className +" image-svg-svg primary"}
            style={{ overflow: "visible" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-0.00011817641643574461 0.04781933128833771 153.13011169433594 141.12530517578125"
        >
            <path
                d="M84.88 23.92L29.75 79.4 1.84 107.49A22 22 0 0 0 0 116.37c0 13.52 12.4 24.51 27.81 24.8h66.56a28.58 28.58 0 0 0 29-28.21V35.21A27.34 27.34 0 0 0 99 19.89a26.71 26.71 0 0 0-14.12 4.03z"
                fill="#000000"
                opacity=".8"
                style={{ isolation: "isolate" }}
            />
            <path
                d="M29.8 28.22V106a27.35 27.35 0 0 0 24.38 15.32 26.8 26.8 0 0 0 14.09-4l55.12-55.48 27.9-28.16a22.23 22.23 0 0 0 1.84-8.84c0-13.5-12.39-24.5-27.79-24.79h-66.6A28.59 28.59 0 0 0 29.8 28.22z"
                fill="#3F51B5"
                opacity=".8"
                style={{ isolation: "isolate" }}
            />
            <path
                d="M68.27 117.27l55.12-55.48V35.21A27.34 27.34 0 0 0 99 19.89a26.64 26.64 0 0 0-14.07 4l-55.12 55.5V106a27.38 27.38 0 0 0 24.37 15.32 26.8 26.8 0 0 0 14.09-4.05z"
                fill="#3F51B5"
                opacity=".5"
                style={{ isolation: "isolate" }}
            />
        </svg>
);

export default Logo;
