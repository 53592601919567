import { Paper } from '@material-ui/core'
import React from 'react'

function CustomPagination(props) {
    const {
        onChangePagination = () => { },
        filters = {},
        totalPage = ""
    } = props
    const vesselShowing = <p>Showing <span>{(((filters.offset) - 1) * filters.limit) || 1}</span>-<span>{(filters.offset || 1) * filters.limit}</span> of <span>{
        // (filters.offset + 1 || 1) * filters.limit
        totalPage
    }</span></p>

    return (
        <div className="pagination-button-div">
            {vesselShowing}
            {filters.offset !== 1 ? <Paper onClick={() => onChangePagination('first', 'vessel')}>{'<<'}</Paper> : null}
            <Paper onClick={() => onChangePagination('dec', 'vessel')}>Prev</Paper>
            <Paper onClick={() => (((filters.offset || 1) * filters.limit) < totalPage) && onChangePagination('inc', 'vessel')}>Next</Paper>
        </div>
    )
}

export default CustomPagination
