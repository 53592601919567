import React, { useState, useRef, useMemo, useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import { accountCargoFields } from "../../constants";
import moment from "moment";
import CustomAddModal from "../Common/CustomAddModal";
import AddNewOrder from "../../containers/User/Orders/AddNewOrder";
import * as calculatorActions from "../../containers/User/Calculator/actions";
import { capitalizeFirstLetter } from "../../utils";
import { useParams } from "react-router-dom";

function AccountCargoDetails(props) {
  const {
    calculatorData = {},
    setLoader = () => { },
    getInitalQueryAndFleetData = () => { },
    isFromSingleVesselCalcy = false,
    getInitialData = () => { },
    isFromCargoAnalysis
  } = props;

  const { portOperation = [], clientQuery = {} } = calculatorData || {};
  const { freightMode } = clientQuery;

  const [open, setOpen] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState({});
  const [selectedPorts, setSelectedPorts] = useState([]);
  const [selectedPortsCommence, setSelectedPortsCommence] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const addQueryModalRef = useRef();
  const { id = "" } = useParams();
  useEffect(() => {
    setSelectedQuery(clientQuery);
    setSelectedPorts(
      portOperation.filter(
        (item) =>
          item.operation === "Loading" || item.operation === "Discharging"
      )
    );
  }, [calculatorData]);

  const onCloseModal = () => {
    setOpen(false);
    setIsEdit(false);
    setSelectedQuery({});
    setSelectedPorts([]);
  };

  const singleLoadingCargo = useMemo(() => {
    let singleLoadingCargo = {};
    let mixAdcom = "";
    let mixBrokerage = "";
    let mixOther = "";
    let mixTtlComm = "";
    let mixNetFrt = "";
    let mixStowFactor = "";
    if (Array.isArray(portOperation) && portOperation.length) {
      for (let i = 0; i < portOperation.length; i++) {
        if (portOperation[i].operation === "Loading") {
          // console.log(portOperation[i].others, "portOperation[i].other")
          if (!Object.keys(singleLoadingCargo).length)
            singleLoadingCargo = portOperation[i];
          mixAdcom =
            mixAdcom + (mixAdcom ? "/" : "") + portOperation[i].adcom || "0";
          mixBrokerage =
            mixBrokerage +
            (mixBrokerage ? "/" : "") +
            portOperation[i].brokerage || "0";
          mixOther =
            mixOther + (mixOther ? "/" : "") + portOperation[i].other || "0";
          mixTtlComm =
            mixTtlComm + (mixTtlComm ? "/" : "") + portOperation[i].ttlComm ||
            "0";
          mixNetFrt =
            mixNetFrt + (mixNetFrt ? "/" : "") + portOperation[i].netFrt || "0";
          mixStowFactor =
            mixStowFactor +
            (mixStowFactor ? "/" : "") +
            portOperation[i].stowFactor || "0";
        }
      }
    }
    return {
      ...singleLoadingCargo,
      mixAdcom,
      mixBrokerage,
      mixOther,
      mixTtlComm,
      mixNetFrt,
      mixStowFactor,
    };
  }, [portOperation]);

  const onClickEditQuery = (query) => {
    // if(isFromSingleVesselCalcy){
    //   return
    // }
    setLoader(true);
    setSelectedQuery(query);
    if (!isFromSingleVesselCalcy) {
      calculatorActions
        .cargoByQuery(query._id)
        .then((res) => {
          setLoader(false);
          setSelectedPorts(
            res.data.cargoList
              .filter((item) => item.operation !== "Commence")
          );
          setSelectedPortsCommence(
            res.data.cargoList
              .filter((item) => item.operation === "Commence")
          );
          setOpen(true);
          setIsEdit(true);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      calculatorActions
        .saveResultById(id)
        .then((res) => {
          setLoader(false);
          setSelectedPorts(
            res.data?.clientQuery?.cargoList
              .filter((item) => item.operation !== "Commence")
          );
          setSelectedPortsCommence(
            res.data?.clientQuery?.cargoList
              .filter((item) => item.operation === "Commence")
          );
          setOpen(true);
          setIsEdit(true);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const onSubmitConfirm = () => {
    if (addQueryModalRef.current.onSubmitQuery) {
      addQueryModalRef.current.onSubmitQuery();
      getInitalQueryAndFleetData();
    }
  };

  const onClickCreateCopy = () => {
    if (addQueryModalRef.current.onSubmitQuery) {
      addQueryModalRef.current.onSubmitQuery(true);
      getInitalQueryAndFleetData();
    }
  };

  return (
    <Grid container id="cargo-details">
      <Grid item xs={12} className="calculator-section-header">
        <h4
          className="result-heading cursor-pointer underline"
          onClick={() => onClickEditQuery(clientQuery)}
        >
          Cargo Details
        </h4>
      </Grid>
      <Grid item xs={12}>
        <div>
          <table className="fleet-result-table">
            {accountCargoFields.map((item, key) => {
              return (
                <tr key={key}>
                  <th>{item.label}</th>
                  {item.value === "adcom" ||
                    item.value === "brokerage" ||
                    item.value === "other" ||
                    item.value === "ttlComm" ||
                    item.value === "netFrt" ||
                    item.value === "stowFactor" ? (
                    <td>
                      {
                        singleLoadingCargo[
                        `mix${capitalizeFirstLetter(item.value)}`
                        ]
                      }
                    </td>
                  ) : item.value === "laycanStartDate" ||
                    item.value === "laycanEndDate" ? (
                    // <td>{singleLoadingCargo[item.value] ? moment(singleLoadingCargo[item.value]).format('DD-MM-yyyy HH:MM') : ''}</td>
                    <td>
                      {clientQuery[item.value]
                        ? moment(clientQuery[item.value]).format(
                          "DD/MMM/yyyy HH:MM"
                        )
                        : ""}
                    </td>
                  ) : item.value === "freightMode" ? (
                    <td>{freightMode}</td>
                  ) : (
                    <td>{clientQuery?.[item?.value]?.name || "-"}</td>
                  )}
                </tr>
              );
            })}
          </table>
        </div>
      </Grid>
      <CustomAddModal
        open={open}
        title={isEdit ? "Update Cargo" : "New Cargo"}
        maxWidth="xl"
        saveLabel={isEdit ? "Update" : "Create"}
        addThirdBtn={isFromCargoAnalysis ? false : isEdit}
        thirdBtnAction={onClickCreateCopy}
        thirdBtnLabel="Create New Order"
        handleClose={onCloseModal}
        onSubmitConfirm={onSubmitConfirm}
        isDetailView={isFromCargoAnalysis}
      >
        <AddNewOrder
          isFromSingleVesselCalcy={isFromSingleVesselCalcy}
          ref={addQueryModalRef}
          setLoader={setLoader}
          onCloseModal={onCloseModal}
          selectedQuery={selectedQuery}
          selectedPorts={selectedPorts}
          selectedPortsCommence={selectedPortsCommence}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          getInitialData={getInitialData}
        />
      </CustomAddModal>
    </Grid>
  );
}

export default AccountCargoDetails;
