import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Zoom } from "@material-ui/core";
import { MdClose } from "react-icons/md";

export default function CustomAddModal(props) {
  const {
    handleClose,
    title,
    onSubmitConfirm,
    open,
    saveLabel = "Add",
    maxWidth = "sm",
    addThirdBtn = false,
    thirdBtnAction = () => {},
    thirdBtnLabel = "",
    isDetailView = false,
  } = props;
  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth={maxWidth}
        TransitionComponent={Zoom}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="flex items-center justify-between w-full px-6 py-4 border-b border-solid border-neutrals6">
          <div
            id="alert-dialog-title"
            className="font-family text-blue typo-18-600 lg:typo-22-600"
          >
            {isDetailView ? "Details" : title}
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer text-neutrals4 px-[6px] py-[8px] rounded-lg border border-solid border-neutrals6"
            onClick={handleClose}
          >
            <MdClose className="text-blue" />
            <div className="hidden md:block typo-12-400">Close</div>
          </div>
        </div>
        <DialogContent className="relative">
          {/* <DialogContentText id="alert-dialog-description" className="font-family"> */}
          {props.children}
          {/* </DialogContentText> */}
        </DialogContent>
        <div className="flex items-center justify-end gap-4 w-full px-6 py-3 border-t border-solid border-neutrals6">
          {(isDetailView || !saveLabel) ? null : (
            <Button
              onClick={onSubmitConfirm}
              color="primary"
              variant="outlined"
              className="font-family  px-8 rounded-md"
            >
              {saveLabel}
            </Button>
          )}
          {addThirdBtn ? (
            <Button
              onClick={thirdBtnAction}
              color="primary"
              variant="outlined"
              className="font-family"
            >
              {thirdBtnLabel}
            </Button>
          ) : null}
        </div>
      </Dialog>
    </div>
  );
}
