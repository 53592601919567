import * as actionTypes from "./actionsTypes";

const initialState = {
  userOrganizaton: [],
  allRoles: [],
  allVessels: [],
  allOrganization: [],
  allFleetByOrg: [],
  userOrganizatonLoading: false,
  allAdminUsers: [],
  allAdminUsersLoading: false,
};

export default function dashboard(state = { ...initialState }, actions) {
  switch (actions.type) {
    case actionTypes.USERS_ORGANIZATION:
      return {
        ...state,
        userOrganizatonLoading: true,
      };
    case actionTypes.USERS_ORGANIZATION_SUCCESS:
      return {
        ...state,
        userOrganizaton: actions.payload.data.list,
        userOrganizatonLoading: false,
      };
    case actionTypes.USERS_ORGANIZATION_FAILED:
      return {
        ...state,
        userOrganizatonLoading: false,
        userOrganizaton: actions.payload,
      };
    case actionTypes.ALL_ADMIN_USERS:
      return {
        ...state,
        allAdminUsersLoading: true,
      };
    case actionTypes.ALL_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        allAdminUsers: actions.payload.data.list,
        allAdminUsersLoading: false,
      };
    case actionTypes.ALL_ADMIN_USERS_FAILED:
      return {
        ...state,
        allAdminUsersLoading: false,
      };
    case actionTypes.All_ROLES_SUCCESS:
      return {
        ...state,
        allRoles: actions.payload.data.list,
      };
    case actionTypes.ALL_VESSELS_SUCCESS:
      return {
        ...state,
        allVessels: actions.payload.data.list,
      };
    case actionTypes.GET_ALL_ORGANIZATION_SUCCESS:
      return {
        ...state,
        allOrganization: actions.payload.data.list,
      };
    case actionTypes.ALL_FLEETS_BY_ORG_SUCCESS:
      return {
        ...state,
        allFleetByOrg: actions.payload.data.list,
      };
    case actionTypes.EMPTY_DASHBOARD_STORE:
      return { ...initialState };
    default:
      return state;
  }
}
