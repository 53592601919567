import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import CustomTextField from "../Common/CustomTextField";
import * as actions from "../../containers/User/Dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserName, isSuperAdminLogin } from "../../utils";

const organizationTypes = [
  {
    label: "FSC",
  },
  {
    label: "FOW",
  },
  {
    label: "FMM",
  },
];

function AddNewOrg(props) {
  const { organization = [], onChange = () => {} } = props;
  const dispatch = useDispatch();
  const { allAdminUsers = [], allAdminUsersLoading } = useSelector(
    (state) => state.dashboard
  );
  const {
    domain = "",
    organizationAdmin = "",
    organizationType = "",
    organizationName = "",
  } = organization;

  useEffect(() => {
    isSuperAdminLogin() && dispatch(actions.allAdminUsers());
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <CustomTextField
            label="Organization Name"
            placeholder="Organization Name"
            size="small"
            name="organizationName"
            value={organizationName}
            onChange={onChange}
          />
        </Box>
        <Box mb={2}>
          <CustomTextField
            label="Domain Name"
            placeholder="Domain Name"
            size="small"
            name="domain"
            value={domain}
            onChange={onChange}
          />
        </Box>
        <Box mb={2}>
          {
            isSuperAdminLogin() ? (
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-label">
                  Select Organization Admin
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={organizationAdmin}
                  label="Select Organization Admin"
                  name="organizationAdmin"
                  onChange={onChange}
                  disabled={allAdminUsersLoading}
                >
                  {allAdminUsers.map((item) => {
                    return (
                      <MenuItem key={item.label} value={item._id}>
                        {item.firstName} {item.lastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null
            // <CustomTextField
            //   label="Organization Admin"
            //   placeholder="Organization Admin"
            //   size="small"
            //   name="organizationAdmin"
            //   value={getCurrentUserName()}
            //   disabled
            // />
          }
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-label">
              Select Organization Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={organizationType}
              label="Select Organization Type"
              name="organizationType"
              onChange={onChange}
            >
              {organizationTypes.map((item) => {
                return (
                  <MenuItem key={item.label} value={item.label}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AddNewOrg;
