import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    Grid,
    Box,
    Paper,
    makeStyles,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Switch,
    Button,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import CustomButton from "../../../components/Common/CustomButton";
import * as actions from "./actions";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { getCurrentUserId, getCurrentUserOrganizationId, toastError, toastSuccess } from "../../../utils";
import CustomPagination from "../../../components/Common/CustomPagination";
import CustomConfirmModal from "../../../components/Common/CustomConfirmModal";
import CustomAddModal from "../../../components/Common/CustomAddModal";
import { UploadCSVModal } from "../../../components/Common/CsvUploader";

import AddUpdateDetails from "./AddUpdateDetails";
import _ from "lodash";
import SearchBar from "../../../common/Search";
const useStyles = makeStyles({
    root: {
        width: "fit-content",
    },
});

const initialSearchType = {
    type: "",
    saveLabel: "",
    name: "",
    firstLoadPort: "",
    lastDischargePort: "",
    loadQuantity: "",
    createdDate: "",
};

function AddressBook(props) {
    const { setLoader = () => { } } = props;
    const classes = useStyles();

    const [value, setValue] = useState(1);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [savedResults, setSavedResults] = useState([]);
    const [searchType, setSearchType] = useState({ ...initialSearchType });
    const [validDocuments, setValidDocuments] = useState([])
    const [singleResults, setSingleResults] = useState({});

    const [myFilters, setMyFilters] = useState({
        offset: 1,
        limit: 10,
    });


    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState({});


    // const organizationId = getCurrentUserOrganizationId();
    const addDetailsModalRef = useRef();

    const [searchValue, setSearchValue] = useState("");
    const onSearchVessel = (search) => {
        getAllSavedResults(search);
    };
    const delaySearch = useCallback(
        _.debounce((data) => onSearchVessel(data), 500),
        []
    );
    const onSearchChange = (e) => {
        setSearchValue(e.target.value)
        delaySearch(e.target.value);
    }
    useEffect(() => {
        getAllSavedResults();
    }, []);
    const onCloseModal = () => {
        setOpen(false);
        setIsEdit(false);
    };
    const getAllSavedResults = async (search) => {
        setLoader(true);
        await actions
            .getAddressDetails({ userId: getCurrentUserId(), search: search })
            .then((res) => {
                setLoader(false);
                setSavedResults(res?.data?.list || []);
                setMyFilters({
                    totalPages: res?.data?.totalItems,
                    offset: res?.data?.currentPage,
                    pageCount: res?.data?.totalPages,
                    limit: res?.data?.perPage
                })
            })
            .catch((err) => {
                setLoader(false);
                toastError(err?.data?.message);
            });
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchType({ ...initialSearchType });
    };

    const onClickDeletAddress = (id) => {
        setDeleteId(id);
        setDeleteModal(true);
    };



    const onChangeMyPagination = (type = "") => {
        let newMyFilters = {};
        if (type === "inc") {
            newMyFilters = { ...myFilters, offset: myFilters.offset + 1 };
        } else if (type === "first") {
            newMyFilters = { ...myFilters, offset: 1 };
        } else {
            if (myFilters.offset === 1) return;
            newMyFilters = { ...myFilters, offset: myFilters.offset - 1 };
        }
        setLoader(true);
        actions
            .getAddressDetails({ ...newMyFilters, userId: getCurrentUserId() })
            .then((res) => {
                setLoader(false);
                setMyFilters({
                    totalPages: res?.data?.totalItems,
                    offset: res?.data?.currentPage,
                    pageCount: res?.data?.totalPages,
                    limit: res?.data?.perPage
                })
                setSavedResults(
                    (res?.data?.list &&
                        Array.isArray(res?.data?.list) &&
                        res?.data?.list) ||
                    []
                );
            })
            .catch((err) => {
                setLoader(false);
            });
    };

    const onDeleteAddress = () => {
        setLoader(true);
        actions
            .deleteAddressDetails(deleteId)
            .then((res) => {
                toastSuccess("Result deleted successfully");
                setDeleteModal(false);
                setDeleteId("");
                getAllSavedResults();
            })
            .catch((err) => {
                toastError();
                setLoader(false);
            });
    };
    const onSubmitConfirm = () => {
        if (addDetailsModalRef.current.onSubmitQuery) {
            addDetailsModalRef.current.onSubmitQuery();
        }
    };

    const onClickEditAddress = async (item) => {
        setLoader(true);
        await actions
            .getSingleAddressDetails({ userId: getCurrentUserId(), addressid: item?._id })
            .then((res) => {
                setSingleResults(res?.data?.[0] || {});
                setIsEdit(true)
                setLoader(false);
                setOpen(true)

            })
            .catch((err) => {
                setLoader(false);
                toastError(err?.data?.message);
            });
    }

    const handleUploadSuccess = async () => {
        try {
            const { data, message } = await actions.addManyAddressDetails(validDocuments);
            if (data.length) {
                setSavedResults((prev) => [...prev, ...data]);
                toastSuccess(message)
                setValidDocuments([])
            }
        } catch (error) {
            toastError("Error while processing uploaded CSV file.");
            console.error(error);
        }
    };
    const handleDataProcessed = (processedData) => {
        if (processedData?.validData) {
            setValidDocuments(processedData?.validData)
        }
    };
    const handleCancel = () => {
        setValidDocuments([]);
    };
    const handleDateRangeChange = async (newDateRange) => {
        const { startDate, endDate } = newDateRange;

        // Ensure dates are in ISO string format
        const formattedStartDate = startDate?.toISOString() || '';
        const formattedEndDate = endDate?.toISOString() || '';

        const { data } = await actions.getAddressDetails({
            userId: getCurrentUserId(),
            startDate: formattedStartDate,
            endDate: formattedEndDate
        });
        setSavedResults(data.list);
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box display="flex" flexDirection="column">
                    {/* Tabs and Search Bar in the first row */}
                    <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" padding={2}>
                        <Box>
                            <Paper className={classes.root}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab value={1} label="My Address Book" />
                                </Tabs>
                            </Paper>
                        </Box>
                        <div className="desktop-searchbar" style={{ margin: '0 16px' }}>

                            <SearchBar
                                placeholder={"Search with Name, type, email, mobile or location..."}
                                setSearchValue={onSearchChange}
                                searchValue={searchValue}
                                onDateRangeChange={handleDateRangeChange}
                                isMobile={false}
                            />
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <CustomButton onClick={() => setOpen(true)}>Add Details</CustomButton>
                        </div>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" mt={2}>
                        <div className="mobile-searchbar">
                            <SearchBar
                                isMobile
                                placeholder={"Search with Name, type, email, mobile or location..."}
                                setSearchValue={onSearchChange}
                                searchValue={searchValue}
                            />
                        </div>
                        <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" width="100%">
                            {/* Breakpoints for UploadCSV and Button */}
                            <Box display="flex" flexDirection="row" justifyContent="flex-end" flexWrap="wrap" width="100%">
                                <div style={{ flex: 1, minWidth: "50%", display: 'flex', justifyContent: 'flex-end' }}>
                                    <UploadCSVModal
                                        onUploadSuccess={handleUploadSuccess}
                                        onDataProcessed={handleDataProcessed}
                                        onCancel={handleCancel}
                                    />
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>


            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
                <Box pt={2}>
                    <TableContainer component={Paper}>
                        <Table className="universal-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>No.</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Name/Full Style</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Mobile no</TableCell>
                                    <TableCell>Created on</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {Array.isArray(savedResults) && savedResults.length ? (
                                    savedResults.map((item, index) => {
                                        return <>{item?.type === "Individual" ? <TableRow
                                            onDoubleClick={() => {
                                                onClickEditAddress(item);
                                            }}
                                            key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                {item?.type}
                                            </TableCell>
                                            <TableCell>
                                                {item?.individual_firstName}
                                            </TableCell>
                                            <TableCell>{item?.individual_title}</TableCell>
                                            <TableCell>{item?.individual_location || ""}</TableCell>
                                            <TableCell>{item?.individual_emailPersonal || ""}</TableCell>
                                            <TableCell>{item?.individual_mobilePersonal || ""}</TableCell>
                                            <TableCell>{moment(item.createdAt).format("DD/MMM/yyyy")}</TableCell>
                                            <TableCell>
                                                <div className="query-actions">
                                                    {/* <>
                                                        <span className="pending-steper validate-icon-vessel-manager">
                                                            <ShareIcon
                                                                color="primary"
                                                                onClick={() => onShareOrgClick(item)}
                                                            />
                                                        </span>
                                                        <div className="tooltip-notification-vessel-manager">
                                                            <p>Share with your Team</p>
                                                        </div>
                                                    </> */}
                                                    <VisibilityIcon
                                                        color="primary"
                                                        onClick={() => {
                                                            onClickEditAddress(item);
                                                        }}
                                                    />
                                                    <DeleteIcon
                                                        color="secondary"
                                                        onClick={() =>
                                                            onClickDeletAddress(item?._id)
                                                        }
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow> : <TableRow key={index}
                                            onDoubleClick={() => {
                                                onClickEditAddress(item);
                                            }}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                {item?.type}
                                            </TableCell>
                                            <TableCell>
                                                {item?.organisation_fullName}
                                            </TableCell>
                                            <TableCell>{item?.organisation_fullStyle}</TableCell>
                                            <TableCell>{item?.organisation_officeLocation || ""}</TableCell>
                                            <TableCell>{item?.organisation_emailOfficial || ""}</TableCell>
                                            <TableCell>{item?.organisation_mobileOfficial || ""}</TableCell>
                                            <TableCell>{moment(item?.createdAt).format("DD/MMM/yyyy")}</TableCell>
                                            <TableCell>
                                                <div className="query-actions">
                                                    {/* <>
                                                        <span className="pending-steper validate-icon-vessel-manager">
                                                            <ShareIcon
                                                                color="primary"
                                                                onClick={() => onShareOrgClick(item)}
                                                            />
                                                        </span>
                                                        <div className="tooltip-notification-vessel-manager">
                                                            <p>Share with your Team</p>
                                                        </div>
                                                    </> */}
                                                    <VisibilityIcon
                                                        color="primary"
                                                        onClick={() => {
                                                            onClickEditAddress(item);
                                                        }}
                                                    />
                                                    <DeleteIcon
                                                        color="secondary"
                                                        onClick={() =>
                                                            onClickDeletAddress(item?._id)
                                                        }
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow>}</>

                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan="8"
                                            align="center"
                                            className="!bg-white"
                                        >
                                            No Address Details Found.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <CustomPagination
                            onChangePagination={onChangeMyPagination}
                            filters={myFilters}
                            totalPage={myFilters?.totalPages}
                        />
                    </TableContainer>
                </Box>

            </Grid>

            <CustomConfirmModal
                open={deleteModal}
                handleClose={() => setDeleteModal(false)}
                message="Are you sure to delete this Address Details?"
                onSubmitConfirm={onDeleteAddress}
            />

            <CustomAddModal
                open={open}
                title={isEdit ? "Update details" : "Add details"}
                maxWidth="lg"
                saveLabel={isEdit ? "Update" : "Create"}
                handleClose={onCloseModal}
                onSubmitConfirm={onSubmitConfirm}
            >
                <AddUpdateDetails
                    ref={addDetailsModalRef}
                    setLoader={setLoader}
                    onCloseModal={onCloseModal}
                    getAllSavedResults={getAllSavedResults}
                    singleResults={singleResults}
                    isEdit={isEdit}
                />
            </CustomAddModal>

            {/* <CustomAddModal
                open={isCopyConfirm}
                handleClose={onCopyClick}
                onSubmitConfirm={onCopySaveClick}
                maxWidth="xs"
                saveLabel="Create"
                title="Copy to Org"
            >
                <ShareWithOrg
                    value={shareLabel}
                    onChange={(e) => setShareLabel(e.target.value)}
                    placeholder="Enter Voyages name*"
                />
            </CustomAddModal> */}
        </Grid>
    );
}

export default AddressBook;
