import {
  getQueryFormatAPI,
  queryByIdAPI,
  cargoByQueryAPI,
  allCargoExtraDetailsByQueryAPI,
  allFleetAPI,
  sendCalculatorDataAPI,
  addCargoExtraDetailsAPI,
  updateQueryFormtAPI,
  deleteCargoExtraDetailAPI,
  updateQueryFormatAPI,
  deleteFleetAPI,
  allVesselsByCharWithoutReducerAPI,
  portsByCharAPI,
  addMultiFleetAPI,
  getEmailsFleetListAPI,
  getVesselsWithIdAPI,
  saveToFleetAPI,
  getPortDistanceByPortListAPI,
  updateDistanceAPI,
  savePortDistanceAPI,
  getCanalNameByUserAPI,
  setUSerPortCanalAPI,
  resetDistanceAPI,
  updateFuelPriceAPI,
  userFleetsAPI,
  userFleetDataCopyAPI,
  allSavedResultsAPI,
  saveResultByIdAPI,
  deleteSavedResultByIdAPI,
  allVoyageResultsByCharAPI,
  allPortRegionsAPI,
  updateClientQueryAccessAPI,
  saveCalculatedCostResultAPI,
  updateFleetDataAPI,
  updateQueryAPI,
  getSingleVesselCalcyAPI,
  addCargoExtraDetailsForSingleVesselAPI,
  deleteCargoExtraDetailForSingleVesselAPI,
  sendCalculatorDataSingleVesselAPI,
  updateQueryCalculatedCostAPI,
  updateVesselManagerAPI,
  getVesselManagerAPI,
  allFleetCalculatorAPI,
  getAutoEmailsVesselListAPI,
  changeAutoVesselDetailsAPI,
  updateChangeSwitchQueryAPI,
  queryByPDFdownloadAPI,
  getSingleFleetDataAPI,
  updateLiveSharingAPI,
  addFleetAPI,
  getPortDistanceMatrixAPI,
  createVesselVesselAPI,
  getGeneralMasterDataAPI,
  getPortDistanceForCargoAnalysisAPI,
  addCargoExtraDetailsForCargoAnalysisAPI,
  deleteCargoExtraDetailFromCargoAnalysisAPI,
  // getNewPortDistanceByPortListAPI,
} from "./api";
import * as actionsTypes from "./actionTypes";

export const getQueryFormat = (data) => {
  return new Promise((resolve, reject) => {
    getQueryFormatAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const queryById = (data) => {
  return new Promise((resolve, reject) => {
    queryByIdAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const queryByPDFdownload = (data) => {
  return new Promise((resolve, reject) => {
    queryByPDFdownloadAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cargoByQuery = (data) => {
  return new Promise((resolve, reject) => {
    cargoByQueryAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const allCargoExtraDetailsByQuery = (data) => {
  return new Promise((resolve, reject) => {
    allCargoExtraDetailsByQueryAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const allFleet = (data) => {
  return new Promise((resolve, reject) => {
    allFleetAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const allFleetCalculator = (data) => {
  return new Promise((resolve, reject) => {
    allFleetCalculatorAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const getSingleVesselCalcy = (data) => {
  return new Promise((resolve, reject) => {
    getSingleVesselCalcyAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendCalculatorData = (data) => {
  return new Promise((resolve, reject) => {
    sendCalculatorDataAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addCargoExtraDetails = (data) => {
  return new Promise((resolve, reject) => {
    addCargoExtraDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addCargoExtraDetailsForCargoAnalysis = (data) => {
  return new Promise((resolve, reject) => {
    addCargoExtraDetailsForCargoAnalysisAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addCargoExtraDetailsForSingleVessel = (data) => {
  return new Promise((resolve, reject) => {
    addCargoExtraDetailsForSingleVesselAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateQueryFormt = (data) => {
  return new Promise((resolve, reject) => {
    updateQueryFormtAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteCargoExtraDetail = (data) => {
  return new Promise((resolve, reject) => {
    deleteCargoExtraDetailAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const deleteCargoExtraDetailFromCargoAnalysis = (data) => {
  return new Promise((resolve, reject) => {
    deleteCargoExtraDetailFromCargoAnalysisAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteCargoExtraDetailForSingleVessel = (data) => {
  return new Promise((resolve, reject) => {
    deleteCargoExtraDetailForSingleVesselAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateQueryFormat = (data) => {
  return new Promise((resolve, reject) => {
    updateQueryFormatAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateQueryDetails = (data) => {
  return new Promise((resolve, reject) => {
    updateQueryAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCalculatedCostDetails = (id, data) => {
  return new Promise((resolve, reject) => {
    updateQueryCalculatedCostAPI(id, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateClientQueryAccess = (data) => {
  return new Promise((resolve, reject) => {
    updateClientQueryAccessAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateChangeSwitchQuery = (data) => {
  return new Promise((resolve, reject) => {
    updateChangeSwitchQueryAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteFleet = (data) => {
  return new Promise((resolve, reject) => {
    deleteFleetAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const allVesselsByCharWithoutReducer = (data) => {
  return new Promise((resolve, reject) => {
    allVesselsByCharWithoutReducerAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const portsByChar = (data) => {
  return new Promise((resolve, reject) => {
    portsByCharAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addMultiFleet = (data) => {
  return new Promise((resolve, reject) => {
    addMultiFleetAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addFleet = (data) => {
  return new Promise((resolve, reject) => {
    addFleetAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changeAutoVesselDetails = (data) => {
  return new Promise((resolve, reject) => {
    changeAutoVesselDetailsAPI(data).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export const updateVesselManagerData = (data) => {
  return new Promise((resolve, reject) => {
    updateVesselManagerAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getVesselManagerData = (data) => {
  return new Promise((resolve, reject) => {
    getVesselManagerAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateFleetData = (data) => {
  return new Promise((resolve, reject) => {
    updateFleetDataAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const updateLiveSharing = (data) => {
  return new Promise((resolve, reject) => {
    updateLiveSharingAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSingleFleetData = (fleetId, data) => {
  return new Promise((resolve, reject) => {
    getSingleFleetDataAPI(fleetId, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEmailsFleetList = (data) => {
  return new Promise((resolve, reject) => {
    getEmailsFleetListAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAutoEmailsVesselList = (data) => {
  return new Promise((resolve, reject) => {
    getAutoEmailsVesselListAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getVesselsWithId = (data, isExtra) => {
  return new Promise((resolve, reject) => {
    getVesselsWithIdAPI(data, isExtra)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveToFleet = (data) => {
  return new Promise((resolve, reject) => {
    saveToFleetAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPortDistanceByPortList = (data) => {
  return new Promise((resolve, reject) => {
    getPortDistanceByPortListAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPortDistanceForCargoAnalysis = (data) => {
  return new Promise((resolve, reject) => {
    getPortDistanceForCargoAnalysisAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const getPortDistanceMatrix = (data, extra) => {
  return new Promise((resolve, reject) => {
    getPortDistanceMatrixAPI(data, extra)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const updateDistance = (data) => {
  return new Promise((resolve, reject) => {
    updateDistanceAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const savePortDistance = (data) => {
  return new Promise((resolve, reject) => {
    savePortDistanceAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveCalculatedCostResult = (data) => {
  return new Promise((resolve, reject) => {
    saveCalculatedCostResultAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCanalNameByUser = (data) => {
  return new Promise((resolve, reject) => {
    getCanalNameByUserAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const setUSerPortCanal = (data) => {
  return new Promise((resolve, reject) => {
    setUSerPortCanalAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetDistance = (data) => {
  return new Promise((resolve, reject) => {
    resetDistanceAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const updateFuelPrice = (data, data2) => {
//   return new Promise((resolve, reject) => {
//     updateFuelPriceAPI(data, data2)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const userFleets = (data) => {
  return new Promise((resolve, reject) => {
    userFleetsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const userFleetDataCopy = (data) => {
  return new Promise((resolve, reject) => {
    userFleetDataCopyAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const allSavedResults = (data) => {
  return new Promise((resolve, reject) => {
    allSavedResultsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveResultById = (data) => {
  return new Promise((resolve, reject) => {
    saveResultByIdAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteSavedResultById = (data) => {
  return new Promise((resolve, reject) => {
    deleteSavedResultByIdAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const allVoyageResultsByChar = (data) => {
  return new Promise((resolve, reject) => {
    allVoyageResultsByCharAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const allPortRegions = (data) => {
  return new Promise((resolve, reject) => {
    allPortRegionsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const getNewPortDistanceByPortList = (data) => {
//     return new Promise((resolve, reject) => {
//         getNewPortDistanceByPortListAPI(data).then(res => {
//             resolve(res)
//         }).catch(err => {
//             reject(err)
//         })
//     })
// }

export const clearCalculatorReducer = () => {
  return (disptach) => {
    disptach({
      type: actionsTypes.CLEAR_CALCULATOR_REDUCER,
    });
  };
};


export const createVesselVessel = (data) => {
  return new Promise((resolve, reject) => {
    createVesselVesselAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getGeneralMasterData = (data) => {
  return new Promise((resolve, reject) => {
    getGeneralMasterDataAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};