import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { expenseResultField } from "../../constants";
import { handleKeyDown } from "../../common/common";

function Expenses(props) {
  const {
    selectedVessels = {},
    onChangeSelectedVessel = () => { },
    openSplitHireModal = () => { },
    calculatorData = {},
    setCalculatorData = () => { },
    onChangeChartValue = () => { },
    isFromSingleVesselCalcy = false
  } = props || {};

  const { clientQuery = {}, portOperation = [] } = calculatorData || {};

  const onChangeQueryValues = (e) => {
    const { name = "", value = "" } = e.target;
    let newPortOperation = [...portOperation];
    if (name === "freightIdea") {
      newPortOperation = newPortOperation.map((item) => {
        if (item.operation === "Loading") {
          return { ...item, freight: Number(value) };
        }
        return item;
      });
    }
    setCalculatorData({
      ...calculatorData,
      clientQuery: {
        ...clientQuery,
        [name]: Number(value),
      },
      portOperation: newPortOperation,
    });
    onChangeChartValue({
      ...calculatorData,
      clientQuery: {
        ...clientQuery,
        [name]: Number(value),
      },
      portOperation: newPortOperation,
    });
  };

  const {
    hireGbb = 0,
    hirePerDay = 0,
    profitPerDay = 0,
    profitPerDayGBB = 0,
    tcEq = 0,
    gbb = 0,
  } = selectedVessels || {};
  const {
    freightMode = "",
    freightIdea = 0,
    miscRevenue = 0,
  } = clientQuery || {};
  const disablefreightIdea = freightMode === "Multiple Freight";

  return (
    <Grid container>
      <Grid item xs={12} className="calculator-section-header">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4 className="result-heading">Freight and Result</h4>
          <span style={{ marginRight: 10 }}>GBB</span>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div>
          <table className="fleet-result-table low-th-width">
            <tr>
              <th>Freight Rate</th>
              <td>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={freightIdea || 0}
                  name="freightIdea"
                  onChange={onChangeQueryValues}
                  disabled={disablefreightIdea}
                />
              </td>
            </tr>
            <tr>
              <th>TC Eq</th>
              <td>
                <p>{Number(tcEq).toFixed(2)}</p>
              </td>
              <td>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  placeholder="0"
                  value={gbb}
                  name="gbb"
                  onChange={(e) => onChangeSelectedVessel(e, true)}
                />
              </td>
            </tr>
            <tr>
              <th
                className="cursor-pointer underline"
                onClick={() => openSplitHireModal(selectedVessels)}
              >
                Hire Per Day
              </th>
              <td>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  placeholder="0"
                  value={hirePerDay}
                  name="hirePerDay"
                  onChange={(e) => onChangeSelectedVessel(e, true)}
                />
              </td>
              <td>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  placeholder="0"
                  value={hireGbb}
                  name="hireGbb"
                  onChange={(e) => onChangeSelectedVessel(e, true)}
                />
              </td>
            </tr>
            <tr>
              <th>Profit/day</th>
              <td>
                <p>{Number(profitPerDay).toFixed(2)}</p>
              </td>
              <td>
                <p>{Number(profitPerDayGBB)}</p>
              </td>
            </tr>
          </table>

          <h4 className="result-heading m-t-5">Revenue</h4>
          <table className="fleet-result-table">
            <tr>
              <th>Misc Revenue</th>
              <td>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  placeholder="0"
                  onChange={onChangeQueryValues}
                  value={miscRevenue || ""}
                  name="miscRevenue"
                />
              </td>
            </tr>
          </table>
          <h4 className="result-heading m-t-5">Expenses</h4>
          <table className="fleet-result-table">
            {expenseResultField.map((item, key) => {
              return (
                <tr key={key}>
                  <th>{item.label}</th>
                  <td>
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      onChange={onChangeQueryValues}
                      value={clientQuery[item.value] || ""}
                      name={item.value}
                    />
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </Grid>

      {isFromSingleVesselCalcy ? <Grid item xs={12}>
        <p className="m-t-20 m-l-5 m-b-5">This Vessel:</p>
        <table className="fleet-result-table">
          <tr>
            <th>Extra Expenses</th>
            <td>
              <input
                type="number"
                onKeyDown={handleKeyDown}
                placeholder="0"
                onChange={(e) => onChangeSelectedVessel(e, true)}
                value={
                  (selectedVessels && selectedVessels.extraExpense) || 0
                }
                name="extraExpense"
              />
            </td>
          </tr>
          <tr>
            <th>Constants</th>
            <td>
              <input
                type="number"
                onKeyDown={handleKeyDown}
                placeholder="0"
                onChange={(e) => onChangeSelectedVessel(e, true)}
                value={
                  (selectedVessels && selectedVessels.constants) || 0
                }
                name="constants"
              />
            </td>
          </tr>
        </table>
      </Grid> : ""}
    </Grid>
  );
}

export default Expenses;
