import React from "react";
import CustomTablePDF from "../../Common/CustomTablePDF";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const CargoPdf = (props) => {
  const { query, portOperations, pdfSaveId = "" } = props;
  const {
    access = "",
    freightMode = "",
    message = "",
    name = "",
    qtyMode = "",
    laycanStartDate = null,
    laycanEndDate = null,
  } = query || {};

  let showMinMax = false;
  if (qtyMode === "MINMAX") showMinMax = true;

  const data = portOperations?.map((portDetails) => ({
    operation: portDetails.operation,
    port: portDetails.port,
    cargoQty: portDetails.cargoQty.toString(),
    tolerance: portDetails.tolerance.toString(),
    rate: portDetails.rate.toString(),
    terms: portDetails.terms,
    turnTime: portDetails.turnTime.toString(),
    freight: portDetails.freight,
    min: portDetails.min.toString(),
    max: portDetails.max.toString(),
    adcom: portDetails.adcom.toString(),
    other: portDetails.other.toString(),
    brokerage: portDetails.brokerage.toString(),
    account: portDetails.account,
    cargo: portDetails.cargo,
    stowFactor: portDetails.stowFactor.toString(),
  }));
  const fields = [
    { title: "Operation", value: "operation", width: 10 },
    { title: "Port", value: "port", width: 11 },
    { title: "Min", value: "min", width: 7, hide: !showMinMax },
    { title: "Max", value: "max", width: 7, hide: !showMinMax },
    { title: "Cargo Qty", value: "cargoQty", width: 7, hide: showMinMax },
    { title: "Tolerance", value: "tolerance", width: 7, hide: showMinMax },
    { title: "Rate MT/Day", value: "rate", width: 7 },
    { title: "Terms", value: "terms", width: 7 },
    { title: "Turn Time (d)", value: "turnTime", width: 7 },
    { title: "Freight", value: "freight", width: 6 },
    { title: "Adcom (%)", value: "adcom", width: 6 },
    { title: "Other (%)", value: "other", width: 6 },
    { title: "Brokerage (%)", value: "brokerage", width: 6 },
    { title: "A/C", value: "account", width: 9 },
    { title: "Cargo", value: "cargo", width: 6 },
    { title: "Stow Factor", value: "stowFactor", width: 5 },
  ];

  return (
    <View>
      <CustomTablePDF fields={fields} data={data} query={query} pdfSaveId={pdfSaveId} />
    </View>
  );
};

export default CargoPdf;
