import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    Grid,
    Box,
    Paper,
    makeStyles,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Switch,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import CustomButton from "../../../components/Common/CustomButton";
import * as actions from "./actions";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { getCurrentUserId, getCurrentUserOrganizationId, toastError, toastSuccess } from "../../../utils";
import CustomPagination from "../../../components/Common/CustomPagination";
import CustomConfirmModal from "../../../components/Common/CustomConfirmModal";
import CustomAddModal from "../../../components/Common/CustomAddModal";

import AddUpdateDetails from "./AddUpdateDetails";
import _ from "lodash";
import SearchBar from "../../../common/Search";
const useStyles = makeStyles({
    root: {
        width: "fit-content",
    },
});

const initialSearchType = {
    type: "",
    saveLabel: "",
    name: "",
    firstLoadPort: "",
    lastDischargePort: "",
    loadQuantity: "",
    createdDate: "",
};

function UserDashboard(props) {
    const { setLoader = () => { } } = props;
    const classes = useStyles();

    const [value, setValue] = useState(1);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [savedResults, setSavedResults] = useState([]);
    const [searchType, setSearchType] = useState({ ...initialSearchType });

    const [singleResults, setSingleResults] = useState({});

    const [myFilters, setMyFilters] = useState({
        offset: 1,
        limit: 10,
    });


    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState({});


    // const organizationId = getCurrentUserOrganizationId();
    const addDetailsModalRef = useRef();

    const [searchValue, setSearchValue] = useState("");
    const onSearchVessel = (search) => {
        getAllSavedResults(search);
    };
    const delaySearch = useCallback(
        _.debounce((data) => onSearchVessel(data), 500),
        []
    );
    const onSearchChange = (e) => {
        setSearchValue(e.target.value)
        delaySearch(e.target.value);
    }
    useEffect(() => {
        // getAllSavedResults();
    }, []);
    const onCloseModal = () => {
        setOpen(false);
        setIsEdit(false);
    };
    const getAllSavedResults = async (search) => {
        setLoader(true);
        await actions
            .getAddressDetails({ userId: getCurrentUserId(), search: search })
            .then((res) => {
                setLoader(false);
                setSavedResults(res?.data?.list || []);
                setMyFilters({
                    totalPages: res?.data?.totalItems,
                    offset: res?.data?.currentPage,
                    pageCount: res?.data?.totalPages,
                    limit: res?.data?.perPage
                })
            })
            .catch((err) => {
                setLoader(false);
                toastError(err?.data?.message);
            });
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchType({ ...initialSearchType });
    };

    const onClickDeletAddress = (id) => {
        setDeleteId(id);
        setDeleteModal(true);
    };



    const onChangeMyPagination = (type = "") => {
        let newMyFilters = {};
        if (type === "inc") {
            newMyFilters = { ...myFilters, offset: myFilters.offset + 1 };
        } else if (type === "first") {
            newMyFilters = { ...myFilters, offset: 1 };
        } else {
            if (myFilters.offset === 1) return;
            newMyFilters = { ...myFilters, offset: myFilters.offset - 1 };
        }
        setLoader(true);
        actions
            .getAddressDetails({ ...newMyFilters, userId: getCurrentUserId() })
            .then((res) => {
                setLoader(false);
                setMyFilters({
                    totalPages: res?.data?.totalItems,
                    offset: res?.data?.currentPage,
                    pageCount: res?.data?.totalPages,
                    limit: res?.data?.perPage
                })
                setSavedResults(
                    (res?.data?.list &&
                        Array.isArray(res?.data?.list) &&
                        res?.data?.list) ||
                    []
                );
            })
            .catch((err) => {
                setLoader(false);
            });
    };

    const onDeleteAddress = () => {
        setLoader(true);
        actions
            .deleteAddressDetails(deleteId)
            .then((res) => {
                toastSuccess("Result deleted successfully");
                setDeleteModal(false);
                setDeleteId("");
                getAllSavedResults();
            })
            .catch((err) => {
                toastError();
                setLoader(false);
            });
    };
    const onSubmitConfirm = () => {
        if (addDetailsModalRef.current.onSubmitQuery) {
            addDetailsModalRef.current.onSubmitQuery();
        }
    };

    const onClickEditAddress = async (item) => {
        setLoader(true);
        await actions
            .getSingleAddressDetails({ userId: getCurrentUserId(), addressid: item?._id })
            .then((res) => {
                setSingleResults(res?.data?.[0] || {});
                setIsEdit(true)
                setLoader(false);
                setOpen(true)

            })
            .catch((err) => {
                setLoader(false);
                toastError(err?.data?.message);
            });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                    Dashboard Charts Analysis comming soon..
                </Box>
               
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
                <Box pt={2}>
                 
                </Box>

            </Grid>

        </Grid>
    );
}

export default UserDashboard;
