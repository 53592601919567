import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { FaUserCircle } from "react-icons/fa";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { getCurrentUserFirstName, getCurrentUserProfileImage, getUserDetails } from "../../utils";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#41465f",
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
}));

// const listOfRoutes = [
//     {
//         label: 'D',
//     },
//     {
//         label: 'C',
//     },
//     {
//         label: 'MV',
//     },
//     {
//         label: 'FR',
//     },
//     {
//         label: 'NewO',
//     },
//     {
//         label: 'VM',
//     },
//     {
//         label: 'EP',
//     },
// ]

export default function ButtonAppBar(props) {
  const {
    title = "",
    onClickMenu = () => { },
    validRoutes = [],
    activeRoute = "",
  } = props;
  const classes = useStyles();
  const userDetails = getUserDetails();

  return (
    <AppBar position="static" className="header-bg-color text-black">
      <Toolbar className="min-h-[54px]">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div className="" style={{ display: "flex", alignItems: "center", color: "#fff" }}>
            <IconButton
              onClick={onClickMenu}
              edge="start"
              className={"appbar-btn"}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h6"
              className={clsx(classes.title, "page-name-header")}
              style={{ marginLeft: 10 }}
            >
              {title}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{ marginRight: 20 }}
              className="flex gap-3 items-center"
            >
              <div className="profile-pic-container">
                {getCurrentUserProfileImage() ? (
                  <img src={getCurrentUserProfileImage()} alt="User Profile" className="profile-pic" />
                ) : (
                  <FaUserCircle className="text-3xl text-neutrals9" />
                )}
              </div>
              {" "}
              <div>
                <div className="typo-18-700 user-name-color">{getCurrentUserFirstName()}</div>
                <div className="typo-11-500 text-neutrals8 uppercase">
                  {userDetails?.role}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}
