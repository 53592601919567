export const validateDetails = (details = {}, selectedValue = "") => {
    let errors = []
    const { individual_title = '', individual_firstName = '', individual_emailPersonal = null, individual_shortName = null, organisation_fullName = '', organisation_emailOfficial = '', organisation_shortName = '' } = details || {}
    if (!selectedValue) errors.push("please select individual or organization")
    if (selectedValue === "Individual") {
        if (!individual_title) errors.push('Title is Required')
        if (!individual_firstName) errors.push('First Name is Required')
        if (!individual_emailPersonal) errors.push('Email Personal is Required')
        if (!individual_shortName) errors.push('Short name is Required')
    } else {
        if (!organisation_fullName) errors.push('Full Name is Required')
        if (!organisation_emailOfficial) errors.push('Email Official is Required')
        if (!organisation_shortName) errors.push('Short Name is Required')
    }
    return {
        isValid: errors.length === 0,
        errors
    }
}