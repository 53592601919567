import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";

const StyledButton = withStyles({
  root: {
    height: 40,
    background: "#3f51b5"
    // "#0051B6",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

export default function CustomButton(props) {
  const { onClick = () => {}, loader = false, ...others } = props;
  return (
    <StyledButton
      onClick={onClick}
      variant="contained"
      color="primary"
      fullWidth
      {...others}
    >
      {loader ? (
        <CircularProgress size={22} style={{ color: "white" }} />
      ) : (
        props.children
      )}
    </StyledButton>
  );
}
