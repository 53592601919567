import * as actionTypes from "./actionTypes";

const initialState = {
  allClientOrders: [],
  queryOrderList: [],
  allOrganizationOrders: [],
  allClientOrdersPagination: {
    offset: 0,
    limit: 10
  },
  allOrganizationOrdersPagination: {
    offset: 0,
    limit: 10
  },
};

export default function dashboard(state = { ...initialState }, actions) {
  switch (actions.type) {
    case actionTypes.ALL_CLIENT_ORDERS:
      return {
        ...state,
        allClientOrders: actions.payload.data.list,
        allClientOrdersPagination: {
          totalPages: actions.payload.data.totalItems,
          offset: actions.payload.data.currentPage,
          pageCount: actions.payload.data.totalPages,
          limit: actions.payload.data.perPage
        }
      };
    case actionTypes.ALL_ORGANIZATION_ORDERS:
      return {
        ...state,
        allOrganizationOrders: actions.payload.data.list,
        allOrganizationOrdersPagination: {
          totalPages: actions.payload.data.totalItems,
          offset: actions.payload.data.currentPage,
          pageCount: actions.payload.data.totalPages,
          limit: actions.payload.data.perPage
        }
      };
    case actionTypes.GET_QUERY_IN_ORDER_FORMAT:
      return {
        ...state,
        queryOrderList: actions.payload.data.list,
      };
    case actionTypes.CLEAR_ORDER_REDUCER:
      return { ...initialState };
    default:
      return state;
  }
}
