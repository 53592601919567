import { apiPost, apiPut } from "../../utils";

export const loginAPI = (data) => {
  return apiPost("/api/auth/login", data);
};

export const forgetPasswordAPI = (data) => {
  return apiPost(`/api/auth/forgetPassword`, data);
};

export const updatePasswordAPI = (data) => {
  return apiPost(`/api/auth/resetPassword/` + data.accessToken, {
    password: data.password,
    confirmPassword: data.password,
  });
};

export const registerUserApi = (data) => {
  return apiPost("/api/auth/register", data);
};
