import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  FormControl,
  Select,
  MenuItem,
  Switch,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import * as actions from "./actions";
import { useSelector, useDispatch } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import CustomButton from "../../../components/Common/CustomButton";
import CustomAddModal from "../../../components/Common/CustomAddModal";
import AddNewUser from "../../../components/Dashboard/AddNewUser";
import {
  toastError,
  toastSuccess,
  getCurrentUserEmail,
  isAdminLogin,
  getOrganizationId,
  isSuperAdminLogin,
} from "../../../utils";
import moment from "moment";
import ActionMenu from "../../../common/ActionMenu";
import CustomPagination from "../../../components/Common/CustomPagination";

const inititalUser = {
  dateOfBirth: "",
  email: "",
  firstName: "",
  lastName: "",
  organizationId: "",
  password: "",
  role: "user",
  isPremium: false,
  isVerified: false,
  premiumAccessExpiry: "",
};

const allAccess = [
  { id: 1, name: "Premium", value: "isPremium" },
  { id: 2, name: "Verified", value: "isVerified" },
];

export default function UserMgntOrg(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({ ...inititalUser });
  const [isEdit, setIsEdit] = useState(false);
  const [saveLabel, setSaveLabel] = useState("Add");
  const [selectedOrganization, setSelectedOrganization] = useState({});
  const [getForwardUser, setForwardUser] = useState("");

  const { userOrganizaton = [], allOrganization = [] } = useSelector(
    (state) => state.dashboard
  );

  const allRoles = [
    { id: 1, name: "User", value: "user" },
    { id: 2, name: "Admin", value: "admin", hide: isAdminLogin() },
    // { id: 3, name: "Super Admin", value: "superadmin" },
  ];
  const [allFilters, setAllFilters] = useState({
    totalPages: 1,
    offset: 1,
    pageCount: 1,
    limit: 10
  });
  useEffect(() => {
    if (allOrganization.length)
      setSelectedOrganization(
        isSuperAdminLogin() ? "allUsers" : allOrganization[0]
      );
  }, [allOrganization]);

  useEffect(() => {
    if (selectedOrganization?._id) {
      setUser({
        ...user,
        organizationId: selectedOrganization?._id,
      });
      onUserForOrg();
    } else if (selectedOrganization === "allUsers") {
      onUserForOrg();
    }
  }, [selectedOrganization]);

  const onUserForOrg = () => {
    props.setLoader(true);
    dispatch(
      actions.usersForOrg({
        organizationId: selectedOrganization?._id,
        allUsers: selectedOrganization === "allUsers",
      })
    )
      .then((res) => {
        setAllFilters({
          totalPages: res?.data?.totalItems,
          offset: res?.data?.currentPage,
          pageCount: res?.data?.totalPages,
          limit: res?.data?.perPage
        })
        props.setLoader(false);
      })
      .catch((err) => {
        toastError(err?.data?.message);
        props.setLoader(false);
      });
  };

  const onCloseModal = () => {
    setUser({
      ...inititalUser,
      organizationId: selectedOrganization?._id,
    });
    setIsEdit(false);
    setSaveLabel("Add");
    setOpen(false);
  };

  const onSubmitConfirm = () => {
    setOpen(false);
    props.setLoader(true);
    let newUser = { ...user, dateOfBirth: moment(user.dateOfBirth).format() };
    if (isEdit) {
      const updatedData = {
        userId: newUser._id,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        email: newUser.email,
        forwardEmail: newUser?.forwardEmail,
        role: newUser.role,
        dateOfBirth: newUser.dateOfBirth,
        organizationId: newUser.organizationId,
        isPremium: newUser.isPremium,
        isVerified: newUser.isVerified,
        premiumAccessExpiry: newUser.premiumAccessExpiry,
      };
      if (updatedData.role === "superadmin") delete updatedData.role;
      return actions
        .updateUser(updatedData)
        .then(() => {
          toastSuccess("User Updated Successfully");
          props.setLoader(false);
          onUserForOrg();
          setUser({
            ...inititalUser,
            organizationId: selectedOrganization?._id,
          });
          setIsEdit(false);
          setSaveLabel("Add");
        })
        .catch((err) => {
          toastError(err?.data?.message);
          props.setLoader(false);
          setOpen(true);
        });
    }
    actions
      .createUser(newUser)
      .then(() => {
        toastSuccess("User Created Successfully");
        props.setLoader(false);
        onUserForOrg();
        setUser({
          ...inititalUser,
          organizationId: selectedOrganization?._id,
        });
        setIsEdit(false);
        setSaveLabel("Add");
      })
      .catch((err) => {
        toastError(err?.data?.message);
        props.setLoader(false);
        setOpen(true);
      });
  };

  const onUpdateUserStatus = (status, item) => {
    const isActivated = status === "activate";
    props.setLoader(true);
    actions
      .updateActiveUser({ isActivated: isActivated, userId: item._id })
      .then(() => {
        props.setLoader(false);
        onUserForOrg();
        toastSuccess(
          `User ${isActivated ? "activated" : "deactivated"} Successfully`
        );
      })
      .catch((err) => {
        toastError(err?.data?.message);
        props.setLoader(false);
      });
  };

  const onEditUser = (item) => {
    const obj = {
      ...item,
      dateOfBirth: moment(item.dateOfBirth).valueOf(),
    };
    setForwardUser(obj?.forwardEmail)
    setUser(obj);
    setIsEdit(true);
    setSaveLabel("Update");
    setOpen(true);
  };

  const onChangeOrganization = (id) => {
    let selectedOrganization =
      Array.isArray(allOrganization) && allOrganization.length
        ? allOrganization.find((org) => org._id === id)
        : {};
    setSelectedOrganization(selectedOrganization || id);
  };

  const onFunction = async (item, newValue) => {

    const updatedData = {
      userId: item._id,
      isPaid: newValue,
    };
    if (updatedData.role === "superadmin") delete updatedData.role;
    return actions
      .updateUser(updatedData)
      .then(() => {
        toastSuccess("User Updated Successfully");
        props.setLoader(false);
        onUserForOrg();
        setUser({
          ...inititalUser,
          organizationId: selectedOrganization?._id,
        });
        setIsEdit(false);
        setSaveLabel("Add");
      })
      .catch((err) => {
        toastError(err?.data?.message);
        props.setLoader(false);
        setOpen(true);
      });

  }

  const onChangeAllPagination = (type = "") => {
    let newallFilters = {}
    if (type === 'inc') {
      newallFilters = { ...allFilters, offset: allFilters.offset + 1 }
    } else if (type === 'first') {
      newallFilters = { ...allFilters, offset: 1 }
    } else {
      if (allFilters.offset === 1) return
      newallFilters = { ...allFilters, offset: allFilters.offset - 1 }
    }
    dispatch(
      actions.usersForOrg({
        ...newallFilters,
        organizationId: selectedOrganization?._id,
        allUsers: selectedOrganization === "allUsers",
      })
    )
      .then((res) => {
        setAllFilters({
          totalPages: res?.data?.totalItems,
          offset: res?.data?.currentPage,
          pageCount: res?.data?.totalPages,
          limit: res?.data?.perPage
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNewUser = async (item, isRead) => {
    if (item.isRead) return
    const updatedData = {
      userId: item._id,
      isRead,
    };
    if (updatedData.role === "superadmin") delete updatedData.role;
    return actions
      .updateUser(updatedData)
      .then(() => {
        toastSuccess("User Updated Successfully");
        props.setLoader(false);
        onUserForOrg();
        setUser({
          ...inititalUser,
          organizationId: selectedOrganization?._id,
        });
        setIsEdit(false);
        setSaveLabel("Add");
      })
      .catch((err) => {
        toastError(err?.data?.message);
        props.setLoader(false);
        setOpen(true);
      });

  }

  return (
    <Grid container className="user-organization-contianer">
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Paper
            onClick={() => history.goBack()}
            className="px-3 py-1 bg-neutrals9 border border-neutrals6 text-neutrals4 rounded-md text-sm flex items-center gap-1 cursor-pointer"
          >
            <ArrowBackIosIcon className="text-sm" /> Go back
          </Paper>
          <div style={{ width: "300px" }}>
            <FormControl variant="filled" size="small" fullWidth>
              <Select
                value={selectedOrganization?._id || selectedOrganization}
                onChange={(e) => onChangeOrganization(e.target.value)}
                // label="Age"
                // disabled={!isSuperAdminLogin()}
                defaultValue="Single Freight"
                size="small"
                variant="outlined"
                fullWidth
              >
                {isSuperAdminLogin() && (
                  <MenuItem dense value={"allUsers"}>
                    All Users
                  </MenuItem>
                )}
                {Array.isArray(allOrganization) &&
                  allOrganization.map((item, index) => {
                    return (
                      <MenuItem key={index} dense value={item._id}>
                        {item.organizationName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div style={{ width: "115px" }}>
            <CustomButton
              onClick={() => setOpen(true)}
              className="bg-page-name rounded-md"
            >
              Create User
            </CustomButton>
          </div>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box pt={2}>
          <TableContainer component={Paper} className="shadow-md rounded-lg">
            <Table className="universal-table">
              {/* Table Header */}
              <TableHead>
                <TableRow className="bg-gray-100 ">
                  <TableCell className="font-semibold ">S.No</TableCell>
                  <TableCell className="font-semibold ">Name</TableCell>
                  <TableCell className="font-semibold ">Email</TableCell>
                  <TableCell className="font-semibold ">Role</TableCell>
                  <TableCell className="font-semibold text-center">Status</TableCell>
                  <TableCell className="font-semibold text-center">isPaid</TableCell>
                  <TableCell className="font-semibold text-center">Created on</TableCell>
                  <TableCell className="font-semibold text-center">Actions</TableCell>
                </TableRow>
              </TableHead>

              {/* Table Body */}
              <TableBody>
                {Array.isArray(userOrganizaton) && userOrganizaton.length ? (
                  userOrganizaton.map((item, index) => {
                    const isEvenRow = index % 2 === 0; // Determine if the row index is even or odd
                    return (
                      <TableRow
                        key={index}
                        className={`cursor-pointer ${item.isRead
                          ? isEvenRow
                            ? "bg-gray-200" // Patterned color for even rows
                            : "bg-white" // Patterned color for odd rows
                          : "bg-teal-100 " // Highlighted color for unread rows
                          }`}
                        onClick={() => handleNewUser(item, true)}
                        onDoubleClick={() => onEditUser(item)}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {item.firstName + item.lastName || ""}
                        </TableCell>

                        {/* Email */}
                        <TableCell className="text-gray-600">{item.email}</TableCell>

                        {/* Role */}
                        <TableCell className="capitalize text-gray-600">{item.role}</TableCell>

                        {/* Status */}
                        <TableCell>
                          <div
                            className={`capitalize px-2 py-1 rounded-full text-center font-medium text-sm ${item.isActivated
                              ? "bg-green-100 text-green-600"
                              : "bg-red-100 text-red-600"
                              }`}
                          >
                            {item.isActivated ? "Active" : "Deactive"}
                          </div>
                        </TableCell>

                        {/* Paid Status */}
                        <TableCell className="text-center">
                          <div>
                            <Switch
                              checked={item?.isPaid}
                              onChange={(event) => onFunction(item, event.target.checked)}
                              color="primary"
                            />
                          </div>
                        </TableCell>
                        <TableCell className="text-center">{moment(item?.createdAt).format("DD/MMM/yyyy")}</TableCell>

                        {/* Actions */}
                        <TableCell className="user-organization-actions flex items-center space-x-4">
                          {item.email !== getCurrentUserEmail() && (
                            <div className="flex items-center">
                              <ActionMenu
                                options={[
                                  {
                                    label: "Edit",
                                    onClick: () => onEditUser(item),
                                    icon: <EditIcon className="text-lightBlue" />,
                                  },
                                  {
                                    label: "Activate",
                                    onClick: () => onUpdateUserStatus("activate", item),
                                    hide: item.role === "superadmin" || item.isActivated,
                                  },
                                  {
                                    label: "Deactivate",
                                    onClick: () => onUpdateUserStatus("deactivate", item),
                                    hide: item.role === "superadmin" || !item.isActivated,
                                  },
                                ]}
                              />
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={6} className="!bg-white">
                      No User organization found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

            </Table>
            <CustomPagination
              onChangePagination={onChangeAllPagination}
              filters={allFilters}
              totalPage={allFilters?.totalPages}
            />
          </TableContainer>
        </Box>
      </Grid >

      <CustomAddModal
        open={open}
        saveLabel={saveLabel}
        title={isEdit ? "Update User" : "Add User"}
        handleClose={onCloseModal}
        onSubmitConfirm={onSubmitConfirm}
      >
        <AddNewUser
          user={user}
          getForwardUser={getForwardUser}
          isEdit={isEdit}
          allRoles={allRoles}
          allAccess={allAccess}
          setUser={setUser}
          selectedOrganization={selectedOrganization}
          onChangeOrganization={onChangeOrganization}
          isAdminLogin={isAdminLogin}
          isSuperAdminLogin={isSuperAdminLogin}
          allOrganization={allOrganization}
        />
      </CustomAddModal>
    </Grid >
  );
}
