import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FileUploader } from 'react-drag-drop-files';
import pdfToText from "react-pdftotext";
import { queryByIdAPI } from '../../containers/User/Calculator/api';
import * as calculatorActions from "../../containers/User/Calculator/actions";

export default function UploadPDFModal(props) {
    const { handleClose, title = "Upload PDF", setPortOperations,setQuery ,query, open } = props;
    const [error, setError] = useState("");
    const [fileUploading, setFileUploading] = useState("");



    const fileTypes = ["PDF"];
    const handleChange = async (file) => {
        setFileUploading("Pdf file Uploading...");
        const queryid = await pdfToText(file)
            .then((text) => {
                const startIndex = text.indexOf("cargoqueryid-");
                const endIndex = text.indexOf("#", startIndex);
                if (startIndex !== -1 && endIndex !== -1) {
                    let substring = text.substring(startIndex + "cargoqueryid-".length, endIndex);
                    substring = substring.replace(/\s+/g, "");
                    return substring;
                }
                return "";
            }).catch(() => setError("invalid file! Please upload valid file"));
        if (!queryid) {
            setError("invalid file! Please upload valid file")
            return
        } else {
            setError("")
        }
        try {
            const details = await calculatorActions.queryByPDFdownload(queryid)
            if (details?.success) {
                setQuery({ ...query, name: details?.data?.name, freightMode: details?.data?.freightMode, qtyMode:details?.data?.qtyMode, access:details?.data?.access, laycanStartDate:details?.data?.laycanStartDate, laycanEndDate:details?.data?.laycanEndDate, message: details?.data?.message })
                setPortOperations(details?.data?.portOperations?.filter(itm=>itm?.operation==="Loading"|| itm?.operation==="Discharging"))
                setFileUploading("File Uploaded Successfully!")
            } else {
                setError("invalid file! Please upload valid file")
            }
        } catch (err) {
            setError("Something went wrong!")
        }
    };
    const CustomSection = () => {
        return (
            <div class="upload-container" id="drop-area">
                <label for="fileElem">Upload or drop a file right here</label>
                {(!error) ? (fileUploading) ? <p>{fileUploading}</p> : <p>Drag & drop or click to select files</p> : ""}
                {error ? <p style={{ color: "red" }}>{error}</p> : ""}
            </div>
        )
    }
    const onClose = () => {
        handleClose()
        setError("")
        setFileUploading("")
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={() => onClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="font-family">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className="font-family">
                        <FileUploader dropMessageStyle={{ color: "#000", backgroundColor: 'red' }} children={<CustomSection />} multiple={false} handleChange={handleChange} name="file" label="Upload or drop a file right here" types={fileTypes} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()} color="primary" className="font-family" autoFocus>
                        {fileUploading ? "Confirm" : "Cancel"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}