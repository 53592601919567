import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as actions from "../../CargoCargoMatrix/actions";
import {
  Grid,
} from "@material-ui/core";

import { getCurrentUserOrganizationId, toastSuccess } from "../../../../utils";
import { termsCoEfficientTable } from "../../../../constants";
import CargoCargoPortOperations from "./CargoCargoPortOperations";
import ActionButtonsCargoCargo from "./ActionButtonsCargoCargo";
import CargoCargoResult from "./CargoCargoResult";
import moment from "moment";
import Expense from "./Expense";
import Revenue from "./Revenue";
import NetProfitResult from "./NetProfitResult";
import CustomAddModal from "../../../../components/Common/CustomAddModal";
import CargoDetails from "./CargoDetails";
import ShareWithOrg from "../../../../components/Common/ShareWithOrg";
import { addCargoCargoDetails } from "../../Orders/actions";

export default function CargoCargoMatrixMain(props) {

  const { setLoader = () => { }, setIsFullScreen = () => { }, } = props;
  const { id = "" } = useParams();
  const [calculatorData, setCalculatorData] = useState(null);
  const organizationId = getCurrentUserOrganizationId();
  const [openCargoDetails, setOpenCargoDetails] = useState(false);
  const [isCopyConfirm, setCopyConfirm] = useState(false);
  const [getSingleCargoData, setSingleCargoData] = useState("");
  const [shareLabel, setShareLabel] = useState("");



  const parseDate = (dateStr) => {
    const [date, time] = dateStr?.split(' ');
    const [month, day, year] = date?.split('/');
    return new Date(`20${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}T${time}:00`);
  };


  const calculateDifference = (arrivalTime, departureTime) => {
    const arrivalDate = parseDate(moment(arrivalTime).format("M/D/YY HH:mm"));
    const departureDate = parseDate(moment(departureTime).format("M/D/YY HH:mm"));
    const timeDiff = departureDate - arrivalDate;
    const diffInDays = timeDiff / (1000 * 60 * 60 * 24);
    return diffInDays.toFixed(9);
  };

  const structureChange = (currentdata) => {
    currentdata?.vesselVoyageData?.map((item) => {
      if (item?.arrivalTime && item?.departureTime) {
        item.portStay = calculateDifference(item?.arrivalTime, item?.departureTime)
      }
    })
    return currentdata
  }
  useEffect(() => {
    setIsFullScreen(true)
    if (id) {
      getInitialData(id)
    }
    return () => {
      setIsFullScreen(false);
    };
  }, []);
  const getInitialData = (id) => {
    setLoader(true, "Fetching data...");
    actions
      .getSingleCargoCargoDetails(id)
      .then((res) => {
        setLoader(false);
        const structureData = structureChange(res.data)
        setCalculatorData(structureData);
        calculate(structureData)
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  const calculate = (calculatordata) => {
    const currentdata = calculatordata
    currentdata.calculateRates = []

    const revenueKeys = [
      "laytimeAllowed",
      "demurrageAmt",
      "despatchAmt",
      "grossFreightAmt",
      "adcomAmt",
      "othersAmt",
      "brokerageAmt",
      "netFreightRevenue",
      "laytimeRevenue",
      "netRevenue"
    ];
    function calculateDemurrage(laytimeIncurred, laytimeAllowed, demurrageRate) {
      const difference = laytimeIncurred - laytimeAllowed;

      if (difference >= 0) {
        return difference * demurrageRate;
      }

      return 0;
    }
    function calculateDespatch(laytimeIncurred, laytimeAllowed, despatchRate) {
      if (laytimeIncurred - laytimeAllowed < 0) {
        return (laytimeAllowed - laytimeIncurred) * despatchRate;
      }
      return 0;
    }

    function calculateGrossFreightAmount(freightRate, actualCargoQty) {
      return freightRate * actualCargoQty;
    }
    function calculateAdcomAmount(grossFreightAmount, adcomRate) {
      return (grossFreightAmount * adcomRate) / 100;
    }
    function calculateOthersAmount(grossFreightAmount, otherTaxRate) {
      return (grossFreightAmount * otherTaxRate) / 100;
    }


    function calculateBrokerageAmount(grossFreightAmount, brokerageRate) {
      return (grossFreightAmount * brokerageRate) / 100;
    }

    function calculateNetFreightRevenue(grossFreightAmount, adcomAmount, othersAmount, brokerageAmount) {
      return grossFreightAmount - adcomAmount - othersAmount - brokerageAmount;
    }
    function calculateNetFreightExpense(grossFreightAmount, adcomAmount, othersAmount, brokerageAmount) {
      return grossFreightAmount - adcomAmount + othersAmount + brokerageAmount;
    }

    function calculateLaytimeRevenue(demurrageAmount, despatchAmount) {
      return demurrageAmount - despatchAmount;
    }

    function calculateNetRevenue(freightRevenue, laytimeRevenue, additionalRevenue, additionalExpense) {
      return freightRevenue + laytimeRevenue + additionalRevenue - additionalExpense;
    }

    function calculateProfitPerUnit(netProfit, actualCargoQty) {
      if (actualCargoQty === 0 || actualCargoQty === undefined || actualCargoQty === null) {
        return 0;
      }
      return netProfit / actualCargoQty;
    }


    for (let i = 0; i < revenueKeys?.length; i++) {
      let calculateRates = {}
      if (currentdata?.vesselVoyageData?.[i]?.actualCargoQty && currentdata?.customerCargo?.cargoList?.[i]?.rate) {
        calculateRates = { ...calculateRates, LaytimeAllowedRevenue: Number(currentdata?.vesselVoyageData[i]?.actualCargoQty || 0) / Number(currentdata?.customerCargo?.cargoList[i]?.rate) }
      }
      if (currentdata?.vesselVoyageData[i]?.actualCargoQty && currentdata?.vendorCargo?.cargoList[i]?.rate) {
        calculateRates = { ...calculateRates, LaytimeAllowedExpense: Number(currentdata?.vesselVoyageData[i]?.actualCargoQty || 0) / Number(currentdata?.vendorCargo?.cargoList[i]?.rate) }
      }
      if (calculateRates?.LaytimeAllowedRevenue || currentdata?.customerCargo?.cargoList[i]?.laytimeIncurred || currentdata?.customerCargo?.cargoList[i]?.demurrageRate) {
        calculateRates = {
          ...calculateRates, DemurrageAmtRevenue: calculateDemurrage(
            Number(currentdata?.customerCargo?.cargoList[i]?.laytimeIncurred || 0),
            Number(calculateRates?.LaytimeAllowedRevenue || 0),
            Number(currentdata?.customerCargo?.cargoList[i]?.demurrageRate || 0))
        }
      }
      if (calculateRates?.LaytimeAllowedExpense || currentdata?.vendorCargo?.cargoList[i]?.laytimeIncurred || currentdata?.vendorCargo?.cargoList[i]?.demurrageRate) {
        calculateRates = {
          ...calculateRates, DemurrageAmtExpense: calculateDemurrage(
            Number(currentdata?.vendorCargo?.cargoList[i]?.laytimeIncurred || 0),
            Number(calculateRates?.LaytimeAllowedExpense || 0),
            Number(currentdata?.vendorCargo?.cargoList[i]?.demurrageRate || 0))
        }
      }

      if (calculateRates?.LaytimeAllowedRevenue ||
        currentdata?.customerCargo?.cargoList[i]?.laytimeIncurred ||
        currentdata?.customerCargo?.cargoList[i]?.despatchRate) {
        calculateRates = {
          ...calculateRates, DespatchAmtRevenue:
            calculateDespatch(
              Number(currentdata?.customerCargo?.cargoList[i]?.laytimeIncurred || 0),
              Number(calculateRates?.LaytimeAllowedRevenue || 0),
              Number(currentdata?.customerCargo?.cargoList[i]?.despatchRate || 0))
        }
      }
      if (calculateRates?.LaytimeAllowedExpense ||
        currentdata?.vendorCargo?.cargoList[i]?.laytimeIncurred ||
        currentdata?.vendorCargo?.cargoList[i]?.despatchRate) {
        calculateRates = {
          ...calculateRates, DespatchAmtExpense: calculateDespatch(
            Number(currentdata?.vendorCargo?.cargoList[i]?.laytimeIncurred || 0),
            Number(calculateRates?.LaytimeAllowedExpense || 0),
            Number(currentdata?.vendorCargo?.cargoList[i]?.despatchRate || 0))
        }
      }
      if (currentdata?.vesselVoyageData?.[i]?.actualCargoQty || currentdata?.customerCargo?.cargoList[i]?.freight) {
        calculateRates = {
          ...calculateRates, grossFreightAmountRevenue: calculateGrossFreightAmount(
            Number(currentdata?.vesselVoyageData?.[i]?.actualCargoQty || 0),
            Number(currentdata?.customerCargo?.cargoList[i]?.freight || 0))
        }
      }

      if (currentdata?.vesselVoyageData?.[i]?.actualCargoQty || currentdata?.customerCargo?.cargoList[i]?.freight) {
        calculateRates = {
          ...calculateRates, grossFreightAmountExpense: calculateGrossFreightAmount(
            Number(currentdata?.vesselVoyageData?.[i]?.actualCargoQty || 0),
            Number(currentdata?.vendorCargo?.cargoList[i]?.freight || 0))
        }
      }

      if (calculateRates?.grossFreightAmountRevenue || currentdata?.customerCargo?.cargoList[i]?.adcom) {
        calculateRates = {
          ...calculateRates, adcomAmountRevenue: calculateAdcomAmount(
            Number(calculateRates?.grossFreightAmountRevenue || 0),
            Number(currentdata?.customerCargo?.cargoList[i]?.adcom || 0))
        }
      }

      if (calculateRates?.grossFreightAmountExpense || currentdata?.vendorCargo?.cargoList[i]?.adcom) {
        calculateRates = {
          ...calculateRates, adcomAmountExpense: calculateAdcomAmount(
            Number(calculateRates?.grossFreightAmountExpense || 0),
            Number(currentdata?.vendorCargo?.cargoList[i]?.adcom || 0))
        }
      }

      if (calculateRates?.grossFreightAmountRevenue || currentdata?.customerCargo?.cargoList[i]?.other) {
        calculateRates = {
          ...calculateRates, othersAmountRevenue: calculateOthersAmount(
            Number(calculateRates?.grossFreightAmountRevenue || 0),
            Number(currentdata?.customerCargo?.cargoList[i]?.other || 0))
        }
      }

      if (calculateRates?.grossFreightAmountExpense || currentdata?.vendorCargo?.cargoList[i]?.other) {
        calculateRates = {
          ...calculateRates, othersAmountExpense: calculateOthersAmount(
            Number(calculateRates?.grossFreightAmountExpense || 0),
            Number(currentdata?.vendorCargo?.cargoList[i]?.other || 0))
        }
      }

      if (calculateRates?.grossFreightAmountRevenue || currentdata?.customerCargo?.cargoList[i]?.brokerage) {
        calculateRates = {
          ...calculateRates, brokerageAmountRevenue: calculateBrokerageAmount(
            Number(calculateRates?.grossFreightAmountRevenue || 0),
            Number(currentdata?.customerCargo?.cargoList[i]?.brokerage || 0))
        }
      }

      if (calculateRates?.grossFreightAmountExpense || currentdata?.vendorCargo?.cargoList[i]?.brokerage) {
        calculateRates = {
          ...calculateRates, brokerageAmountExpense: calculateBrokerageAmount(
            Number(calculateRates?.grossFreightAmountExpense || 0),
            Number(currentdata?.vendorCargo?.cargoList[i]?.brokerage || 0))
        }
      }

      if (calculateRates?.grossFreightAmountRevenue || calculateRates?.adcomAmountRevenue || calculateRates?.othersAmountRevenue || calculateRates?.brokerageAmountRevenue) {
        calculateRates = {
          ...calculateRates, netFreightRevenue: calculateNetFreightRevenue(
            Number(calculateRates?.grossFreightAmountRevenue || 0),
            Number(calculateRates?.adcomAmountRevenue || 0),
            Number(calculateRates?.othersAmountRevenue || 0),
            Number(calculateRates?.brokerageAmountRevenue || 0))
        }
      }



      if (calculateRates?.grossFreightAmountExpense || calculateRates?.adcomAmountExpense || calculateRates?.othersAmountExpense || calculateRates?.brokerageAmountExpense) {
        calculateRates = {
          ...calculateRates, netFreightExpense: calculateNetFreightExpense(
            Number(calculateRates?.grossFreightAmountExpense || 0),
            Number(calculateRates?.adcomAmountExpense || 0),
            Number(calculateRates?.othersAmountExpense || 0),
            Number(calculateRates?.brokerageAmountExpense || 0))
        }
      }

      if (calculateRates?.netFreightRevenue || calculateRates?.netFreightExpense) {
        calculateRates = {
          ...calculateRates, totalNetFreight: Number(calculateRates?.netFreightRevenue || 0) - Number(calculateRates?.netFreightExpense || 0)
        }
      }


      if (calculateRates?.DemurrageAmtRevenue || calculateRates?.DespatchAmtRevenue) {
        calculateRates = {
          ...calculateRates,
          laytimeRevenueRevenue: calculateLaytimeRevenue(
            Number(calculateRates?.DemurrageAmtRevenue || 0),
            Number(calculateRates?.DespatchAmtRevenue || 0)
          )
        };
      }

      if (calculateRates?.DemurrageAmtExpense || calculateRates?.DespatchAmtExpense) {
        calculateRates = {
          ...calculateRates,
          laytimeRevenueExpense: calculateLaytimeRevenue(
            Number(calculateRates?.DemurrageAmtExpense || 0),
            Number(calculateRates?.DespatchAmtExpense || 0)
          )
        };
      }

      if (calculateRates?.laytimeRevenueRevenue || calculateRates?.laytimeRevenueExpense) {
        calculateRates = {
          ...calculateRates, totalLaytimeProfit: Number(calculateRates?.laytimeRevenueRevenue || 0) - Number(calculateRates?.laytimeRevenueExpense || 0)
        }
      }

      if (
        calculateRates?.netFreightRevenue ||
        calculateRates?.laytimeRevenueRevenue ||
        currentdata?.customerCargo?.cargoList[i]?.additionalRevenue ||
        currentdata?.customerCargo?.cargoList[i]?.additionalExpense
      ) {

        calculateRates = {
          ...calculateRates,
          netRevenue: calculateNetRevenue(
            Number(calculateRates?.netFreightRevenue || 0),
            Number(calculateRates?.laytimeRevenueRevenue || 0),
            Number(currentdata?.customerCargo?.cargoList[i]?.additionalRevenue || 0),
            Number(currentdata?.customerCargo?.cargoList[i]?.additionalExpense || 0)
          )
        };
      }



      if (
        calculateRates?.netFreightExpense ||
        calculateRates?.laytimeRevenueExpense ||
        currentdata?.vendorCargo?.cargoList[i]?.additionalRevenue ||
        currentdata?.vendorCargo?.cargoList[i]?.additionalExpense
      ) {

        calculateRates = {
          ...calculateRates,
          netExpense: calculateNetRevenue(
            Number(calculateRates?.netFreightExpense || 0),
            Number(calculateRates?.laytimeRevenueExpense || 0),
            Number(currentdata?.vendorCargo?.cargoList[i]?.additionalRevenue || 0),
            Number(currentdata?.vendorCargo?.cargoList[i]?.additionalExpense || 0)
          )
        };
      }

      if (calculateRates?.totalNetFreight || calculateRates?.totalLaytimeProfit) {
        calculateRates = {
          ...calculateRates, netProfit: Number(calculateRates?.totalNetFreight) + Number(calculateRates?.totalLaytimeProfit)
        }
      }

      if (calculateRates?.netProfit) {
        calculateRates = {
          ...calculateRates, profitPerUnit: calculateProfitPerUnit(calculateRates?.netProfit, currentdata?.vesselVoyageData?.[i]?.actualCargoQty)
        }
      }

      if (Object.keys(calculateRates).length > 0) {
        currentdata.calculateRates.push(calculateRates);
      }
    }

    const totalCalculatedRates = {
      totalDemurrageAmtExpense: 0,
      totalDemurrageAmtRevenue: 0,
      totalDespatchAmtExpense: 0,
      totalDespatchAmtRevenue: 0,
      totalGrossFreightAmountExpense: 0,
      totalGrossFreightAmountRevenue: 0,
      totalAdcomAmountExpense: 0,
      totalAdcomAmountRevenue: 0,
      totalOthersAmountExpense: 0,
      totalOthersAmountRevenue: 0,
      totalBrokerageAmountExpense: 0,
      totalBrokerageAmountRevenue: 0,
      totalNetFreightRevenue: 0,
      totalNetFreightExpense: 0,
      totalLaytimeRevenueRevenue: 0,
      totalLaytimeRevenueExpense: 0,
      totalNetRevenue: 0,
      totalNetExpense: 0,
      totalNetFreight: 0,
      totalLaytimeProfit: 0,
      netProfit: 0


    };
    currentdata.calculateRates.forEach(rate => {
      totalCalculatedRates.totalDemurrageAmtExpense += rate.DemurrageAmtExpense || 0;
      totalCalculatedRates.totalDemurrageAmtRevenue += rate.DemurrageAmtRevenue || 0;
      totalCalculatedRates.totalDespatchAmtExpense += rate.DespatchAmtExpense || 0;
      totalCalculatedRates.totalDespatchAmtRevenue += rate.DespatchAmtRevenue || 0;
      totalCalculatedRates.totalGrossFreightAmountExpense += rate.grossFreightAmountExpense || 0;
      totalCalculatedRates.totalGrossFreightAmountRevenue += rate.grossFreightAmountRevenue || 0;
      totalCalculatedRates.totalAdcomAmountExpense += rate.adcomAmountExpense || 0;
      totalCalculatedRates.totalAdcomAmountRevenue += rate.adcomAmountRevenue || 0;
      totalCalculatedRates.totalOthersAmountExpense += rate.othersAmountExpense || 0;
      totalCalculatedRates.totalOthersAmountRevenue += rate.othersAmountRevenue || 0;
      totalCalculatedRates.totalBrokerageAmountExpense += rate.brokerageAmountExpense || 0;
      totalCalculatedRates.totalBrokerageAmountRevenue += rate.brokerageAmountRevenue || 0;
      totalCalculatedRates.totalNetFreightRevenue += rate.netFreightRevenue || 0;
      totalCalculatedRates.totalNetFreightExpense += rate.netFreightExpense || 0;
      totalCalculatedRates.totalLaytimeRevenueRevenue += rate.laytimeRevenueRevenue || 0;
      totalCalculatedRates.totalLaytimeRevenueExpense += rate.laytimeRevenueExpense || 0;
      totalCalculatedRates.totalNetRevenue += rate.netRevenue || 0;
      totalCalculatedRates.totalNetExpense += rate.netExpense || 0;
      totalCalculatedRates.totalNetFreight += rate.totalNetFreight || 0;
      totalCalculatedRates.totalLaytimeProfit += rate.totalLaytimeProfit || 0;
      totalCalculatedRates.netProfit += rate.netProfit || 0;

    });
    currentdata.totalCalculatedRates = totalCalculatedRates
    setCalculatorData(currentdata)
  }


  const sendCalculationRequest = async () => {
    await actions
      .updateCargoCargoDetails(calculatorData?._id, { ...calculatorData, organizationId })
      .then((res) => {
        toastSuccess("Details updated successfully");
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };


  const onChangeRevenueOperations = (index, e, isDate = false, datename) => {
    let value = ""
    let name = ""
    if (isDate) {
      value = e;
      name = datename
    }
    else {
      value = e.target?.value
      name = e.target?.name
    }
    let customerCargoList = [...calculatorData.customerCargo?.cargoList];

    if (name === "terms") {
      const termFactor = termsCoEfficientTable.find(
        (item) => item.label === value
      );
      customerCargoList[index]["terms"] = value;
      customerCargoList[index]["factor"] = (termFactor && termFactor.value) || 0;
    } else {
      customerCargoList[index][name] = value;
    }
    if (calculatorData?.freightModeRevenue === "Single Freight") {
      if (name === "freight") {
        customerCargoList?.forEach(item => {
          if (item?.operation?.toLowerCase() === "loading") {
            item.freight = value;
          }
        });
      } else if (name === "adcom") {
        customerCargoList?.forEach(item => {
          if (item?.operation?.toLowerCase() === "loading") {
            item.adcom = value;
          }
        });
      } else if (name === "other") {
        customerCargoList?.forEach(item => {
          if (item?.operation?.toLowerCase() === "loading") {
            item.other = value;
          }
        });
      } else if (name === "brokerage") {
        customerCargoList?.forEach(item => {
          if (item?.operation?.toLowerCase() === "loading") {
            item.brokerage = value;
          }
        });
      }
    }
    let updatedCalculatorData = {
      ...calculatorData, customerCargo: { ...calculatorData.customerCargo, cargoList: customerCargoList }
    };
    setCalculatorData(updatedCalculatorData);
    calculate(updatedCalculatorData)
  };

  const onChangeExpenseOperations = (index, e, isDate = false, datename) => {
    let value = ""
    let name = ""
    if (isDate) {
      value = e;
      name = datename
    }
    else {
      value = e.target?.value
      name = e.target?.name
    }
    let customerCargoList = [...calculatorData.vendorCargo?.cargoList];

    if (name === "terms") {
      const termFactor = termsCoEfficientTable.find(
        (item) => item.label === value
      );
      customerCargoList[index]["terms"] = value;
      customerCargoList[index]["factor"] = (termFactor && termFactor.value) || 0;
    } else {
      customerCargoList[index][name] = value;
    }

    if (calculatorData?.freightModeExpense === "Single Freight") {
      if (name === "freight") {
        customerCargoList?.forEach(item => {
          if (item?.operation?.toLowerCase() === "loading") {
            item.freight = value;
          }
        });
      } else if (name === "adcom") {
        customerCargoList?.forEach(item => {
          if (item?.operation?.toLowerCase() === "loading") {
            item.adcom = value;
          }
        });
      } else if (name === "other") {
        customerCargoList?.forEach(item => {
          if (item?.operation?.toLowerCase() === "loading") {
            item.other = value;
          }
        });
      } else if (name === "brokerage") {
        customerCargoList?.forEach(item => {
          if (item?.operation?.toLowerCase() === "loading") {
            item.brokerage = value;
          }
        });
      }
    }
    let updatedCalculatorData = {
      ...calculatorData, vendorCargo: { ...calculatorData.vendorCargo, cargoList: customerCargoList }
    };
    setCalculatorData(updatedCalculatorData);
    calculate(updatedCalculatorData)
  };

  const onChangePortOperations = (index, e, isDate = false, datename) => {
    let value = ""
    let name = ""
    if (isDate) {
      value = e;
      name = datename
    }
    else {
      value = e.target?.value
      name = e.target?.name
    }
    let vesselVoyageData = [...calculatorData.vesselVoyageData];

    if (name === "terms") {
      const termFactor = termsCoEfficientTable.find(
        (item) => item.label === value
      );
      vesselVoyageData[index]["terms"] = value;
      vesselVoyageData[index]["factor"] = (termFactor && termFactor.value) || 0;
    } else {
      vesselVoyageData[index][name] = value;
    }
    if (datename === "departureTime" || datename === "arrivalTime") {
      if (vesselVoyageData[index]?.departureTime && vesselVoyageData[index]?.arrivalTime) {
        vesselVoyageData[index].portStay = calculateDifference(vesselVoyageData[index]?.arrivalTime, vesselVoyageData[index]?.departureTime)
      }
    }
    let updatedCalculatorData = { ...calculatorData, vesselVoyageData };
    setCalculatorData(updatedCalculatorData);
    calculate(updatedCalculatorData)
  };


  const onChangeFields = (e, isDate = false, datename) => {
    let value = ""
    let name = ""
    if (isDate) {
      value = e;
      name = datename
    }
    else {
      value = e.target?.value
      name = e.target?.name
    }

    let calculatorDetailsData = { ...calculatorData };
    if (name === "freightModeRevenue") {
      if (value === "Single Freight") {
        let firstLoadPort = {}; // reset values to first loading port

        const cargoListRevenue = calculatorDetailsData?.customerCargo?.cargoList?.map((item, index) => {
          if (
            !Object.keys(firstLoadPort).length &&
            item.operation === "Loading"
          )
            firstLoadPort = {
              freight: item.freight,
              adcom: item.adcom,
              other: item.other,
              brokerage: item.brokerage,
            };
          if (index !== 0 && item.operation === "Loading") {
            return {
              ...item,
              ...firstLoadPort,
            };
          }
          return item;
        })
        calculatorDetailsData.customerCargo.cargoList = cargoListRevenue
      } else if (value === "Multi Freight") {

      }
    }
    if (name === "freightModeExpense") {
      if (value === "Single Freight") {
        let firstLoadPort = {}; // reset values to first loading port

        const cargoListRevenue = calculatorDetailsData?.vendorCargo?.cargoList?.map((item, index) => {
          if (
            !Object.keys(firstLoadPort).length &&
            item.operation === "Loading"
          )
            firstLoadPort = {
              freight: item.freight,
              adcom: item.adcom,
              other: item.other,
              brokerage: item.brokerage,
            };
          if (index !== 0 && item.operation === "Loading") {
            return {
              ...item,
              ...firstLoadPort,
            };
          }
          return item;
        })
        calculatorDetailsData.vendorCargo.cargoList = cargoListRevenue
      } else if (value === "Multi Freight") {
      }

    }
    calculatorDetailsData[name] = value;
    setCalculatorData(calculatorDetailsData);
    calculate(calculatorDetailsData)


  };

  const onChangeModelFields = (e, isDate = false, datename, cargocargo = "customerCargo") => {
    let value = ""
    let name = ""
    if (isDate) {
      value = e;
      name = datename
    }
    else {
      value = e.target?.value
      name = e.target?.name
    }
    let calculatorDetailsData = { ...calculatorData?.[cargocargo] };
    calculatorDetailsData[name] = value;
    let calculatorDataDetails = { ...calculatorData }
    calculatorDataDetails[cargocargo] = calculatorDetailsData
    setCalculatorData(calculatorDataDetails);

  };
  const onClickCopyToMyCargo = () => {
    setCopyConfirm(!isCopyConfirm)
    setSingleCargoData(calculatorData ? calculatorData : "")
    setShareLabel("")

  }
  const onCopySaveClick = async () => {
    if (!shareLabel || !getSingleCargoData || !organizationId) return
    let { _id, ...datamain } = getSingleCargoData
    datamain.access = "private"
    datamain.organizationId = organizationId
    datamain.cargoCargoName = shareLabel
    await addCargoCargoDetails(datamain)
    setCopyConfirm(false)
    setShareLabel("")
  }

  return (
    <Grid container className="calculator-section pl-2 pr-2">
      <Grid item xs={12}>
        <Grid >
          <Grid item xs={12}>

            <div className="quick-menu-bar d-flex justify-end">
              <ActionButtonsCargoCargo
                onClickCalculateAndSave={sendCalculationRequest}
                calculatorData={calculatorData}
                onClickCopyToMyCargo={onClickCopyToMyCargo}
              />
            </div>
            <div className="operation-input-div pb-2">
              <span>Performing Vessel : </span>
              <input
                type="text"
                value={calculatorData?.performingVessel}
                name="performingVessel"
                placeholder=""
                onChange={(e) => onChangeFields(e)}
              />
            </div>
          </Grid>
          <div className="middle-section-grid d-flex">
            <Grid container alignItems="flex-end">

              <Grid item xs={10} className="pr-1">
                <CargoCargoPortOperations
                  calculatorData={calculatorData}
                  setCalculatorData={setCalculatorData}
                  onChangePortOperations={onChangePortOperations}
                />

              </Grid>
              <Grid item xs={2}>

                {calculatorData && <NetProfitResult
                  label="Net Profit Result"
                  // calculationErrors={calculationErrors}
                  calculatorData={calculatorData}
                />}
              </Grid>
            </Grid>
          </div>
          <div className="pt-2 pb-2">
            <Grid item xs={12}>
              <div className="pb-1">
                <span style={{ fontSize: "16px", fontWeight: 500 }}>Revenue Side : </span>

              </div>
              <div className="d-flex">
                <div className="operation-input-div cargo-cargo-div pb-1" >
                  <span>Freight Mode:   </span>
                  <select
                    id="freightModeRevenue"
                    value={calculatorData?.freightModeRevenue}
                    name="freightModeRevenue"
                    onChange={(e) => onChangeFields(e)}
                  >
                    <option
                      key={0}
                      value="Single Freight"
                    >
                      Single Freight
                    </option>
                    <option
                      key={1}
                      value="Multiple Freight"
                    >
                      Multiple Freight
                    </option>
                  </select>
                </div>
                <div className="operation-input-div cargo-cargo-div pb-1 pl-2 underline" onClick={() => setOpenCargoDetails("customerCargo")} >
                  Cargo Details
                </div>
              </div>
            </Grid>
            <div className="middle-section-grid d-flex">

              <Grid item xs={10} className="pr-1">
                <Revenue
                  calculatorData={calculatorData}
                  setCalculatorData={setCalculatorData}
                  onChangeRevenueOperations={onChangeRevenueOperations}
                  freightMode={calculatorData?.freightModeRevenue}
                />

              </Grid>
              <Grid item xs={2}>
                {calculatorData && <CargoCargoResult
                  label="Customer Side (Revenue):"
                  isCustomerSide
                  calculatorData={calculatorData}
                // calculationErrors={calculationErrors}
                />}
              </Grid>
            </div>

          </div>
          <div className="pt-2 pb-2">
            <Grid item xs={12}>
              <div className="pb-1">
                <span style={{ fontSize: "16px", fontWeight: 500 }}>Expense Side : </span>

              </div>
              <div className="d-flex">
                <div className="operation-input-div cargo-cargo-div pb-1" >
                  <span>Freight Mode:   </span>

                  <select
                    value={calculatorData?.freightModeExpense}
                    name="freightModeExpense"
                    onChange={(e) => onChangeFields(e)}
                  >
                    <option
                      key={0}
                      value="Single Freight"
                    >
                      Single Freight
                    </option>
                    <option
                      key={0}
                      value="Multiple Freight"
                    >
                      Multiple Freight
                    </option>
                  </select>
                </div>
                <div className="operation-input-div cargo-cargo-div pb-1 pl-2 underline" onClick={() => setOpenCargoDetails("vendorCargo")} >
                  Cargo Details
                </div>
              </div>
            </Grid>
            <div className="middle-section-grid d-flex">

              <Grid item xs={10} className="pr-1">
                <Expense
                  calculatorData={calculatorData}
                  setCalculatorData={setCalculatorData}
                  onChangeExpenseOperations={onChangeExpenseOperations}
                  freightMode={calculatorData?.freightModeExpense}

                />
              </Grid>
              <Grid item xs={2}>

                {calculatorData && <CargoCargoResult
                  label="Vendor Side (Expense)"
                  calculatorData={calculatorData}
                />}
              </Grid>
            </div>

          </div>

          <CustomAddModal
            open={openCargoDetails}
            handleClose={() => setOpenCargoDetails(false)}
            maxWidth=""
            title={`${openCargoDetails === "customerCargo" ? "Revenue side" : "Vendor side"} cargo details`}
            saveLabel={"Update"}
            onSubmitConfirm={() => setOpenCargoDetails(false)}
          >
            <CargoDetails
              calculatorData={calculatorData}
              onChangeHandler={onChangeFields}
              onChangeModelFields={onChangeModelFields}
              cargoCargo={openCargoDetails}
            />
          </CustomAddModal>

          <CustomAddModal
            open={isCopyConfirm}
            handleClose={onClickCopyToMyCargo}
            onSubmitConfirm={onCopySaveClick}
            maxWidth="xs"
            saveLabel="Create"
            title="Copy to My Cargo"
          >
            <ShareWithOrg
              value={shareLabel}
              onChange={(e) => setShareLabel(e.target.value)}
              placeholder="Enter CargoCargo name*"
            />
          </CustomAddModal>
        </Grid>
      </Grid>
    </Grid>
  );
}
