import {
  usersForOrgAPI,
  createUserAPI,
  allRolesAPI,
  deleteUserAPI,
  usersWithIdAPI,
  getUserRolesAPI,
  updateUserAPI,
  getAllRolesAPI,
  getFleetByOrgAPI,
  allVesselsAPI,
  createVesselAPI,
  getAllOrganizationsAPI,
  allVesselsByCharAPI,
  updateVesselWithFleetDetailsAPI,
  markFleetAPI,
  createOrganizationAPI,
  updateOrganizationAPI,
  deleteOrganizationAPI,
  getAllAdminUsersAPI,
  updateActiveUserAPI,
  updateOrganizationStatusAPI,
} from "./api";
import * as actionTypes from "./actionsTypes";

export const usersForOrg = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USERS_ORGANIZATION,
    });
    return new Promise((resolve, reject) => {
      usersForOrgAPI(data)
        .then((res) => {
          dispatch({
            type: actionTypes.USERS_ORGANIZATION_SUCCESS,
            payload: res,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.USERS_ORGANIZATION_FAILED,
            payload: [],
          });
          reject(err);
        });
    });
  };
};

export const allAdminUsers = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ALL_ADMIN_USERS,
    });
    return new Promise((resolve, reject) => {
      getAllAdminUsersAPI(data)
        .then((res) => {
          dispatch({
            type: actionTypes.ALL_ADMIN_USERS_SUCCESS,
            payload: res,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.ALL_ADMIN_USERS_FAILED,
          });
          reject(err);
        });
    });
  };
};

export const allRoles = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.All_ROLES,
    });
    return new Promise((resolve, reject) => {
      getAllRolesAPI(data)
        .then((res) => {
          dispatch({
            type: actionTypes.All_ROLES_SUCCESS,
            payload: res,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.All_ROLES_FAILED,
          });
          reject(err);
        });
    });
  };
};

export const allVessels = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ALL_VESSELS,
    });
    return new Promise((resolve, reject) => {
      allVesselsAPI(data)
        .then((res) => {
          dispatch({
            type: actionTypes.ALL_VESSELS_SUCCESS,
            payload: res,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.ALL_VESSELS_FAILED,
          });
          reject(err);
        });
    });
  };
};

export const allVesselsByChar = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ALL_VESSELS,
    });
    return new Promise((resolve, reject) => {
      allVesselsByCharAPI(data)
        .then((res) => {
          dispatch({
            type: actionTypes.ALL_VESSELS_SUCCESS,
            payload: res,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.ALL_VESSELS_FAILED,
          });
          reject(err);
        });
    });
  };
};

export const getFleetByOrg = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ALL_FLEETS_BY_ORG,
    });
    return new Promise((resolve, reject) => {
      getFleetByOrgAPI(data)
        .then((res) => {
          dispatch({
            type: actionTypes.ALL_FLEETS_BY_ORG_SUCCESS,
            payload: res,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.ALL_FLEETS_BY_ORG_FAILED,
          });
          reject(err);
        });
    });
  };
};

export const getAllOrganizations = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ALL_ORGANIZATION,
    });
    return new Promise((resolve, reject) => {
      getAllOrganizationsAPI(data)
        .then((res) => {
          dispatch({
            type: actionTypes.GET_ALL_ORGANIZATION_SUCCESS,
            payload: res,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.GET_ALL_ORGANIZATION_FAILED,
          });
          // reject(err?.data?.message);
        });
    });
  };
};

export const clearDashbaordStore = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EMPTY_DASHBOARD_STORE,
    });
  };
};

export const createUser = (data) => {
  return new Promise((resolve, reject) => {
    createUserAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteUser = (data) => {
  return new Promise((resolve, reject) => {
    deleteUserAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateActiveUser = (data) => {
  return new Promise((resolve, reject) => {
    updateActiveUserAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const usersWithId = (data) => {
  return new Promise((resolve, reject) => {
    usersWithIdAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserRoles = (data) => {
  return new Promise((resolve, reject) => {
    getUserRolesAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateUser = (data) => {
  return new Promise((resolve, reject) => {
    updateUserAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createVessel = (data) => {
  return new Promise((resolve, reject) => {
    createVesselAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateVesselWithFleetDetails = (data) => {
  return new Promise((resolve, reject) => {
    updateVesselWithFleetDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const markFleet = (data) => {
  return new Promise((resolve, reject) => {
    markFleetAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createOrganization = (data) => {
  return new Promise((resolve, reject) => {
    createOrganizationAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateOrganization = (data) => {
  return new Promise((resolve, reject) => {
    updateOrganizationAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteOrganization = (data) => {
  return new Promise((resolve, reject) => {
    deleteOrganizationAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateOrganizationStatus = (data) => {
  return new Promise((resolve, reject) => {
    updateOrganizationStatusAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
