import React, {
    useState,
    forwardRef,
    useImperativeHandle,
    useEffect,
    Fragment,
} from "react";
import {
    Grid,
    Box,
    Select,
    MenuItem,
    TableContainer,
    Table,
    Paper,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    makeStyles,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Button,
} from "@material-ui/core";
import CustomTextField from "../../../components/Common/CustomTextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
    addQueryInitialData,
    cargoFuelPriceStep,
    expenseResultField,
    fuelPriceOptions,
    loadlineOptions,
    portEmissionOptions,
    queryPortDisabledFields,
    termsCoEfficientTable,
} from "../../../constants";
import DeleteIcon from "@material-ui/icons/Delete";
import * as actions from "./actions";
import {
    getCurrentUserOrganizationId,
    toastError,
    toastSuccess,
} from "../../../utils";
import moment from "moment";
import { useDispatch } from "react-redux";
import CustomShowModal from "../../../components/Common/CustomShowModal";
import AddPortQueryModal from "../../../components/Orders/AddPortQueryModal";
import * as commonActions from "../../Common/actions";
import { validateNewQuery } from "./addQueryValidation";
import { setCopyPortOperation, setCopyQuery } from "./dataMapping";
import DownloadPDF from "../../../components/Common/DowloadPDF";
import CargoPdf from "../../../components/Calculator/CargoPdf";
import { Document, PDFViewer, Page } from "@react-pdf/renderer";
import { handleKeyDown } from "../../../common/common";
import CustomButton from "../../../components/Common/CustomButton";
import UploadPDFModal from "../../../components/Common/UploadPDF";
import { GrDocumentPdf } from "react-icons/gr";
import { useParams } from "react-router-dom";
import Expenses from "../../../components/Calculator/Expenses";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
        background: "#fff"
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Fuel Prices', 'Revenue & expenses', "Extra Cargo details"];
}

const CreateNewCargo = forwardRef((props, ref) => {
    const {
        setLoader = () => { },
        onCloseModal = () => { },
        selectedQuery = {},
        selectedPorts = [],
        selectedPortsCommence = [],
        isEdit = false,
        // isDetailView = false,
        onChangePinQuery = () => { },
        isFromSingleVesselCalcy = false,
        getInitialData = () => { },
        setIsEdit

    } = props;
    const organizationId = getCurrentUserOrganizationId();

    const addPortInitialData = [
        {
            cargoId: "",
            operation: "Loading",
            port: "",
            cargoQty: 0,
            tolerance: 0,
            rate: 0,
            terms: "SHINC",
            turnTime: 0,
            pdaRemarks: "",
            grade: "",
            freight: 0,
            adcom: 0,
            other: 0,
            brokerage: 0,
            ttlComm: 0,
            netFrt: 0,
            min: 0,
            max: 0,
            account: "",
            cargo: "",
            factor: 1,
            stowFactor: 0,
            queryId: "",
            loadline: "S-Summer",
            sulfur: "Normal",
        },
        {
            cargoId: "",
            operation: "Discharging",
            port: "",
            cargoQty: 0,
            tolerance: 0,
            rate: 0,
            terms: "SHINC",
            turnTime: 0,
            pdaRemarks: "",
            grade: "",
            freight: 0,
            adcom: 0,
            other: 0,
            brokerage: 0,
            ttlComm: 0,
            netFrt: 0,
            min: 0,
            max: 0,
            account: "",
            cargo: "",
            factor: 1,
            stowFactor: 0,
            queryId: "",
            loadline: "S-Summer",
            sulfur: "Normal",
        },
    ];

    const dispatch = useDispatch();

    const [portOperations, setPortOperations] = useState([...addPortInitialData]);
    const [query, setQuery] = useState({ ...addQueryInitialData });
    const [portModal, setPortModal] = useState(false);
    const [selectedPort, setSelectedPort] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [getOpenUploadPDF, setOpenUploadPDF] = useState(false);
    const { id = "" } = useParams();
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    useEffect(() => {
        if (Object.keys(selectedQuery).length) {
            setQuery(selectedQuery);
        }
        if (Array.isArray(selectedPorts) && selectedPorts.length) {
            setPortOperations(selectedPorts);
        }
        return () => {
            resetStateVariables();
        };
    }, [selectedQuery, selectedPorts]);

    useEffect(() => {
        const { errors } = validateNewQuery(query, portOperations);
        dispatch(commonActions.addOnlyErrors({ errorList: errors }));
    }, [query, portOperations]);
    const resetStateVariables = () => {
        setPortOperations([...addPortInitialData]);
        setQuery({ ...addQueryInitialData });
        // setStartDate(null)
        // setEndDate(null)
        setPortModal(false);
        setSelectedPort({});
        setSelectedIndex(0);
    };

    const onOpenErrorAlert = (errors = []) => {
        dispatch(commonActions.openErrorAlert({ errorList: errors }));
    };

    // useImperativeHandle(ref, () => ({
    const onSubmitQuery = async (isCopy = false) => {
        const { errors, isValid } = validateNewQuery(query, portOperations);
        if (!isValid) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1)
            return onOpenErrorAlert(errors);
        }
        setLoader(true);
        const finalPortOperations = [...selectedPortsCommence, ...portOperations]


        let newQuery = {
            ...query,
            isAllStepClear: true,
            isShowInCalculator: true,
            laycanStartDate: moment(query.laycanStartDate).toISOString(),
            laycanEndDate: moment(query.laycanEndDate).toISOString(),
        };
        let newPortOperation = [];
        if (isCopy) {
            newQuery = setCopyQuery(newQuery);
            newPortOperation = setCopyPortOperation(finalPortOperations);
        } else {
            newPortOperation = finalPortOperations;
        }
        newQuery.cargoList = newPortOperation;

        // actions
        //     .addClientQuery(newQuery)
        //     .then((res) => {
        //         toastSuccess("Query added successfully");
        //         onCloseModal();
        //         Promise.all([
        //             !!organizationId ? dispatch(actions.clientQueryOrg()) : null,
        //             dispatch(actions.clientQueryUser()),
        //             dispatch(actions.getQueryFormatForUser()),
        //         ]);
        //         setLoader(false);
        //     })
        //     .catch(() => {
        //         setLoader(false);
        //     });
        const formData = new FormData();
        formData.append('QueryData', JSON.stringify(newQuery) || '');

        await actions
            .updateClientQuery(formData)
            .then((res) => {
                toastSuccess("Query updated successfully");
                onCloseModal();
                Promise.all([
                    !!organizationId ? dispatch(actions.clientQueryOrg()) : null,
                    dispatch(actions.clientQueryUser()),
                    dispatch(actions.getQueryFormatForUser()),
                ]);
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            });
    }
    // }));

    const onChangeHandler = (e) => {
        // if (isDetailView) return
        const { value = "", name = "" } = e.target || {};
        if (name === "freightMode") {
            if (value === "Single Freight") {
                let firstLoadPort = {}; // reset values to first loading port
                setPortOperations(
                    portOperations.map((item, index) => {
                        if (
                            !Object.keys(firstLoadPort).length &&
                            item.operation === "Loading"
                        )
                            firstLoadPort = {
                                freight: item.freight,
                                adcom: item.adcom,
                                other: item.other,
                                brokerage: item.brokerage,
                            };
                        if (index !== 0 && item.operation === "Loading") {
                            return {
                                ...item,
                                ...firstLoadPort,
                            };
                        }
                        return item;
                    })
                );
            }
        }
        setQuery({ ...query, [name]: value });
    };

    const onChangePortOperation = (index, e) => {
        // if (isDetailView) return
        const { name = "", value = "", type = "" } = e.target;
        let updatedPortOperation = [...portOperations];
        updatedPortOperation[index][name] =
            type === "number" ? Number(value) : value;
        if (name === "terms") {
            const findFactor = termsCoEfficientTable.find(
                (item) => item.label === value
            );
            if (findFactor) {
                updatedPortOperation[index]["factor"] = findFactor.value;
            }
        }
        setPortOperations(updatedPortOperation);
    };

    const onAddPortOperations = () => {
        // if (isDetailView) return
        setPortOperations([...portOperations, { ...addPortInitialData[1] }]);
    };

    const onDeletePortOperations = (index) => {
        // if (isDetailView) return
        let updatedOperation = [...portOperations];
        updatedOperation.splice(index, 1);
        setPortOperations(updatedOperation);
    };

    const onSelectPortsOfOperations = (port, index) => {
        setSelectedPort(port);
        setSelectedIndex(index);
        setPortModal(true);
    };

    const onSavePortOperationsOfQuery = (port, val) => {
        let updatedPortOperation = [...portOperations];
        updatedPortOperation[selectedIndex]["port"] = port;
        updatedPortOperation[selectedIndex]["lat"] = val?.lat;
        updatedPortOperation[selectedIndex]["lng"] = val?.lng;

        setPortOperations(updatedPortOperation);
        onClosePortModal();
    };

    const onClosePortModal = () => {
        setPortModal(false);
        setSelectedPort({});
        setSelectedIndex(0);
    };

    const onChangeDate = (type, date) => {
        // if (isDetailView) return
        setQuery({ ...query, [type]: date });
    };

    const {
        access = "",
        freightMode = "",
        message = "",
        name = "",
        qtyMode = "",
        laycanStartDate = null,
        laycanEndDate = null,

        fuelCalculationMethod = "",
        fuelType = "TYPE0",
        lsFuelPriceType0 = 0,
        lsFuelPriceType1 = 0,
        lsFuelPriceType2 = 0,
        lsFuelPriceType3 = 0,
        lsFuelPriceType4 = 0,
        hsFuelPriceType0 = 0,
        hsFuelPriceType1 = 0,
        hsFuelPriceType2 = 0,
        hsFuelPriceType3 = 0,
        hsFuelPriceType4 = 0,
        mgoFuelPriceType0 = 0,
        mgoFuelPriceType1 = 0,
        mgoFuelPriceType2 = 0,
        mgoFuelPriceType3 = 0,
        mgoFuelPriceType4 = 0,

        freightIdea = 0,
        miscRevenue = 0,
    } = query || {};
    let fuelTypePrices = {
        TYPE0: {
            normal: hsFuelPriceType0 || 0,
            eca: lsFuelPriceType0 || 0,
            mgo: mgoFuelPriceType0 || 0,
        },
        TYPE1: {
            normal: hsFuelPriceType1 || 0,
            eca: lsFuelPriceType1 || 0,
            mgo: mgoFuelPriceType1 || 0,
        },
        TYPE2: {
            normal: hsFuelPriceType2 || 0,
            eca: lsFuelPriceType2 || 0,
            mgo: mgoFuelPriceType2 || 0,
        },
        TYPE3: {
            normal: hsFuelPriceType3 || 0,
            eca: lsFuelPriceType3 || 0,
            mgo: mgoFuelPriceType3 || 0,
        },
        TYPE4: {
            normal: hsFuelPriceType4 || 0,
            eca: lsFuelPriceType4 || 0,
            mgo: mgoFuelPriceType4 || 0,
        },
    };
    let showMinMax = false;
    if (qtyMode === "MINMAX") showMinMax = true;

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === 2) {
            onSubmitQuery()
        }
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    const onChangeExpenses = (e, fuelType) => {
        const { value = 0, name = "" } = e.target || {};

        setQuery({ ...query, [name]: Number(value) });

    }
    const onChangeFuelPrice = (e, fuelType) => {
        const { name = "", value = "" } = e.target;

        let newClientQuery = { ...query };
        const currentType = fuelType.replace("TYPE", "");
        if (name === "normal") {
            newClientQuery[`hsFuelPriceType${currentType}`] = parseFloat(value);
        }
        if (name === "eca") {
            newClientQuery[`lsFuelPriceType${currentType}`] = parseFloat(value);
        }
        if (name === "mgo") {
            newClientQuery[`mgoFuelPriceType${currentType}`] = parseFloat(value);
        }
        setQuery(newClientQuery)

    };
    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <Grid><Grid container >
                    <Grid item sm={1} style={{ fontSize: "14px" }}>
                    </Grid>
                    <Grid item sm={5} xs={12} style={{ fontSize: "14px" }}>
                        <p className="mt-4 m-l-5 m-b-5" style={{ fontSize: "15px" }}>Notes: </p>
                        {cargoFuelPriceStep?.notes?.map((itm) => { return (<p>{itm}</p>) })}
                    </Grid>
                    <Grid item sm={5} xs={12} style={{ fontSize: "14px" }}>
                        <p className="mt-6 m-l-5 m-b-5" style={{ fontSize: "15px" }}>Fuel Price</p>
                        <div className="consumptions-headers" >
                            <p></p>
                            <p style={{ fontSize: "14px" }}>Normal</p>
                            <p style={{ fontSize: "14px" }}>ECA</p>
                            <p style={{ fontSize: "14px" }}>MGO</p>
                        </div>
                        {fuelPriceOptions.map((item, i) => {
                            return <div className="consumptions-content">
                                <div className="consumptions-headers" style={{ width: "25%" }}>
                                    <p style={{ fontWeight: "500", width: "100%", display: "flex", justifyContent: "center", fontSize: "14px" }}>{item.label}</p>
                                </div>
                                <input
                                    type="number"
                                    onKeyDown={handleKeyDown}
                                    placeholder="0"
                                    name="normal"
                                    onChange={(e) => onChangeFuelPrice(e, item?.value)}
                                    value={fuelTypePrices?.[item?.value]?.normal}
                                    style={{ background: "#fff", fontSize: "14px" }}
                                />
                                <input
                                    type="number"
                                    onKeyDown={handleKeyDown}
                                    placeholder="0"
                                    name="eca"
                                    onChange={(e) => onChangeFuelPrice(e, item?.value)}
                                    value={fuelTypePrices?.[item?.value]?.eca}
                                    style={{ background: "#fff", fontSize: "14px" }}
                                />
                                <input
                                    type="number"
                                    placeholder="0"
                                    onKeyDown={handleKeyDown}
                                    name="mgo"
                                    onChange={(e) => onChangeFuelPrice(e, item?.value)}
                                    value={fuelTypePrices?.[item?.value]?.mgo}
                                    style={{ background: "#fff", fontSize: "14px" }}
                                />
                            </div>
                        })}


                    </Grid>
                </Grid>
                    <Grid container >
                        <Grid item sm={1} style={{ fontSize: "14px" }}>
                        </Grid>
                        <Grid item sm={11} xs={12} style={{ fontSize: "14px" }}>
                            <p className="mt-4 m-l-5 " style={{ fontSize: "15px" }}> Comments : </p>
                            <ul className="" style={{ fontSize: "14px", listStyleType: "circle" }}>
                                {cargoFuelPriceStep?.comments?.map((itm) => { return (<li>{itm}</li>) })}
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>

            case 1:
                return <Grid>
                    <Grid container >
                        <Grid item sm={5} xs={12} style={{ fontSize: "14px" }}>
                            <p className="mt-4 m-l-5 m-b-5" style={{ fontSize: "15px" }}>Notes: </p>
                            <p>Revenue : All lumpsum revenue including lumpsum freight, or any other, may be entered here</p>
                        </Grid>
                        <Grid item sm={5} xs={12}>
                            <div>
                                <h4 className="result-heading mt-5" style={{ fontSize: "14px" }}>Revenue</h4>
                                <table className="fleet-result-table">
                                    <tr>
                                        <th style={{ fontSize: "14px" }}>Misc Revenue</th>
                                        <td>
                                            <input
                                                type="number"
                                                onKeyDown={handleKeyDown}
                                                placeholder="0"
                                                onChange={onChangeExpenses}
                                                value={miscRevenue || ""}
                                                name="miscRevenue"
                                                style={{ fontSize: "14px" }}
                                            />
                                        </td>
                                    </tr>
                                </table>
                                <h4 className="result-heading m-t-5" style={{ fontSize: "14px" }}>Expenses</h4>
                                <table className="fleet-result-table">
                                    {expenseResultField.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <th style={{ fontSize: "14px" }}>{item.label}</th>
                                                <td>
                                                    <input
                                                        type="number"
                                                        onKeyDown={handleKeyDown}
                                                        placeholder="0"
                                                        onChange={onChangeExpenses}
                                                        value={query[item.value] || ""}
                                                        name={item.value}
                                                        style={{ fontSize: "14px" }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </table>
                            </div>
                        </Grid>

                    </Grid>
                </Grid>
            case 2:
                return <Grid container className="add-order-container">
                    <Grid item xs={12}>
                        <div className="w-full flex justify-between mb-2 items-center mt-1 popup-cargo-list">
                            <div className="typo-18-500 text-neutrals4">Port Expense</div>
                        </div>
                        <Box className="item-box-contianer cargobox-tables">
                            <TableContainer
                                component={Paper}
                                className="add-query-operations-table"
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Operation</TableCell>
                                            <TableCell>Port</TableCell>

                                            <TableCell>Extra Days</TableCell>
                                            <TableCell>Port Emission</TableCell>
                                            <TableCell>Loadline</TableCell>
                                            <TableCell>Draft Restriction</TableCell>
                                            <TableCell>Water Density</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {portOperations.map((item, index) => {
                                            const {
                                                operation = "",
                                                port = "",
                                                // sulfur = "",
                                                // loadline = "",
                                                extraDays = "",
                                                waterDensity = "",
                                                draftRes
                                            } = item || {};
                                            item.sulfur = item.sulfur ? item.sulfur : "Normal"
                                            item.loadline = item.loadline ? item.loadline : "loadline"
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <div className="port-change-div cursor-pointer">
                                                            <p>{operation}</p>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell style={{ width: 100 }}>

                                                        <div className="port-change-div cursor-pointer">
                                                            <p
                                                            // onClick={() =>
                                                            //     onSelectPortsOfOperations(item, index)
                                                            // }
                                                            >
                                                                {port}
                                                            </p>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell>
                                                        <input
                                                            type="number"
                                                            onKeyDown={handleKeyDown}
                                                            value={extraDays}
                                                            name="extraDays"
                                                            onChange={(e) => onChangePortOperation(index, e)}
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ width: 60 }}>
                                                        <select
                                                            name="sulfur"
                                                            value={item.sulfur}
                                                            onChange={(e) => onChangePortOperation(index, e)}
                                                        >
                                                            {portEmissionOptions.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.label}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </TableCell>
                                                    <TableCell style={{ width: 60 }}>
                                                        <select
                                                            name="loadline"
                                                            value={item?.loadline}
                                                            onChange={(e) => onChangePortOperation(index, e)}
                                                        >
                                                            {loadlineOptions.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.label}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </TableCell>
                                                    <TableCell>
                                                        <input
                                                            type="number"
                                                            onKeyDown={handleKeyDown}
                                                            value={draftRes}
                                                            name="draftRes"
                                                            onChange={(e) => onChangePortOperation(index, e)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <input
                                                            type="number"
                                                            onKeyDown={handleKeyDown}
                                                            value={waterDensity}
                                                            name="waterDensity"

                                                            onChange={(e) => onChangePortOperation(index, e)}
                                                        />
                                                    </TableCell>


                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                </Grid>
            default:
                return 'Unknown stepIndex';

        }
    }
    return (
        <Fragment>
            <div className="mb-4 flex justify-end w-full">
                <div className={classes.root}>
                    <Stepper activeStep={activeStep} className="stepper-background" style={{ background: "fff" }} alternativeLabel>
                        {steps.map((label) => (
                            <Step style={{ background: "fff" }} key={label}>
                                <StepLabel style={{ background: "fff" }}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>All steps completed</Typography>
                                <Button onClick={handleReset}>Reset</Button>
                            </div>
                        ) : (
                            <div>
                                <Typography className={classes.instructions} style={{ display: "flex", justifyContent: "center" }}>{getStepContent(activeStep)}</Typography>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}
                                    >
                                        Back
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* <PDFViewer className="w-full h-96">
          <Document>
            <Page size="A4" orientation=
              "landscape"
            >
              <CargoPdf portOperations={portOperations} query={query} />
            </Page>
          </Document>
        </PDFViewer> */}

        </Fragment>
    );
});

export default CreateNewCargo;
