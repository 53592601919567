import React, {
  memo,

} from "react";

import PortOperations from "../../../components/Calculator/PortOperations";

import Result from "../../../components/Calculator/Result";
import Expenses from "../../../components/Calculator/Expenses";
import AccountCargoDetails from "../../../components/Calculator/AccountCargoDetails";
import CalculatorVesselDetails from "../../../components/Calculator/CalculatorVesselDetails";
import MarginAndAllowances from "../../../components/Calculator/MarginAndAllowances";

import _, { cloneDeep } from "lodash";

import clsx from "clsx";


function CalculatorMain(props) {
  const {
    selectedQuery = {},
    calculatorData = {},
    setCalculatorData = () => { },
    onChangeChartValue = () => { },
    selectedVessels = [],
    onChangeFleetRowSelect = () => { },
    onDeleteFleet = () => { },
    onClickAddFleet = () => { },
    addFleetModal = false,
    addFleetsArray = [],
    setAddFleetsArray = () => { },
    onChangeNewFleetsValue = () => { },
    setLoader = () => { },
    setAddFleetModal = () => { },
    sendCalculationRequest = () => { },
    setSelectedVessels = () => { },
    splitHireModal = false,
    setSplitHireModal = () => { },
    selectedSplitHire = {},
    setSelectedSplitHire = () => { },
    openSplitHireModal = () => { },
    onFetchPortOperationDistance = () => { },
    getInitalQueryAndFleetData = () => { },
    initialFleetList = [],

    onChangeSelectedVessel = () => { },
    fleetCheckbox = () => { },
    setFleetCheckbox = () => { },

    setMarginModal = () => { },
    syncBunker = () => { },
    setSyncBunker = () => { },
    setSupplyModal = () => { },
    onChangeSyncBunker = () => { },
    isOverride = () => { },

    calculationErrors = {},


    marginModal,
    onChangePortOperations = () => { },
    onAddOperations = () => { },
    onDeleteOperations = () => { },
    supplyModal,
    fetchPortDistance = () => { },
    openAddOperation = () => { },
    setOpenAddOperation,
    setOpenAddOperationindex,
    onChangePortCanalInQueryState = () => { },
    setIsOverride,
    hideOrder,
    hideCalculator,
    getCurrentResult,

    isFromCargoAnalysis = false
  } = props;


  return (
    <div className="calculator-section flex flex-col w-full">
      <div className="flex flex-row">
        <div
          className={clsx("flex flex-col main-content-wrapper-inner", {
            "w-full": hideOrder,
            "w-full": !hideOrder,
          })}
        >

          <div>

            <div className="calc-secound-section p-2 pt-1">

              <div className="flex flex-col gap-2">
                {hideCalculator ? null : (
                  <div className="grid grid-cols-6 gap-3 all-content-tables">
                    <div className="upper-section-grid">
                      <AccountCargoDetails
                        calculatorData={calculatorData}
                        setLoader={setLoader}
                        getInitalQueryAndFleetData={
                          getInitalQueryAndFleetData
                        }
                        isFromCargoAnalysis={isFromCargoAnalysis}
                      />
                    </div>

                    <div className="upper-section-grid col-span-2">
                      <CalculatorVesselDetails
                        setLoader={setLoader}
                        setSelectedVessels={setSelectedVessels}
                        selectedVessels={selectedVessels}
                        calculatorData={calculatorData}
                        setCalculatorData={setCalculatorData}
                        onChangeHandler={onChangeSelectedVessel}
                        fleetCheckbox={fleetCheckbox}
                        setFleetCheckbox={setFleetCheckbox}
                        isFromCargoAnalysis={isFromCargoAnalysis}
                      />
                    </div>

                    <div className="upper-section-grid">
                      <MarginAndAllowances
                        onChangeSelectedVessel={onChangeSelectedVessel}
                        selectedVessels={selectedVessels}
                        setSelectedVessels={setSelectedVessels}
                        setMarginModal={setMarginModal}
                        calculatorData={calculatorData}
                        setCalculatorData={setCalculatorData}
                        syncBunker={syncBunker}
                        setSyncBunker={setSyncBunker}
                        setSupplyModal={setSupplyModal}
                        onChangeSyncBunker={onChangeSyncBunker}
                        isOverride={isOverride}
                      />
                    </div>

                    <div className="upper-section-grid">
                      <Expenses
                        onChangeSelectedVessel={onChangeSelectedVessel}
                        selectedVessels={selectedVessels}
                        openSplitHireModal={openSplitHireModal}
                        calculatorData={calculatorData}
                        setCalculatorData={setCalculatorData}
                        onChangeChartValue={onChangeChartValue}
                      />
                    </div>

                    <div className="upper-section-grid">
                      <Result
                        selectedVessels={selectedVessels}
                        calculationErrors={calculationErrors}
                        getCurrentResult={getCurrentResult}
                        calculatorData={calculatorData}
                      />
                    </div>
                  </div>
                )}
                {/* port operations section drag and drop items */}
                <div>
                  {calculatorData && <div className="middle-section-grid">
                    <PortOperations
                      marginModal={marginModal}
                      setMarginModal={setMarginModal}
                      sendCalculationRequest={sendCalculationRequest}
                      onChangePortOperations={onChangePortOperations}
                      calculatorData={calculatorData}
                      setCalculatorData={setCalculatorData}
                      syncBunker={syncBunker}
                      setSyncBunker={setSyncBunker}
                      onAddOperations={onAddOperations}
                      onDeleteOperations={onDeleteOperations}
                      selectedVessels={selectedVessels}
                      supplyModal={supplyModal}
                      setSupplyModal={setSupplyModal}
                      onChangeSyncBunker={onChangeSyncBunker}
                      onFetchPortOperationDistance={
                        onFetchPortOperationDistance
                      }
                      fetchPortDistance={fetchPortDistance}
                      openAddOperation={openAddOperation}
                      setOpenAddOperation={setOpenAddOperation}
                      setOpenAddOperationindex={setOpenAddOperationindex}
                      onChangeSelectedVessel={onChangeSelectedVessel}
                      isOverride={isOverride}
                      onChangePortCanalInQueryState={
                        onChangePortCanalInQueryState
                      }
                      setIsOverride={setIsOverride}
                    />
                  </div>}
                </div>

                {/* vessels selection section */}

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

export default memo(CalculatorMain);
