import React from "react";
import { Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { handleKeyDown } from "../../common/common";

function SplitHireModal(props) {
  const {
    selectedSplitHire = {},
    onChangeSplitHire = () => { },
    addNewSplitHire = () => { },
    isfromVesselVessl = false
  } = props;

  const splitHire =
    (selectedSplitHire &&
      selectedSplitHire.splitHire &&
      JSON.parse(selectedSplitHire.splitHire)) ||
    [];

  return (
    <Grid container>
      <Grid item xs={12}>
        <table className={!isfromVesselVessl ? "split-hire-table" : ""}>
          <thead>
            <tr>
              <th>No. of Days</th>
              <th>Hire Rate</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(splitHire) && splitHire.length ? (
              splitHire.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        value={item.NoDays}
                        name="NoDays"
                        onChange={(e) => onChangeSplitHire(index, e)}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        value={item.hireRate}
                        name="hireRate"
                        onChange={(e) => onChangeSplitHire(index, e)}
                      />
                    </td>

                    <td>
                      <DeleteIcon
                        style={{
                          color: "blue",
                          fontSize: "18px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => onChangeSplitHire(index, e, true)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (

              <tr>
                <td colSpan="3">
                  <p
                    style={{ padding: 5, borderRadius: 4, width: isfromVesselVessl ? "auto" : "290px" }}
                    className="!bg-white"
                  >
                    No data found.
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Grid>
      <Grid item xs={12} >
        <span className="add-split-hire" onClick={addNewSplitHire}>
          + Add
        </span>
      </Grid>
    </Grid>
  );
}

export default SplitHireModal;
