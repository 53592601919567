import React, {
    useState,
    forwardRef,
    useImperativeHandle,
    useEffect,
    Fragment,
} from "react";
import {
    Grid,
    Box,
    Select,
    MenuItem,
    TableContainer,
    Table,
    Paper,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    makeStyles,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Button,
    Checkbox,
} from "@material-ui/core";

import {
    addQueryInitialData,

} from "../../../constants";
import * as actions from "./actions";
import {
    getCurrentUserOrganizationId,
    toastSuccess,
} from "../../../utils";
import moment from "moment";
import { useDispatch } from "react-redux";
import * as commonActions from "../../Common/actions";
import { validateNewQuery } from "./addQueryValidation";
import { setCopyPortOperation, setCopyQuery } from "./dataMapping";

import { useParams } from "react-router-dom";
import CustomPagination from "../../../components/Common/CustomPagination";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
        background: "#fff"
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Fuel Prices', 'Revenue & expenses', "Extra Cargo details"];
}

const VesselList = forwardRef((props, ref) => {
    const {
        fleets,
        onChangeVesselPagination,
        vesselFilters,
        onCloseSaveCargoCargoVessel,
        onClickVessel,
        selectedVessel,
        onCreateVesselClick,

    } = props;


    const classes = useStyles();


    return (
        <Fragment>
            <div className="mb-4 flex justify-end w-full">
                <div className={classes.root}>



                    <Typography className={classes.instructions} style={{ display: "flex", justifyContent: "center" }}>
                        <TableContainer component={Paper}>
                            <Table className="universal-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ paddingRight: "0px" }}>Select one</TableCell>
                                        <TableCell>No.</TableCell>
                                        <TableCell>Vessel Name</TableCell>
                                        <TableCell>DWT</TableCell>
                                        <TableCell>Port</TableCell>
                                        <TableCell>Date Opening</TableCell>
                                        <TableCell>Hire Per Day</TableCell>
                                        <TableCell>Hire GBB</TableCell>
                                        <TableCell>Remark</TableCell>
                                        <TableCell>Created on</TableCell>
                                        <TableCell>Created By</TableCell>
                                        {/* <TableCell></TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(fleets) && fleets.length ? (
                                        fleets.map((item, index) => {
                                            return (
                                                <TableRow

                                                    key={index}>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={selectedVessel?._id === item?._id}
                                                            onChange={(e) => onClickVessel(e, item)}
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{item.vesselName || ""}</TableCell>
                                                    <TableCell>{item.dwt || 0}</TableCell>
                                                    <TableCell>{item.port || ""}</TableCell>
                                                    <TableCell>
                                                        {moment(item.dateOpen).format("DD/MMM/yyyy")}
                                                    </TableCell>
                                                    <TableCell>{item.hirePerDay || ""}</TableCell>
                                                    <TableCell>{item.hireGbb || 0}</TableCell>
                                                    <TableCell>{item.remark || ""}</TableCell>
                                                    <TableCell>
                                                        {item.createdDate
                                                            ? moment(item.createdDate).format("DD/MMM/yyyy")
                                                            : ""}
                                                    </TableCell>{" "}
                                                    <TableCell>
                                                        {item?.createdUser
                                                            ? `${item.createdUser?.firstName} ${item.createdUser?.lastName}`
                                                            : ""}
                                                    </TableCell>
                                                    {/* <TableCell>
                                                        <div className="query-actions">





                                                            <VisibilityIcon
                                                                color="primary"
                                                                onClick={() => {
                                                                    onClickEditVessel(item);
                                                                }}
                                                            />

                                                        </div>
                                                    </TableCell> */}
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan="8" align="center" className="!bg-white">
                                                No Fleet Found.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>

                        </TableContainer>
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div>
                            <CustomPagination
                                onChangePagination={onChangeVesselPagination}
                                filters={vesselFilters}
                                totalPage={vesselFilters?.totalPages}
                            />
                        </div>
                        <div style={{ alignContent: "end" }} >
                            <Button
                                onClick={onCloseSaveCargoCargoVessel}
                                className={classes.backButton}
                            >
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary"
                                onClick={onCreateVesselClick}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </div>
            </div>


        </Fragment>
    );
});

export default VesselList;
