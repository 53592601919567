import {
  clientQueryUserAPI,
  clientQueryOrgAPI,
  getQueryFormatForUserAPI,
  addClientQueryAPI,
  addCargoToQueryListAPI,
  querydeleteAPI,
  updateCargoDetailsAPI,
  updateQueryAPI,
  updateClientQueryAPI,
  allQueryByCharForOrgAPI,
  allQueryByCharForUserAPI,
  updateClientQueryForSingleVesselAPI,
  addDownloadPdfAPI,
  addCargoCargoDetailsAPI,
  addCargoAnalysisDetailsAPI,
} from "./api";
import * as actionTypes from "./actionTypes";
import { apiGet } from "../../../utils";

export const clientQueryUser = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      clientQueryUserAPI(data)
        .then((res) => {
          dispatch({
            type: actionTypes.ALL_CLIENT_ORDERS,
            payload: res,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.ALL_CLIENT_ORDERS,
            payload: { data: { list: [] } },
          });
          reject(err);
        });
    });
  };
};

export const clientQueryOrg = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      clientQueryOrgAPI(data)
        .then((res) => {
          dispatch({
            type: actionTypes.ALL_ORGANIZATION_ORDERS,
            payload: res,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.ALL_ORGANIZATION_ORDERS,
            payload: { data: { list: [] } },
          });
          reject(err);
        });
    });
  };
};

export const getQueryFormatForUser = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getQueryFormatForUserAPI(data)
        .then((res) => {
          dispatch({
            type: actionTypes.GET_QUERY_IN_ORDER_FORMAT,
            payload: res,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.GET_QUERY_IN_ORDER_FORMAT,
            payload: { data: { list: [] } },
          });
          reject(err);
        });
    });
  };
};

export const addClientQuery = (data) => {
  return new Promise((resolve, reject) => {
    addClientQueryAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addDownloadPdf = (data) => {
  return new Promise((resolve, reject) => {
    addDownloadPdfAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addCargoToQueryList = (data, queryId) => {
  return new Promise((resolve, reject) => {
    addCargoToQueryListAPI(data, queryId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const querydelete = (data) => {
  return new Promise((resolve, reject) => {
    querydeleteAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCargoDetails = (data) => {
  return new Promise((resolve, reject) => {
    updateCargoDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateQuery = (data) => {
  return new Promise((resolve, reject) => {
    updateQueryAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateClientQuery = (data) => {
  return new Promise((resolve, reject) => {
    updateClientQueryAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateClientQueryForSingleVessel = (id, data) => {
  return new Promise((resolve, reject) => {
    updateClientQueryForSingleVesselAPI(id, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const allQueryByCharForUser = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      allQueryByCharForUserAPI(data)
        .then((res) => {
          dispatch({
            type: actionTypes.ALL_CLIENT_ORDERS,
            payload: res,
          });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const allQueryByCharForOrg = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      allQueryByCharForOrgAPI(data)
        .then((res) => {
          dispatch({
            type: actionTypes.ALL_ORGANIZATION_ORDERS,
            payload: res,
          });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const clearOrderReducer = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_ORDER_REDUCER,
    });
  };
};


export function tagsByCharAPI(search, type) {
  return apiGet(`/api/addressBook/getAddressDetails`, {
    searchTerm: search,
    type: type,
    skipPagination: true,
  });
}


export const addCargoCargoDetails = (data) => {
  return new Promise((resolve, reject) => {
    addCargoCargoDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addCargoAnalysisDetails = (data) => {
  return new Promise((resolve, reject) => {
    addCargoAnalysisDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};