import axios from "axios";
import { ACCESS_TOKEN, API_URL } from "./constants";
import { toast, Slide } from "react-toastify";
import store from "./store";
import { clearDashbaordStore } from "./containers/User/Dashboard/actions";
import { clearCalculatorReducer } from "./containers/User/Calculator/actions";
import { clearOrderReducer } from "./containers/User/Orders/actions";
import moment from "moment";

export function apiGet(endpoint, data) {
  const finalEndpoint = finalUrl(endpoint);
  return apiRequest(finalEndpoint, data, "get", {});
}

export function apiPost(endpoint, data, headers) {
  const finalEndpoint = finalUrl(endpoint);
  return apiRequest(finalEndpoint, data, "post", headers);
}

export function apiPut(endpoint, data) {
  const finalEndpoint = finalUrl(endpoint);
  return apiRequest(finalEndpoint, data, "put", {});
}

export function apiDelete(endpoint, data = {}) {
  /*   const user = getObject("user");
      const headers = { ...header, Authorization: user.token }; */
  const finalEndpoint = finalUrl(endpoint);
  return apiRequest(finalEndpoint, data, "delete", {});
}

export function apiPatch(endpoint, data) {
  const finalEndpoint = finalUrl(endpoint);
  return apiRequest(finalEndpoint, data, "patch", {});
}

export function getHeaders() {
  const auth = getObject("auth") || {};
  if (auth.accessToken) {
    return {
      Authorization: `Bearer ${auth.accessToken}`,
    };
  }
  return {};
}

export function apiRequest(endPoint, data, method, headers) {
  return new Promise((resolve, reject) => {
    headers = {
      ...getHeaders(),
      ...headers,
    };

    if (method === "get" || method === "delete") {
      data = {
        params: data,
        headers,
      };
    }
    axios[method](endPoint, data, { headers })
      .then((response) => {
        const { data } = response;
        if (!data.hasOwnProperty("error")) {
          return resolve(data);
        }
        return reject(data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          removeAuthObjects();
          toastError("Authorization expired, please login again");
          window.open("/login", "_self");
          return;
        }
        if ((error.response && error.response, "error.response")) {
          return reject(error.response ? error.response : error);
        }
      });
  });
}

const finalUrl = (slug) => {
  return API_URL + slug;
};

export const saveObject = (key, data) => {
  let obj = JSON.stringify(data);
  localStorage.setItem(key, obj);
};

export const removeObject = (key) => {
  localStorage.removeItem(key);
};

export const getObject = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const isLoggedIn = () => {
  let auth = getObject("auth");
  if (auth && Object.keys(auth).length) {
    return true;
  }
  return false;
};

export const isAdminLogin = () => {
  let auth = getObject("auth");
  if (auth.role === "admin") {
    return true;
  }
  return false;
};

export const isSuperAdminLogin = () => {
  let auth = getObject("auth");
  if (auth.role === "superadmin") {
    return true;
  }
  return false;
};

export const getOrganizationId = () => {
  let auth = getObject("auth");
  if (auth && auth.organizationId) {
    return auth.organizationId;
  }
  return "";
};

export const getCurrentUserEmail = () => {
  let auth = getObject("auth");
  if (auth && auth.username) {
    return auth.username;
  }
  return "";
};

export const getCurrentUserId = () => {
  let auth = getObject("auth");
  if (auth && auth.userId) {
    return auth.userId;
  }
  return "";
};

export const getCurrentUserOrganizationId = () => {
  let auth = getObject("auth");
  if (auth && auth.organizationId) {
    return auth.organizationId;
  }
  return "";
};

export const getCurrentUserFirstName = () => {
  let auth = getObject("auth");
  if (auth && auth.firstName) {
    return auth.firstName;
  }
  return "";
};

export const getCurrentUserProfileImage = () => {
  let auth = getObject("auth");
  if (auth && auth.profileImage) {
    return auth.profileImage;
  }
  return "";
};

export const getCurrentUserName = () => {
  let auth = getObject("auth");
  if (auth) {
    return auth?.firstName + " " + auth?.lastName;
  }
  return "";
};

export const getUserDetails = () => {
  let auth = getObject("auth");
  if (auth && auth) {
    return auth;
  }
  return "";
};

export const validateEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function toastSuccess(message, time = 3000) {
  toast.success(message, {
    autoClose: time,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_RIGHT,
    transition: Slide,
  });
  clearWaitingQueue();
}

export function toastError(message, time = 1400) {
  toast.error(message || "Something went wrong.", {
    autoClose: time,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_RIGHT,
    transition: Slide,
  });
  clearWaitingQueue();
}

export function clearWaitingQueue() {
  toast.clearWaitingQueue();
}

export function removeAuthObjects() {
  removeObject("auth");
  // store.dispatch({
  //     type: 'CLEAR_ALL_STORE'
  // })
  store.dispatch(clearDashbaordStore());
  store.dispatch(clearCalculatorReducer());
  store.dispatch(clearOrderReducer());
  removeObject("auth");
  removeObject(ACCESS_TOKEN);
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function capitalizeFirstLetter(s) {
  return s[0].toUpperCase() + s.slice(1);
}

export function getRolePermisionRoutes() {
  let auth = getObject("auth");
  const isAdmin = auth?.role === "admin";
  //   if (auth.role === "user") isPricing = true;
  const isSuperAdmin = auth?.role === "superadmin";
  const isPricing = false;
  const premiumAccessExpiry = auth?.premiumAccessExpiry;
  const isPremium = auth?.isPremium;
  const isVerified = auth?.isVerified;
  const isPremiumExpired =
    isPremium &&
    (premiumAccessExpiry
      ? moment(premiumAccessExpiry).isBefore(moment())
      : false);
  let routeList = [
    "/",
    "/orders",
    "/userOrganization",
    "/vesselInformation",
    "/vessel/:id",
    "/voyages",
    "/calculator",
    "/emailParser",
    "/addressBook",
    "/cargo-cargo-matrix",
    "/vessel-vessel-matrix",
    "/cargo-analysis",
    "/distance-matrix",
    "/cargo-cargo/matrix/:id",
    "/vessel-vessel/matrix/:id",
    "/cargo-analysis/matrix/:id",
    "/map/:id",
    "/saved/:id",
    "/saved",
    "/vesselManager",
    "/singlevessel/:queryid/calculater/:vesselid",
    "/support",
    "/dashboard"
  ];

  if (isAdmin) {
    routeList = ["/", "/userOrganization", "/organization"];
  } else if (isPricing) {
    routeList = routeList.filter((item) => item !== "/");
  } else if (isSuperAdmin) {
    routeList = [
      "/",
      "/userOrganization",
      "/vesselInformation",
      "/organization",
      "/vessel/:id",
      "/supportinformation",

    ];
  } else if (!isPremiumExpired) {
    routeList = [
      "/orders",
      "/calculator",
      "/singlevessel/:queryid/calculater/:vesselid",
      "/map/:id",
      "/saved/:id",
      "/cargo-cargo/matrix/:id",
      "/vessel-vessel/matrix/:id",
      "/cargo-analysis/matrix/:id",
      "/saved",
      "/voyages",
      "/vesselManager",
      "/emailParser",
      "/addressBook",
      "/cargo-cargo-matrix",
      "/vessel-vessel-matrix",
      "/distance-matrix",
      "/cargo-analysis",
      "/support",
      "/dashboard",
      // "/vessel/:id",
    ];
  } else {
    routeList = [
      "/orders",
      // "/calculator",
      // "/map/:id",
      // "/saved/:id",
      // "/saved",
      "/voyages",
      "/vesselManager",
    ];
  }

  return routeList;
}

export function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const checkValidation = (vessel, vesseldata, activeStep = 4) => {
  let error = [];
  if (activeStep === 0 || activeStep === 4) {

    const checkBallastError = (vesselBal1, vesselBal2, vesselBal3, vesselBal4) => {
      let vesselBalCheck = []
      if (vesseldata?.vesselBalComb1Selected) {
        vesselBalCheck.push(Number(vesselBal1) ? true : false)
      }
      if (vesseldata?.vesselBalComb2Selected) {
        vesselBalCheck.push(Number(vesselBal2) ? true : false)
      }
      if (vesseldata?.vesselBalComb3Selected) {
        vesselBalCheck.push(Number(vesselBal3) ? true : false)
      }
      if (vesseldata?.vesselBalComb4Selected) {
        vesselBalCheck.push(Number(vesselBal4) ? true : false)
      }
      if (vesselBalCheck.every(value => value === false)) {
        return true
      }
    }
    const checkLadenError = (vesselLdn1, vesselLdn2, vesselLdn3, vesselLdn4) => {
      let vesselLdnSpdCheck = []
      if (vesseldata?.vesselLadComb1Selected) {
        vesselLdnSpdCheck.push(Number(vesselLdn1) ? true : false)
      }
      if (vesseldata?.vesselLadComb2Selected) {
        vesselLdnSpdCheck.push(Number(vesselLdn2) ? true : false)
      }
      if (vesseldata?.vesselLadComb3Selected) {
        vesselLdnSpdCheck.push(Number(vesselLdn3) ? true : false)
      }
      if (vesseldata?.vesselLadComb4Selected) {
        vesselLdnSpdCheck.push(Number(vesselLdn4) ? true : false)
      }
      if (vesselLdnSpdCheck.every(value => value === false)) {
        return true
      }
    }

    if (!vesseldata?.vesselName) {
      error.push("Vessel name is required");
    }
    if (vesseldata?.vesselDwt === "" || vesseldata?.vesselDwt === undefined || vesseldata?.vesselDwt === null) {
      error.push("Vessel DWT is required");
    }

    if (vesseldata?.vesselDraftS === "" || vesseldata?.vesselDraftS === undefined || vesseldata?.vesselDraftS === null) {
      error.push("Vessel Draft S is required");
    }

    if (vesseldata?.vesselTPC === "" || vesseldata?.vesselTPC === undefined || vesseldata?.vesselTPC === null) {
      error.push("Vessel TPC is required");
    }

    if (vesseldata?.vesselGrainCap === "" || vesseldata?.vesselGrainCap === undefined || vesseldata?.vesselGrainCap === null) {
      error.push("Vessel Grain Cap is required");
    }

    // if (vesseldata?.vesselBalSpd1 === "" || vesseldata?.vesselBalSpd1 === undefined||vesseldata?.vesselBalSpd1===null) {
    //   error.push("Vessel Ballast Speed 1 is required");
    // }

    // if (vesseldata?.vesselBalConsmHS1 === "" || vesseldata?.vesselBalConsmHS1 === undefined||vesseldata?.vesselBalConsmHS1===null) {
    //   error.push("Vessel Ballast Consumption HS1 is required");
    // }

    // if (vesseldata?.vesselBalConsmLS1 === "" || vesseldata?.vesselBalConsmLS1 === undefined||vesseldata?.vesselBalConsmLS1===null) {
    //   error.push("Vessel Ballast Consumption LS1 is required");
    // }

    // if (vesseldata?.vesselBalConsmMGO1 === "" || vesseldata?.vesselBalConsmMGO1 === undefined || vesseldata?.vesselBalConsmMGO1 === null) {
    //   error.push("Vessel Ballast Consumption MGO1 is required");
    // }
    if (vesseldata?.vesselBalComb1Selected) {
      if (checkBallastError(vesseldata?.vesselBalSpd1, vesseldata?.vesselBalSpd2, vesseldata?.vesselBalSpd3, vesseldata?.vesselBalSpd4)) {
        error.push("Vessel Ballast Speed 1 is required");
      }
      if (checkBallastError(vesseldata?.vesselBalConsmHS1, vesseldata?.vesselBalConsmHS2, vesseldata?.vesselBalConsmHS3, vesseldata?.vesselBalConsmHS4)) {
        error.push("Vessel Ballast Consumption HS1 is required");
      }
      if (checkBallastError(vesseldata?.vesselBalConsmLS1, vesseldata?.vesselBalConsmLS2, vesseldata?.vesselBalConsmLS3, vesseldata?.vesselBalConsmLS4)) {
        error.push("Vessel Ballast Consumption LS1 is required");
      }
    }
    if (vesseldata?.vesselBalComb2Selected) {
      if (checkBallastError(vesseldata?.vesselBalSpd1, vesseldata?.vesselBalSpd2, vesseldata?.vesselBalSpd3, vesseldata?.vesselBalSpd4)) {
        error.push("Vessel Ballast Speed 2 is required");
      }
      if (checkBallastError(vesseldata?.vesselBalConsmHS1, vesseldata?.vesselBalConsmHS2, vesseldata?.vesselBalConsmHS3, vesseldata?.vesselBalConsmHS4)) {
        error.push("Vessel Ballast Consumption HS2 is required");
      }
      if (checkBallastError(vesseldata?.vesselBalConsmLS1, vesseldata?.vesselBalConsmLS2, vesseldata?.vesselBalConsmLS3, vesseldata?.vesselBalConsmLS4)) {
        error.push("Vessel Ballast Consumption LS2 is required");
      }
    }
    if (vesseldata?.vesselBalComb3Selected) {
      if (checkBallastError(vesseldata?.vesselBalSpd1, vesseldata?.vesselBalSpd2, vesseldata?.vesselBalSpd3, vesseldata?.vesselBalSpd4)) {
        error.push("Vessel Ballast Speed 3 is required");
      }
      if (checkBallastError(vesseldata?.vesselBalConsmHS1, vesseldata?.vesselBalConsmHS2, vesseldata?.vesselBalConsmHS3, vesseldata?.vesselBalConsmHS4)) {
        error.push("Vessel Ballast Consumption HS3 is required");
      }
      if (checkBallastError(vesseldata?.vesselBalConsmLS1, vesseldata?.vesselBalConsmLS2, vesseldata?.vesselBalConsmLS3, vesseldata?.vesselBalConsmLS4)) {
        error.push("Vessel Ballast Consumption LS3 is required");
      }
    }
    if (vesseldata?.vesselBalComb4Selected) {
      if (checkBallastError(vesseldata?.vesselBalSpd1, vesseldata?.vesselBalSpd2, vesseldata?.vesselBalSpd3, vesseldata?.vesselBalSpd4)) {
        error.push("Vessel Ballast Speed 4 is required");
      }
      if (checkBallastError(vesseldata?.vesselBalConsmHS1, vesseldata?.vesselBalConsmHS2, vesseldata?.vesselBalConsmHS3, vesseldata?.vesselBalConsmHS4)) {
        error.push("Vessel Ballast Consumption HS4 is required");
      }
      if (checkBallastError(vesseldata?.vesselBalConsmLS1, vesseldata?.vesselBalConsmLS2, vesseldata?.vesselBalConsmLS3, vesseldata?.vesselBalConsmLS4)) {
        error.push("Vessel Ballast Consumption LS4 is required");
      }
    }
    if (vesseldata?.vesselLadComb1Selected) {
      if (checkLadenError(vesseldata?.vesselLdnSpd1, vesseldata?.vesselLdnSpd2, vesseldata?.vesselLdnSpd3, vesseldata?.vesselLdnSpd4)) {
        error.push("Vessel Laden Speed 1 is required");
      }
      if (checkLadenError(vesseldata?.vesselLdnConsmHS1, vesseldata?.vesselLdnConsmHS2, vesseldata?.vesselLdnConsmHS3, vesseldata?.vesselLdnConsmHS4)) {
        error.push("Vessel Laden Consumption HS1 is required");
      }
      if (checkLadenError(vesseldata?.vesselLdnConsmLS1, vesseldata?.vesselLdnConsmLS2, vesseldata?.vesselLdnConsmLS3, vesseldata?.vesselLdnConsmLS4)) {
        error.push("Vessel Laden Consumption LS1 is required");
      }
    }
    if (vesseldata?.vesselLadComb2Selected) {
      if (checkLadenError(vesseldata?.vesselLdnSpd1, vesseldata?.vesselLdnSpd2, vesseldata?.vesselLdnSpd3, vesseldata?.vesselLdnSpd4)) {
        error.push("Vessel Laden Speed 2 is required");
      }
      if (checkLadenError(vesseldata?.vesselLdnConsmHS1, vesseldata?.vesselLdnConsmHS2, vesseldata?.vesselLdnConsmHS3, vesseldata?.vesselLdnConsmHS4)) {
        error.push("Vessel Laden Consumption HS2 is required");
      }
      if (checkLadenError(vesseldata?.vesselLdnConsmLS1, vesseldata?.vesselLdnConsmLS2, vesseldata?.vesselLdnConsmLS3, vesseldata?.vesselLdnConsmLS4)) {
        error.push("Vessel Laden Consumption LS2 is required");
      }
    }
    if (vesseldata?.vesselLadComb3Selected) {
      if (checkLadenError(vesseldata?.vesselLdnSpd1, vesseldata?.vesselLdnSpd2, vesseldata?.vesselLdnSpd3, vesseldata?.vesselLdnSpd4)) {
        error.push("Vessel Laden Speed 3 is required");
      }
      if (checkLadenError(vesseldata?.vesselLdnConsmHS1, vesseldata?.vesselLdnConsmHS2, vesseldata?.vesselLdnConsmHS3, vesseldata?.vesselLdnConsmHS4)) {
        error.push("Vessel Laden Consumption HS3 is required");
      }
      if (checkLadenError(vesseldata?.vesselLdnConsmLS1, vesseldata?.vesselLdnConsmLS2, vesseldata?.vesselLdnConsmLS3, vesseldata?.vesselLdnConsmLS4)) {
        error.push("Vessel Laden Consumption LS3 is required");
      }
    }
    if (vesseldata?.vesselLadComb4Selected) {
      if (checkLadenError(vesseldata?.vesselLdnSpd1, vesseldata?.vesselLdnSpd2, vesseldata?.vesselLdnSpd3, vesseldata?.vesselLdnSpd4)) {
        error.push("Vessel Laden Speed 4 is required");
      }
      if (checkLadenError(vesseldata?.vesselLdnConsmHS1, vesseldata?.vesselLdnConsmHS2, vesseldata?.vesselLdnConsmHS3, vesseldata?.vesselLdnConsmHS4)) {
        error.push("Vessel Laden Consumption HS4 is required");
      }
      if (checkLadenError(vesseldata?.vesselLdnConsmLS1, vesseldata?.vesselLdnConsmLS2, vesseldata?.vesselLdnConsmLS3, vesseldata?.vesselLdnConsmLS4)) {
        error.push("Vessel Laden Consumption LS4 is required");
      }
    }
    // if (!vesseldata?.vesselBalComb1Selected) {
    //   error.push("Vessel Ballast Combination 1 must be selected");
    // }

    // if (vesseldata?.vesselBalSpd2 === "" || vesseldata?.vesselBalSpd2 === undefined || vesseldata?.vesselBalSpd2 === null) {
    //   error.push("Vessel Ballast Speed 2 is required");
    // }

    // if (vesseldata?.vesselBalConsmHS2 === "" || vesseldata?.vesselBalConsmHS2 === undefined || vesseldata?.vesselBalConsmHS2 === null) {
    //   error.push("Vessel Ballast Consumption HS2 is required");
    // }

    // if (vesseldata?.vesselBalConsmLS2 === "" || vesseldata?.vesselBalConsmLS2 === undefined || vesseldata?.vesselBalConsmLS2 === null) {
    //   error.push("Vessel Ballast Consumption LS2 is required");
    // }

    // if (vesseldata?.vesselBalConsmMGO2 === "" || vesseldata?.vesselBalConsmMGO2 === undefined || vesseldata?.vesselBalConsmMGO2 === null) {
    //   error.push("Vessel Ballast Consumption MGO2 is required");
    // }

    // if (!vesseldata?.vesselBalComb2Selected) {
    //   error.push("Vessel Ballast Combination 2 must be selected");
    // }

    // if (vesseldata?.vesselBalSpd3 === "" || vesseldata?.vesselBalSpd3 === undefined || vesseldata?.vesselBalSpd3 === null) {
    //   error.push("Vessel Ballast Speed 3 is required");
    // }

    // if (vesseldata?.vesselBalConsmHS3 === "" || vesseldata?.vesselBalConsmHS3 === undefined || vesseldata?.vesselBalConsmHS3 === null) {
    //   error.push("Vessel Ballast Consumption HS3 is required");
    // }

    // if (vesseldata?.vesselBalConsmLS3 === "" || vesseldata?.vesselBalConsmLS3 === undefined || vesseldata?.vesselBalConsmLS3 === null) {
    //   error.push("Vessel Ballast Consumption LS3 is required");
    // }

    // if (vesseldata?.vesselBalConsmMGO3 === "" || vesseldata?.vesselBalConsmMGO3 === undefined || vesseldata?.vesselBalConsmMGO3 === null) {
    //   error.push("Vessel Ballast Consumption MGO3 is required");
    // }

    // if (!vesseldata?.vesselBalComb3Selected) {
    //   error.push("Vessel Ballast Combination 3 must be selected");
    // }

    // if (vesseldata?.vesselBalSpd4 === "" || vesseldata?.vesselBalSpd4 === undefined || vesseldata?.vesselBalSpd4 === null) {
    //   error.push("Vessel Ballast Speed 4 is required");
    // }

    // if (vesseldata?.vesselBalConsmHS4 === "" || vesseldata?.vesselBalConsmHS4 === undefined || vesseldata?.vesselBalConsmHS4 === null) {
    //   error.push("Vessel Ballast Consumption HS4 is required");
    // }

    // if (vesseldata?.vesselBalConsmLS4 === "" || vesseldata?.vesselBalConsmLS4 === undefined || vesseldata?.vesselBalConsmLS4 === null) {
    //   error.push("Vessel Ballast Consumption LS4 is required");
    // }

    // if (vesseldata?.vesselBalConsmMGO4 === "" || vesseldata?.vesselBalConsmMGO4 === undefined || vesseldata?.vesselBalConsmMGO4 === null) {
    //   error.push("Vessel Ballast Consumption MGO4 is required");
    // }

    // if (!vesseldata?.vesselBalComb4Selected) {
    //   error.push("Vessel Ballast Combination 4 must be selected");
    // }

    // if (vesseldata?.vesselLdnSpd1 === "" || vesseldata?.vesselLdnSpd1 === undefined || vesseldata?.vesselLdnSpd1 === null) {
    //   error.push("Vessel Laden Speed 1 is required");
    // }

    // if (vesseldata?.vesselLdnConsmHS1 === "" || vesseldata?.vesselLdnConsmHS1 === undefined || vesseldata?.vesselLdnConsmHS1 === null) {
    //   error.push("Vessel Laden Consumption HS1 is required");
    // }

    // if (vesseldata?.vesselLdnConsmLS1 === "" || vesseldata?.vesselLdnConsmLS1 === undefined || vesseldata?.vesselLdnConsmLS1 === null) {
    //   error.push("Vessel Laden Consumption LS1 is required");
    // }

    // if (vesseldata?.vesselLdnConsmMGO1 === "" || vesseldata?.vesselLdnConsmMGO1 === undefined || vesseldata?.vesselLdnConsmMGO1 === null) {
    //   error.push("Vessel Laden Consumption MGO1 is required");
    // }

    // if (!vesseldata?.vesselLadComb1Selected) {
    //   error.push("Vessel Laden Combination 1 must be selected");
    // }

    // if (vesseldata?.vesselLdnSpd2 === "" || vesseldata?.vesselLdnSpd2 === undefined || vesseldata?.vesselLdnSpd2 === null) {
    //   error.push("Vessel Laden Speed 2 is required");
    // }

    // if (vesseldata?.vesselLdnConsmHS2 === "" || vesseldata?.vesselLdnConsmHS2 === undefined || vesseldata?.vesselLdnConsmHS2 === null) {
    //   error.push("Vessel Laden Consumption HS2 is required");
    // }

    // if (vesseldata?.vesselLdnConsmLS2 === "" || vesseldata?.vesselLdnConsmLS2 === undefined || vesseldata?.vesselLdnConsmLS2 === null) {
    //   error.push("Vessel Laden Consumption LS2 is required");
    // }

    // if (vesseldata?.vesselLdnConsmMGO2 === "" || vesseldata?.vesselLdnConsmMGO2 === undefined || vesseldata?.vesselLdnConsmMGO2 === null) {
    //   error.push("Vessel Laden Consumption MGO2 is required");
    // }

    // if (!vesseldata?.vesselLadComb2Selected) {
    //   error.push("Vessel Laden Combination 2 must be selected");
    // }

    // if (vesseldata?.vesselLdnSpd3 === "" || vesseldata?.vesselLdnSpd3 === undefined || vesseldata?.vesselLdnSpd3 === null) {
    //   error.push("Vessel Laden Speed 3 is required");
    // }

    // if (vesseldata?.vesselLdnConsmHS3 === "" || vesseldata?.vesselLdnConsmHS3 === undefined || vesseldata?.vesselLdnConsmHS3 === null) {
    //   error.push("Vessel Laden Consumption HS3 is required");
    // }

    // if (vesseldata?.vesselLdnConsmLS3 === "" || vesseldata?.vesselLdnConsmLS3 === undefined || vesseldata?.vesselLdnConsmLS3 === null) {
    //   error.push("Vessel Laden Consumption LS3 is required");
    // }

    // if (vesseldata?.vesselLdnConsmMGO3 === "" || vesseldata?.vesselLdnConsmMGO3 === undefined || vesseldata?.vesselLdnConsmMGO3 === null) {
    //   error.push("Vessel Laden Consumption MGO3 is required");
    // }

    // if (!vesseldata?.vesselLadComb3Selected) {
    //   error.push("Vessel Laden Combination 3 must be selected");
    // }

    // if (vesseldata?.vesselLdnSpd4 === "" || vesseldata?.vesselLdnSpd4 === undefined || vesseldata?.vesselLdnSpd4 === null) {
    //   error.push("Vessel Laden Speed 4 is required");
    // }

    // if (vesseldata?.vesselLdnConsmHS4 === "" || vesseldata?.vesselLdnConsmHS4 === undefined || vesseldata?.vesselLdnConsmHS4 === null) {
    //   error.push("Vessel Laden Consumption HS4 is required");
    // }

    // if (vesseldata?.vesselLdnConsmLS4 === "" || vesseldata?.vesselLdnConsmLS4 === undefined || vesseldata?.vesselLdnConsmLS4 === null) {
    //   error.push("Vessel Laden Consumption LS4 is required");
    // }

    // if (vesseldata?.vesselLdnConsmMGO4 === "" || vesseldata?.vesselLdnConsmMGO4 === undefined || vesseldata?.vesselLdnConsmMGO4 === null) {
    //   error.push("Vessel Laden Consumption MGO4 is required");
    // }

    // if (!vesseldata?.vesselLadComb4Selected) {
    //   error.push("Vessel Laden Combination 4 must be selected");
    // }

    if (vesseldata?.vesselPIdleConsmHS === "" || vesseldata?.vesselPIdleConsmHS === undefined || vesseldata?.vesselPIdleConsmHS === null) {
      error.push("Vessel Idle Consumption HS is required");
    }

    if (vesseldata?.vesselPIdleConsmLS === "" || vesseldata?.vesselPIdleConsmLS === undefined || vesseldata?.vesselPIdleConsmLS === null) {
      error.push("Vessel Idle Consumption LS is required");
    }

    if (vesseldata?.vesselPIdleConsmMGO === "" || vesseldata?.vesselPIdleConsmMGO === undefined || vesseldata?.vesselPIdleConsmMGO === null) {
      error.push("Vessel Idle Consumption MGO is required");
    }

    if (vesseldata?.vesselPLdgConsmHS === "" || vesseldata?.vesselPLdgConsmHS === undefined || vesseldata?.vesselPLdgConsmHS === null) {
      error.push("Vessel Loading Consumption HS is required");
    }

    if (vesseldata?.vesselPLdgConsmLS === "" || vesseldata?.vesselPLdgConsmLS === undefined || vesseldata?.vesselPLdgConsmLS === null) {
      error.push("Vessel Loading Consumption LS is required");
    }

    if (vesseldata?.vesselPLdgConsmMGO === "" || vesseldata?.vesselPLdgConsmMGO === undefined || vesseldata?.vesselPLdgConsmMGO === null) {
      error.push("Vessel Loading Consumption MGO is required");
    }

    if (vesseldata?.vesselPDischConsmHS === "" || vesseldata?.vesselPDischConsmHS === undefined || vesseldata?.vesselPDischConsmHS === null) {
      error.push("Vessel Discharge Consumption HS is required");
    }

    if (vesseldata?.vesselPDischConsmLS === "" || vesseldata?.vesselPDischConsmLS === undefined || vesseldata?.vesselPDischConsmLS === null) {
      error.push("Vessel Discharge Consumption LS is required");
    }

    if (vesseldata?.vesselPDischConsmMGO === "" || vesseldata?.vesselPDischConsmMGO === undefined || vesseldata?.vesselPDischConsmMGO === null) {
      error.push("Vessel Discharge Consumption MGO is required");
    }

    if (vesseldata?.vesselVExtraConsmHS === "" || vesseldata?.vesselVExtraConsmHS === undefined || vesseldata?.vesselVExtraConsmHS === null) {
      error.push("Vessel Extra Consumption HS is required");
    }

    if (vesseldata?.vesselVExtraConsmLS === "" || vesseldata?.vesselVExtraConsmLS === undefined || vesseldata?.vesselVExtraConsmLS === null) {
      error.push("Vessel Extra Consumption LS is required");
    }

    if (vesseldata?.vesselVExtraConsmMGO === "" || vesseldata?.vesselVExtraConsmMGO === undefined || vesseldata?.vesselVExtraConsmMGO === null) {
      error.push("Vessel Extra Consumption MGO is required");
    }
  }
  if (activeStep === 1 || activeStep === 4) {
    if (vessel?.bnkDelhs === "" || vessel?.bnkDelhs === undefined || vessel?.bnkDelhs === null) {
      error.push("Bunker Delivery HS is required");
    }
    if (vessel?.bnkDells === "" || vessel?.bnkDells === undefined || vessel?.bnkDells === null) {
      error.push("Bunker Delivery LS is required");
    }

    if (vessel?.bnkDelmgo === "" || vessel?.bnkDelmgo === undefined || vessel?.bnkDelmgo === null) {
      error.push("Bunker Delivery MGO is required");
    }

    // if (vessel?.constants===""||f (vessel?.constants===undefined||f (vessel?.constants===null) {
    //     error.push("Constants are required");
    // }

    // if (vessel?.extraExpense===""||f (vessel?.extraExpense===undefined||f (vessel?.extraExpense===null) {
    //     error.push("Extra Expense is required");
    // }

    // if (vessel?.bnkOnDel===""||f (vessel?.bnkOnDel===undefined||f (vessel?.bnkOnDel===null) {
    //     error.push("Bunker On Delivery is required");
    // }

    if (vessel?.bnkQtyRedeliveryhs === "" || vessel?.bnkQtyRedeliveryhs === undefined || vessel?.bnkQtyRedeliveryhs === null) {
      error.push("Bunker Quantity Redelivery HS is required");
    }

    if (vessel?.bnkQtyRedeliveryls === "" || vessel?.bnkQtyRedeliveryls === undefined || vessel?.bnkQtyRedeliveryls === null) {
      error.push("Bunker Quantity Redelivery LS is required");
    }

    if (vessel?.bnkQtyRedeliverymgo === "" || vessel?.bnkQtyRedeliverymgo === undefined || vessel?.bnkQtyRedeliverymgo === null) {
      error.push("Bunker Quantity Redelivery MGO is required");
    }



    if (vessel?.bnkRedeliveryQtyTolerancehs === "" || vessel?.bnkRedeliveryQtyTolerancehs === undefined || vessel?.bnkRedeliveryQtyTolerancehs === null) {
      error.push("Bunker Redelivery Quantity Tolerance HS is required");
    }

    if (vessel?.bnkRedeliveryQtyTolerancels === "" || vessel?.bnkRedeliveryQtyTolerancels === undefined || vessel?.bnkRedeliveryQtyTolerancels === null) {
      error.push("Bunker Redelivery Quantity Tolerance LS is required");
    }

    if (vessel?.bnkRedeliveryQtyTolerancemgo === "" || vessel?.bnkRedeliveryQtyTolerancemgo === undefined || vessel?.bnkRedeliveryQtyTolerancemgo === null) {
      error.push("Bunker Redelivery Quantity Tolerance MGO is required");
    }

    if (vessel?.bunkerPriceOverridden === undefined) {
      error.push("Bunker Price Overridden is required");
    }
    if (vessel?.bunkerPriceOverridden) {
      if (vessel?.bnkPriceDeliveryhs === "" || vessel?.bnkPriceDeliveryhs === undefined || vessel?.bnkPriceDeliveryhs === null) {
        error.push("Bunker Price Delivery HS is required");
      }

      if (vessel?.bnkPriceDeliveryls === "" || vessel?.bnkPriceDeliveryls === undefined || vessel?.bnkPriceDeliveryls === null) {
        error.push("Bunker Price Delivery LS is required");
      }

      if (vessel?.bnkPriceDeliverymgo === "" || vessel?.bnkPriceDeliverymgo === undefined || vessel?.bnkPriceDeliverymgo === null) {
        error.push("Bunker Price Delivery MGO is required");
      }

      if (vessel?.bnkPriceRedeliveryhs === "" || vessel?.bnkPriceRedeliveryhs === undefined || vessel?.bnkPriceRedeliveryhs === null) {
        error.push("Bunker Price Redelivery HS is required");
      }

      if (vessel?.bnkPriceRedeliveryls === "" || vessel?.bnkPriceRedeliveryls === undefined || vessel?.bnkPriceRedeliveryls === null) {
        error.push("Bunker Price Redelivery LS is required");
      }

      if (vessel?.bnkPriceRedeliverymgo === "" || vessel?.bnkPriceRedeliverymgo === undefined || vessel?.bnkPriceRedeliverymgo === null) {
        error.push("Bunker Price Redelivery MGO is required");
      }

      if (vessel?.bnkPriceSettlemenths === "" || vessel?.bnkPriceSettlemenths === undefined || vessel?.bnkPriceSettlemenths === null) {
        error.push("Bunker Price Settlement HS is required");
      }

      if (vessel?.bnkPriceSettlementls === "" || vessel?.bnkPriceSettlementls === undefined || vessel?.bnkPriceSettlementls === null) {
        error.push("Bunker Price Settlement LS is required");
      }

      if (vessel?.bnkPriceSettlementmgo === "" || vessel?.bnkPriceSettlementmgo === undefined || vessel?.bnkPriceSettlementmgo === null) {
        error.push("Bunker Price Settlement MGO is required");
      }
    }

  }
  if (activeStep === 2 || activeStep === 4) {
    if (vessel.splitHire) {
      const tempSplitHire = JSON.parse(vessel.splitHire)
      tempSplitHire.forEach((item, index) => {
        if (item.NoDays === undefined) {
          error.push(`NoDays is required at index ${index + 1}`);
        }

        if (item.hireRate === undefined) {
          error.push(`hireRate is required at index ${index + 1}`);
        }
      });
    }
  }
  if (activeStep === 3 || activeStep === 4) {
    if (!vessel?.dateOpen) {
      error.push("Date Open is required");
    }

    if (vessel?.gbb === "") {
      error.push("Gbb is required");
    }

    if (vessel?.hirePerDay === "") {
      error.push("Hire Per Day is required");
    }

    if (vessel?.hireGbb === "") {
      error.push("Hire Gbb is required");
    }

    if (vessel?.constants === "") {
      error.push("Constants is required");
    }

    if (vessel?.extraExpense === "") {
      error.push("Extra Expense is required");
    }

  }
  return error
}