
import {
    changeMaildetailsAPI,
    getDataByCumulativeSearchAPI,
    getEmailsByVesselAPI,
    onChangeAutoVesselAPI
} from "./api"

export const getDataByCumulativeSearch = (data) => {
    return new Promise((resolve, reject) => {
        getDataByCumulativeSearchAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}


export const getEmailsByVessel = (data) => {
    return new Promise((resolve, reject) => {
        getEmailsByVesselAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const changeMaildetails = (data) => {
    return new Promise((resolve, reject) => {
        changeMaildetailsAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const onChangeAutoVessel = (data) => {
    return new Promise((resolve, reject) => {
        onChangeAutoVesselAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}