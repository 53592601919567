import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  Paper,
  makeStyles,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  useTheme,
  TableBody,
  Switch,
  Checkbox,
} from "@material-ui/core";
import CustomButton from "../../../components/Common/CustomButton";
import * as actions from "../Calculator/actions";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { checkValidation, getCurrentUserId, getCurrentUserOrganizationId, getOrganizationId, toastError, toastSuccess } from "../../../utils";
import CustomPagination from "../../../components/Common/CustomPagination";
import CustomConfirmModal from "../../../components/Common/CustomConfirmModal";
import AddFleetModal from "../../../components/Calculator/AddCalculatorFleet/AddFleetTabView";
import { initialAddFleet, initialVesselState } from "../../../constants";
import CustomShowModal from "../../../components/Common/CustomShowModal";
import AutoAddFleet from "../../../components/Calculator/AddCalculatorFleet/AutoAddFleet";
import ManualAddFleet from "../../../components/Calculator/AddCalculatorFleet/ManualAddFleet";
import UserCopyFleet from "../../../components/Calculator/AddCalculatorFleet/UserCopyFleet";
import _ from "lodash";
import SingleVessel from "../Dashboard/Vessel";
import EditVesselOnFleetAdd, { initialRowData, initialVessel } from "../Dashboard/Vessel/editVesselOnFleetAdd";
import EditVesselOnFleetAddStep from "../Dashboard/Vessel/steps/editVesselOnFleetAddStep";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ShareIcon from '@material-ui/icons/Share';
import CustomAddModal from "../../../components/Common/CustomAddModal";
import ShareWithOrg from "../../../components/Common/ShareWithOrg";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SaveCargoLabel from "../../../components/Calculator/SaveCargoLabel";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { use } from "react";


const initialSearchType = {
  type: "",
  saveLabel: "",
  name: "",
  firstLoadPort: "",
  lastDischargePort: "",
  loadQuantity: "",
  createdDate: "",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="pt-1">
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function MyVessel(props) {
  const { setLoader = () => { }, setSearchValue = () => { }, searchValue, DatefilterValue } = props;
  const theme = useTheme();

  const [value, setValue] = useState(1);
  const [fleets, setFleets] = useState([]);
  const [searchType, setSearchType] = useState({ ...initialSearchType });
  const [addFleetModal, setAddFleetModal] = useState(false);
  const [addFleetsArray, setAddFleetsArray] = useState([
    { ...initialAddFleet },
  ]);

  const [myFilters, setMyFilters] = useState({
    offset: 1,
    limit: 10,
  });
  const history = useHistory();


  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState({});
  //tabs
  const [vesselLoading, setVesselLoading] = useState({});
  const [portLoading, setPortLoading] = useState({});
  const [saveVesselListByIndex, setSaveVesselListByIndex] = useState({});
  const [savePortListByIndex, setSavePortListByIndex] = useState({});
  const [autoSwitch, setAutoSwitch] = useState(false);
  const [emailFleetList, setEmailFleetList] = useState([]);
  const [autoFilters, setAutoFilters] = useState({
    updateSince: { label: "1", value: "1" },
    portRegion: [
      // { label: "Unknown", value: "Unknown" }
    ],
    vesselType: { label: "All", value: "0_99999999" },
    gear: { label: "All", value: "All" },
  });
  const [selectedAutoFleet, setSelectedAutoFleet] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [onValidationVessel, setValidationVessel] = useState(false);
  const [onValidationVesselFromAuto, setValidationVesselFromAuto] = useState(false);
  const [onValidationVesselIndex, setValidationVesselIndex] = useState(0);
  const [onValidationVesselFromAutoIndex, setValidationVesselFromAutoIndex] = useState("");
  const [addFleetRegionOptions, setAddFleetRegionOptions] = useState([]);
  const [vesselManager, setVesselManager] = useState([]);
  const [singleResults, setSingleResults] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [isShareConfirm, setShareConfirm] = useState(false);
  const [getSingleVesselData, setSingleVesselData] = useState("");
  const [shareLabel, setShareLabel] = useState("");

  const [selectedVesssel, setSelectedVesssel] = useState(null);
  const [saveVesselVesselLabel, setSaveVesselVesselLabel] = useState("");
  const [vesselVesselModal, setVesselVesselModal] = useState(false);
  const [checkSeachrender, seCheckSeachrender] = useState(false)
  const organizationId = getCurrentUserOrganizationId();


  const onDateFilter = () => {
    setFleets(DatefilterValue)
  }
  useEffect(() => {
    onDateFilter()
  }, [DatefilterValue])

  const onSearchVessel = (search) => {
    getAllFleets(false, { search: search });
  };
  const delaySearch = useCallback(
    _.debounce((data) => onSearchVessel(data), 500),
    []
  );
  useEffect(() => {
    if (checkSeachrender) {
      delaySearch(searchValue);
    }
    seCheckSeachrender(true)

  }, [searchValue]);

  useEffect(() => {
    getVesselManager();
    getAllFleets();
  }, []);
  const getVesselManager = async () => {
    await actions.getVesselManagerData()
      .then((res) => {
        setVesselManager(res?.data?.allVesselManager)
      })
      .catch(() => { });
  }
  const getAllFleets = async (fromCheck = false, fromPagination) => {
    if (!fromCheck)
      setLoader(true);

    await actions
      .userFleets(fromPagination ? fromPagination : myFilters)
      .then((res) => {
        setLoader(false);
        setFleets(
          (res.data && Array.isArray(res.data.list) && res.data.list) || []
        );
        setMyFilters({
          totalPages: res?.data?.totalItems,
          offset: res?.data?.currentPage,
          pageCount: res?.data?.totalPages,
          limit: res?.data?.perPage
        })
      })
      .catch(() => {
        setLoader(false);
        toastError();
      });
  };

  const onClickEditVessel = async (item) => {
    setLoader(true);
    await actions
      .getSingleFleetData(item?._id, { userId: getCurrentUserId() })
      .then((res) => {
        setSingleResults({ ...res?.data, vesseldata: JSON.parse(res?.data?.vessel) } || {});
        setIsEdit(true)
        setLoader(false);
        setOpen(true)

      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchType({ ...initialSearchType });
    if (newValue === 2) {
      onSubmitAutoSearch()
    }
  };

  const onClickDeleteSavedFleet = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const onChangeMyPagination = (type = "") => {
    let newMyFilters = {};
    if (type === "inc") {
      newMyFilters = { ...myFilters, offset: myFilters.offset + 1 };
    } else if (type === "first") {
      newMyFilters = { ...myFilters, offset: 1 };
    } else {
      if (myFilters.offset === 1) return;
      newMyFilters = { ...myFilters, offset: myFilters.offset - 1 };
    }
    setLoader(true);

    // .allSavedResults(newMyFilters)
    getAllFleets(false, { ...newMyFilters, search: searchValue })

    // .then((res) => {
    //   // getAllFleets();
    //   setLoader(false);
    //   // setMyFilters(newMyFilters);
    //   setMyFilters({
    //     totalPages: res?.data?.totalItems,
    //     offset: res?.data?.currentPage,
    //     pageCount: res?.data?.totalPages,
    //     limit: res?.data?.perPage
    //   })
    //   setFleets((res.data && Array.isArray(res.data) && res.data) || []);
    // })
    // .catch((err) => {
    //   setLoader(false);
    // });
  };

  const onDeleteFleet = () => {
    setLoader(true);
    actions
      .deleteFleet(deleteId)
      .then((res) => {
        toastSuccess("Fleet deleted successfully");
        setDeleteModal(false);
        setDeleteId("");
        getAllFleets();
      })
      .catch((err) => {
        toastError();
        setLoader(false);
      });
  };

  const onClickAddFleet = () => {
    setAddFleetModal((prev) => !prev);
  };
  //Tabs

  const delayVesselSearch = useCallback(
    _.debounce((index, search) => onSearchVesselList(index, search), 500),
    []
  );
  const delayPortSearch = useCallback(
    _.debounce((index, search) => onSearchPortList(index, search), 500),
    []
  );

  useEffect(() => {
    setValue(0);
    actions.allPortRegions().then((res) => {
      const regionOptions =
        (Array.isArray(res?.data?.list) &&
          res?.data?.list?.map((region) => ({ label: region, value: region }))) ||
        [];
      setAddFleetRegionOptions([
        // { label: "Unknown", value: "Unknown" },
        ...regionOptions,
      ]);
    });
    return () => {
      setAddFleetsArray([{ ...initialAddFleet }]);
    };
  }, []);

  const onSearchVesselList = (index, value) => {
    if (value) {
      setVesselLoading({ ...vesselLoading, [index]: true });
      actions
        .allVesselsByCharWithoutReducer(value)
        .then((res) => {
          setVesselLoading({ ...vesselLoading, [index]: false });
          setSaveVesselListByIndex({
            ...saveVesselListByIndex,
            [index]:
              (Array.isArray(res.data.list) &&
                res.data.list.map((item) => ({
                  ...item,
                  vesselLabel: `${item.vesselName} ${item.vesselDwt}`,
                }))) ||
              [],
          });
        })
        .catch((err) => {
          setVesselLoading({ ...vesselLoading, [index]: false });
        });
    }
  };

  const onSearchPortList = (index, value) => {
    if (value) {
      setPortLoading({ ...portLoading, [index]: true });
      actions
        .portsByChar(value)
        .then((res) => {
          setPortLoading({ ...portLoading, [index]: false });
          setSavePortListByIndex({
            ...savePortListByIndex,
            [index]:
              (Array.isArray(res.data.list) &&
                res.data.list.map((item) => ({
                  ...item,
                  portLabel: `${item.name} ${item.country ? "(" + item.country + ")" : ""
                    }`,
                }))) ||
              [],
          });
        })
        .catch((err) => {
          setPortLoading({ ...portLoading, [index]: false });
        });
    }
  };

  const onChangeNewFleetsValue = (index, name, value) => {
    let newAddFleetsArray = [...addFleetsArray];
    if (name === "vesselName") {
      newAddFleetsArray[index].vesselName = value.vesselName;
      newAddFleetsArray[index].selectedVessel = value;
    } else if (name === "port") {
      newAddFleetsArray[index].port = value.name;
      newAddFleetsArray[index].selectedPort = value;
      newAddFleetsArray[index].lat = value.lat || "";
      newAddFleetsArray[index].lng = value.lng || "";
    } else {
      newAddFleetsArray[index][name] = value;
    }
    setAddFleetsArray(newAddFleetsArray);
  };

  const checkForRequired = () =>
    addFleetsArray.find(
      (item) => !item.vesselName || !item.port || !String(item.dateOpen)
    );

  const checkForRequiredFromAuto = (output) =>
    output?.find(
      (item) => !item?.vesselName || !item?.nearestport?.name || !item?.nearestDate?.date
    );

  const onAddFleetLine = () => {
    if (!!checkForRequired()) return toastError("Please fill required fields");
    setAddFleetsArray([...addFleetsArray, { ...initialAddFleet }]);
  };

  const onDeleteVesselLine = (index) => {
    let newAddFleetsArray = [...addFleetsArray];
    newAddFleetsArray.splice(index, 1);
    setSaveVesselListByIndex({ ...saveVesselListByIndex, [index]: [] });
    setSavePortListByIndex({ ...savePortListByIndex, [index]: [] });
    setAddFleetsArray(newAddFleetsArray);
  };

  const removeExtraField = ({ selectedPort, selectedVessel, ...rest }) => ({
    ...rest,
  });

  const removeExtraFieldFromAuto = ({ nearestport, vesseldata, ...rest }) => ({
    ...rest,
  });

  const removeExtraFieldFromAutoVessel = ({ id, ...rest }) => ({
    ...rest,
  });


  const onChangeNewPortValue = async (index, value, id, isDate) => {
    let newAddPortsArray = [...emailFleetList];
    setLoader(true);
    const data = {
      id: id,
      value: value,
      isDate
    }
    await actions
      .changeAutoVesselDetails(data)
      .then((res) => {
        if (isDate) {
          newAddPortsArray[index].nearestDate = { date: value };
        } else {
          newAddPortsArray[index].nearestport = value;
        }
        setEmailFleetList(newAddPortsArray);

        // setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toastError();
      });
  };
  const onSaveAllVesselsFromAuto = () => {
    const output = emailFleetList?.filter((itm) => selectedAutoFleet.find((item) => item._id === itm?._id))
    if (!output?.length) {
      toastError("Please select vessel!")
      return
    }
    if (!!checkForRequiredFromAuto(output)) return toastError("Please fill required fields");
    const resulted = output?.map((item) => {
      return {
        ...removeExtraFieldFromAuto(item),
        fromAutoVesselId: item?._id,
        ...item?.nearestport,
        port: item?.nearestport?.name,
        dateOpen: moment(item?.nearestDate?.date).toISOString(),
        vesselId: item?.vesselId,
        vessel: JSON.stringify(
          {
            ...removeExtraFieldFromAutoVessel(item.vesseldata),
            createdUser: { _id: item.vesseldata?.createdUser }
          }),
      };
    });
    setAddFleetModal(false);
    setLoader(true);
    actions
      .addMultiFleet(resulted)
      .then((res) => {
        getAllFleets();
        setLoader(false);
        setSelectedAutoFleet([])
        // setAddFleetModal(false);
        toastSuccess("Vessel(s) added successfully");
        // setAddFleetsArray([{ ...initialAddFleet }]);
        onSubmitAutoSearch()
      })
      .catch((err) => {
        // setAddFleetModal(false);
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onSaveAllVessels = () => {
    if (autoSwitch) return onSubmitAutoFleet();
    if (!!checkForRequired()) return toastError("Please fill required fields");
    let checkValidate = false
    for (let i = 0; i < addFleetsArray?.length; i++) {
      if (!addFleetsArray[i]?.isValidate) {
        checkValidate = true
      }
    }
    if (checkValidate) {
      return toastError("Please Validate Vessel's required fields");
    }
    const resulted = addFleetsArray.map((item) => {
      return {
        ...removeExtraField(item),
        dateOpen: moment(item.dateOpen).toISOString(),
        vesselId: item.selectedVessel._id,
        vessel: JSON.stringify(item.selectedVessel),
      };
    });
    setAddFleetModal(false);
    setLoader(true);
    actions
      .addMultiFleet(resulted)
      .then((res) => {
        getAllFleets();
        setLoader(false);
        setAddFleetModal(false);
        toastSuccess("Vessel(s) added successfully");
        setAddFleetsArray([{ ...initialAddFleet }]);
      })
      .catch((err) => {
        setAddFleetModal(false);
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onChangeAutoSwitch = (checked) => {
    setAutoSwitch(checked);
  };

  const updatedSinceLastOptions = () => {
    const arr = [];
    for (let i = 0; i < 30; i++) {
      arr.push({ label: String(i + 1), value: String(i + 1) });
    }
    return arr;
  };

  const onChangeAutoFilters = (name, value) => {
    setAutoFilters({ ...autoFilters, [name]: value });
  };

  const onSubmitAutoSearch = () => {
    const { updateSince, portRegion, vesselType, gear } = autoFilters || {};
    const modifiedFilters = {
      emailDateLower: moment()
        .subtract(Number(updateSince.value), "d")
        .startOf()
        .format("yyyy-MM-DD hh:mm"),
      emailDateUpper: moment().startOf().format("yyyy-MM-DD hh:mm"),
      dwtLower: Number(vesselType.value.split("_")[0]),
      dwtUpper: Number(vesselType.value.split("_")[1]),
      portRegion: portRegion.map((item) => item.value).join(","),
      orgId: getOrganizationId(),
      gear: gear.label,
    };
    setLoader(true);
    actions
      .getAutoEmailsVesselList(modifiedFilters)
      .then((res) => {
        if (Array.isArray(res)) setEmailFleetList(res);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onSelectAllAutoFleet = ({ target: { checked } }) => {
    setAllSelected(checked);
    if (checked) {
      setSelectedAutoFleet(emailFleetList?.filter(itm => !itm?.fleet_id));
    } else {
      setSelectedAutoFleet([]);
    }
  };

  const onAutoFleetListCheckBox = (selectedItem) => {
    let updatedSelectedAutoFleet = [...selectedAutoFleet];
    const fleetIndex = updatedSelectedAutoFleet.findIndex(
      (item) => item._id === selectedItem._id
    );
    if (fleetIndex > -1) {
      updatedSelectedAutoFleet.splice(fleetIndex, 1);
    } else {
      updatedSelectedAutoFleet.push(selectedItem);
    }
    setSelectedAutoFleet(updatedSelectedAutoFleet);
  };

  const onChangeDateOpening = (index, selectedItem, date) => {
    let updatedSelectedAutoFleet = [...selectedAutoFleet];
    let updatedEmailFleetList = [...emailFleetList];

    const fleetIndex = updatedSelectedAutoFleet.findIndex(
      (item) => item._id === selectedItem._id
    );
    if (fleetIndex > -1) {
      updatedSelectedAutoFleet[fleetIndex].dateOpen = date;
      setSelectedAutoFleet(updatedSelectedAutoFleet);
    }
    updatedEmailFleetList[index].dateOpen = date;
    setEmailFleetList(updatedEmailFleetList);
  };

  const onSubmitAutoFleet = () => {
    const findWithoutDate =
      selectedAutoFleet.find((item) => !item.dateOpen) || false;
    if (!!findWithoutDate)
      return toastError("Please add date to selected vessels");
    let promiseArray = selectedAutoFleet.map((item) =>
      actions.getVesselsWithId(item.vesselId)
    );
    setAddFleetModal(false);
    setLoader(true);
    Promise.all(promiseArray)
      .then((responseArray) => {
        let newAddFleetsArray = responseArray.map((vessel, index) => {
          const { vesselName = "", vesselDwt = "", id = "" } = vessel;
          const { dateOpen = "", portName = "" } =
            selectedAutoFleet[index] || {};
          return {
            vesselName,
            dwt: vesselDwt,
            port: portName,
            dateOpening: moment(dateOpen).toISOString(),
            tcEq: 0,
            gbb: 0,
            hirePerDay: 0,
            hireGbb: 0,
            frtCost: 0,
            profit: 0,
            loadability: 0,
            arrivalLoadPort: "",
            extra_Exp: "",
            bod: 0,
            constan: 0,
            remark: "",
            vesselId: id,
            vessel: JSON.stringify(vessel),
          };
        });
        actions
          .saveToFleet(newAddFleetsArray)
          .then((res) => {
            setLoader(false);
            toastSuccess("Vessel(s) added successfully");
          })
          .catch((err) => {
            setAddFleetModal(true);
            setLoader(false);
            toastError(err?.data?.message || "Something went wrong");
          });
      })
      .catch((err) => {
        setAddFleetModal(true);
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onChangeVesselManager = (e, item) => {
    setLoader(true);
    const data = {
      checked: e.target.checked,
      vesselId: item?._id
    }
    actions
      .updateVesselManagerData(data)
      .then((res) => {
        getVesselManager();
        getAllFleets(true);
      })
      .catch((err) => {
        // setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  }

  const onUpdateMyVessel = () => {

    if (!!checkForRequired()) return toastError("Please fill required fields");
    const resulted = addFleetsArray.map((item) => {
      return {
        ...removeExtraFieldFromAuto(item),
        ...item?.nearestport,
        port: item?.nearestport?.name,
        dateOpen: moment(item?.nearestDate?.date).toISOString(),
        vesselId: item?.vesselId,
        vessel: JSON.stringify(
          {
            ...removeExtraFieldFromAutoVessel(item.vesseldata),
            createdUser: { _id: item.vesseldata?.createdUser }
          }),
      };
    });
    setLoader(true);
    actions
      .updateFleetData(resulted?.[0])
      .then((res) => {
        getAllFleets();
        setLoader(false);
        setOpen(false)
        toastSuccess("Vessel(s) Updated successfully");
        setAddFleetsArray([{ ...initialAddFleet }]);
      })
      .catch((err) => {
        setLoader(false);
        setOpen(false)
        toastError(err?.data?.message || "Something went wrong");
      });
  }



  const onShareOrgSaveClick = async () => {
    if (!shareLabel || !getSingleVesselData || !organizationId) return
    let { _id, ...data } = getSingleVesselData
    data.access = "public"
    data.organizationId = organizationId
    data.vesselName = shareLabel
    await actions
      .addFleet(data)

    setShareConfirm(false)
    setShareLabel("")
  }

  const onShareOrgClick = (item) => {
    setShareConfirm(!isShareConfirm)
    setSingleVesselData(item ? item : "")
    setShareLabel("")
  }

  const onLiveSharingChange = (fleet, liveShare) => {
    setLoader(true);
    actions
      .updateLiveSharing({ fleetId: fleet?._id, liveShare })
      .then((res) => {
        getAllFleets();
        setLoader(false);
        toastSuccess("Live Sharing Updated successfully");
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  }



  const onClickVesselVessel = (vessel) => {
    setSelectedVesssel(vessel);
    setSaveVesselVesselLabel("");
    setVesselVesselModal(true);
  };
  const onCloseVesselVessel = () => {
    setVesselVesselModal(false)
    setSelectedVesssel(null)
  }
  const onClickSaveVesselVessel = async () => {
    const newVesselVessel = {
      "fleetList": [
        {
          ...selectedVesssel,
          "vesselOut": {
            hireGbb: selectedVesssel?.hireGbb,
            hirePerDay: selectedVesssel?.hirePerDay,
            tcEq: selectedVesssel?.tcEq,
            port: selectedVesssel?.port,
            dateOpen: selectedVesssel?.dateOpen,
            DurationOut: selectedVesssel?.DurationOut,
            MinDuration: selectedVesssel?.MinDuration,
            MaxDuration: selectedVesssel?.MaxDuration,
            bnkDelhs: selectedVesssel?.bnkDelhs,
            bnkDells: selectedVesssel?.bnkDells,
            bnkDelmgo: selectedVesssel?.bnkDelmgo,
            bnkPriceDeliveryhs: selectedVesssel?.bnkPriceDeliveryhs,
            bnkPriceDeliveryls: selectedVesssel?.bnkPriceDeliveryls,
            bnkPriceDeliverymgo: selectedVesssel?.bnkPriceDeliverymgo,
            bnkPriceRedeliveryhs: selectedVesssel?.bnkPriceRedeliveryhs,
            bnkPriceRedeliveryls: selectedVesssel?.bnkPriceRedeliveryls,
            bnkPriceRedeliverymgo: selectedVesssel?.bnkPriceRedeliverymgo,
            bnkPriceSettlemenths: selectedVesssel?.bnkPriceSettlemenths,
            bnkPriceSettlementls: selectedVesssel?.bnkPriceSettlementls,
            bnkPriceSettlementmgo: selectedVesssel?.bnkPriceSettlementmgo,
            bnkQtyRedeliveryhs: selectedVesssel?.bnkQtyRedeliveryhs,
            bnkQtyRedeliveryls: selectedVesssel?.bnkQtyRedeliveryls,
            bnkQtyRedeliverymgo: selectedVesssel?.bnkQtyRedeliverymgo,
            bnkRedeliveryQtyTolerancehs: selectedVesssel?.bnkRedeliveryQtyTolerancehs,
            bnkRedeliveryQtyTolerancels: selectedVesssel?.bnkRedeliveryQtyTolerancels,
            bnkRedeliveryQtyTolerancemgo: selectedVesssel?.bnkRedeliveryQtyTolerancemgo,
            revenue: selectedVesssel?.revenue,
            miscRevenue: selectedVesssel?.miscRevenue,

          },
        }
      ],

      "clientQuery": {
        "qtyMode": "MOLOO",
        "fuelPriceOverridden": false,
        "cargoList": [
          {
            "label": null,
            "voyType": null,
            "account": null,
            "firstLoadPort": null,
            "lastDischargePort": null,
            "cargo": null,
            "freight": null,
            "tcEq": null,
            "tcGbb": null,
            "hire": "",
            "hireGbb": null,
            "profit": null,
            "duration": null,
            "freightMode": null,
            "laycanBegin": null,
            "laycanEnd": null,
            "broker": null,
            "lumpsumFreight": 0,
            "stowFactor": 0,
            "multiParcelMode": null,
            "operation": "Commence",
            "port": selectedVesssel?.port,
            "cargoQty": 0,
            "tolerance": 0,
            "rate": 0,
            "terms": "SHINC",
            "turnTime": 0,
            "pdaRemarks": null,
            "grade": null,
            "adcom": 0,
            "brokerage": 0,
            "ttlComm": 0,
            "netFrt": 0,
            "min": 0,
            "max": 0,
            "factor": 1,
            "seaTime": 0,
            "portExp": 0,
            "portStay": 0,
            "extraDays": 0,
            "supply": 0,
            "sulfur": null,
            "loadline": null,
            "dwf": 0,
            "draftRes": 0,
            "waterDensity": 0,
            "other": 0,
            "dischargeQty": 0,
            "speedNormal": 0,
            "speedECA": 0,
            "cargoTx": 0,
            "cargoTxOverriddenValue": 0,
            "arrivalTime": "N/A",
            "departureTime": null,
            "arrivalHsfo": 0,
            "arrivalLsfo": 0,
            "arrivalMgo": 0,
            "departureHsfo": 0,
            "departureLsfo": 0,
            "departureMgo": 0,
            "index": 0,
            "lbmarker": "",
            "lat": selectedVesssel?.lat || null,
            "lng": selectedVesssel?.lng || null,
            "country": null,
            "portId": null,
            "notes": null,
            "cargoDetails": {
              "distNormal": 0,
              "distEca": 0,
              "bnkrFactor": 0,
              "speedReduce": 0,
              "speedFactor": 0,
              "arrivalBunkersHSFO": 0,
              "arrivalBunkersLSFO": 0,
              "arrivalBunkersMGO": 0,
              "bunkerSuppliedHSFO": 0,
              "bunkerSuppliedLSFO": 0,
              "bunkerSuppliedMGO": 0,
              "bunkerNonScrubberHSFO": 0,
              "bunkerNonScrubberLSFO": 0,
              "bunkerNonScrubberMGO": 0,
              "bunkerScrubberHSFO": 0,
              "bunkerScrubberLSFO": 0,
              "bunkerScrubberMGO": 0,
              "reserve": "Reserve",
              "paidBy": "External",
              "fuelPriceType0Hsfo": 0,
              "fuelPriceType0Lsfo": 0,
              "fuelPriceType0Mgo": 0,
              "fuelPriceType1Hsfo": 0,
              "fuelPriceType1Lsfo": 0,
              "fuelPriceType1Mgo": 0,
              "fuelPriceType2Hsfo": 0,
              "fuelPriceType2Lsfo": 0,
              "fuelPriceType2Mgo": 0,
              "fuelPriceType3Hsfo": 0,
              "fuelPriceType3Lsfo": 0,
              "fuelPriceType3Mgo": 0,
              "fuelPriceType4Hsfo": 0,
              "fuelPriceType4Lsfo": 0,
              "fuelPriceType4Mgo": 0
            },
            "distanceOverridden": false,
            "isExtra": false,
            "version": 0,
            "system": true
          },
          {
            "label": null,
            "voyType": null,
            "account": null,
            "firstLoadPort": null,
            "lastDischargePort": null,
            "cargo": null,
            "freight": "0",
            "tcEq": null,
            "tcGbb": null,
            "hire": "",
            "hireGbb": null,
            "profit": null,
            "duration": null,
            "freightMode": null,
            "laycanBegin": null,
            "laycanEnd": null,
            "broker": null,
            "lumpsumFreight": 0,
            "stowFactor": 0,
            "multiParcelMode": null,
            "operation": "Delivery-out",
            "port": selectedVesssel?.port,
            "cargoQty": 0,
            "tolerance": 0,
            "rate": 0,
            "terms": "SHINC",
            "turnTime": 0,
            "pdaRemarks": null,
            "grade": null,
            "adcom": 0,
            "brokerage": 0,
            "ttlComm": 0,
            "netFrt": 0,
            "min": 0,
            "max": 0,
            "factor": 1,
            "seaTime": 0,
            "portExp": 0,
            "portStay": 0,
            "extraDays": 0,
            "supply": 0,
            "sulfur": "Normal",
            "loadline": "S-Summer",
            "dwf": 0,
            "draftRes": 0,
            "waterDensity": 0,
            "other": 0,
            "dischargeQty": 0,
            "speedNormal": 0,
            "speedECA": 0,
            "cargoTx": null,
            "cargoTxOverriddenValue": 0,
            "arrivalTime": "N/A",
            "departureTime": null,
            "arrivalHsfo": null,
            "arrivalLsfo": null,
            "arrivalMgo": null,
            "departureHsfo": null,
            "departureLsfo": null,
            "departureMgo": null,
            "index": 0,
            "lbmarker": null,
            "lat": selectedVesssel?.lat || null,
            "lng": selectedVesssel?.lng || null,
            "country": null,
            "portId": null,
            "notes": null,
            "distanceOverridden": false,
            "isExtra": true,
            "version": 0,
            "system": true,
            "cargoDetails": {
              "distNormal": 0,
              "distEca": 0,
              "bnkrFactor": 0,
              "speedReduce": 0,
              "speedFactor": 0,
              "arrivalBunkersHSFO": 0,
              "arrivalBunkersLSFO": 0,
              "arrivalBunkersMGO": 0,
              "bunkerSuppliedHSFO": 0,
              "bunkerSuppliedLSFO": 0,
              "bunkerSuppliedMGO": 0,
              "bunkerNonScrubberHSFO": 0,
              "bunkerNonScrubberLSFO": 0,
              "bunkerNonScrubberMGO": 0,
              "bunkerScrubberHSFO": 0,
              "bunkerScrubberLSFO": 0,
              "bunkerScrubberMGO": 0,
              "reserve": "Reserve",
              "paidBy": "External",
              "fuelPriceType0Hsfo": 0,
              "fuelPriceType0Lsfo": 0,
              "fuelPriceType0Mgo": 0,
              "fuelPriceType1Hsfo": 0,
              "fuelPriceType1Lsfo": 0,
              "fuelPriceType1Mgo": 0,
              "fuelPriceType2Hsfo": 0,
              "fuelPriceType2Lsfo": 0,
              "fuelPriceType2Mgo": 0,
              "fuelPriceType3Hsfo": 0,
              "fuelPriceType3Lsfo": 0,
              "fuelPriceType3Mgo": 0,
              "fuelPriceType4Hsfo": 0,
              "fuelPriceType4Lsfo": 0,
              "fuelPriceType4Mgo": 0
            }
          },
          {
            "label": null,
            "voyType": null,
            "account": null,
            "firstLoadPort": null,
            "lastDischargePort": null,
            "cargo": null,
            "freight": "0",
            "tcEq": null,
            "tcGbb": null,
            "hire": "",
            "hireGbb": null,
            "profit": null,
            "duration": null,
            "freightMode": null,
            "laycanBegin": null,
            "laycanEnd": null,
            "broker": null,
            "lumpsumFreight": 0,
            "stowFactor": 0,
            "multiParcelMode": null,
            "operation": "Redelivery-in",
            "port": selectedVesssel?.port,
            "cargoQty": 0,
            "tolerance": 0,
            "rate": 0,
            "terms": "SHINC",
            "turnTime": 0,
            "pdaRemarks": null,
            "grade": null,
            "adcom": 0,
            "brokerage": 0,
            "ttlComm": 0,
            "netFrt": 0,
            "min": 0,
            "max": 0,
            "factor": 1,
            "seaTime": 0,
            "portExp": 0,
            "portStay": 0,
            "extraDays": 0,
            "supply": 0,
            "sulfur": "Normal",
            "loadline": "S-Summer",
            "dwf": 0,
            "draftRes": 0,
            "waterDensity": 0,
            "other": 0,
            "dischargeQty": 0,
            "speedNormal": 0,
            "speedECA": 0,
            "cargoTx": null,
            "cargoTxOverriddenValue": 0,
            "arrivalTime": "N/A",
            "departureTime": null,
            "arrivalHsfo": null,
            "arrivalLsfo": null,
            "arrivalMgo": null,
            "departureHsfo": null,
            "departureLsfo": null,
            "departureMgo": null,
            "index": 0,
            "lbmarker": null,
            "lat": selectedVesssel?.lat || null,
            "lng": selectedVesssel?.lng || null,
            "country": null,
            "portId": null,
            "notes": null,
            "distanceOverridden": false,
            "isExtra": true,
            "version": 0,
            "system": true,
            "cargoDetails": {
              "distNormal": 0,
              "distEca": 0,
              "bnkrFactor": 0,
              "speedReduce": 0,
              "speedFactor": 0,
              "arrivalBunkersHSFO": 0,
              "arrivalBunkersLSFO": 0,
              "arrivalBunkersMGO": 0,
              "bunkerSuppliedHSFO": 0,
              "bunkerSuppliedLSFO": 0,
              "bunkerSuppliedMGO": 0,
              "bunkerNonScrubberHSFO": 0,
              "bunkerNonScrubberLSFO": 0,
              "bunkerNonScrubberMGO": 0,
              "bunkerScrubberHSFO": 0,
              "bunkerScrubberLSFO": 0,
              "bunkerScrubberMGO": 0,
              "reserve": "Reserve",
              "paidBy": "External",
              "fuelPriceType0Hsfo": 0,
              "fuelPriceType0Lsfo": 0,
              "fuelPriceType0Mgo": 0,
              "fuelPriceType1Hsfo": 0,
              "fuelPriceType1Lsfo": 0,
              "fuelPriceType1Mgo": 0,
              "fuelPriceType2Hsfo": 0,
              "fuelPriceType2Lsfo": 0,
              "fuelPriceType2Mgo": 0,
              "fuelPriceType3Hsfo": 0,
              "fuelPriceType3Lsfo": 0,
              "fuelPriceType3Mgo": 0,
              "fuelPriceType4Hsfo": 0,
              "fuelPriceType4Lsfo": 0,
              "fuelPriceType4Mgo": 0
            }
          }
        ],
      },
      "autoSave": false,
      vesselVesselLabel: saveVesselVesselLabel
    }
    setLoader(true);
    actions
      .createVesselVessel(newVesselVessel)
      .then((res) => {
        setLoader(false);
        onCloseVesselVessel()
        toastSuccess("vessel vessel calc. create successfully");
        history.push(`/vessel-vessel/matrix/${res?.data?._id}`);

      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });


  }
  return (
    <Grid container>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} className="mb-3">
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            width="100%"
            position="relative"
          >
            <Paper
              elevation={1}
              sx={{
                borderRadius: 2,
                overflow: 'hidden',
                width: { xs: '100%', sm: 'auto' }
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                indicatorColor="primary"
                textColor="primary"
                sx={{
                  minHeight: '48px',
                  '& .MuiTab-root': {
                    minWidth: 100,
                    textTransform: 'none',
                    fontWeight: 600
                  }
                }}
              >
                <Tab
                  value={0}
                  label="Vessel List"
                />
              </Tabs>
            </Paper>
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '-25px',
                  transform: 'translateX(-50%)',
                  color: "#3f51b5"
                }}

              >
                Add Vessel with
              </Box>
              <Paper
                elevation={2}
                sx={{
                  borderRadius: 2,
                  overflow: 'hidden',
                  width: { xs: '100%', sm: 'auto' }
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  indicatorColor="primary"
                  textColor="primary"
                  sx={{
                    minHeight: '48px',
                    '& .MuiTab-root': {
                      minWidth: 100,
                      textTransform: 'none',
                      fontWeight: 600
                    }
                  }}
                >
                  <Tab
                    value={1}
                    label="Manual"
                  />
                  <Tab
                    value={2}
                    label="Auto"
                  />
                  <Tab
                    value={3}
                    label="Users"
                  />
                  <Tab
                    value={4}
                    label="AIS"
                  />
                  <Tab
                    value={5}
                    label="Markets"
                  />
                </Tabs>
              </Paper>
            </Box>
            {value === 1 && (
              <Box
                sx={{
                  marginLeft: 'auto' // This pushes the button to the right
                }}
              >
                <CustomButton
                  variant="contained"
                  color="primary"
                  onClick={() => onClickAddFleet()}
                  sx={{
                    textTransform: 'none',
                    borderRadius: 2,
                    padding: '10px 20px'
                  }}
                >
                  Create Vessel
                </CustomButton>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <div className="add-fleet-modal-container w-[inherit]">
        <TabPanel value={value} index={0} className="px-0">
          <TableContainer component={Paper}>
            <Table className="universal-table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ paddingRight: "0px" }}>Show <br />in Calc.</TableCell>
                  <TableCell>No.</TableCell>
                  <TableCell>Vessel Name</TableCell>
                  <TableCell>DWT</TableCell>
                  <TableCell>Port</TableCell>
                  <TableCell>Date Opening</TableCell>
                  <TableCell>Hire Per Day</TableCell>
                  <TableCell>Hire GBB</TableCell>
                  <TableCell>Remark</TableCell>
                  <TableCell>Created on</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(fleets) && fleets.length ? (
                  fleets.map((item, index) => {
                    return (
                      <TableRow
                        onDoubleClick={() => {
                          onClickEditVessel(item);
                        }}
                        key={index}>
                        <TableCell>
                          <Checkbox
                            checked={!vesselManager?.vesselIds?.includes(item?._id)}
                            onChange={(e) => onChangeVesselManager(e, item)}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </TableCell>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.vesselName || ""}</TableCell>
                        <TableCell>{item.dwt || 0}</TableCell>
                        <TableCell>{item.port || ""}</TableCell>
                        <TableCell>
                          {moment(item.dateOpen).format("DD/MMM/yyyy")}
                        </TableCell>
                        <TableCell>{item.hirePerDay || ""}</TableCell>
                        <TableCell>{item.hireGbb || 0}</TableCell>
                        <TableCell>{item.remark || ""}</TableCell>
                        <TableCell>
                          {item.createdDate
                            ? moment(item.createdDate).format("DD/MMM/yyyy")
                            : ""}
                        </TableCell>{" "}
                        <TableCell>
                          {item?.createdUser
                            ? `${item.createdUser?.firstName} ${item.createdUser?.lastName}`
                            : ""}
                        </TableCell>
                        <TableCell>
                          <div className="query-actions">
                            <VisibilityIcon
                              color="primary"
                              onClick={() => {
                                onClickEditVessel(item);
                              }}
                            />

                            <span className="pending-steper m-0 validate-icon-vessel-manager">
                              <Switch
                                checked={item?.liveShare}
                                onChange={() => onLiveSharingChange(item, !item?.liveShare)}
                                color="primary"
                                size="small"
                              />
                            </span>
                            <div className="tooltip-notification-vessel-manager">
                              <p>Live share with team</p>
                            </div>
                            <>
                              <span className="pending-steper m-1 validate-icon-vessel-manager">
                                <ShareIcon
                                  color="primary"
                                  onClick={() => onShareOrgClick(item)}
                                />
                              </span>
                              <div className="tooltip-notification-vessel-manager">
                                <p>Copy Share with your Team</p>
                              </div>
                            </>


                            <>
                              <span className="pending-steper validate-icon-vessel-manager">

                                <ExitToAppIcon
                                  style={{
                                    color: "black",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => onClickVesselVessel(item)}
                                />
                              </span>
                              <div className="tooltip-notification-vessel-manager mr-0 ml-0">
                                <p>Add to vessel vessel matrix</p>
                              </div>
                            </>
                            <DeleteIcon
                              color="secondary"
                              onClick={() => onClickDeleteSavedFleet(item._id)}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="8" align="center" className="!bg-white">
                      No Fleet Found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <CustomPagination
              onChangePagination={onChangeMyPagination}
              filters={myFilters}
              totalPage={myFilters?.totalPages}
            />
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div className="bg-neutrals9 p-5 h-[calc(100vh-196px)] w-[calc(100vw-104px)] rounded-md">
            <AutoAddFleet
              onAddFleetLine={onAddFleetLine}
              addFleetsArray={addFleetsArray}
              saveVesselListByIndex={saveVesselListByIndex}
              onChangeNewFleetsValue={onChangeNewFleetsValue}
              delayVesselSearch={delayVesselSearch}
              vesselLoading={vesselLoading}
              savePortListByIndex={savePortListByIndex}
              delayPortSearch={delayPortSearch}
              portLoading={portLoading}
              onDeleteVesselLine={onDeleteVesselLine}
              onSaveAllVessels={onSaveAllVessels}
              setValidationVessel={setValidationVessel}
              setValidationVesselIndex={setValidationVesselIndex}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <div className="bg-neutrals9 p-5 h-[calc(100vh-196px)] w-[calc(100vw-104px)] rounded-md">
            <ManualAddFleet
              updatedSinceLastOptions={updatedSinceLastOptions}
              autoFilters={autoFilters}
              onChangeAutoFilters={onChangeAutoFilters}
              onSubmitAutoSearch={onSubmitAutoSearch}
              emailFleetList={emailFleetList}
              selectedAutoFleet={selectedAutoFleet}
              onChangeDateOpening={onChangeDateOpening}
              onAutoFleetListCheckBox={onAutoFleetListCheckBox}
              allSelected={allSelected}
              addFleetRegionOptions={addFleetRegionOptions}
              onSelectAllAutoFleet={onSelectAllAutoFleet}
              setValidationVesselFromAuto={setValidationVesselFromAuto}
              setValidationVesselFromAutoIndex={setValidationVesselFromAutoIndex}
              savePortListByIndex={savePortListByIndex}
              delayPortSearch={delayPortSearch}
              portLoading={portLoading}
              onChangeNewPortValue={onChangeNewPortValue}
              onSaveAllVessels={onSaveAllVesselsFromAuto}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <div className="bg-neutrals9 p-5 h-[calc(100vh-196px)] w-[calc(100vw-104px)] rounded-md">
            <UserCopyFleet
              setLoader={setLoader}
              // getInitalQueryAndFleetData={getInitalQueryAndFleetData}
              onClickAddFleet={onClickAddFleet}
              getAllFleets={getAllFleets}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <div className="bg-neutrals9 p-5 h-[calc(100vh-196px)] w-[calc(100vw-104px)] rounded-md">
            Coming Soon
          </div>
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <div className="bg-neutrals9 p-5 h-[calc(100vh-196px)] w-[calc(100vw-104px)] rounded-md">
            Coming Soon
          </div>
        </TabPanel>
      </div>

      <CustomShowModal
        open={onValidationVesselFromAuto}
        disableClose
        handleClose={() => setValidationVesselFromAuto(false)}
        maxWidth="lg"
        title="Update Details"
      >
        <EditVesselOnFleetAddStep setValidationVesselFromAuto={setValidationVesselFromAuto} setAddFleetsArray={setEmailFleetList} addFleetsArray={emailFleetList} onValidationVesselIndex={onValidationVesselFromAutoIndex} onClickAddFleet={onClickAddFleet} isFromCreateVessel={true} />
      </CustomShowModal>
      <CustomShowModal
        open={open}
        disableClose
        handleClose={() => setOpen(false)}
        maxWidth="lg"
        title="Update Details"
      >

        <EditVesselOnFleetAddStep setValidationVesselFromAuto={onUpdateMyVessel} setValidationVessel={setValidationVessel} setAddFleetsArray={setAddFleetsArray} addFleetsArray={[singleResults]} onValidationVesselIndex={0} isFromCreateVessel={true} />
      </CustomShowModal>

      <CustomShowModal
        open={onValidationVessel}
        disableClose
        handleClose={() => setValidationVessel(false)}
        maxWidth="lg"
        title="Update Details"
      >
        <EditVesselOnFleetAdd setValidationVessel={setValidationVessel} setAddFleetsArray={setAddFleetsArray} addFleetsArray={addFleetsArray} onValidationVesselIndex={onValidationVesselIndex} onClickAddFleet={onClickAddFleet} isFromCreateVessel={true} />
      </CustomShowModal>
      <CustomShowModal
        open={addFleetModal}
        disableClose
        handleClose={onClickAddFleet}
        maxWidth="lg"
        title="Create Vessel"
      >

        <SingleVessel onClickAddFleet={onClickAddFleet} isFromCreateVessel={true} />
        {/* <AddFleetModal
          //   calculatorData={calculatorData}
          //   setCalculatorData={setCalculatorData}
          addFleetsArray={addFleetsArray}
          setAddFleetsArray={setAddFleetsArray}
          //   onChangeNewFleetsValue={onChangeNewFleetsValue}
          setLoader={setLoader}
          setAddFleetModal={setAddFleetModal}
          //   sendCalculationRequest={sendCalculationRequest}
          //   getInitalQueryAndFleetData={getInitalQueryAndFleetData}
          onClickAddFleet={onClickAddFleet}
          getAllFleets={getAllFleets}
        /> */}
      </CustomShowModal>
      <CustomConfirmModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        message="Are you sure to delete this fleet?"
        onSubmitConfirm={onDeleteFleet}
      />
      <CustomAddModal
        open={isShareConfirm}
        handleClose={onShareOrgClick}
        onSubmitConfirm={onShareOrgSaveClick}
        maxWidth="xs"
        saveLabel="Share"
        title="Share with Team"
      >
        <ShareWithOrg
          value={shareLabel}
          onChange={(e) => setShareLabel(e.target.value)}
          placeholder="Enter Vessel name*"
        />
      </CustomAddModal>

      <CustomAddModal
        open={vesselVesselModal}
        handleClose={onCloseVesselVessel}
        onSubmitConfirm={onClickSaveVesselVessel}
        maxWidth="xs"
        saveLabel="Save"
        title="Save to Vessel Vessel Matrix"
      >
        <SaveCargoLabel
          value={saveVesselVesselLabel}
          onChange={(e) => setSaveVesselVesselLabel(e.target.value)}
          placeholder="Enter Vessel-Vessel Matrix label"
        />
      </CustomAddModal>
    </Grid>
  );
}

export default MyVessel;
