import React from "react";
import { Document, Page, pdf } from "@react-pdf/renderer";
import Button from "@material-ui/core/Button";
import { GrDocumentPdf } from "react-icons/gr";
import CargoPdf from "../Calculator/CargoPdf";

const DowloadPDF = (props) => {
  const { pdfDocument, pdfName, portOperations, query, onDownloadPdf = () => { } } = props;

  const downloadFile = (filename, url) => {
    let element = document.createElement("a");
    element.href = url;
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const pdfdetails = (pdfSaveId) => {
    return <CargoPdf portOperations={portOperations} query={query} pdfSaveId={pdfSaveId} />
  }
  const downloadPDF = async () => {
    const data = await onDownloadPdf()
    if(data?.data?._id){
      
    const details = await pdfdetails(data?.data?._id)
      const pdfBlob = await pdf(
        <Document>
          <Page size="A4" orientation="landscape">
            {details}
          </Page>
        </Document>
      ).toBlob();
      const url = window.URL.createObjectURL(
        new Blob([pdfBlob], { type: "application/pdf" })
      );
      downloadFile(pdfName, url);
    }
  };

  return (
    <div>
      <Button
        onClick={downloadPDF}
        variant="outlined"
        className="text-center py-1 page-name text-neutrals9 px-3 rounded-md text-sm flex items-center gap-1 mb-2 ml-3"
        autoFocus
      >
        <div className="flex items-center gap-2 text-transform-initial-pdf">
          <GrDocumentPdf />
          Download PDF
        </div>
      </Button>
    </div>
  );
};

export default DowloadPDF;
