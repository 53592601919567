import React, { useState } from "react";
import {
  Grid,
  Table,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TextField,
  CircularProgress,
  Switch,
} from "@material-ui/core";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CustomPagination from "../Common/CustomPagination";
function EmailTableContainer(props) {
  const {
    emailParserData = [],
    onSearchByOrgId = () => { },
    selectedEmail = {},
    savePortListByIndex,
    selectedPort,
    delayPortSearch,
    onChangeNewPortValue,
    portLoading,
    onChangeAutoVessel,
    onChangePagination = () => { },
    filters = () => { },
    totalPage,
  } = props;

  return (
    <Grid container className="m-t-20">
      <Grid
        item
        xs={12}
        className="mui-selected-grey"
        style={{ height: "280px", overflow: "auto" }}
      >
        <TableContainer component={Paper} className="vessel-selection-table auto-add-from-mail">
        <CustomPagination
            onChangePagination={onChangePagination}
            filters={filters}
            totalPage={totalPage}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Vessel</TableCell>
                <TableCell>Dwt</TableCell>
                <TableCell>Built</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Region</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Gears</TableCell>
                <TableCell>Sender</TableCell>
                <TableCell>Updated On</TableCell>
                <TableCell>Add to Auto Vessel</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {emailParserData.length ? (
                emailParserData.map((email, index) => {
                  const {
                    _id = "",

                    nearestport = {},
                    nearestDate = {},

                    // portName = "",
                    // portRegion = "",
                    // createdDate = "",

                    sender = "",
                    updatedAt = "",
                  } = email;

                  const {
                    vesselName = "",
                    vesselDwt = "",
                    vesselBuilt = "",
                    vesselGears = "" } = email
                  return (
                    <TableRow
                      key={_id}
                      hover
                      style={{ cursor: "pointer" }}
                      selected={selectedEmail._id === _id}
                      onClick={() => onSearchByOrgId(email)}
                    >
                      <TableCell>{vesselName}</TableCell>
                      <TableCell>{vesselDwt}</TableCell>
                      <TableCell>{vesselBuilt}</TableCell>
                      <TableCell>
                        {/* {nearestport?.name} */}

                        <Autocomplete
                          options={
                            (savePortListByIndex[index] &&
                              savePortListByIndex[index]) ||
                            []
                          }
                          value={nearestport}
                          getOptionLabel={(option) => option.portLabel}
                          disableClearable
                          onChange={(e, value) =>
                            onChangeNewPortValue(index, value)
                          }
                          className="autocomplete-mui"
                          getOptionSelected={(option, value) =>
                            option.portLabel === value.portLabel
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Search Port"
                              value={nearestport && nearestport?.portLabel}
                              onChange={(e) =>
                                delayPortSearch(index, e.target.value)
                              }
                              size="small"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {(portLoading[index] &&
                                      portLoading[index]) ||
                                      false ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={15}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>{nearestport?.area}</TableCell>
                      <TableCell>
                        {/* {nearestDate?.date} */}
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            inputVariant="outlined"
                            size="small"
                            format="DD MMM YYYY"
                            className="date-time-picker"
                            style={{ width: 150 }}
                            value={moment(nearestDate?.date)}
                            onChange={(date) =>
                              onChangeNewPortValue(index, date, "isDate")
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </TableCell>
                      <TableCell>{vesselGears}</TableCell>
                      <TableCell>{sender}</TableCell>
                      <TableCell>{moment(updatedAt).format("DD MMM YYYY HH:mm:A")}</TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <Switch
                          checked={email?.showInAutoVessel}
                          onChange={(e) => onChangeAutoVessel(email?._id, e.target.checked, index)}
                          color="primary"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={10} align="center" className="!bg-white">
                    No data found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
         
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default EmailTableContainer;
