import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ListAltIcon from "@material-ui/icons/ListAlt";
import EmailIcon from "@material-ui/icons/Email";
import dashboardBlack from "./assets/flaticons/dashboard-black.png";
import dashboardWhite from "./assets/flaticons/dashboard-white.png";
import orderBlack from "./assets/flaticons/order-black.png";
import orderWhite from "./assets/flaticons/order-white.png";
import calculatorBlack from "./assets/flaticons/calculator-black.png";
import calculatorWhite from "./assets/flaticons/calculator-white.png";
import emailBlack from "./assets/flaticons/email-parser-black.png";
import emailWhite from "./assets/flaticons/email-parser-white.png";
import voyagesBlack from "./assets/flaticons/voyages-black.png";
import voyagesWhite from "./assets/flaticons/voyages-white.png";
import Ship from "./assets/flaticons/ship.png";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import bookWhite from "./assets/flaticons/book-white.png";
import bookBlack from "./assets/flaticons/book-black.png";
import cargocargoBlack from "./assets/flaticons/cargo-cargo-black.png";
import cargocargoWhite from "./assets/flaticons/cargo-cargo-white.png";
import distanceBlack from "./assets/flaticons/distance-black.png";
import distanceWhite from "./assets/flaticons/distance-white.png";
import vesselvesselBlack from "./assets/flaticons/vesselvessel-black.png";
import vesselvesselWhite from "./assets/flaticons/vesselvessel-white.png";
import cargoAnalysisBlack from "./assets/flaticons/cargo-analysis-black.png";
import cargoAnalysisWhite from "./assets/flaticons/cargo-analysis-white.png";
import supportWhite from "./assets/flaticons/support-white.png";
import supportBlack from "./assets/flaticons/support-black.png";
import userDashboardWhite from "./assets/flaticons/user-dashboard-white.png";
import userDashboardBlack from "./assets/flaticons/user-dashboard-black.png";

const keys = require("./config/keys");

// export const API_URL = "http://54.221.198.168:8083"
// export const API_URL = "https://fleetsigma.com"
// export const API_URL = "http://fs-staging.fleetsigma.com"
// export const API_URL = "http://localhost:8080";
// export const API_URL = "https://voy-calc-be-git-13may24-manoj-kumars-projects-0a428b84.vercel.app"
// export const API_URL = "https://voy-calc.vercel.app";
export const API_URL = "https://dryapi.smartseafreight.com";

export const API_BASE_URL = keys.apiBaseUrl;

export const ACCESS_TOKEN = "accessToken";

export const OAUTH2_REDIRECT_URI = keys.redirectUri;

export const GOOGLE_AUTH_URL =
  API_BASE_URL + "/api/auth/google?redirect_uri=" + OAUTH2_REDIRECT_URI;

export const CargoVideoUrl = "https://devsmartseafreight.s3.ap-south-1.amazonaws.com/documents/outpu26+(1).webm"

//leaflet js
export const userDrawerList = [
  {
    label: "Dashboard",
    url: "/dashboard",
    shortLabel: "Dashboard",
    blackImg: userDashboardBlack,
    whiteImg: userDashboardWhite,
    icon: <ImportContactsIcon />,
    activeRoutes: ["/dashboard"],
  },
  {
    label: "Dashboard",
    url: "/",
    shortLabel: "D",
    icon: <DashboardIcon />,
    blackImg: dashboardBlack,
    whiteImg: dashboardWhite,
    activeRoutes: [
      "/",
      "/organization",
      "/userOrganization",
      "/vesselInformation",
      "/vessel/:id",
    ],
    description:
      "You can manage users, organizations and vessels from dashboard.",
  },
  {
    label: "Support",
    url: "/supportinformation",
    shortLabel: "support",
    blackImg: supportBlack,
    whiteImg: supportWhite,
    icon: <ImportContactsIcon />,
    activeRoutes: ["/supportinformation"],
    description:
      "You can manage user complaints from support page.",
  },
  {
    label: "Freight Order",
    url: "/orders",
    shortLabel: "FO",
    blackImg: orderBlack,
    whiteImg: orderWhite,
    icon: <ShoppingCartIcon />,
    activeRoutes: ["/orders"],
    description:
      "Create a freight order of transporting goods from one location to another",
  },
  {
    label: "My Matrix",
    url: "/calculator",
    shortLabel: "C",
    blackImg: calculatorBlack,
    whiteImg: calculatorWhite,
    icon: <ListAltIcon />,
    activeRoutes: ["/calculator"],
    description:
      "Calculate the cost of transporting goods from one location to another",
  },
  {
    label: "E-mail Reader",
    url: "/emailParser",
    shortLabel: "EP",
    blackImg: emailBlack,
    whiteImg: emailWhite,
    icon: <EmailIcon />,
    activeRoutes: ["/emailParser"],
  },
  {
    label: "My Voyages",
    url: "/voyages",
    shortLabel: "MV",
    blackImg: voyagesBlack,
    whiteImg: voyagesWhite,
    icon: <ShoppingCartIcon />,
    activeRoutes: ["/voyages"],
    description:
      "Add Voyage details that will carry goods from one location to another",
  },
  {
    label: "Welcome",
    url: "/welcome",
    shortLabel: "HOME",
    blackImg: emailBlack,
    whiteImg: emailWhite,
    icon: <EmailIcon />,
    activeRoutes: ["/welcome"],
  },
  {
    label: "Vessel Manager",
    url: "/vesselManager",
    shortLabel: "C",
    blackImg: Ship,
    whiteImg: Ship,
    icon: <Ship />,
    activeRoutes: ["/vesselManager"],
    description:
      "Create collection of voyage used to transport goods to different locations",
  },
  {
    label: "Vessel Vessel Matrix",
    url: "/vessel-vessel-matrix",
    shortLabel: "VVM",
    blackImg: vesselvesselBlack,
    whiteImg: vesselvesselWhite,
    icon: <vesselvesselBlack />,
    activeRoutes: ["/vessel-vessel-matrix"],
  },
  {
    label: "Cargo Cargo Matrix",
    url: "/cargo-cargo-matrix",
    shortLabel: "CCM",
    blackImg: cargocargoBlack,
    whiteImg: cargocargoWhite,
    icon: <cargocargoBlack />,
    activeRoutes: ["/cargo-cargo-matrix"],
  },
  {
    label: "Cargo Analysis",
    url: "/cargo-analysis",
    shortLabel: "CA",
    blackImg: cargoAnalysisBlack,
    whiteImg: cargoAnalysisWhite,
    icon: <cargoAnalysisBlack />,
    activeRoutes: ["/cargo-analysis"],
  },

  {
    label: "Distance Matrix",
    url: "/distance-matrix",
    shortLabel: "DM",
    blackImg: distanceBlack,
    whiteImg: distanceWhite,
    icon: <distanceBlack />,
    activeRoutes: ["/distance-matrix"],
  },
  {
    label: "Address Book",
    url: "/addressBook",
    shortLabel: "AB",
    blackImg: bookBlack,
    whiteImg: bookWhite,
    icon: <ImportContactsIcon />,
    activeRoutes: ["/addressBook"],
  },
  {
    label: "Support",
    url: "/support",
    shortLabel: "support",
    blackImg: supportBlack,
    whiteImg: supportWhite,
    icon: <ImportContactsIcon />,
    activeRoutes: ["/support"],
  },
];

export const adminDrawerList = [
  {
    label: "Dashboard",
    url: "/",
    icon: <DashboardIcon />,
    activeRoutes: [
      "/",
      "/userOrganization",
      "/organization",
      "/vesselInformation",
    ],
  },
];

export const createVesselOptions = [
  {
    label: "Built",
    name: "vesselBuilt",
    type: "number",
  },
  {
    label: "Flag",
    name: "vesselFlag",
    type: "type",
  },
  {
    label: "LOA",
    name: "vesselLOA",
    type: "number",
  },
  {
    label: "GRT",
    name: "vesselGRT",
    type: "number",
  },
  {
    label: "NGT",
    name: "vesselNRT",
    type: "number",
  },
  {
    label: "IMO",
    name: "vesselIMO",
    type: "number",
  },
  {
    label: "Class",
    name: "vesselClass",
    type: "text",
  },
  {
    label: "Bale Capacity",
    name: "vesselBaleCap",
    type: "number",
  },
  {
    label: "Gears",
    name: "vesselGears",
    type: "text",
  },
  {
    label: "Grabs",
    name: "vesselGrabs",
    type: "text",
  },
  {
    label: "Holds",
    name: "vesselHolds",
    type: "number",
  },
  {
    label: "CO2",
    name: "vesselCO2",
    type: "text",
  },
  {
    label: "AHL",
    name: "vesselAHL",
    type: "text",
  },
  {
    label: "Ice Class",
    name: "vesselIceClass",
    type: "text",
  },
  {
    label: "Shipyard",
    name: "vesselShipyard",
    type: "text",
  },
  {
    label: "Ship owner",
    name: "vesselShipowner",
    type: "text",
  },
  {
    label: "Ship country",
    name: "vesselCountry",
    type: "text",
  },
  {
    label: "Light Ship",
    name: "vesselLightShip",
    type: "number",
  },
];

export const createTableQuery = [
  {
    label: "Operation",
    name: "operation",
    type: "select",
  },
  {
    label: "Port",
    name: "port",
    type: "text",
  },
  {
    label: "Cargo Qty",
    name: "cargoQty",
    type: "number",
  },
  {
    label: "Tolerance",
    name: "tolerance",
    type: "number",
  },
  {
    label: "Rate MT/Day",
    name: "rate",
    type: "number",
  },
  {
    label: "Turn Time",
    name: "turnTime",
    type: "number",
  },
  {
    label: "PDA Remarks",
    name: "pdaRemarks",
    type: "text",
  },
  {
    label: "Grade/ Remarks",
    name: "grade",
    type: "text",
  },
  {
    label: "Freight",
    name: "freight",
    type: "number",
  },
  {
    label: "Adcom",
    name: "adcom",
    type: "number",
  },
  {
    label: "Other",
    name: "other",
    type: "number",
  },
  {
    label: "Brokerage",
    name: "brokerage",
    type: "number",
  },
  {
    label: "TTL Comm",
    name: "ttlComm",
    type: "number",
  },
  {
    label: "Net Freight",
    name: "netFrt",
    type: "number",
  },
  {
    label: "Min",
    name: "min",
    type: "number",
  },
  {
    label: "Max",
    name: "max",
    type: "number",
  },
  {
    label: "Account",
    name: "account",
    type: "text",
  },
  {
    label: "Cargo",
    name: "cargo",
    type: "text",
  },
  {
    label: "Stow Factor",
    name: "stowFactor",
    type: "number",
  },
];

export const accountCargoFields = [
  {
    label: "Account",
    value: "account",
  },
  {
    label: "Cargo",
    value: "cargo",
  },
  {
    label: "Laycan start",
    value: "laycanStartDate",
  },
  {
    label: "Laycan end",
    value: "laycanEndDate",
  },
  {
    label: "Adcom",
    value: "adcom",
  },
  {
    label: "Brokerage",
    value: "brokerage",
  },
  {
    label: "Others",
    value: "other",
  },
  {
    label: "Sf of cargo",
    value: "stowFactor",
  },
  {
    label: "Cargo Min",
    value: "min",
  },
  {
    label: "Cargo Max",
    value: "max",
  },
  {
    label: "freight Mode",
    value: "freightMode",
  },
  {
    label: "Consultant",
    value: "Tag1",
  },
  {
    label: "Consultancy",
    value: "Tag2",
  },
  {
    label: "Consultant",
    value: "Tag3",
  },
  {
    label: "Consultancy",
    value: "Tag4",
  },
];

export const vesselCalculatorMainFields = [
  {
    label: "DWT (S)",
    name: "vesselDwt",
    type: "number",
  },
  {
    label: "Draft (S)",
    name: "vesselDraftS",
    type: "number",
  },
  {
    label: "TPC",
    name: "vesselTPC",
    type: "number",
  },
  {
    label: "Grain",
    name: "vesselGrainCap",
    type: "number",
  },
  {
    label: "Built",
    name: "vesselBuilt",
    type: "number",
  },
  {
    label: "LOA",
    name: "vesselLOA",
    type: "number",
  },
  {
    label: "Beam",
    name: "vesselBeam",
    type: "number",
  },
  {
    label: "GRT",
    name: "vesselGRT",
    type: "number",
  },
  {
    label: "NRT",
    name: "vesselNRT",
    type: "number",
  },
  {
    label: "Light Ship",
    name: "vesselLightShip",
    type: "number",
  },
  {
    label: "Bale Capacity",
    name: "vesselBaleCap",
    type: "number",
  },
  {
    label: "Gears",
    name: "vesselGears",
    type: "text",
  },
  {
    label: "Grabs",
    name: "vesselGrabs",
    type: "text",
  },
  {
    label: "Constants",
    name: "Constants",
    type: "text",
  },
  {
    label: "Fuel Type",
    name: "fuelType",
    type: "text",
  },
  {
    label: "Scrubber Fitted",
    name: "vesselScrubber",
    type: "text",
  },
];

export const marginAndAllowancesOptions = [
  {
    label: "DWF",
    value: "dwf",
    type: "number",
    unitType: "%",
  },
  {
    label: "Speed Perf Factor",
    value: "Speed Perf Factor",
    type: "number",
    unitType: "%",
  },
  {
    label: "Speed Reduce",
    value: "Speed Reduce",
    type: "number",
    unitType: "Knots",
  },
  {
    label: "Bunker Con Allowance",
    value: "Bunker Con Allowance",
    type: "number",
    unitType: "%",
  },
];

export const expenseResultField = [
  {
    label: "CVE(per30days)",
    value: "cve",
    type: "text",
  },
  {
    label: "Insurance(Per Day)",
    value: "insurance",
    type: "text",
  },
  {
    label: "On/Off Hire Survey",
    value: "hireSurvey",
    type: "text",
  },
  {
    label: "Canal Expenses",
    value: "canalExpenses",
    type: "text",
  },
  {
    label: "Additional Premium",
    value: "additionalPremium",
    type: "text",
  },
  {
    label: "ILOHC",
    value: "ilohc",
    type: "text",
  },
  {
    label: "Manoevring Exp",
    value: "manoevringExp",
    type: "text",
  },
  {
    label: "Misc Exp",
    value: "miscExp",
    type: "text",
  },
  // {
  //     label: "Total",
  //     value: "Total",
  //     type: "text",
  //     disabled: true
  // }
];

export const resultsFields = [
  {
    label: "Frt Cost",
    value: "frtCost",
  },
  {
    label: "Profit PMT",
    value: "profitPmt",
  },
  {
    label: "Profit Net",
    value: "profitNet",
  },
  {
    label: "Net Frt Revenue",
    value: "netFrtRevenue",
  },
  {
    label: "Hire Exp",
    value: "hireExpense",
  },
  {
    label: "Fuel Expenses",
    value: "fuelExpense",
  },
  {
    label: "Others",
    value: "otherExpense",
  },
  {
    label: "HS Qty",
    value: "hsQuantity",
  },
  {
    label: "LS Qty",
    value: "lsQuantity",
  },
  {
    label: "MGO Qty",
    value: "mgoQuantity",
  },
  {
    label: "Voy Duration",
    value: "voyageDuration",
  },
  {
    label: "Port Stay",
    value: "portStayTotal",
  },
  {
    label: "Stowage",
    value: "stowage",
  },
  {
    label: "Fuel Settle Profit",
    value: "fuelSettlementProfit",
  },
];

export const vesselVesselRevenueResultsFields = [
  {
    label: "TCO Gross Hire",
    value: "TCOGrossHire",
  },
  {
    label: "TCO Hire Commission",
    value: "TCOHireCommission",
  },
  {
    label: "Misc Revenue",
    value: "miscRevenue",
  },
  {
    label: "Fuel Settlement Profit",
    value: "FuelSettlementProfit",
  },
  {
    label: "Fuel Hand-Over Profit",
    value: "FuelHandOverProfit",
  },
  {
    label: "Total Revenue",
    value: "totalRevenue",
  },
];
export const vesselVesselExpenseResultsFields = [
  {
    label: "Fuel Expenses",
    value: "fuelExpenses",
  },
  {
    label: "Hire Expenses",
    value: "hireExpenses",
  },
  {
    label: "Port Expenses",
    value: "portExpenses",
  },
  {
    label: "Others",
    value: "others",
  },
  {
    label: "Total Expenses",
    value: "totalExpenses",
  },
];
export const vesselVesselResultsFields = [
  {
    label: "Profit",
    value: "profit",
  },
  {
    label: "Profit Per Day",
    value: "profitPerDay",
  },
  {
    label: "Voy Duration",
    value: "voyDuration",
  },
  {
    label: "HS Qty",
    value: "hsQty",
  },
  {
    label: "LS Qty",
    value: "lsQty",
  },

  {
    label: "MGO Qty",
    value: "mgoQty",
  },
];

export const cargocargoRevenueResultsFields = [
  {
    label: "Freight",
    value: "totalGrossFreightAmountRevenue",
  },
  {
    label: "Adcom",
    value: "totalAdcomAmountRevenue",
  },
  {
    label: "Others (Tax)",
    value: "totalOthersAmountRevenue",
  },
  {
    label: "Brokerage",
    value: "totalBrokerageAmountRevenue",
  },
  {
    label: "Net Freight Revenue",
    value: "totalNetFreightRevenue",
  },
  {
    label: "Laytime Revenue",
    value: "totalLaytimeRevenueRevenue",
  },
  {
    label: "Net Revenue",
    value: "totalNetRevenue",
  },
];

export const cargocargoExpenseResultsFields = [
  {
    label: "Freight",
    value: "totalGrossFreightAmountExpense",
  },
  {
    label: "Adcom",
    value: "totalAdcomAmountExpense",
  },
  {
    label: "Others (Tax)",
    value: "totalOthersAmountExpense",
  },
  {
    label: "Brokerage",
    value: "totalBrokerageAmountExpense",
  },
  {
    label: "Net Freight Expense",
    value: "totalNetFreightExpense",
  },
  {
    label: "Laytime Expense",
    value: "totalLaytimeRevenueExpense",
  },
  {
    label: "Net Expense",
    value: "totalNetExpense",
  },
];

export const netProfitResultsFields = [
  {
    label: "Total Net Profit",
    value: "netProfit",
  },
  {
    label: "Total Freight Profit",
    value: "totalNetFreight",
  },
  {
    label: "Total Laytime Profit",
    value: "totalLaytimeProfit",
  },
];

export const portOperationHeader = [
  {
    label: "Add Opr..",
    width: 30,
    paddingLeft: "4px",
  },
  {
    label: "",
    width: 30,
  },
  {
    label: "B/L",
    width: 30,
  },
  {
    label: "Operation",
    width: 90,
  },
  {
    label: "Port",
    width: 80,
  },
  {
    label: "Dist Normal",
    width: 50,
  },
  {
    label: "Dist ECA",
    width: 40,
  },
  {
    label: "DWF",
    width: 40,
  },
  {
    label: "Speed Normal",
    width: 50,
  },
  {
    label: "Speed ECA",
    width: 50,
  },
  {
    label: "Cargo Tx",
    width: 60,
  },
  {
    label: "Rate MT/day",
    width: 60,
  },
  {
    label: "Terms",
    width: 90,
  },
  {
    label: "Factor",
    width: 60,
  },
  {
    label: "Turn time(d)",
    width: 50,
  },
  {
    label: "Extra days",
    width: 50,
  },
  {
    label: "Port Exp",
    width: 60,
  },
  {
    label: "Port stay",
    width: 60,
  },
  {
    label: "Port Emission",
    width: 60,
  },
  {
    label: "Loadline",
    width: 80,
  },
  {
    label: "Draft Res",
    width: 40,
  },
  {
    label: "Water Den",
    width: 40,
  },
  {
    label: "Supply",
    width: 40,
  },
  {
    label: "",
    width: 30,
  },
];

export const vesselVesselPortOperationHeader = [
  {
    label: "Add Opr..",
    width: 30,
    paddingLeft: "4px",
  },
  {
    label: "",
    width: 30,
  },
  // {
  //   label: "B/L",
  //   width: 30,
  // },
  {
    label: "Operation",
    width: 90,
  },
  {
    label: "Port",
    width: 80,
  },
  {
    label: "Dist Normal",
    width: 50,
    header: "Distance",
  },
  {
    label: "Dist ECA",
    width: 40,
    header: "Covered",
  },
  {
    label: "DWF",
    width: 40,
  },
  {
    label: "Arrival Time",
    width: 120,
    header: "Arrival",
  },
  {
    label: "HSFO",
    width: 50,
    header: "Arrival",
    justifyContent: "center",
  },
  {
    label: "LSFO",
    width: 50,
    header: "Fuel",
  },
  {
    label: "MGO",
    width: 50,
  },
  {
    label: "HSFO",
    width: 40,
    header: "Fuel",
    justifyContent: "center",
  },
  {
    label: "LSFO",
    width: 50,
    header: "Supplied",
  },
  {
    label: "MGO",
    width: 50,
    header: "at Port",
  },
  {
    label: "TripReserve",
    width: 70,
  },
  {
    label: "Paid By",
    width: 65,
  },
  {
    label: "Supply",
    width: 50,
  },
  {
    label: "HSFO",
    width: 40,
    header: "Fuel",
    justifyContent: "center",
  },
  {
    label: "LSFO",
    width: 40,
    header: "Prices",
  },
  {
    label: "MGO",
    width: 50,
    dynamicHeader: true,
  },
  {
    label: "Extra days",
    width: 40,
  },
  {
    label: "Port Exp",
    width: 40,
  },
  {
    label: "Port Emission",
    width: 50,
  },
  {
    label: "Departure Time",
    width: 120,
    header: "Departure",
    justifyContent: "center",
  },
  {
    label: "HSFO",
    width: 50,
    header: "Departure",
    // justifyContent:"center"
  },
  {
    label: "LSFO",
    width: 40,
    header: "Fuel",
    justifyContent: "center",
  },
  {
    label: "MGO",
    width: 40,
  },
];

export const CargoCargoOperationHeader = [
  {
    label: "Operation",
    width: 90,
    paddingLeft: "8px",
  },
  {
    label: "Port",
    width: 120,
  },
  {
    label: "Min",
    width: 80,
  },
  {
    label: "Max",
    width: 60,
  },
  {
    label: "Cargo",
    width: 60,
  },
  {
    label: "Stow Factor",
    width: 80,
  },
  {
    label: "Laycan Start",
    width: 150,
  },
  {
    label: "Laycan End",
    width: 150,
  },
  {
    label: "Actual Cargo Qty",
    width: 80,
  },
  {
    label: "Arrival Time",
    width: 150,
  },
  {
    label: "Departure Time",
    width: 140,
  },
  {
    label: "Port Stay Duration",
    width: 80,
  },
];

export const CargoCargoRevenueExpenseHeader = [
  {
    label: "Operation",
    width: 90,
    paddingLeft: "8px",
  },
  {
    label: "Port",
    width: 120,
  },
  {
    label: "Rate MT/Day",
    width: 80,
  },
  {
    label: "Terms",
    width: 80,
  },
  {
    label: "Turn Time",
    width: 80,
  },
  {
    label: "Freight Rate",
    width: 80,
  },
  {
    label: "Adcom",
    width: 60,
  },
  {
    label: "Other (Tax, etc)",
    width: 80,
  },
  {
    label: "Brokerage",
    width: 60,
  },
  {
    label: "CounterParty",
    width: 120,
  },
  {
    label: "Demurrage Rate",
    width: 80,
  },
  {
    label: "Despatch Rate",
    width: 80,
  },
  {
    label: "Additional Revenue",
    width: 80,
  },
  {
    label: "Additional Expense",
    width: 80,
  },
  {
    label: "Laytime Incurred",
    width: 80,
  },
];

export const portOperationDisabledFields2 = {
  Commence: {
    operation: true,
    cargoQuantity: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    draftRes: true,
    waterDensity: true,
    dwf: true,
    consumptionMargin: true,
    speedMargin: true,
    speedReduction: true,
    extraDays: true,
    loadline: true,
    distanceFromPreviousPort: true,
    ecaLowSulphurDistanceFromLastPort: true,
    portEmission: true,
    portExpenses: true,
    reserve: true,
    hsfo: true,
    lsfo: true,
    mgo: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
  },
  Loading: {
    operation: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
  },
  Passing: {
    cargoQuantity: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
    canDelete: true,
  },
  Waiting: {
    cargoQuantity: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
    canDelete: true,
  },
  Bunkering: {
    cargoQuantity: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
    canDelete: true,
  },
  Redelivery: {
    cargoQuantity: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
    canDelete: true,
  },
  Relay: {
    cargoQuantity: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
    canDelete: true,
  },
  Discharging: {
    operation: true,
    cargoQuantity: true,
    tolerance: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyAuto: true,
  },
};

export const portOperationDisabledFields = {
  Commence: {
    operation: true,
    cargoQty: true,
    tolerance: true,
    terms: true,
    other: true,
    bunkerSuppliedHSFO: true,
    bunkerSuppliedLSFO: true,
    bunkerSuppliedMGO: true,
    arrivalTime: true,
    currentHsfo: true,
    currentLsfo: true,
    currentMgo: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    draftRes: true,
    waterDensity: true,
    dwf: true,
    bnkrFactor: true,
    speedFactor: true,
    speedReduce: true,
    extraDays: true,
    loadline: true,
    distNormal: true,
    distEca: true,
    sulfur: true,
    portExp: true,
    reserve: true,
    bunkerSuppliedHSFO: true,
    bunkerSuppliedLSFO: true,
    bunkerSuppliedMGO: true,
    bunkerNonScrubberHSFO: true,
    bunkerNonScrubberLSFO: true,
    bunkerNonScrubberMGO: true,
    bunkerScrubberHSFO: true,
    bunkerScrubberLSFO: true,
    bunkerScrubberMGO: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
  },
  Loading: {
    operation: true,
    dischargeQty: true,
    cargoTxQtyAuto: true,
  },
  Passing: {
    cargoQty: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
    canDelete: true,
  },
  Waiting: {
    cargoQty: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
    canDelete: true,
  },
  Bunkering: {
    cargoQty: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
    canDelete: true,
  },
  Redelivery: {
    cargoQty: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
    canDelete: true,
  },
  Relay: {
    cargoQty: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
    canDelete: true,
  },
  Discharging: {
    operation: true,
    cargoQty: true,
    tolerance: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyAuto: true,
  },
  "Redelivery-in": {
    operation: true,
    cargoQty: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
  },
  "Delivery-out": {
    operation: true,
    cargoQty: true,
    tolerance: true,
    terms: true,
    other_tax: true,
    brokerage: true,
    account: true,
    cargo: true,
    stowFactor: true,
    freight: true,
    rate: true,
    turnTime: true,
    adcom: true,
    min: true,
    max: true,
    cargoTxQtyActual: true,
    cargoTxQtyAuto: true,
  },
};

export const termsCoEfficientTable = [
  {
    label: "SHINC",
    value: 1,
  },
  {
    label: "SHEX-12hrs",
    value: 1.0769,
  },
  {
    label: "SHEX-18hrs",
    value: 1.12,
  },
  {
    label: "SHEX-24hrs",
    value: 1.1667,
  },
  {
    label: "SHEX-30hrs",
    value: 1.2174,
  },
  {
    label: "SHEX-36hrs",
    value: 1.2727,
  },
  {
    label: "SHEX-38hrs",
    value: 1.2923,
  },
  {
    label: "SHEX-40hrs",
    value: 1.3125,
  },
  {
    label: "SHEX-42hrs",
    value: 1.3333,
  },
  {
    label: "SHEX-44hrs",
    value: 1.3548,
  },
  {
    label: "SHEX-48hrs",
    value: 1.4,
  },
  {
    label: "SHEX-55hrs",
    value: 1.4867,
  },
  {
    label: "SHEX-56hrs",
    value: 1.5,
  },
  {
    label: "SHEX-62hrs",
    value: 1.5849,
  },
  {
    label: "SHEX-63hrs",
    value: 1.6,
  },
  {
    label: "SHEX-68hrs",
    value: 1.68,
  },
  {
    label: "SHEX-72hrs",
    value: 1.75,
  },
  {
    label: "SHEX-84hrs",
    value: 2,
  },
  {
    label: "SHEX-96hrs",
    value: 2.3333,
  },
];

export const operationsOptions = [
  {
    label: "Commence",
    isDisabled: true,
  },
  {
    label: "Loading",
    isDisabled: true,
  },
  {
    label: "Passing",
    isDisabled: false,
  },
  {
    label: "Delivery-out",
    isDisabled: true,
  },
  {
    label: "Redelivery-in",
    isDisabled: true,
  },
  {
    label: "Waiting",
    isDisabled: false,
  },
  {
    label: "Bunkering",
    isDisabled: false,
  },
  {
    label: "Redelivery",
    isDisabled: false,
  },
  {
    label: "Relay",
    isDisabled: false,
  },
  {
    label: "Discharging",
    isDisabled: true,
  },
];

export const loadlineOptions = [
  { label: "S-Summer", value: "S-Summer" },
  { label: "TF-Tropical FW", value: "TF-Tropical FW" },
  { label: "F-FW", value: "F-FW" },
  { label: "T-Tropical", value: "T-Tropical" },
  { label: "W-Winter", value: "W-Winter" },
  { label: "WNA-Winter NA", value: "WNA-Winter NA" },
];

export const portEmissionOptions = [
  { label: "Normal", value: "Normal" },
  { label: "ECA", value: "ECA" },
];

export const initialCreatePortOperation = {
  no: "L",
  operation: "Bunkering",
  port: "",
  dist_Normal: 0,
  dist_eca: 0,
  dwf: 0,
  seepd_Normal: "",
  speed_eca: "",
  sea_Time: "",
  cargoQty: 0,
  rate: 0,
  terms: "SHINC",
  factor: "1.0000",
  turnTime: 0,
  extraDays: 0,
  portExp: 0,
  portStay: 0,
  sulfur: "Normal",
  loadline: "S-Summer",
  draftRes: 0,
  waterDensity: 0,
  supply: 0,
  arrival: "",
  departure: "",
  maxQty: 0,
  freight: 0,
  adCom: 0,
  brokerage: 0,
  queryId: "",
};

export const initialPortOperation = {
  account: "",
  adcom: 0,
  arrivalHsfo: "",
  arrivalLsfo: "",
  arrivalMgo: "",
  arrivalTime: "",
  broker: "",
  brokerage: 0,
  cargo: "",
  cargoDetails: {
    arrivalBunkersHSFO: 0,
    arrivalBunkersLSFO: 0,
    arrivalBunkersMGO: 0,
    bnkrFactor: 0,
    bunkerNonScrubberHSFO: 0,
    bunkerNonScrubberLSFO: 0,
    bunkerNonScrubberMGO: 0,
    bunkerScrubberHSFO: 0,
    bunkerScrubberLSFO: 0,
    bunkerScrubberMGO: 0,
    bunkerSuppliedHSFO: 0,
    bunkerSuppliedLSFO: 0,
    bunkerSuppliedMGO: 0,
    cargoId: "",
    distEca: 0,
    distNormal: 0,
    id: "",
    organizationId: "",
    paidBy: "Operator",
    reserve: "User",
    speedFactor: 0,
    speedReduce: 0,
  },
  cargoId: "",
  cargoQty: 0,
  cargoTx: "",
  country: "",
  createdDate: "",
  createdUser: "",
  departureHsfo: "",
  departureLsfo: "",
  departureMgo: "",
  departureTime: "",
  dischargeQty: 0,
  draftRes: 0,
  duration: "",
  dwf: 0,
  extraDays: 0,
  factor: 0,
  firstLoadPort: "",
  freight: "",
  freightMode: "",
  grade: "",
  hire: "",
  hireGbb: "",
  id: "",
  index: 0,
  label: "",
  lastDischargePort: "",
  lastModifiedDate: "",
  lastModifiedUser: "",
  lat: "",
  laycanBegin: "",
  laycanEnd: "",
  lbmarker: "",
  lng: "",
  loadline: "",
  lumpsumFreight: 0,
  max: 0,
  min: 0,
  multiParcelMode: "",
  netFrt: 0,
  notes: "",
  operation: "",
  organizationId: "",
  other: 0,
  pdaRemarks: "",
  port: "",
  portExp: 0,
  portId: "",
  portStay: 0,
  profit: "",
  queryId: "",
  rate: 0,
  seaTime: 0,
  speedECA: "",
  speedNormal: "",
  stowFactor: 0,
  sulfur: "",
  supply: 0,
  system: false,
  tcEq: "",
  tcGbb: "",
  terms: "",
  tolerance: 0,
  ttlComm: 0,
  turnTime: 0,
  version: 0,
  voyType: "",
  waterDensity: 0,
};

export const fuelPriceOptions = [
  {
    label: "Type-1",
    value: "TYPE0",
    newLabel: "Non-Scrubber",
  },
  {
    label: "Type-2",
    value: "TYPE1",
    newLabel: "Scrubber",
  },
  {
    label: "Type-3",
    value: "TYPE2",
    newLabel: "LNG",
  },
  {
    label: "Type-4",
    value: "TYPE3",
    newLabel: "Hydrogen",
  },
  {
    label: "Type-5",
    value: "TYPE4",
    newLabel: "Bio-Fuel",
  },
];

export const scrubberOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export const initialAddFleet = {
  checked: false,
  vesselName: "",
  dwt: 0,
  port: "",
  dateOpen: Date.now(),
  tcEq: 0,
  gbb: 0,
  hirePerDay: 0,
  hireGbb: 0,
  frtCost: 0,
  profit: 0,
  loadability: 0,
  arrivalLoadPort: "",
  extra_Exp: "",
  bod: 0,
  constan: 0,
  lat: 0,
  lng: 0,
  remark: "",
  vesselId: "",
  vessel: "",
  selectedVessel: {},
  selectedPort: {},
};

export const addFleetRegionOptions = [
  {
    label: "Unknown",
    value: "Unknown",
  },
  {
    label: "OTHERS",
    value: "OTHERS",
  },
  {
    label: "BALTIC",
    value: "BALTIC",
  },
  {
    label: "INDIA SUBCOUNT",
    value: "INDIA SUBCOUNT",
  },
  {
    label: "AUSTRALIA/NZ",
    value: "AUSTRALIA/NZ",
  },
  {
    label: "UKC",
    value: "UKC",
  },
  {
    label: "BLACK SEA",
    value: "BLACK SEA",
  },
  {
    label: "FAR EAST",
    value: "FAR EAST",
  },
  {
    label: "WC LATIN AMERICA",
    value: "WC LATIN AMERICA",
  },
  {
    label: "EAST/SOUTH AFRICA",
    value: "EAST/SOUTH AFRICA",
  },
  {
    label: "EC LATIN AMERICA",
    value: "EC LATIN AMERICA",
  },
  {
    label: "CARIBS/ECMEX",
    value: "CARIBS/ECMEX",
  },
  {
    label: "USAC/ECC",
    value: "USAC/ECC",
  },
  {
    label: "MED",
    value: "MED",
  },
  {
    label: "USWC/WCM",
    value: "USWC/WCM",
  },
  {
    label: "USG",
    value: "USG",
  },
  {
    label: "MIDDLE EAST",
    value: "MIDDLE EAST",
  },
  {
    label: "SOUTH EAST ASIA",
    value: "SOUTH EAST ASIA",
  },
  {
    label: "WAF",
    value: "WAF",
  },
];

export const addFleetVesselTypeOptions = [
  {
    label: "All",
    value: "0_99999999",
  },
  {
    label: "Mini Bulk 0-20000",
    value: "0_20000",
  },
  {
    label: "HandyBulk 20,000-40,000",
    value: "20001_40000",
  },
  {
    label: "Supra/Ultra 40,000-68,000",
    value: "40001_68000",
  },
  {
    label: "P/Pmx 68,000-100,000",
    value: "68001_100000",
  },
  {
    label: "Cape 100,000 & above",
    value: "100001_99999999",
  },
];

export const addFleetGearsOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Geared",
    value: "Geared",
  },
  {
    label: "Gearless",
    value: "Gearless",
  },
];

export const refreshCanalOptions = [
  {
    label: "Avoid Suez Canal",
    value: "suez",
  },
  {
    label: "Avoid Panama Canal",
    value: "panama",
  },
  {
    label: "Avoid Singapore",
    value: "singapore",
  },
  {
    label: "Avoid JWC",
    value: "jwc",
  },
  {
    label: "Avoid HRA",
    value: "hra",
  },
  {
    label: "Minimise ECA",
    value: "eca",
  },
];

export const addQueryInitialData = {
  name: "",
  cve: 0,
  insurance: 0,
  hireSurvey: 0,
  canalExpenses: 0,
  additionalPremium: 0,
  ilohc: 0,
  manoevringExp: 0,
  miscExp: 0,
  miscRevenue: 0,
  message: "",
  qtyMode: "MOLOO",
  freightMode: "Single Freight",
  access: "private",
  scrubberType: false,
  laycanStartDate: null,
  laycanEndDate: null,
  bnkDelhs: 0,
  bnkDells: 0,
  bnkDelmgo: 0,
};

export const queryPortDisabledFields = {
  Loading: {},
  Discharging: {
    min: true,
    max: true,
    cargoQty: true,
    tolerance: true,
    stowFactor: true,
    freight: true,
    adcom: true,
    other: true,
    brokerage: true,
    account: true,
    cargo: true,
  },
};

export const initialVesselState = {
  vesselAHL: "",
  vesselBalConsmHS1: 0,
  vesselBalConsmHS2: 0,
  vesselBalConsmHS3: 0,
  vesselBalConsmHS4: 0,
  vesselBalConsmLS1: 0,
  vesselBalConsmLS2: 0,
  vesselBalConsmLS3: 0,
  vesselBalConsmLS4: 0,
  vesselBalConsmMGO1: 0,
  vesselBalConsmMGO2: 0,
  vesselBalConsmMGO3: 0,
  vesselBalConsmMGO4: 0,
  vesselBalSpd1: 0,
  vesselBalSpd2: 0,
  vesselBalSpd3: 0,
  vesselBalSpd4: 0,
  vesselBaleCap: 0,
  vesselBeam: 0,
  vesselBuilt: 0,
  vesselCO2: "",
  vesselClass: "",
  vesselCountry: "",
  vesselDraftS: 0,
  vesselDwt: 0,
  vesselFlag: "",
  vesselGRT: 0,
  vesselGears: "",
  vesselGrabs: "",
  vesselGrainCap: 0,
  vesselHatches: 0,
  vesselHolds: 0,
  vesselIMO: 0,
  vesselIceClass: "",
  vesselLOA: 0,
  vesselLdnConsmHS1: 0,
  vesselLdnConsmHS2: 0,
  vesselLdnConsmHS3: 0,
  vesselLdnConsmHS4: 0,
  vesselLdnConsmLS1: 0,
  vesselLdnConsmLS2: 0,
  vesselLdnConsmLS3: 0,
  vesselLdnConsmLS4: 0,
  vesselLdnConsmMGO1: 0,
  vesselLdnConsmMGO2: 0,
  vesselLdnConsmMGO3: 0,
  vesselLdnConsmMGO4: 0,
  vesselLdnSpd1: 0,
  vesselLdnSpd2: 0,
  vesselLdnSpd3: 0,
  vesselLdnSpd4: 0,
  vesselLightShip: 0,
  vesselMMSI: 0,
  vesselNRT: 0,
  vesselName: "",
  vesselPDischConsmHS: 0,
  vesselPDischConsmLS: 0,
  vesselPDischConsmMGO: 0,
  vesselPIdleConsmHS: 0,
  vesselPIdleConsmLS: 0,
  vesselPIdleConsmMGO: 0,
  vesselPLdgConsmHS: 0,
  vesselPLdgConsmLS: 0,
  vesselPLdgConsmMGO: 0,
  fuelType: "TYPE0",
  vesselShipowner: "",
  vesselShipyard: "",
  vesselSource: "",
  vesselTPC: 0,
  vesselType: "",
  vesselVExtraConsmHS: 0,
  vesselVExtraConsmLS: 0,
  vesselVExtraConsmMGO: 0,
  vesselBalComb1Selected: false,
  vesselBalComb2Selected: false,
  vesselBalComb3Selected: false,
  vesselBalComb4Selected: false,
  vesselLadComb1Selected: false,
  vesselLadComb2Selected: false,
  vesselLadComb3Selected: false,
  vesselLadComb4Selected: false,
};

export const vesselTableHeading = [
  { id: "accordian", title: "", showOrder: false },
  { id: "vesselName", title: "Vessel Name", showOrder: false },
  { id: "dwt", title: "DWT", showOrder: false },
  { id: "port", title: "Port", showOrder: false },
  { id: "dateOpening", title: "Date Opening", showOrder: false },
  { id: "tcEq", title: "TC Eq", showOrder: true },
  { id: "gbb", title: "GBB", showOrder: false },
  { id: "hirePerDay", title: "Hire Per Day", showOrder: false },
  { id: "hireGbb", title: "Hire GBB", showOrder: false },
  { id: "frtCost", title: "Frt Cost", showOrder: true },
  { id: "profitNet", title: "Profit", showOrder: true },
  { id: "loadability", title: "Loadability", showOrder: true },
  { id: "eta", title: "ETA", showOrder: false },
  { id: "vesselPremium", title: "Vessel Premium", showOrder: true },
  { id: "positionPremium", title: "Position Premium", showOrder: true },
  { id: "remarks", title: "Remarks/Broker/etc", showOrder: false },
  { id: "actions", title: "Actions", showOrder: false },
];

export const vesselTableHeadingViewOnly = [
  { id: "checkbox", title: "checkbox", showOrder: true },
  { id: "vesselName", title: "Vessel Name", showOrder: true },
  { id: "dwt", title: "DWT", showOrder: true },
  { id: "port", title: "Port", showOrder: true },
  { id: "dateOpening", title: "Date Opening", showOrder: false },
  { id: "gbb", title: "GBB", showOrder: true },
  { id: "hirePerDay", title: "Hire Per Day", showOrder: true },
  { id: "hireGbb", title: "Hire GBB", showOrder: true },
  // { id: "eta", title: "ETA", showOrder: true },
  // { id: "rank", title: "Rank", showOrder: false },
  { id: "remarks", title: "Remarks/Broker/etc", showOrder: false },
  // { id: "actions", title: "Actions", showOrder: false },
];

export const vesselDetailsOption1 = [
  {
    label: "Flag",
    name: "vesselFlag",
    isExtra: false,
  },
  {
    label: "Class",
    name: "vesselClass",
    isExtra: false,
  },
  {
    label: "IMO Number",
    name: "vesselIMO",
    isExtra: false,
  },
  {
    label: "Holds",
    name: "vesselHolds",
    isExtra: false,
  },
  {
    label: "Hatches",
    name: "vesselHatches",
    isExtra: false,
  },
  {
    label: "CO2",
    name: "vesselCO2",
    isExtra: false,
  },
  {
    label: "AHL",
    name: "vesselAHL",
    isExtra: false,
  },
  {
    label: "Ice Class",
    name: "vesselIceClass",
    isExtra: false,
  },
  {
    label: "Suez GRT",
    name: "suezGRT",
    isExtra: true,
  },
  {
    label: "Suez NRT",
    name: "suezNRT",
    isExtra: true,
  },
  {
    label: "Panama NT",
    name: "panamaNT",
    isExtra: true,
  },
  {
    label: "IFO Capacity",
    name: "ifoCapacity",
    isExtra: true,
  },
  {
    label: "MGO Capacity",
    name: "mgoCapacity",
    isExtra: true,
  },
  {
    label: "FW Capacity",
    name: "fwCapacity",
    isExtra: true,
  },
];

export const vesselDetailsOption2 = [
  {
    label: "Type",
    name: "vesselType",
    isExtra: false,
  },
  {
    label: "Sub Type",
    name: "subType",
    isExtra: true,
  },
  {
    label: "Call Sign",
    name: "callSign",
    isExtra: true,
  },
  {
    label: "MMSI Number",
    name: "vesselMMSI",
    isExtra: false,
  },
  {
    label: "Ship Yard Built",
    name: "vesselShipyard",
    isExtra: false,
  },
  {
    label: "Ship Owner",
    name: "vesselShipowner",
    isExtra: false,
  },
  {
    label: "Ship Country",
    name: "vesselCountry",
    isExtra: false,
  },
  {
    label: "E-mail",
    name: "email",
    isExtra: true,
  },
  {
    label: "Phone 1",
    name: "phone1",
    isExtra: true,
  },
  {
    label: "Phone 2",
    name: "phone2",
    isExtra: true,
  },
  {
    label: "P & I Club",
    name: "pandIClub",
    isExtra: true,
  },
  {
    label: "Created Timestamp",
    name: "null",
    isExtra: true,
    isDisabled: true,
  },
  {
    label: "Last Updated Time",
    name: "lastModifiedDate",
    isExtra: false,
    isDisabled: true,
  },
  {
    label: "Last Updated By",
    name: "savedBy",
    isExtra: false,
    isDisabled: true,
  },
];

export const cargoFuelPriceStep = {
  notes: [
    "* 5 set of fuel prices can be used to calculate voyages, However you can enter only those prices which you intend to use for your vessels",
    "Type-1: Non-scrubber vessels (most prevalent)",
    "Type-2: Scrubber fitted (O)",
    "Type-3: Scrubber fitted (C)",
    "Type-4: LNG (or custom combination)",
    "Type-5: Ammonia (or custom combination)",
  ],
  comments: [
    "Main fuel is consumed in normal (0.5%) area, ECA fuel in ECA areas (0.1%). At any time, only one of them will be consumed.",
    "For our voyage calculations, we are concerned with only prices of the fuel to be used. (No worries about fuel grades and specs at this stage)",
    "As our system calculates and compares all vessels simultaneously, it is smart to enter all combination of fuel prices now.",
    "These fuel prices will be considered only in default mode of fuel calculation method. For all other methods, respective prices from vessel details shall be used.",
  ],
};

export const shortNameForAddressBook = [
  {
    label: "Shipping Agents",
    value: "shippingAgents",
  },
  {
    label: "Brokers",
    value: "brokers",
  },
  {
    label: "Charterers",
    value: "charterers",
  },
  {
    label: "Ship Owners",
    value: "shipOwners",
  },
  {
    label: "Fuel Suppliers",
    value: "fuelSuppliers",
  },
  {
    label: "Others",
    value: "others",
  },
  {
    label: "Port Service Supplier",
    value: "portServiceSupplier",
  },
  {
    label: "Port/Terminal",
    value: "portTerminal",
  },
  {
    label: "Receiver",
    value: "receiver",
  },
  {
    label: "Shipper",
    value: "shipper",
  },
];

export const individualDummyData = [
  {
    individual_title: "Mr.",
    individual_firstName: "John",
    individual_middleName: "Doe",
    individual_lastName: "Smith",
    individual_webPage: "https://johnsmith.com",
    individual_gender: "male",
    individual_birthdate: "1990-01-01",
    individual_anniversary: "2015-06-15",
    individual_location: "New York, USA",
    individual_language: "English",
    individual_emailPersonal: "john.smith@email.com",
    individual_emailPersonal2: "johndoe123@email.com",
    individual_mobilePersonal: "+1234567890",
    individual_mobilePersonal2: "+0987654321",
    individual_homeAddress: "123 Main St, Apt 4B",
    individual_personal_city: "New York",
    individual_personal_state: "NY",
    individual_personal_postalCode: "10001",
    individual_personal_country: "USA",
    individual_message: "Loream ipnus liomns",
    individual_company: "Tech Corp",
    individual_jobTitle: "Software Engineer",
    individual_department: "Engineering",
    individual_officeLocation: "Tech Corp Headquarters",
    individual_mobileNoOfficial: "+1122334455",
    individual_mobileNoOfficial2: "+2233445566",
    individual_emailOfficial: "john.smith@techcorp.com",
    individual_emailOfficial2: "john.doe@techcorp.com",
    individual_emailOfficial3: "j.smith@techcorp.com",
    individual_officeAddress: "456 Tech Blvd, Suite 200",
    individual_professional_city: "San Francisco",
    individual_professional_state: "CA",
    individual_professional_postalCode: "94105",
    individual_professional_country: "USA",
  },
];

export const organisationDummyData = [
  {
    organisation_fullName: "Tech Corp Inc.",
    organisation_fullStyle: "Corporation",
    organisation_typeTags: "Tech, Software, Engineering",
    organisation_officeLocation: "Tech Corp HQ, San Francisco",
    organisation_contactPerson: "Alice Johnson",
    organisation_contactPerson2: "Bob Williams",
    organisation_mobileOfficial: "+15551234567",
    organisation_mobileOfficial2: "+15559876543",
    organisation_officeAddress: "123 Tech Park, Suite 500",
    organisation_emailOfficial: "contact@techcorp.com",
    organisation_emailOfficial2: "support@techcorp.com",
    organisation_emailOfficial3: "info@techcorp.com",
    organisation_city: "San Francisco",
    organisation_state: "CA",
    organisation_postalCode: "94107",
    organisation_country: "USA",
    organisation_shortName: "TCI",
    organisation_notes:
      "Leading tech company specializing in software development.",
  },
];
