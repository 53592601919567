export const USERS_ORGANIZATION = "USERS_ORGANIZATION";
export const USERS_ORGANIZATION_SUCCESS = "USERS_ORGANIZATION_SUCCESS";
export const USERS_ORGANIZATION_FAILED = "USERS_ORGANIZATION_FAILED";

export const All_ROLES = "All_ROLES";
export const All_ROLES_SUCCESS = "All_ROLES_SUCCESS";
export const All_ROLES_FAILED = "All_ROLES_FAILED";

export const ALL_VESSELS = "ALL_VESSELS";
export const ALL_VESSELS_SUCCESS = "ALL_VESSELS_SUCCESS";
export const ALL_VESSELS_FAILED = "ALL_VESSELS_FAILED";

export const GET_ALL_ORGANIZATION = "GET_ALL_ORGANIZATION";
export const GET_ALL_ORGANIZATION_SUCCESS = "GET_ALL_ORGANIZATION_SUCCESS";
export const GET_ALL_ORGANIZATION_FAILED = "GET_ALL_ORGANIZATION_FAILED";

export const ALL_FLEETS_BY_ORG = "ALL_FLEETS_BY_ORG";
export const ALL_FLEETS_BY_ORG_SUCCESS = "ALL_FLEETS_BY_ORG_SUCCESS";
export const ALL_FLEETS_BY_ORG_FAILED = "ALL_FLEETS_BY_ORG_FAILED";

export const EMPTY_DASHBOARD_STORE = "EMPTY_DASHBOARD_STORE";

export const ALL_ADMIN_USERS = "ALL_ADMIN_USERS";
export const ALL_ADMIN_USERS_SUCCESS = "ALL_ADMIN_USERS_SUCCESS";
export const ALL_ADMIN_USERS_FAILED = "ALL_ADMIN_USERS_FAILED";
