import React, { useState, useEffect } from "react";
import { Grid, Box, Link } from "@material-ui/core";
import CustomTextField from "../../components/Common/CustomTextField";
import CustomButton from "../../components/Common/CustomButton";
import * as actions from "./actions";
import { useHistory, useLocation } from "react-router-dom";
import { toastError } from "../../utils";
const queryString = require("query-string");

const initialUser = {
  password: "",
  confirmPassword: "",
  passwordResetToken: "",
};

function ResetPassword() {
  const history = useHistory();
  const location = useLocation();

  const [user, setUser] = useState({ ...initialUser });
  const [loginLoader, setLoginLoader] = useState(false);
  // const [errors, setErrors] = useState({})

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.accessToken) {
      setUser({ ...user, passwordResetToken: parsed.accessToken });
    } else {
      history.push("/login");
    }
  }, []);

  const onChangeHandler = (e) => {
    const { name = "", value = "" } = e.target;
    setUser({ ...user, [name]: value });
    // setErrors({ ...errors, [name]: "" })
  };

  const keyPressed = (e) => {
    if (e.key === "Enter") {
      if (!loginLoader) {
        onConfirmEmail();
      }
    }
  };

  const onConfirmEmail = () => {
    if (!user.password || !user.confirmPassword) return;
    if (user.password !== user.confirmPassword)
      return toastError("Password mismatch");
    setLoginLoader(true);
    actions
      .updatePassword({
        password: user.password,
        accessToken: user.passwordResetToken,
      })
      .then((res) => {
        setLoginLoader(false);
      })
      .catch((err) => {
        setLoginLoader(false);
      });
  };

  return (
    <div className={"authBgImage bg-cover bg-no-repeat flex bg-center"}>
      <div className="flex flex-col justify-center md:items-start items-center m-3 gap-10 w-full">
        <div className="border border-neutral-100 w-full px-6 sm:w-[500px] bg-white py-[26px] rounded-[48px] border-solid flex flex-col gap-10">
          <div className="my-2 text-center flex flex-col gap-2">
            <div className="typo-30-500">Reset Password</div>
          </div>
          <div className="max-w-[344px] flex flex-col gap-10 mx-auto my-0 w-full">
            <div className="flex gap-4 flex-col">
              <CustomTextField
                name="password"
                placeholder="New Password"
                label="New Password"
                value={user.password}
                onChange={onChangeHandler}
              />
              <CustomTextField
                name="confirmPassword"
                placeholder="Confirm Password"
                label="Confirm Password"
                value={user.confirmPassword}
                onChange={onChangeHandler}
                onKeyPress={keyPressed}
              />
            </div>
            <div className="flex justify-center flex-col items-center gap-1">
              <CustomButton
                onClick={onConfirmEmail}
                loader={loginLoader}
                disabled={loginLoader}
                className="bg-blue py-7 rounded-full w-60 mt-2"
              >
                Reset Password
              </CustomButton>
              <div className="text-center mt-5">
                <div className="typo-16-400">
                  Back to?{" "}
                  <Link href="/login" className="text-blue">
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
