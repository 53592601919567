import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";

function checkStrEmpty(str) {
  return !(str && str.length > 1 && str.split(" ").join("").length > 0);
}

const styles = StyleSheet.create({
  main: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  PageHeader: {
    fontSize: "18px",
    paddingBottom: "25px",
    paddingLeft: "10px",
    paddingTop: "15px",
  },
  table: {
    width: "auto",
    paddingX: "2px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },

  tableCellHeader: {
    backgroundColor: "#3f51b5",
    fontSize: "6px",
    margin: 0,
    padding: "5px",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
  },
  tableCell: {
    backgroundColor: "#ededed",
    padding: "3px 6px 7px",
    textAlign: "center",
    paddingLeft: "2px",
    paddingRight: "0px",
    fontSize: "5px",
    flex: 1,
    color: "#555",
  },
  textCenter: {
    textAlign: "center",
  },
  cargodetails: {
    fontSize: "10px",
    marginBottom: "10px",
    paddingLeft: "10px",
  },
  cargoquery: {
    textAlign: "Right",
    fontSize: "4px",
    // color:"#fff"
  },
});

function CustomTablePDF(props) {
  const { fields = [], data = [], query = {}, pdfSaveId = "" } = props;
  let tableCol = {
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: "0.5px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    wordBreak: "break-all",
    overflowWrap: "break-word",
    whiteSpace: "normal",
  };
  const filteredFields = fields.filter((field) => !field?.hide);
  const tabledata = data.filter(
    (field) =>
      field?.operation === "Loading" || field?.operation === "Discharging"
  );
  return (
    <View style={styles.main}>
      <Text style={styles.cargoquery}>cargoqueryid-{pdfSaveId}#</Text>
      <Text style={[styles.PageHeader, { textAlign: "Left" }]}>Cargo List</Text>

      <View style={styles.table}>
        <View style={[styles.tableCellHeader, styles.tableRow]}>
          {filteredFields.map((_item, _idx) => (
            <View
              key={_idx}
              style={[
                {
                  width: _item.width + "%",
                  textAlign: "center",
                  color: "white",
                },
              ]}
            >
              <Text>{_item.title}</Text>
            </View>
          ))}
        </View>
        {tabledata.map((item, idx) => {
          if (!item) {
            return;
          }
          return (
            <View key={idx} style={styles.tableRow}>
              {filteredFields.map((_item, _idx) => {
                let val = item[_item.value] || "";
                let value_alt = _item.value_alt ? item[_item.value_alt] : "";
                if (_item.custom) {
                  return (
                    <View
                      key={_idx}
                      style={[tableCol, { width: _item.width + "%" }]}
                    >
                      <Text
                        style={[styles.tableCell, item.style ? item.style : {}]}
                      >
                        {_item.component(item)}
                      </Text>
                    </View>
                  );
                } else {
                  return (
                    <View style={[tableCol, { width: _item.width + "%" }]}>
                      <Text
                        style={[styles.tableCell, item.style ? item.style : {}]}
                      >
                        {/* {checkStrEmpty(val) ? value_alt : val || "-"} */}

                        {val ? val : value_alt ? value_alt : "-"}
                      </Text>
                    </View>
                  );
                }
              })}
            </View>
          );
        })}
      </View>
      <Text style={[styles.PageHeader, { textAlign: "Left" }]}>
        Cargo Details
      </Text>
      <View>
        <Text style={[styles.cargodetails, { textAlign: "Left" }]}>
          Name: {query?.name}
        </Text>
        <Text style={[styles.cargodetails, { textAlign: "Left" }]}>
          Freight Mode: {query?.freightMode}
        </Text>
        <Text style={[styles.cargodetails, { textAlign: "Left" }]}>
          Qty option: {query?.qtyMode}
        </Text>
        <Text style={[styles.cargodetails, { textAlign: "Left" }]}>
          Access Mode: {query?.access}
        </Text>
        <Text style={[styles.cargodetails, { textAlign: "Left" }]}>
          Laycan start date:{" "}
          {moment(query?.laycanStartDate).format("DD MMM yyyy HH:mm A")}
        </Text>
        <Text style={[styles.cargodetails, { textAlign: "Left" }]}>
          Laycan end date:{" "}
          {moment(query?.laycanEndDate).format("DD MMM yyyy HH:mm A")}
        </Text>
        <Text style={[styles.cargodetails, { textAlign: "Left" }]}>
          {query?.message}
        </Text>
      </View>
    </View>
  );
}

export default CustomTablePDF;
