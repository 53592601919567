import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  Fragment,
  useCallback,
} from "react";
import {
  Grid,
  Box,
  Select,
  MenuItem,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import CustomTextField from "../../../../components/Common/CustomTextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  addQueryInitialData,
  queryPortDisabledFields,
  termsCoEfficientTable,
} from "../../../../constants";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  getCurrentUserOrganizationId,
  toastError,
  toastSuccess,
} from "../../../../utils";
import moment from "moment";
import { useDispatch } from "react-redux";
import CustomShowModal from "../../../../components/Common/CustomShowModal";
import AddPortQueryModal from "../../../../components/Orders/AddPortQueryModal";
import DownloadPDF from "../../../../components/Common/DowloadPDF";
import CargoPdf from "../../../../components/Calculator/CargoPdf";
import { handleKeyDown } from "../../../../common/common";
import UploadPDFModal from "../../../../components/Common/UploadPDF";
import { GrDocumentPdf } from "react-icons/gr";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import PlusIcon from '../../../../assets/icons/plus.png'
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash";
import { tagsByCharAPI } from "../../Orders/actions";

const CargoDetails = forwardRef((props, ref) => {
  const {
    calculatorData = {},
    onChangeHandler = () => { },
    setLoader = () => { },
    onCloseModal = () => { },
    cargoCargo = "customerCargo",
    onChangeModelFields = () => { },
    selectedQuery = {},
    selectedPorts = [],
    selectedPortsCommence = [],
    isEdit = false,
    // isDetailView = false,
    onChangePinQuery = () => { },
    isFromSingleVesselCalcy = false,
    getInitialData = () => { },
    onShareOrgClick = () => { },
    tabvalue
  } = props;
  const organizationId = getCurrentUserOrganizationId();

  const addPortInitialData = [
    {
      cargoId: "",
      operation: "Loading",
      port: "",
      cargoQty: 0,
      tolerance: 0,
      rate: 0,
      terms: "SHINC",
      turnTime: 0,
      pdaRemarks: "",
      grade: "",
      freight: 0,
      adcom: 0,
      other: 0,
      brokerage: 0,
      ttlComm: 0,
      netFrt: 0,
      min: 0,
      max: 0,
      account: "",
      cargo: "",
      factor: 1,
      stowFactor: 0,
      queryId: "",
      loadline: "S-Summer",
      sulfur: "Normal",
    },
    {
      cargoId: "",
      operation: "Discharging",
      port: "",
      cargoQty: 0,
      tolerance: 0,
      rate: 0,
      terms: "SHINC",
      turnTime: 0,
      pdaRemarks: "",
      grade: "",
      freight: 0,
      adcom: 0,
      other: 0,
      brokerage: 0,
      ttlComm: 0,
      netFrt: 0,
      min: 0,
      max: 0,
      account: "",
      cargo: "",
      factor: 1,
      stowFactor: 0,
      queryId: "",
      loadline: "S-Summer",
      sulfur: "Normal",
    },
  ];

  const dispatch = useDispatch();

  const [portOperations, setPortOperations] = useState([...addPortInitialData]);
  const [query, setQuery] = useState({});
  const [portModal, setPortModal] = useState(false);
  const [selectedPort, setSelectedPort] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [getOpenUploadPDF, setOpenUploadPDF] = useState(false);
  const [pdfSaveId, setPdfSaveId] = useState("");

  const [tagsLoading, setTagsLoading] = useState(false);
  const [TagsOptions, setTagsOptions] = useState([]);

  const { id = "" } = useParams();
  // useEffect(() => {
  //   setPdfSaveId(uuidv4())
  // }, [])
  // useEffect(() => {
  //   if (Object.keys(selectedQuery).length) {
  //     setQuery(selectedQuery);
  //   }
  //   if (Array.isArray(selectedPorts) && selectedPorts.length) {
  //     setPortOperations(selectedPorts);
  //   }
  //   return () => {
  //     resetStateVariables();
  //   };
  // }, [selectedQuery, selectedPorts]);



  const onSearchTagsList = (value, type, name) => {
    if (value) {
      setTagsLoading(name);
      tagsByCharAPI(value, type)
        .then((res) => {
          setTagsLoading(false);
          let tagsList = [];
          if (res.data.list?.length > 0) {
            if (type === "individual") {
              tagsList = res.data.list.map((item) => ({
                label: `${item.individual_firstName} (${item?.individual_emailPersonal})`,
                value: item._id,
                name: item.individual_firstName
              }));
            } else {
              tagsList = res.data.list.map((item) => ({
                label: `${item.organisation_fullName} (${item?.organisation_emailOfficial})`,
                value: item._id,
                name: item.organisation_fullName
              }));
            }
          }
          setTagsOptions(tagsList);
        })
        .catch((err) => {
          setTagsLoading(false);
          setTagsOptions([]);
        });
    }
  };

  const debouncedSearch = useCallback(
    debounce((search, type, name) => {
      onSearchTagsList(search, type, name);
    }, 400),
    []
  );

  const delayTagsSearch = (search, type, name) => {
    // setSelectedPort({ ...selectedPort, port: search });
    debouncedSearch(search, type, name);
  };






  const onSelectTags = (value, name) => {
    onChangeModelFields(value, true, name, cargoCargo)
    setTagsOptions([])
  };

  const {
    message = "",
    qtyMode = "",
    Tag1 = "",
    Tag2 = "",
    Tag3 = "",
    Tag4 = "",

  } = calculatorData?.[cargoCargo] || {};


  let showMinMax = false;
  if (qtyMode === "MINMAX") showMinMax = true;

  return (
    <Fragment>
      <Grid container className="add-order-container">

        <Grid item xs={12} className="lower-input-grid mt-2 cargo-details-wrap">
          <div className="input-div">
            <div className="upper-item-div">
              <p>Name</p>
              <div className="inputs" style={{ width: "100%" }}>
                <CustomTextField
                  size="small"
                  placeholder="Enter Name"
                  value={calculatorData?.cargoCargoName}
                  name="cargoCargoName"
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            {/* <div className="upper-item-div">
              <p>Freight Mode</p>
              <div className="inputs">
                <Select
                  value={freightMode || ""}
                  onChange={onChangeHandler}
                  name="freightMode"
                  size="small"
                  variant="outlined"
                  fullWidth
                  className="custom-select"
                >
                  <MenuItem dense value="Single Freight">
                    Single Freight
                  </MenuItem>
                  <MenuItem dense value="Multiple Freight">
                    Multiple Freight
                  </MenuItem>
                </Select>
              </div>
            </div> */}
            {/* <div className="upper-item-div">
              <p>Qty option</p>
              <div className="inputs">
                <Select
                  value={qtyMode || ""}
                  onChange={onChangeHandler}
                  name="qtyMode"
                  size="small"
                  variant="outlined"
                  fullWidth
                  className="custom-select"
                >
                  <MenuItem dense value="MOLOO">
                    MOLOO
                  </MenuItem>
                  <MenuItem dense value="MINMAX">
                    MINMAX
                  </MenuItem>
                </Select>
              </div>
            </div> */}
            {/* <div className="upper-item-div">
              <p>Access Mode</p>
              <div className="inputs">
                <Select
                  value={access || ""}
                  onChange={onChangeHandler}
                  name="access"
                  size="small"
                  variant="outlined"
                  fullWidth
                  className="custom-select"
                >
                  <MenuItem dense value="private">
                    Private
                  </MenuItem>
                  <MenuItem dense value="public">
                    Public
                  </MenuItem>
                </Select>
              </div>
            </div> */}
            <div className="upper-item-div">
              <p>Consultant</p>
              <div className="inputs">
                <Autocomplete
                  options={
                    (tagsLoading === "tag1") || TagsOptions.length === 0 ? [] : TagsOptions
                  }
                  value={Tag1}
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => onSelectTags(value, "Tag1")}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      variant="outlined"
                      placeholder="Search Name"
                      onClick={() => { setTagsOptions([]) }}
                      value={Tag1}
                      onChange={(e) => delayTagsSearch(e.target.value, "individual", "tag1")}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {(tagsLoading === "tag1") ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="upper-item-div">
              <p>Consultancy</p>
              <div className="inputs">
                <Autocomplete
                  options={
                    (tagsLoading === "tag2") || TagsOptions.length === 0 ? [] : TagsOptions
                  }
                  value={Tag2}
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => onSelectTags(value, "Tag2")}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      variant="outlined"
                      placeholder="Search Name"
                      value={Tag1}
                      onClick={() => { setTagsOptions([]) }}
                      onChange={(e) => delayTagsSearch(e.target.value, "organisation", "tag2")}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {(tagsLoading === "tag2") ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="upper-item-div">
              <p>Consultant</p>
              <div className="inputs">
                <Autocomplete
                  options={
                    (tagsLoading === "tag3") || TagsOptions.length === 0 ? [] : TagsOptions
                  }
                  value={Tag3}
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => onSelectTags(value, "Tag3")}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      variant="outlined"
                      placeholder="Search Name"
                      value={Tag1}
                      onClick={() => { setTagsOptions([]) }}
                      onChange={(e) => delayTagsSearch(e.target.value, "individual", "tag3")}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {(tagsLoading === "tag3") ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="upper-item-div">
              <p>Consultancy</p>
              <div className="inputs">
                <Autocomplete
                  options={
                    (tagsLoading === "tag4") || TagsOptions.length === 0 ? [] : TagsOptions
                  }
                  value={Tag4}
                  disableClearable
                  getOptionLabel={(option) => option.label}

                  onChange={(e, value) => onSelectTags(value, "Tag4")}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      variant="outlined"
                      placeholder="Search Name"
                      onClick={() => { setTagsOptions([]) }}
                      value={Tag1}
                      onChange={(e) => delayTagsSearch(e.target.value, "organisation", "tag4")}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {(tagsLoading === "tag4") ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="textarea-div">
            <textarea
              className="add-order-textarea"
              placeholder="Please enter your cargo details here...."
              value={message}
              name="message"
              onChange={(e) => onChangeModelFields(e, false, "", cargoCargo)}
            />
          </div>
        </Grid>
        {/* <UploadPDFModal
          open={getOpenUploadPDF}
          handleClose={() => setOpenUploadPDF(false)}
          setPortOperations={setPortOperations}
          setQuery={setQuery}
          query={query}
        /> */}
        {/* <CustomShowModal
          open={portModal}
          handleClose={onClosePortModal}
          maxWidth="xs"
          disableClose
          title={`Change ${selectedPort.operation} Port`}
        >
          <AddPortQueryModal
            // onClosePortModal={onClosePortModal}
            selectedPort={selectedPort}
            setSelectedPort={setSelectedPort}
            onSavePortOperationsOfQuery={onSavePortOperationsOfQuery}
          />
        </CustomShowModal> */}
      </Grid>
    </Fragment>
  );
});

export default CargoDetails;
