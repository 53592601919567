import React, { useState, useEffect } from "react";
import ButtonAppBar from "../../../components/Main/ButtonAppBar";
import CustomDrawer from "../../../components/Main/CustomDrawer";
import { Box, Grid, 
  CircularProgress 
} from "@material-ui/core";
import * as dashboardActions from "../Dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomErrorAlert from "../../../components/Common/CustomErrorAlert";
import {
  getCurrentUserId,
  getRolePermisionRoutes,
  isAdminLogin,
} from "../../../utils";
import { userDrawerList } from "../../../constants";
import { useRouteMatch } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import clsx from "clsx";
// import CircularProgress from '../../../assets/icons/ship.gif'
function UserMain(props) {
  const { routeName = "" } = props;
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [loadingMsg, setlLoadingMsg] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const {
    errorList = [],
    errorTitle = "",
    errorOpen = false,
  } = useSelector((state) => state.common);
  const routeMatch = useRouteMatch();

  useEffect(() => {
    const organizationAdmin = isAdminLogin()
      ? { organizationAdmin: getCurrentUserId() }
      : {};
    dispatch(dashboardActions.getAllOrganizations(organizationAdmin));
  }, []);

  const onClickMenu = () => {
    setOpenDrawer(!openDrawer);
  };

  const onSetLoader = (action, loadingMessage = null) => {
    setLoader(action);
    if (loadingMessage) setlLoadingMsg(loadingMessage);
  };

  const validRoutes = userDrawerList.filter((item) => {
    return getRolePermisionRoutes().includes(item.url);
  });

  useEffect(() => {
    if (routeName !== "Matrix" && routeName!=="Saved Results") {
      setIsFullScreen(false);
    }
  }, [routeName]);

  return (
    <div className="root-main-theme">
      {loader ? (
        <div className="main-advanced-loader">
          <div className="loader-wrapper">
            {/* <img className="loader-wrapper-ship-img" src={CircularProgress} size={45} /> */}
            <CircularProgress/>
            {loadingMsg ? (
              <div>
                <p>{loadingMsg}</p>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      <CustomDrawer
        openDrawer={openDrawer}
        onClickMenu={onClickMenu}
        validRoutes={validRoutes}
        activeRoute={routeMatch.path}
      />{" "}
      <div className="flex bg-lightGray2 main-body">
        <span className="sidebar-main">
        <Sidebar
          drawerOpen={drawerOpen}
          drawerClose={() => setDrawerOpen((s) => !s)}
          validRoutes={validRoutes}
          setDrawerOpen={setDrawerOpen}
          activeRoute={routeMatch.path}
        /></span>
        <div className="flex flex-col main-content-wrapper">
          {/* {routeName !== "Matrix" && ( */}
            <ButtonAppBar
              onClickMenu={onClickMenu}
              title={routeName}
              validRoutes={validRoutes}
              activeRoute={routeMatch.path}
            />
           {/* )}  */}
          <Box
            className={clsx("h-full", {
              "px-6 md:px-6 py-6": !isFullScreen,
            })}
          >
            {React.cloneElement(props.children, {
              setLoader: onSetLoader,
              setIsFullScreen,
              loader,
            })}
          </Box>
        </div>
      </div>
      {/* <Grid container className="main-parent-container">
        <Grid item xs={12} style={{ height: "calc(100vh - 50px)" }}></Grid>
      </Grid> */}
      {errorOpen ? (
        <CustomErrorAlert errorList={errorList} errorTitle={errorTitle} />
      ) : null}
    </div>
  );
}

export default UserMain;
