import React from "react";
import {
  portOperationDisabledFields,
  operationsOptions,

  CargoCargoOperationHeader,
} from "../../../../constants";
import { handleKeyDown } from "../../../../common/common";
import { DateTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { TextField } from "@material-ui/core";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'; // Import Material-UI Icon


const styles = (index) => {
  const width = CargoCargoOperationHeader[index]?.width;
  return { minWidth: width, width };
};

const CargoPortList = ((props) => {
  let {
    combinedOperation = [],
    onChangePortOperations = () => { },

  } = props;


  return (
    <div className="sortable-div">
      {combinedOperation &&
        combinedOperation.map((operations, index) => {
          const checkDisabled =
            portOperationDisabledFields[operations.operation] || {};

          return (
            <div className="sortable-row-tr cargo-cargo-row" key={index} index={index}>

              <div className="operation-input-div cargo-cargo-div" style={styles(0)}>
                {/* {operations.operation || 0} */}
                <select
                  value={operations.operation || ""}
                  name="operation"
                  onChange={(e) => onChangePortOperations(index, e)}
                  disabled={checkDisabled.operation}
                >
                  {operationsOptions.map((option, key) => (
                    <option
                      key={key}
                      value={option.value}
                      disabled={option.isDisabled}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="operation-input-div cargo-cargo-div" style={styles(1)}>
                <p
                  className="add-three-dots"
                >
                  {operations.port || ""}
                </p>
              </div>

              <div className="operation-input-div cargo-cargo-div" style={styles(2)}>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.min || ""}
                  // disabled={!!checkDisabled.min}
                  name="min"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div cargo-cargo-div" style={styles(3)}>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.max || 0}
                  // disabled={!!checkDisabled.max}
                  name="max"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div cargo-cargo-div" style={styles(4)}>

                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.cargo || ""}
                  // disabled={!!checkDisabled.cargo}
                  name="cargo"
                  placeholder=""
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>

              <div className="operation-input-div cargo-cargo-div" style={styles(5)}>
                {/*  rate MT day */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.stowFactor || ""}
                  // disabled={!!checkDisabled.stowFactor}
                  name="stowFactor"
                  placeholder=""
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>

              <div className="operation-input-div cargo-cargo-div" style={styles(6)}>
                {/* {operations.portExp} */}
                {/* <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.laycanStartDate || ""}
                  // disabled={!!checkDisabled.laycanStartDate}
                  name="laycanStartDate"
                  placeholder=""
                  onChange={(e) => onChangePortOperations(index, e)}
                /> */}
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    size="small"
                    className="date-time-picker-cargo-cargo"
                    format="DD/MM/YYYY HH:mm"
                    style={{ width: 160 }}
                    value={operations.laycanStartDate || null}
                    onChange={(date) => onChangePortOperations(index, date, true, "laycanStartDate")}
                    inputProps={{
                      placeholder: "DD/MM/YYYY HH:MM",
                    }}
                    InputProps={{
                      style: { padding: 0 }, // Remove input padding
                    }}
                    keyboardIcon={
                      <CalendarTodayIcon
                        style={{
                          padding: 0,
                          margin: 0,
                          color: "gray",
                          display: "block",
                        }}
                      />
                    }
                    InputAdornmentProps={{
                      position: "end",
                      style: {
                        margin: 0
                      },
                    }}
                    KeyboardButtonProps={{
                      style: {
                        padding: 0,
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      },
                    }}
                    disableMaskedInput={false}
                  />
                </MuiPickersUtilsProvider>

              </div>
              <div className="operation-input-div cargo-cargo-div" style={styles(7)}>
                {/* <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.laycanEndDate || ""}
                  // disabled={!!checkDisabled.laycanEndDate}
                  name="laycanEndDate"
                  placeholder=""
                  onChange={(e) => onChangePortOperations(index, e)}
                /> */}
                {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    // inputVariant="outlined"
                    size="small"
                    className="date-time-picker-cargo-cargo"
                    format="DD/MMM/YYYY HH:mm"
                    style={{ width: 160 }}
                    value={operations.laycanEndDate || null}
                    onChange={(date) =>
                      onChangePortOperations(index, date, true, "laycanEndDate")
                    }
                  />
                </MuiPickersUtilsProvider> */}

                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    size="small"
                    className="date-time-picker-cargo-cargo"
                    format="DD/MM/YYYY HH:mm"
                    style={{ width: 160 }}
                    value={operations.laycanEndDate || null}
                    onChange={(date) => onChangePortOperations(index, date, true, "laycanEndDate")}
                    inputProps={{
                      placeholder: "DD/MM/YYYY HH:MM",
                    }}
                    InputProps={{
                      style: { padding: 0 }, // Remove input padding
                    }}
                    keyboardIcon={
                      <CalendarTodayIcon
                        style={{
                          padding: 0,
                          margin: 0,
                          color: "gray",
                          display: "block",
                        }}
                      />
                    }
                    InputAdornmentProps={{
                      position: "end",
                      style: {
                        margin: 0
                      },
                    }}
                    KeyboardButtonProps={{
                      style: {
                        padding: 0,
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      },
                    }}
                    disableMaskedInput={false}
                  />
                </MuiPickersUtilsProvider>

              </div>
              <div className="operation-input-div cargo-cargo-div" style={styles(8)}>
                {/*  Port Emission */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.actualCargoQty || 0}
                  // disabled={!!checkDisabled.actualCargoQty}
                  name="actualCargoQty"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div cargo-cargo-div" style={styles(9)}>
                {/* <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.arrivalTime || ""}
                  // disabled={!!checkDisabled.arrivalTime}
                  name="arrivalTime"
                  placeholder=""
                  onChange={(e) => onChangePortOperations(index, e)}
                /> */}
                {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    // inputVariant="outlined"
                    size="small"
                    className="date-time-picker-cargo-cargo"
                    format="DD/MMM/YYYY HH:mm"
                    style={{ width: 160 }}
                    value={operations.arrivalTime || null}
                    onChange={(date) =>
                      onChangePortOperations(index, date, true, "arrivalTime")
                    }
                  />
                </MuiPickersUtilsProvider> */}

                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    size="small"
                    className="date-time-picker-cargo-cargo"
                    format="DD/MM/YYYY HH:mm"
                    style={{ width: 160 }}
                    value={operations.arrivalTime || null}
                    onChange={(date) => onChangePortOperations(index, date, true, "arrivalTime")}
                    inputProps={{
                      placeholder: "DD/MM/YYYY HH:MM",
                    }}
                    InputProps={{
                      style: { padding: 0 }, // Remove input padding
                    }}
                    keyboardIcon={
                      <CalendarTodayIcon
                        style={{
                          padding: 0,
                          margin: 0,
                          color: "gray",
                          display: "block",
                        }}
                      />
                    }
                    InputAdornmentProps={{
                      position: "end",
                      style: {
                        margin: 0
                      },
                    }}
                    KeyboardButtonProps={{
                      style: {
                        padding: 0,
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      },
                    }}
                    disableMaskedInput={false}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="operation-input-div cargo-cargo-div" style={styles(10)}>
                {/* <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.departureTime || ""}
                  // disabled={!!checkDisabled.departureTime}
                  name="departureTime"
                  placeholder=""
                  onChange={(e) => onChangePortOperations(index, e)}
                /> */}
                {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    // inputVariant="outlined"
                    size="small"
                    className="date-time-picker-cargo-cargo"
                    format="DD/MMM/YYYY HH:mm"
                    style={{ width: 160 }}
                    value={operations.departureTime || null}
                    onChange={(date) =>
                      onChangePortOperations(index, date, true, "departureTime")
                    }
                  />
                </MuiPickersUtilsProvider> */}

                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    size="small"
                    className="date-time-picker-cargo-cargo"
                    format="DD/MM/YYYY HH:mm"
                    style={{ width: 160 }}
                    value={operations.departureTime || null}
                    onChange={(date) => onChangePortOperations(index, date, true, "departureTime")}
                    inputProps={{
                      placeholder: "DD/MM/YYYY HH:MM",
                    }}
                    InputProps={{
                      style: { padding: 0 }, // Remove input padding
                    }}
                    keyboardIcon={
                      <CalendarTodayIcon
                        style={{
                          padding: 0,
                          margin: 0,
                          color: "gray",
                          display: "block",
                        }}
                      />
                    }
                    InputAdornmentProps={{
                      position: "end",
                      style: {
                        margin: 0
                      },
                    }}
                    KeyboardButtonProps={{
                      style: {
                        padding: 0,
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      },
                    }}
                    disableMaskedInput={false}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="operation-input-div cargo-cargo-div" style={styles(11)}>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.portStay || ""}
                  disabled={true}
                  name="portStay"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>

            </div>
          );
        })}
    </div>
  );
});

export default CargoPortList;
