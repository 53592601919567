import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  getCurrentUserId,
} from "../../../utils";

export function getQueryFormatAPI(extra) {
  const data = { userId: getCurrentUserId(), ...extra };
  return apiGet(`/api/clientQuery`, data);
}

export function queryByIdAPI(id) {
  return apiGet(`/api/clientQuery/${id}`);
}

export function queryByPDFdownloadAPI(id) {
  return apiGet(`/api/clientQuery/pdfdownload/${id}`);
}

export function cargoByQueryAPI(id) {
  return apiGet(`/api/clientQuery/${id}`);
}

export function allCargoExtraDetailsByQueryAPI(id) {
  const data = { id: id };
  return apiGet(`/api/v1/allCargoExtraDetailsByQuery`, data);
}

export function allFleetAPI() {
  return apiGet(`/api/fleet/getAllFleet`);
}

export function allFleetCalculatorAPI() {
  return apiGet(`/api/fleet/getAllFleetForCacl`);
}

export function getSingleVesselCalcyAPI(data) {
  return apiGet(`/api/calculatedCost/getSingleVesselCalcy/${data?.queryid}/${data?.vesselid}`);
}

export function sendCalculatorDataAPI(data) {
  return apiPost(`/api/clientQuery/calculate/cargoPrice`, data);
}

export function addCargoExtraDetailsAPI(data) {
  return apiPost(
    `/api/clientQuery/cargoList/addCargo/${data.queryId}`,
    data
  );
}

export function addCargoExtraDetailsForCargoAnalysisAPI(data) {
  return apiPost(
    `/api/cargoAnalysis/cargoList/addCargo/${data.queryId}`,
    data
  );
}

export function addCargoExtraDetailsForSingleVesselAPI(data) {
  return apiPost(
    `/api/calculatedCost/cargoList/addCargo/${data.calculatedCostId}`,
    data
  );
}

export function updateQueryFormtAPI(data) {
  return apiPut(`/api/v1/updateQueryFormt`, data);
}

export function deleteCargoExtraDetailAPI(data) {
  return apiDelete(`/api/clientQuery/${data.queryId}/${data.cargoId}`);
}
export function deleteCargoExtraDetailFromCargoAnalysisAPI(data) {
  return apiDelete(`/api/cargoAnalysis/deleteCargoExtraDetails/${data.queryId}/${data.cargoId}`);
}
export function deleteCargoExtraDetailForSingleVesselAPI(data) {
  return apiDelete(`/api/calculatedCost/${data.calculatedCostId}/${data.cargoId}`);
}

export function updateQueryFormatAPI(data) {
  return apiPut(`/api/v1/updateQueryFormat`, data);
}

export function updateQueryAPI(data) {
  const formData = new FormData();

  formData.append('QueryData', JSON.stringify(data) || '');

  return apiPut(`/api/clientQuery/${data._id}`, formData);
}

export function updateQueryCalculatedCostAPI(id, data) {
  return apiPut(`/api/calculatedCost/${id}`, data);
}

export function updateClientQueryAccessAPI(data) {
  return apiPut(`/api/clientQuery/access/${data.id}`, { access: data.access });
}

export function updateChangeSwitchQueryAPI(data) {
  return apiPut(`/api/clientQuery/switch/${data.id}`, { isShowInCalculator: data.isShowInCalculator });
}

export function deleteFleetAPI(id) {
  return apiDelete(`/api/fleet/${id}`);
}

export const allVesselsByCharWithoutReducerAPI = (search) => {
  // const { vessel = '', offset = 0 } = data || {}
  return apiGet(`/api/vessel/getAllVessel`, {
    vesselName: search,
    skipUserId: true,
  });
};

export function portsByCharAPI(search) {
  return apiGet(`/api/port/getAllPort`, {
    searchTerm: search,
    skipPagination: true,
  });
}

export function addMultiFleetAPI(data) {
  return apiPost(`/api/fleet/multiple`, data);
}

export function addFleetAPI(data) {
  return apiPost(`/api/fleet`, data);
}

export function changeAutoVesselDetailsAPI(data) {
  return apiPost(`/api/vesselManager/changeAutoVesselDetails`, data)
}

export function updateVesselManagerAPI(data) {
  return apiPost(`/api/vesselManager`, data);
}
export function getVesselManagerAPI(data) {
  return apiGet(`/api/vesselManager/getVesselManagerList`, data);
}

export function updateFleetDataAPI(data) {
  return apiPut(`/api/fleet/` + data._id, data);
}

export function updateLiveSharingAPI(data) {
  return apiPost(`/api/fleet/livesharing/${data.fleetId}/${data?.liveShare}`);
}

export function getSingleFleetDataAPI(fleetId, data) {
  return apiGet(`/api/fleet/` + fleetId, data);
}


export function getEmailsFleetListAPI(data) {
  return apiGet(`/api/vesselManager/getAutoEmailsVesselList`, data);
}
export function getAutoEmailsVesselListAPI(data) {
  return apiGet(`/api/vesselManager/getAutoEmailsVesselList`, data);
}

export function getVesselsWithIdAPI(vesselId, isExtra = false) {
  return apiGet(`/api/vessel/${vesselId}`);
}

export function saveToFleetAPI(data) {
  return apiPost(`/api/v1/saveToFleet`, data);
}

export function getPortDistanceByPortListAPI(portData) {
  // const data = {
  //     portList,
  //     userId: getCurrentUserId()
  // }
  // return apiGet(`/api/v1/getPortDistance`, data)
  const data = {
    ...portData,
    userId: getCurrentUserId(),
  };
  return apiPost(`/api/portDistance/calculate/portDistance`, data);
}

export function getPortDistanceForCargoAnalysisAPI(portData) {
  const data = {
    ...portData,
    userId: getCurrentUserId(),
  };
  return apiPost(`/api/portDistance/calculate/portDistanceCargoAnalysis`, data);
}

export function getPortDistanceMatrixAPI(portData, extra) {
  const data = {
    ...extra,
    routeList: portData,
    userId: getCurrentUserId(),
  };
  return apiPost(`/api/portDistance/calculate/distanceMatrix`, data);
}


// export function getNewPortDistanceByPortListAPI(portData) {
//     const data = {
//         ...portData,
//         userId: getCurrentUserId()
//     }
//     return apiPost(`/api/v1/getPortDistance`, data)
// }

export function updateDistanceAPI(data) {
  return apiPut(`/api/v1/updateDistance`, data);
}

export function savePortDistanceAPI(data) {
  return apiPost(`/api/v1/savePortDistance`, {
    ...data,
    userId: getCurrentUserId(),
  });
}

export function getCanalNameByUserAPI(data) {
  return apiGet(`/api/v1/getCanalNameByUser?id=${getCurrentUserId()}`);
}

export function userPortNotesAPI(data) {
  return apiGet(`/api/v1/userPortNotes`, data);
}

export function setUSerPortCanalAPI(data) {
  return apiPost(`/api/v1/canal?canalName=${data}`);
}

export function resetDistanceAPI(portData) {
  const data = {
    ...portData,
    userId: getCurrentUserId(),
  };
  return apiGet(`/api/portDistance/calculate/resetDistance`, data);
}

// export function updateFuelPriceAPI(fuelType, data) {
//   return apiPut(`/api/v1/updateFuelPrice?fuelType=${fuelType}`, data);
// }

export function userFleetsAPI(data) {
  return apiGet(`/api/fleet/getAllFleet`, data);
}

export function userFleetDataCopyAPI(data) {
  return apiPost(`/api/vesselManager/userFleetDataCopy`, data);
}

export function allSavedResultsAPI(data = {}) {
  let inititalData = { limit: 10, offset: 1 };
  return apiGet(`/api/calculatedCost/getAllCalculatedCost`, {
    ...inititalData,
    ...data,
  });
}

export function saveCalculatedCostResultAPI(data) {
  return apiPost(`/api/calculatedCost`, data);
}

export function saveResultByIdAPI(id) {
  return apiGet(`/api/calculatedCost/${id}`);
}

export function allResultsByVesselCharAPI(vessel) {
  return apiGet(
    `/api/v1/result/allResultsByVesselChar?offset=1&vessel=${vessel}`
  );
}

export function deleteSavedResultByIdAPI(id) {
  return apiDelete(`/api/calculatedCost/${id}`);
}

export function allVoyageResultsByCharAPI(data) {
  return apiGet(`/api/v1/result/allVoyageResultsByChar`, data);
}

export function allPortRegionsAPI() {
  return apiGet(`/api/portRegion/getAllPortRegions`, { skipId: true });
}

export function createVesselVesselAPI(data) {
  return apiPost(`/api/vesselVessel/createVesselVessel`, data);
}

export function getGeneralMasterDataAPI(type) {
  return apiGet(`/api/generalMaster/getData?type=${type}`);
}