import React, { useState } from "react";
import CloudUploadIcon from "@material-ui/icons/CloudUploadOutlined";
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CancelIcon from '@material-ui/icons/Cancel';
import { FileUploader } from 'react-drag-drop-files';
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
  Box,
  useTheme,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import Papa from "papaparse";
import CsvDownloader from "./CsvDownloader";
import { individualDummyData, organisationDummyData } from "../../constants";
import { Alert } from "@material-ui/lab";

export const CsvUploader = ({ onDataProcessed }) => {
  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const text = await file.text();

        Papa.parse(text, {
          complete: (result) => {
            const jsonData = result.data;
            const structuredData = jsonData;
            if (onDataProcessed) {
              onDataProcessed(structuredData[0]); // Pass structured data to parent
            }
          },
          header: true,
          skipEmptyLines: true,
        });
      } catch (error) {
        console.error("Error while parsing CSV:", error.message);
        alert("Failed to parse CSV.");
      }
    }
  };

  return (
    <Button
      variant="contained"
      component="label"
      startIcon={<CloudUploadIcon />}
    >
      Upload CSV
      <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
    </Button>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end', // Align items to the right
    width: '100%',
    gap: theme.spacing(1),
  },
  actionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  dropZone: {
    padding: theme.spacing(4),
    width: '100%',
    textAlign: 'center',
  },
}));
export const UploadCSVModal = ({ onUploadSuccess, onCancel, onDataProcessed }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState('Individual');
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploading, setFileUploading] = useState('');
  const [error, setError] = useState('');

  const fileTypes = ['CSV'];
  // Maximum allowed rows
  const MAX_ROWS = 3000;

  // Email Validation Function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Determine the correct email key based on selected type
  const getEmailKey = () => {
    return selectedType === 'Individual'
      ? 'individual_emailOfficial'
      : 'organisation_emailOfficial';
  };

  // Handle File Change
  const handleChange = async (file) => {
    setError('');
    setFileUploading('');

    if (file.type !== 'text/csv' && !file.name.toLowerCase().endsWith('.csv')) {
      setError('Please upload a valid CSV file');
      return;
    }

    try {
      setFileUploading('CSV file Uploading...');

      // Read the file text
      const text = await file.text();

      // Parse CSV with Papaparse
      Papa.parse(text, {
        complete: (result) => {
          // Check row limit
          if (result.data.length > MAX_ROWS) {
            setError(`Maximum ${MAX_ROWS} rows allowed. Your file contains ${result.data.length} rows.`);
            setFileUploading('');
            return;
          }

          // Get the correct email key
          const emailKey = getEmailKey();

          // More comprehensive filtering
          const filteredData = result.data
            .filter((row) => {
              const email = row[emailKey]?.trim();
              return email && validateEmail(email);
            })
            .map((row) => ({
              ...row,
              type: selectedType, // Add selectedType to each row
            }));


          // Separate invalid rows
          const invalidRows = result.data.filter((row) => {
            // Use the dynamic email key
            const email = row[emailKey]?.trim();
            return !email || !validateEmail(email);
          });

          // Process the data
          if (onDataProcessed) {
            onDataProcessed({
              validData: filteredData,
              invalidData: invalidRows,
              totalRows: result.data.length,
              type: selectedType
            });
          }

          // Only set success if row limit is not exceeded
          if (result.data.length <= MAX_ROWS) {
            setFileUploading('File Uploaded Successfully!');
            setSelectedFile(file);

          }
        },
        header: true,
        skipEmptyLines: true,
      });
    } catch (err) {
      setError('Something went wrong during upload!');
      onCancel?.();
      setOpenUploadModal(false);
      setSelectedFile(null);
      setError('');
      setFileUploading('');
      console.error(err);
    }
  };
  // Custom Section for File Uploader
  const CustomSection = () => (
    <div className={classes.dropZone}>
      <Typography
        variant="body1"
        color={'textSecondary'}
      >
        {fileUploading || `Drag & drop or click to select CSV files (Max ${MAX_ROWS} rows)`}
      </Typography>
    </div>
  );



  // Handle Cancel
  const handleCancel = () => {
    onCancel?.();
    setOpenUploadModal(false);
    setSelectedFile(null);
    setError('');
    setFileUploading('');
  };

  // Handle Upload
  const handleUpload = () => {
    onUploadSuccess?.();
    setOpenUploadModal(false);
    onCancel?.();
    setOpenUploadModal(false);
    setSelectedFile(null);
    setError('');
    setFileUploading('');
  };

  return (
    <Box className={classes.root}>
      {/* Radio Group and Action Buttons in one line on the right */}
      <Box className={classes.actionContainer}>
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <FormControlLabel
              value="Individual"
              control={<Radio color="primary" size="small" />}
              label="Individual"
            />
            <FormControlLabel
              value="Organisation"
              control={<Radio color="primary" size="small" />}
              label="Organisation"
            />
          </RadioGroup>
        </FormControl>

        <CsvDownloader
          data={
            selectedType === 'Individual'
              ? individualDummyData
              : organisationDummyData
          }
        />

        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudUploadIcon />}
          onClick={() => setOpenUploadModal(true)}
          size="medium"
        >
          Upload CSV
        </Button>
      </Box>

      {/* Upload Modal */}
      <Dialog
        open={openUploadModal}
        onClose={handleCancel}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              Upload {selectedType === 'Individual' ? 'Individual' : 'Organisation'} CSV
            </Typography>
            <IconButton onClick={handleCancel} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent className={classes.uploadContainer}>
          {/* File Upload Section */}
          <Box
            border={`2px dashed ${theme.palette.primary.light}`}
            borderRadius={2}
            p={3}
            mb={2}
            textAlign="center"
          >
            <FileUploader
              dropMessageStyle={{ color: '#000' }}
              children={<CustomSection />}
              multiple={false}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
            />
          </Box>

          {/* Error Display */}
          {error && (
            <Alert
              severity="error"
              style={{
                width: '100%',
                marginBottom: 16,
              }}
            >
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            </Alert>
          )}

          {/* File Details */}
          {selectedFile && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bgcolor={theme.palette.background.default}
              p={2}
              borderRadius={1}
              mb={2}
            >
              <Box display="flex" alignItems="center">
                <InsertDriveFileIcon color="primary" style={{ marginRight: 16 }} />
                <Typography variant="body2">
                  {selectedFile.name}
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                {(selectedFile.size / 1024).toFixed(2)} KB
              </Typography>
            </Box>
          )}

          {/* Upload Constraints */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            align="center"
            style={{ marginBottom: 16 }}
          >
            {` | `}
            Maximum rows: {MAX_ROWS}
            {` | `}
            Allowed file type: .csv
          </Typography>

          {/* Action Buttons */}
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            mt={2}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              startIcon={<CloudUploadIcon />}
              disabled={!selectedFile || !!error}
              onClick={handleUpload}
            >
              Upload File
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};