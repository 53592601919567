import React, { useState } from "react";
import CustomButton from "../../../components/Common/CustomButton";
import CustomTextField from "../../../components/Common/CustomTextField";
import { useHistory } from "react-router-dom";
import { toastSuccess } from "../../../utils";
import registerValidation from "../../../validations/registerValidation";
import { registerUser } from "../actions";
import useResponsive from "../../../hooks/useResponsive";
import { Link } from "@material-ui/core";
// import cargovideo from "../../../img/cargovideo.webm"
// import { ReactComponent as Logo1 } from "../../../assets/images/logo1.svg";
import { CargoVideoUrl } from "../../../constants";
import Logo from "../../../common/Logo";

const intialRequestObj = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  phoneNumber: "",
};

export default function SignUp() {
  const history = useHistory();
  const [user, setUser] = useState({ ...intialRequestObj });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");

  const onChangeHandler = (e) => {
    const { name = "", value = "" } = e.target;
    const newUser = { ...user, [name]: value };
    newUser.username = newUser.firstName + newUser.lastName;
    setUser(newUser);
  };
  const onRegisterUser = async () => {
    const { isValid, errors } = registerValidation(user);
    if (!isValid) return setErrors(errors);
    setIsLoading(true);
    const { confirmPassword, ...userDetails } = user;
    const res = await registerUser(userDetails);
    if (res?.success) {
      toastSuccess("User registration successful");
      setUser({ ...intialRequestObj });
    }
    setIsLoading(false);
  };

  return (
    <div className="bg-cover bg-no-repeat flex bg-center relative w-full h-screen overflow-hidden">
      <div className="authBgImage bg-cover bg-no-repeat flex bg-center">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src={CargoVideoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="relative flex flex-col justify-center md:items-start items-center m-3 gap-10 w-full">
        <Logo className="w-10 h-10 inline-block left-0 top-0 absolute" />

        <div className="border border-neutral-100 px-6 w-full sm:w-[500px] bg-white py-[26px] rounded-[48px] border-solid flex flex-col gap-10">
          <div className="my-2 text-center flex flex-col gap-2">
            {/* <div className="typo-16-500 text-neutrals4"> */}

            {/* </div> */}
            <div className="typo-16-500 text-neutrals4">
              Hello! Welcome to Smart Sea Freight.
            </div>
            <div className="typo-30-500">
              {/* <Logo className="w-10 h-10 inline-block" /> */}

              <span className="pr-1">Login To Your Account</span>
              {/* 👨‍💻 */}
            </div>
          </div>
          <div className="max-w-[344px] flex flex-col gap-5 mx-auto my-0">
            {/* <div px={2} className="login-container-card"> */}
            <div className="flex gap-3">
              <CustomTextField
                name="firstName"
                placeholder="First Name"
                label="First Name"
                value={user.firstName}
                onChange={onChangeHandler}
                error={!!errors.firstName}
                errorMsg={errors.firstName}
              // onKeyPress={keyPressed}
              />
              <CustomTextField
                name="lastName"
                placeholder="Last Name"
                label="Last Name"
                value={user.lastName}
                onChange={onChangeHandler}
                error={!!errors.lastName}
                errorMsg={errors.lastName}
              // onKeyPress={keyPressed}
              />
            </div>
            <CustomTextField
              name="email"
              placeholder="Email"
              label="Email"
              value={user.email}
              onChange={onChangeHandler}
              error={!!errors.email}
              errorMsg={errors.email}
            // onKeyPress={keyPressed}
            />
            <CustomTextField
              type="password"
              name="password"
              placeholder="Password"
              label="Password"
              value={user.password}
              onChange={onChangeHandler}
              error={!!errors.password}
              errorMsg={errors.password}
            // onKeyPress={keyPressed}
            />
            <CustomTextField
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              label="Confirm Password"
              value={user.confirmPassword}
              onChange={onChangeHandler}
              error={!!errors.confirmPassword}
              errorMsg={errors.confirmPassword}
            // onKeyPress={keyPressed}
            />

            <CustomTextField
              type="number"
              name="phoneNumber"
              placeholder="Phone Number"
              label="Phone Number"
              value={user.phoneNumber}
              onChange={onChangeHandler}
              npm
              error={!!errors.phoneNumber}
              errorMsg={errors.phoneNumber}
            // onKeyPress={keyPressed}
            />
            <div className="flex justify-center">
              <CustomButton
                onClick={onRegisterUser}
                loader={isLoading}
                disabled={isLoading}
                className="bg-blue py-7 rounded-full w-60 mt-2"
              >
                Register
              </CustomButton>
            </div>
            <div className="text-center mt-5">
              <div className="typo-16-400">
                Already have an account?{" "}
                <Link href="/login" className="text-blue">
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
