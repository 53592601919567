import React from "react";
// import CloseIcon from '@material-ui/icons/Close';
import { MdCancel } from "react-icons/md";
import clsx from "clsx";

function OrdersList(props) {
  const {
    allQueryFormat = [],
    selectedQuery = {},
    setSelectedQuery = () => {},
    onRemoveOrderQuery = () => {},
    onChangeSelectedQuery = () => {},
    setHideOrder = () => {},
  } = props;

  return (
    <ul className="orders-list-items gap-1 flex flex-col">
      {Array.isArray(allQueryFormat) && allQueryFormat.length ? (
        allQueryFormat.map((query, index) => {
          const selected = selectedQuery._id === query._id;
          return (
            <li
              key={index}
              className={clsx(
                "px-3 py-0.5 rounded-md cursor-pointer",
                selected
                  ? `selected selected-color border border-neutrals4 `
                  : "bg-neutrals6 hover:bg-neutrals7"
              )}
              onClick={() => {
                onChangeSelectedQuery(query, [], false);
                setHideOrder(true);
              }}
            >
              <p>
                <span>{query.name}</span>
                <MdCancel
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemoveOrderQuery(query._id);
                  }}
                  className="text-red text-sm z-50"
                />
              </p>
            </li>
          );
        })
      ) : (
        <li>No Query Found.</li>
      )}
    </ul>
  );
}

export default OrdersList;
