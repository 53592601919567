import {
  addTeamCargoAnalysisDetailsAPI,
  deleteCargoAnalysisAPI,
  getAllCargoAnalysisDetailsAPI,
  getCargoAnalysisDetailsAPI,
  getSingleCargoAnalysisDetailsAPI,
  updateCargoAnalysisDetailsAPI,
} from "./api";



export const getCargoAnalysisDetails = (data) => {
  return new Promise((resolve, reject) => {
    getCargoAnalysisDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllCargoAnalysisDetails = (data) => {
  return new Promise((resolve, reject) => {
    getAllCargoAnalysisDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSingleCargoAnalysisDetails = (data) => {
  return new Promise((resolve, reject) => {
    getSingleCargoAnalysisDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const updateCargoAnalysisDetails = (id, data) => {
  return new Promise((resolve, reject) => {
    updateCargoAnalysisDetailsAPI(id, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const addTeamCargoAnalysisDetails = (data) => {
  return new Promise((resolve, reject) => {
    addTeamCargoAnalysisDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const deleteCargoAnalysis = (data) => {
  return new Promise((resolve, reject) => {
    deleteCargoAnalysisAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};