import {
  apiGet,
  getCurrentUserId,
  apiPost,
  apiPut,
  getCurrentUserOrganizationId,
  apiDelete,
} from "../../../utils";

export const clientQueryUserAPI = (data = {}) => {
  return apiGet("/api/clientQuery", { ...data });
};

export const clientQueryOrgAPI = (mdata) => {
  const organizationId = getCurrentUserOrganizationId();
  let data = { ...mdata, organizationId: organizationId };

  return apiGet("/api/clientQuery", { ...data });
};

export const getAddressDetailsAPI = (query) => {
  let data = { ...query, userId: getCurrentUserId() };
  return apiGet("/api/addressbook/getAddressDetails", data);
};

export const getSingleAddressDetailsAPI = (query) => {
  let data = { ...query };
  return apiGet("/api/addressbook/getSingleAddressDetails", data);
};

export const addAddressDetailsAPI = (data) => {
  return apiPost("/api/addressbook/addAddressDetails", data);
};

export const updateAddressDetailsAPI = (data) => {
  return apiPost("/api/addressbook/updateAddressDetails", data);
};

export const deleteAddressDetailsAPI = (id) => {
  return apiDelete(`/api/addressbook/${id}`);
};