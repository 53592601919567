import React, { useEffect, useState } from "react";
import { AgCharts } from "ag-charts-react";

export const PieChart = (props) => {
  const { label = "", data = [] } = props
  function getData() {
    return [
      { label: "ballast sea", value: 60000 },
      { label: "laden sea", value: 40000 },
      { label: "port stay total", value: 7000 },

    ];
  }
  const [options, setOptions] = useState({
    data: data,
    title: {
      text: label,
    },
    series: [
      {
        rotation: 270,
        type: "pie",
        angleKey: "value",
        calloutLabelKey: "label",
        sectorLabelKey: "value",

        sectorLabel: {
          color: "white",

          fontWeight: "bold",
          formatter: ({ value }) => `${(value).toFixed(4)}`,
        },
      },
    ],
    background: {
      fill: "#d7e8fd", // Set background color here
    },
  });
  // useEffect(() => {
  //   setOptions()
  // }, [data])

  return <AgCharts options={{ ...options, data: data }} />;
};
