import React, { useState, memo, useCallback, useMemo } from "react";
import DraggableList from "./DraggableList";
import arrayMove from "array-move";
import {
  fuelPriceOptions,
  initialCreatePortOperation,
  initialPortOperation,
  portOperationHeader,
  termsCoEfficientTable,
  vesselVesselPortOperationHeader,
} from "../../constants";
import CustomShowModal from "../Common/CustomShowModal";
import BunkerSupply from "./BunkerSupply";
import MarginAllowancesTable from "./MarginAllowancesTable";
import * as actions from "../../containers/User/Calculator/actions";
import PortDistanceModal from "./PortDistanceModal";
import AddPortsModal from "./AddPortsModal";
import _ from "lodash";
import AddOperationsModal from "./AddOperationsModal";
import { toastError } from "../../utils";
import VesselVesselDraggableList from "./VesselVesselDraggableList";

function VesselVesselPortOperations(props) {
  const {
    disableEdit = false,
    sendCalculationRequest = () => { },
    calculatorData = [],
    setCalculatorData = () => { },
    onChangePortOperations = () => { },
    marginModal = false,
    setMarginModal = () => { },
    syncBunker = true,
    setSyncBunker = () => { },
    onAddOperations = () => { },
    onDeleteOperations = () => { },
    selectedVessels = {},
    supplyModal = false,
    setSupplyModal = () => { },
    onChangeSyncBunker = () => { },
    onFetchPortOperationDistance = () => { },
    openAddOperation = false,
    setOpenAddOperation = () => { },
    setOpenAddOperationindex = () => { },
    onChangeSelectedVessel = () => { },
    isOverride = false,
    setIsOverride = () => { },
    onChangePortCanalInQueryState = () => { },
  } = props;
  const { portOperation = [], clientQuery = {} } = calculatorData || {};
  const { cargoTransactionMode = false } = clientQuery;

  const [termsModal, setTermsModal] = useState(false);
  const [distanceModal, setDistanceModal] = useState(false);
  //
  const [selectedOperation, setSelectedOperation] = useState({});
  // const [cargoTxOverride, setCargoTxOverride] = useState(false)
  const [addPortModal, setAddPortModal] = useState(false);
  const [portLoading, setPortLoading] = useState(false);
  const [portOptions, setPortOptions] = useState([]);
  const [initialAddOperation, setInitialAddOperation] = useState({
    operation: "Bunkering",
    port: "",
    portExp: 0,
    extraDays: 0,
    lat: 0,
    lng: 0
  });

  const delayPortsSearch = useCallback(
    _.debounce(
      (selectedOperation, search, isInitialData = false) =>
        onSearchPortList(selectedOperation, search, isInitialData),
      500
    ),
    []
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (
      portOperation[oldIndex].operation === "Commence" ||
      portOperation[newIndex].operation === "Commence"
    )
      return;

    if (portOperation[oldIndex].operation === "Delivery-out") {
      const redeliveryInIndex = portOperation?.findIndex(item => item.operation === "Redelivery-in");
      if (redeliveryInIndex <= newIndex) {
        return;
      }
    } else if (portOperation[oldIndex].operation === "Redelivery-in") {
      const deliveryOutIndex = portOperation?.findIndex(item => item.operation === "Delivery-out");
      if (newIndex <= deliveryOutIndex) {
        console.log("Invalid move: 'Redelivery-in' cannot be moved above 'Delivery-out'");
        return;
      }
    }


    let newCombinedOperation = arrayMove(portOperation, oldIndex, newIndex);
    const updatedData = {
      ...calculatorData,
      portOperation: [...newCombinedOperation].map((item, index) => ({
        ...item,
        index,
      })),
    };
    setCalculatorData(updatedData);
    onFetchPortOperationDistance(false, updatedData);
  };

  const onClickHeaderModal = (headerName) => {
    if (headerName === "Terms") {
      setTermsModal(true);
    } else if (headerName === "Supply") {
      setSupplyModal(true);
    } else if (headerName === "Dist Normal" || headerName === "Dist ECA") {
      setDistanceModal(true);
    } else {
      return;
    }
  };

  const onChangeBunkerScrubber = (index, e) => {
    const { name = "", value = "" } = e.target;
    console.log('==ASDFASFD===>', index, name, value)

    let newPortOperation = [...portOperation];
    newPortOperation[index].cargoDetails[name] = Number(value);
    setCalculatorData({ ...calculatorData, portOperation: newPortOperation });
  };

  const onClickSinglePort = (operation) => {
    setSelectedOperation(operation);
    setAddPortModal(true);
  };

  const onSearchPortList = (
    selectedOperation,
    value,
    isInitialData = false
  ) => {
    if (isInitialData) {
      setInitialAddOperation({ ...selectedOperation, port: value });
    } else {
      setSelectedOperation({ ...selectedOperation, port: value });
    }
    if (value?.length > 2) {
      setPortLoading(true);
      actions
        .portsByChar(value)
        .then((res) => {
          setPortLoading(false);
          setPortOptions(
            (Array.isArray(res.data.list) &&
              res.data.list.map((item) => ({
                label: `${item.name} ${item.country ? "(" + item.country + ")" : ""
                  }`,
                value: item.name,
                lat: item?.lat,
                lng: item?.lng
              }))) ||
            []
          );
        })
        .catch((err) => {
          setPortLoading(false);
        });
    }
  };

  const onSelectPortsOfOperation = (selectedPort) => {
    const portIndex = portOperation.findIndex(
      (item) => item._id === selectedOperation._id
    );
    if (portIndex > -1) {
      const updatedOperation = [...portOperation];
      updatedOperation[portIndex].port = selectedPort.value;
      updatedOperation[portIndex].lat = selectedPort.lat;
      updatedOperation[portIndex].lng = selectedPort.lng;

      setSelectedOperation({ ...selectedOperation, port: selectedPort.value });
      setCalculatorData({
        ...calculatorData,
        portOperation: updatedOperation,
      });
    }
  };

  const onClickCalculateDistance = () => {
    onFetchPortOperationDistance(false);
    setAddPortModal(false);
  };

  const onAddOperationByModal = () => {
    const {
      operation = "",
      port = "",
      portExp = 0,
      portStay = 0,
    } = initialAddOperation;
    if (!operation || !port) {
      return toastError("Port is required");
    }
    onAddOperations(initialAddOperation);
    setOpenAddOperation(false);
  };

  const dischargedPortIndexes = useMemo(() => {
    let indexes = [];
    for (let i = 0; i < portOperation.length; i++) {
      if (portOperation[i].operation === "Discharging") {
        indexes.push(i);
      }
    }
    indexes.splice(indexes.length - 1, 1);
    return indexes;
  }, [portOperation]);

  const onSetCargoTxOverride = (checked) => {
    // setCargoTxOverride(checked)
    const updatedPortOperation = [...portOperation];
    for (let i = 0; i < dischargedPortIndexes.length; i++) {
      updatedPortOperation[dischargedPortIndexes[i]].cargoTxOverriddenValue = 0;
      updatedPortOperation[dischargedPortIndexes[i]].cargoTx = 0;
    }
    setCalculatorData({
      ...calculatorData,
      clientQuery: { ...clientQuery, cargoTransactionMode: checked },
      portOperation: updatedPortOperation,
    });
  };

  const termsToDisplay = termsCoEfficientTable.filter(
    (item) => item.value !== 0
  );
  let newPortOperationHeader = [...vesselVesselPortOperationHeader];


  let showModal = ["Dist Normal", "Dist ECA", "Terms"];
  if (disableEdit) showModal = showModal.filter((item) => item !== "Supply");

  return (
    <div style={{ overflow: "auto" }} className="operation-background">
      <div className="port-operation-buttons">
        {/* <button
          onClick={() => setOpenAddOperation(true)}
          className="text-center py-1 bg-neutrals9 hover:bg-neutrals7 px-3 border border-neutrals6 text-neutrals4 rounded-md text-sm flex items-center gap-1 mb-2"
        >
          + Add Operations
        </button> */}
      </div>
      <div className="port-operation-header">
        {newPortOperationHeader.map((item, index) => {
          const { fuelType = "" } = clientQuery || {}
          let selectedFuelType = fuelPriceOptions.find(item => item.value === fuelType)
          if (selectedFuelType) selectedFuelType = selectedFuelType.label
          else selectedFuelType = 'Type-1'
          const currentType = fuelType.replace("TYPE", "")
          return (
            <div key={index} className="flex items-center flex-wrap">
              <span
                style={{
                  minWidth: item.width,
                  width: item.width,
                  paddingLeft: item?.paddingLeft,
                  // cursor: isModalField ? "pointer" : "default",
                  color: "#fff"
                  //0051B6
                  ,
                  textDecoration: "none",
                  display: item?.display || "flex",
                  flexDirection: item?.justifyContent ? "" : "column",
                  justifyContent: item?.justifyContent || "",
                  borderRight: item?.borderRight || "none",
                  borderLeft: item?.borderLeft || "none",
                }}

              >
                {item.header}
                {item?.dynamicHeader ? (selectedFuelType) : ""}

              </span>{" "}

            </div>
          );
        })}


      </div>
      <div className="port-operation-header" style={{ borderRadius: "0px" }}>
        {newPortOperationHeader.map((item, index) => {
          const isModalField = showModal.includes(item.label);
          // console.log(showModal, "showModal")

          if (disableEdit && item.label === "Add Opr..") {
            return
          }
          return (
            <div key={index} className="flex items-center flex-wrap">
              <span
                style={{
                  minWidth: item.width,
                  width: item.width,
                  paddingLeft: item?.paddingLeft,
                  cursor: isModalField ? "pointer" : "default",
                  color: isModalField ? "#fff"
                    //0051B6
                    : "",
                  textDecoration: isModalField ? "underline" : "none",
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={() => {
                  if (isModalField) {
                    onClickHeaderModal(item.label);
                  }
                }}
              >
                {item.label}
                {item.label === "Cargo Tx" ? (
                  <input
                    type="checkbox"
                    checked={cargoTransactionMode}
                    onChange={(e) => onSetCargoTxOverride(e.target.checked)}
                  />
                ) : null}
              </span>{" "}

            </div>
          );
        })}
      </div>
      {portOperation.length ? (
        <VesselVesselDraggableList
          useDragHandle
          onSortEnd={onSortEnd}
          onChangePortOperations={onChangePortOperations}
          combinedOperation={portOperation}
          onDeleteOperations={onDeleteOperations}
          onClickSinglePort={onClickSinglePort}
          cargoTxOverride={cargoTransactionMode}
          dischargedPortIndexes={dischargedPortIndexes}
          setOpenAddOperation={setOpenAddOperation}
          setOpenAddOperationindex={setOpenAddOperationindex}
          disableEdit={disableEdit}
          selectedVessels={selectedVessels}
          clientQuery={clientQuery}
          onChangeBunkerScrubber={onChangeBunkerScrubber}

        />
      ) : (
        <div className="no-port-found-div">
          <p>No port operations found.</p>
        </div>
      )}

      <CustomShowModal
        open={termsModal}
        handleClose={() => setTermsModal(false)}
        maxWidth="xs"
        title="Terms Co-efficient Table"
      >
        {termsToDisplay.map((term, index) => {
          return (
            <div className="show-term-modal" key={index}>
              <p>{term.label}</p>
              <p>{term.value}</p>
            </div>
          );
        })}
      </CustomShowModal>

      <CustomShowModal
        open={supplyModal}
        handleClose={() => setSupplyModal(false)}
        maxWidth="lg"
        title="Bunker Supply Table"
      >
        <BunkerSupply
          portOperation={portOperation}
          syncBunker={syncBunker}
          setSyncBunker={setSyncBunker}
          clientQuery={clientQuery}
          onChangeBunkerScrubber={onChangeBunkerScrubber}
          selectedVessels={selectedVessels}
          onChangeSyncBunker={onChangeSyncBunker}
          onChangeSelectedVessel={onChangeSelectedVessel}
          onChangePortOperations={onChangePortOperations}
          isOverride={isOverride}
          setIsOverride={setIsOverride}
        />
      </CustomShowModal>

      <CustomShowModal
        open={marginModal}
        handleClose={() => setMarginModal(false)}
        maxWidth="md"
        title="Margin Allowances Table"
      >
        <MarginAllowancesTable
          calculatorData={calculatorData}
          setCalculatorData={setCalculatorData}
        />
      </CustomShowModal>

      <CustomShowModal
        open={distanceModal}
        handleClose={() => setDistanceModal(false)}
        maxWidth="lg"
        disableClose
        title="Port Distance"
      >
        <PortDistanceModal
          calculatorData={calculatorData}
          setCalculatorData={setCalculatorData}
          onFetchPortOperationDistance={onFetchPortOperationDistance}
          onChangePortCanalInQueryState={onChangePortCanalInQueryState}
        />
      </CustomShowModal>

      <CustomShowModal
        open={addPortModal}
        handleClose={() => setAddPortModal(false)}
        maxWidth="xs"
        disableClose
        title={`Change ${selectedOperation.operation} Port`}
      >
        <AddPortsModal
          selectedOperation={selectedOperation}
          delayPortsSearch={delayPortsSearch}
          portOptions={portOptions}
          portLoading={portLoading}
          onClickCalculateDistance={onClickCalculateDistance}
          onSelectPortsOfOperation={onSelectPortsOfOperation}
        />
      </CustomShowModal>

      <CustomShowModal
        open={openAddOperation}
        handleClose={() => setOpenAddOperation(false)}
        maxWidth="xs"
        disableClose
        title={`Add Operation`}
      >
        <AddOperationsModal
          selectedOperation={selectedOperation}
          delayPortsSearch={delayPortsSearch}
          portOptions={portOptions}
          portLoading={portLoading}
          initialAddOperation={initialAddOperation}
          setInitialAddOperation={setInitialAddOperation}
          onClickCalculateDistance={onClickCalculateDistance}
          onSelectPortsOfOperation={onSelectPortsOfOperation}
          onAddOperationByModal={onAddOperationByModal}
        />
      </CustomShowModal>
    </div>
  );
}

export default memo(VesselVesselPortOperations);
