import {
  addTeamCargoCargoDetailsAPI,
  getAllCargoCargoDetailsAPI,
  getCargoCargoDetailsAPI,
  getSingleCargoCargoDetailsAPI,
  updateCargoCargoDetailsAPI,
} from "./api";



export const getCargoCargoDetails = (data) => {
  return new Promise((resolve, reject) => {
    getCargoCargoDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllCargoCargoDetails = (data) => {
  return new Promise((resolve, reject) => {
    getAllCargoCargoDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSingleCargoCargoDetails = (data) => {
  return new Promise((resolve, reject) => {
    getSingleCargoCargoDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const updateCargoCargoDetails = (id, data) => {
  return new Promise((resolve, reject) => {
    updateCargoCargoDetailsAPI(id, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const addTeamCargoCargoDetails = (data) => {
  return new Promise((resolve, reject) => {
    addTeamCargoCargoDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
