import React, { useState, memo, useCallback, useMemo } from "react";
import {
  CargoCargoRevenueExpenseHeader,
  queryPortDisabledFields,
  termsCoEfficientTable,
} from "../../../../constants";
import {
  operationsOptions,
} from "../../../../constants";
import CustomShowModal from "../../../../components/Common/CustomShowModal";
import { handleKeyDown } from "../../../../common/common";

function Expense(props) {
  const {
    calculatorData = [],
    onChangeExpenseOperations = () => { },
    freightMode,
  } = props;
  const { vendorCargo = {} } = calculatorData || {};
  const [termsModal, setTermsModal] = useState(false);
  //


  const onClickHeaderModal = (headerName) => {
    if (headerName === "Terms") {
      setTermsModal(true);
    }
  };


  const termsToDisplay = termsCoEfficientTable.filter(
    (item) => item.value !== 0
  );

  const styles = (index) => {
    const width = CargoCargoRevenueExpenseHeader[index]?.width;
    return { minWidth: width, width };
  };

  let newPortOperationHeader = [...CargoCargoRevenueExpenseHeader];
  let showModal = ["Dist Normal", "Dist ECA", "Terms", "Supply"];
  const inx = vendorCargo?.cargoList?.findIndex(item => item?.operation?.toLowerCase() === "loading");

  return (
    <div style={{ overflow: "auto" }} className="operation-background">
      <div className="port-operation-buttons">
      </div>
      <div className="port-operation-header">
        {newPortOperationHeader.map((item, index) => {
          const isModalField = showModal.includes(item.label);

          return (
            <div key={index} className="flex items-center flex-wrap">
              <span
                style={{
                  minWidth: item.width,
                  width: item.width,
                  paddingLeft: item?.paddingLeft,
                  cursor: isModalField ? "pointer" : "default",
                  color: isModalField ? "#fff"
                    //0051B6
                    : "",
                  textDecoration: isModalField ? "underline" : "none",
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={() => {
                  if (isModalField) {
                    onClickHeaderModal(item.label);
                  }
                }}
              >
                {item.label}
              </span>{" "}

            </div>
          );
        })}
      </div>
      <div className="sortable-div">
        {vendorCargo?.cargoList &&
          vendorCargo?.cargoList.map((operations, index) => {
            const checkDisabled =
              CargoCargoRevenueExpenseHeader[operations.operation] || {};
            const disableForSingleFreight =
              (freightMode === "Single Freight" && index !== inx) || 
              false;
            return (
              <div className="sortable-row-tr cargo-cargo-row" key={index} index={index}>

                <div className="operation-input-div cargo-cargo-div" style={styles(0)}>
                  {/* {operations.operation || 0} */}
                  <select
                    value={operations.operation || ""}
                    name="operation"
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                    disabled={true}
                  >
                    {operationsOptions.map((option, key) => (
                      <option
                        key={key}
                        value={option.value}
                        disabled={option.isDisabled}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="operation-input-div cargo-cargo-div" style={styles(1)}>
                  <p
                    className="add-three-dots"
                  >
                    {operations.port || ""}
                  </p>
                </div>

                <div className="operation-input-div cargo-cargo-div" style={styles(2)}>
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.rate || ""}
                    // disabled={!!checkDisabled.rate}
                    name="rate"
                    placeholder="0"
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                  />
                </div>
                <div className="operation-input-div cargo-cargo-div" style={styles(3)}>
                  <div className="operation-input-div" style={styles(12)}>
                    {/* {operations.terms}  */}
                    <select
                      value={operations.terms || ""}
                      name="terms"
                      onChange={(e) => onChangeExpenseOperations(index, e)}
                      disabled={checkDisabled.terms}
                    >
                      {termsCoEfficientTable.map((option, key) => (
                        <option key={key} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="operation-input-div cargo-cargo-div" style={styles(4)}>

                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.turnTime || ""}
                    name="turnTime"
                    placeholder=""
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                  />
                </div>

                <div className="operation-input-div cargo-cargo-div" style={styles(5)}>
                  {/*  rate MT day */}
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.freight || ""}
                    // disabled={!!checkDisabled.freight}
                    disabled={
                      queryPortDisabledFields?.[operations?.operation]?.[
                      "freight"
                      ] || disableForSingleFreight
                    }
                    name="freight"
                    placeholder=""
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                  />
                </div>

                <div className="operation-input-div cargo-cargo-div" style={styles(6)}>
                  {/* {operations.portExp} */}
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.adcom || ""}
                    // disabled={!!checkDisabled.adcom}
                    disabled={
                      queryPortDisabledFields?.[operations?.operation]?.["adcom"] ||
                      disableForSingleFreight
                    }
                    name="adcom"
                    placeholder="0"
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                  />
                </div>
                <div className="operation-input-div cargo-cargo-div" style={styles(7)}>
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.other || ""}
                    // disabled={!!checkDisabled.other}
                    disabled={
                      queryPortDisabledFields?.[operations?.operation]?.["other"] ||
                      disableForSingleFreight
                    }
                    name="other"
                    placeholder="0"
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                  />
                </div>
                <div className="operation-input-div cargo-cargo-div" style={styles(8)}>
                  {/*  Port Emission */}
                  <input
                    type="text"
                    onKeyDown={handleKeyDown}
                    value={operations.brokerage || ""}
                    // disabled={!!checkDisabled.brokerage}
                    disabled={
                      queryPortDisabledFields?.[operations?.operation]?.[
                      "brokerage"
                      ] || disableForSingleFreight
                    }
                    name="brokerage"
                    placeholder="0"
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                  />
                </div>
                <div className="operation-input-div cargo-cargo-div" style={styles(9)}>
                  <input
                    type="text"
                    // onKeyDown={handleKeyDown}
                    value={operations.account || ""}
                    // disabled={!!checkDisabled.account}
                    disabled={
                      queryPortDisabledFields?.[operations?.operation]?.["account"]
                    }
                    name="account"
                    placeholder=""
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                  />
                </div>
                <div className="operation-input-div cargo-cargo-div" style={styles(10)}>
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.demurrageRate || ""}
                    // disabled={!!checkDisabled.demurrageRate}
                    name="demurrageRate"
                    placeholder="0"
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                  />
                </div>
                <div className="operation-input-div cargo-cargo-div" style={styles(11)}>
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.despatchRate || ""}
                    // disabled={!!checkDisabled.despatchRate}
                    name="despatchRate"
                    placeholder="0"
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                  />
                </div>
                <div className="operation-input-div cargo-cargo-div" style={styles(11)}>
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.additionalRevenue || ""}
                    // disabled={!!checkDisabled.additionalRevenue}
                    name="additionalRevenue"
                    placeholder="0"
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                  />
                </div>
                <div className="operation-input-div cargo-cargo-div" style={styles(11)}>
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.additionalExpense || ""}
                    // disabled={!!checkDisabled.additionalExpense}
                    name="additionalExpense"
                    placeholder="0"
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                  />
                </div>
                <div className="operation-input-div cargo-cargo-div" style={styles(11)}>
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.laytimeIncurred || ""}
                    // disabled={!!checkDisabled.laytimeIncurred}
                    name="laytimeIncurred"
                    placeholder="0"
                    onChange={(e) => onChangeExpenseOperations(index, e)}
                  />
                </div>
              </div>
            );
          })}
      </div>
      <CustomShowModal
        open={termsModal}
        handleClose={() => setTermsModal(false)}
        maxWidth="xs"
        title="Terms Co-efficient Table"
      >
        {termsToDisplay.map((term, index) => {
          return (
            <div className="show-term-modal" key={index}>
              <p>{term.label}</p>
              <p>{term.value}</p>
            </div>
          );
        })}
      </CustomShowModal>

    </div>
  );
}

export default memo(Expense);
