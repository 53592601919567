import React from "react";
import {
  Page,
  View,
  Text,
  StyleSheet,
  Document,
  Image,
} from "@react-pdf/renderer";
import checkBox1 from "../../assets/images/checkbox-checked.png";
import checkBox2 from "../../assets/images/checkbox-unchecked.png";
import moment from "moment";
import { PieChart } from "../Charts/PieChart";

const styles = StyleSheet.create({
  tableContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    overflowX: "auto",
    padding: 7,
  },
  table: {
    width: "20%",
    borderRadius: 6,
    margin: 3,
    backgroundColor: "#ededed",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  fieldValue: {
    flexDirection: "row",
    fontSize: 5,
    paddingHorizontal: 3,
    paddingVertical: 2,
    color: "#000",
    alignItems: "center",
    verticalAlign: "middle",
  },
  field: {
    fontSize: 6,
    fontWeight: "extra-bold",
    color: "#000",
    marginRight: 5,
    width: "50%",
  },
  value: {
    fontSize: "5px",
    width: "50%",
    backgroundColor: "#fff",
    borderRadius: 2,
    color: "#333",
    padding: "1px 3px",
  },
  row: {
    flexDirection: "row",
  },
  headerCell: {
    flex: 1,
  },
  subHeader: {
    marginBottom: 5,
    marginTop: 5,
    textAlign: "center",
    fontSize: "5px",
    color: "#000",
  },
  cell: {
    border: "0.4px",
    borderColor: "#ffe4c4",
    backgroundColor: "#ffe4c4",
    borderBottomColor: "gray",
    padding: "1px",
    textAlign: "center",
    paddingLeft: "2px",
    paddingRight: "0px",
    fontSize: "5px",
    flex: 1,
    color: "#555",
  },
  vesselTable: {
    width: "32%",
    borderRadius: 6,
    margin: 3,
    backgroundColor: "#ededed",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    paddingBottom: "10px",
  },
  Operationtable: {
    width: "100%",
    padding: 0,
    margin: 5,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  colLeft: {
    width: "45%",
  },
  colRight: {
    width: "55%",
  },
  headerText: {
    fontSize: 4,
    border: "0.4px",
    borderColor: "#ccc",
    padding: "2px",
    textAlign: "center",
    paddingLeft: "3px",
    paddingRight: "0px",
    fontSize: "5px",
    flex: 1,
    color: "#1c1c1c",
    backgroundColor: "#e8e8e8",
  },
  typeCell: {
    fontWeight: "bold",
    color: "#1c1c1c",
    backgroundColor: "#f2f0f0",
    whiteSpace: "nowrap",
    overflow: "visible",
    textOverflow: "clip",
    display: "inline-block",
  },
  TablesBackGround: {
    padding: "3px",
    borderRadius: "5px",
    backgroundColor: "#f0f0f0",
  },
  heading: {
    backgroundColor: "#3f51b5",
    fontSize: "6px",
    margin: 0,
    padding: "5px",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    marginBottom: "5px",
  },
  text: {
    width: "25%",
    verticalAlign: "middle",
  },
  resultHeading: {
    backgroundColor: "#117f09",
    fontSize: "7px",
    margin: 0,
    padding: "5px",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    marginBottom: "5px",
  },
  operationHeader: {
    borderBottomColor: "gray",
    padding: "1px",
    textAlign: "center",
    paddingLeft: "2px",
    paddingRight: "0px",
    fontSize: "6px",
    flex: 1,
    color: "#fff",
  },
  operationHeading: {
    backgroundColor: "#3f51b5",
    margin: 0,
    padding: "5px",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
  },
  opreationCell: {
    backgroundColor: "#ededed",
    padding: "5px",
    textAlign: "center",
    paddingLeft: "2px",
    paddingRight: "0px",
    fontSize: "6px",
    flex: 1,
    color: "#555",
  },
});

const CargoTable = ({ data }) => {
  return (
    <View style={styles.table}>
      <Text style={styles.heading}>Cargo Details</Text>
      {Object.entries(data).map(([fieldName, value], index) => (
        <View style={styles.fieldValue} key={index}>
          <Text style={styles.field}>{fieldName}:</Text>
          <Text>{value || "-"}</Text>
        </View>
      ))}
    </View>
  );
};
const FreightTable = ({ data }) => {
  return (
    <View style={styles.table}>
      <Text style={styles.heading}>Freight and Result</Text>
      {Object.entries(data).map(([fieldName, value], index) => (
        <View style={styles.fieldValue} key={index}>
          <Text style={styles.field}>{fieldName}:</Text>
          <Text style={styles.value}>{value || "-"}</Text>
        </View>
      ))}
    </View>
  );
};
const ResultTable = ({ data }) => {
  return (
    <View style={styles.table}>
      <Text style={styles.resultHeading}>Result Details</Text>
      {Object.entries(data).map(([fieldName, value], index) => (
        <View style={styles.fieldValue} key={index}>
          <Text style={styles.field}>{fieldName}:</Text>
          <Text>{value || "-"}</Text>
        </View>
      ))}
    </View>
  );
};

const VesselTable = ({ data, vesselData }) => {
  const ballastData = data.filter((row) => row.Type.startsWith("Ballast"));
  const ladenData = data.filter((row) => row.Type.startsWith("Laden"));
  const portData = data.filter(
    (row) => row.Type !== "Ballast" && row.Type !== "Laden"
  );

  return (
    <View style={styles.vesselTable}>
      <Text style={styles.heading}>Vessel Details</Text>
      {/* Vessel Data Section */}
      <View style={styles.row}>
        <View style={styles.colLeft}>
          {Object.entries(vesselData).map(([fieldName, value], index) => (
            <View style={styles.fieldValue} key={index}>
              <Text style={styles.field}>{fieldName}:</Text>
              <Text style={[styles.value]}>{value || "-"}</Text>
            </View>
          ))}
        </View>
        <View style={[styles.colRight]}>
          <View style={styles.row}>
            <Text style={styles.fieldValue}>DWT :</Text>
            <Text style={styles.value}>{vesselData.DWT || "-"}</Text>
            <Text style={styles.fieldValue}>IMO :</Text>
            <Text style={styles.value}>{vesselData.IMO || "-"} </Text>
          </View>
          {/* Ballast Section */}
          <Text style={styles.subHeader}>ballast Consumption Data</Text>
          <View style={styles.row}>
            {["Speed", "HS", "LS", "MGO"].map((header, index) => (
              <Text key={index} style={[styles.cell, styles.headerText]}>
                {header}
              </Text>
            ))}
          </View>
          {ballastData.map((row, rowIndex) => (
            <View key={rowIndex} style={styles.row}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "10px",
                }}
              >
                <Image
                  source={row["Selected"] === true ? checkBox1 : checkBox2}
                  style={{
                    width: 8,
                    height: 8,
                    alignSelf: "center",
                  }}
                  resizeMode="contain"
                />
              </View>
              {["Speed", "HS", "LS", "MGO"].map((header, index) => (
                <View key={index} style={styles.cell}>
                  <Text>{row[header] || "-"}</Text>
                </View>
              ))}
            </View>
          ))}

          {/* Laden Section */}
          <Text style={styles.subHeader}>Laden Consumption Data</Text>
          <View style={styles.row}>
            {["Speed", "HS", "LS", "MGO"].map((header, index) => (
              <Text key={index} style={[styles.cell, styles.headerText]}>
                {header}
              </Text>
            ))}
          </View>
          {ladenData.map((row, rowIndex) => (
            <View key={rowIndex} style={styles.row}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "10px",
                }}
              >
                <Image
                  source={row["Selected"] === true ? checkBox1 : checkBox2}
                  style={{ width: 8, height: 8, alignSelf: "center" }}
                  resizeMode="contain"
                />
              </View>
              {["Speed", "HS", "LS", "MGO"].map((header, index) => (
                <View key={index} style={styles.cell}>
                  <Text>{row[header] || "-"}</Text>
                </View>
              ))}
            </View>
          ))}

          {/* Port Section */}
          <Text style={styles.subHeader}>Port Consumption Data</Text>
          <View style={styles.row}>
            {["Operation", "HS", "LS", "MGO"].map((header, index) => (
              <Text key={index} style={[styles.cell, styles.headerText]}>
                {header}
              </Text>
            ))}
          </View>
          {portData.map((row, rowIndex) => (
            <View key={rowIndex} style={styles.row}>
              {["Type", "HS", "LS", "MGO"].map((header, index) => (
                <Text
                  key={index}
                  style={[
                    styles.cell,
                    header === "Type" ? styles.typeCell : null,
                  ]}
                >
                  {row[header] || "-"}
                </Text>
              ))}
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

const AllowancesTable = ({ data, fuelTable }) => {
  const {
    DWF,
    Perf_Factor,
    speedReduce,
    bnkrFactor,
    extraExpense,
    constants,
    fuelCalculationMethod,
  } = data;

  const { Type, Normal, ECA, MGO } = fuelTable;
  return (
    <View style={styles.table}>
      <Text style={styles.heading}>Margin and Allowances</Text>
      <View style={styles.fieldValue}>
        <Text style={styles.field}>DWT:</Text>
        <Text style={[styles.value, styles.text]}>{DWF || "-"} %</Text>
        <Text style={styles.text}>%</Text>
      </View>
      <View style={styles.fieldValue}>
        <Text style={styles.field}>Perf Factor:</Text>
        <Text style={[styles.value, styles.text]}>{Perf_Factor || "-"}%</Text>
        <Text style={styles.text}>%</Text>
      </View>
      <View style={styles.fieldValue}>
        <Text style={styles.field}>Speed Reduce:</Text>
        <Text style={[styles.value, styles.text]}>{speedReduce || "-"} </Text>
        <Text style={styles.text}> Knots</Text>
      </View>
      <View style={styles.fieldValue}>
        <Text style={styles.field}>Bunker Con Allowance:</Text>
        <Text style={[styles.value, styles.text]}>{bnkrFactor || "-"}%</Text>
        <Text style={styles.text}>%</Text>
      </View>

      {fuelCalculationMethod === "DEFAULT" ? (
        <View>
          <View style={styles.fieldValue}>
            <Text style={styles.field}>Fuel Calculation Method:</Text>
            <Text style={styles.value}>{fuelCalculationMethod}</Text>
          </View>
          <Text style={styles.subHeader}>Fuel Price:</Text>

          <View style={styles.row}>
            {["Type", "Normal", "ECA", "MGO"].map((header, index) => (
              <Text key={index} style={[styles.headerText]}>
                {header}
              </Text>
            ))}
          </View>

          {fuelTable.map((row, rowIndex) => (
            <View key={rowIndex} style={styles.row}>
              {["Type", "Normal", "ECA", "MGO"].map((header, index) => (
                <Text key={index} style={styles.cell}>
                  {row[header] || "0"}
                </Text>
              ))}
            </View>
          ))}
        </View>
      ) : (
        ""
      )}

      <Text style={styles.subHeader}>This Vessel:</Text>
      <View style={styles.fieldValue}>
        <Text style={styles.field}>Extra Expense:</Text>
        <Text style={styles.value}>{extraExpense || "-"}</Text>
      </View>
      <View style={styles.fieldValue}>
        <Text style={styles.field}>Constants:</Text>
        <Text style={styles.value}>{constants || "-"}</Text>
      </View>
    </View>
  );
};

const Add_operationTable = ({ data }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return <Text>No data available</Text>;
  }

  const headers = Object.keys(data[0]);

  return (
    <View style={styles.Operationtable}>
      {/* Render headers */}
      <View style={[styles.row, styles.operationHeading]}>
        {headers.map((header, index) => (
          <Text key={index} style={[styles.operationHeader]}>
            {header}
          </Text>
        ))}
      </View>

      {/* Render rows */}
      {data.map((row, rowIndex) => (
        <View key={rowIndex} style={styles.row}>
          {headers.map((header, index) => (
            <Text key={index} style={styles.opreationCell}>
              {row[header] || "-"}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};

const OtherTables = ({ data }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return <Text>No data available</Text>;
  }

  const headers = Object.keys(data[0]);
  return (
    <View style={styles.Operationtable}>
      {/* Render headers */}
      <View style={[styles.row, styles.operationHeading]}>
        {headers.map((header, index) => (
          <Text key={index} style={[styles.operationHeader]}>
            {header}
          </Text>
        ))}
      </View>

      {/* Render rows */}
      {data.map((row, rowIndex) => (
        <View key={rowIndex} style={styles.row}>
          {headers.map((header, index) => (
            <Text key={index} style={styles.opreationCell}>
              {row[header] || "-"}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};

const PdfDataComponent = ({ data }) => {
  const cargoData = {
    Account: data?.Cargo_Details?.account,
    Cargo: data?.Cargo_Details?.cargo,
    "Laycan Start Date": data?.Cargo_Details?.laycanStartDate
      ? moment(data?.Cargo_Details?.laycanStartDate).format("DD/MMM/yyyy HH:MM")
      : "",
    "Laycan End Date": data?.Cargo_Details?.laycanEndDate
      ? moment(data?.Cargo_Details?.laycanEndDate).format("DD/MMM/yyyy HH:MM")
      : "",
    Adcom: data?.Cargo_Details?.adcom,
    Brokerage: data?.Cargo_Details?.brokerage,
    Other: data?.Cargo_Details?.other,
    "Sf of cargo": data?.Cargo_Details?.sf_of_cargo,
    "Cargo Min": data?.Cargo_Details?.Cargo_Min,
    "Cargo Max": data?.Cargo_Details?.Cargo_Max,
    "freight Mode": data?.Cargo_Details?.freightMode,
    Tag1: data?.Cargo_Details?.Tag1,
    Tag2: data?.Cargo_Details?.Tag2,
    Tag3: data?.Cargo_Details?.Tag3,
    Tag4: data?.Cargo_Details?.Tag4,
  };
  const Margin_and_Allowances = {
    DWF: data?.Margin_and_Allowances?.DWF || "0",
    Perf_Factor: data?.Margin_and_Allowances?.Perf_Factor || "0",
    speedReduce: data?.Margin_and_Allowances?.speedReduce || "0",
    bnkrFactor: data?.Margin_and_Allowances?.bnkrFactor || "0",
    extraExpense: data?.Margin_and_Allowances?.extraExpense || "0",
    constants: data?.Margin_and_Allowances?.constants || "0",
    fuelCalculationMethod:
      data?.Margin_and_Allowances?.fuelCalculationMethod || "DEFAULT",
  };

  const fuelTable = [
    {
      Type: data?.Margin_and_Allowances?.Fuel_Price?.fuel_type || "Type-1",
      Normal: data?.Margin_and_Allowances?.Fuel_Price?.Normal || "0",
      ECA: data?.Margin_and_Allowances?.Fuel_Price?.ECA || "0",
      MGO: data?.Margin_and_Allowances?.Fuel_Price?.MGO || "0",
    },
  ];

  const vesselData = {
    "Vessel Name": data?.vessel?.vesselName,
    DWT: data?.vessel?.DWT,
    Draft: data?.vessel?.Draft,
    TPC: data?.vessel?.TPC,
    Grain: data?.vessel?.grain,
    Built: data?.vessel?.built,
    LOA: data?.vessel?.LOA,
    Beam: data?.vessel?.Beam,
    GRT: data?.vessel?.GRT,
    NRT: data?.vessel?.NRT,
    "Light Ship": data?.vessel?.light_ship,
    "Bale Capacity": data?.vessel?.bale_capacity,
    Gears: data?.vessel?.Gears,
    Grabs: data?.vessel?.Grabs,
    Constants: data?.vessel?.Constants,
    "Fuel Type": data?.vessel?.fuel_type,
    Scrubber: data?.vessel?.scrubber,
    IMO: data?.vessel?.IMO,
  };

  const ballastConsumptionData = [
    {
      Selected: data?.vessel?.BalComb1,
      Speed: data?.vessel?.Ballast_Sea_Consumptions?.col_1?.Speed,
      HS: data?.vessel?.Ballast_Sea_Consumptions?.col_1?.HS,
      LS: data?.vessel?.Ballast_Sea_Consumptions?.col_1?.LS,
      MGO: data?.vessel?.Ballast_Sea_Consumptions?.col_1?.MGO,
    },

    {
      Selected: data?.vessel?.BalComb2,
      Speed: data?.vessel?.Ballast_Sea_Consumptions?.col_2?.Speed,
      HS: data?.vessel?.Ballast_Sea_Consumptions?.col_2?.HS,
      LS: data?.vessel?.Ballast_Sea_Consumptions?.col_2?.LS,
      MGO: data?.vessel?.Ballast_Sea_Consumptions?.col_2?.MGO,
    },

    {
      Selected: data?.vessel?.BalComb3,
      Speed: data?.vessel?.Ballast_Sea_Consumptions?.col_3?.Speed,
      HS: data?.vessel?.Ballast_Sea_Consumptions?.col_3?.HS,
      LS: data?.vessel?.Ballast_Sea_Consumptions?.col_3?.LS,
      MGO: data?.vessel?.Ballast_Sea_Consumptions?.col_3?.MGO,
    },

    {
      Selected: data?.vessel?.BalComb4,
      Speed: data?.vessel?.Ballast_Sea_Consumptions?.col_4?.Speed,
      HS: data?.vessel?.Ballast_Sea_Consumptions?.col_4?.HS,
      LS: data?.vessel?.Ballast_Sea_Consumptions?.col_4?.LS,
      MGO: data?.vessel?.Ballast_Sea_Consumptions?.col_4?.MGO,
    },
  ];

  const ladenConsumptionData = [
    {
      Selected: data?.vessel?.LadComb1,
      Speed: data?.vessel?.Laden_Sea_Consumptions?.col_1?.Speed,
      HS: data?.vessel?.Laden_Sea_Consumptions?.col_1?.HS,
      LS: data?.vessel?.Laden_Sea_Consumptions?.col_1?.LS,
      MGO: data?.vessel?.Laden_Sea_Consumptions?.col_1?.MGO,
    },

    {
      Selected: data?.vessel?.LadComb2,
      Speed: data?.vessel?.Laden_Sea_Consumptions?.col_2?.Speed,
      HS: data?.vessel?.Laden_Sea_Consumptions?.col_2?.HS,
      LS: data?.vessel?.Laden_Sea_Consumptions?.col_2?.LS,
      MGO: data?.vessel?.Laden_Sea_Consumptions?.col_2?.MGO,
    },
    {
      Selected: data?.vessel?.LadComb3,
      Speed: data?.vessel?.Laden_Sea_Consumptions?.col_3?.Speed,
      HS: data?.vessel?.Laden_Sea_Consumptions?.col_3?.HS,
      LS: data?.vessel?.Laden_Sea_Consumptions?.col_3?.LS,
      MGO: data?.vessel?.Laden_Sea_Consumptions?.col_3?.MGO,
    },

    {
      Selected: data?.vessel?.LadComb4,
      Speed: data?.vessel?.Laden_Sea_Consumptions?.col_4?.Speed,
      HS: data?.vessel?.Laden_Sea_Consumptions?.col_4?.HS,
      LS: data?.vessel?.Laden_Sea_Consumptions?.col_4?.LS,
      MGO: data?.vessel?.Laden_Sea_Consumptions?.col_4?.MGO,
    },
  ];

  const portConsumptionData = [
    {
      Operation: "Idle",
      HS: data?.vessel?.Port_Consumptions?.Idle?.HS,
      LS: data?.vessel?.Port_Consumptions?.Idle?.LS,
      MGO: data?.vessel?.Port_Consumptions?.Idle?.MGO,
    },
    {
      Operation: "Loading",
      HS: data?.vessel?.Port_Consumptions?.Loading?.HS,
      LS: data?.vessel?.Port_Consumptions?.Loading?.LS,
      MGO: data?.vessel?.Port_Consumptions?.Loading?.MGO,
    },
    {
      Operation: "Discharge",
      HS: data?.vessel?.Port_Consumptions?.Disch?.HS,
      LS: data?.vessel?.Port_Consumptions?.Disch?.LS,
      MGO: data?.vessel?.Port_Consumptions?.Disch?.MGO,
    },
    {
      Operation: "Extra",
      HS: data?.vessel?.Port_Consumptions?.Extra?.HS,
      LS: data?.vessel?.Port_Consumptions?.Extra?.LS,
      MGO: data?.vessel?.Port_Consumptions?.Extra?.MGO,
    },
  ];

  const resultData = {
    "Freight Cost": data?.result?.frtCost
      ? Number(data.result.frtCost).toFixed(2)
      : "0",
    "Profit PMT": data?.result?.profitPmt
      ? Number(data.result.profitPmt).toFixed(2)
      : "0",
    "Net Profit": data?.result?.profitNet
      ? Number(data.result.profitNet).toFixed(2)
      : "0",
    "Net Freight Revenue": data?.result?.netFrtRevenue
      ? Number(data.result.netFrtRevenue).toFixed(2)
      : "0",
    "Hire Expense": data?.result?.hireExpense
      ? Number(data.result.hireExpense).toFixed(2)
      : "0",
    "Fuel Expense": data?.result?.fuelExpense
      ? Number(data.result.fuelExpense).toFixed(2)
      : "0",
    "Other Expense": data?.result?.otherExpense
      ? Number(data.result.otherExpense).toFixed(2)
      : "0",
    "HS Quantity": data?.result?.hsQuantity
      ? Number(data.result.hsQuantity).toFixed(2)
      : "0",
    "LS Quantity": data?.result?.lsQuantity
      ? Number(data.result.lsQuantity).toFixed(2)
      : "0",
    "MGO Quantity": data?.result?.mgoQuantity
      ? Number(data.result.mgoQuantity).toFixed(2)
      : "0",
    "Voyage Duration": data?.result?.voyageDuration
      ? Number(data.result.voyageDuration).toFixed(2)
      : "0",
    "Port Stay Total": data?.result?.portStayTotal
      ? Number(data.result.portStayTotal).toFixed(2)
      : "0",
    Stowage: data?.result?.stowage
      ? Number(data.result.stowage).toFixed(2)
      : "0",
    "Fuel Settlement Profit": data?.result?.fuelSettlementProfit
      ? Number(data.result.fuelSettlementProfit).toFixed(2)
      : "0",
  };

  const combinedConsumptionData = [
    ...ballastConsumptionData.map((data, index) => ({
      Type: `Ballast`,
      Selected: data.Selected,
      Speed: data.Speed,
      HS: data.HS,
      LS: data.LS,
      MGO: data.MGO,
    })),
    ...ladenConsumptionData.map((data, index) => ({
      Type: `Laden`,
      Selected: data.Selected,
      Speed: data.Speed,
      HS: data.HS,
      LS: data.LS,
      MGO: data.MGO,
    })),
    ...portConsumptionData.map((data) => ({
      Type: data.Operation,
      HS: data.HS,
      LS: data.LS,
      MGO: data.MGO,
    })),
  ];
  const Freight_and_Result = {
    "Freight Rate": data?.Freight_and_Result?.Freight_Rate || "0",
    tcEq: `${data?.Freight_and_Result?.tcEq?.toFixed(2) || "0"} / ${data?.Freight_and_Result?.gbb || "0"
      }`,
    "hire per day": `${data?.Freight_and_Result?.hirePerDay || "0"} / ${data?.Freight_and_Result?.hireGbb || "0"
      }`,
    "profit per day": `${data?.Freight_and_Result?.profitPerDay?.toFixed(2) || "0"
      } / ${data?.Freight_and_Result?.profitPerDayGBB || "0"}`,
    "Misc Revenue": data?.Freight_and_Result?.Misc_Revenue || "0",
    "CVE(per30days)": data?.Freight_and_Result?.CVE || "0",
    "Insurance(Per Day)": data?.Freight_and_Result?.insurance || "0",
    "On/Off Hire Survey": data?.Freight_and_Result?.hireSurvey || "0",
    "Canal Expenses": data?.Freight_and_Result?.canalExpenses || "0",
    "additional Premium": data?.Freight_and_Result?.additionalPremium || "0",
    ILOHC: data?.Freight_and_Result?.ilohc || "0",
    "Manoevring Exp	": data?.Freight_and_Result?.miscExp || "0",
    "Misc Exp	": data?.Freight_and_Result?.manoevringExp || "0",
  };

  const add_operations = data?.add_operations?.map((operation) => ({
    Operation: operation?.operation || "",
    Port: operation?.Port || "",
    "dist Normal": operation?.distNormal || "0",
    "dist ECA": operation?.distEca || "0",
    DWF: operation?.dwf || "0",
    "speed Normal": operation?.speedNormal || "0",
    "speed ECA": operation?.speedECA || "0",
    "Cargo Tx": operation?.cargoTx || "0",
    "Rate MT/day": operation?.rate || "0",
    Terms: operation?.terms || "",
    Factor: operation?.factor || "0",
    "Turn Time(d)": operation?.turnTime || "0",
    "Extra Days": operation?.extraDays || "0",
    "Port Exp": operation?.portExp || "0",
    "Port Stay": operation?.portStay
      ? Number(operation?.portStay).toFixed(2)
      : "0",
    "Port Emission": operation?.Port_emission || "0",
    Loadline: operation?.loadline || "",
    "Draft Res": operation?.draftRes || "0",
    "Water Den": operation?.waterDensity || "0",
    supply: operation?.supply || "",
  }));

  const otherTableData = data?.otherTables?.map((data) => ({
    "Vessel Name": data?.vessel_name,
    DWT: data?.DWT || "0",
    port: data?.port || "",
    "Date Opening": data?.dateOpen
      ? moment(data?.dateOpen).format("DD/MMM/YYYY HH:MM")
      : "",
    "TC Eq": data?.tcEq || "0",
    gbb: data?.gbb || "0",
    "hire Per Day": data?.hirePerDay || "0",
    "hire Gbb": data?.hireGbb || "0",
    "Frt Cost": data?.frtCost || "0",
    profit: data?.profit || "0",
    loadability: data?.loadability || "0",
    ETA: data?.ETA ? moment(data?.ETA).format("DD/MMM/YYYY HH:MM") : "",
    "Vessel Premium": data?.VesselPremium || "0",
    "Position Premium": data?.positionPremium || "0",
    "Remarks": data?.remark || "",
  }));

  return (
    <Document>
      <Page
        size="A4"
        orientation="landscape"
        style={{ padding: 10, color: "#f9f9f9" }}
      >
        <View>
          {/* <Text style={{ fontSize: 4, color: "000" }}>pdfId:</Text> */}
          {/* Row with 5 Tables */}
          <View style={styles.tableContainer}>
            <CargoTable data={cargoData} />

            <VesselTable
              data={combinedConsumptionData}
              vesselData={vesselData}
            />

            <AllowancesTable
              data={Margin_and_Allowances}
              fuelTable={fuelTable}
            />
            <FreightTable data={Freight_and_Result} />
            <ResultTable data={resultData} />
          </View>
          <View style={styles.tableContainer}>
            <Add_operationTable data={add_operations} />
          </View>
          <View style={styles.tableContainer}>
            <OtherTables data={otherTableData} />
            {/* <PieChart label="Voyage Expenses Break-up" /> */}

          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDataComponent;
