import React from 'react'
import { TableContainer, Paper, Table, TableBody, TableHead, TableCell, TableRow } from "@material-ui/core"
import moment from 'moment'
import { handleKeyDown } from '../../common/common'

function MarginAllowancesTable(props) {
    const {
        calculatorData = {},
        setCalculatorData = () => { },
        disableEdit = false
    } = props

    const { portOperation = [] } = calculatorData || {}

    const onChangeMarginAllowance = (e, index) => {
        const { name = "", value = "" } = e.target
        let updatedPortOperation = [...portOperation]
        if (name === "dwf") {
            updatedPortOperation[index].dwf = Number(value)
        } else {
            updatedPortOperation[index].cargoDetails[name] = Number(value)
        }
        setCalculatorData({
            ...calculatorData,
            portOperation: updatedPortOperation
        })
    }

    return (
        <div>
            {disableEdit ? <h1 className="modal-head-text">Margin Allowances Table</h1> : null}
            <TableContainer component={Paper} className="bunker-supply-table">

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Operation</TableCell>
                            <TableCell>Port</TableCell>
                            <TableCell>Dwf (%)</TableCell>
                            <TableCell>Speed Factor (%)</TableCell>
                            <TableCell>Speed Reduce (Knots)</TableCell>
                            <TableCell>BnkrFackor (%)</TableCell>
                            {/* <TableCell>Arrival</TableCell>
                            <TableCell>Departure</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            portOperation.map((operations, index) => {
                                const { operation = '', port = '', cargoDetails = {}, dwf = 0, arrivalTime = '', departureTime = '' } = operations
                                const { bnkrFactor = 0, speedReduce = 0, speedFactor = 0 } = cargoDetails || {}

                                return <TableRow key={index}>
                                    <TableCell><p>{operation}</p></TableCell>
                                    <TableCell><p>{port}</p></TableCell>
                                    <TableCell><input onKeyDown={handleKeyDown} type="number" className="margin-allowance-input" value={dwf} name="dwf" onChange={(e) => onChangeMarginAllowance(e, index)} /></TableCell>
                                    <TableCell><input onKeyDown={handleKeyDown} type="number" className="margin-allowance-input" value={speedFactor} name="speedFactor" onChange={(e) => onChangeMarginAllowance(e, index)} /></TableCell>
                                    <TableCell><input onKeyDown={handleKeyDown} type="number" className="margin-allowance-input" value={speedReduce} name="speedReduce" onChange={(e) => onChangeMarginAllowance(e, index)} /></TableCell>
                                    <TableCell><input onKeyDown={handleKeyDown} type="number" className="margin-allowance-input" value={bnkrFactor} name="bnkrFactor" onChange={(e) => onChangeMarginAllowance(e, index)} /></TableCell>
                                    {/* <TableCell><p>{arrivalTime ? moment(arrivalTime).format('DD-MM-yyyy HH:mm') : ''}</p></TableCell>
                                    <TableCell><p>{departureTime ? moment(departureTime).format('DD-MM-yyyy HH:mm') : ''}</p></TableCell> */}
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default MarginAllowancesTable
