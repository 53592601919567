import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Box,
  Paper,
  makeStyles,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Pagination from "@material-ui/lab/Pagination";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../components/Common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import CustomAddModal from "../../../components/Common/CustomAddModal";
import AddNewVessel from "../../../components/Dashboard/AddNewVessel";
import moment from "moment";
import { isSuperAdminLogin, toastError, toastSuccess } from "../../../utils";
import _ from "lodash";
import CustomTextField from "../../../components/Common/CustomTextField";
import { getOrganizationId, isAdminLogin } from "../../../utils";
import { initialVesselState } from "../../../constants";
import CustomPagination from "../../../components/Common/CustomPagination";

const useStyles = makeStyles({
  root: {
    width: "fit-content",
  },
});

export default function VesselInformation(props) {
  const { setLoader = () => {} } = props;
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const delaySearch = useCallback(
    _.debounce((filters) => getSearchedData({ ...filters }), 500),
    []
  );

  const {
    allVessels = [],
    allOrganization = [],
    allFleetByOrg = [],
  } = useSelector((state) => state.dashboard);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(1);
  const [saveLabel, setSaveLabel] = useState("Add");
  const [search, setSearch] = useState("");
  const [vessel, setVessel] = useState({ ...initialVesselState });
  const [filters, setFilters] = useState({
    vessel: "",
    offset: 0,
    limit: 25,
  });
  const [selectedOrganization, setSelectedOrganization] = useState({});
  const [fleetFilters, setFleetFilters] = useState({
    offset: 0,
    limit: 25,
  });
  const [selectedFleetId, setSelectedFleetId] = useState("");

  useEffect(() => {
    getAllVessels();
  }, []);

  useEffect(() => {
    if (getOrganizationId()) {
      let selectedOrganization =
        Array.isArray(allOrganization) && allOrganization.length
          ? allOrganization.find((org) => org._id === getOrganizationId())
          : {};
      setSelectedOrganization(selectedOrganization);
    } else {
      if (allOrganization.length) setSelectedOrganization(allOrganization[0]);
    }
  }, [allOrganization]);

  useEffect(() => {
    if (selectedOrganization?._id || selectedOrganization === "allFleets") {
      getAllfleetByOrg();
    }
  }, [selectedOrganization]);

  const getInititalData = async (filters) => {
    // let requests = []
    // requests.push(getAllVessels(filters))
    // if (selectedOrganization?._id) {
    //     requests.push(getAllfleetByOrg(filters))
    // }
    setLoader(true);
    // Promise.all(requests).then(() => {
    //     setSaveLabel('Add')
    //     setLoader(false)
    // }).catch(() => setLoader(false))
    if (value === 1) await getAllVessels(filters);
    else await getAllfleetByOrg(filters);
    setSaveLabel("Add");
    setLoader(false);
  };

  const getAllVessels = async (filters) => {
    setLoader(true);
    await dispatch(actions.allVessels(filters))
      .then(() => {
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const getAllfleetByOrg = async (filters) => {
    setLoader(true);
    await dispatch(
      actions.getFleetByOrg({
        ...filters,
        isValid: true,
        organizationId:
          selectedOrganization === "allFleets" ? "" : selectedOrganization?._id,
      })
    )
      .then(() => {
        setLoader(false);
      })
      .catch((err) => {
        toastError(err?.data?.message);
        setLoader(false);
      });
  };

  const getSearchedData = (filters) => {
    setLoader(true);
    dispatch(actions.allVesselsByChar({ ...filters, skipUserId: true }))
      .then(() => {
        setLoader(false);
        setSaveLabel("Add");
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const onChangeHandler = (e) => {
    let {
      name = "",
      value = "",
      type = "checkbox",
      checked = false,
    } = e.target;
    let newVessel = { ...vessel };
    if (type === "checkbox") {
      newVessel[name] = checked;
    } else {
      if (value && value.toString().split("")[0] === 0) return;
      newVessel[name] = value;
    }
    setVessel(newVessel);
  };

  const searchVessel = (value) => {
    setSearch(value);
    // setFilters({...filters, })
    if (!!value) {
      delaySearch({ ...filters, vessel: value, skipUserId: true });
    } else {
      dispatch(actions.allVessels({ ...filters, skipUserId: true }));
    }
  };

  const onCloseModal = () => {
    setOpen(false);
    setSaveLabel("Add");
    setVessel({ ...initialVesselState });
  };

  const onSubmitConfirm = () => {
    if (!vessel.vesselName) return toastError("Vessel Name is required");
    setLoader(true);
    if (saveLabel === "Update") {
      actions
        .updateVesselWithFleetDetails(vessel)
        .then(async (res) => {
          onCloseModal();
          setLoader(false);
          toastSuccess("Vessel Updated Successfully");
          setVessel({ ...initialVesselState });
          if (filters.vessel) {
            getSearchedData(filters);
          } else {
            setLoader(true);

            if (value === 2) await getAllfleetByOrg(filters);
            await getAllVessels(filters);
            setSaveLabel("Add");
            setLoader(false);
          }
          setSelectedFleetId("");
        })
        .catch((err) => {
          setLoader(false);
          toastError(err.error);
          setOpen(true);
        });
      return;
    }
    actions
      .createVessel(vessel)
      .then((res) => {
        onCloseModal();
        setLoader(false);
        toastSuccess("Vessel Created Successfully");
        setVessel({ ...initialVesselState });
        if (filters.vessel) {
          getSearchedData(filters);
        } else {
          getInititalData(filters);
        }
      })
      .catch((err) => {
        setLoader(false);
        toastError(err.error);
        setOpen(true);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onClickEdit = (item) => {
    setVessel(item);
    setSaveLabel("Update");
    setOpen(true);
  };

  const onChangeOrganization = (id) => {
    setFleetFilters({ ...filters, offset: 0 });
    const selectedOrganization =
      id === "allFleets"
        ? "allFleets"
        : allOrganization?.find((org) => org._id === id);

    setSelectedOrganization(selectedOrganization);
  };

  const onChangePagination = (type, api) => {
    if (api === "vessel") {
      let newFilters = {};
      if (type === "inc") {
        newFilters = { ...filters, offset: filters.offset + 1 };
      } else if (type === "first") {
        newFilters = { ...filters, offset: 0 };
      } else {
        if (filters.offset === 0) return;
        newFilters = { ...filters, offset: filters.offset - 1 };
      }
      setLoader(true);
      dispatch(actions.allVessels(newFilters))
        .then(() => {
          setLoader(false);
          setFilters(newFilters);
        })
        .catch((err) => {
          toastError();
          setLoader(false);
        });
    } else {
      let newFilters = {};
      if (type === "inc") {
        newFilters = { ...fleetFilters, offset: fleetFilters.offset + 1 };
      } else if (type === "first") {
        newFilters = { ...fleetFilters, offset: 0 };
      } else {
        if (fleetFilters.offset === 0) return;
        newFilters = { ...fleetFilters, offset: fleetFilters.offset - 1 };
      }
      setLoader(true);
      dispatch(
        actions.getFleetByOrg({
          ...newFilters,
          organizationId: selectedOrganization?._id,
        })
      )
        .then(() => {
          setLoader(false);
          setFleetFilters(newFilters);
        })
        .catch((err) => {
          toastError();
          setLoader(false);
        });
    }
  };

  const onDiscardFleet = () => {
    if (selectedFleetId) {
      setLoader(true);
      actions
        .markFleet({
          id: selectedFleetId,
          status: false,
        })
        .then((res) => {
          onCloseModal();
          setLoader(false);
          if (filters.vessel) {
            getSearchedData(filters);
          } else {
            getInititalData(filters);
          }
          setSelectedFleetId("");
        })
        .catch((err) => {
          setLoader(false);
          toastError();
        });
    }
  };

  const fleetShowing = (
    <p>
      Showing{" "}
      <span>{(fleetFilters.offset + 1 - 1) * fleetFilters.limit || 1}</span>-
      <span>{(fleetFilters.offset + 1 || 1) * fleetFilters.limit}</span> of{" "}
      <span>{(fleetFilters.offset + 1 || 1) * fleetFilters.limit}</span>
    </p>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Paper
            onClick={() => history.goBack()}
            className="px-3 py-1 bg-neutrals9 border border-neutrals6 text-neutrals4 rounded-md text-sm flex items-center gap-1 cursor-pointer"
          >
            <ArrowBackIosIcon className="text-sm" /> Go back
          </Paper>
          <div style={{ width: "130px" }}>
            <CustomButton
              onClick={() => setOpen(true)}
              className="bg-page-name rounded-md"
            >
              Create Vessel
            </CustomButton>
          </div>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box pt={2}>
          <Paper className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab value={1} label="Vessel List" />
              <Tab value={2} label="Fleet List" />
            </Tabs>
          </Paper>
        </Box>
      </Grid>

      <Grid item xs={12}>
        {value === 1 ? (
          <Box pt={2}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ maxWidth: 500, marginBottom: 20 }}>
                  <CustomTextField
                    value={search}
                    onChange={(e) => searchVessel(e.target.value)}
                    placeholder="Search By vessel name"
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table className="universal-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Vessel Name</TableCell>
                    <TableCell>DWT</TableCell>
                    <TableCell>Draft</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Ship Country</TableCell>
                    <TableCell>Edit Vessel</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(allVessels) && allVessels.length ? (
                    allVessels.map((item, index) => {
                      const {
                        vesselName = "",
                        vesselDwt = "",
                        vesselDraftS = "",
                        vesselShipowner = "",
                        vesselCountry = "",
                      } = item;
                      return (
                        <TableRow
                          key={index}
                          onDoubleClick={() => {
                            onClickEdit(item);
                          }}
                        >
                          <TableCell>{vesselName}</TableCell>
                          <TableCell>{vesselDwt}</TableCell>
                          <TableCell>{vesselDraftS}</TableCell>
                          <TableCell>{vesselShipowner}</TableCell>
                          <TableCell>{vesselCountry}</TableCell>
                          <TableCell>
                            <EditIcon
                              onClick={() => onClickEdit(item)}
                              className="page-name"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan="6"
                        align="center"
                        className="!bg-white"
                      >
                        No Vessel Found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {/* <Pagination count={totalPages} showFirstButton showLastButton onChange={onPageChange} page={filters.page || 0} /> */}
              {/* <Pagination count={10} showFirstButton showLastButton page={0} /> */}
              <CustomPagination
                onChangePagination={onChangePagination}
                filters={filters}
              />
            </TableContainer>
          </Box>
        ) : (
          <Box pt={2}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ maxWidth: 500, marginBottom: 20 }}>
                  <FormControl variant="filled" size="small" fullWidth>
                    <Select
                      value={
                        selectedOrganization === "allFleets"
                          ? "allFleets"
                          : selectedOrganization?._id
                          ? selectedOrganization?._id
                          : ""
                      }
                      onChange={(e) => onChangeOrganization(e.target.value)}
                      disabled={!isSuperAdminLogin()}
                      defaultValue="Single Freight"
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem dense value="allFleets">
                        All Fleets
                      </MenuItem>
                      {Array.isArray(allOrganization) &&
                        allOrganization.map((item, index) => {
                          return (
                            <MenuItem key={index} dense value={item._id}>
                              {item.organizationName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table className="universal-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Vessel Name</TableCell>
                    <TableCell>DWT</TableCell>
                    <TableCell>Port Opening</TableCell>
                    <TableCell>Date Opening</TableCell>
                    <TableCell>Hire Per Day</TableCell>
                    <TableCell>Hire GBB</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Edit Vessel</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(allFleetByOrg) && allFleetByOrg.length ? (
                    allFleetByOrg.map((item, index) => {
                      const {
                        vesselName = "",
                        dwt = "",
                        port = "",
                        dateOpen = "",
                        hirePerDay = "",
                        hireGbb = "",
                        createdUserName = "",
                      } = item;
                      return (
                        <TableRow
                          key={index}
                          onDoubleClick={() => {
                            setSelectedFleetId(item._id);
                            onClickEdit(JSON.parse(item.vessel));
                          }}
                        >
                          <TableCell>{vesselName}</TableCell>
                          <TableCell>{dwt}</TableCell>
                          <TableCell>{port || ""}</TableCell>
                          <TableCell>
                            {dateOpen
                              ? moment(dateOpen).format("DD/MMM/yyyy")
                              : ""}
                          </TableCell>
                          <TableCell>{hirePerDay}</TableCell>
                          <TableCell>{hireGbb}</TableCell>
                          <TableCell>{createdUserName}</TableCell>
                          <TableCell>
                            <EditIcon
                              className="page-name"
                              onClick={() => {
                                setSelectedFleetId(item._id);
                                onClickEdit(JSON.parse(item.vessel));
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan="6"
                        align="center"
                        className="!bg-white"
                      >
                        No Fleet Found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {/* <Pagination count={totalPages} showFirstButton showLastButton onChange={onPageChange} page={filters.page || 0} /> */}
              {/* <Pagination count={10} showFirstButton showLastButton page={0} /> */}
              <CustomPagination
                onChangePagination={onChangePagination}
                filters={fleetFilters}
              />
            </TableContainer>
          </Box>
        )}
      </Grid>

      <CustomAddModal
        open={open}
        saveLabel={saveLabel}
        title={`${saveLabel} Vessel`}
        maxWidth="sm"
        addThirdBtn={value === 2}
        thirdBtnAction={onDiscardFleet}
        thirdBtnLabel="Discard"
        handleClose={onCloseModal}
        onSubmitConfirm={onSubmitConfirm}
      >
        <AddNewVessel vessel={vessel} onChangeHandler={onChangeHandler} />
      </CustomAddModal>
    </Grid>
  );
}
