import {
  addComplaintsAPI,
  deleteComplaintsAPI,
  getAllComplaintsAPI,
  getComplaintsAPI,
  getSingleComplaintsAPI,
  updateComplaintsAPI,
} from "./api";

export const addComplaints = (id, data) => {
  return new Promise((resolve, reject) => {
    addComplaintsAPI(id, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateComplaints = (data) => {
  return new Promise((resolve, reject) => {
    updateComplaintsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComplaints = (data) => {
  return new Promise((resolve, reject) => {
    getComplaintsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllComplaints = (data) => {
  return new Promise((resolve, reject) => {
    getAllComplaintsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSingleComplaints = (data) => {
  return new Promise((resolve, reject) => {
    getSingleComplaintsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};



export const deleteComplaints = (data) => {
  return new Promise((resolve, reject) => {
    deleteComplaintsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};