import React, { useState, memo, useCallback, useMemo } from "react";
import {
  CargoCargoOperationHeader,
} from "../../../../constants";
import CargoPortList from "./CargoPortList";

function CargoCargoPortOperations(props) {
  const {
    disableEdit = false,
    calculatorData = [],
    onChangePortOperations = () => {},
 
  } = props;
  const { vesselVoyageData = [] } = calculatorData || {};

  let newPortOperationHeader = [...CargoCargoOperationHeader];


  let showModal = ["Dist Normal", "Dist ECA", "Terms", "Supply"];
  if (disableEdit) showModal = showModal.filter((item) => item !== "Supply");

  return (
    <div style={{ overflow: "auto" }} className="operation-background">
      <div className="port-operation-buttons">
      </div>
      <div className="cargo-cargo-port-operation-header">
        {newPortOperationHeader.map((item, index) => {
          const isModalField = showModal.includes(item.label);
         
          return (
            <div key={index} className="flex items-center flex-wrap">
              <span
                style={{
                  minWidth: item.width,
                  width: item.width,
                  paddingLeft:item?.paddingLeft,
                  cursor: isModalField ? "pointer" : "default",
                  color: isModalField ? "#fff"
                  //0051B6
                  : "",
                  textDecoration: isModalField ? "underline" : "none",
                  display:"flex",
                  flexDirection:"column",
                }}
              >
                {item.label}
              </span>{" "}
              
            </div>
          );
        })}
      </div>
      {vesselVoyageData.length ? (
        <CargoPortList
          onChangePortOperations={onChangePortOperations}
          combinedOperation={vesselVoyageData}
      
        />
      ) : (
        <div className="no-port-found-div">
          <p>No port operations found.</p>
        </div>
      )}
    </div>
  );
}

export default memo(CargoCargoPortOperations);
