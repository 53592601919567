import React from "react";
import { Grid, Checkbox, Radio, FormControl, Select, MenuItem } from "@material-ui/core";
import CustomTextField from "../Common/CustomTextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { isSuperAdminLogin } from "../../utils";

function AddNewUser(props) {
  const {
    user = {},
    getForwardUser = "",
    setUser = () => { },
    isEdit = false,
    selectedOrganization = {},
    onChangeOrganization = () => { },
    isAdminLogin = () => { },
    allOrganization = [],
    allAccess = [],
  } = props;
  let { allRoles = [] } = props;
  const {
    firstName = "",
    lastName = "",
    email = "",
    forwardEmail = "",
    dateOfBirth = "",
    premiumAccessExpiry,
    password = "",
    organization = "",
    role = [],
    isPremium = false,
    isVerified = false,
  } = user || {};

  const onChange = (key, value) => {
    setUser({ ...user, [key]: value });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <CustomTextField
          label="First Name"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => onChange("firstName", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomTextField
          label="Last Name"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => onChange("lastName", e.target.value)}
        />
      </Grid>
      {/* add to disable chrome auto fill */}
      <Grid item xs={12}>
        <CustomTextField
          label="Email"
          placeholder="Email"
          value={email}
          disabled={isEdit}
          onChange={(e) => onChange("email", e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          label="Forward mail address"
          placeholder="Email address"
          value={forwardEmail}
          disabled={getForwardUser && isEdit}
          onChange={(e) => onChange("forwardEmail", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* <div className="react-date-picker"> */}
        {/* <DatePicker
                    onChange={onChange}
                    value={new Date()}
                /> */}
        {/* </div> */}
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            autoOk
            fullWidth
            variant="inline"
            inputVariant="outlined"
            format="DD/MMM/yyyy"
            placeholder="DOB: DD/MMM/YYYY"
            label="Date Of Birth"
            value={dateOfBirth || null}
            InputAdornmentProps={{ position: "end" }}
            onChange={(date) => onChange("dateOfBirth", date)}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* <div className="react-date-picker"> */}
        {/* <DatePicker
                    onChange={onChange}
                    value={new Date()}
                /> */}
        {/* </div> */}
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            autoOk
            fullWidth
            variant="inline"
            inputVariant="outlined"
            format="DD/MMM/yyyy"
            label="Premium Access Expiry Date"
            placeholder="Premium Access Expiry Date"
            value={premiumAccessExpiry || null}
            InputAdornmentProps={{ position: "end" }}
            onChange={(date) => onChange("premiumAccessExpiry", date)}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      {/* add to disable chrome auto fill */}
      <Grid item xs={12} sm={6} style={{ display: "none" }}>
        <CustomTextField />
      </Grid>
      {isEdit ? null : (
        <Grid item xs={12}>
          <CustomTextField
            inputType="password"
            placeholder="Password"
            value={password}
            onChange={(e) => onChange("password", e.target.value)}
          />
        </Grid>
      )}

      {user.role !== "superadmin" && (isSuperAdminLogin() || !isEdit) && (
        <Grid item xs={5}>
          <h4>Select Roles</h4>
          <ul className="select-roles-list">
            {Array.isArray(allRoles) &&
              allRoles
                .filter((item) => !item?.hide)
                .map((item, index) => {
                  const isSelected = role === item.value;
                  return (
                    <li
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => onChange("role", item.value)}
                    >
                      <Radio checked={!!isSelected} />
                      {item.name}
                    </li>
                  );
                })}
          </ul>
        </Grid>
      )}
      <Grid item xs={7}>
        <Grid container>
          <Grid item xs={12}>
            <h4>Select Organization</h4>
            <ul className="select-roles-list">
              <FormControl variant="filled" size="small" fullWidth>
                <Select
                  value={
                    // (selectedOrganization && selectedOrganization._id) || ""
                    (user.organizationId && user.organizationId) || ""
                  }
                  onChange={(e) => onChange("organizationId", e.target.value)}
                  // label="Age"
                  disabled={(isEdit && (!isAdminLogin() || !isSuperAdminLogin()))}
                  defaultValue="Single Freight"
                  size="small"
                  variant="outlined"
                  fullWidth
                >
                  {Array.isArray(allOrganization) &&
                    allOrganization.map((item, index) => {
                      return (
                        <MenuItem key={index} dense value={item._id}>
                          {item.organizationName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </ul>
          </Grid>
          {user.role !== "superadmin" && (
            <Grid item xs={12}>
              <h4>User Access</h4>{" "}
              <ul className="select-roles-list">
                {Array.isArray(allAccess) &&
                  allAccess.map((item, index) => {
                    return (
                      <li
                        style={{ cursor: "pointer" }}
                        key={index}
                        onClick={() => onChange(item.value, !user[item.value])}
                      >
                        <Checkbox checked={user[item.value]} />
                        {item.name}
                      </li>
                    );
                  })}
              </ul>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddNewUser;
