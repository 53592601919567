import React from 'react'
import { Grid, Box, Paper } from "@material-ui/core"
import CustomTextField from '../Common/CustomTextField'
import { createVesselOptions, fuelPriceOptions, scrubberOptions, vesselCalculatorMainFields } from '../../constants'
import CalculatorVesselDetails from "../Calculator/CalculatorVesselDetails"
import { handleKeyDown } from '../../common/common'

function AddNewVessel(props) {
    const { onChangeHandler = () => { }, vessel = {}, showDetails = false } = props;
    const {
        vesselBalConsmHS1 = 0,
        vesselBalConsmHS2 = 0,
        vesselBalConsmHS3 = 0,
        vesselBalConsmHS4 = 0,
        vesselBalConsmLS1 = 0,
        vesselBalConsmLS2 = 0,
        vesselBalConsmLS3 = 0,
        vesselBalConsmLS4 = 0,
        vesselBalConsmMGO1 = 0,
        vesselBalConsmMGO2 = 0,
        vesselBalConsmMGO3 = 0,
        vesselBalConsmMGO4 = 0,
        vesselBalSpd1 = 0,
        vesselBalSpd2 = 0,
        vesselBalSpd3 = 0,
        vesselBalSpd4 = 0,
        vesselDwt = 0,
        vesselName = "",
        vesselGrainCap = 0,
        vesselDraftS,
        vesselLdnConsmHS1 = 0,
        vesselLdnConsmHS2 = 0,
        vesselLdnConsmHS3 = 0,
        vesselLdnConsmHS4 = 0,
        vesselLdnConsmLS1 = 0,
        vesselLdnConsmLS2 = 0,
        vesselLdnConsmLS3 = 0,
        vesselLdnConsmLS4 = 0,
        vesselLdnConsmMGO1 = 0,
        vesselLdnConsmMGO2 = 0,
        vesselLdnConsmMGO3 = 0,
        vesselLdnConsmMGO4 = 0,
        vesselLdnSpd1 = 0,
        vesselLdnSpd2 = 0,
        vesselLdnSpd3 = 0,
        vesselLdnSpd4 = 0,
        fuelType = '',
        vesselPDischConsmHS = 0,
        vesselPDischConsmLS = 0,
        vesselPDischConsmMGO = 0,
        vesselPIdleConsmHS = 0,
        vesselPIdleConsmLS = 0,
        vesselPIdleConsmMGO = 0,
        vesselPLdgConsmHS = 0,
        vesselPLdgConsmLS = 0,
        vesselPLdgConsmMGO = 0,
        vesselVExtraConsmHS = 0,
        vesselVExtraConsmLS = 0,
        vesselVExtraConsmMGO = 0,
        vesselTPC = 0,
        vesselBalComb1Selected = false,
        vesselBalComb2Selected = false,
        vesselBalComb3Selected = false,
        vesselBalComb4Selected = false,
        vesselLadComb1Selected = false,
        vesselLadComb2Selected = false,
        vesselLadComb3Selected = false,
        vesselLadComb4Selected = false,
        vesselIMO = 0,
        vesselScrubber = ''
    } = vessel || {}

    // return <Grid item xs={12}>
    //     <div className="upper-section-grid">
    //         <CalculatorVesselDetails
    //         // setLoader={setLoader}
    //         // setSelectedVessels={setSelectedVessels}
    //         // selectedVessels={selectedVessels}
    //         // calculatorData={calculatorData}
    //         // setCalculatorData={setCalculatorData}
    //         // onChangeHandler={onChangeSelectedVessel}
    //         // fleetCheckbox={fleetCheckbox}
    //         // setFleetCheckbox={setFleetCheckbox}
    //         />
    //     </div>
    // </Grid>

    return (
        <Grid container className='create-new-vessel-popup'>
            <Grid item xs={12}>
                <Paper>
                    <Grid container>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <div className="vessel-name-div">
                                        <span>Vessel</span>
                                        <span><input value={vesselName} name="vesselName" onChange={onChangeHandler} /></span>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className="vessel-name-div">
                                        <span>Dwt</span>
                                        <span><input value={vesselDwt} name="vesselDwt" onChange={onChangeHandler} /></span>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className="vessel-name-div">
                                        <span>IMO</span>
                                        <span><input value={vesselIMO} name="vesselIMO" onChange={onChangeHandler} /></span>
                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={5}>
                            <table className="fleet-result-table">
                                {vesselCalculatorMainFields.map((item, key) => {
                                    if (item.name === 'fuelType') {
                                        return <tr key={key}>
                                            <th>{item.label}</th>
                                            <select
                                                value={fuelType || 'Type-1'}
                                                name="fuelType"
                                                className="vessel-scrubber-select"
                                                onChange={onChangeHandler}
                                            // disabled={disableSelection}
                                            >
                                                {fuelPriceOptions.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.label}</option>
                                                })}
                                            </select>
                                        </tr>
                                    }
                                    if (item.name === 'vesselScrubber') {
                                        return <tr key={key}>
                                            <th>{item.label}</th>
                                            <select
                                                value={vesselScrubber || 'Yes'}
                                                name="vesselScrubber"
                                                className="vessel-scrubber-select"
                                                onChange={onChangeHandler}
                                            >
                                                {scrubberOptions.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.label}</option>
                                                })}
                                            </select>
                                        </tr>
                                    }
                                    return <tr key={key}>
                                        <th>{item.label}</th>
                                        <td>
                                            <input
                                                value={vessel[item.name] || ''}
                                                type={item.type}
                                                onKeyDown={handleKeyDown} 
                                                name={item.name}
                                                onChange={onChangeHandler}
                                            />
                                        </td>
                                    </tr>
                                })}
                            </table>
                        </Grid>
                        <Grid item xs={7}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <p className="m-b-5">Ballast Sea Consumptions</p>
                                    <div className="consumptions-headers">
                                        <div style={{ width: 25 }}></div>
                                        <p>Speed</p>
                                        <p>HS</p>
                                        <p>LS</p>
                                        <p>MGO</p>
                                    </div>
                                    <div className="consumptions-content">
                                        <input type="checkbox" className="input-type-checkbox" name="vesselBalComb1Selected" checked={vesselBalComb1Selected || false} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselBalSpd1" value={vesselBalSpd1 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselBalConsmHS1" value={vesselBalConsmHS1 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselBalConsmLS1" value={vesselBalConsmLS1 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselBalConsmMGO1" value={vesselBalConsmMGO1 || ''} onChange={onChangeHandler} />
                                    </div>
                                    <div className="consumptions-content">
                                        <input type="checkbox" className="input-type-checkbox" name="vesselBalComb2Selected" checked={vesselBalComb2Selected || false} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselBalSpd2" value={vesselBalSpd2 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselBalConsmHS2" value={vesselBalConsmHS2 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselBalConsmLS2" value={vesselBalConsmLS2 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselBalConsmMGO2" value={vesselBalConsmMGO2 || ''} onChange={onChangeHandler} />
                                    </div>
                                    <div className="consumptions-content">
                                        <input type="checkbox" className="input-type-checkbox" name="vesselBalComb3Selected" checked={vesselBalComb3Selected || false} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselBalSpd3" value={vesselBalSpd3 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselBalConsmHS3" value={vesselBalConsmHS3 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselBalConsmLS3" value={vesselBalConsmLS3 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselBalConsmMGO3" value={vesselBalConsmMGO3 || ''} onChange={onChangeHandler} />
                                    </div>
                                    <div className="consumptions-content">
                                        <input type="checkbox" className="input-type-checkbox" name="vesselBalComb4Selected" checked={vesselBalComb4Selected || false} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselBalSpd4" value={vesselBalSpd4 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselBalConsmHS4" value={vesselBalConsmHS4 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselBalConsmLS4" value={vesselBalConsmLS4 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselBalConsmMGO4" value={vesselBalConsmMGO4 || ''} onChange={onChangeHandler} />
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <p className="m-t-5 m-b-5">Laden Sea Consumptions</p>
                                    <div className="consumptions-headers">
                                        <div style={{ width: 25 }}></div>
                                        <p>Speed</p>
                                        <p>HS</p>
                                        <p>LS</p>
                                        <p>MGO</p>
                                    </div>
                                    <div className="consumptions-content">
                                        <input type="checkbox" className="input-type-checkbox" name="vesselLadComb1Selected" checked={vesselLadComb1Selected || false} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselLdnSpd1" value={vesselLdnSpd1 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselLdnConsmHS1" value={vesselLdnConsmHS1 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselLdnConsmLS1" value={vesselLdnConsmLS1 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselLdnConsmMGO1" value={vesselLdnConsmMGO1 || ''} onChange={onChangeHandler} />
                                    </div>
                                    <div className="consumptions-content">
                                        <input type="checkbox" className="input-type-checkbox" name="vesselLadComb2Selected" checked={vesselLadComb2Selected || false} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselLdnSpd2" value={vesselLdnSpd2 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselLdnConsmHS2" value={vesselLdnConsmHS2 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselLdnConsmLS2" value={vesselLdnConsmLS2 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselLdnConsmMGO2" value={vesselLdnConsmMGO2 || ''} onChange={onChangeHandler} />
                                    </div>
                                    <div className="consumptions-content">
                                        <input type="checkbox" className="input-type-checkbox" name="vesselLadComb3Selected" checked={vesselLadComb3Selected || false} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselLdnSpd3" value={vesselLdnSpd3 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselLdnConsmHS3" value={vesselLdnConsmHS3 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselLdnConsmLS3" value={vesselLdnConsmLS3 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown}  placeholder="0" name="vesselLdnConsmMGO3" value={vesselLdnConsmMGO3 || ''} onChange={onChangeHandler} />
                                    </div>
                                    <div className="consumptions-content">
                                        <input type="checkbox" className="input-type-checkbox" name="vesselLadComb4Selected" checked={vesselLadComb4Selected || false} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselLdnSpd4" value={vesselLdnSpd4 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselLdnConsmHS4" value={vesselLdnConsmHS4 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselLdnConsmLS4" value={vesselLdnConsmLS4 || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselLdnConsmMGO4" value={vesselLdnConsmMGO4 || ''} onChange={onChangeHandler} />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <p className="m-t-5 m-b-5">Port Consumptions</p>
                                    <div className="consumptions-headers">
                                        <p></p>
                                        <p>HS</p>
                                        <p>LS</p>
                                        <p>MGO</p>
                                    </div>
                                    <div className="consumptions-content">
                                        <p className="port-consumptions-label">Idle</p>
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselPIdleConsmHS" value={vesselPIdleConsmHS || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselPIdleConsmLS" value={vesselPIdleConsmLS || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselPIdleConsmMGO" value={vesselPIdleConsmMGO || ''} onChange={onChangeHandler} />
                                    </div>
                                    <div className="consumptions-content">
                                        <p className="port-consumptions-label">Loading</p>
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselPLdgConsmHS" value={vesselPLdgConsmHS || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselPLdgConsmLS" value={vesselPLdgConsmLS || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselPLdgConsmMGO" value={vesselPLdgConsmMGO || ''} onChange={onChangeHandler} />
                                    </div>
                                    <div className="consumptions-content">
                                        <p className="port-consumptions-label">Disch</p>
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselPDischConsmHS" value={vesselPDischConsmHS || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselPDischConsmLS" value={vesselPDischConsmLS || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselPDischConsmMGO" value={vesselPDischConsmMGO || ''} onChange={onChangeHandler} />
                                    </div>
                                    <div className="consumptions-content">
                                        <p className="port-consumptions-label">Extra</p>
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselVExtraConsmHS" value={vesselVExtraConsmHS || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselVExtraConsmLS" value={vesselVExtraConsmLS || ''} onChange={onChangeHandler} />
                                        <input type="number" onKeyDown={handleKeyDown} placeholder="0" name="vesselVExtraConsmMGO" value={vesselVExtraConsmMGO || ''} onChange={onChangeHandler} />
                                    </div>

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </Paper>
            </Grid>
        </Grid>
    )
    /*
        return (
            <Grid container className="add-vessel-container">
                <Grid item xs={12}>
                    <div style={{ maxWidth: "300px" }}>
                        {showDetails ? <div><span style={{ fontWeight: 500 }}>Vessel Name:</span> {vesselName}</div> : <CustomTextField
                            placeholder="Enter Vessel Name"
                            size="small"
                            name="vesselName"
                            value={vesselName || ''}
                            onChange={onChangeHandler}
                        />}
                    </div>
                </Grid>
    
                <Grid item xs={showDetails ? 4 : 12}>
                    <Box className="item-box-contianer">
                        {!showDetails ? createVesselOptions.map((item, index) => {
                            return <div className="upper-field-items" key={index}>
                                <p>{item.label}</p>
                                <input
                                    className="upper-field-input"
                                    placeholder=".."
                                    type={item.type}
                                    name={item.name}
                                    value={(vessel && vessel[item.name]) || ''}
                                    onChange={onChangeHandler}
                                />
                            </div>
                        })
                            : <div className="show-detail-vessel-table"><table>{createVesselOptions.map((item, index) => {
                                return <tr key={index}>
                                    <th>{item.label}</th>
                                    <td>{(vessel && vessel[item.name]) || ''}</td>
                                </tr>
                            })}
                                <tr>
                                    <th>Vessel Scrubber</th>
                                    <td>{fuelType || '-'}</td>
                                </tr>
                            </table></div>
                        }
                    </Box>
                </Grid>
    
                {showDetails ? null : <Grid item xs={12}>
                    <Box display="flex" mt={2}>
                        <p style={{ fontSize: 14 }}>Vessel Scrubber</p>
                        <select
                            style={{ marginLeft: 10, width: 100, fontSize: 12 }}
                            name="fuelType"
                            value={fuelType}
                            onChange={onChangeHandler}
                        >
                            {fuelPriceOptions.map((item, index) => {
                                return <option key={index} value={item.value} >{item.newLabel}</option>
                            })}
                        </select>
                    </Box>
                </Grid>}
                <Grid item xs={showDetails ? 8 : 12}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={showDetails ? 6 : 3}>
                            <Box pl={0} p={2} style={{ marginTop: 10 }}>
                                <p style={{ fontSize: 12, marginTop: 10, marginBottom: 10 }}>Ballast Sea Consumptions</p>
                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <p className="bottom-p-tag">Speed</p>
                                    <p className="bottom-p-tag">HS</p>
                                    <p className="bottom-p-tag">LS</p>
                                    <p className="bottom-p-tag">MGO</p>
                                </div>
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    <input type="checkbox" name="vesselBalComb1Selected" checked={vesselBalComb1Selected || false} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalSpd1" value={vesselBalSpd1 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalConsmHS1" value={vesselBalConsmHS1 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalConsmLS1" value={vesselBalConsmLS1 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalConsmMGO1" value={vesselBalConsmMGO1 || ''} onChange={onChangeHandler} />
                                </div>
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    <input type="checkbox" name="vesselBalComb2Selected" checked={vesselBalComb2Selected || false} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalSpd2" value={vesselBalSpd2 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalConsmHS2" value={vesselBalConsmHS2 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalConsmLS2" value={vesselBalConsmLS2 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalConsmMGO2" value={vesselBalConsmMGO2 || ''} onChange={onChangeHandler} />
                                </div>
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    <input type="checkbox" name="vesselBalComb3Selected" checked={vesselBalComb3Selected || false} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalSpd3" value={vesselBalSpd3 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalConsmHS3" value={vesselBalConsmHS3 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalConsmLS3" value={vesselBalConsmLS3 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalConsmMGO3" value={vesselBalConsmMGO3 || ''} onChange={onChangeHandler} />
                                </div>
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    <input type="checkbox" name="vesselBalComb4Selected" checked={vesselBalComb4Selected || false} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalSpd4" value={vesselBalSpd4 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalConsmHS4" value={vesselBalConsmHS4 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalConsmLS4" value={vesselBalConsmLS4 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselBalConsmMGO4" value={vesselBalConsmMGO4 || ''} onChange={onChangeHandler} />
                                </div>
                            </Box>
                        </Grid>
    
                        <Grid item xs={12} sm={6} md={showDetails ? 6 : 3}>
                            <Box p={2} style={{ marginTop: 10 }}>
                                <p style={{ fontSize: 12, marginTop: 10, marginBottom: 10 }}>Laden Sea Consumptions</p>
                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <p className="bottom-p-tag">Speed</p>
                                    <p className="bottom-p-tag">HS</p>
                                    <p className="bottom-p-tag">LS</p>
                                    <p className="bottom-p-tag">MGO</p>
                                </div>
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    <input type="checkbox" name="vesselLadComb1Selected" checked={vesselLadComb1Selected || false} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnSpd1" value={vesselLdnSpd1 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnConsmHS1" value={vesselLdnConsmHS1 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnConsmLS1" value={vesselLdnConsmLS1 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnConsmMGO1" value={vesselLdnConsmMGO1 || ''} onChange={onChangeHandler} />
                                </div>
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    <input type="checkbox" name="vesselLadComb2Selected" checked={vesselLadComb2Selected || false} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnSpd2" value={vesselLdnSpd2 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnConsmHS2" value={vesselLdnConsmHS2 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnConsmLS2" value={vesselLdnConsmLS2 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnConsmMGO2" value={vesselLdnConsmMGO2 || ''} onChange={onChangeHandler} />
                                </div>
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    <input type="checkbox" name="vesselLadComb3Selected" checked={vesselLadComb3Selected || false} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnSpd3" value={vesselLdnSpd3 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnConsmHS3" value={vesselLdnConsmHS3 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnConsmLS3" value={vesselLdnConsmLS3 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnConsmMGO3" value={vesselLdnConsmMGO3 || ''} onChange={onChangeHandler} />
                                </div>
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    <input type="checkbox" name="vesselLadComb4Selected" checked={vesselLadComb4Selected || false} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnSpd4" value={vesselLdnSpd4 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnConsmHS4" value={vesselLdnConsmHS4 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnConsmLS4" value={vesselLdnConsmLS4 || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselLdnConsmMGO4" value={vesselLdnConsmMGO4 || ''} onChange={onChangeHandler} />
                                </div>
                            </Box>
                        </Grid>
    
                        <Grid item xs={12} sm={6} md={showDetails ? 6 : 3}>
                            <Box p={2} style={{ marginTop: 10 }}>
                                <p style={{ fontSize: 12, marginTop: 10, marginBottom: 10 }}>Port Consumptions</p>
                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <p className="bottom-p-tag"></p>
                                    <p className="bottom-p-tag">HS</p>
                                    <p className="bottom-p-tag">LS</p>
                                    <p className="bottom-p-tag">MGO</p>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <p className="port-consumptions-label">Idle</p>
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselPIdleConsmHS" value={vesselPIdleConsmHS || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselPIdleConsmLS" value={vesselPIdleConsmLS || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselPIdleConsmMGO" value={vesselPIdleConsmMGO || ''} onChange={onChangeHandler} />
                                </div>
                                <div style={{ display: "flex" }}>
                                    <p className="port-consumptions-label">Loading</p>
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselPLdgConsmHS" value={vesselPLdgConsmHS || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselPLdgConsmLS" value={vesselPLdgConsmLS || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselPLdgConsmMGO" value={vesselPLdgConsmMGO || ''} onChange={onChangeHandler} />
                                </div>
                                <div style={{ display: "flex" }}>
                                    <p className="port-consumptions-label">Disch</p>
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselPDischConsmHS" value={vesselPDischConsmHS || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselPDischConsmLS" value={vesselPDischConsmLS || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselPDischConsmMGO" value={vesselPDischConsmMGO || ''} onChange={onChangeHandler} />
                                </div>
                                <div style={{ display: "flex" }}>
                                    <p className="port-consumptions-label">Extra</p>
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselVExtraConsmHS" value={vesselVExtraConsmHS || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselVExtraConsmLS" value={vesselVExtraConsmLS || ''} onChange={onChangeHandler} />
                                    <input type="number" className="upper-field-input" placeholder="0" name="vesselVExtraConsmMGO" value={vesselVExtraConsmMGO || ''} onChange={onChangeHandler} />
                                </div>
    
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={showDetails ? 6 : 3}>
                            <div style={{ marginTop: 60 }}>
                                <div style={{ display: "flex" }}>
                                    <p className="port-consumptions-label2">DWT</p>
                                    <input type="number" className="upper-field-input" placeholder="0" style={{ width: "100px" }} name="vesselDwt" value={vesselDwt || ''} onChange={onChangeHandler} />
                                </div>
                                <div style={{ display: "flex" }}>
                                    <p className="port-consumptions-label2">Drafts</p>
                                    <input type="number" className="upper-field-input" placeholder="0" style={{ width: "100px" }} name="vesselDraftS" value={vesselDraftS || ''} onChange={onChangeHandler} />
                                </div>
                                <div style={{ display: "flex" }}>
                                    <p className="port-consumptions-label2">TPC</p>
                                    <input type="number" className="upper-field-input" placeholder="0" style={{ width: "100px" }} name="vesselTPC" value={vesselTPC || ''} onChange={onChangeHandler} />
                                </div>
                                <div style={{ display: "flex" }}>
                                    <p className="port-consumptions-label2">Grain Capacity</p>
                                    <input type="number" className="upper-field-input" placeholder="0" style={{ width: "100px" }} name="vesselGrainCap" value={vesselGrainCap || ''} onChange={onChangeHandler} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
    
    
            </Grid >
        )
    
        */
}

export default AddNewVessel