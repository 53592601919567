import React, { useCallback, useState } from "react";
import { CircularProgress, Grid, Paper, Switch, TextField } from "@material-ui/core";
import { expenseResultField } from "../../constants";
import { handleKeyDown } from "../../common/common";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import SplitHireModal from "./SplitHireModal";
import moment from "moment";
import { tagsByCharAPI } from "../../containers/User/Orders/actions";
import { debounce } from "lodash";
import { Autocomplete } from "@material-ui/lab";

function VesselVesselReDeliveryIn(props) {
  const {
    selectedVessels = {},
    onChangeSelectedVessel = () => { },
    openSplitHireModal = () => { },
    calculatorData = {},
    setCalculatorData = () => { },
    isFromSingleVesselCalcy = false,
    addNewSplitHire = () => { },
    onChangeSplitHire = () => { },
    isOverride = false,
    setIsOverride,
    label = ""

  } = props || {};

  const { clientQuery = {}, portOperation = [] } = calculatorData || {};
  const { Tag3 = {}, Tag4 = {}, } = clientQuery
  const [TagsOptions, setTagsOptions] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(false);

  const onChangeQueryValues = (e) => {
    const { name = "", value = "" } = e.target;
    let newPortOperation = [...portOperation];
    if (name === "freightIdea") {
      newPortOperation = newPortOperation.map((item) => {
        if (item.operation === "Loading") {
          return { ...item, freight: Number(value) };
        }
        return item;
      });
    }
    setCalculatorData({
      ...calculatorData,
      clientQuery: {
        ...clientQuery,
        [name]: Number(value),
      },
      portOperation: newPortOperation,
    });
  };
  const onSelectTags = (value, name) => {
    setCalculatorData({
      ...calculatorData,
      clientQuery: {
        ...clientQuery,
        [name]: value,
      },
    });
    setTagsOptions([])
  };

  const onSearchTagsList = (value, type, name) => {
    if (value) {
      setTagsLoading(name);
      tagsByCharAPI(value, type)
        .then((res) => {
          setTagsLoading(false);
          let tagsList = [];
          if (res.data.list?.length > 0) {
            if (type === "individual") {
              tagsList = res.data.list.map((item) => ({
                label: `${item.individual_firstName} (${item?.individual_emailPersonal})`,
                value: item._id,
                name: item.individual_firstName
              }));
            } else {
              tagsList = res.data.list.map((item) => ({
                label: `${item.organisation_fullName} (${item?.organisation_emailOfficial})`,
                value: item._id,
                name: item.organisation_fullName
              }));
            }
          }
          setTagsOptions(tagsList);
        })
        .catch((err) => {
          setTagsLoading(false);
          setTagsOptions([]);
        });
    }
  };

  const debouncedSearch = useCallback(
    debounce((search, type, name) => {
      onSearchTagsList(search, type, name);
    }, 400),
    []
  );

  const delayTagsSearch = (search, type, name) => {
    // setSelectedPort({ ...selectedPort, port: search });
    debouncedSearch(search, type, name);
  };


  const {
    hireGbb = 0,
    hirePerDay = 0,
    tcEq = 0,
    port = "",
    dateOpen,
    DurationOut = 0,
    MinDuration = 0,
    MaxDuration = 0,
    bnkDelhs = 0,
    bnkDells = 0,
    bnkDelmgo = 0,
    bnkPriceDeliveryhs = 0,
    bnkPriceDeliveryls = 0,
    bnkPriceDeliverymgo = 0,
    bnkPriceRedeliveryhs = 0,
    bnkPriceRedeliveryls = 0,
    bnkPriceRedeliverymgo = 0,
    bnkPriceSettlemenths = 0,
    bnkPriceSettlementls = 0,
    bnkPriceSettlementmgo = 0,
    bnkQtyRedeliveryhs = 0,
    bnkQtyRedeliveryls = 0,
    bnkQtyRedeliverymgo = 0,
    bnkRedeliveryQtyTolerancehs = 0,
    bnkRedeliveryQtyTolerancels = 0,
    bnkRedeliveryQtyTolerancemgo = 0,

    miscRevenue = 0,
    revenue = 0,

  } = selectedVessels?.vesselOut || {};


  return (
    <Grid container>
      <Grid item xs={12} className="calculator-section-header">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4 className="result-heading">{label}</h4>
          {/* <span style={{ marginRight: 10 }}>GBB</span> */}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div>
          <table className="fleet-result-table low-th-width">
            <tr>
              <th>Port</th>
              <td>
                <input
                  type="text"
                  // onKeyDown={handleKeyDown}
                  value={calculatorData?.portOperation?.filter((itm) => itm?.operation === "Delivery-out")?.[0]?.port || ""}
                  name="port"
                // onChange={onChangeQueryValues}
                />
              </td>
            </tr>
            <tr>
              <th>Date Opening</th>
              <td style={{ paddingRight: "8px" }} className="datepicker-vessel-vessel">
                <div style={{ paddingRight: "5px", background: "#fff", borderRadius: "3px" }}>
                  {moment(calculatorData?.portOperation?.filter((itm) => itm?.operation === "Redelivery-in")?.[0]?.departureTime).format("MMM Do 'YY HH:mm")}
                </div>
                {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    format="MMM Do 'YY HH:mm"
                    size="small"
                    name="dateOpen"
                    // className="date-time-picker-update-fleet"
                    style={{ maxWidth: 140, background: "#fff" }}
                    disabled
                    value={(dateOpen) || Date.now()}
                    onChange={(date) =>
                      onChangeSelectedVessel(date, false, "dateOpen", true)
                    }
                  />
                </MuiPickersUtilsProvider> */}
              </td>
            </tr>
            <tr>
              <th>Duration-Out</th>
              <td className="d-flex">
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={DurationOut || 0}
                  name="DurationOut"
                  style={{ width: 80, background: "#fff", marginRight: "5px" }}
                  onChange={(e) => onChangeSelectedVessel(e, true)}
                // disabled={disablefreightIdea}
                />
                <div>
                  days
                </div>
              </td>

            </tr>
            <tr >
              <th>Min Duration</th>
              <td className="d-flex" >
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={MinDuration || 0}
                  style={{ width: "80px", background: "#fff", marginRight: "5px" }}
                  name="MinDuration"
                  onChange={(e) => onChangeSelectedVessel(e, true)}
                />
                <div>
                  days
                </div>
              </td>

            </tr>
            <tr>
              <th>Max Duration</th>
              <td className="d-flex">

                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={MaxDuration || 0}
                  style={{ width: 80, background: "#fff", marginRight: "5px" }}
                  name="MaxDuration"
                  onChange={(e) => onChangeSelectedVessel(e, true)}
                />
                <div>
                  days
                </div>
              </td>
            </tr>
            {/* 
            <tr>
              <th></th>
              <td className="pt-2" style={{ fontWeight: 500 }}>
                Split Hire
              </td>
            </tr>
            <tr>
              <th></th>
              <td >
                <div className="split-hire-vessel-vessel">
                  <SplitHireModal
                    addNewSplitHire={addNewSplitHire}
                    selectedSplitHire={selectedVessels}
                    onChangeSplitHire={onChangeSplitHire}
                    isfromVesselVessl
                  />
                </div>
              </td>
            </tr> */}


            <tr>
              <th
                className="cursor-pointer underline"
                onClick={() => openSplitHireModal(selectedVessels)}
              >
                Hire Per Day
              </th>
              <td > Hire GBB</td>
            </tr>
            <tr>
              <td>

                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  placeholder="0"
                  value={hirePerDay}
                  name="hirePerDay"
                  className="mr-2"
                  onChange={(e) => onChangeSelectedVessel(e, true)}
                />
              </td>
              <td className="d-flex">

                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  placeholder="0"
                  value={hireGbb}
                  name="hireGbb"
                  onChange={(e) => onChangeSelectedVessel(e, true)}
                />
              </td>

            </tr>
            <tr>
              <th className="pt-1">TC Eq </th></tr>
            <tr>
              <th style={{ fontWeight: "400" }}>Total Comm(%)</th>
              <td>
                {/* <p>{Number(tcEq).toFixed(2)}</p> */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  placeholder="0"
                  value={tcEq}
                  name="tcEq"
                  className="mr-2"
                  onChange={(e) => onChangeSelectedVessel(e, true)}
                />
              </td>

            </tr>
            <tr>
              <th>Misc Revenue</th>
              <td>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  placeholder="0"
                  onChange={(e) => onChangeSelectedVessel(e, true)}
                  value={miscRevenue || ""}
                  name="miscRevenue"
                />
              </td>
            </tr>
            <tr>
              <th style={{ fontWeight: "400" }}>Vsl Extra Expense</th>
              <td>

                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  placeholder="0"
                  value={revenue}
                  name="revenue"
                  onChange={(e) => onChangeSelectedVessel(e, true)}
                />
              </td>

            </tr>
            <tr>
              <th style={{ fontWeight: "500" }}>Tag 3</th>
              <td>
                <Autocomplete
                  options={
                    (tagsLoading === "tag3") || TagsOptions.length === 0 ? [] : TagsOptions
                  }
                  value={Tag3}
                  disableClearable
                  className="vesselvessel-tags"
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => onSelectTags(value, "Tag3")}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      variant="outlined"
                      placeholder="Search Name(individual)"
                      onClick={() => { setTagsOptions([]) }}
                      value={Tag3}
                      onChange={(e) => delayTagsSearch(e.target.value, "individual", "tag3")}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {(tagsLoading === "tag3") ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </td>

            </tr>
            <tr>
              <th style={{ fontWeight: "500" }}>Tag 4</th>
              <td>
                <Autocomplete
                  options={
                    (tagsLoading === "tag4") || TagsOptions.length === 0 ? [] : TagsOptions
                  }
                  value={Tag4}
                  disableClearable
                  className="vesselvessel-tags"
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => onSelectTags(value, "Tag4")}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      variant="outlined"
                      placeholder="Search Name(organisation)"
                      onClick={() => { setTagsOptions([]) }}
                      value={Tag4}
                      onChange={(e) => delayTagsSearch(e.target.value, "organisation", "tag4")}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {(tagsLoading === "tag4") ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </td>

            </tr>
          </table>

          {/* <h4 className="result-heading m-t-5">Revenue</h4>
          <table className="fleet-result-table">
            <tr>
              <th>Misc Revenue</th>
              <td>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  placeholder="0"
                  onChange={onChangeQueryValues}
                  value={miscRevenue || ""}
                  name="miscRevenue"
                />
              </td>
            </tr>
          </table>
          <h4 className="result-heading m-t-5">Expenses</h4>
          <table className="fleet-result-table">
            {expenseResultField.map((item, key) => {
              return (
                <tr key={key}>
                  <th>{item.label}</th>
                  <td>
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      onChange={onChangeQueryValues}
                      value={clientQuery[item.value] || ""}
                      name={item.value}
                    />
                  </td>
                </tr>
              );
            })}
          </table> */}
        </div>
      </Grid>

      {/* {isFromSingleVesselCalcy ? <Grid item xs={12}>
        <div style={{ marginBottom: 0 }} className="sync-bunker-switch">
          <label>Override</label>
          <Switch
            checked={isOverride}
            onChange={e => setIsOverride(e.target.checked)}
            name="checkedA"
            color="primary"
          />
        </div>
      </Grid> : ""} */}
      {isFromSingleVesselCalcy ? <Grid item xs={12}>
        <Grid container className="lower-prices-container">
          <div className="show-bunker-lower-prices-from-single-vessel">
            <table>
              <thead>
                <tr>
                  <th>Bunker Qty </th>
                  <th>HS(0.5)</th>
                  <th>LS(0.1)</th>
                  <th>MGO</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>On Delivery</th>
                  <td><input type="number" disabled onKeyDown={handleKeyDown} value={(calculatorData?.portOperation?.filter((itm) => itm?.operation === "Delivery-out")?.[0]?.departureHsfo)?.toFixed(1)} name="bnkDelhs" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                  <td><input type="number" disabled onKeyDown={handleKeyDown} value={(calculatorData?.portOperation?.filter((itm) => itm?.operation === "Delivery-out")?.[0]?.departureLsfo)?.toFixed(1)} name="bnkDells" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                  <td><input type="number" disabled onKeyDown={handleKeyDown} value={(calculatorData?.portOperation?.filter((itm) => itm?.operation === "Delivery-out")?.[0]?.departureMgo)?.toFixed(1)} name="bnkDelmgo" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                </tr>
                <tr>
                  <th>Exp on Re-Delivery</th>
                  <td><input type="number" onKeyDown={handleKeyDown} value={bnkQtyRedeliveryhs} name="bnkQtyRedeliveryhs" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                  <td><input type="number" onKeyDown={handleKeyDown} value={bnkQtyRedeliveryls} name="bnkQtyRedeliveryls" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                  <td><input type="number" onKeyDown={handleKeyDown} value={bnkQtyRedeliverymgo} name="bnkQtyRedeliverymgo" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                </tr>
                <tr>
                  <th>Tolerance Acceptable</th>
                  <td><input type="number" onKeyDown={handleKeyDown} value={bnkRedeliveryQtyTolerancehs} name="bnkRedeliveryQtyTolerancehs" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                  <td><input type="number" onKeyDown={handleKeyDown} value={bnkRedeliveryQtyTolerancels} name="bnkRedeliveryQtyTolerancels" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                  <td><input type="number" onKeyDown={handleKeyDown} value={bnkRedeliveryQtyTolerancemgo} name="bnkRedeliveryQtyTolerancemgo" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="show-bunker-lower-prices-from-single-vessel">
            <table>
              <thead>
                <tr>
                  <th>Bunker Prices</th>
                  <th>HS(0.5)</th>
                  <th>LS(0.1)</th>
                  <th>MGO</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th> On Delivery</th>
                  <td>{!isOverride ? <p>{bnkPriceDeliveryhs}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceDeliveryhs} disabled={!isOverride} name="bnkPriceDeliveryhs" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                  <td>{!isOverride ? <p>{bnkPriceDeliveryls}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceDeliveryls} disabled={!isOverride} name="bnkPriceDeliveryls" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                  <td>{!isOverride ? <p>{bnkPriceDeliverymgo}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceDeliverymgo} disabled={!isOverride} name="bnkPriceDeliverymgo" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                </tr>
                <tr>
                  <th> On Re-Delivery</th>
                  <td>{!isOverride ? <p>{bnkPriceRedeliveryhs}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceRedeliveryhs} name="bnkPriceRedeliveryhs" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                  <td>{!isOverride ? <p>{bnkPriceRedeliveryls}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceRedeliveryls} name="bnkPriceRedeliveryls" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                  <td>{!isOverride ? <p>{bnkPriceRedeliverymgo}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceRedeliverymgo} name="bnkPriceRedeliverymgo" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                </tr>
                <tr>
                  <th> For Settlement</th>
                  <td>{!isOverride ? <p>{bnkPriceSettlemenths}</p> : <input type="number" onKeyDown={handleKeyDown} value={bnkPriceSettlemenths} name="bnkPriceSettlemenths" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                  <td>{!isOverride ? <p>{bnkPriceSettlementls}</p> : <input type="number" onKeyDown={handleKeyDown} value={bnkPriceSettlementls} name="bnkPriceSettlementls" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                  <td>{!isOverride ? <p>{bnkPriceSettlementmgo}</p> : <input type="number" onKeyDown={handleKeyDown} value={bnkPriceSettlementmgo} name="bnkPriceSettlementmgo" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </Grid>
      </Grid> : ""}
    </Grid>
  );
}

export default VesselVesselReDeliveryIn;
