import { apiGet, apiPost } from "../../../utils"

export function getDataByCumulativeSearchAPI(data) {
    return apiGet(`/api/vesselManager/getEmailsFleetList`, data)
}

export function getEmailsByVesselAPI(data) {
    return apiGet(`/api/vesselManager/getEmailsByVessel`, data)
}

export function changeMaildetailsAPI(data) {
    return apiPost(`/api/vesselManager/changeMaildetails`, data)
}

export function onChangeAutoVesselAPI(data) {
    return apiPost(`/api/vesselManager/onChangeAutoVessel`, data)
}

