import React, { useCallback, useEffect, useState } from "react";
import * as actions from "../actions";
import { useParams } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import L from "leaflet";
// import axios from "axios"
import {
  addPortDistanceForDistanceMatrix,
  addPortDistanceToOperations,
  convertPortOperationToDistanceMatrixCalc,
  convertPortOperationToDistanceString,
  getGeoJsonLineAndCoordinates,
} from "../dataMapping";
import { IsJsonString, toastError } from "../../../../utils";
import fleetIcon from "../../../../assets/flaticons/cargo-ship.png";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import { Checkbox, CircularProgress, TextField } from "@material-ui/core";

export default function DistanceMatrix(props) {
  const { setLoader = () => { }, setIsFullScreen } = props;
  const [savePortListByIndex, setSavePortListByIndex] = useState({});
  const [portLoading, setPortLoading] = useState({});
  const [leafletmap, setLeafLetMap] = useState(null); // Map state
  const [getDistance, setDistance] = useState([]);
  const [getAvoidPanama, setAvoidPanama] = useState(false);
  const [getAvoidSuez, setAvoidSuez] = useState(false);
  const [speed, setSpeed] = useState("");



  const [addPortsArray, setAddPortsArray] = useState([{ port: "", selectedPort: "", lat: "", lng: "" }]);
  const markersvg = (num) => {
    return `
        <svg
          width='50'
          height='50'
          viewBox='0 0 60 81'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M30.0336 80.2488C29.0354 80.2488 28.124 79.8148 27.473 79.0336C21.0496 71.0043 0 43.7918 0 29.9902C0 13.4544 13.4544 0 29.9902 0C46.5261 0 59.9804 13.4544 59.9804 29.9902C59.9804 43.8352 38.9743 71.0477 32.5075 79.0336C31.9433 79.8148 31.0318 80.2488 30.0336 80.2488ZM30.0336 6.72718C17.2303 6.72718 6.81398 17.1435 6.81398 29.9468C6.81398 42.7502 17.2303 53.1664 30.0336 53.1664C42.837 53.1664 53.2533 42.7502 53.2533 29.9468C53.2533 17.1435 42.837 6.72718 30.0336 6.72718Z'
            fill='url(#paint0_linear_1097_10904)'
          />
          <path
            d='M30.0331 49.7811C19.096 49.7811 10.2422 40.8839 10.2422 29.9902C10.2422 19.0965 19.1394 10.1992 30.0331 10.1992C40.9702 10.1992 49.8241 19.0965 49.8241 29.9902C49.8241 40.8839 40.9268 49.7811 30.0331 49.7811Z'
            fill='url(#paint1_linear_1097_10904)'
          />
          <path
            d='M30.0319 0.477051C13.7565 0.477051 0.5625 13.671 0.5625 29.9465C0.5625 43.531 21.1347 70.2662 27.9052 78.686C28.9903 80.0314 31.0735 80.0314 32.1586 78.686C38.9291 70.2662 59.5013 43.5744 59.5013 29.9465C59.5013 13.671 46.3073 0.477051 30.0319 0.477051ZM30.0319 53.7303C16.9247 53.7303 6.29146 43.097 6.29146 29.9899C6.29146 16.8827 16.9247 6.24941 30.0319 6.24941C43.1391 6.24941 53.7724 16.8827 53.7724 29.9899C53.7724 43.097 43.1391 53.7303 30.0319 53.7303Z'
            fill="#3f51b5"
          />
          <path
            d='M30.0318 49.2165C40.6744 49.2165 49.302 40.589 49.302 29.9464C49.302 19.3038 40.6744 10.6763 30.0318 10.6763C19.3892 10.6763 10.7617 19.3038 10.7617 29.9464C10.7617 40.589 19.3892 49.2165 30.0318 49.2165Z'
            fill='white'
          />
    
          <text
            x=${num >= 9 ? '22' : '25'}
            y='35'
            fill='#000'
            fontWeight='600'
            textAlign='center'
            alignmentBaseline='middle'
            style="font-size: 17px;"
          >
            ${num}
          </text>
    
          <defs>
            <linearGradient
              id='paint0_linear_1097_10904'
              x1='14.139'
              y1='-7.81286'
              x2='51.6603'
              y2='79.4963'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='white' />
              <stop offset='1' stopColor='#517536' />
            </linearGradient>
            <linearGradient
              id='paint1_linear_1097_10904'
              x1='13.8377'
              y1='-7.68381'
              x2='51.359'
              y2='79.6254'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='white' />
              <stop offset='1' stopColor='#517536' />
            </linearGradient>
          </defs>
        </svg>`

  }

  useEffect(() => {
    setMapMarkers()
  }, [])

  const setMapMarkers = async (newPortOperations = [], fleets = []) => {
    try {
      const centerIndex = Math.floor(newPortOperations.length / 2);
      const centerLat =
        (newPortOperations[centerIndex] &&
          newPortOperations[centerIndex].lat) ||
        51.505;
      const centerLong =
        (newPortOperations[centerIndex] &&
          newPortOperations[centerIndex].lng) ||
        -0.09;
      const center = [centerLat, centerLong];
      var defaultZoom = 3;
      var defaultMapCenter = new L.LatLng(20, 0);
      var editableLayers = new L.FeatureGroup();

      var seaDefault = L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
      );
      var seaMark = new L.TileLayer(
        "http://t1.openseamap.org/seamark/{z}/{x}/{y}.png"
      );
      var seaProfil = new L.TileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}"
      );
      var osm = new L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png");
      var Esri_WorldImagery = L.tileLayer(
        "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      );
      var Esri_WorldGrayCanvas = L.tileLayer(
        "http://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
      );
      var OpenStreetMap_BlackAndWhite = L.tileLayer(
        "http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
      );

      const map = new L.Map("map", {
        layers: [seaDefault, seaMark],
        center: defaultMapCenter,
        zoom: defaultZoom,
        editable: true,
        minZoom: 2,
        cursor: true,
      });
      setLeafLetMap(map)
      map.on("baselayerchange", function (event) {
        seaMark.bringToFront();
      });
      var baseMaps = {
        Default: seaDefault,
        "Sea profile": seaProfil,
        Imagery: Esri_WorldImagery,
        "Open Street Map": osm,
        Gray: Esri_WorldGrayCanvas,
        "Black & white": OpenStreetMap_BlackAndWhite,
      };
      var overlayMaps = {

      };
      L.control.layers(baseMaps, overlayMaps).addTo(map);
      map.zoomControl.setPosition("topright");


      let combinedGeoJson = [];



      for (let i = 0; i < newPortOperations.length; i++) {
        const svgIcon = L.divIcon({
          html: markersvg(i + 1),
          className: "",
          iconSize: [38, 38],
          iconAnchor: [26, 48],
          popupAnchor: [0, -38]
        });
        let lat1 = newPortOperations[i].lat || 0;
        let long1 = newPortOperations[i].lng || 0;
        L.marker([lat1, long1], { icon: svgIcon }).addTo(map).bindPopup(`${i + 1} ${newPortOperations[i].port}`);

        // .openPopup();
        if (newPortOperations[i].geoJSON) {
          const geoJSON = getGeoJsonLineAndCoordinates(
            newPortOperations[i].geoJSON
          );
          // console.log(geoJSON, "geoJSON");
          combinedGeoJson.push(geoJSON);
        }
      }
      var myStyle = {
        "color": "#3f51b5",
        "weight": 3,
        "opacity": 1
      };

      L.geoJSON(combinedGeoJson, {
        style: myStyle
      }).addTo(map);

      // var greenIcon = L.icon({
      //   iconUrl: fleetIcon,
      //   // shadowUrl: 'leaf-shadow.png',
      //   iconSize: [25, 25],
      //   iconAnchor: [10, 18],
      //   popupAnchor: [0, -5]
      //   // iconSize: [38, 95], // size of the icon
      //   // shadowSize:   [50, 64], // size of the shadow
      //   // iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
      //   // shadowAnchor: [4, 62],  // the same for the shadow
      //   // popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      // });

      // for (let i = 0; i < fleets.length; i++) {
      //   const { lat = 0, lng = 0, } = fleets[i];


      //   L.marker([lat, lng], { icon: greenIcon })
      //     .addTo(map)
      //     .bindPopup(i + 1);
      // }
    } catch (ex) {
      console.log(ex?.message || "Something went wrong");
    }
  };

  function displayGrid() {
    return L.latlngGraticule({
      showLabel: true,
      color: "black",
      weight: 0.3,
      zoomInterval: [
        { start: 2, end: 2, interval: 40 },
        { start: 3, end: 3, interval: 20 },
        { start: 4, end: 4, interval: 10 },
        { start: 5, end: 7, interval: 5 },
        { start: 8, end: 20, interval: 1 },
      ],
    });
  }

  function displayZones(list, name, color) {
    var newArea = [];
    var polygons = [];
    list.forEach(function (area) {
      var inversedPoly = [];
      area.polygon.forEach(function (p) {
        inversedPoly.push([p[1], p[0]]);
      });
      newArea.push({ name: area.name, polygon: inversedPoly });
      var poly = L.polygon(inversedPoly, {
        color: color,
        opacity: 0.1,
        fillColor: color,
        fillOpacity: 0.3,
      }).bindPopup(name + " : " + area.name);
      polygons.push(poly);
    });
    return L.layerGroup(polygons);
  }

  // function getPortsLayer() {
  //     var ports = [];
  //     portsList.forEach(function (p) {
  //         var cityMarker = L.circleMarker(p.latlng, {
  //             radius: 2,
  //             color: '#666',
  //             fillOpacity: 1
  //         }).on('click', function () {
  //             cityMarker.bindPopup(makePopupText(null, p.id, p.title_short, p.country_name, p.country_code, false)).openPopup();
  //         });
  //         ports.push(cityMarker)
  //     });
  //     return L.layerGroup(ports);
  // }




  const handleAddLocation = () => {
    // setLocations([...locations, { location: "" }]);
    setAddPortsArray([...addPortsArray, { port: "", selectedPort: "", lat: "", lng: "" }])
  };

  const onChangePanama = () => {
    setAvoidPanama(!getAvoidPanama)
  }

  const onChangeSuez = () => {
    setAvoidSuez(!getAvoidSuez)
  }
  const handleRemoveLocation = (index) => {
    const newLocations = [...addPortsArray];
    newLocations.splice(index, 1);
    // setLocations(newLocations);
    setAddPortsArray(newLocations)
    setDistance([])
  };

  const handleRouteCalculation = async () => {
    setLoader(true)
    const requestData = addPortsArray?.filter((itm) => !!itm?.port)
    setAddPortsArray(requestData)
    const { routeList } = await convertPortOperationToDistanceMatrixCalc(requestData)
    const disOutput = await actions.getPortDistanceMatrix(routeList, { panama: getAvoidPanama, suez: getAvoidSuez })
    const extractedData = [];
    for (const key in disOutput?.data) {
      extractedData.push({
        distEca: disOutput?.data?.[key].distEca,
        distNormal: disOutput?.data?.[key].distNormal,
        name: key
      });

    }
    setDistance(extractedData)
    if (leafletmap)
      leafletmap?.remove()
    setLoader(false)
    const newPortOperations = addPortDistanceForDistanceMatrix(
      disOutput?.data,
      requestData,
      true
    );
    setMapMarkers(newPortOperations, newPortOperations)
  };
  const delayPortSearch = useCallback(
    _.debounce((index, search) => onSearchPortList(index, search), 500),
    []
  );
  const onSearchPortList = (index, value) => {
    if (value?.length > 2) {
      setPortLoading({ ...portLoading, [index]: true });
      actions.portsByChar(value)
        .then((res) => {
          setPortLoading({ ...portLoading, [index]: false });
          setSavePortListByIndex({
            ...savePortListByIndex,
            [index]:
              (Array.isArray(res.data.list) &&
                res.data.list.map((item) => ({
                  ...item,
                  portLabel: `${item.name} ${item.country ? "(" + item.country + ")" : ""
                    }`,
                }))) ||
              [],
          });
        })
        .catch((err) => {
          setPortLoading({ ...portLoading, [index]: false });
        });
    }
  };
  const onChangeNewPortValue = (index, name, value) => {
    let newAddPortsArray = [...addPortsArray];
    newAddPortsArray[index].port = value.name;
    newAddPortsArray[index].selectedPort = value;
    newAddPortsArray[index].lat = value.lat || "";
    newAddPortsArray[index].lng = value.lng || "";
    setAddPortsArray(newAddPortsArray);
    setDistance([])
    setSavePortListByIndex({})
    // handleChange(index, value)
  };
  const totalDistEca = getDistance?.reduce((sum, item) => sum + item?.distEca, 0);
  const totalDistNormal = getDistance?.reduce((sum, item) => sum + item?.distNormal, 0);

  const calculateTravelTime = (distance) => {
    // Calculate time in days
    const totalDays = distance / (24 * speed);

    // Convert days to hours and minutes
    const days = Math.floor(totalDays);
    const hours = Math.floor((totalDays - days) * 24);
    const minutes = Math.round(((totalDays - days) * 24 - hours) * 60);
    return `${days} days, ${hours}H, ${minutes}M`
  }
  return <>
    <div className="dynamic-location-inputs">
      <h2>Enter Port Locations</h2>

      {addPortsArray.map((loc, index) => (
        <div className={`d-flex ${(getDistance?.[index]?.distNormal || getDistance?.[index]?.distECA) ? "" : "pb-3"}`} key={index}>
          {/* <input
              type="text"
              value={loc.location}
              placeholder={`Location ${index + 1}`}
              onChange={(e) => handleChange(index, e)}
            /> */}
          <div>
            <div className="circle"></div>
            <Autocomplete
              options={
                (savePortListByIndex[index] &&
                  savePortListByIndex[index]) ||
                []
              }
              value={addPortsArray[index]?.selectedPort}
              getOptionLabel={(option) => option.portLabel}
              style={{ width: 160 }}
              disableClearable
              onChange={(e, value) =>
                onChangeNewPortValue(index, "port", value)
              }
              className="autocomplete-mui-distance-matrix"
              getOptionSelected={(option, value) =>
                option.portLabel === value.portLabel
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={`Location ${index + 1}`}
                  value={addPortsArray[index]?.selectedPort && addPortsArray[index]?.selectedPort?.portLabel}
                  onChange={(e) =>
                    delayPortSearch(index, e.target.value)
                  }
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {(portLoading[index] &&
                          portLoading[index]) ||
                          false ? (
                          <CircularProgress
                            color="inherit"
                            size={15}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {(getDistance?.[index]?.distNormal || getDistance?.[index]?.distECA) ? <><div className="small-circle-1"></div>
              <div className="small-circle-2"></div></> : ""}

            {(getDistance?.[index]?.distNormal || getDistance?.[index]?.distECA) ? <span style={{ fontSize: "12px", color: "#2100ff" }}>Dist-Normal: {getDistance?.[index]?.distNormal} &nbsp; &nbsp; &nbsp; Dist-ECA: {getDistance?.[index]?.distEca}</span> : ""}
          </div>
          <>
            {addPortsArray?.length > 1 && (

              <DeleteIcon
                color="secondary"
                onClick={() => handleRemoveLocation(index)}
                className="pl-1"
              />
            )}
          </>
        </div>
      ))}

      <button className="add-btn" onClick={handleAddLocation}>
        Add Route
      </button>
      <div className="d-flex" style={{ fontSize: "13px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox className="p-1" color="primary" name="suez" checked={getAvoidSuez}
            onChange={() => onChangeSuez()}
          />
          <span>Avoid Suez</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox className="p-1" color="primary" name="panama" checked={getAvoidPanama}
            onChange={() => onChangePanama()}
          />
          <span>Avoid Panama</span>
        </div>
      </div>
      <div style={{ fontSize: "13px", display: "flex", flexDirection: "column" }}>
        <span>
          {(getDistance?.length) ? <span style={{ fontSize: "12px", color: "#2100ff" }}>Total Dist Normal: {totalDistNormal} &nbsp; Total Dist ECA: {totalDistEca}</span> : ""}
        </span>
      </div>
      <div style={{ fontSize: "13px", display: "flex", flexDirection: "row" }}>
        <span>Enter Speed : </span>
        <input
          value={speed} type="number" onChange={(e) => setSpeed(e.target.value)}
          placeholder="Speed"
          style={{
            borderBottom: "1px solid rgb(0, 0, 0)",
            height: "20px",
            padding: "5px",
            width: "40%",
            marginRight: "5px",
            marginLeft: "5px",
          }}
        />
        <span>Knots </span>

      </div>
      <div style={{ fontSize: "13px", display: "flex", flexDirection: "column", paddingBottom: "4px" }}>
        <span>
          {(totalDistNormal && speed) ? <span style={{ fontSize: "12px" }}>Total Time (dist Normal): <span style={{ color: "#2100ff" }}>{calculateTravelTime(totalDistNormal)}</span></span> : ""}

        </span>
        <span>
          {(totalDistEca && speed) ? <span style={{ fontSize: "12px" }}>Total Time (dist ECA): <span style={{ color: "#2100ff" }}>{calculateTravelTime(totalDistEca)}</span></span> : ""}

        </span>
      </div>
      <button className="calculate-btn" onClick={handleRouteCalculation}>
        Show Route
      </button>
    </div>
    <div className="showmap-container" id="map" /></>;
}
