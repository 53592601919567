import React, { memo, useState, useMemo, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Link,
} from "@material-ui/core";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import ShareIcon from "@material-ui/icons/OpenInNew";
import SaveIcon from "@material-ui/icons/Save";
import CustomShowModal from "../Common/CustomShowModal";
import AddFleetModal from "./AddCalculatorFleet/AddFleetTabView";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { getComparator, stableSort } from "../../utils";
import SplitHireModal from "./SplitHireModal";
import pinIconBlack from "../../assets/icons/pin-icon-black.png";
import pinIconRed from "../../assets/icons/pin-icon-red.png";
import ConfirmModal from "../Common/CustomConfirmModal";
import { vesselTableHeading } from "../../constants";
import CustomAddModal from "../Common/CustomAddModal";
import SaveVesselLabel from "./SaveVesselLabel";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { saveCalculatedCostResult } from "../../containers/User/Calculator/actions";
import { handleKeyDown } from "../../common/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CalculatorMain from "../../containers/User/Calculator/CalculatorMain";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PdfDataComponent from "./VesselPdf";
function VesselSelection(props) {
  const {
    selectedQuery = {},
    calculatorData = {},
    setCalculatorData = () => { },
    selectedVessels = [],
    onChangeFleetRowSelect = () => { },
    onDeleteFleet = () => { },
    onClickAddFleet = () => { },
    addFleetModal = false,
    addFleetsArray = [],
    setAddFleetsArray = () => { },
    onChangeNewFleetsValue = () => { },
    setLoader = () => { },
    setAddFleetModal = () => { },
    sendCalculationRequest = () => { },
    setSelectedVessels = () => { },
    splitHireModal = false,
    setSplitHireModal = () => { },
    selectedSplitHire = {},
    setSelectedSplitHire = () => { },
    openSplitHireModal = () => { },
    onFetchPortOperationDistance = () => { },
    getInitalQueryAndFleetData = () => { },
    initialFleetList = [],

    onChangeSelectedVessel = () => { },
    fleetCheckbox = () => { },
    setFleetCheckbox = () => { },

    setMarginModal = () => { },
    syncBunker = () => { },
    setSyncBunker = () => { },
    setSupplyModal = () => { },
    onChangeSyncBunker = () => { },
    isOverride = () => { },

    calculationErrors = {},

    marginModal,
    onChangePortOperations,
    onAddOperations,
    onDeleteOperations,
    supplyModal,
    fetchPortDistance,
    openAddOperation,
    setOpenAddOperation,
    setOpenAddOperationindex,
    onChangePortCanalInQueryState,
    setIsOverride,
    hideOrder,
    setHideOrder,
    hideCalculator,
    getCurrentResult,
    isFromCargoAnalysis = false,
    setPdfData = () => { },
    onChangeChartValue = () => { },
    setExpandedRows = () => { },
    expandedRows
  } = props;
  const {
    fleetList = [],
    portOperation = [],
    clientQuery = {},
  } = calculatorData || {};
  const { deliveryTerms = "", tcAdcom = "", _id: queryid } = clientQuery || {};
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("tceq");
  const [showConfrim, setShowConfrim] = useState(false);
  const [data, setData] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  const [selectedVessel, setSelectedVessel] = useState(null);
  const [saveLabel, setSaveLabel] = useState("");
  const history = useHistory();
  // const [expandedRows, setExpandedRows] = useState({}); // Track expanded rows
  const onChangeHandler = (id, name, value) => {
    const findIndex = fleetList.findIndex((item) => item._id === id);
    fleetList[findIndex][name] = value;

    setCalculatorData({
      ...calculatorData,
      fleetList,
    });
    if (id === selectedVessels._id) {
      setSelectedVessels({ ...selectedVessels, [name]: value });
    }
  };

  const onChangeSplitHire = (index, e, isDelete = false) => {
    let splitHire =
      (selectedSplitHire &&
        selectedSplitHire.splitHire &&
        JSON.parse(selectedSplitHire.splitHire)) ||
      [];
    if (isDelete) splitHire.splice(index, 1);
    else {
      const { name = "", value = "" } = e.target;
      splitHire[index][name] = Number(value);
    }
    const udpatedSelectedSplitHire = {
      ...selectedSplitHire,
      splitHire: JSON.stringify(splitHire),
    };
    setSelectedSplitHire(udpatedSelectedSplitHire);

    setCalculatorData({
      ...calculatorData,
      fleetList: calculatorData.fleetList.map((item) => {
        if (item._id === udpatedSelectedSplitHire._id) {
          return udpatedSelectedSplitHire;
        }
        return item;
      }),
    });
    if (udpatedSelectedSplitHire._id === selectedVessels._id)
      setSelectedVessels(udpatedSelectedSplitHire);
  };

  const addNewSplitHire = () => {
    let splitHire =
      (selectedSplitHire &&
        selectedSplitHire.splitHire &&
        JSON.parse(selectedSplitHire.splitHire)) ||
      [];
    if (!!splitHire.find((item) => item.NoDays === 0)) return;
    splitHire.push({ NoDays: 0, applied: 0, disable: false, hireRate: 0 });
    setSelectedSplitHire({
      ...selectedSplitHire,
      splitHire: JSON.stringify(splitHire),
    });
  };

  const onChangeIsReferenceVessel = (fleet) => {
    setCalculatorData({
      ...calculatorData,
      fleetList: calculatorData.fleetList.map((item) => {
        return {
          ...item,
          isReferenceVessel: item._id === fleet._id,
        };
      }),
    });
  };

  const onClickDeleleButton = () => {
    onDeleteFleet(deleteId);
    setShowConfrim(false);
    setDeleteId("");
  };

  // const getArrivalTimeOfFirstLoading = useMemo(() => {
  //     let time = ''
  //     for (let i = 0; i < portOperation.length; i++) {
  //         if (portOperation[i].operation === "Loading") {
  //             time = portOperation[i].arrivalTime
  //             break;
  //         }
  //     }
  //     return time
  // }, [portOperation])

  const onClickRowChange = (vessel) => {
    // onFetchPortOperationDistance()
    onChangeFleetRowSelect(vessel, null, true);

  };

  const onChangeClientQuery = (e) => {
    const { name = "", value = "" } = e.target || {};
    setCalculatorData({
      ...calculatorData,
      clientQuery: {
        ...clientQuery,
        [name]: value,
      },
    });
  };

  const onClickSaveFleet = (id, saveLabel = "", vessel) => {
    onChangeFleetRowSelect(vessel, null, true);
    setSelectedVessel(id);
    setSaveLabel(saveLabel);
    setSaveModal(true);
  };

  const onCloseSaveFleet = () => {
    setSaveModal(false);
    setSelectedVessel(null);
    setSaveLabel("");
  };

  const onClickSaveFleetLabel = async () => {
    const findIndex = fleetList.findIndex(
      (item) => item._id === selectedVessels._id
    );

    if (!!saveLabel) {
      const calculatedFleetDetails = fleetList.find(
        (item) => item._id === selectedVessels._id
      );
      const initialFleetDetails = initialFleetList.find(
        (item) => item._id === selectedVessels._id
      );
      const updatedFleetList = [...fleetList];
      updatedFleetList[findIndex] = {
        ...updatedFleetList[findIndex],
        selectedForSave: true,
        saveLabel,
      };
      // setCalculatorData({
      //     ...calculatorData,
      //     fleetList: updatedFleetList
      // })
      // const updatedCalculatorData = {
      //   ...calculatorData,
      //   fleetList: updatedFleetList,
      //   autoSave: true,
      // };
      const clientQueryFinal = {
        ...calculatorData?.clientQuery,
        cargoList: calculatorData?.portOperation,
      };
      const calculatedData = {
        name: saveLabel,
        vessel: selectedVessels,
        clientQuery: clientQueryFinal,
        fleet: initialFleetDetails,
        calculatedFleetResult: calculatedFleetDetails,
      };

      if (selectedVessel === selectedVessels._id) {
        setSelectedVessels({
          ...selectedVessels,
          saveLabel,
        });
      }
      const resultLink = await saveCalculatedCostResult(calculatedData);
      onCloseSaveFleet();
      history.push(`/saved/${resultLink?.data?._id}`);
    }
  };

  const onChangeOrderBy = (order, orderBy) => {
    setOrder(order);
    setOrderBy(orderBy);
  };

  const filteredFleetList = useMemo(() => {
    return stableSort(fleetList, getComparator(order, orderBy));
  }, [fleetList, order, orderBy]);

  const handleAccordionToggle = (vesselId, vessel) => {
    setExpandedRows((prevState) => {
      const newState = { [vesselId]: !prevState[vesselId] };
      onChangeChartValue(calculatorData, newState[vesselId], vessel);
      return newState;
    });
  };

  const fetchDataForPdf = async () => {
    const id = Object.keys(expandedRows)[0];
    const { portOperation, clientQuery } = calculatorData;
    const index = filteredFleetList.findIndex((val) => val._id == id);

    const fleete = index !== -1 ? filteredFleetList[index] : undefined;

    const port = portOperation;
    if (!fleete || !port) return {};

    const vessel = JSON.parse(fleete.vessel || "[]") || {};

    const mainVessel = filteredFleetList.flatMap((item) => {
      try {
        return item.vessel ? JSON.parse(item.vessel) : [];
      } catch (error) {
        console.error("Error parsing vessel:", item.vessel, error);
        return [];
      }
    });

    // Construct the PDF data
    const data = {
      Cargo_Details: {
        account: clientQuery.account,
        cargo: clientQuery.cargo,
        laycanStartDate: clientQuery.laycanStartDate,
        laycanEndDate: clientQuery.laycanEndDate,
        adcom: port?.adcom,
        brokerage: port?.brokerage,
        other: port?.other,
        freightMode: clientQuery.freightMode,
        Cargo_Min: clientQuery.cargoList[0].min,
        Cargo_Max: clientQuery.cargoList[0].max,
        sf_of_cargo: clientQuery.cargoList[0].stowFactor,
        Tag1: clientQuery?.Tag1?.name,
        Tag2: clientQuery?.Tag2?.name,
        Tag3: clientQuery?.Tag3?.name,
        Tag4: clientQuery?.Tag4?.name,
      },
      vessel: {
        date_opning: fleete.dateOpen,
        ETA: fleete.portToPortOperationResultMap[1]?.arrivalTime,
        vesselPremium: fleete.vesselPremium,
        positionPremium: fleete.positionPremium,
        remark: fleete.remark,
        port: fleete?.port,
        vesselName: vessel.vesselName,
        DWT: vessel.vesselDwt,
        Draft: vessel.vesselDraftS,
        TPC: vessel.vesselTPC,
        grain: vessel.vesselGrainCap,
        built: vessel.vesselBuilt,
        LOA: vessel.vesselLOA,
        Beam: vessel.vesselBeam,
        GRT: vessel.vesselGRT,
        NRT: vessel.vesselNRT,
        light_ship: vessel.vesselLightShip,
        bale_capacity: vessel.vesselBaleCap,
        Gears: vessel.vesselGears,
        Grabs: vessel.vesselGrabs,
        Constants: vessel.Constants,
        fuel_type: vessel.fuelType,
        scrubber: vessel.vessel_scrubber,
        IMO: vessel.vesselIMO,
        BalComb1: vessel?.vesselBalComb1Selected,
        BalComb2: vessel?.vesselBalComb2Selected,
        BalComb3: vessel?.vesselBalComb3Selected,
        BalComb4: vessel?.vesselBalComb4Selected,
        LadComb1: vessel?.vesselLadComb1Selected,
        LadComb2: vessel?.vesselLadComb2Selected,
        LadComb3: vessel?.vesselLadComb3Selected,
        LadComb4: vessel?.vesselLadComb4Selected,
        Ballast_Sea_Consumptions: {
          col_1: {
            Speed: vessel?.vesselBalSpd1,
            HS: vessel?.vesselBalConsmHS1,
            LS: vessel?.vesselBalConsmLS1,
            MGO: vessel?.vesselBalConsmMGO1,
          },
          col_2: {
            Speed: vessel?.vesselBalSpd2,
            HS: vessel?.vesselBalConsmHS2,
            LS: vessel?.vesselBalConsmLS2,
            MGO: vessel?.vesselBalConsmMGO2,
          },
          col_3: {
            Speed: vessel?.vesselBalSpd3,
            HS: vessel?.vesselBalConsmHS3,
            LS: vessel?.vesselBalConsmLS3,
            MGO: vessel?.vesselBalConsmMGO3,
          },
          col_4: {
            Speed: vessel?.vesselBalSpd4,
            HS: vessel?.vesselBalConsmHS4,
            LS: vessel?.vesselBalConsmLS4,
            MGO: vessel?.vesselBalConsmMGO4,
          },
        },
        Laden_Sea_Consumptions: {
          col_1: {
            Speed: vessel?.vesselLdnSpd1,
            HS: vessel?.vesselLdnConsmHS1,
            LS: vessel?.vesselLdnConsmLS1,
            MGO: vessel?.vesselLdnConsmMGO1,
          },
          col_2: {
            Speed: vessel?.vesselLdnSpd2,
            HS: vessel?.vesselLdnConsmHS2,
            LS: vessel?.vesselLdnConsmLS2,
            MGO: vessel?.vesselLdnConsmMGO2,
          },
          col_3: {
            Speed: vessel?.vesselLdnSpd3,
            HS: vessel?.vesselLdnConsmHS3,
            LS: vessel?.vesselLdnConsmLS3,
            MGO: vessel?.vesselLdnConsmMGO3,
          },
          col_4: {
            Speed: vessel?.vesselLdnSpd4,
            HS: vessel?.vesselLdnConsmHS4,
            LS: vessel?.vesselLdnConsmLS4,
            MGO: vessel?.vesselLdnConsmMGO4,
          },
        },
        Port_Consumptions: {
          Idle: {
            HS: vessel?.vesselPIdleConsmHS,
            LS: vessel?.vesselPIdleConsmLS,
            MGO: vessel?.vesselPIdleConsmMGO,
          },
          Loading: {
            HS: vessel?.vesselPLdgConsmHS,
            LS: vessel?.vesselPLdgConsmLS,
            MGO: vessel?.vesselPLdgConsmMGO,
          },
          Disch: {
            HS: vessel?.vesselPDischConsmHS,
            LS: vessel?.vesselPDischConsmLS,
            MGO: vessel?.vesselPDischConsmMGO,
          },
          Extra: {
            HS: vessel?.vesselVExtraConsmHS,
            LS: vessel?.vesselVExtraConsmLS,
            MGO: vessel?.vesselVExtraConsmMGO,
          },
        },
      },
      Margin_and_Allowances: {
        DWF: port?.dwf,
        Perf_Factor: port?.cargoDetails?.speedFactor,
        speedReduce: port?.cargoDetails?.speedReduce,
        bnkrFactor: port?.cargoDetails?.bnkrFactor,
        extraExpense: fleete.extraExpense,
        constants: fleete.constants,
        fuelCalculationMethod: clientQuery.fuelCalculationMethod,
        Fuel_Price: {
          fuel_type: clientQuery.fuelType,
          Normal: clientQuery.hsFuelPriceType0,
          ECA: clientQuery.lsFuelPriceType0,
          MGO: clientQuery.mgoFuelPriceType0,
        },
      },

      Freight_and_Result: {
        Freight_Rate: clientQuery.freightIdea,
        tcEq: fleete.tcEq,
        gbb: fleete.gbb,
        hirePerDay: fleete.hirePerDay,
        hireGbb: fleete.hireGbb,
        profitPerDay: fleete.profitPerDay,
        profitPerDayGBB: fleete.profitPerDayGBB,
        Misc_Revenue: clientQuery.miscRevenue,
        CVE: clientQuery.cve,
        insurance: clientQuery.insurance,
        hireSurvey: clientQuery.hireSurvey,
        canalExpenses: clientQuery.canalExpenses,
        additionalPremium: clientQuery.additionalPremium,
        ilohc: clientQuery.ilohc,
        miscExp: clientQuery.miscExp,
        manoevringExp: clientQuery.manoevringExp,
      },
      result: {
        frtCost: fleete.frtCost,
        profitPmt: fleete.profitPmt,
        profitNet: fleete.profitNet,
        netFrtRevenue: fleete.netFrtRevenue,
        hireExpense: fleete.hireExpense,
        fuelExpense: fleete.fuelExpense,
        otherExpense: fleete.otherExpense,
        hsQuantity: fleete.hsQuantity,
        lsQuantity: fleete.lsQuantity,
        mgoQuantity: fleete.mgoQuantity,
        voyageDuration: fleete.voyageDuration,
        portStayTotal: fleete.portStayTotal,
        stowage: fleete.stowage,
        fuelSettlementProfit: fleete.fuelSettlementProfit,
      },
      add_operations: port.map((port) => ({
        operation: port?.operation,
        Port: port?.port,
        distNormal: port?.cargoDetails?.distNormal,
        distEca: port?.cargoDetails?.distEca,
        dwf: port?.dwf,
        speedNormal: port?.speedNormal,
        speedECA: port?.speedECA,
        cargoTx: port?.cargoTx,
        rate: port?.rate,
        terms: port?.terms,
        factor: port?.factor,
        turnTime: port?.turnTime,
        extraDays: port?.extraDays,
        portExp: port?.portExp,
        portStay: port?.portStay,
        loadline: port?.loadline,
        draftRes: port?.draftRes,
        waterDensity: port?.waterDensity,
        supply: port?.supply,
        Port_emission: port?.sulfur,
      })),
      otherTables: (() =>
        filteredFleetList
          .map((fleetItem, i) => {
            const vessel = mainVessel[i];
            // const portList = port[i];
            const formatNumber = (value, decimals = 2) =>
              value ? Number(value).toFixed(decimals) : 0;

            return {
              _id: fleetItem._id,
              frtCost: port?.frtCost,
              vessel_name: vessel?.vesselName || "",
              DWT: vessel?.vesselDwt || 0,
              VesselPremium: fleetItem?.vesselPremium || 0,
              positionPremium: fleetItem?.positionPremium || 0,
              remark: fleetItem?.remark || "",
              port: fleetItem?.port || "",
              ETA:
                fleetItem?.portToPortOperationResultMap?.[1]?.arrivalTime || "",
              tcEq: formatNumber(fleetItem?.tcEq),
              gbb: formatNumber(fleetItem?.gbb),
              hirePerDay: formatNumber(fleetItem?.hirePerDay),
              hireGbb: formatNumber(fleetItem?.hireGbb),
              profit: formatNumber(fleetItem?.profitNet),
              loadability: formatNumber(fleetItem?.loadability),
              dateOpen: fleetItem?.dateOpen || "",
            };
          })
      )(),
    };

    return data;
  };

  const onDownloadPdf = async () => {
    const generatedData = await fetchDataForPdf(); // Fetch your data or use the existing logic
    setPdfData(generatedData);
  };

  useEffect(() => {
    onDownloadPdf();
  }, [calculatorData, expandedRows, orderBy, order]);

  return (
    <div>
      <div className="port-operation-buttons">
        <div>
          {/* <button
            className="px-3 py-1 text-center bg-neutrals9 hover:bg-neutrals7 border border-neutrals6 text-neutrals4 rounded-md text-sm flex items-center gap-1 mb-2"
            onClick={onClickAddFleet}
          >
            + Add Vessel
          </button> */}
          <div className="flex">
            {!isFromCargoAnalysis && (
              <Link
                className="px-3 py-1 text-center add-vessel-button border border-neutrals6 rounded-md text-sm flex items-center gap-1 mb-2"
                href="/vesselManager"
              >
                + Add Vessel
              </Link>
            )}
            {/* <PDFDownloadLink
              document={<PdfDataComponent data={data} />}
              fileName={`${Date.now()}.pdf`} // Corrected this line
            >
              <div className="px-3 py-1 text-center bg-[#3f51b5] hover:bg-[#06195f] hover:underline border border-neutrals6 text-white rounded-md text-sm flex items-center gap-1 ms-3">
                Dowload PDF
              </div>
            </PDFDownloadLink> */}
          </div>
        </div>
        <div className="vessel-extra-actions">
          <div className="extra-actions-div">
            <span>TC Adcom %:</span>
            <input
              type="number"
              name="tcAdcom"
              value={tcAdcom}
              onKeyDown={handleKeyDown}
              onChange={onChangeClientQuery}
            />
          </div>
          {/* <div className="extra-actions-div">
            <span>Delivery Terms</span>
            <select
              name="deliveryTerms"
              value={deliveryTerms}
              onChange={onChangeClientQuery}
            >
              <option value="DLOSP">DLOSP</option>
              <option value="AFSPS">AFSPS</option>
            </select>
          </div> */}
        </div>
      </div>
      {/* <div style={{ width: '100%' }}> */}

      {/* </div> */}
      <TableContainer component={Paper} className="vessel-selection-table">
        <Table>
          <TableHead>
            <TableRow>
              {vesselTableHeading.map((item) => {
                if (isFromCargoAnalysis && item?.title === "Actions") {
                  return
                }
                if (item.showOrder) {
                  return (
                    <TableCell key={item._id}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span>{item.title}</span>
                        {order === "desc" && item.id === orderBy ? (
                          <ArrowDownwardIcon
                            className="table-sort-arrow active-arrow"
                            onClick={() => onChangeOrderBy("asc", item.id)}
                          />
                        ) : order === "asc" && item.id === orderBy ? (
                          <ArrowUpwardIcon
                            className="table-sort-arrow active-arrow"
                            onClick={() => onChangeOrderBy("desc", item.id)}
                          />
                        ) : order === "desc" && item.id !== orderBy ? (
                          <ArrowDownwardIcon
                            className="table-sort-arrow"
                            onClick={() => onChangeOrderBy("asc", item.id)}
                          />
                        ) : order === "asc" && item.id !== orderBy ? (
                          <ArrowUpwardIcon
                            className="table-sort-arrow"
                            onClick={() => onChangeOrderBy("desc", item.id)}
                          />
                        ) : null}
                      </div>
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={item._id}>
                    <span>{item.title}</span>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(filteredFleetList) && filteredFleetList.length ? (
              filteredFleetList.map((vessel) => {
                const {
                  _id,
                  vesselName = "",
                  dateOpen = "",
                  port = "",
                  gbb = 0,
                  hireGbb = 0,
                  hirePerDay = 0,
                  remark = "",
                  tcEq = 0,
                  portToPortOperationResultMap = {},
                  frtCost = 0,
                  profitNet = 0,
                  loadability = 0,
                  vesselPremium = 0,
                  positionPremium = 0,
                  vessel: fleetVesselData,
                  splitHire = "",
                  isReferenceVessel = false,
                  saveLabel = "",
                } = vessel || {};
                const { vesselDwt = 0 } = JSON.parse(fleetVesselData) || "";
                const isSelected = selectedVessels._id === _id || false;
                const arrivalTime =
                  (portToPortOperationResultMap &&
                    portToPortOperationResultMap[1] &&
                    portToPortOperationResultMap[1].arrivalTime) ||
                  "";
                const isExpanded = expandedRows[_id];
                return (
                  <>
                    <TableRow
                      key={_id}
                      onClick={() => {
                        onClickRowChange(vessel);
                        handleAccordionToggle(vessel._id, vessel);
                      }}
                      className={
                        isSelected
                          ? "selected-fleet-row"
                          : `non-selected-fleet-row ${isReferenceVessel ? "reference-fleet" : ""
                          }`
                      }
                    >
                      <TableCell
                        style={{ width: "1%", cursor: "pointer" }}
                      >
                        <ExpandMoreIcon
                          style={{
                            transform: isExpanded
                              ? "rotate(180deg)"
                              : "rotate(0deg)", // Rotate on open/close
                            transition: "transform 0.3s ease",
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ width: "12%", fontWeight:"500" }}>
                        {JSON.parse(vessel?.vessel)?.vesselName}
                      </TableCell>
                      <TableCell style={{ width: "4%" }}>{vesselDwt}</TableCell>
                      <TableCell style={{ width: "10%" }}>{port}</TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <MuiPickersUtilsProvider
                          utils={MomentUtils}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          <DateTimePicker
                            inputVariant="outlined"
                            size="small"
                            // style={{ width: 150 }}
                            value={dateOpen || null}
                            format="DD/MMM/yyyy HH:mm"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                            onChange={(date) =>
                              onChangeHandler(vessel._id, "dateOpen", date)
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </TableCell>
                      <TableCell
                        style={{ width: "5%", borderLeft: "1px solid grey" }}
                      >
                        {Number(tcEq).toFixed(0)}
                      </TableCell>
                      <TableCell>
                        <input
                          type="number"
                          className="vessel-list-editable-input"
                          value={gbb || ""}
                          placeholder="0"
                          onKeyDown={handleKeyDown}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            onChangeHandler(vessel._id, "gbb", e.target.value)
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{ minWidth: 95, borderLeft: "1px solid grey" }}
                      >
                        <div style={{ position: "relative" }}>
                          <input
                            type="number"
                            className="vessel-list-editable-input"
                            value={hirePerDay || ""}
                            // style={{ width: 90 }}
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) =>
                              onChangeHandler(
                                vessel._id,
                                "hirePerDay",
                                e.target.value
                              )
                            }
                          />
                          <span
                            className={`split-hire-dot ${splitHire ? "red" : "green"
                              }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              openSplitHireModal(vessel);
                            }}
                          >
                            &bull;
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <input
                          type="number"
                          className="vessel-list-editable-input"
                          value={hireGbb || ""}
                          onKeyDown={handleKeyDown}
                          placeholder="0"
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            onChangeHandler(
                              vessel._id,
                              "hireGbb",
                              e.target.value
                            )
                          }
                        />
                      </TableCell>
                      <TableCell style={{ borderLeft: "1px solid grey" }}>
                        {Number(frtCost).toFixed(3)}
                      </TableCell>
                      <TableCell>{Number(profitNet).toFixed(0)}</TableCell>
                      <TableCell>{Number(loadability).toFixed(0)}</TableCell>
                      {/* <TableCell>{moment(getArrivalTimeOfFirstLoading).format('DD/MMM/yyyy HH:mm')}</TableCell> */}
                      <TableCell
                        style={{ width: "14%", borderLeft: "1px solid grey" }}
                      >
                        {arrivalTime
                          ? moment(arrivalTime).format("DD/MMM/yyyy HH:mm")
                          : ""}
                      </TableCell>
                      {/* <TableCell>Rank</TableCell> */}
                      <TableCell style={{ borderLeft: "1px solid grey" }}>
                        {Number(vesselPremium).toFixed(0)}
                      </TableCell>
                      <TableCell>
                        {Number(positionPremium).toFixed(0)}
                      </TableCell>
                      <TableCell>
                        <textarea
                          value={remark}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            onChangeHandler(
                              vessel._id,
                              "remark",
                              e.target.value
                            )
                          }
                        />
                      </TableCell>
                      {!isFromCargoAnalysis && <TableCell onClick={(e) => e.stopPropagation()}>
                        <div className="d-flex">
                          <DeleteIcon
                            style={{
                              color: "blue",
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              setDeleteId(_id);
                              setShowConfrim(true);
                            }}
                          />
                          <div>
                            <img
                              src={
                                isReferenceVessel ? pinIconRed : pinIconBlack
                              }
                              width="13px"
                              onClick={(e) => {
                                onChangeIsReferenceVessel(vessel);
                              }}
                              alt=""
                            />
                          </div>
                          {/* <a
                        href={`/singlevessel/${queryid}/calculater/${_id}`}
                        target="_blank"
                        rel="noreferrer"
                      > */}
                          <ShareIcon
                            style={{
                              color: "green",
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              onClickSaveFleet(_id, saveLabel, vessel)
                            }
                          />
                          {/* </a> */}
                          {/* <SaveIcon
                          style={{
                            color: "green",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          onClick={() => onClickSaveFleet(_id, saveLabel, vessel)}
                        /> */}
                        </div>
                      </TableCell>}
                    </TableRow>
                    {expandedRows[_id] && (
                      <TableRow>
                        <TableCell
                          colSpan={18}
                          style={{ padding: 0, border: "none" }}
                        >
                          <Accordion
                            className="accordian-background"
                            expanded={expandedRows[_id]}
                            onChange={() => handleAccordionToggle(_id)}
                          >
                            <AccordionDetails style={{ padding: 0 }}>
                              <Typography>
                                {/* Display more information here, such as: */}
                                <CalculatorMain
                                  calculatorData={calculatorData}
                                  setLoader={setLoader}
                                  getInitalQueryAndFleetData={
                                    getInitalQueryAndFleetData
                                  }
                                  setSelectedVessels={setSelectedVessels}
                                  selectedVessels={selectedVessels}
                                  setCalculatorData={setCalculatorData}
                                  onChangeHandler={onChangeSelectedVessel}
                                  fleetCheckbox={fleetCheckbox}
                                  setFleetCheckbox={setFleetCheckbox}
                                  onChangeSelectedVessel={
                                    onChangeSelectedVessel
                                  }
                                  setMarginModal={setMarginModal}
                                  syncBunker={syncBunker}
                                  setSyncBunker={setSyncBunker}
                                  setSupplyModal={setSupplyModal}
                                  onChangeSyncBunker={onChangeSyncBunker}
                                  isOverride={isOverride}
                                  onFetchPortOperationDistance={
                                    onFetchPortOperationDistance
                                  }
                                  openSplitHireModal={openSplitHireModal}
                                  calculationErrors={calculationErrors}
                                  marginModal={marginModal}
                                  onChangePortOperations={
                                    onChangePortOperations
                                  }
                                  onAddOperations={onAddOperations}
                                  onDeleteOperations={onDeleteOperations}
                                  supplyModal={supplyModal}
                                  fetchPortDistance={fetchPortDistance}
                                  openAddOperation={openAddOperation}
                                  setOpenAddOperation={setOpenAddOperation}
                                  setOpenAddOperationindex={
                                    setOpenAddOperationindex
                                  }
                                  onChangePortCanalInQueryState={
                                    onChangePortCanalInQueryState
                                  }
                                  setIsOverride={setIsOverride}
                                  hideOrder={hideOrder}
                                  setHideOrder={setHideOrder}
                                  hideCalculator={hideCalculator}
                                  sendCalculationRequest={
                                    sendCalculationRequest
                                  }
                                  getCurrentResult={getCurrentResult}
                                  isFromCargoAnalysis={isFromCargoAnalysis}
                                  onChangeChartValue={onChangeChartValue}
                                />
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan="18" align="center" className="!bg-white">
                  No Vessel Found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomShowModal
        open={addFleetModal}
        disableClose
        handleClose={onClickAddFleet}
        maxWidth="lg"
        title="Add Fleet"
      >
        <AddFleetModal
          calculatorData={calculatorData}
          setCalculatorData={setCalculatorData}
          addFleetsArray={addFleetsArray}
          setAddFleetsArray={setAddFleetsArray}
          onChangeNewFleetsValue={onChangeNewFleetsValue}
          setLoader={setLoader}
          setAddFleetModal={setAddFleetModal}
          sendCalculationRequest={sendCalculationRequest}
          getInitalQueryAndFleetData={getInitalQueryAndFleetData}
          onClickAddFleet={onClickAddFleet}
        />
      </CustomShowModal>

      <CustomShowModal
        open={splitHireModal}
        handleClose={() => setSplitHireModal(false)}
        maxWidth="xs"
        title="Split Hire"
      >
        <SplitHireModal
          addNewSplitHire={addNewSplitHire}
          selectedSplitHire={selectedSplitHire}
          onChangeSplitHire={onChangeSplitHire}
        />
      </CustomShowModal>

      <CustomAddModal
        open={saveModal}
        handleClose={onCloseSaveFleet}
        onSubmitConfirm={onClickSaveFleetLabel}
        maxWidth="xs"
        saveLabel="Save"
        title="Save Voyage"
      >
        <SaveVesselLabel
          value={saveLabel}
          onChange={(e) => setSaveLabel(e.target.value)}
        />
      </CustomAddModal>

      <ConfirmModal
        open={showConfrim}
        message="Are you sure you want to delete this Vessel?"
        handleClose={() => setShowConfrim(false)}
        onSubmitConfirm={onClickDeleleButton}
      />
    </div>
  );
}

export default memo(VesselSelection);
