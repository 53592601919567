import React from "react";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { BiCalculator, BiSolidMapPin } from "react-icons/bi";
import { MdSave } from "react-icons/md";

function ActionButtonsCargoCargo(props) {
  const {
    onClickCalculateAndSave = () => { },
    onClickCopyToMyCargo = () => { },
    calculatorData = {}
  } = props;

  return (
    <div container className="action-background actions-buttons-div pt-2 pb-1 pr-14 pl-2">
      <div className="flex justify-end w-full items-center action-button-container-outer">

        <div className="flex gap-32">
          {calculatorData?.access === "private" ?
            <button
              className="w-36 text-center py-1 cal-buttons-color border border-neutrals6  rounded-md text-sm flex items-center justify-center gap-2"
              onClick={onClickCalculateAndSave}
            >
              <MdSave size={15} className="calc-icon-color" />
              <span>Calculate & Save</span>
            </button> :
            <button
              className="w-36 text-center py-1 cal-buttons-color border border-neutrals6  rounded-md text-sm flex items-center justify-center gap-2"
              onClick={onClickCopyToMyCargo}
            >
              <MdSave size={15} className="calc-icon-color" />
              <span>Copy to my cargo</span>
            </button>
          }
        </div>
      </div>
    </div>
  );
}

export default ActionButtonsCargoCargo;
