import {

  sendCalculatorDataAPI,
  deleteFleetAPI,
  savePortDistanceAPI,
  resetDistanceAPI,
  allFleetCalculatorAPI,
  updateChangeSwitchQueryAPI,
  getVesselVesselDetailsAPI,
  getAllVesselVesselDetailsAPI,
  addTeamVesselVesselDetailsAPI,
  getSingleVesselVesselDetailsAPI,
  addCargoDetailsAPI,
  getPortDistanceVesselVesselByPortListAPI,
  updateVesselVesselDetailsAPI,
  deleteCargoExtraDetailVesselVesselAPI,
  copyToMyVesselVesselDetailsAPI,
  deleteVesselVesselAPI,
  // getNewPortDistanceByPortListAPI,
} from "./api";
import * as actionsTypes from "./actionTypes";


// export const getNewPortDistanceByPortList = (data) => {
//     return new Promise((resolve, reject) => {
//         getNewPortDistanceByPortListAPI(data).then(res => {
//             resolve(res)
//         }).catch(err => {
//             reject(err)
//         })
//     })
// }

export const clearCalculatorReducer = () => {
  return (disptach) => {
    disptach({
      type: actionsTypes.CLEAR_CALCULATOR_REDUCER,
    });
  };
};


export const getVesselVesselDetails = (data) => {
  return new Promise((resolve, reject) => {
    getVesselVesselDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllVesselVesselDetails = (data) => {
  return new Promise((resolve, reject) => {
    getAllVesselVesselDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const addTeamVesselVesselDetails = (data) => {
  return new Promise((resolve, reject) => {
    addTeamVesselVesselDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSingleVesselVesselDetails = (id, data) => {
  return new Promise((resolve, reject) => {
    getSingleVesselVesselDetailsAPI(id, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addCargoDetails = (data) => {
  return new Promise((resolve, reject) => {
    addCargoDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPortDistanceVesselVesselByPortList = (data) => {
  return new Promise((resolve, reject) => {
    getPortDistanceVesselVesselByPortListAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateVesselVesselDetails = (id, data) => {
  return new Promise((resolve, reject) => {
    updateVesselVesselDetailsAPI(id, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteCargoExtraDetailVesselVessel = (data) => {
  return new Promise((resolve, reject) => {
    deleteCargoExtraDetailVesselVesselAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const savePortDistance = (data) => {
  return new Promise((resolve, reject) => {
    savePortDistanceAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const sendCalculatorData = (data) => {
  return new Promise((resolve, reject) => {
    sendCalculatorDataAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetDistance = (data) => {
  return new Promise((resolve, reject) => {
    resetDistanceAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateChangeSwitchQuery = (data) => {
  return new Promise((resolve, reject) => {
    updateChangeSwitchQueryAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteFleet = (data) => {
  return new Promise((resolve, reject) => {
    deleteFleetAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const allFleetCalculator = (data) => {
  return new Promise((resolve, reject) => {
    allFleetCalculatorAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const copyToMyVesselVesselDetails = (data) => {
  return new Promise((resolve, reject) => {
    copyToMyVesselVesselDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteVesselVessel = (data) => {
  return new Promise((resolve, reject) => {
    deleteVesselVesselAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};