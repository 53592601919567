import React from 'react'
import { Grid, Switch, TableContainer, Paper, Table, TableBody, TableHead, TableCell, TableRow } from "@material-ui/core"
import { fuelPriceOptions } from '../../constants'
import { handleKeyDown } from '../../common/common'
import moment from 'moment'

function BunkerSupply(props) {
    const {
        portOperation = [],
        setSyncBunker = () => { },
        onChangeBunkerScrubber = () => { },
        selectedVessels = {},
        onChangeSyncBunker = () => { },
        clientQuery = () => { },
        onChangeSelectedVessel = () => { },
        onChangePortOperations = () => { },
        isOverride = false,
        setIsOverride = () => { },
        disableEdit = false,
        syncBunker = false
    } = props

    const { fuelType = "", } = clientQuery || {}

    const {
        bnkDelhs = 0,
        bnkDells = 0,
        bnkDelmgo = 0,
        bnkPriceDeliveryhs = 0,
        bnkPriceDeliveryls = 0,
        bnkPriceDeliverymgo = 0,
        bnkPriceRedeliveryhs = 0,
        bnkPriceRedeliveryls = 0,
        bnkPriceRedeliverymgo = 0,
        bnkPriceSettlemenths = 0,
        bnkPriceSettlementls = 0,
        bnkPriceSettlementmgo = 0,
        bnkQtyRedeliveryhs = 0,
        bnkQtyRedeliveryls = 0,
        bnkQtyRedeliverymgo = 0,
        bnkRedeliveryQtyTolerancehs = 0,
        bnkRedeliveryQtyTolerancels = 0,
        bnkRedeliveryQtyTolerancemgo = 0,
    } = selectedVessels || {}

    let selectedFuelType = fuelPriceOptions.find(item => item.value === fuelType)
    if (selectedFuelType) selectedFuelType = selectedFuelType.label
    else selectedFuelType = 'Type-1'

    return (
        <Grid container>
            {disableEdit ? <h1 className="modal-head-text">Bunker Supply Table</h1> : null}
            <Grid item xs={12}>
                <div className="sync-bunker-switch">
                    <label>Allow Fuel Price Adjustment</label>
                    <Switch
                        checked={syncBunker}
                        onChange={e => onChangeSyncBunker(e.target.checked)}
                        name="checkedA"
                        color="primary"
                    />
                </div>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper} className="bunker-supply-table">

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}></TableCell>
                                <TableCell colSpan={4}>Arrival Bunkers</TableCell>
                                <TableCell colSpan={4}>Bunker Supplied at port</TableCell>
                                <TableCell colSpan={2}></TableCell>
                                <TableCell colSpan={4}>Selected Fuel Type: {selectedFuelType}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Operation</TableCell>
                                <TableCell>Port</TableCell>
                                <TableCell>Arrival</TableCell>
                                <TableCell>HS(0.5)</TableCell>
                                <TableCell>LS(0.1)</TableCell>
                                <TableCell>MGO</TableCell>
                                <TableCell>HS(0.5)</TableCell>
                                <TableCell>LS(0.1)</TableCell>
                                <TableCell>MGO</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Reserve</TableCell>
                                <TableCell>Paid By</TableCell>
                                <TableCell>HS(0.5)</TableCell>
                                <TableCell>LS(0.1)</TableCell>
                                <TableCell>MGO</TableCell>
                                <TableCell>Departure</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                portOperation.map((operations, index) => {
                                    const { operation = '', port = '', cargoDetails = {}, index: operationIndex, arrivalTime = '', departureTime = '' } = operations
                                    const { reserve = '', bunkerSuppliedHSFO = 0, bunkerSuppliedLSFO = 0, bunkerSuppliedMGO = 0, paidBy = 'Owner' } = cargoDetails || {}

                                    const selectedArrivalBunker = (selectedVessels && selectedVessels.portToPortOperationResultMap && selectedVessels.portToPortOperationResultMap[operationIndex]) || {}
                                    const { arrivalHsfo = 0, arrivalLsfo = 0, arrivalMgo = 0 } = selectedArrivalBunker || {}

                                    const currentType = fuelType.replace("TYPE", "")
                                    const currentHsfo = cargoDetails[`fuelPriceType${currentType}Hsfo`] || 0
                                    const currentLsFo = cargoDetails[`fuelPriceType${currentType}Lsfo`] || 0
                                    const currentMgo = cargoDetails[`fuelPriceType${currentType}Mgo`] || 0

                                    return <TableRow key={index}>
                                        <TableCell><p>{operation}</p></TableCell>
                                        <TableCell><p>{port}</p></TableCell>
                                        <TableCell><p style={{ width: "max-content" }}>{arrivalTime ? moment(arrivalTime).format('DD-MM-yyyy HH:mm') : ''}</p></TableCell>
                                        <TableCell><p>{Number(arrivalHsfo).toFixed(2)}</p></TableCell>
                                        <TableCell><p>{Number(arrivalLsfo).toFixed(2)}</p></TableCell>
                                        <TableCell><p>{Number(arrivalMgo).toFixed(2)}</p></TableCell>
                                        <TableCell style={{ width: 60 }}>{index === 0 ? <p>{bunkerSuppliedHSFO}</p> : <input onKeyDown={handleKeyDown} type="number" value={bunkerSuppliedHSFO} name="bunkerSuppliedHSFO" onChange={e => onChangePortOperations(index, e, true)} />}</TableCell>
                                        <TableCell style={{ width: 60 }}>{index === 0 ? <p>{bunkerSuppliedLSFO}</p> : <input onKeyDown={handleKeyDown} type="number" value={bunkerSuppliedLSFO} name="bunkerSuppliedLSFO" onChange={e => onChangePortOperations(index, e, true)} />}</TableCell>
                                        <TableCell style={{ width: 60 }}>{index === 0 ? <p>{bunkerSuppliedMGO}</p> : <input onKeyDown={handleKeyDown} type="number" value={bunkerSuppliedMGO} name="bunkerSuppliedMGO" onChange={e => onChangePortOperations(index, e, true)} />}</TableCell>
                                        <TableCell><p>{Number(bunkerSuppliedHSFO) + Number(bunkerSuppliedLSFO) + Number(bunkerSuppliedMGO)}</p></TableCell>
                                        <TableCell>
                                            <select
                                                className="select-color-orange"
                                                name="reserve"
                                                value={reserve}
                                                onChange={e => onChangePortOperations(index, e, true)}
                                            >
                                                <option value="Reserve">Reserve</option>
                                                <option value="Supply">Supply</option>
                                            </select>
                                            {/* <p>{reserve}</p> */}
                                        </TableCell>
                                        <TableCell>
                                            <select
                                                className="select-color-orange"
                                                name="paidBy"
                                                value={paidBy}
                                                onChange={e => onChangePortOperations(index, e, true)}
                                            >
                                                <option value="Operator">External</option>
                                                <option value="Owner">Internal</option>
                                            </select>
                                            {/* <p>{paidBy}</p> */}
                                        </TableCell>
                                        <TableCell className="custom-editable-tablecell">{syncBunker ? index === 0 ? <p>{currentHsfo}</p> : <input onKeyDown={handleKeyDown} type="number" value={currentHsfo} name={`fuelPriceType${currentType}Hsfo`} onChange={e => onChangeBunkerScrubber(index, e)} /> : <p>{currentHsfo}</p>}</TableCell>
                                        <TableCell className="custom-editable-tablecell">{syncBunker ? index === 0 ? <p>{currentLsFo}</p> : <input onKeyDown={handleKeyDown} type="number" value={currentLsFo} name={`fuelPriceType${currentType}Lsfo`} onChange={e => onChangeBunkerScrubber(index, e)} /> : <p>{currentLsFo}</p>}</TableCell>
                                        <TableCell className="custom-editable-tablecell">{syncBunker ? index === 0 ? <p>{currentMgo}</p> : <input onKeyDown={handleKeyDown} type="number" value={currentMgo} name={`fuelPriceType${currentType}Mgo`} onChange={e => onChangeBunkerScrubber(index, e)} /> : <p>{currentMgo}</p>}</TableCell>

                                        <TableCell><p style={{ width: "max-content" }}>{departureTime ? moment(departureTime).format('DD-MM-yyyy HH:mm') : ''}</p></TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 10 }}>
                <div className="sync-bunker-switch">
                    <label>Override</label>
                    <Switch
                        checked={isOverride}
                        onChange={e => setIsOverride(e.target.checked)}
                        name="checkedA"
                        color="primary"
                    />
                </div>
            </Grid>

            <Grid item xs={12}>
                <Grid container className="lower-prices-container">
                    <div className="show-bunker-lower-prices">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>HS(0.5)</th>
                                    <th>LS(0.1)</th>
                                    <th>MGO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Bunker Qty on Delivery</th>
                                    <td><input type="number" onKeyDown={handleKeyDown} value={bnkDelhs} name="bnkDelhs" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                    <td><input type="number" onKeyDown={handleKeyDown} value={bnkDells} name="bnkDells" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                    <td><input type="number" onKeyDown={handleKeyDown} value={bnkDelmgo} name="bnkDelmgo" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                </tr>
                                <tr>
                                    <th>Bunker Qty expected on Re-Delivery</th>
                                    <td><input type="number" onKeyDown={handleKeyDown} value={bnkQtyRedeliveryhs} name="bnkQtyRedeliveryhs" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                    <td><input type="number" onKeyDown={handleKeyDown} value={bnkQtyRedeliveryls} name="bnkQtyRedeliveryls" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                    <td><input type="number" onKeyDown={handleKeyDown} value={bnkQtyRedeliverymgo} name="bnkQtyRedeliverymgo" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                </tr>
                                <tr>
                                    <th>Bunker Qty Tolerance Acceptable</th>
                                    <td><input type="number" onKeyDown={handleKeyDown} value={bnkRedeliveryQtyTolerancehs} name="bnkRedeliveryQtyTolerancehs" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                    <td><input type="number" onKeyDown={handleKeyDown} value={bnkRedeliveryQtyTolerancels} name="bnkRedeliveryQtyTolerancels" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                    <td><input type="number" onKeyDown={handleKeyDown} value={bnkRedeliveryQtyTolerancemgo} name="bnkRedeliveryQtyTolerancemgo" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="show-bunker-lower-prices">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>HS(0.5)</th>
                                    <th>LS(0.1)</th>
                                    <th>MGO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Bunker Prices on Delivery</th>
                                    <td>{!isOverride ? <p>{bnkPriceDeliveryhs}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceDeliveryhs} disabled={!isOverride} name="bnkPriceDeliveryhs" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                    <td>{!isOverride ? <p>{bnkPriceDeliveryls}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceDeliveryls} disabled={!isOverride} name="bnkPriceDeliveryls" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                    <td>{!isOverride ? <p>{bnkPriceDeliverymgo}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceDeliverymgo} disabled={!isOverride} name="bnkPriceDeliverymgo" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                </tr>
                                <tr>
                                    <th>Bunker Prices on Re-Delivery</th>
                                    <td>{!isOverride ? <p>{bnkPriceRedeliveryhs}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceRedeliveryhs} name="bnkPriceRedeliveryhs" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                    <td>{!isOverride ? <p>{bnkPriceRedeliveryls}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceRedeliveryls} name="bnkPriceRedeliveryls" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                    <td>{!isOverride ? <p>{bnkPriceRedeliverymgo}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceRedeliverymgo} name="bnkPriceRedeliverymgo" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                </tr>
                                <tr>
                                    <th>Bunker Prices for Settlement</th>
                                    <td>{!isOverride ? <p>{bnkPriceSettlemenths}</p> : <input type="number" onKeyDown={handleKeyDown} value={bnkPriceSettlemenths} name="bnkPriceSettlemenths" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                    <td>{!isOverride ? <p>{bnkPriceSettlementls}</p> : <input type="number" onKeyDown={handleKeyDown} value={bnkPriceSettlementls} name="bnkPriceSettlementls" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                    <td>{!isOverride ? <p>{bnkPriceSettlementmgo}</p> : <input type="number" onKeyDown={handleKeyDown} value={bnkPriceSettlementmgo} name="bnkPriceSettlementmgo" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default BunkerSupply
