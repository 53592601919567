import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { resultsFields } from "../../constants";
const _ = require('lodash');

function VesselVesselResult(props) {
  const { selectedVessels = {}, calculationErrors = [], getCurrentResult = {}, calculatorData = {}, label = "", fields = [] } = props;
  const isEqual = _.isEqual(calculatorData, getCurrentResult)
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className="calculator-section-header result-section"
        style={{ paddingRight: 10 }}
      >
        <h4 className="result-heading">{label}</h4>
      </Grid>
      <Grid item xs={12}>
        <div>
          <table className="fleet-result-table">
            {
              // isEqual ? 
              fields.map((item, key) => {
                return (
                  <tr key={key}>
                    <th>{item.label}</th>
                    <td>
                      {selectedVessels?.FuelHandOverProfitCount?.[item.value] &&
                        Number(selectedVessels?.FuelHandOverProfitCount?.[item.value]).toFixed(2)}
                    </td>
                  </tr>
                );
              })
              // : "Please click calculate button."
            }
          </table>
        </div>
      </Grid>

    </Grid>
  );
}

export default VesselVesselResult;
