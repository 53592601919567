import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  Paper,
  makeStyles,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  useTheme,
  TableBody,
  Switch,
  Checkbox,
} from "@material-ui/core";
import CustomButton from "../../../components/Common/CustomButton";
import * as actions from "../Calculator/actions";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { getCurrentUserId, getCurrentUserOrganizationId, getOrganizationId, toastError, toastSuccess } from "../../../utils";
import CustomPagination from "../../../components/Common/CustomPagination";
import CustomConfirmModal from "../../../components/Common/CustomConfirmModal";
import AddFleetModal from "../../../components/Calculator/AddCalculatorFleet/AddFleetTabView";
import { initialAddFleet } from "../../../constants";
import CustomShowModal from "../../../components/Common/CustomShowModal";
import AutoAddFleet from "../../../components/Calculator/AddCalculatorFleet/AutoAddFleet";
import ManualAddFleet from "../../../components/Calculator/AddCalculatorFleet/ManualAddFleet";
import UserCopyFleet from "../../../components/Calculator/AddCalculatorFleet/UserCopyFleet";
import _ from "lodash";
import SingleVessel from "../Dashboard/Vessel";
import EditVesselOnFleetAdd from "../Dashboard/Vessel/editVesselOnFleetAdd";
import EditVesselOnFleetAddStep from "../Dashboard/Vessel/steps/editVesselOnFleetAddStep";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MyVessel from "./myVessel";
import SearchBar from "../../../common/Search";

const useStyles = makeStyles({
  root: {
    width: "fit-content",
  },
});

const initialSearchType = {
  type: "",
  saveLabel: "",
  name: "",
  firstLoadPort: "",
  lastDischargePort: "",
  loadQuantity: "",
  createdDate: "",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="pt-8">
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function Fleet(props) {
  const { setLoader = () => { } } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [fleets, setFleets] = useState([]);
  const [allSavedResults, setAllSavedResults] = useState([]);
  const [allFilters, setAllFilters] = useState({
    offset: 1,
    limit: 10,
  });
  const [onDateFilter, setonDateFilter] = useState([])
  const [value, setValue] = useState(0);
  const organizationId = getCurrentUserOrganizationId();
  const [singleResults, setSingleResults] = useState({});
  const [open, setOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      getAllOrgSavedResults()
    }
  };

  const [searchValue, setSearchValue] = useState("");
  const onSearchVessel = (search) => {
    getAllOrgSavedResults({ search: search });
  };
  const delaySearch = useCallback(
    _.debounce((data) => onSearchVessel(data), 500),
    []
  );
  const onSearchChange = (e) => {
    setSearchValue(e.target.value)
    delaySearch(e.target.value);
  }

  useEffect(() => {
    getAllOrgSavedResults()
  }, [])

  const getAllOrgSavedResults = async (fromPagination) => {
    setLoader(true);
    await actions
      .userFleets({ ...fromPagination, access: "public", organizationId: organizationId })
      .then((res) => {
        setLoader(false);
        setAllSavedResults(res?.data?.list || []);
        setAllFilters({
          totalPages: res?.data?.totalItems,
          offset: res?.data?.currentPage,
          pageCount: res?.data?.totalPages,
          limit: res?.data?.perPage
        })
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  };

  const onChangeMyPagination = (type = "") => {
    let newAllFilters = {};
    if (type === "inc") {
      newAllFilters = { ...allFilters, offset: allFilters.offset + 1 };
    } else if (type === "first") {
      newAllFilters = { ...allFilters, offset: 1 };
    } else {
      if (allFilters.offset === 1) return;
      newAllFilters = { ...allFilters, offset: allFilters.offset - 1 };
    }
    setLoader(true);

    getAllOrgSavedResults({ ...newAllFilters, search: searchValue })

  };

  const onClickEditVessel = async (item) => {
    setSingleResults({ ...item, vesseldata: JSON.parse(item?.vessel) } || {});
    // setIsEdit(true)
    setOpen(true)

  }

  const removeExtraFieldFromAuto = ({ _id, nearestport, vesseldata, ...rest }) => ({
    ...rest,
  });
  const onUpdateMyVessel = () => {

    setLoader(true);

    const resulted = singleResults?.map((item) => {
      return {
        ...removeExtraFieldFromAuto(item),
        ...item?.nearestport,
        dateOpen: moment(item?.nearestDate?.date).toISOString(),
        userId: getCurrentUserId(),
        vesselId: item?.vesselId,
        access: "private",
        vessel: JSON.stringify(
          {
            ...(item.vesseldata),
            createdUser: { _id: item.vesseldata?.createdUser }
          }),
      };
    });
    setOpen(false)
    setLoader(true);
    actions
      .addMultiFleet(resulted)
      .then((res) => {
        setLoader(false);
        toastSuccess("Vessel(s) Copy added  successfully");
      })
      .catch((err) => {
        // setAddFleetModal(false);
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
    setOpen(false)
  }
  const handleDateRangeChange = async (newDateRange) => {
    const { startDate, endDate } = newDateRange;
    // Ensure dates are in ISO string format
    const formattedStartDate = startDate?.toISOString() || '';
    const formattedEndDate = endDate?.toISOString() || '';

    if (value == 1) {
      const { data } = await actions.userFleets({
        access: 'public',
        organizationId: organizationId,
        startDate: formattedStartDate,
        endDate: formattedEndDate
      });
      setAllSavedResults(data.list);
    } else {
      const { data } = await actions.userFleets({
        userId: getCurrentUserId(),
        startDate: formattedStartDate,
        endDate: formattedEndDate
      });
      setonDateFilter(data.list);
    }
  };

  return (
    <Grid container>

      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          position="relative"
          width="100%"
        >
          <Box>
            <Paper className={classes.root}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab value={0} label="My Vessel" />
                <Tab label="All Vessel" value={1} />
              </Tabs>
            </Paper>
          </Box>

          <Box
            position="absolute"
            left="50%"
            sx={{
              transform: 'translateX(-50%)',
              display: { xs: 'none', md: 'block' } // Desktop view
            }}
          >
            <div className="desktop-searchbar">
              <SearchBar
                onDateRangeChange={handleDateRangeChange}
                placeholder={"Search with Vessel Name or Port..."}
                setSearchValue={onSearchChange}
                searchValue={searchValue}
              />
            </div>
          </Box>

        </Box>

        {/* Mobile search bar */}
        <Box
          className="md:hidden"
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <SearchBar
            isMobile
            onDateRangeChange={handleDateRangeChange}
            placeholder={"Search with Vessel Name or Port..."}
            setSearchValue={onSearchChange}
            searchValue={searchValue}
          />
        </Box>
      </Grid>
      <div className="add-fleet-modal-container w-[inherit]">
        <TabPanel value={value} index={0} className="px-0">
          <MyVessel {...props} DatefilterValue={onDateFilter} setSearchValue={onSearchChange} searchValue={searchValue} delaySearch={delaySearch} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <TableContainer component={Paper}>
            <Table className="universal-table">
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Vessel Name</TableCell>
                  <TableCell>DWT</TableCell>
                  <TableCell>Port</TableCell>
                  <TableCell>Date Opening</TableCell>
                  <TableCell>Hire Per Day</TableCell>
                  <TableCell>Hire GBB</TableCell>
                  <TableCell>Remark</TableCell>
                  <TableCell>Created on</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(allSavedResults) && allSavedResults.length ? (
                  allSavedResults.map((item, index) => {
                    return (
                      <TableRow
                        onDoubleClick={() => {
                          onClickEditVessel(item);
                        }}
                        key={index}>

                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.vesselName || ""}</TableCell>
                        <TableCell>{item.dwt || 0}</TableCell>
                        <TableCell>{item.port || ""}</TableCell>
                        <TableCell>
                          {moment(item.dateOpen).format("DD/MMM/yyyy")}
                        </TableCell>
                        <TableCell>{item.hirePerDay || ""}</TableCell>
                        <TableCell>{item.hireGbb || 0}</TableCell>
                        <TableCell>{item.remark || ""}</TableCell>
                        <TableCell>
                          {item.createdDate
                            ? moment(item.createdDate).format("DD/MMM/yyyy")
                            : ""}
                        </TableCell>{" "}
                        <TableCell>
                          {item?.createdUser
                            ? `${item.createdUser?.firstName} ${item.createdUser?.lastName}`
                            : ""}
                        </TableCell>
                        <TableCell>
                          <div className="query-actions">
                            <>
                              <span className="pending-steper m-1 validate-icon-vessel-manager">
                                <VisibilityIcon
                                  color="primary"
                                  onClick={() => {
                                    onClickEditVessel(item);
                                  }}
                                />
                              </span>

                              <div className="tooltip-notification-vessel-manager">
                                <p>Copy Vessel details to my vessel</p>
                              </div>
                            </>

                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="8" align="center" className="!bg-white">
                      No Vessel Found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <CustomPagination
              onChangePagination={onChangeMyPagination}
              filters={allFilters}
              totalPage={allFilters?.totalPages}
            />
          </TableContainer>
        </TabPanel>

      </div>
      <CustomShowModal
        open={open}
        disableClose
        handleClose={() => setOpen(false)}
        maxWidth="lg"
        title="Copy Vessel Details"
      >

        <EditVesselOnFleetAddStep setValidationVesselFromAuto={onUpdateMyVessel} setValidationVessel={setOpen} setAddFleetsArray={setSingleResults} addFleetsArray={[singleResults]} onValidationVesselIndex={0} isFromCreateVessel={true} />
      </CustomShowModal>

    </Grid>
  );
}

export default Fleet;
