import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import * as commonActions from "../../containers/Common/actions"
import { useDispatch } from "react-redux"

function CustomErrorAlert(props) {
    const dispatch = useDispatch()
    const { errorTitle = 'List of Errors.', errorList = [] } = props || {}
    return (
        <div className="custom-error-alert">
            <div>
                <h4>{errorTitle}</h4>
                <CloseIcon onClick={() => dispatch(commonActions.closeErrorAlert())} />
            </div>
            <ul style={{overflowX: "auto", maxHeight:"52vh"}}>
                {Array.isArray(errorList) && errorList.map((item, index) => {
                    return <li key={index}>{item}</li>
                })}
            </ul>
        </div>
    )
}

export default CustomErrorAlert


