import {
  apiGet,
  apiPost,
  getCurrentUserId,
} from "../../../utils";

export const getCargoCargoDetailsAPI = (query) => {
  let data = { ...query, userId: getCurrentUserId() };
  return apiGet("/api/cargocargo/getCargoCargoDetails", data);
};

export const getAllCargoCargoDetailsAPI = (query) => {
  let data = { ...query, userId: getCurrentUserId() };
  return apiGet("/api/cargocargo/getAllCargoCargoDetails", data);
};

export const getSingleCargoCargoDetailsAPI = (query) => {
  return apiGet(`/api/cargocargo/getCargoCargoDetail/${query}`,);
};


export const updateCargoCargoDetailsAPI = (id, data) => {
  return apiPost(`/api/cargoCargo/updateCargoCargoDetails/${id}`, data);
};

export const addTeamCargoCargoDetailsAPI = (data) => {
  return apiPost(`/api/cargoCargo/addTeamCargoCargoDetails`, data);
};
