import React, { useCallback } from "react";
import { Grid, Paper, Switch } from "@material-ui/core";
import { expenseResultField, fuelPriceOptions, marginAndAllowancesOptions } from "../../constants";
import * as actions from "../../containers/User/Calculator/actions";
import _ from "lodash";
import { handleKeyDown } from "../../common/common";

function MarginAndAllowances(props) {
  const {
    calculatorData = {},
    setMarginModal = () => { },
    setCalculatorData = () => { },
    selectedVessels = {},
    setSelectedVessels = () => { },
    syncBunker = false,
    setSyncBunker = () => { },
    setSupplyModal = () => { },
    onChangeSyncBunker = () => { },
    onChangeSelectedVessel = () => { },
    isOverride = false,
    setIsOverride = () => { },
    isFromSingleVesselCalcy = false,
    isFromVesselVessel = false
  } = props;

  let vessel =
    (selectedVessels &&
      selectedVessels.vessel &&
      JSON.parse(selectedVessels.vessel)) ||
    {};

  // const delayUpdateOperation = useCallback(
  //   _.debounce(
  //     (fuelType, newClientQuery) => onUpdateFuelPrice(fuelType, newClientQuery),
  //     500
  //   ),
  //   []
  // );

  const { clientQuery = {}, portOperation = [] } = calculatorData || {};
  let {
    fuelCalculationMethod = "",
    fuelType = "TYPE0",
    lsFuelPriceType0 = 0,
    lsFuelPriceType1 = 0,
    lsFuelPriceType2 = 0,
    lsFuelPriceType3 = 0,
    lsFuelPriceType4 = 0,
    hsFuelPriceType0 = 0,
    hsFuelPriceType1 = 0,
    hsFuelPriceType2 = 0,
    hsFuelPriceType3 = 0,
    hsFuelPriceType4 = 0,
    mgoFuelPriceType0 = 0,
    mgoFuelPriceType1 = 0,
    mgoFuelPriceType2 = 0,
    mgoFuelPriceType3 = 0,
    mgoFuelPriceType4 = 0,
  } = clientQuery || {};

  let fuelTypePrices = {
    TYPE0: {
      normal: hsFuelPriceType0 || 0,
      eca: lsFuelPriceType0 || 0,
      mgo: mgoFuelPriceType0 || 0,
    },
    TYPE1: {
      normal: hsFuelPriceType1 || 0,
      eca: lsFuelPriceType1 || 0,
      mgo: mgoFuelPriceType1 || 0,
    },
    TYPE2: {
      normal: hsFuelPriceType2 || 0,
      eca: lsFuelPriceType2 || 0,
      mgo: mgoFuelPriceType2 || 0,
    },
    TYPE3: {
      normal: hsFuelPriceType3 || 0,
      eca: lsFuelPriceType3 || 0,
      mgo: mgoFuelPriceType3 || 0,
    },
    TYPE4: {
      normal: hsFuelPriceType4 || 0,
      eca: lsFuelPriceType4 || 0,
      mgo: mgoFuelPriceType4 || 0,
    },
  };

  const onChangeHandler = (e) => {
    const { name: key = "", value = "" } = e.target;
    let newCargoDetails = {};
    if (key === "fuelType") {
      vessel.fuelType = value;
      setSelectedVessels({
        ...selectedVessels,
        vessel: JSON.stringify(vessel),
      });
      const currentType = value.replace("TYPE", "");
      newCargoDetails[`fuelPriceType${currentType}Hsfo`] = Number(
        clientQuery[`hsFuelPriceType${currentType}`]
      );
      newCargoDetails[`fuelPriceType${currentType}Lsfo`] = Number(
        clientQuery[`lsFuelPriceType${currentType}`]
      );
      newCargoDetails[`fuelPriceType${currentType}Mgo`] = Number(
        clientQuery[`mgoFuelPriceType${currentType}`]
      );
    }
    if (key === "fuelCalculationMethod" && value !== "DEFAULT") {
      setSyncBunker(true);
    } else {
      setSyncBunker(false);
    }

    setCalculatorData({
      ...calculatorData,
      clientQuery: {
        ...calculatorData.clientQuery,
        [key]: value,
      },
      portOperation: calculatorData.portOperation.map((item) => {
        return {
          ...item,
          cargoDetails: {
            ...item.cargoDetails,
            ...newCargoDetails,
          },
        };
      }),
    });
  };

  const onChangeFuelPrice = (e) => {
    const { name = "", value = "" } = e.target;
    if (!vessel.fuelType) vessel.fuelType = "TYPE0";

    let newClientQuery = { ...clientQuery };
    let newCargoDetails = {};
    const currentType = fuelType.replace("TYPE", "");
    if (name === "normal") {
      newClientQuery[`hsFuelPriceType${currentType}`] = value;
    }
    if (name === "eca") {
      newClientQuery[`lsFuelPriceType${currentType}`] = value;
    }
    if (name === "mgo") {
      newClientQuery[`mgoFuelPriceType${currentType}`] = value;
    }

    if (fuelCalculationMethod === "DEFAULT") {
      // newCargoDetails[`fuelPriceType${currentType}Hsfo`] = Number(newClientQuery[`hsFuelPriceType${currentType}`])
      // newCargoDetails[`fuelPriceType${currentType}Lsfo`] = Number(newClientQuery[`lsFuelPriceType${currentType}`])
      // newCargoDetails[`fuelPriceType${currentType}Mgo`] = Number(newClientQuery[`mgoFuelPriceType${currentType}`])

      if (!isOverride) {
        setSelectedVessels({
          ...selectedVessels,
          bnkPriceDeliveryhs: Number(
            newClientQuery[`hsFuelPriceType${currentType}`]
          ),
          bnkPriceDeliveryls: Number(
            newClientQuery[`lsFuelPriceType${currentType}`]
          ),
          bnkPriceDeliverymgo: Number(
            newClientQuery[`mgoFuelPriceType${currentType}`]
          ),
          bnkPriceRedeliveryhs: Number(
            newClientQuery[`hsFuelPriceType${currentType}`]
          ),
          bnkPriceRedeliveryls: Number(
            newClientQuery[`lsFuelPriceType${currentType}`]
          ),
          bnkPriceRedeliverymgo: Number(
            newClientQuery[`mgoFuelPriceType${currentType}`]
          ),
          bnkPriceSettlemenths: Number(
            newClientQuery[`hsFuelPriceType${currentType}`]
          ),
          bnkPriceSettlementls: Number(
            newClientQuery[`lsFuelPriceType${currentType}`]
          ),
          bnkPriceSettlementmgo: Number(
            newClientQuery[`mgoFuelPriceType${currentType}`]
          ),
        });
      }

      setCalculatorData({
        ...calculatorData,
        portOperation: calculatorData.portOperation.map((item, index) => {
          if (isOverride && item.operation === "Commence") return item;
          return {
            ...item,
            cargoDetails: {
              ...item.cargoDetails,
              ...newCargoDetails,
            },
          };
        }),
        clientQuery: newClientQuery,
      });
      // delayUpdateOperation(fuelType, newClientQuery);
    }
  };

  // const onUpdateFuelPrice = (fuelType, newClientQuery) => {
  //   actions.updateFuelPrice(fuelType, newClientQuery);
  // };

  const onChangePortMarginValue = (e) => {
    const { name = "", value = "" } = e.target || {};
    let updatedOperation = [];
    if (name === "dwf") {
      updatedOperation = portOperation.map((item) => ({
        ...item,
        dwf: Number(value),
      }));
    } else {
      updatedOperation = portOperation.map((item) => ({
        ...item,
        cargoDetails: { ...item.cargoDetails, [name]: Number(value) },
      }));
    }
    setCalculatorData({
      ...calculatorData,
      portOperation: updatedOperation,
    });
  };

  const checkDiff = (a) => {
    return a.length !== 0 && new Set(a).size === 1;
  };

  let dwfArray = [];
  let speedFactorArray = [];
  let speedReduceArray = [];
  let bunkerConArray = [];

  for (let i = 0; i < portOperation.length; i++) {
    const operation = portOperation[i];
    const { cargoDetails = {}, dwf = 0 } = operation || {};
    const { speedFactor = 0, speedReduce = 0, bnkrFactor = 0 } = cargoDetails;
    dwfArray.push(dwf);
    speedFactorArray.push(speedFactor);
    speedReduceArray.push(speedReduce);
    bunkerConArray.push(bnkrFactor);
  }

  let showDwfViewReset = checkDiff(dwfArray);
  let showSpeedFactorViewReset = checkDiff(speedFactorArray);
  let showSpeedReduceViewReset = checkDiff(speedReduceArray);
  let showBunkerConViewReset = checkDiff(bunkerConArray);

  const onChangeViewReset = (name) => {
    let updatedOperation = [];
    if (name === "dwf") {
      updatedOperation = portOperation.map((item) => ({ ...item, dwf: 0 }));
    } else {
      updatedOperation = portOperation.map((item) => ({
        ...item,
        cargoDetails: { ...item.cargoDetails, [name]: 0 },
      }));
    }
    setCalculatorData({
      ...calculatorData,
      portOperation: updatedOperation,
    });
  };

  const onChangeQueryValues = (e) => {
    const { name = "", value = "" } = e.target;
    let newPortOperation = [...portOperation];
    setCalculatorData({
      ...calculatorData,
      clientQuery: {
        ...clientQuery,
        [name]: Number(value),
      },
      portOperation: newPortOperation,
    });
  };

  let dwfValue = 0;
  let speedFactorValue = 0;
  let speedReduceValue = 0;
  let bnkrFactorValue = 0;
  if (Array.isArray(portOperation) && portOperation.length) {
    dwfValue = portOperation[0].dwf || 0;
    speedFactorValue =
      (portOperation[0].cargoDetails &&
        portOperation[0].cargoDetails.speedFactor) ||
      0;
    speedReduceValue =
      (portOperation[0].cargoDetails &&
        portOperation[0].cargoDetails.speedReduce) ||
      0;
    bnkrFactorValue =
      (portOperation[0].cargoDetails &&
        portOperation[0].cargoDetails.bnkrFactor) ||
      0;
  }

  const selectedFuelType =
    fuelPriceOptions.find((item) => item.value === fuelType) || {};

  const {
    bnkDelhs = 0,
    bnkDells = 0,
    bnkDelmgo = 0,
    bnkPriceDeliveryhs = 0,
    bnkPriceDeliveryls = 0,
    bnkPriceDeliverymgo = 0,
    bnkPriceRedeliveryhs = 0,
    bnkPriceRedeliveryls = 0,
    bnkPriceRedeliverymgo = 0,
    bnkPriceSettlemenths = 0,
    bnkPriceSettlementls = 0,
    bnkPriceSettlementmgo = 0,
    bnkQtyRedeliveryhs = 0,
    bnkQtyRedeliveryls = 0,
    bnkQtyRedeliverymgo = 0,
    bnkRedeliveryQtyTolerancehs = 0,
    bnkRedeliveryQtyTolerancels = 0,
    bnkRedeliveryQtyTolerancemgo = 0,
  } = selectedVessels || {}

  return (
    <Grid container>
      <Grid item xs={12}>
        <div>
          <Grid container>
            <Grid item xs={12} className="calculator-section-header">
              <h4
                className="result-heading cursor-pointer underline"
                onClick={() => setMarginModal(true)}
              >
                Margin and Allowances
              </h4>
            </Grid>

            <Grid item xs={12}>
              <table className="fleet-result-table">
                <tr>
                  <th>DWF</th>
                  {showDwfViewReset ? (
                    <>
                      <td>
                        {" "}
                        <input
                          type="number"
                          onKeyDown={handleKeyDown}
                          name="dwf"
                          value={dwfValue}
                          onChange={onChangePortMarginValue}
                        />
                      </td>
                      <td>%</td>
                    </>
                  ) : (
                    <>
                      <td>
                        <span onClick={() => setMarginModal(true)}>View</span>
                      </td>
                      <td>
                        <span onClick={() => onChangeViewReset("dwf")}>
                          Reset
                        </span>
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  <th>Speed Perf Factor</th>
                  {showSpeedFactorViewReset ? (
                    <>
                      <td>
                        {" "}
                        <input
                          type="number"
                          onKeyDown={handleKeyDown}
                          name="speedFactor"
                          value={speedFactorValue}
                          onChange={onChangePortMarginValue}
                        />
                      </td>
                      <td>%</td>
                    </>
                  ) : (
                    <>
                      <td>
                        <span onClick={() => setMarginModal(true)}>View</span>
                      </td>
                      <td>
                        <span onClick={() => onChangeViewReset("speedFactor")}>
                          Reset
                        </span>
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  <th>Speed Reduce</th>
                  {showSpeedReduceViewReset ? (
                    <>
                      <td>
                        {" "}
                        <input
                          type="number"
                          onKeyDown={handleKeyDown}
                          name="speedReduce"
                          value={speedReduceValue}
                          onChange={onChangePortMarginValue}
                        />
                      </td>
                      <td>Knots</td>
                    </>
                  ) : (
                    <>
                      <td>
                        <span onClick={() => setMarginModal(true)}>View</span>
                      </td>
                      <td>
                        <span onClick={() => onChangeViewReset("speedReduce")}>
                          Reset
                        </span>
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  <th>Bunker Con Allowance</th>
                  {showBunkerConViewReset ? (
                    <>
                      <td>
                        {" "}
                        <input
                          type="number"
                          onKeyDown={handleKeyDown}
                          name="bnkrFactor"
                          value={bnkrFactorValue}
                          onChange={onChangePortMarginValue}
                        />
                      </td>
                      <td>%</td>
                    </>
                  ) : (
                    <>
                      <td>
                        <span onClick={() => setMarginModal(true)}>View</span>
                      </td>
                      <td>
                        <span onClick={() => onChangeViewReset("bnkrFactor")}>
                          Reset
                        </span>
                      </td>
                    </>
                  )}
                </tr>
              </table>
            </Grid>

            <Grid item xs={12}>
              <div className="fuel-calculation-method">
                <span>Fuel Calculation Method</span>
                <select
                  value={fuelCalculationMethod}
                  name="fuelCalculationMethod"
                  onChange={onChangeHandler}
                // disabled={checkDisabled.operation}
                >
                  <option value="DEFAULT">DEFAULT</option>
                  <option value="LIFO">LIFO</option>
                  <option value="FIFO">FIFO</option>
                  <option value="AVERAGE">AVERAGE</option>
                </select>
              </div>
            </Grid>
            {isFromVesselVessel ?

              <>
                <h4 className="result-heading m-t-5">Expenses</h4>
                <table className="fleet-result-table">
                  {expenseResultField.map((item, key) => {
                    return (
                      <tr key={key}>
                        <th>{item.label}</th>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            onChange={onChangeQueryValues}
                            value={clientQuery[item.value] || ""}
                            name={item.value}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </table></> :
              <Grid item xs={12}>
                <p className="m-t-5 m-l-5 m-b-5">Fuel Price</p>
                <div className="consumptions-headers">
                  <p></p>
                  <p>Normal</p>
                  <p>ECA</p>
                  <p>MGO</p>
                </div>
                {syncBunker ? (
                  <>
                    <p
                      className="view-bunker-supply-table"
                      onClick={() => setSupplyModal(true)}
                    >
                      View Bunker Supply Table
                    </p>
                  </>
                ) : (
                  <div className="consumptions-content">
                    <select
                      value={fuelType}
                      name="fuelType"
                      className="margin-scrubber-select"
                      onChange={onChangeHandler}
                      disabled
                    >
                      {fuelPriceOptions.map((item, index) => {
                        return (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                    {console.log('===fuelTypePrices==>',fuelTypePrices,fuelType)}
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="normal"
                      onChange={onChangeFuelPrice}
                      value={fuelTypePrices?.[fuelType]?.normal}
                    />
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="eca"
                      onChange={onChangeFuelPrice}
                      value={fuelTypePrices?.[fuelType]?.eca}
                    />
                    <input
                      type="number"
                      placeholder="0"
                      onKeyDown={handleKeyDown}
                      name="mgo"
                      onChange={onChangeFuelPrice}
                      value={fuelTypePrices?.[fuelType]?.mgo}
                    />
                  </div>
                )}
              </Grid>}


            {(!isFromSingleVesselCalcy && !isFromVesselVessel) ? <Grid item xs={12}>
              <h2 className="fuel-type-bold-text">
                {(selectedFuelType && selectedFuelType.label) || "Type-1"}
              </h2>
            </Grid> : ""}

            {isFromSingleVesselCalcy ? <Grid item xs={12}>
              <div style={{ marginBottom: 0 }} className="sync-bunker-switch">
                <label>Override</label>
                <Switch
                  checked={isOverride}
                  onChange={e => setIsOverride(e.target.checked)}
                  name="checkedA"
                  color="primary"
                />
              </div>
            </Grid> : ""}

            {isFromSingleVesselCalcy ? <Grid item xs={12}>
              <Grid container className="lower-prices-container">


                <div className="show-bunker-lower-prices-from-single-vessel">
                  <table>
                    <thead>
                      <tr>
                        <th>Bunker Prices</th>
                        <th>HS(0.5)</th>
                        <th>LS(0.1)</th>
                        <th>MGO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th> On Delivery</th>
                        <td>{!isOverride ? <p>{bnkPriceDeliveryhs}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceDeliveryhs} disabled={!isOverride} name="bnkPriceDeliveryhs" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                        <td>{!isOverride ? <p>{bnkPriceDeliveryls}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceDeliveryls} disabled={!isOverride} name="bnkPriceDeliveryls" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                        <td>{!isOverride ? <p>{bnkPriceDeliverymgo}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceDeliverymgo} disabled={!isOverride} name="bnkPriceDeliverymgo" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                      </tr>
                      <tr>
                        <th> On Re-Delivery</th>
                        <td>{!isOverride ? <p>{bnkPriceRedeliveryhs}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceRedeliveryhs} name="bnkPriceRedeliveryhs" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                        <td>{!isOverride ? <p>{bnkPriceRedeliveryls}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceRedeliveryls} name="bnkPriceRedeliveryls" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                        <td>{!isOverride ? <p>{bnkPriceRedeliverymgo}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceRedeliverymgo} name="bnkPriceRedeliverymgo" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                      </tr>
                      <tr>
                        <th> For Settlement</th>
                        <td>{!isOverride ? <p>{bnkPriceSettlemenths}</p> : <input type="number" onKeyDown={handleKeyDown} value={bnkPriceSettlemenths} name="bnkPriceSettlemenths" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                        <td>{!isOverride ? <p>{bnkPriceSettlementls}</p> : <input type="number" onKeyDown={handleKeyDown} value={bnkPriceSettlementls} name="bnkPriceSettlementls" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                        <td>{!isOverride ? <p>{bnkPriceSettlementmgo}</p> : <input type="number" onKeyDown={handleKeyDown} value={bnkPriceSettlementmgo} name="bnkPriceSettlementmgo" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="show-bunker-lower-prices-from-single-vessel">
                  <table>
                    <thead>
                      <tr>
                        <th>Bunker Qty </th>
                        <th>HS(0.5)</th>
                        <th>LS(0.1)</th>
                        <th>MGO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>On Delivery</th>
                        <td><input type="number" onKeyDown={handleKeyDown} value={bnkDelhs} name="bnkDelhs" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                        <td><input type="number" onKeyDown={handleKeyDown} value={bnkDells} name="bnkDells" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                        <td><input type="number" onKeyDown={handleKeyDown} value={bnkDelmgo} name="bnkDelmgo" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                      </tr>
                      <tr>
                        <th>Exp on Re-Delivery</th>
                        <td><input type="number" onKeyDown={handleKeyDown} value={bnkQtyRedeliveryhs} name="bnkQtyRedeliveryhs" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                        <td><input type="number" onKeyDown={handleKeyDown} value={bnkQtyRedeliveryls} name="bnkQtyRedeliveryls" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                        <td><input type="number" onKeyDown={handleKeyDown} value={bnkQtyRedeliverymgo} name="bnkQtyRedeliverymgo" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                      </tr>
                      <tr>
                        <th>Tolerance Acceptable</th>
                        <td><input type="number" onKeyDown={handleKeyDown} value={bnkRedeliveryQtyTolerancehs} name="bnkRedeliveryQtyTolerancehs" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                        <td><input type="number" onKeyDown={handleKeyDown} value={bnkRedeliveryQtyTolerancels} name="bnkRedeliveryQtyTolerancels" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                        <td><input type="number" onKeyDown={handleKeyDown} value={bnkRedeliveryQtyTolerancemgo} name="bnkRedeliveryQtyTolerancemgo" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid> : ""}

            {!isFromSingleVesselCalcy ? <Grid item xs={12}>
              <p className="m-t-20 m-l-5 m-b-5">This Vessel:</p>
              <table className="fleet-result-table">
                <tr>
                  <th>Extra Expenses</th>
                  <td>
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      onChange={(e) => onChangeSelectedVessel(e, true)}
                      value={
                        (selectedVessels && selectedVessels.extraExpense) || 0
                      }
                      name="extraExpense"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Constants</th>
                  <td>
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      onChange={(e) => onChangeSelectedVessel(e, true)}
                      value={
                        (selectedVessels && selectedVessels.constants) || 0
                      }
                      name="constants"
                    />
                  </td>
                </tr>
              </table>
            </Grid>
              : ""}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default MarginAndAllowances;
