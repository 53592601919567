import React from "react";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { BiCalculator, BiSolidMapPin } from "react-icons/bi";
import { MdSave } from "react-icons/md";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDataComponent from "./VesselPdf";
import { GrDocumentPdf } from "react-icons/gr";

function ActionButtons(props) {
  const {
    isFromSingleVesselCalcy = false,
    hideOrder = false,
    setHideOrder = () => { },
    onClickCalculate = () => { },
    onClickCalculateAndSave = () => { },
    onSetHideCalculator = () => { },
    onSetOpenMap = () => { },
    isPublic = false,
    pdfdata,
    isFromCargoAnalysis = false,
    isFromMatrix = false
  } = props;
  return (
    <div container className="action-background actions-buttons-div pt-2 pb-1 pr-3 pl-2">
      <div className="flex justify-between w-full items-center action-button-container-outer">

        <div className="flex gap-32">
          {!isFromSingleVesselCalcy && <div>
            <SwapHorizIcon onClick={() => setHideOrder((prev) => !prev)} />
          </div>}
          {!isFromSingleVesselCalcy && <div className="show-hide-calculator">
            <SwapHorizIcon onClick={onSetHideCalculator} />
          </div>}
        </div>

        <div className="action-button-container flex gap-3">
          {!isFromSingleVesselCalcy ? <button
            className="w-36 text-center py-1 cal-buttons-color border border-neutrals6  rounded-md text-sm flex items-center justify-center gap-2"
            onClick={onSetOpenMap}
          >
            <BiSolidMapPin size={15} className="calc-icon-color" />
            <span>Open Map</span>
          </button> : ""}
          {/* <a href="/voyages" target="_blank"><button className="calculate">Load Saved Results</button></a> */}
          {/* <button className="calculate" onClick={onSetHideCalculator}>Show/Hide Calculator</button> */}
          <button
            className="w-36 text-center py-1 cal-buttons-color border border-neutrals6  rounded-md text-sm flex items-center justify-center gap-2"
            onClick={onClickCalculate}
          >
            <BiCalculator size={15} className="calc-icon-color" />
            Calculate
          </button>
          {!isPublic ?
            <button
              className="w-36 text-center py-1 cal-buttons-color border border-neutrals6  rounded-md text-sm flex items-center justify-center gap-2"
              onClick={onClickCalculateAndSave}
            >
              <MdSave size={15} className="calc-icon-color" />
              <span>Calculate & Save</span>
            </button> : ""}
          {(isFromCargoAnalysis || isFromMatrix) &&

            <div className="action-button-container flex gap-3">
              <PDFDownloadLink
                document={<PdfDataComponent data={pdfdata} />}
                fileName={`${Date.now()}.pdf`} // Corrected this line
              >
                <div className="download-pdf-calc text-center py-2 cal-buttons-color border border-neutrals6  rounded-md text-sm flex items-center justify-center gap-2">
                  <span className="size-[15px] ">
                    <GrDocumentPdf className="size-3 calc-icon-color" style={{ color: "#fff" }} />
                  </span>
                  <span className="download-pdf">Dowload PDF</span>
                </div>
              </PDFDownloadLink>
            </div>
          }
        </div>
      </div>
    </div >
  );
}

export default ActionButtons;
