import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { netProfitResultsFields, resultsFields } from "../../../../constants";

function NetProfitResult(props) {
  const { selectedVessels = {}, calculationErrors = [], label = "", calculatorData = {} } = props;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className="calculator-section-header result-section"
        style={{ paddingRight: 10 }}
      >
        <h4 className="result-heading">{label}</h4>
      </Grid>
      <Grid item xs={12}>
        <div>
          <table className="fleet-result-table">
            {netProfitResultsFields.map((item, key) => {
              return (
                <tr key={key}>
                  <th>{item.label}</th>
                  <td>
                    {calculatorData?.totalCalculatedRates?.[item.value] &&
                      calculatorData?.totalCalculatedRates?.[item.value] % 1 === 0 ? calculatorData?.totalCalculatedRates?.[item.value] : calculatorData?.totalCalculatedRates?.[item.value]?.toFixed(4)}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </Grid>
    </Grid>
  );
}

export default NetProfitResult;
