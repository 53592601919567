import React, { useCallback, useState, useEffect } from "react";
import { AppBar, Box, Tab, Tabs, useTheme } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { Grid, Switch } from "@material-ui/core";
import { initialAddFleet } from "../../../constants";
import * as actions from "../../../containers/User/Calculator/actions";
import { getOrganizationId, toastError, toastSuccess } from "../../../utils";
import moment from "moment";
import _ from "lodash";
import ManualAddFleet from "./ManualAddFleet";
import AutoAddFleet from "./AutoAddFleet";
import UserCopyFleet from "./UserCopyFleet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function AddFleetTabView(props) {
  const {
    addFleetsArray = [],
    setAddFleetsArray = () => {},
    calculatorData = {},
    setLoader = () => {},
    setAddFleetModal = () => {},
    sendCalculationRequest = () => {},
    getInitalQueryAndFleetData = () => {},
    onClickAddFleet = () => {},
    getAllFleets,
  } = props;

  const theme = useTheme();
  const [value, setValue] = useState(1);
  const [vesselLoading, setVesselLoading] = useState({});
  const [portLoading, setPortLoading] = useState({});
  const [saveVesselListByIndex, setSaveVesselListByIndex] = useState({});
  const [savePortListByIndex, setSavePortListByIndex] = useState({});
  const [autoSwitch, setAutoSwitch] = useState(false);
  const [emailFleetList, setEmailFleetList] = useState([]);
  const [autoFilters, setAutoFilters] = useState({
    updateSince: { label: "1", value: "1" },
    portRegion: [{ label: "Unknown", value: "Unknown" }],
    vesselType: { label: "All", value: "0_99999999" },
    gear: { label: "All", value: "All" },
  });
  const [selectedAutoFleet, setSelectedAutoFleet] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [addFleetRegionOptions, setAddFleetRegionOptions] = useState([]);

  const delayVesselSearch = useCallback(
    _.debounce((index, search) => onSearchVesselList(index, search), 500),
    []
  );
  const delayPortSearch = useCallback(
    _.debounce((index, search) => onSearchPortList(index, search), 500),
    []
  );

  useEffect(() => {
    setValue(0);
    actions.allPortRegions().then((res) => {
      const regionOptions =
        (Array.isArray(res) &&
          res.data.list.map((region) => ({ label: region, value: region }))) ||
        [];
      setAddFleetRegionOptions([
        { label: "Unknown", value: "Unknown" },
        ...regionOptions,
      ]);
    });
    return () => {
      setAddFleetsArray([{ ...initialAddFleet }]);
    };
  }, []);

  const onSearchVesselList = (index, value) => {
    if (value) {
      setVesselLoading({ ...vesselLoading, [index]: true });
      actions
        .allVesselsByCharWithoutReducer(value)
        .then((res) => {
          setVesselLoading({ ...vesselLoading, [index]: false });
          setSaveVesselListByIndex({
            ...saveVesselListByIndex,
            [index]:
              (Array.isArray(res.data.list) &&
                res.data.list.map((item) => ({
                  ...item,
                  vesselLabel: `${item.vesselName} ${item.vesselDwt}`,
                }))) ||
              [],
          });
        })
        .catch((err) => {
          setVesselLoading({ ...vesselLoading, [index]: false });
        });
    }
  };

  const onSearchPortList = (index, value) => {
    if (value) {
      setPortLoading({ ...portLoading, [index]: true });
      actions
        .portsByChar(value)
        .then((res) => {
          setPortLoading({ ...portLoading, [index]: false });
          setSavePortListByIndex({
            ...savePortListByIndex,
            [index]:
              (Array.isArray(res.data.list) &&
                res.data.list.map((item) => ({
                  ...item,
                  portLabel: `${item.name} ${
                    item.country ? "(" + item.country + ")" : ""
                  }`,
                }))) ||
              [],
          });
        })
        .catch((err) => {
          setPortLoading({ ...portLoading, [index]: false });
        });
    }
  };

  const onChangeNewFleetsValue = (index, name, value) => {
    let newAddFleetsArray = [...addFleetsArray];
    if (name === "vesselName") {
      newAddFleetsArray[index].vesselName = value.vesselName;
      newAddFleetsArray[index].selectedVessel = value;
    } else if (name === "port") {
      newAddFleetsArray[index].port = value.name;
      newAddFleetsArray[index].selectedPort = value;
      newAddFleetsArray[index].lat = value.lat || "";
      newAddFleetsArray[index].lng = value.lng || "";
    } else {
      newAddFleetsArray[index][name] = value;
    }
    setAddFleetsArray(newAddFleetsArray);
  };

  const checkForRequired = () =>
    addFleetsArray.find(
      (item) => !item.vesselName || !item.port || !String(item.dateOpen)
    );

  const onAddFleetLine = () => {
    if (!!checkForRequired()) return toastError("Please fill required fields");
    setAddFleetsArray([...addFleetsArray, { ...initialAddFleet }]);
  };

  const onDeleteVesselLine = (index) => {
    let newAddFleetsArray = [...addFleetsArray];
    newAddFleetsArray.splice(index, 1);
    setSaveVesselListByIndex({ ...saveVesselListByIndex, [index]: [] });
    setSavePortListByIndex({ ...savePortListByIndex, [index]: [] });
    setAddFleetsArray(newAddFleetsArray);
  };

  const removeExtraField = ({ selectedPort, selectedVessel, ...rest }) => ({
    ...rest,
  });

  const onSaveAllVessels = () => {
    if (autoSwitch) return onSubmitAutoFleet();
    if (!!checkForRequired()) return toastError("Please fill required fields");
    const resulted = addFleetsArray.map((item) => {
      return {
        ...removeExtraField(item),
        dateOpen: moment(item.dateOpen).toISOString(),
        vesselId: item.selectedVessel._id,
        vessel: JSON.stringify(item.selectedVessel),
      };
    });
    setAddFleetModal(false);
    setLoader(true);
    actions
      .addMultiFleet(resulted)
      .then((res) => {
        getAllFleets();
        setLoader(false);
        setAddFleetModal(false);
        toastSuccess("Vessel(s) added successfully");
        sendCalculationRequest({
          ...calculatorData,
          fleetList: [...calculatorData.fleetList, ...res],
        });
      })
      .catch((err) => {
        setAddFleetModal(false);
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onChangeAutoSwitch = (checked) => {
    setAutoSwitch(checked);
  };

  const updatedSinceLastOptions = () => {
    const arr = [];
    for (let i = 0; i < 30; i++) {
      arr.push({ label: String(i + 1), value: String(i + 1) });
    }
    return arr;
  };

  const onChangeAutoFilters = (name, value) => {
    setAutoFilters({ ...autoFilters, [name]: value });
  };

  const onSubmitAutoSearch = () => {
    const { updateSince, portRegion, vesselType, gear } = autoFilters || {};
    const modifiedFilters = {
      emailDateLower: moment()
        .subtract(Number(updateSince.value), "d")
        .startOf()
        .format("yyyy-MM-DD HH:MM:SS"),
      emailDateUpper: moment().startOf().format("yyyy-MM-DD HH:MM:SS"),
      dwtLower: Number(vesselType.value.split("_")[0]),
      dwtUpper: Number(vesselType.value.split("_")[1]),
      portRegion: portRegion.map((item) => item.value).join(","),
      orgId: getOrganizationId(),
      gear: gear.label,
    };
    setLoader(true);
    actions
      .getEmailsFleetList(modifiedFilters)
      .then((res) => {
        if (Array.isArray(res)) setEmailFleetList(res);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onSelectAllAutoFleet = ({ target: { checked } }) => {
    setAllSelected(checked);
    if (checked) {
      setSelectedAutoFleet(emailFleetList);
    } else {
      setSelectedAutoFleet([]);
    }
  };

  const onAutoFleetListCheckBox = (selectedItem) => {
    let updatedSelectedAutoFleet = [...selectedAutoFleet];
    const fleetIndex = updatedSelectedAutoFleet.findIndex(
      (item) => item._id === selectedItem._id
    );
    if (fleetIndex > -1) {
      updatedSelectedAutoFleet.splice(fleetIndex, 1);
    } else {
      updatedSelectedAutoFleet.push(selectedItem);
    }
    setSelectedAutoFleet(updatedSelectedAutoFleet);
  };

  const onChangeDateOpening = (index, selectedItem, date) => {
    let updatedSelectedAutoFleet = [...selectedAutoFleet];
    let updatedEmailFleetList = [...emailFleetList];

    const fleetIndex = updatedSelectedAutoFleet.findIndex(
      (item) => item._id === selectedItem._id
    );
    if (fleetIndex > -1) {
      updatedSelectedAutoFleet[fleetIndex].dateOpen = date;
      setSelectedAutoFleet(updatedSelectedAutoFleet);
    }
    updatedEmailFleetList[index].dateOpen = date;
    setEmailFleetList(updatedEmailFleetList);
  };

  const onSubmitAutoFleet = () => {
    const findWithoutDate =
      selectedAutoFleet.find((item) => !item.dateOpen) || false;
    if (!!findWithoutDate)
      return toastError("Please add date to selected vessels");
    let promiseArray = selectedAutoFleet.map((item) =>
      actions.getVesselsWithId(item.vesselId)
    );
    setAddFleetModal(false);
    setLoader(true);
    Promise.all(promiseArray)
      .then((responseArray) => {
        let newAddFleetsArray = responseArray.map((vessel, index) => {
          const { vesselName = "", vesselDwt = "", id = "" } = vessel;
          const { dateOpen = "", portName = "" } =
            selectedAutoFleet[index] || {};
          return {
            vesselName,
            dwt: vesselDwt,
            port: portName,
            dateOpening: moment(dateOpen).toISOString(),
            tcEq: 0,
            gbb: 0,
            hirePerDay: 0,
            hireGbb: 0,
            frtCost: 0,
            profit: 0,
            loadability: 0,
            arrivalLoadPort: "",
            extra_Exp: "",
            bod: 0,
            constan: 0,
            remark: "",
            vesselId: id,
            vessel: JSON.stringify(vessel),
          };
        });
        actions
          .saveToFleet(newAddFleetsArray)
          .then((res) => {
            setLoader(false);
            toastSuccess("Vessel(s) added successfully");
            sendCalculationRequest({
              ...calculatorData,
              fleetList: [...calculatorData.fleetList, ...res],
            });
          })
          .catch((err) => {
            setAddFleetModal(true);
            setLoader(false);
            toastError(err?.data?.message || "Something went wrong");
          });
      })
      .catch((err) => {
        setAddFleetModal(true);
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Grid container className="add-fleet-modal-container">
      <Grid item xs={12}>
        <Box sx={{ bgcolor: "background.paper" }}>
          <AppBar position="static" color="transparent">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              className="add-fleets-tabs"
              aria-label="full width tabs example"
            >
              <Tab label="Manual" {...a11yProps(0)} />
              <Tab label="Auto" {...a11yProps(1)} />
              <Tab label="Users" {...a11yProps(2)} />
              <Tab label="AIS" {...a11yProps(3)} />
              <Tab label="Markets" {...a11yProps(4)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            // onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <div style={{ minHeight: 220 }}>
                <AutoAddFleet
                  onAddFleetLine={onAddFleetLine}
                  addFleetsArray={addFleetsArray}
                  saveVesselListByIndex={saveVesselListByIndex}
                  onChangeNewFleetsValue={onChangeNewFleetsValue}
                  delayVesselSearch={delayVesselSearch}
                  vesselLoading={vesselLoading}
                  savePortListByIndex={savePortListByIndex}
                  delayPortSearch={delayPortSearch}
                  portLoading={portLoading}
                  onDeleteVesselLine={onDeleteVesselLine}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <div style={{ minHeight: 220 }}>
                <ManualAddFleet
                  updatedSinceLastOptions={updatedSinceLastOptions}
                  autoFilters={autoFilters}
                  onChangeAutoFilters={onChangeAutoFilters}
                  onSubmitAutoSearch={onSubmitAutoSearch}
                  emailFleetList={emailFleetList}
                  selectedAutoFleet={selectedAutoFleet}
                  onChangeDateOpening={onChangeDateOpening}
                  onAutoFleetListCheckBox={onAutoFleetListCheckBox}
                  allSelected={allSelected}
                  addFleetRegionOptions={addFleetRegionOptions}
                  onSelectAllAutoFleet={onSelectAllAutoFleet}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <div style={{ minHeight: 220 }}>
                <UserCopyFleet
                  setLoader={setLoader}
                  getInitalQueryAndFleetData={getInitalQueryAndFleetData}
                  onClickAddFleet={onClickAddFleet}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <div
                style={{
                  minHeight: 220,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Coming Soon
              </div>
            </TabPanel>
            <TabPanel value={value} index={4} dir={theme.direction}>
              <div
                style={{
                  minHeight: 220,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Coming Soon
              </div>
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Grid>
      {value === 0 || value === 1 ? (
        <Grid item xs={12}>
          <span className="save-all-vessel" onClick={onSaveAllVessels}>
            Save
          </span>
        </Grid>
      ) : null}
    </Grid>
  );
}
