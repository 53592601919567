import React, {
  useEffect,
  useState,
  memo,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Box, Grid, Paper, TextField, Typography, Button } from "@material-ui/core";
import * as actions from "./actions";
import * as dashboardActions from "../Dashboard/actions";
import OrdersList from "../../../components/Calculator/OrdersList";
import PortOperations from "../../../components/Calculator/PortOperations";
import VesselSelection from "../../../components/Calculator/VesselSelection";
import {
  initialAddFleet,
  initialCreatePortOperation,
  termsCoEfficientTable,
} from "../../../constants";
import {
  addPortDistanceToOperations,
  convertPortOperationToDistanceString,
  fleetWithCalculatorDataMapping,
  sendConsumptionsDataByCheckBox,
  calculationValidation,
  addPortDistanceToSelectedFleet,
} from "./dataMapping";
import Result from "../../../components/Calculator/Result";
import Expenses from "../../../components/Calculator/Expenses";
import AccountCargoDetails from "../../../components/Calculator/AccountCargoDetails";
import CalculatorVesselDetails from "../../../components/Calculator/CalculatorVesselDetails";
import MarginAndAllowances from "../../../components/Calculator/MarginAndAllowances";
import ActionButtons from "../../../components/Calculator/ActionButtons";
import { getObject, getOrganizationId, toastError, toastSuccess } from "../../../utils";
import _, { cloneDeep } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { PieChart } from "../../../components/Charts/PieChart";

function Matrix(props) {
  const {
    setIsFullScreen = () => { },
    setLoader = () => { },
    loader = false,
  } = props;
  const dispatch = useDispatch();
  const [hideOrder, setHideOrder] = useState(true);
  const [selectedQuery, setSelectedQuery] = useState({});

  const [calculatorData, setCalculatorData] = useState({
    portOperation: [],
    clientQuery: {},
    fleetList: [],
    cargoList: [],
    cargoExtraDetails: [],
    autoSave: false,
  });

  // reducer data in state
  const [allQueryFormat, setAllQueryFormat] = useState([]);

  // vessel
  const [selectedVessels, setSelectedVessels] = useState({});
  const [syncBunker, setSyncBunker] = useState(false);
  const [isOverride, setIsOverride] = useState(false);
  const [hideCalculator, setHideCalculator] = useState(false);
  const [fleetCheckbox, setFleetCheckbox] = useState({
    ballest: {
      1: true,
      2: true,
      3: true,
      4: true,
    },
    laden: {
      1: true,
      2: true,
      3: true,
      4: true,
    },
  });
  // const [startBunkerSync, setStartBunkerSync] = useState(false)
  const [addFleetsArray, setAddFleetsArray] = useState([
    { ...initialAddFleet },
  ]);
  const [portDistanceResponse, setPortDistanceResponse] = useState({});
  const [selectedSplitHire, setSelectedSplitHire] = useState({});
  const [initialFleetList, setInitialFleetList] = useState([]);
  const user = getObject("auth")
  //modals
  const [marginModal, setMarginModal] = useState(false);
  const [supplyModal, setSupplyModal] = useState(false);
  const [addFleetModal, setAddFleetModal] = useState(false);
  const [splitHireModal, setSplitHireModal] = useState(false);
  const delayUpdateAndSaveOperation = useCallback(
    _.debounce((data) => onUpdateAndSavePortDistance(data), 500),
    []
  );
  const [openAddOperation, setOpenAddOperation] = useState(false);
  const [openAddOperationIndex, setOpenAddOperationindex] = useState(false);
  const [getCurrentResult, setCurrentResult] = useState({});
  const [pdfdata, setPdfData] = useState({});
  const [voyagesDurationData, setVoyagesDurationData] = useState([])
  const [voyagesExpensesBreakupData, setVoyagesExpensesBreakupData] = useState([])
  const [expandedRows, setExpandedRows] = useState({}); // Track expanded rows
  // const [openMap, setOpenMap] = useState(false)

  //refs
  const fetchPortDistance = useRef(true);

  useEffect(() => {
    setIsFullScreen(true);
    getInitalQueryAndFleetData();
    return () => {
      setIsFullScreen(false);
    };
  }, []);

  const getInitalQueryAndFleetData = () => {
    setLoader(true);
    Promise.all([
      actions.getQueryFormat({ isFromCalc: true }),
      actions.allFleetCalculator(),
      // dispatch(
      //   dashboardActions.usersForOrg({ organizationId: getOrganizationId() })
      // ),
    ])
      .then((res) => {
        const response1 = res[0].data.list;
        const response2 = res[1].data.list;
        const newResponse1 =
          (Array.isArray(response1) && response1.filter((item) => !!item)) ||
          [];
        setInitialFleetList(response2);
        setInitialFetchedData(newResponse1, response2, true);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  };

  const calculationErrors = useMemo(() => {
    let errors = [];
    if (
      selectedVessels &&
      calculatorData.portOperation.length &&
      calculatorData.fleetList.length
    ) {
      errors = calculationValidation(calculatorData, selectedVessels);
    }
    return errors;
  }, [calculatorData, selectedVessels]);

  const setInitialFetchedData = async (mainRes1, mainRes2, initial = false) => {
    fetchPortDistance.current = true;
    let selectedQuery = {};
    if (initial && Array.isArray(mainRes1) && mainRes1.length) {
      setAllQueryFormat(mainRes1);
      selectedQuery = mainRes1[0];
    }
    if (!initial) selectedQuery = mainRes1;
    setSelectedQuery(selectedQuery);
    if (selectedQuery?._id) {
      if (!loader) setLoader(true);

      let combinedCargo = [];
      let formattedPortOperations = [];

      combinedCargo = selectedQuery.cargoList;

      // set Order according to selectedQuery calculationFormat
      const portOperationOrder =
        (selectedQuery.calculationFormat &&
          selectedQuery.calculationFormat) ||
        [];

      // if (portOperationOrder.length > 0) {
      //   for (let i = 0; i < portOperationOrder.length; i++) {
      //     const portElement = combinedCargo.find(
      //       (item) => item._id === portOperationOrder[i]
      //     );
      //     if (!!portElement) {
      //       formattedPortOperations.push({ ...portElement, index: i });
      //     }
      //   }
      // } else {
      // }
      formattedPortOperations = combinedCargo.map((portElement, i) => ({
        ...portElement,
        index: i,
      }));
      if (!selectedQuery.fuelCalculationMethod)
        selectedQuery.fuelCalculationMethod = "DEFAULT";
      if (!selectedQuery.fuelType) selectedQuery.fuelType = "TYPE0";

      if (
        selectedQuery.fuelCalculationMethod === "LIFO" ||
        selectedQuery.fuelCalculationMethod === "FIFO" ||
        selectedQuery.fuelCalculationMethod === "AVERAGE"
      ) {
        setSyncBunker(true);
      }

      if (selectedQuery.fuelCalculationMethod === "DEFAULT") {
        const currentType = selectedQuery.fuelType.replace("TYPE", "");
        formattedPortOperations = formattedPortOperations.map((item) => {
          return {
            ...item,
            cargoDetails: {
              ...item?.cargoDetails,
              fuelPriceType0Hsfo: 0,
              fuelPriceType0Lsfo: 0,
              fuelPriceType0Mgo: 0,
              fuelPriceType1Hsfo: 0,
              fuelPriceType1Lsfo: 0,
              fuelPriceType1Mgo: 0,
              fuelPriceType2Hsfo: 0,
              fuelPriceType2Lsfo: 0,
              fuelPriceType2Mgo: 0,
              fuelPriceType3Hsfo: 0,
              fuelPriceType3Lsfo: 0,
              fuelPriceType3Mgo: 0,
              fuelPriceType4Hsfo: 0,
              fuelPriceType4Lsfo: 0,
              fuelPriceType4Mgo: 0,
            },
          };
        });
      }

      // make calculator data add port operations
      const updatedData = {
        ...calculatorData,
        clientQuery: selectedQuery,
        portOperation: formattedPortOperations,
      };
      if (initial) updatedData.fleetList = cloneDeep(mainRes2);
      // setCalculatorData(updatedDataMain)
      sendCalculationRequest(updatedData);
    } else {
      setLoader(false);
    }
  };

  const onChangePortOperations = (index, e, cargoUpdate = false) => {
    let { value = "", name = "" } = e.target;
    let portOperation = [...calculatorData.portOperation];

    if (name === "terms") {
      const termFactor = termsCoEfficientTable.find(
        (item) => item.label === value
      );
      portOperation[index]["terms"] = value;
      portOperation[index]["factor"] = (termFactor && termFactor.value) || 0;
    } else if (cargoUpdate) {
      portOperation[index].cargoDetails[name] = value;
    } else {
      portOperation[index][name] = value;
    }

    let updatedCalculatorData = { ...calculatorData, portOperation };

    updatedCalculatorData.clientQuery.portToPortDistance.output[`${portOperation[index - 1]?.port}_${portOperation[index].port}`][name] = value
    if (name === "distanceNormal" || name === "distanceECA") {
      const updateData = {
        distEca: portOperation[index].distanceECA,
        distNormal: portOperation[index].distanceNormal,
        id: portOperation[index]._id,
      };
      const saveData = {
        distEca: portOperation[index].distanceECA,
        distNormal: portOperation[index].distanceNormal,
        openingPort:
          (portOperation[index - 1] && portOperation[index - 1]["port"]) || "",
        endingPort: portOperation[index]["port"] || "",
      };
      delayUpdateAndSaveOperation({
        updateData,
        saveData,
        updatedCalculatorData,
      });
    }
    setCalculatorData(updatedCalculatorData);
    onChangeChartValue(updatedCalculatorData)

  };

  const onUpdateAndSavePortDistance = ({
    updateData = {},
    saveData = {},
    updatedCalculatorData = {},
  }) => {
    setLoader(true);
    Promise.all([
      // actions.updateDistance(updateData),
      actions.savePortDistance(saveData),
    ])
      .then(() => {
        setLoader(false);
        sendCalculationRequest(updatedCalculatorData);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong.");
      });
  };

  const sendCalculationRequest = async (sendData) => {
    let portDistanceRes = portDistanceResponse;
    if (fetchPortDistance.current) {
      portDistanceRes = await onFetchAndSetIntitalPortDistance(sendData);
    }
    sendData = {
      ...sendData,
      portOperation: addPortDistanceToOperations(portDistanceRes, [
        ...sendData.portOperation,
      ]),
    };

    let fleetSelected = {};
    const updatedFleedIds = sendData.fleetList.map((fleetDetails) => ({
      ...fleetDetails,
      vesselUniqueId: uuidv4(),
    }));

    let modifiedData = {
      cargoList: sendData.portOperation.filter((item) => !item.isExtra),
      cargoExtraDetails: sendData.portOperation.filter(
        (item) => !!item.isExtra
      ),
      fleetList: cloneDeep(updatedFleedIds),
      clientQuery: {
        ...sendData.clientQuery,
        calculationFormat: sendData.portOperation
          .map((item) => item._id)
          .join(","),
      },
      autoSave: false,
    };
    await calculationValidation(modifiedData, selectedVessels)
    if (!loader) setLoader(true);
    const res = await sendCalculatorResponse(modifiedData);
    setLoader(false);
    const fleetData = fleetWithCalculatorDataMapping(
      cloneDeep(updatedFleedIds),
      res?.data
    );
    if (selectedVessels && selectedVessels._id)
      fleetSelected =
        fleetData.find((item) => item._id === selectedVessels._id) || {};
    else fleetSelected = fleetData[0];
    onChangeFleetRowSelect(
      fleetSelected,
      { ...sendData, fleetList: fleetData },
      true,
      true,
      true
    );
    const {
      __v,
      version,
      system,
      createdUser,
      vesselId,
      vesselName,
      userId,
      lastModifiedUser,
      lastModifiedDate,
      ...restClientQuery
    } = calculatorData.clientQuery;

    const newUpdatedQuery = {
      ...restClientQuery,
      cargoList: calculatorData.portOperation,
      createdUser: createdUser?._id,
    };

    const newUpdatedFleet = { ...fleetSelected };

    if (sendData.autoSave) {
      saveAllResultUpdatedData({
        newUpdatedFleet,
        newUpdatedQuery,
      });
    }
  };

  const saveAllResultUpdatedData = ({ newUpdatedFleet, newUpdatedQuery }) => {
    Promise.all([
      actions.updateQueryDetails(newUpdatedQuery),
      actions.updateFleetData(newUpdatedFleet),
    ])
      .then((res) => {
        if (res?.[0]?.success && res?.[1]?.success) {
          getInitalQueryAndFleetData();
          toastSuccess("Calculation updated and saved successfully!");
        }
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  };

  const sendCalculatorResponse = (modifiedData = {}) => {
    return new Promise((resolve, reject) => {
      if (modifiedData.fleetList.length === 0) return resolve({});
      const updatedCargoList = modifiedData.cargoList.map((cargoDetails) => {
        const { _id, ...restCargoMainDetails } = cargoDetails;
        const { _id: cargoDetailId, ...restCargoDetail } =
          cargoDetails?.cargoDetails;
        return {
          ...restCargoMainDetails,
          cargoDetails: { ...restCargoDetail },
        };
      });

      const {
        _id,
        cargoList,
        createdUser,
        lastModifiedUser,
        userId,
        calculationFormat,
        __v,
        createdAt,
        updatedAt,
        isCalculate,
        ...restClientQuery
      } = modifiedData.clientQuery;

      const updatedClientQuery = {
        ...restClientQuery,
        cargoList: null,
      };

      const updatedFleetListData =
        modifiedData?.fleetList?.length > 0
          ? modifiedData.fleetList.map((fleetDetails, index) => {
            const vesselDetails = !!fleetDetails?.vessel
              ? JSON.parse(fleetDetails.vessel)
              : {};
            const {
              _id: parseVesselId,
              userId: parseVesselUserId,
              createdUser: parseVesselCreatedUser,
              lastModifiedUser: parseVesselModifiedUser,
              vesselExtraDetails,
              __v: parseVessel__v,
              createdAt,
              createdDate,
              fuelType,
              lastModifiedDate,
              pattern,
              updatedAt,

              vesselLabel,
              vesselAHL,
              vesselCO2,

              vesselCountry,
              vesselGrabs,
              vesselIceClass,
              vesselIMO,
              vesselLdnConsmMGO1,
              vesselLdnConsmMGO2,
              vesselLightShip,
              vesselMMSI,
              vesselType,
              vesselSource,
              vesselName,
              ...parseVesselDetails
            } = vesselDetails;
            const {
              _id,
              vesselId,
              createdUser,
              organizationId,
              userId,
              lastModifiedUser,
              vesselUniqueId,
              __v,
              ...rest
            } = fleetDetails;
            const uniqueId = vesselUniqueId;

            return {
              ...rest,
              vessel: JSON.stringify({
                id: uniqueId,
                ...parseVesselDetails,
                system: true,
              }),
              vesselId: uniqueId,
            };
          })
          : [];
      const updatedCargoExtraDetails = modifiedData.cargoExtraDetails.map(
        (cargoextraDetails) => {
          const { _id, ...restCargoMainDetails } = cargoextraDetails;
          const { _id: cargoDetailId, ...restCargoDetail } =
            cargoextraDetails.cargoDetails;
          return {
            ...restCargoMainDetails,
            cargoDetails: { ...restCargoDetail },
          };
        }
      );

      const calculationData = {
        autoSave: false,
        cargoExtraDetails: updatedCargoExtraDetails,
        cargoList: updatedCargoList,
        clientQuery: updatedClientQuery,
        fleetList: updatedFleetListData,
      };

      actions
        .sendCalculatorData(calculationData)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          resolve({});
          toastError(err?.data?.message || "Something went wrong");
        });
    });
  };

  const onChangeFleetRowSelect = async (
    fleet = {},
    resultCalculatorData = null,
    changeVessel = false,
    isPortDistanceChanged = false,
    isFromCalculateButton = false
  ) => {
    let updatedCalculatorData = {};
    if (resultCalculatorData) updatedCalculatorData = resultCalculatorData;
    else updatedCalculatorData = { ...calculatorData };
    let commencePort = (fleet && fleet.port) || "";
    let fleetFuelType =
      (fleet && fleet.vessel && JSON.parse(fleet.vessel)) || {};
    fleetFuelType = fleetFuelType.fuelType || "TYPE0";
    let portOperation = [...updatedCalculatorData.portOperation];

    const portToPortOperationResultMap =
      (fleet && fleet.portToPortOperationResultMap) || [];
    portOperation = portOperation.map((operation, index) => {
      const selectedResultMap = portToPortOperationResultMap[index] || {};
      const removeDistanceFromResultMap = ({
        distanceECA = "",
        distanceNormal = "",
        ...rest
      }) => {
        return { ...rest };
      };
      if (index !== 0) commencePort = (operation && operation.port) || "";
      let isTermExists = termsCoEfficientTable[0];
      if (operation.terms) {
        isTermExists = termsCoEfficientTable.find(
          (item) => item.label === operation.terms
        );
      }
      let cargoDetails = { ...operation.cargoDetails };

      if (index === 0) {
        const currentType = fleetFuelType.replace("TYPE", "");
        const {
          bnkDelhs = 0,
          bnkDells = 0,
          bnkDelmgo = 0,
          bnkPriceDeliveryhs = 0,
          bnkPriceDeliveryls = 0,
          bnkPriceDeliverymgo = 0,
        } = fleet;
        cargoDetails = {
          ...cargoDetails,
          bunkerSuppliedHSFO: bnkDelhs,
          bunkerSuppliedLSFO: bnkDells,
          bunkerSuppliedMGO: bnkDelmgo,
          [`fuelPriceType${currentType}Hsfo`]: bnkPriceDeliveryhs,
          [`fuelPriceType${currentType}Lsfo`]: bnkPriceDeliveryls,
          [`fuelPriceType${currentType}Mgo`]: bnkPriceDeliverymgo,
        };
      }
      return {
        ...operation,
        ...removeDistanceFromResultMap(selectedResultMap),
        cargoDetails,
        port: commencePort,
        terms: isTermExists.label,
        factor: isTermExists.value,
      };
    });

    if (!isPortDistanceChanged) {
      let portDistanceRes = portDistanceResponse;
      if (fetchPortDistance.current) {
        portDistanceRes = await onFetchAndSetIntitalPortDistance(
          updatedCalculatorData
        );
      }
      portOperation = addPortDistanceToOperations(portDistanceRes, [
        ...portOperation,
      ]);
    }
    // add post distance to selected fleet as well
    fleet = addPortDistanceToSelectedFleet(portOperation, fleet);

    if (changeVessel || !selectedVessels._id) {
      setSelectedVessels(fleet);
    }
    if (isFromCalculateButton) {
      setCurrentResult({
        ...updatedCalculatorData,
        clientQuery: {
          ...updatedCalculatorData.clientQuery,
          fuelType: fleetFuelType,
        },
        fleetList: updatedCalculatorData.fleetList.map((singleFleet) => {
          if (singleFleet._id === fleet._id) return fleet;
          else return singleFleet;
        }),
        portOperation,
      })

    }
    setCalculatorData({
      ...updatedCalculatorData,
      clientQuery: {
        ...updatedCalculatorData.clientQuery,
        fuelType: fleetFuelType,
      },
      fleetList: updatedCalculatorData.fleetList.map((singleFleet) => {
        if (singleFleet._id === fleet._id) return fleet;
        else return singleFleet;
      }),
      portOperation,
    });
    onChangeChartValue({
      ...updatedCalculatorData,
      clientQuery: {
        ...updatedCalculatorData.clientQuery,
        fuelType: fleetFuelType,
      },
      fleetList: updatedCalculatorData.fleetList.map((singleFleet) => {
        if (singleFleet._id === fleet._id) return fleet;
        else return singleFleet;
      }),
      portOperation,
    });


  };

  const onChangePortCanalInQueryState = (portCanalChecks) => {
    const findIndexQuery = allQueryFormat.findIndex(
      (query) => query._id === selectedQuery?._id
    );

    if (findIndexQuery > -1) {
      const updatedAllQueryFormat = [...allQueryFormat];
      updatedAllQueryFormat[findIndexQuery] = {
        ...updatedAllQueryFormat[findIndexQuery],
        ...portCanalChecks,
      };
      setAllQueryFormat(updatedAllQueryFormat);
      setSelectedQuery({
        ...updatedAllQueryFormat[findIndexQuery],
        ...portCanalChecks,
      });
    }
  };

  const onFetchPortOperationDistance = (
    reset = false,
    propCalculator = null,
    portCanalChecks = {}
  ) => {
    let updatedCalculatorData = { ...calculatorData };
    if (propCalculator) {
      updatedCalculatorData = { ...propCalculator };
    }
    const { getPortDistanceString, routeList } =
      convertPortOperationToDistanceString(updatedCalculatorData);

    const { clientQuery = {} } = updatedCalculatorData;
    const {
      eca = false,
      hra = false,
      jwc = false,
      suez = false,
      panama = false,
      singapore = false,
    } = clientQuery;
    const portDataObj = {
      eca,
      hra,
      jwc,
      suez,
      panama,
      singapore,
      routeOverride: false,

      getGeometry: false,
      ...portCanalChecks,
      routeList: routeList,
      portList: getPortDistanceString,
      clientQueryId: updatedCalculatorData.clientQuery._id,
      queryId: updatedCalculatorData.clientQuery._id,
    };

    let request = "";

    // change port selection the query as well
    onChangePortCanalInQueryState(portCanalChecks);

    if (reset) request = actions.resetDistance(portDataObj);
    else request = actions.getPortDistanceByPortList(portDataObj);

    if (!loader) setLoader(true);
    request
      .then((res) => {
        setLoader(false);
        setPortDistanceResponse(res.data);
        const newPortOperations = addPortDistanceToOperations(res.data, [
          ...updatedCalculatorData.portOperation,
        ]);
        sendCalculationRequest({
          ...updatedCalculatorData,
          portOperation: newPortOperations,
        });
      })
      .catch((err) => setLoader(false));
  };

  const onFetchAndSetIntitalPortDistance = (propCalculator) => {
    if (!fetchPortDistance.current) return;
    let updatedCalculatorData = { ...propCalculator };
    const { getPortDistanceString, routeList } =
      convertPortOperationToDistanceString(updatedCalculatorData);
    setLoader(true);

    return new Promise((resolve, reject) => {
      const { clientQuery = {} } = updatedCalculatorData;
      const {
        eca = false,
        hra = false,
        jwc = false,
        suez = false,
        panama = false,
        singapore = false,
      } = clientQuery;
      const portDataObj = {
        eca,
        hra,
        jwc,
        suez,
        panama,
        singapore,
        routeOverride: false,
        routeList: routeList,
        portList: getPortDistanceString,
        clientQueryId: updatedCalculatorData.clientQuery._id,
        queryId: updatedCalculatorData.clientQuery._id,
        getGeometry: false,
      };
      actions
        .getPortDistanceByPortList(portDataObj)
        .then((res) => {
          fetchPortDistance.current = false;
          setPortDistanceResponse(res.data);
          resolve(res?.data);
        })
        .catch((err) => {
          setLoader(false);
          resolve({});
          toastError(err?.data?.message || "Something went wrong");
        });
    });
  };

  const onAddOperations = (requiredData = {}) => {
    setLoader(true);
    fetchPortDistance.current = true;
    actions
      .addCargoExtraDetails({
        details: {
          ...initialCreatePortOperation,
          ...requiredData,
        },
        queryId: selectedQuery?._id,
        addIndex: openAddOperationIndex,
      })
      .then((response) => {
        const updatedSelectedQuery = {
          ...selectedQuery,
          calculationFormat: response.data.calculationFormat,
        };
        setLoader(false);

        const updatedCalculatorData = {
          ...calculatorData,
          clientQuery: updatedSelectedQuery,
          portOperation: response.data.cargoList,
        };

        sendCalculationRequest(updatedCalculatorData);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onDeleteOperations = (index, id) => {
    setLoader(true);
    actions
      .deleteCargoExtraDetail({ queryId: selectedQuery?._id, cargoId: id })
      .then((res) => {
        setLoader(false);
        let portOperation = [...calculatorData.portOperation];
        portOperation.splice(index, 1);
        const updatedSelectedQuery = {
          ...selectedQuery,
          calculationFormat: res.data.calculationFormat,
        };

        setCalculatorData({
          ...calculatorData,
          portOperation,
          clientQuery: updatedSelectedQuery,
        });
        onChangeChartValue({
          ...calculatorData,
          portOperation,
          clientQuery: updatedSelectedQuery,
        })

      })
      .catch((err) => setLoader(false));
  };

  const onClickCalculate = () => {
    sendCalculationRequest({ ...calculatorData, autoSave: false });
  };
  const onClickCalculateAndSave = async () => {
    await sendCalculationRequest({ ...calculatorData, autoSave: true });
  };

  const onChangeSelectedVessel = (e, isFleetEdit = false) => {
    const {
      name = "",
      value = "",
      type = "number",
      checked = false,
    } = e.target || {};
    let newUpdatedFleet = { ...selectedVessels };
    const { fuelType = "" } = calculatorData.clientQuery || {};
    let updatedvessel = { ...JSON.parse(newUpdatedFleet.vessel) };

    if (isFleetEdit) {
      newUpdatedFleet[name] = value;
    } else {
      if (type === "checkbox") {
        updatedvessel[name] = checked;
        if (name === "vesselBalComb1Selected" || name === "vesselBalComb2Selected" || name === "vesselBalComb3Selected" || name === "vesselBalComb4Selected") {
          if (!updatedvessel["vesselBalComb1Selected"] && !updatedvessel["vesselBalComb2Selected"] && !updatedvessel["vesselBalComb3Selected"] && !updatedvessel["vesselBalComb4Selected"]) {
            return
          }
        }
        else if (name === "vesselLadComb1Selected" || name === "vesselLadComb2Selected" || name === "vesselLadComb3Selected" || name === "vesselLadComb4Selected") {
          if (!updatedvessel["vesselLadComb1Selected"] && !updatedvessel["vesselLadComb2Selected"] && !updatedvessel["vesselLadComb3Selected"] && !updatedvessel["vesselLadComb4Selected"]) {
            return
          }
        }
      } else {
        updatedvessel[name] = value;
      }
    }
    const updatedFleet = {
      ...newUpdatedFleet,
      vessel: JSON.stringify(updatedvessel),
    };
    setSelectedVessels(updatedFleet);
    const updatedFleetList = calculatorData.fleetList.map((item) => {
      if (item._id === updatedFleet._id) {
        return updatedFleet;
      }
      return item;
    });
    let updatedPortOperation = [...calculatorData.portOperation];
    if (
      name === "bnkPriceDeliveryhs" ||
      name === "bnkPriceDeliveryls" ||
      name === "bnkPriceDeliverymgo"
    ) {
      const currentType = fuelType.replace("TYPE", "");
      if (name === "bnkPriceDeliveryhs")
        updatedPortOperation[0].cargoDetails[
          `fuelPriceType${currentType}Hsfo`
        ] = value;
      if (name === "bnkPriceDeliveryls")
        updatedPortOperation[0].cargoDetails[
          `fuelPriceType${currentType}Lsfo`
        ] = value;
      if (name === "bnkPriceDeliverymgo")
        updatedPortOperation[0].cargoDetails[`fuelPriceType${currentType}Mgo`] =
          value;
    }
    if (name === "bnkDelhs" || name === "bnkDells" || name === "bnkDelmgo") {
      if (updatedPortOperation.length) {
        if (name === "bnkDelhs")
          updatedPortOperation[0].cargoDetails.bunkerSuppliedHSFO = value;
        if (name === "bnkDells")
          updatedPortOperation[0].cargoDetails.bunkerSuppliedLSFO = value;
        if (name === "bnkDelmgo")
          updatedPortOperation[0].cargoDetails.bunkerSuppliedMGO = value;
      }
    }
    setCalculatorData({
      ...calculatorData,
      portOperation: updatedPortOperation,
      fleetList: updatedFleetList,
    });

  };

  // const onChangeSelectedFleet = (e) => {
  //     const { name = '', value = '' } = e.target || {}
  //     let updatedFleet = { ...selectedVessels }
  //     updatedFleet[name] = value
  //     setSelectedVessels(updatedFleet)
  // }

  const onRemoveOrderQuery = (id) => {
    // const queryIdMap = allQueryFormat
    //   .map((item) => item._id)
    //   .filter((item) => item._id !== id);
    // setLoader(true);
    // actions
    //   .updateQueryFormat(queryIdMap)
    //   .then((res) => {
    //     setLoader(false);
    //     if (id === selectedQuery?._id) {
    //       setSelectedQuery({});
    //     }
    //     setAllQueryFormat(allQueryFormat.filter((item) => item._id !== id));
    //   })
    actions.updateChangeSwitchQuery({
      id: id,
      isShowInCalculator: false,
    })
      .then(async (res) => {
        getInitalQueryAndFleetData();
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onDeleteFleet = (fleetId) => {
    setLoader(true);
    actions
      .deleteFleet(fleetId)
      .then((res) => {
        toastSuccess("Vessel deleted successfully");
        actions
          .allFleetCalculator()
          .then((res) => {
            setLoader(false);
            // if (Array.isArray(res)) setAllFleets(res)
            if (Array.isArray(res.data.list)) {
              sendCalculationRequest({
                ...calculatorData,
                fleetList: res.data.list,
              });
            }
          })
          .catch((err) => {
            setLoader(false);
            toastError(err?.data?.message || "Something went wrong");
          });
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onChangeSyncBunker = (checked) => {
    let updatedClientQuery = { ...calculatorData.clientQuery };
    if (!checked && updatedClientQuery.fuelCalculationMethod !== "DEFAULT")
      return toastError("Please change fuel calculation method");
    // setSyncBunker(checked)

    let updatedCalculatorData = { ...calculatorData };

    let clientQuery = {
      ...updatedCalculatorData.clientQuery,
      fuelPriceOverridden: checked,
    };
    if (!checked) {
      clientQuery = {
        ...updatedCalculatorData.clientQuery,
        fuelPriceOverridden: checked,
        fuelCalculationMethod: "DEFAULT",
      };
    }

    updatedCalculatorData = {
      ...updatedCalculatorData,
      clientQuery,
    };
    setCalculatorData(updatedCalculatorData);
    // onChangeChartValue(updatedCalculatorData)
  };

  const onClickAddFleet = () => {
    setAddFleetModal((prev) => !prev);
  };

  const openSplitHireModal = (fleet) => {
    setSelectedSplitHire(fleet);
    setSplitHireModal(true);
  };

  const onSetHideCalculator = () => {
    setHideCalculator(!hideCalculator);
  };

  const onChangeOverride = (value) => {
    if (!value) {
      const {
        clientQuery = {},
        portOperation,
        fleetList,
      } = { ...calculatorData } || {};
      let {
        fuelCalculationMethod = "",
        fuelType = "TYPE0",
        lsFuelPriceType0 = 0,
        lsFuelPriceType1 = 0,
        lsFuelPriceType2 = 0,
        lsFuelPriceType3 = 0,
        lsFuelPriceType4 = 0,
        hsFuelPriceType0 = 0,
        hsFuelPriceType1 = 0,
        hsFuelPriceType2 = 0,
        hsFuelPriceType3 = 0,
        hsFuelPriceType4 = 0,
        mgoFuelPriceType0 = 0,
        mgoFuelPriceType1 = 0,
        mgoFuelPriceType2 = 0,
        mgoFuelPriceType3 = 0,
        mgoFuelPriceType4 = 0,
      } = clientQuery || {};

      let fuelTypePrices = {
        TYPE0: {
          normal: hsFuelPriceType0,
          eca: lsFuelPriceType0,
          mgo: mgoFuelPriceType0,
        },
        TYPE1: {
          normal: hsFuelPriceType1,
          eca: lsFuelPriceType1,
          mgo: mgoFuelPriceType1,
        },
        TYPE2: {
          normal: hsFuelPriceType2,
          eca: lsFuelPriceType2,
          mgo: mgoFuelPriceType2,
        },
        TYPE3: {
          normal: hsFuelPriceType3,
          eca: lsFuelPriceType3,
          mgo: mgoFuelPriceType3,
        },
        TYPE4: {
          normal: hsFuelPriceType4,
          eca: lsFuelPriceType4,
          mgo: mgoFuelPriceType4,
        },
      };

      const selectedFuelPrice = fuelTypePrices[fuelType];
      const newPortOperation = [...portOperation];
      const currentType = fuelType.replace("TYPE", "");
      newPortOperation[0].cargoDetails[`fuelPriceType${currentType}Hsfo`] =
        selectedFuelPrice.normal;
      newPortOperation[0].cargoDetails[`fuelPriceType${currentType}Lsfo`] =
        selectedFuelPrice.eca;
      newPortOperation[0].cargoDetails[`fuelPriceType${currentType}Mgo`] =
        selectedFuelPrice.mgo;

      let newSelectedVessel = {
        ...selectedVessels,
        bunkerPriceOverridden: false,
        bnkPriceDeliveryhs: selectedFuelPrice.normal,
        bnkPriceDeliveryls: selectedFuelPrice.eca,
        bnkPriceDeliverymgo: selectedFuelPrice.mgo,
        bnkPriceRedeliveryhs: selectedFuelPrice.normal,
        bnkPriceRedeliveryls: selectedFuelPrice.eca,
        bnkPriceRedeliverymgo: selectedFuelPrice.mgo,
        bnkPriceSettlemenths: selectedFuelPrice.normal,
        bnkPriceSettlementls: selectedFuelPrice.eca,
        bnkPriceSettlementmgo: selectedFuelPrice.mgo,
      };

      let updatedFleets = fleetList.map((item) => {
        if (item._id === newSelectedVessel._id) return newSelectedVessel;
        return item;
      });
      setSelectedVessels(newSelectedVessel);
      setCalculatorData({
        ...calculatorData,
        portOperation: newPortOperation,
        fleetList: updatedFleets,
      });
      // onChangeChartValue({
      //   ...calculatorData,
      //   portOperation: newPortOperation,
      //   fleetList: updatedFleets,
      // });
    } else {
      const { fleetList } = { ...calculatorData } || {};
      let newSelectedVessel = {
        ...selectedVessels,
        bunkerPriceOverridden: true,
      };

      let updatedFleets = fleetList.map((item) => {
        if (item._id === newSelectedVessel._id) return newSelectedVessel;
        return item;
      });
      setSelectedVessels(newSelectedVessel);
      setCalculatorData({ ...calculatorData, fleetList: updatedFleets });
      // onChangeChartValue({ ...calculatorData, fleetList: updatedFleets });
    }
    setIsOverride(value);
  };

  const onSetOpenMap = () => {
    if (selectedQuery?._id) {
      window.open(`/map/${selectedQuery?._id}`);
    }
  };

  const onChangeChartValue = (calculatorData, isExpanded, vessel) => {
    const id = Object.keys(expandedRows)[0];
    const vesselIndex = calculatorData?.fleetList?.findIndex((i) => i?._id?.toString() == id)
    if (vesselIndex == -1 && !isExpanded) {
      setVoyagesDurationData([])
      setVoyagesExpensesBreakupData([])
      return
    }
    vessel = vessel ? vessel : calculatorData?.fleetList?.[vesselIndex]
    if (vessel?.portStayTotal || vessel?.ballastSeaDuration || vessel?.ladenSeaDuration)
      setVoyagesDurationData([
        { label: "Port Stay Total", value: vessel?.portStayTotal },
        { label: "Ballast Sea Duration", value: vessel?.ballastSeaDuration },
        { label: "Laden Sea Duration", value: vessel?.ladenSeaDuration }])
    let totalPortExpenses = 0
    for (let i = 0; i <= calculatorData?.portOperation?.length; i++) {
      totalPortExpenses += calculatorData?.portOperation[i]?.portExp ? Number(calculatorData?.portOperation[i]?.portExp) : 0
    }
    let totalExpenses = Number(vessel?.netFrtRevenue) + Number(calculatorData?.clientQuery?.miscRevenue) - (vessel?.profitNet < 0 ? (-1 * parseInt(vessel?.profitNet)) : parseInt(vessel?.profitNet))
    if (vessel?.fuelExpense || vessel?.hireExpense || totalPortExpenses)
      setVoyagesExpensesBreakupData([
        { label: "Fuel Expenses", value: vessel?.fuelExpense || 0 },
        { label: "Hire Expenses", value: vessel?.hireExpense || 0 },
        { label: "Total Port Expenses", value: totalPortExpenses || 0 },
        {
          label: "Others Expenses", value: (totalExpenses) -
            (vessel?.hireExpense < 0 ? (-1 * parseInt(vessel?.hireExpense || 0)) : parseInt(vessel?.hireExpense || 0))
            - (vessel?.fuelExpense < 0 ? (-1 * parseInt(vessel?.fuelExpense || 0)) : parseInt(vessel?.fuelExpense || 0))
            - (totalPortExpenses < 0 ? (-1 * parseInt(totalPortExpenses || 0)) : parseInt(totalPortExpenses || 0))
        }
      ]
      )
  }

  return (
    <div className="calculator-section flex flex-col w-full">
      {/* first UI part orders selector */}
      <div className="flex flex-row">
        {/* header */}
        {/* {hideOrder ? null : ( */}
        <>
          <div className={`calc-order-section w-[13%] slideInRightMedium second-sidebar-box ${hideOrder ? "" : "second-sidebar-box-active"}`}>
            <div className="flex flex-col gap-3">
              <div className="mt-2 typo-16-500 text-center">
                {" "}
                Select a Query
              </div>
              <OrdersList
                onRemoveOrderQuery={onRemoveOrderQuery}
                allQueryFormat={allQueryFormat}
                selectedQuery={selectedQuery}
                setSelectedQuery={setSelectedQuery}
                onChangeSelectedQuery={setInitialFetchedData}
                setHideOrder={setHideOrder}
              />
            </div>
          </div>
          <span className="trigger-close-sidebar" onClick={() => setHideOrder((prev) => !prev)} ></span>
        </>
        <div
          className={clsx("flex flex-col main-content-wrapper-inner", {
            "w-full": hideOrder,
            "w-[87%]": !hideOrder,
          })}
        >
          <div className="quick-menu-bar">
            <ActionButtons
              hideOrder={hideOrder}
              setHideOrder={setHideOrder}
              onSetOpenMap={onSetOpenMap}
              onClickCalculate={onClickCalculate}
              onSetHideCalculator={onSetHideCalculator}
              onClickCalculateAndSave={onClickCalculateAndSave}
              pdfdata={pdfdata}
              isFromMatrix
            />
          </div>
          {/* secound UI part */}
          <div>
            {!selectedQuery?._id ? (
              <div className="h-100">
                <div className="flex">
                  <p className="m-a text-xl">Please select a query.</p>
                </div>
              </div>
            ) : (
              <div className="calc-secound-section p-2 pt-1">
                <div>
                  <div className="bottom-section-grid">
                    <VesselSelection
                      selectedVessels={selectedVessels}
                      onFetchPortOperationDistance={
                        onFetchPortOperationDistance
                      }
                      selectedQuery={selectedQuery}
                      onChangeFleetRowSelect={onChangeFleetRowSelect}
                      onDeleteFleet={onDeleteFleet}
                      onClickAddFleet={onClickAddFleet}
                      addFleetModal={addFleetModal}
                      addFleetsArray={addFleetsArray}
                      setAddFleetsArray={setAddFleetsArray}
                      calculatorData={calculatorData}
                      setCalculatorData={setCalculatorData}
                      setLoader={setLoader}
                      setAddFleetModal={setAddFleetModal}
                      sendCalculationRequest={sendCalculationRequest}
                      setSelectedVessels={setSelectedVessels}
                      splitHireModal={splitHireModal}
                      setSplitHireModal={setSplitHireModal}
                      selectedSplitHire={selectedSplitHire}
                      setSelectedSplitHire={setSelectedSplitHire}
                      openSplitHireModal={openSplitHireModal}
                      getInitalQueryAndFleetData={getInitalQueryAndFleetData}
                      initialFleetList={initialFleetList}

                      onChangeSelectedVessel={onChangeSelectedVessel}
                      fleetCheckbox={fleetCheckbox}
                      setFleetCheckbox={setFleetCheckbox}

                      setMarginModal={setMarginModal}
                      syncBunker={syncBunker}
                      setSyncBunker={setSyncBunker}
                      setSupplyModal={setSupplyModal}
                      onChangeSyncBunker={onChangeSyncBunker}
                      isOverride={isOverride}

                      calculationErrors={calculationErrors}


                      marginModal={marginModal}
                      onChangePortOperations={onChangePortOperations}
                      onAddOperations={onAddOperations}
                      onDeleteOperations={onDeleteOperations}
                      supplyModal={supplyModal}
                      fetchPortDistance={fetchPortDistance}
                      openAddOperation={openAddOperation}
                      setOpenAddOperation={setOpenAddOperation}
                      setOpenAddOperationindex={setOpenAddOperationindex}
                      onChangePortCanalInQueryState={onChangePortCanalInQueryState}
                      setIsOverride={onChangeOverride}

                      hideOrder={hideOrder}
                      setHideOrder={setHideOrder}
                      hideCalculator={hideCalculator}
                      getCurrentResult={getCurrentResult}

                      setPdfData={setPdfData}
                      onChangeChartValue={onChangeChartValue}

                      expandedRows={expandedRows}
                      setExpandedRows={setExpandedRows}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="w-[50%]">
                    {voyagesDurationData?.length ? <PieChart data={voyagesDurationData} label="Voyage Duration Break-up" /> : ""}

                  </div>
                  <div className="w-[50%] pr-2">
                    {voyagesExpensesBreakupData?.length ? <PieChart data={voyagesExpensesBreakupData} label="Voyage Expenses Break-up" /> : ""}

                  </div>
                </div>

                {/* <div className="flex flex-col gap-2">
                  {hideCalculator ? null : (
                    <div className="grid grid-cols-6 gap-3 all-content-tables">
                      <div className="upper-section-grid">
                        <AccountCargoDetails
                          calculatorData={calculatorData}
                          setLoader={setLoader}
                          getInitalQueryAndFleetData={
                            getInitalQueryAndFleetData
                          }
                        />
                      </div>

                      <div className="upper-section-grid col-span-2">
                        <CalculatorVesselDetails
                          setLoader={setLoader}
                          setSelectedVessels={setSelectedVessels}
                          selectedVessels={selectedVessels}
                          calculatorData={calculatorData}
                          setCalculatorData={setCalculatorData}
                          onChangeHandler={onChangeSelectedVessel}
                          fleetCheckbox={fleetCheckbox}
                          setFleetCheckbox={setFleetCheckbox}
                        />
                      </div>

                      <div className="upper-section-grid">
                        <MarginAndAllowances
                          onChangeSelectedVessel={onChangeSelectedVessel}
                          selectedVessels={selectedVessels}
                          setSelectedVessels={setSelectedVessels}
                          setMarginModal={setMarginModal}
                          calculatorData={calculatorData}
                          setCalculatorData={setCalculatorData}
                          syncBunker={syncBunker}
                          setSyncBunker={setSyncBunker}
                          setSupplyModal={setSupplyModal}
                          onChangeSyncBunker={onChangeSyncBunker}
                          isOverride={isOverride}
                        />
                      </div>

                      <div className="upper-section-grid">
                        <Expenses
                          onChangeSelectedVessel={onChangeSelectedVessel}
                          selectedVessels={selectedVessels}
                          openSplitHireModal={openSplitHireModal}
                          calculatorData={calculatorData}
                          setCalculatorData={setCalculatorData}
                        />
                      </div>

                      <div className="upper-section-grid">
                        <Result
                          selectedVessels={selectedVessels}
                          calculationErrors={calculationErrors}
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    {calculatorData && <div className="middle-section-grid">
                      <PortOperations
                        marginModal={marginModal}
                        setMarginModal={setMarginModal}
                        sendCalculationRequest={sendCalculationRequest}
                        onChangePortOperations={onChangePortOperations}
                        calculatorData={calculatorData}
                        setCalculatorData={setCalculatorData}
                        syncBunker={syncBunker}
                        setSyncBunker={setSyncBunker}
                        onAddOperations={onAddOperations}
                        onDeleteOperations={onDeleteOperations}
                        selectedVessels={selectedVessels}
                        supplyModal={supplyModal}
                        setSupplyModal={setSupplyModal}
                        onChangeSyncBunker={onChangeSyncBunker}
                        onFetchPortOperationDistance={
                          onFetchPortOperationDistance
                        }
                        fetchPortDistance={fetchPortDistance}
                        openAddOperation={openAddOperation}
                        setOpenAddOperation={setOpenAddOperation}
                        setOpenAddOperationindex={setOpenAddOperationindex}
                        onChangeSelectedVessel={onChangeSelectedVessel}
                        isOverride={isOverride}
                        onChangePortCanalInQueryState={
                          onChangePortCanalInQueryState
                        }
                        setIsOverride={onChangeOverride}
                      />
                    </div>}
                  </div>


                </div> */}
              </div>

            )}
          </div>

          {/* <CustomShowModal
                open={openMap}
                handleClose={onSetOpenMap}
                maxWidth="lg"
                title="Map View"
            >
                <CalculatorPortMapView
                    calculatorData={calculatorData}
                />
            </CustomShowModal> */}
          {/* <CustomShowModal
                open={openResult}
                handleClose={onSetOpenResult}
                maxWidth="lg"
                title="Load Saved Result"
            >
                <LoadSavedResults setLoader={setLoader} />
            </CustomShowModal> */}
        </div>{" "}
      </div>
    </div>
  );


}

export default memo(Matrix);
