import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  Fragment,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  Grid,
  Box,
  Select,
  MenuItem,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  TextField,
  CircularProgress,
  Chip,
  DialogContent,
  IconButton,
  DialogTitle,
  Dialog,
  DialogActions,
  makeStyles,
  Button,
} from "@material-ui/core";
import CustomTextField from "../../../components/Common/CustomTextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  addQueryInitialData,
  queryPortDisabledFields,
  termsCoEfficientTable,
} from "../../../constants";
import DeleteIcon from "@material-ui/icons/Delete";
import * as actions from "./actions";
import {
  getCurrentUserOrganizationId,
  toastError,
  toastSuccess,
} from "../../../utils";
import moment from "moment";
import { useDispatch } from "react-redux";
import CustomShowModal from "../../../components/Common/CustomShowModal";
import AddPortQueryModal from "../../../components/Orders/AddPortQueryModal";
import * as commonActions from "../../Common/actions";
import { validateNewQuery } from "./addQueryValidation";
import { setCopyPortOperation, setCopyQuery } from "./dataMapping";
import DownloadPDF from "../../../components/Common/DowloadPDF";
import CargoPdf from "../../../components/Calculator/CargoPdf";
import { Document, PDFViewer, Page } from "@react-pdf/renderer";
import { handleKeyDown } from "../../../common/common";
import CustomButton from "../../../components/Common/CustomButton";
import UploadPDFModal from "../../../components/Common/UploadPDF";
import LinkIcon from '@material-ui/icons/Link';
import { GrDocumentPdf } from "react-icons/gr";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import PlusIcon from '../../../assets/icons/plus.png'
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash";
import { de } from "react-date-range/dist/locale";
import { BiUpload } from "react-icons/bi";
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 12,
      minWidth: '400px',
    }
  },
  dialogTitle: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.white,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: theme.spacing(3),
  },
  linkList: {
    width: '100%',
    maxHeight: '400px',
    overflowY: 'auto',
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
  },
  linkItem: {
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    }
  }
}));
const AddNewOrder = forwardRef((props, ref) => {
  const {
    setLoader = () => { },
    onCloseModal = () => { },
    selectedQuery = {},
    selectedPorts = [],
    selectedPortsCommence = [],
    isEdit = false,
    // isDetailView = false,
    onChangePinQuery = () => { },
    isFromSingleVesselCalcy = false,
    getInitialData = () => { },
    onShareOrgClick = () => { },
    tabvalue
  } = props;
  const organizationId = getCurrentUserOrganizationId();

  const addPortInitialData = [
    {
      cargoId: "",
      operation: "Loading",
      port: "",
      cargoQty: 0,
      tolerance: 0,
      rate: 0,
      terms: "SHINC",
      turnTime: 0,
      pdaRemarks: "",
      grade: "",
      freight: 0,
      adcom: 0,
      other: 0,
      brokerage: 0,
      ttlComm: 0,
      netFrt: 0,
      min: 0,
      max: 0,
      account: "",
      cargo: "",
      factor: 1,
      stowFactor: 0,
      queryId: "",
      loadline: "S-Summer",
      sulfur: "Normal",
    },
    {
      cargoId: "",
      operation: "Discharging",
      port: "",
      cargoQty: 0,
      tolerance: 0,
      rate: 0,
      terms: "SHINC",
      turnTime: 0,
      pdaRemarks: "",
      grade: "",
      freight: 0,
      adcom: 0,
      other: 0,
      brokerage: 0,
      ttlComm: 0,
      netFrt: 0,
      min: 0,
      max: 0,
      account: "",
      cargo: "",
      factor: 1,
      stowFactor: 0,
      queryId: "",
      loadline: "S-Summer",
      sulfur: "Normal",
    },
  ];

  const dispatch = useDispatch();

  const [portOperations, setPortOperations] = useState([...addPortInitialData]);
  const [query, setQuery] = useState({ ...addQueryInitialData });
  const [portModal, setPortModal] = useState(false);
  const [selectedPort, setSelectedPort] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [getOpenUploadPDF, setOpenUploadPDF] = useState(false);
  const [pdfSaveId, setPdfSaveId] = useState("");
  const [TempCargoDetails, setTempCargoDetails] = useState({})
  const [AttachmentFiles, setAttachmentFiles] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [TagsOptions, setTagsOptions] = useState([]);
  const { id = "" } = useParams();
  const fileInputRef = useRef(null);

  const selectedCargoDetails = () => {
    const data = portOperations?.map((portDetails) => ({
      operation: portDetails.operation,
      lat: portDetails?.lat,
      lng: portDetails?.lng,
      port: portDetails.port,
      cargoQty: portDetails.cargoQty.toString(),
      tolerance: portDetails.tolerance.toString(),
      rate: portDetails.rate.toString(),
      terms: portDetails.terms,
      turnTime: portDetails.turnTime.toString(),
      freight: portDetails.freight,
      min: portDetails.min.toString(),
      max: portDetails.max.toString(),
      adcom: portDetails.adcom.toString(),
      other: portDetails.other.toString(),
      brokerage: portDetails.brokerage.toString(),
      account: portDetails.account,
      cargo: portDetails.cargo,
      stowFactor: portDetails.stowFactor.toString(),

    }));
    const tabledata = data?.filter((field) => (field?.operation === "Loading" || field?.operation === "Discharging"))

    const querydata = {
      queryid: query?._id,
      name: query?.name,
      freightMode: query?.freightMode,
      qtyMode: query?.qtyMode,
      access: query?.access,
      laycanStartDate: query?.laycanStartDate,
      laycanEndDate: query?.laycanEndDate,
      message: query?.message,
      portOperations: tabledata,
      Tag1: query?.Tag1,
      Tag2: query?.Tag2,
      Tag3: query?.Tag3,
      Tag4: query?.Tag4,
    }
    setTempCargoDetails(querydata)
  }

  useEffect(() => {
    selectedCargoDetails()
  }, [portOperations, query])

  useEffect(() => {
    setPdfSaveId(uuidv4())
  }, [])
  useEffect(() => {
    if (Object.keys(selectedQuery).length) {
      setQuery(selectedQuery);
    }
    if (Array.isArray(selectedPorts) && selectedPorts.length) {
      setPortOperations(selectedPorts);
    }
    return () => {
      resetStateVariables();
    };
  }, [selectedQuery, selectedPorts]);

  useEffect(() => {
    const { errors } = validateNewQuery(query, portOperations);
    dispatch(commonActions.addOnlyErrors({ errorList: errors }));
  }, [query, portOperations]);

  const resetStateVariables = () => {
    setPortOperations([...addPortInitialData]);
    setQuery({ ...addQueryInitialData });
    // setStartDate(null)
    // setEndDate(null)
    setPortModal(false);
    setSelectedPort({});
    setSelectedIndex(0);
  };

  const onOpenErrorAlert = (errors = []) => {
    dispatch(commonActions.openErrorAlert({ errorList: errors }));
  };
  const debouncedSearch = useCallback(
    debounce((search, type, name) => {
      onSearchTagsList(search, type, name);
    }, 400),
    []
  );

  const onSearchTagsList = (value, type, name) => {
    if (value) {
      setTagsLoading(name);
      actions
        .tagsByCharAPI(value, type)
        .then((res) => {
          setTagsLoading(false);
          let tagsList = [];
          if (res.data.list?.length > 0) {
            if (type === "individual") {
              tagsList = res.data.list.map((item) => ({
                label: `${item.individual_firstName} (${item?.individual_emailPersonal})`,
                value: item._id,
                name: item.individual_firstName
              }));
            } else {
              tagsList = res.data.list.map((item) => ({
                label: `${item.organisation_fullName} (${item?.organisation_emailOfficial})`,
                value: item._id,
                name: item.organisation_fullName
              }));
            }
          }
          setTagsOptions(tagsList);
        })
        .catch((err) => {
          setTagsLoading(false);
          setTagsOptions([]);
        });
    }
  };


  const delayTagsSearch = (search, type, name) => {
    // setSelectedPort({ ...selectedPort, port: search });
    debouncedSearch(search, type, name);
  };

  useImperativeHandle(ref, () => ({
    async onSubmitQuery(isCopy = false, isShareName = "") {
      const { errors, isValid } = validateNewQuery(query, portOperations);
      if (!isValid) return onOpenErrorAlert(errors);

      const finalPortOperations = [...selectedPortsCommence, ...portOperations];
      const formData = new FormData();

      try {
        let newQuery = {
          ...query,
          laycanStartDate: moment(query.laycanStartDate).toISOString(),
          laycanEndDate: moment(query.laycanEndDate).toISOString(),
          cargoList: finalPortOperations,
          organizationId,
        };

        if (isCopy) {
          newQuery = { ...TempCargoDetails };
          const newPortOperation = [...(TempCargoDetails?.portOperations || [])];
          delete newQuery?.portOperations;

          if (isShareName) {
            newQuery.name = isShareName;
            newQuery.access = "public";
          } else {
            newQuery.access = "private";
          }

          newQuery.cargoList = newPortOperation.map(({ max, min, ...rest }) => rest);
        } else {
          newQuery.cargoList = finalPortOperations.map(({ max, min, ...rest }) => rest);
        }

        // Append all data to FormData
        formData.append('QueryData', JSON.stringify(newQuery) || '');

        // Append attachment files
        AttachmentFiles.forEach((file, index) => {
          formData.append('files', file);
        });

        if (isEdit && !isCopy) {
          try {
            if (isFromSingleVesselCalcy) {
              await actions
                .updateClientQueryForSingleVessel(id, formData)
                .then(() => {
                  toastSuccess("Query updated successfully");
                  getInitialData(id);
                  Promise.all([
                    !!organizationId ? dispatch(actions.clientQueryOrg()) : null,
                    dispatch(actions.clientQueryUser()),
                    dispatch(actions.getQueryFormatForUser()),
                  ]);
                  setLoader(false);
                })
                .catch(() => setLoader(false));
            } else {
              await actions
                .updateClientQuery(formData)
                .then(() => {
                  toastSuccess("Query updated successfully");
                  Promise.all([
                    !!organizationId ? dispatch(actions.clientQueryOrg()) : null,
                    dispatch(actions.clientQueryUser()),
                    dispatch(actions.getQueryFormatForUser()),
                  ]);
                  setLoader(false);
                })
                .catch(() => setLoader(false));
            }
            setLoader(false);
          } catch (ex) {
            setLoader(false);
          }
          onCloseModal();
          return;
        }

        actions
          .addClientQuery(formData)
          .then(() => {
            toastSuccess("Query added successfully");
            onCloseModal();
            Promise.all([
              !!organizationId ? dispatch(actions.clientQueryOrg()) : null,
              dispatch(actions.clientQueryUser()),
              dispatch(actions.getQueryFormatForUser()),
            ]);
            setLoader(false);
          })
          .catch(() => setLoader(false));
      } catch (ex) {
        setLoader(false);
        console.error("Error in submitting query:", ex);
      }
    },
  }));


  const onDownloadPdf = async () => {
    const result = await actions.addDownloadPdf(TempCargoDetails)
    return result
  }



  const onChangeHandler = (e) => {
    // if (isDetailView) return
    const { value = "", name = "" } = e.target || {};
    if (name === "freightMode") {
      if (value === "Single Freight") {
        let firstLoadPort = {}; // reset values to first loading port
        setPortOperations(
          portOperations.map((item, index) => {
            if (
              !Object.keys(firstLoadPort).length &&
              item.operation === "Loading"
            )
              firstLoadPort = {
                freight: item.freight,
                adcom: item.adcom,
                other: item.other,
                brokerage: item.brokerage,
              };
            if (index !== 0 && item.operation === "Loading") {
              return {
                ...item,
                ...firstLoadPort,
              };
            }
            return item;
          })
        );
      }
    }
    setQuery({ ...query, [name]: value });
  };

  const onChangePortOperation = (index, e) => {
    // if (isDetailView) return
    const { name = "", value = "", type = "" } = e.target;
    let updatedPortOperation = [...portOperations];
    updatedPortOperation[index][name] =
      type === "number" ? Number(value) : value;
    if (name === "terms") {
      const findFactor = termsCoEfficientTable.find(
        (item) => item.label === value
      );

      if (findFactor) {
        updatedPortOperation[index]["factor"] = findFactor.value;
      }

    }

    setPortOperations(updatedPortOperation);
  };

  // const onAddPortOperations = (index) => {
  //   // if (isDetailView) return
  //   setPortOperations([...portOperations, { ...addPortInitialData[1] }]);
  // };

  const onAddPortOperations = (index) => {
    // if (isDetailView) return
    const newPortOperations = [...portOperations];
    newPortOperations.splice(index + 1, 0, { ...addPortInitialData[1] });
    setPortOperations(newPortOperations);
  };

  const onDeletePortOperations = (index) => {
    // if (isDetailView) return
    let updatedOperation = [...portOperations];
    updatedOperation.splice(index, 1);
    setPortOperations(updatedOperation);
  };

  const onSelectPortsOfOperations = (port, index) => {
    setSelectedPort(port);
    setSelectedIndex(index);
    setPortModal(true);
  };

  const onSavePortOperationsOfQuery = (port, val) => {
    let updatedPortOperation = [...portOperations];
    updatedPortOperation[selectedIndex]["port"] = port;
    updatedPortOperation[selectedIndex]["lat"] = val?.lat;
    updatedPortOperation[selectedIndex]["lng"] = val?.lng;

    setPortOperations(updatedPortOperation);
    onClosePortModal();
  };

  const onClosePortModal = () => {
    setPortModal(false);
    setSelectedPort({});
    setSelectedIndex(0);
  };

  const onChangeDate = (type, date) => {
    // if (isDetailView) return
    setQuery({ ...query, [type]: date });
  };

  const onSelectTags = (value, name) => {
    setQuery({ ...query, [name]: value });
    setTagsOptions([])
  };
  const onChangeHandlerAccess = (e) => {
    const { checked = "", name = "" } = e.target || {};
    setQuery({ ...query, [name]: checked ? "public" : "private" });

  }
  const {
    access = "",
    freightMode = "",
    message = "",
    name = "",
    qtyMode = "",
    laycanStartDate = null,
    laycanEndDate = null,
    Tag1 = "",
    Tag2 = "",
    Tag3 = "",
    Tag4 = "",
    Attachments = [],
  } = query || {};

  let showMinMax = false;
  if (qtyMode === "MINMAX") showMinMax = true;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // Separate Attachments and videos based on file extensions
  const mediaTypes = useMemo(() => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.bmp', '.tiff'];
    const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv', '.flv', '.webm', '.mkv'];
    const documentExtensions = ['.pdf', '.doc', '.docx', '.odt', '.xls', '.xlsx', '.csv', '.xlsm', '.ppt', '.pptx', '.odp', '.txt'
    ];
    const sortedMedia = {
      images: [],
      videos: [],
      documents: []
    };

    Attachments.forEach(url => {
      const extension = url.substring(url.lastIndexOf('.')).toLowerCase();

      if (imageExtensions.includes(extension)) {
        sortedMedia.images.push(url);
      } else if (videoExtensions.includes(extension)) {
        sortedMedia.videos.push(url);
      } else if (documentExtensions.includes(extension)) {
        sortedMedia.documents.push(url);
      }
    });

    return sortedMedia;
  }, [Attachments]);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setAttachmentFiles(prevFiles => [...prevFiles, ...files]);
  };
  const handleRemoveFile = (item) => {
    if (typeof item === 'number') {
      setAttachmentFiles((prevFiles) => prevFiles.filter((_, i) => i !== item));
    }
    // Check if the item is a URL (from mediaTypes.images)
    else if (typeof item === 'string') {
      // Remove from images in currentData
      const updatedFiles = Attachments.filter((url) => url !== item);

      setQuery((prevData) => ({
        ...prevData,
        Attachments: updatedFiles
      }));
    }
    setAttachmentFiles((prevFiles) => prevFiles.filter((_, i) => i !== item));
  };

  return (
    <Fragment>
      <div className="mb-4 flex justify-end w-full">

      </div>
      {/* <PDFViewer className="w-full h-96">
        <Document>
          <Page size="A4" orientation=
            "landscape"
          >
            <CargoPdf portOperations={portOperations} query={query} />
          </Page>
        </Document>
      </PDFViewer> */}
      <Grid container className="add-order-container">
        <Grid item xs={12}>
          <div className="w-full flex justify-between mb-2 items-center mt-1 popup-cargo-list">
            <div className="typo-18-500 text-neutrals4">Cargo List</div>
            <div className="d-flex">
              {!isFromSingleVesselCalcy ? <>
                {isEdit ?
                  <DownloadPDF
                    pdfName={name}
                    onDownloadPdf={onDownloadPdf}
                    portOperations={portOperations}
                    query={query}
                    pdfDocument={
                      <CargoPdf portOperations={portOperations} query={query} pdfSaveId={pdfSaveId} />
                    }
                  /> :
                  <button
                    className="text-center py-1 bg-page-name text-neutrals9 px-3 rounded-md text-sm flex items-center gap-1 mb-2 ml-3"
                    onClick={() => setOpenUploadPDF(true)}
                  >
                    <span className="pr-1"><GrDocumentPdf /></span> Upload PDF
                  </button>}
              </> : ""}
              {/* <button
                className="text-center py-1 bg-page-name text-neutrals9 px-3 rounded-md text-sm flex items-center gap-1 mb-2 ml-3"
                onClick={onAddPortOperations}
              >
                + Add Cargo
              </button> */}

              {(isEdit && tabvalue === 0) ? <span>
                {/* <Switch
                  checked={access === "public"}
                  onChange={(e) => onChangeHandlerAccess(e)}
                  name="access"
                  color="primary"
                  size="small"
                /> */}
                <button
                  className="text-center py-1 bg-page-name text-neutrals9 px-3 rounded-md text-sm flex items-center gap-1 mb-2 ml-3"
                  onClick={() => onShareOrgClick()}
                >
                  Share with Team
                </button>
              </span> : ""}
            </div>
          </div>
          <Box className="item-box-contianer cargobox-tables">
            <TableContainer
              component={Paper}
              className="add-query-operations-table"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ maxWidth: "20px" }}>Add opr..</TableCell>
                    <TableCell>Operation</TableCell>
                    <TableCell>Port</TableCell>
                    {showMinMax ? (
                      <>
                        <TableCell>Min</TableCell>
                        <TableCell>Max</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>Cargo Qty</TableCell>
                        <TableCell>Tolerance</TableCell>
                      </>
                    )}
                    <TableCell>Rate MT/Day</TableCell>
                    <TableCell>Terms</TableCell>
                    <TableCell>Turn Time(d)</TableCell>
                    <TableCell>Freight</TableCell>
                    <TableCell>Adcom(%)</TableCell>
                    <TableCell>Other(%)</TableCell>
                    <TableCell>Brokerage(%)</TableCell>
                    <TableCell>A/C</TableCell>
                    <TableCell>Cargo</TableCell>
                    <TableCell>Stow Factor</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {portOperations.map((item, index) => {
                    const {
                      operation = "",
                      port = "",
                      cargoQty = "",
                      tolerance = "",
                      rate = "",
                      terms = "",
                      turnTime = "",
                      freight = "",
                      min = 0,
                      max = 0,
                      adcom = "",
                      other = "",
                      brokerage = "",
                      account = "",
                      cargo = "",
                      stowFactor = "",
                      factor = 0,
                    } = item || {};

                    const disableForSingleFreight =
                      (freightMode === "Single Freight" && index !== 0) ||
                      false;
                    if (item?.operation?.toLowerCase() === "loading" || item?.operation?.toLowerCase() === "discharging") {
                    } else {
                      return <></>
                    }
                    return (
                      <TableRow style={{ background: "#f6f6f6" }} key={index}>
                        <TableCell src={PlusIcon} style={{ display: "flex", justifyContent: "center", width: "auto", minWidth: "auto" }} onClick={() => onAddPortOperations(index)}>
                          {index > 0 &&
                            <>
                              <span className="validate-icon-vessel-manager">
                                <img src={PlusIcon} className="operation-plus-icon" style={{ width: "21px" }} alt="" />
                              </span>
                              <div className="tooltip-add-cargo">
                                <p>Add operation in next row</p>
                              </div>
                            </>}
                        </TableCell>
                        <TableCell>
                          <select
                            disabled={index < 1}
                            value={operation}
                            name="operation"
                            // style={{ marginLeft: 5 }}
                            onChange={(e) => onChangePortOperation(index, e)}
                          >
                            <option value="Commence" disabled hidden>
                              Commence
                            </option>
                            <option value="Loading">Loading</option>
                            <option value="Discharging">Discharging</option>
                          </select>
                        </TableCell>
                        <TableCell style={{ width: 100 }}>
                          {/* <div>
                            <AddPortQueryModal
                              // onClosePortModal={onClosePortModal}
                              selectedPort={selectedPort}
                              setSelectedPort={setSelectedPort}
                              onSavePortOperationsOfQuery={
                                onSavePortOperationsOfQuery
                              }
                            />
                          </div> */}
                          <div className="port-change-div cursor-pointer">
                            <p
                              onClick={() =>
                                onSelectPortsOfOperations(item, index)
                              }
                            >
                              {port}
                            </p>
                          </div>
                        </TableCell>
                        {showMinMax ? (
                          <>
                            {" "}
                            <TableCell>
                              <input
                                type="number"
                                onKeyDown={handleKeyDown}
                                value={min}
                                name="min"
                                disabled={
                                  queryPortDisabledFields?.[operation]?.["min"]
                                }
                                onChange={(e) =>
                                  onChangePortOperation(index, e)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <input
                                type="number"
                                onKeyDown={handleKeyDown}
                                value={max}
                                name="max"
                                disabled={
                                  queryPortDisabledFields?.[operation]?.["max"]
                                }
                                onChange={(e) =>
                                  onChangePortOperation(index, e)
                                }
                              />
                            </TableCell>
                          </>
                        ) : (
                          <>
                            {" "}
                            <TableCell>
                              <input
                                type="number"
                                onKeyDown={handleKeyDown}
                                value={cargoQty}
                                name="cargoQty"
                                disabled={
                                  queryPortDisabledFields?.[operation]?.[
                                  "cargoQty"
                                  ]
                                }
                                onChange={(e) =>
                                  onChangePortOperation(index, e)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <input
                                type="number"
                                onKeyDown={handleKeyDown}
                                value={tolerance}
                                name="tolerance"
                                disabled={
                                  queryPortDisabledFields?.[operation]?.[
                                  "tolerance"
                                  ]
                                }
                                onChange={(e) =>
                                  onChangePortOperation(index, e)
                                }
                              />
                            </TableCell>
                          </>
                        )}
                        <TableCell>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            value={rate}
                            name="rate"
                            onChange={(e) => onChangePortOperation(index, e)}
                          />
                        </TableCell>
                        <TableCell style={{ width: 60 }}>
                          <select
                            name="terms"
                            value={terms}
                            onChange={(e) => onChangePortOperation(index, e)}
                          >
                            {termsCoEfficientTable.map((item, index) => {
                              return (
                                <option key={index} value={item.label}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </TableCell>
                        <TableCell>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            value={turnTime}
                            name="turnTime"
                            onChange={(e) => onChangePortOperation(index, e)}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            value={freight}
                            name="freight"
                            disabled={
                              queryPortDisabledFields?.[operation]?.[
                              "freight"
                              ] || disableForSingleFreight
                            }
                            onChange={(e) => onChangePortOperation(index, e)}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            value={adcom}
                            name="adcom"
                            disabled={
                              queryPortDisabledFields?.[operation]?.["adcom"] ||
                              disableForSingleFreight
                            }
                            onChange={(e) => onChangePortOperation(index, e)}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            value={other}
                            name="other"
                            disabled={
                              queryPortDisabledFields?.[operation]?.["other"] ||
                              disableForSingleFreight
                            }
                            onChange={(e) => onChangePortOperation(index, e)}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            value={brokerage}
                            name="brokerage"
                            disabled={
                              queryPortDisabledFields?.[operation]?.[
                              "brokerage"
                              ] || disableForSingleFreight
                            }
                            onChange={(e) => onChangePortOperation(index, e)}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="text"
                            value={account}
                            name="account"
                            disabled={
                              queryPortDisabledFields?.[operation]?.["account"]
                            }
                            onChange={(e) => onChangePortOperation(index, e)}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="text"
                            value={cargo}
                            name="cargo"
                            disabled={
                              queryPortDisabledFields?.[operation]?.["cargo"]
                            }
                            onChange={(e) => onChangePortOperation(index, e)}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            value={stowFactor}
                            name="stowFactor"
                            disabled={
                              queryPortDisabledFields?.[operation]?.[
                              "stowFactor"
                              ]
                            }
                            onChange={(e) => onChangePortOperation(index, e)}
                          />
                        </TableCell>
                        <TableCell>
                          {index > 1 ? (
                            <DeleteIcon
                              className="text-red"
                              onClick={() => onDeletePortOperations(index)}
                            />
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <div className="w-full flex justify-between items-center mt-4">
          <div className="typo-18-500 text-neutrals4">Cargo Details</div>
        </div>
        <Grid item xs={12} className="lower-input-grid mt-2 cargo-details-wrap">
          <div className="input-div">
            <div className="upper-item-div">
              <p>Name</p>
              <div className="inputs" style={{ width: "100%" }}>
                <CustomTextField
                  size="small"
                  placeholder="Enter Name"
                  value={name}
                  name="name"
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            <div className="upper-item-div">
              <p>Freight Mode</p>
              <div className="inputs">
                <Select
                  value={freightMode || ""}
                  onChange={onChangeHandler}
                  name="freightMode"
                  size="small"
                  variant="outlined"
                  fullWidth
                  className="custom-select"
                >
                  <MenuItem dense value="Single Freight">
                    Single Freight
                  </MenuItem>
                  <MenuItem dense value="Multiple Freight">
                    Multiple Freight
                  </MenuItem>
                </Select>
              </div>
            </div>
            <div className="upper-item-div">
              <p>Qty option</p>
              <div className="inputs">
                <Select
                  value={qtyMode || ""}
                  onChange={onChangeHandler}
                  name="qtyMode"
                  size="small"
                  variant="outlined"
                  fullWidth
                  className="custom-select"
                >
                  <MenuItem dense value="MOLOO">
                    MOLOO
                  </MenuItem>
                  <MenuItem dense value="MINMAX">
                    MINMAX
                  </MenuItem>
                </Select>
              </div>
            </div>
            {/* <div className="upper-item-div">
              <p>Access Mode</p>
              <div className="inputs">
                <Select
                  value={access || ""}
                  onChange={onChangeHandler}
                  name="access"
                  size="small"
                  variant="outlined"
                  fullWidth
                  className="custom-select"
                >
                  <MenuItem dense value="private">
                    Private
                  </MenuItem>
                  <MenuItem dense value="public">
                    Public
                  </MenuItem>
                </Select>
              </div>
            </div> */}
            <div className="upper-item-div">
              <p>laycan start date</p>
              <div className="inputs">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    autoOk
                    fullWidth
                    // variant="inline"
                    inputVariant="outlined"
                    format="DD MMM yyyy HH:mm A"
                    placeholder="laycan start date"
                    value={laycanStartDate || null}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(date) => onChangeDate("laycanStartDate", date)}
                    className="custom-date-time-picker"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="upper-item-div">
              <p>laycan end date</p>
              <div className="inputs">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    autoOk
                    fullWidth
                    // variant="inline"
                    inputVariant="outlined"
                    format="DD MMM yyyy HH:mm A"
                    placeholder="laycan end date"
                    value={laycanEndDate || null}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(date) => onChangeDate("laycanEndDate", date)}
                    className="custom-date-time-picker"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="upper-item-div">
              <p>Consultant</p>
              <div className="inputs">
                <Autocomplete
                  options={
                    (tagsLoading === "tag1") || TagsOptions.length === 0 ? [] : TagsOptions
                  }
                  value={Tag1}
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => onSelectTags(value, "Tag1")}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      variant="outlined"
                      placeholder="Search Name"
                      onClick={() => { setTagsOptions([]) }}
                      value={Tag1}
                      onChange={(e) => delayTagsSearch(e.target.value, "individual", "tag1")}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {(tagsLoading === "tag1") ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="upper-item-div">
              <p>Consultancy</p>
              <div className="inputs">
                <Autocomplete
                  options={
                    (tagsLoading === "tag2") || TagsOptions.length === 0 ? [] : TagsOptions
                  }
                  value={Tag2}
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => onSelectTags(value, "Tag2")}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      variant="outlined"
                      placeholder="Search Name"
                      value={Tag1}
                      onClick={() => { setTagsOptions([]) }}
                      onChange={(e) => delayTagsSearch(e.target.value, "organisation", "tag2")}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {(tagsLoading === "tag2") ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="upper-item-div">
              <p>Consultant</p>
              <div className="inputs">
                <Autocomplete
                  options={
                    (tagsLoading === "tag3") || TagsOptions.length === 0 ? [] : TagsOptions
                  }
                  value={Tag3}
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => onSelectTags(value, "Tag3")}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      variant="outlined"
                      placeholder="Search Name"
                      value={Tag1}
                      onClick={() => { setTagsOptions([]) }}
                      onChange={(e) => delayTagsSearch(e.target.value, "individual", "tag3")}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {(tagsLoading === "tag3") ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="upper-item-div">
              <p>Consultancy</p>
              <div className="inputs">
                <Autocomplete
                  options={
                    (tagsLoading === "tag4") || TagsOptions.length === 0 ? [] : TagsOptions
                  }
                  value={Tag4}
                  disableClearable
                  getOptionLabel={(option) => option.label}

                  onChange={(e, value) => onSelectTags(value, "Tag4")}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      variant="outlined"
                      placeholder="Search Name"
                      onClick={() => { setTagsOptions([]) }}
                      value={Tag1}
                      onChange={(e) => delayTagsSearch(e.target.value, "organisation", "tag4")}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {(tagsLoading === "tag4") ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>

            {(mediaTypes.images.length > 0 || mediaTypes.videos.length > 0) && (
              <div className="flex w-[95%] items-center">
                <p className=" text-sm mr-8">Existing Files</p>
                <Chip
                  icon={<LinkIcon />}
                  label={`Media: ${mediaTypes.images.length} Images, ${mediaTypes.videos.length} Videos,  ${mediaTypes.documents.length} Doc`}
                  color="primary"
                  variant="outlined"
                  onClick={handleOpen}
                  className="cursor-pointer hover:bg-blue-50"
                />
              </div>
            )}
            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="sm"
              fullWidth
              className={classes.root}
            >
              <DialogTitle className={classes.dialogTitle}>
                <div className="flex-grow">
                  Review Media
                </div>
                <IconButton
                  onClick={handleClose}
                  className={classes.closeButton}
                  style={{ position: 'absolute', right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent dividers className={classes.dialogContent}>
                {/* Images Section */}
                {mediaTypes.images.length > 0 && (
                  <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-4 w-max border-b pb-2">
                      Images ({mediaTypes.images.length})
                    </h3>
                    <div className="grid grid-cols-3 gap-4">
                      {mediaTypes.images.map((url, index) => (
                        <div
                          key={index}
                          className="relative shadow-md shadow-gray-700 hover:shadow-lg transition-shadow overflow-visible"
                        >

                          <button
                            onClick={() => handleRemoveFile(url)}
                            className="absolute -top-2 -right-2 z-10 bg-rose-500 text-white rounded-full hover:bg-rose-600 transition-colors 
                                shadow-md hover:shadow-lg 
                                focus:outline-none focus:ring-2 focus:ring-rose-300"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>

                          <div
                            className="cursor-pointer"
                            onClick={() => handleOpenInNewTab(url)}
                          >
                            <img
                              src={url}
                              alt={`Image ${index + 1}`}
                              className="w-full h-[5rem] object-cover"
                            />
                            <div className="p-1 bg-gray-100 text-center">
                              <span className="text-sm truncate">
                                Image {index + 1}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Videos Section */}
                {mediaTypes.videos.length > 0 && (
                  <div>
                    <h3 className="text-lg font-semibold mb-4 w-max border-b pb-2">
                      Videos ({mediaTypes.videos.length})
                    </h3>
                    <div className={classes.linkList} style={{ overflow: "visible" }}>
                      {mediaTypes.videos.map((url, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between w-full p-3 border-b border-gray-200 hover:bg-gray-50 transition-colors duration-200 "
                        >
                          <div className="flex items-center space-x-3 relative">

                            <button
                              onClick={() => handleRemoveFile(url)}
                              className="absolute -top-7 right-[-9.5rem] z-10 bg-rose-500 text-white rounded-full p-1 
                                hover:bg-rose-600 transition-colors 
                                  shadow-md hover:shadow-lg 
                                  focus:outline-none focus:ring-2 focus:ring-rose-300"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2.5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>

                            <div className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs font-semibold">
                              Video {index + 1}
                            </div>
                            <span
                              className="text-sm text-gray-700 max-w-[300px] truncate"
                              title={url}
                            >
                              {url.split('/').pop()}
                            </span>
                          </div>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<LaunchIcon />}
                            onClick={() => handleOpenInNewTab(url)}
                            className="ml-4"
                          >
                            Open Link
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {mediaTypes.documents.length > 0 && (
                  <div>
                    <h3 className="text-lg font-semibold mb-4 mt-4 w-max border-b pb-2">
                      Documents ({mediaTypes.documents.length})
                    </h3>
                    <div className={classes.linkList} style={{ overflow: "visible" }}>
                      {mediaTypes.documents.map((url, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between w-full p-3 border-b border-gray-200 hover:bg-gray-50 transition-colors duration-200 "
                        >
                          <div className="flex items-center space-x-3 relative">

                            <button
                              onClick={() => handleRemoveFile(url)}
                              className="absolute -top-7 right-[-9.5rem] z-10 bg-rose-500 text-white rounded-full p-1 
                                hover:bg-rose-600 transition-colors 
                                  shadow-md hover:shadow-lg 
                                  focus:outline-none focus:ring-2 focus:ring-rose-300"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2.5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>

                            <div className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs font-semibold">
                              Document {index + 1}
                            </div>
                            <span
                              className="text-sm text-gray-700 max-w-[300px] truncate"
                              title={url}
                            >
                              {url.split('/').pop()}
                            </span>
                          </div>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<LaunchIcon />}
                            onClick={() => handleOpenInNewTab(url)}
                            className="ml-4"
                          >
                            Open Link
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* No Media Message */}
                {mediaTypes.images.length === 0 && mediaTypes.videos.length === 0 && (
                  <div className="text-center text-gray-500 py-4">
                    No media available
                  </div>
                )}
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>


            <div className="flex w-[95%] items-center mt-2">
              <p className="mb-2 mt-3 text-sm mr-7">Upload New Files</p>
              <div className="flex flex-col items-start gap-2 w-full bg-transparent p-2 rounded-lg border border-[#3f51b5]  mb-2">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => fileInputRef.current.click()}
                    style={{
                      padding: "8px 16px",
                      background: "#3f51b5",
                      color: "white",
                      fontSize: "14px",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      transition: 'background 0.3s ease'
                    }}
                  >
                    <BiUpload />
                    Attachments
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    multiple
                    accept="image/*"
                    onChange={handleFileSelect}
                  />
                  <span style={{
                    color: '#666',
                    fontSize: '12px'
                  }}>
                    {AttachmentFiles.length} image(s) selected
                  </span>
                </div>

                {AttachmentFiles.length > 0 && (
                  <div style={{
                    width: '100%',
                    maxHeight: '200px',
                    overflowY: 'auto',
                    backgroundColor: 'white',
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    padding: '10px'
                  }}>
                    {AttachmentFiles.map((file, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '5px 10px',
                          borderBottom: index < AttachmentFiles.length - 1 ? '1px solid #f0f0f0' : 'none'
                        }}
                      >
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px'
                        }}>
                          <i className="fas fa-file-image" style={{ color: '#4CAF50' }}></i>
                          <span style={{
                            fontSize: '13px',
                            color: '#333'
                          }}>
                            {file.name}
                          </span>
                        </div>
                        <button
                          onClick={() => handleRemoveFile(index)}
                          style={{
                            color: '#f44336',
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            transition: 'color 0.3s ease'
                          }}
                          onMouseOver={(e) => e.target.style.color = '#d32f2f'}
                          onMouseOut={(e) => e.target.style.color = '#f44336'}
                        >
                          <i className="fas fa-trash-alt"></i>
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="textarea-div">
            <textarea
              className="add-order-textarea"
              placeholder="Please enter your cargo details here...."
              value={message}
              name="message"
              onChange={onChangeHandler}
            />
          </div>
        </Grid>
        <UploadPDFModal
          open={getOpenUploadPDF}
          handleClose={() => setOpenUploadPDF(false)}
          setPortOperations={setPortOperations}
          setQuery={setQuery}
          query={query}
        />
        <CustomShowModal
          open={portModal}
          handleClose={onClosePortModal}
          maxWidth="xs"
          disableClose
          title={`Change ${selectedPort.operation} Port`}
        >
          <AddPortQueryModal
            // onClosePortModal={onClosePortModal}
            selectedPort={selectedPort}
            setSelectedPort={setSelectedPort}
            onSavePortOperationsOfQuery={onSavePortOperationsOfQuery}
          />
        </CustomShowModal>
      </Grid>
    </Fragment>
  );
});

export default AddNewOrder;
