import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "./actions";
import {
  Grid,
  Box,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomButton from "../../../components/Common/CustomButton";
import CustomAddModal from "../../../components/Common/CustomAddModal";
import CustomConfirm from "../../../components/Common/CustomConfirmModal";
import AddNewOrg from "../../../components/Dashboard/AddNewOrg";
import {
  getCurrentUserId,
  isAdminLogin,
  toastError,
  toastSuccess,
} from "../../../utils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import ActionMenu from "../../../common/ActionMenu";

const inititalOrganization = {
  organizationAdmin: "",
  domain: "",
  organizationType: "",
  organizationName: "",
};

function SetupOrganization(props) {
  const { setLoader = () => {} } = props;

  const dispatch = useDispatch();
  const { allOrganization = [] } = useSelector((state) => state.dashboard);
  const history = useHistory();
  const [organization, setOrganization] = useState({ ...inititalOrganization });
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [saveLabel, setSaveLabel] = useState("Add");
  const [selectedOrg, setSelectedOrg] = useState({ id: "", status: "" });

  useEffect(() => {
    const organizationAdmin = isAdminLogin()
      ? { organizationAdmin: getCurrentUserId() }
      : {};
    if (allOrganization.length) {
      dispatch(actions.getAllOrganizations(organizationAdmin));
    }
  }, []);

  const onCloseModal = () => {
    setOpen(false);
    setOrganization({ ...inititalOrganization });
    setSaveLabel("Add");
  };

  const onChangeHandler = (e) => {
    const { name = "", value = "" } = e.target;
    setOrganization({ ...organization, [name]: value });
  };

  const onOpenConfirm = (status, id) => {
    setSelectedOrg({ id, status });
    setOpenConfirm(true);
  };

  const onSubmitConfirm = async () => {
    try {
      setLoader(true);

      if (saveLabel === "Add") {
        const data = {
          ...organization,
          organizationAdmin: isAdminLogin()
            ? getCurrentUserId()
            : organization.organizationAdmin,
        };
        await actions.createOrganization(data);
        toastSuccess("Organization created successfully");
      } else {
        const { _id, ...organizationData } = organization;
        await actions.updateOrganization({
          id: _id,
          data: {
            ...organizationData,
            organizationAdmin: isAdminLogin()
              ? getCurrentUserId()
              : organization.organizationAdmin,
          },
        });
        toastSuccess("Organization updated successfully");
      }

      const organizationAdmin = isAdminLogin()
        ? { organizationAdmin: getCurrentUserId() }
        : {};
      dispatch(actions.getAllOrganizations(organizationAdmin));

      onCloseModal();
      setLoader(false);
    } catch (err) {
      setLoader(false);
      toastError(err?.data?.message);
    }
  };

  const onClickEdit = (org = {}) => {
    const {
      _id = "",
      domain = "",
      organizationAdmin = "",
      organizationType = "",
      organizationName = "",
    } = org;
    setOrganization({
      _id,
      organizationAdmin: organizationAdmin?._id,
      domain,
      organizationType,
      organizationName,
    });
    setSaveLabel("Update");
    setOpen(true);
  };

  const onSubmitStatus = async () => {
    try {
      setLoader(true);
      const orgId = selectedOrg.id;
      await actions.updateOrganizationStatus({
        id: orgId,
        action: selectedOrg?.status === "activate" ? "activate" : "deactivate",
      });
      setSelectedOrg({ id: "", status: "" });
      setOpenConfirm(false);
      const organizationAdmin = isAdminLogin()
        ? { organizationAdmin: getCurrentUserId() }
        : {};
      dispatch(actions.getAllOrganizations(organizationAdmin));
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toastError(ex?.data?.message);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Paper
            onClick={() => history.goBack()}
            className="px-3 py-1 bg-neutrals9 border border-neutrals6 text-neutrals4 rounded-md text-sm flex items-center gap-1 cursor-pointer"
          >
            <ArrowBackIosIcon className="text-sm" /> Go back
          </Paper>
          <Box display="flex" justifyContent="flex-end">
            <div style={{ width: "180px" }}>
              <CustomButton
                onClick={() => setOpen(true)}
                className="bg-page-name rounded-md"
              >
                Create Organization
              </CustomButton>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box pt={2}>
          <TableContainer component={Paper}>
            <Table className="universal-table">
              <TableHead>
                <TableRow>
                  <TableCell>S.no</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Organization Id</TableCell>
                  <TableCell>Organization Admin</TableCell>
                  <TableCell>Domain</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(allOrganization) && allOrganization.length ? (
                  allOrganization.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        onDoubleClick={() => onClickEdit(item)}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.organizationName}</TableCell>
                        <TableCell>{item._id}</TableCell>
                        <TableCell>
                          {item.organizationAdmin?.firstName ||
                          item.organizationAdmin?.lastName
                            ? `${item.organizationAdmin?.firstName} ${item.organizationAdmin?.lastName}`
                            : "N/A"}
                        </TableCell>
                        <TableCell>{item.domain}</TableCell>
                        <TableCell>
                          <div
                            className={`capitalize tb-status tb-status-${
                              item.isActive ? "success" : "danger"
                            }`}
                          >{`${item.isActive ? "Active" : "Deactive"}`}</div>
                        </TableCell>
                        <TableCell className="user-organization-actions">
                          <ActionMenu
                            options={[
                              {
                                label: "Edit",
                                onClick: () => onClickEdit(item),
                                icon: <EditIcon className="!text-lightBlue" />,
                              },
                              {
                                label: "Activate",
                                onClick: () =>
                                  onOpenConfirm("activate", item._id),
                                hide:
                                  item.role === "superadmin" || item.isActive,
                              },
                              {
                                label: "Deactivate",
                                onClick: () =>
                                  onOpenConfirm("deactivate", item._id),
                                hide:
                                  item.role === "superadmin" || !item.isActive,
                              },
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan="5" className="!bg-white">
                      No User organizaton found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      <CustomAddModal
        open={open}
        saveLabel={saveLabel}
        maxWidth="xs"
        title={`${saveLabel === "Add" ? "Add" : "Edit"} Organization`}
        handleClose={onCloseModal}
        onSubmitConfirm={onSubmitConfirm}
      >
        <AddNewOrg organization={organization} onChange={onChangeHandler} />
      </CustomAddModal>
      <CustomConfirm
        open={openConfirm}
        message={`Please confirm if you want to ${
          selectedOrg.status === "activate" ? "activate" : "deactivate"
        } organization`}
        onSubmitConfirm={onSubmitStatus}
        handleClose={() => setOpenConfirm(false)}
      >
        <div>hello</div>
      </CustomConfirm>
    </Grid>
  );
}

export default SetupOrganization;
