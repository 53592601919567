import * as React from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";

import { MdOutlineMoreVert } from "react-icons/md";

const ITEM_HEIGHT = 20;

export default function ActionMenu(props) {
  const { options } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MdOutlineMoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options
          .filter((optionDetails) => !optionDetails?.hide)
          .map((option) => (
            <MenuItem
              key={option}
              onClick={() => {
                option.onClick(option.value);
                handleClose();
              }}
            >
              <div className="flex gap-2">
                {option.icon && option.icons}
                {option.label}
              </div>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
