import React, {
  useEffect,
  useState,
  memo,

} from "react";
import { Box, Grid, Paper, TextField, Typography, Button } from "@material-ui/core";

import { getObject, getOrganizationId, toastError, toastSuccess } from "../../../utils";
import _, { cloneDeep } from "lodash";
import Matrix from "./Matrix";

function Calculator(props) {
  const user = getObject("auth")

  if (user?.isPaid) {
    return (
      <Matrix {...props} />
    );
  }
  return (
    <div className="request-access-container">
      <Paper className="request-access-wrapper" elevation={3}>
        <Typography className="request-access-header">Request Access</Typography>
        <form >
          <TextField
            label="Reason"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            className="request-access-input"
            // value={reason}
            // onChange={(e) => setReason(e.target.value)}
            placeholder="Enter your reason here"
          />

          <Button
            // onClick={onSubmitConfirm}
            color="primary"
            variant="outlined"
            className="font-family mt-2 px-8 rounded-md"
          >
            Submit
          </Button>
        </form>
      </Paper>
    </div>)
}

export default memo(Calculator);
