import React, {
    useState,
    forwardRef,
    useImperativeHandle,
    useEffect,
    Fragment,
    useCallback,
} from "react";
import {
    Grid,
    Box,
    Select,
    MenuItem,
    TableContainer,
    Table,
    Paper,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    makeStyles,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Button,
    InputLabel,
    FormControl,
    TextField,
    CircularProgress,
} from "@material-ui/core";
import CustomTextField from "../../../components/Common/CustomTextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
    addQueryInitialData,
    cargoFuelPriceStep,
    expenseResultField,
    fuelPriceOptions,
    loadlineOptions,
    portEmissionOptions,
    queryPortDisabledFields,
    termsCoEfficientTable,
    vesselCalculatorMainFields,
} from "../../../constants";
import DeleteIcon from "@material-ui/icons/Delete";
import * as actions from "./actions";
import {
    getCurrentUserOrganizationId,
    toastError,
    toastSuccess,
} from "../../../utils";
import moment from "moment";
import { useDispatch } from "react-redux";
import CustomShowModal from "../../../components/Common/CustomShowModal";
import AddPortQueryModal from "../../../components/Orders/AddPortQueryModal";
import * as commonActions from "../../Common/actions";
import { validateNewQuery } from "./addQueryValidation";
import { setCopyPortOperation, setCopyQuery } from "./dataMapping";
import DownloadPDF from "../../../components/Common/DowloadPDF";
import CargoPdf from "../../../components/Calculator/CargoPdf";
import { Document, PDFViewer, Page } from "@react-pdf/renderer";
import { handleKeyDown } from "../../../common/common";
import CustomButton from "../../../components/Common/CustomButton";
import UploadPDFModal from "../../../components/Common/UploadPDF";
import { GrDocumentPdf } from "react-icons/gr";
import { useParams } from "react-router-dom";
import Expenses from "../../../components/Calculator/Expenses";
import { Autocomplete } from "@material-ui/lab";
import { portsByChar } from "../Calculator/actions";
import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
        background: "#fff"
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Disclaimer', 'Select Vessel Type', "Add Daily Hire Rates", " Vessel Opening Port and/or Ballast Factor", "Add Fuel Prices", "Port Expenses and Restricions"];
}

const CreateCargoAnalysis = forwardRef((props, ref) => {
    const {
        setLoader = () => { },
        onCloseModal = () => { },
        selectedQuery = {},
        selectedPorts = [],
        selectedPortsCommence = [],
        analysisVessel = [],
        setCargoAnalysisData,
        cargoAnalysisData,
        isEdit = false,
        // isDetailView = false,
        onChangePinQuery = () => { },
        isFromSingleVesselCalcy = false,
        getInitialData = () => { },
        setIsEdit

    } = props;
    const organizationId = getCurrentUserOrganizationId();

    const addPortInitialData = [
        {
            cargoId: "",
            operation: "Loading",
            port: "",
            cargoQty: 0,
            tolerance: 0,
            rate: 0,
            terms: "SHINC",
            turnTime: 0,
            pdaRemarks: "",
            grade: "",
            freight: 0,
            adcom: 0,
            other: 0,
            brokerage: 0,
            ttlComm: 0,
            netFrt: 0,
            min: 0,
            max: 0,
            account: "",
            cargo: "",
            factor: 1,
            stowFactor: 0,
            queryId: "",
            loadline: "S-Summer",
            sulfur: "Normal",
        },
        {
            cargoId: "",
            operation: "Discharging",
            port: "",
            cargoQty: 0,
            tolerance: 0,
            rate: 0,
            terms: "SHINC",
            turnTime: 0,
            pdaRemarks: "",
            grade: "",
            freight: 0,
            adcom: 0,
            other: 0,
            brokerage: 0,
            ttlComm: 0,
            netFrt: 0,
            min: 0,
            max: 0,
            account: "",
            cargo: "",
            factor: 1,
            stowFactor: 0,
            queryId: "",
            loadline: "S-Summer",
            sulfur: "Normal",
        },
    ];

    const dispatch = useDispatch();

    const [portOperations, setPortOperations] = useState([...addPortInitialData]);
    const [query, setQuery] = useState({ ...addQueryInitialData });
    const [portModal, setPortModal] = useState(false);
    const [selectedPort, setSelectedPort] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [getOpenUploadPDF, setOpenUploadPDF] = useState(false);
    const { id = "" } = useParams();
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [portLoading, setPortLoading] = useState(false);
    const [portOptions, setPortOptions] = useState([]);

    const steps = getSteps();

    useEffect(() => {
        if (Object.keys(selectedQuery).length) {
            setQuery(selectedQuery);
        }
        if (Array.isArray(selectedPorts) && selectedPorts.length) {
            setPortOperations(selectedPorts);
        }
        return () => {
            resetStateVariables();
        };
    }, [selectedQuery, selectedPorts]);

    useEffect(() => {
        const { errors } = validateNewQuery(query, portOperations);
        dispatch(commonActions.addOnlyErrors({ errorList: errors }));
    }, [query, portOperations]);
    const resetStateVariables = () => {
        setPortOperations([...addPortInitialData]);
        setQuery({ ...addQueryInitialData });
        // setStartDate(null)
        // setEndDate(null)
        setPortModal(false);
        setSelectedPort({});
        setSelectedIndex(0);
    };



    // useImperativeHandle(ref, () => ({
    const onSubmitQuery = async () => {
        setLoader(true);
        await actions
            .addCargoAnalysisDetails(cargoAnalysisData)
            .then((res) => {
                toastSuccess("Cargo analysis added successfully");
                onCloseModal();

                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            });
    }
    // }));

    const onChangePortOperation = (index, e) => {
        // if (isDetailView) return
        const { name = "", value = "", type = "" } = e.target;
        let updatedPortOperation = [...portOperations];
        updatedPortOperation[index][name] =
            type === "number" ? Number(value) : value;
        if (name === "terms") {
            const findFactor = termsCoEfficientTable.find(
                (item) => item.label === value
            );
            if (findFactor) {
                updatedPortOperation[index]["factor"] = findFactor.value;
            }
        }
        setPortOperations(updatedPortOperation);
    };




    const {
        qtyMode = "",
        fuelType = "TYPE0",
        lsFuelPriceType0 = 0,
        lsFuelPriceType1 = 0,
        lsFuelPriceType2 = 0,
        lsFuelPriceType3 = 0,
        lsFuelPriceType4 = 0,
        hsFuelPriceType0 = 0,
        hsFuelPriceType1 = 0,
        hsFuelPriceType2 = 0,
        hsFuelPriceType3 = 0,
        hsFuelPriceType4 = 0,
        mgoFuelPriceType0 = 0,
        mgoFuelPriceType1 = 0,
        mgoFuelPriceType2 = 0,
        mgoFuelPriceType3 = 0,
        mgoFuelPriceType4 = 0,
    } = cargoAnalysisData?.clientQuery || {};
    let fuelTypePrices = {
        TYPE0: {
            normal: hsFuelPriceType0 || 0,
            eca: lsFuelPriceType0 || 0,
            mgo: mgoFuelPriceType0 || 0,
        },
        TYPE1: {
            normal: hsFuelPriceType1 || 0,
            eca: lsFuelPriceType1 || 0,
            mgo: mgoFuelPriceType1 || 0,
        },
        TYPE2: {
            normal: hsFuelPriceType2 || 0,
            eca: lsFuelPriceType2 || 0,
            mgo: mgoFuelPriceType2 || 0,
        },
        TYPE3: {
            normal: hsFuelPriceType3 || 0,
            eca: lsFuelPriceType3 || 0,
            mgo: mgoFuelPriceType3 || 0,
        },
        TYPE4: {
            normal: hsFuelPriceType4 || 0,
            eca: lsFuelPriceType4 || 0,
            mgo: mgoFuelPriceType4 || 0,
        },
    };
    let showMinMax = false;
    if (qtyMode === "MINMAX") showMinMax = true;

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        if (activeStep === 1) {
            if (!cargoAnalysisData?.vessel) {
                return toastError("Please select vessel!")
            }
        }
        if (activeStep === 2) {
            if (!cargoAnalysisData?.vessel?.hirePerDay) {
                return toastError("Hire per day is required!")
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === 5) {
            onSubmitQuery()
        }
    };
    const handleReset = () => {
        setActiveStep(0);
    };


    const onChangeFuelPrice = (e) => {
        const { name = "", value = "" } = e.target;
        let newClientQuery = cargoAnalysisData?.clientQuery
        const currentType = 0
        if (name === "normal") {
            newClientQuery[`hsFuelPriceType${currentType}`] = parseFloat(value);
        }
        if (name === "eca") {
            newClientQuery[`lsFuelPriceType${currentType}`] = parseFloat(value);
        }
        if (name === "mgo") {
            newClientQuery[`mgoFuelPriceType${currentType}`] = parseFloat(value);
        }
        newClientQuery.fuelType = "TYPE0"

        setCargoAnalysisData({
            ...cargoAnalysisData,
            clientQuery: newClientQuery

        });

    };
    const {
        vesselName = "",
        vesselDwt = 0,
        vesselIMO = 0,
        vesselBalSpd1 = 0,
        vesselBalConsmHS1 = 0,
        vesselBalConsmLS1 = 0,
        vesselBalConsmMGO1 = 0,
        vesselBalSpd2 = 0,
        vesselBalConsmHS2 = 0,
        vesselBalConsmLS2 = 0,
        vesselBalConsmMGO2 = 0,
        vesselBalSpd3 = 0,
        vesselBalConsmHS3 = 0,
        vesselBalConsmLS3 = 0,
        vesselBalConsmMGO3 = 0,
        vesselBalSpd4 = 0,
        vesselBalConsmHS4 = 0,
        vesselBalConsmLS4 = 0,
        vesselBalConsmMGO4 = 0,
        vesselLdnSpd1 = 0,
        vesselLdnConsmHS1 = 0,
        vesselLdnConsmLS1 = 0,
        vesselLdnConsmMGO1 = 0,
        vesselLdnSpd2 = 0,
        vesselLdnConsmHS2 = 0,
        vesselLdnConsmLS2 = 0,
        vesselLdnConsmMGO2 = 0,
        vesselLdnSpd3 = 0,
        vesselLdnConsmHS3 = 0,
        vesselLdnConsmLS3 = 0,
        vesselLdnConsmMGO3 = 0,
        vesselLdnSpd4 = 0,
        vesselLdnConsmHS4 = 0,
        vesselLdnConsmLS4 = 0,
        vesselLdnConsmMGO4 = 0,
        vesselPIdleConsmHS = 0,
        vesselPIdleConsmLS = 0,
        vesselPIdleConsmMGO = 0,
        vesselPLdgConsmHS = 0,
        vesselPLdgConsmLS = 0,
        vesselPLdgConsmMGO = 0,
        vesselPDischConsmHS = 0,
        vesselPDischConsmLS = 0,
        vesselPDischConsmMGO = 0,
        vesselVExtraConsmHS = 0,
        vesselVExtraConsmLS = 0,
        vesselVExtraConsmMGO = 0,
        vesselBalComb1Selected = false,
        vesselBalComb2Selected = false,
        vesselBalComb3Selected = false,
        vesselBalComb4Selected = false,
        vesselLadComb1Selected = false,
        vesselLadComb2Selected = false,
        vesselLadComb3Selected = false,
        vesselLadComb4Selected = false,
        vesselExtraDetails = {},
    } = cargoAnalysisData?.vessel?.vessel ? JSON.parse(cargoAnalysisData?.vessel?.vessel) : {};


    const handleChange = (event) => {
        setCargoAnalysisData({ ...cargoAnalysisData, vessel: event.target.value })
    };

    const handleChangeFields = (event) => {
        let cargoAnalysis = cargoAnalysisData.vessel
        setCargoAnalysisData({ ...cargoAnalysisData, vessel: { ...cargoAnalysis, [event.target.name]: event.target.value } })
    };

    const handleChangeFieldsDwf = (event) => {
        let clientQuery = cargoAnalysisData.clientQuery
        for (let i = 0; i < clientQuery?.cargoList?.length; i++) {
            clientQuery.cargoList[i].dwf = event.target.value
        }
        setCargoAnalysisData({ ...cargoAnalysisData, clientQuery: clientQuery })
    };


    const debouncedSearch = useCallback(
        debounce((search) => {
            onSearchPortList(search);
        }, 400),
        []
    );

    const delayPortsSearch = (search) => {
        setSelectedPort({ ...selectedPort, port: search });
        if (search?.length > 2)
            debouncedSearch(search);
    };

    const onSearchPortList = (value) => {

        if (value) {
            setPortLoading(true);
            portsByChar(value)
                .then((res) => {
                    setPortLoading(false);
                    let portList = [];
                    if (res.data.list?.length > 0) {
                        portList = res.data.list.map((item) => ({
                            label: `${item.name} ${item.country ? "(" + item.country + ")" : ""
                                }`,
                            value: item.name,
                            lat: item?.lat,
                            lng: item?.lng
                        }));
                    }
                    setPortOptions(portList);
                })
                .catch((err) => {
                    setPortLoading(false);
                    setPortOptions([]);
                });
        }
    };

    const onSelectPortsOfOperation = (value) => {
        setSelectedPort({ ...selectedPort, port: value.value });
        let cargoAnalysis = cargoAnalysisData.vessel
        setCargoAnalysisData({ ...cargoAnalysisData, vessel: { ...cargoAnalysis, port: value.value, lat: value.lat, lng: value.lng } })
    };

    let newPortOptions = [...portOptions];
    let selectedPortName = { label: "", value: "" };
    if (selectedPort.port) {
        // newPortOptions = [...portOptions, { label: selectedPort.port, value: selectedPort.port }]
        selectedPortName = { label: selectedPort.port, value: selectedPort.port };
    }


    const onChangeHandler = (e) => {
        const { name: key = "", value = "" } = e.target;
        let newCargoDetails = {};
        let clientQuery = cargoAnalysisData?.clientQuery
        if (key === "fuelType") {
            const currentType = 0
            newCargoDetails[`fuelPriceType${currentType}Hsfo`] = Number(
                clientQuery[`hsFuelPriceType${currentType}`]
            );
            newCargoDetails[`fuelPriceType${currentType}Lsfo`] = Number(
                clientQuery[`lsFuelPriceType${currentType}`]
            );
            newCargoDetails[`fuelPriceType${currentType}Mgo`] = Number(
                clientQuery[`mgoFuelPriceType${currentType}`]
            );
        }


        setCargoAnalysisData({
            ...cargoAnalysisData,
            clientQuery: {
                ...cargoAnalysisData.clientQuery,
                [key]: value,
            }
        });
    };

    console.log('===cargoAnalysisData==>', cargoAnalysisData)
    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <Grid>
                    <Grid container >
                        <Grid item sm={1} style={{ fontSize: "14px" }}>
                        </Grid>
                        <Grid item sm={11} xs={12} style={{ fontSize: "14px" }}>
                            {/* <p className="mt-4 m-l-5 " style={{ fontSize: "15px" }}> Comments : </p> */}
                            <ul className="" style={{ fontSize: "14px", listStyleType: "circle" }}>
                                {/* {cargoFuelPriceStep?.comments?.map((itm) => { return (<li>{itm}</li>) })} */}
                                <li>Cargo Analysis will evaluate the estimated freight for this cargo on the basis of user provided inputs.</li>
                                <li>Please note this is NOT a freight indication. You may end up getting a very different freight indication in the prevailing shipping markets.
                                    Shipping markets are complex and freight level depends upon several factors including market sentiments, vessel availability, vessel preferences, regulatory regime, etc.</li>
                                <li>To be more clear, this is a freight calculation on a template vessel with the user given inputs. The purpose of this analysis is to give you a fair idea of the freight levels on a template vessel. The actual vessels in existence will have quite different characteristics and every ship owner may indicate his own workable freight number depending upon market condition and his own business acumen.</li>
                                <li>Primarily the freight is calculated basis the daily hire rate of ships, fuel prices, port expenses etc. On clicking next, we will guide you through the process to enter the values.</li>
                            </ul>
                            Disclaimer : The system provider does not take any liability for any miscalculation. The user shall be solely responsible for all calculations and/or misinterpretation of the results, caused for whatsoever reasons.
                        </Grid>
                    </Grid>
                </Grid>

            case 1:
                return <Grid container className="add-order-container">
                    <Grid item xs={12} md={1} style={{ padding: 5 }}>
                    </Grid>
                    <Grid item xs={12} md={3} style={{ padding: 5 }}>
                        <ul className="pt-10" style={{ fontSize: "14px", listStyleType: "circle" }}>
                            <li>

                                Please choose the most suitable vessel type from the drop down list. Although there are vessels available in the market for many sizes and dimensions, we have listed only the most prominent types. In reality, you may find vessels which do not fall in any of these categories.
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: 5 }}>

                        <Grid container>
                            <Grid item xs={6}>
                                <div style={{ display: "flex", flexDirection: "column", gap: "8px", padding: "5px" }}>
                                    <FormControl style={{ width: "100%", maxWidth: "300px" }}>
                                        <InputLabel className="pl-2" id="vessel-select-label">Select Vessel</InputLabel>
                                        <Select
                                            value={cargoAnalysisData?.vessel || ""}
                                            onChange={handleChange}
                                            name="analysisVessel"
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            className="custom-select"
                                        >
                                            {analysisVessel?.map((item, i) => {
                                                return (
                                                    <MenuItem dense value={item}>
                                                        {item?.vesselName}
                                                    </MenuItem>
                                                )
                                            })}


                                        </Select>
                                    </FormControl>
                                </div>

                            </Grid>
                        </Grid>
                        <div style={{ background: "lightgrey" }}>

                            <Paper>
                                <Grid container>
                                    {/* <Grid item xs={12}>
                            <div className="d-flex">
                                <h4 className="result-heading" style={{ textDecoration: "underline" }}><Link to={`/vessel/${vessel.id}`} target="_blank" >Vessel Details</Link></h4>
                                <div><button color="primary" className="add-vessel-calculator" onClick={() => setOpen(true)}>+</button></div>
                            </div>
                        </Grid> */}

                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <div className="vessel-name-div">
                                                    <span>Vessel</span>
                                                    <span>
                                                        <input
                                                            disabled={true}
                                                            value={vesselName}
                                                            type="text"
                                                            name="vesselName"
                                                        // onChange={onChangeHandler}
                                                        />
                                                    </span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <div className="vessel-name-div">
                                                    <span>Dwt</span>
                                                    <span>
                                                        <input
                                                            disabled={true}
                                                            value={vesselDwt}
                                                            name="vesselDwt"
                                                        // onChange={onChangeHandler}
                                                        />
                                                    </span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <div className="vessel-name-div">
                                                    <span>IMO</span>
                                                    <span>
                                                        <input
                                                            disabled={true}
                                                            value={vesselIMO}
                                                            name="vesselIMO"
                                                        // onChange={onChangeHandler}
                                                        />
                                                    </span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <table className="fleet-result-table vesselDetails">
                                            <tbody>
                                                {vesselCalculatorMainFields.map((item, key) => {
                                                    if (
                                                        item.name === "fuelType"
                                                        // item.name === "vesselScrubber"
                                                    )
                                                        return (
                                                            <tr key={key}>
                                                                <th>{item.label}</th>
                                                                <td>
                                                                    <input
                                                                        value={"Type-1"}
                                                                        type={item.type}
                                                                        // onKeyDown={handleKeyDown}
                                                                        name={item.name}
                                                                    // onChange={onChangeHandler}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    return (
                                                        <tr key={key}>
                                                            <th>{item.label}</th>
                                                            <td>
                                                                <input
                                                                    value={cargoAnalysisData?.vessel?.vessel ? JSON.parse(cargoAnalysisData?.vessel?.vessel)?.[item.name] : ""}
                                                                    type={item.type}
                                                                    // onKeyDown={handleKeyDown}
                                                                    name={item.name}
                                                                    readOnly={true}
                                                                    onChange={(e) => {
                                                                        e.preventDefault(); // Prevent the default behavior of the input change
                                                                        e.stopPropagation();
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p className="m-b-5" style={{ fontSize: 14 }}>
                                                    Ballast Sea Consumptions
                                                </p>
                                                <table className="vessel-details-tables">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Speed</th>
                                                            <th>HS</th>
                                                            <th>LS</th>
                                                            <th>MGO</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselBalComb1Selected"
                                                                    checked={vesselBalComb1Selected || false}
                                                                // onChange={(e) => onChangeHandler(e, false, true)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalSpd1"
                                                                    value={vesselBalSpd1 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmHS1"
                                                                    value={vesselBalConsmHS1 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmLS1"
                                                                    value={vesselBalConsmLS1 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmMGO1"
                                                                    value={vesselBalConsmMGO1 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselBalComb2Selected"
                                                                    checked={vesselBalComb2Selected || false}
                                                                // onChange={onChangeHandler}
                                                                // onChange={(e) => onChangeHandler(e, false, true)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalSpd2"
                                                                    value={vesselBalSpd2 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmHS2"
                                                                    value={vesselBalConsmHS2 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmLS2"
                                                                    value={vesselBalConsmLS2 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmMGO2"
                                                                    value={vesselBalConsmMGO2 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselBalComb3Selected"
                                                                    checked={vesselBalComb3Selected || false}
                                                                // onChange={onChangeHandler}
                                                                // onChange={(e) => onChangeHandler(e, false, true)}

                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalSpd3"
                                                                    value={vesselBalSpd3 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmHS3"
                                                                    value={vesselBalConsmHS3 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmLS3"
                                                                    value={vesselBalConsmLS3 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmMGO3"
                                                                    value={vesselBalConsmMGO3 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselBalComb4Selected"
                                                                    checked={vesselBalComb4Selected || false}
                                                                // onChange={onChangeHandler}
                                                                // onChange={(e) => onChangeHandler(e, false, true)}

                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalSpd4"
                                                                    value={vesselBalSpd4 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmHS4"
                                                                    value={vesselBalConsmHS4 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmLS4"
                                                                    value={vesselBalConsmLS4 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmMGO4"
                                                                    value={vesselBalConsmMGO4 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <p className="m-t-5 m-b-5" style={{ fontSize: 14 }}>
                                                    Laden Sea Consumptions
                                                </p>
                                                <table className="vessel-details-tables">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Speed</th>
                                                            <th>HS</th>
                                                            <th>LS</th>
                                                            <th>MGO</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselLadComb1Selected"
                                                                    checked={vesselLadComb1Selected || false}
                                                                // onChange={onChangeHandler}
                                                                // onChange={(e) => onChangeHandler(e, false, true)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnSpd1"
                                                                    value={vesselLdnSpd1 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmHS1"
                                                                    value={vesselLdnConsmHS1 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmLS1"
                                                                    value={vesselLdnConsmLS1 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmMGO1"
                                                                    value={vesselLdnConsmMGO1 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselLadComb2Selected"
                                                                    checked={vesselLadComb2Selected || false}
                                                                // onChange={onChangeHandler}
                                                                // onChange={(e) => onChangeHandler(e, false, true)}

                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnSpd2"
                                                                    value={vesselLdnSpd2 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmHS2"
                                                                    value={vesselLdnConsmHS2 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmLS2"
                                                                    value={vesselLdnConsmLS2 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmMGO2"
                                                                    value={vesselLdnConsmMGO2 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselLadComb3Selected"
                                                                    checked={vesselLadComb3Selected || false}
                                                                // onChange={onChangeHandler}
                                                                // onChange={(e) => onChangeHandler(e, false, true)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnSpd3"
                                                                    value={vesselLdnSpd3 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmHS3"
                                                                    value={vesselLdnConsmHS3 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmLS3"
                                                                    value={vesselLdnConsmLS3 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmMGO3"
                                                                    value={vesselLdnConsmMGO3 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselLadComb4Selected"
                                                                    checked={vesselLadComb4Selected || false}
                                                                // onChange={onChangeHandler}
                                                                // onChange={(e) => onChangeHandler(e, false, true)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnSpd4"
                                                                    value={vesselLdnSpd4 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmHS4"
                                                                    value={vesselLdnConsmHS4 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmLS4"
                                                                    value={vesselLdnConsmLS4 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmMGO4"
                                                                    value={vesselLdnConsmMGO4 || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p className="m-t-5 m-b-5" style={{ fontSize: 14 }}>
                                                    Port Consumptions
                                                </p>
                                                <table className="vessel-details-tables">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>HS</th>
                                                            <th>LS</th>
                                                            <th>MGO</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th>Idle</th>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPIdleConsmHS"
                                                                    value={vesselPIdleConsmHS || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPIdleConsmLS"
                                                                    value={vesselPIdleConsmLS || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPIdleConsmMGO"
                                                                    value={vesselPIdleConsmMGO || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Loading</th>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPLdgConsmHS"
                                                                    value={vesselPLdgConsmHS || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPLdgConsmLS"
                                                                    value={vesselPLdgConsmLS || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPLdgConsmMGO"
                                                                    value={vesselPLdgConsmMGO || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Disch</th>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPDischConsmHS"
                                                                    value={vesselPDischConsmHS || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPDischConsmLS"
                                                                    value={vesselPDischConsmLS || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPDischConsmMGO"
                                                                    value={vesselPDischConsmMGO || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Extra</th>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselVExtraConsmHS"
                                                                    value={vesselVExtraConsmHS || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselVExtraConsmLS"
                                                                    value={vesselVExtraConsmLS || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselVExtraConsmMGO"
                                                                    value={vesselVExtraConsmMGO || ""}
                                                                // onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </Grid>

                </Grid>
            case 2:
                return <Grid>
                    <Grid container className="add-order-container">
                        <Grid item xs={12} md={2} style={{ padding: 5 }}>

                        </Grid>
                        <Grid item xs={12} md={8} style={{ padding: 5 }}>

                            <div className="w-full flex justify-between mb-2 items-center mt-1 popup-cargo-list">
                                <div className=" text-neutrals4">Please enter Daily Hire Rates (Ref the below explanation)</div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className="add-order-container">

                        <Grid item xs={12} md={4} style={{ padding: 5 }}>

                        </Grid>
                        <Grid item xs={12} md={3} style={{ padding: 5 }}>

                            <table className="fleet-result-table vesselDetails mb-2">
                                <tbody>

                                    <tr>
                                        <th>Hire Per Day</th>
                                        <td>
                                            <input
                                                value={cargoAnalysisData?.vessel?.hirePerDay}
                                                type="number"
                                                onKeyDown={handleKeyDown}
                                                name={"hirePerDay"}
                                                onChange={handleChangeFields}
                                            />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </Grid>

                    </Grid>
                    <Grid container className="add-order-container">
                        <Grid item xs={12} md={1} style={{ padding: 5 }}>

                        </Grid>
                        <Grid item xs={12} md={10} style={{ padding: 5 }}>

                            <div className="w-full flex justify-between mb-2 items-center mt-1 popup-cargo-list" style={{ fontSize: "14px" }}>
                                <div>
                                    <p>
                                        Daily Hire Rates: </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Generally speaking, ships are highly capital intensive assets. Ship-owners strive to achieve the shortest payback period for their investments, thus they try to squeeze the best possible hire rate (i.e. Hire Per Day)  from the market. Ultimately it is a demand supply tussle which decides the final price, as a result of long bargaining and negotiations of the stakeholders. Albeit it is very difficult to guess at what hire rates, the ships are available, a cue may be taken from the daily published indices for the dry bulk market.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The published indices are applicable for the pre-defined routes, which may or may not coincide with desired cargo route, however you may carry your own statistical analysis to find any correlation between any one published routes and desired routes. If you have a good amount of past data points, you may derive your own alpha (α) and beta (ß) and apply it to the index hire to obtain your current route hire. (Beware of the Statistical fallacies)
                                        Please be aware the actual market scenario is highly dependent upon localised market conditions and may bear no relation with the index hires, however statistics may hold true sometimes in  deeper and matured markets.
                                    </p>
                                    <p>

                                        For a better understanding, please refer our help section.

                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
            case 3:
                return <Grid>
                    <Grid>
                        <Grid container className="add-order-container">
                            <Grid item xs={12} md={2} style={{ padding: 5 }}>

                            </Grid>
                            <Grid item xs={12} md={8} style={{ padding: 5 }}>

                                <div className=" text-neutrals4"> Please enter Vessel Opening Port and/or Ballast Factor.
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className="add-order-container">

                            <Grid item xs={12} md={3} style={{ padding: 5 }}>

                            </Grid>
                            <Grid item xs={12} md={5} style={{ padding: 5 }}>

                                <table className="fleet-result-table vesselDetails">
                                    <tbody>
                                        <tr >
                                            <th>Vessel Opening Port</th>
                                            <td>
                                                <Autocomplete
                                                    options={
                                                        portLoading || portOptions.length === 0 ? [] : newPortOptions
                                                    }
                                                    value={selectedPortName}
                                                    disableClearable
                                                    className="vesselvessel-tags"
                                                    style={{ marginLeft: 0, marginRight: "15px" }}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(e, value) => onSelectPortsOfOperation(value)}
                                                    getOptionSelected={(option, value) => option.value === value.value}
                                                    // inputValue={selectedPortName.port}
                                                    // onInputChange={}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            autoFocus
                                                            variant="outlined"
                                                            placeholder="Search Ports"
                                                            value={cargoAnalysisData?.vessel?.port}
                                                            onChange={(e) => delayPortsSearch(e.target.value)}
                                                            size="small"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {portLoading ? (
                                                                            <CircularProgress color="inherit" size={14} />
                                                                        ) : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>

                        </Grid>
                        <Grid container className="add-order-container">
                            <Grid item xs={12} md={1} style={{ padding: 5 }}>

                            </Grid>
                            <Grid item xs={12} md={10} style={{ padding: 5 }}>

                                <div className="w-full flex justify-between mb-2 items-center mt-1 popup-cargo-list" style={{ fontSize: "14px" }}>
                                    <div>
                                        <p>
                                            Vessel Opening Port : </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Please select the most prominent vessel opening port near to the loading port. If you are not sure, please select loading port, and give some value to Ballast Factor.
                                        </p>

                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid>

                        <Grid container className="add-order-container">

                            <Grid item xs={12} md={3} style={{ padding: 5 }}>

                            </Grid>
                            <Grid item xs={12} md={5} style={{ padding: 5 }}>

                                <table className="fleet-result-table vesselDetails">
                                    <tbody>
                                        <tr >
                                            <th>Ballast Factor</th>
                                            <td>
                                                <input
                                                    value={cargoAnalysisData?.clientQuery?.cargoList[0]?.dwf}
                                                    type="number"
                                                    onKeyDown={handleKeyDown}
                                                    name={"dwf"}
                                                    onChange={handleChangeFieldsDwf}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>

                        </Grid>
                        <Grid container className="add-order-container">
                            <Grid item xs={12} md={1} style={{ padding: 5 }}>

                            </Grid>
                            <Grid item xs={12} md={10} style={{ padding: 5 }}>

                                <div className="w-full flex justify-between mb-2 items-center mt-1 popup-cargo-list" style={{ fontSize: "14px" }}>
                                    <div>
                                        <p>
                                            Ballast Factor : </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  You may choose some ballast factor (%) to give it the same impact as the ballasting distance of the vessel from opening port to loading port. If not sure about this, we recommend 33%. Ultimately, the combination of Daily Hire Rate, Vessel opening port, Ballast Factor, Fuel Prices, Port Expenses and other expenses should determine the freight rate for this cargo order.
                                        </p>

                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            case 4:
                return <Grid>
                    <Grid>
                        <Grid container className="add-order-container">
                            <Grid item xs={12} md={1} style={{ padding: 5 }}>

                            </Grid>
                            <Grid item xs={12} md={8} style={{ padding: 5 }}>

                                <div className=" text-neutrals4"> Please Enter Fuel Prices applicable for this analysis
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className="add-order-container">

                            <Grid item xs={12} md={1} style={{ padding: 5 }}>

                            </Grid>
                            <Grid item xs={12} md={4} style={{ padding: 5 }}>

                                {/* <p className="m-t-5 m-l-5 m-b-5">Fuel Price</p> */}
                                <div className="consumptions-headers">
                                    <p></p>
                                    <p>Normal</p>
                                    <p>ECA</p>
                                    <p>MGO</p>
                                </div>

                                <div className="consumptions-content">
                                    <select
                                        value={fuelType}
                                        name="fuelType"
                                        className="margin-scrubber-select"
                                        onChange={onChangeHandler}
                                        disabled
                                    >
                                        {fuelPriceOptions.map((item, index) => {
                                            return (
                                                <option key={index} value={item.value}>
                                                    {item.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <input
                                        type="number"
                                        onKeyDown={handleKeyDown}
                                        style={{ background: "#fff" }}
                                        placeholder="0"
                                        name="normal"
                                        onChange={onChangeFuelPrice}
                                        value={fuelTypePrices?.[fuelType]?.normal}
                                    />
                                    <input
                                        type="number"
                                        onKeyDown={handleKeyDown}
                                        placeholder="0"
                                        name="eca"
                                        style={{ background: "#fff" }}
                                        onChange={onChangeFuelPrice}
                                        value={fuelTypePrices?.[fuelType]?.eca}
                                    />
                                    <input
                                        type="number"
                                        placeholder="0"
                                        style={{ background: "#fff" }}
                                        onKeyDown={handleKeyDown}
                                        name="mgo"
                                        onChange={onChangeFuelPrice}
                                        value={fuelTypePrices?.[fuelType]?.mgo}
                                    />
                                </div>


                            </Grid>
                            <Grid item xs={12} md={1} style={{ padding: 5 }}>

                            </Grid>
                            <Grid item xm={12} xs={6} style={{ fontSize: "14px" }}>
                                {/* <p className="mt-4 m-l-5 " style={{ fontSize: "15px" }}> Comments : </p> */}
                                <ul className="" style={{ fontSize: "14px", listStyleType: "circle" }}>
                                    {/* {cargoFuelPriceStep?.comments?.map((itm) => { return (<li>{itm}</li>) })} */}
                                    <li>
                                        HS (Normal) : Price for the fuel used in 0.5% emission Area
                                    </li>
                                    <li>
                                        LS (ECA) : Price for the fuel used in 0.1% emissioin area
                                    </li>
                                    <li>
                                        MGO : Price for the light fuel needed on board
                                    </li>
                                    <li>(Generally MGO consumption is miniscule, so you may skip this for estimate purpose)</li>
                                </ul>
                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid>
                        <Grid container className="add-order-container">
                            <Grid item xs={12} md={1} style={{ padding: 5 }}>

                            </Grid>
                            <Grid item xs={12} md={8} style={{ padding: 5 }}>

                                <div className=" text-neutrals4"> Please Enter Total Deductibles for deadweight
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className="add-order-container">

                            <Grid item xs={12} md={1} style={{ padding: 5 }}>

                            </Grid>
                            <Grid item xs={12} md={4} style={{ padding: 5 }}>

                                <table className="fleet-result-table vesselDetails mb-2">
                                    <tbody>

                                        <tr>
                                            <th>Deductibles for cargo carriage</th>
                                            <td>
                                                <input
                                                    value={cargoAnalysisData?.vessel?.bnkDelhs}
                                                    type="number"
                                                    onKeyDown={handleKeyDown}
                                                    name={"bnkDelhs"}
                                                    onChange={handleChangeFields}
                                                />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </Grid>
                            <Grid item xs={12} md={1} style={{ padding: 5 }}>

                            </Grid>
                            <Grid item xm={12} xs={6} style={{ fontSize: "14px" }}>
                                <ul className="" style={{ fontSize: "14px", listStyleType: "circle" }}>
                                    <li>
                                        (This quantity will be deducted from deadweight to find loadability)
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>


                    </Grid>

                </Grid>
            case 5:
                return <Grid container className="add-order-container">
                    <Grid item xs={12} style={{ fontSize: "14px" }}>
                        <div className="w-full flex justify-between  items-center mt-1 popup-cargo-list">

                            <div className="typo-18-500 text-neutrals4"> Port Expenses and Port Restrictions:</div>
                        </div>
                        <div className="mt-2 m-l-5 mb-1" style={{ fontSize: "14px" }}> Please enter approximate Port Expenses and other port restrictions in this table. The system will automatically apply these costs and restrictions to calculate the voyage cost, cargo loadability, and thus derive the freight Per Metric Ton.</div>

                    </Grid>
                    <Grid item xs={12}>
                        <div className="w-full flex justify-between mb-2 items-center mt-1 popup-cargo-list">
                            {/* <div className="typo-18-500 text-neutrals4">Port Expense</div> */}
                        </div>
                        <Box className="item-box-contianer cargobox-tables">
                            <TableContainer
                                component={Paper}
                                className="add-query-operations-table"
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ background: "#d1d1d1", color: "#000", fontWeight: "500" }}>Operation</TableCell>
                                            <TableCell style={{ background: "#d1d1d1", color: "#000", fontWeight: "500" }}>Port</TableCell>
                                            <TableCell style={{ background: "#d1d1d1", color: "#000", fontWeight: "500" }}>Extra Days</TableCell>
                                            <TableCell style={{ background: "#d1d1d1", color: "#000", fontWeight: "500" }}>Port Exp</TableCell>
                                            <TableCell style={{ background: "#d1d1d1", color: "#000", fontWeight: "500" }}>Port Emission</TableCell>
                                            <TableCell style={{ background: "#d1d1d1", color: "#000", fontWeight: "500" }}>Loadline</TableCell>
                                            <TableCell style={{ background: "#d1d1d1", color: "#000", fontWeight: "500" }}>Draft Restriction</TableCell>
                                            <TableCell style={{ background: "#d1d1d1", color: "#000", fontWeight: "500" }}>Water Density</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {portOperations.map((item, index) => {
                                            const {
                                                operation = "",
                                                port = "",
                                                // sulfur = "",
                                                // loadline = "",
                                                extraDays = "",
                                                waterDensity = "",
                                                draftRes,
                                                portExp = ""
                                            } = item || {};
                                            item.sulfur = item.sulfur ? item.sulfur : "Normal"
                                            item.loadline = item.loadline ? item.loadline : "loadline"
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <div className="port-change-div cursor-pointer">
                                                            <p>{operation}</p>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell style={{ width: 100 }}>

                                                        <div className="port-change-div cursor-pointer">
                                                            <p
                                                            // onClick={() =>
                                                            //     onSelectPortsOfOperations(item, index)
                                                            // }
                                                            >
                                                                {port}
                                                            </p>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell>
                                                        <input
                                                            type="number"
                                                            onKeyDown={handleKeyDown}
                                                            value={extraDays}
                                                            name="extraDays"
                                                            onChange={(e) => onChangePortOperation(index, e)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <input
                                                            type="number"
                                                            onKeyDown={handleKeyDown}
                                                            value={portExp}
                                                            name="portExp"

                                                            onChange={(e) => onChangePortOperation(index, e)}
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ width: 60 }}>
                                                        <select
                                                            name="sulfur"
                                                            value={item.sulfur}
                                                            onChange={(e) => onChangePortOperation(index, e)}
                                                        >
                                                            {portEmissionOptions.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.label}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </TableCell>
                                                    <TableCell style={{ width: 60 }}>
                                                        <select
                                                            name="loadline"
                                                            value={item?.loadline}
                                                            onChange={(e) => onChangePortOperation(index, e)}
                                                        >
                                                            {loadlineOptions.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.label}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </TableCell>
                                                    <TableCell>
                                                        <input
                                                            type="number"
                                                            onKeyDown={handleKeyDown}
                                                            value={draftRes}
                                                            name="draftRes"
                                                            onChange={(e) => onChangePortOperation(index, e)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <input
                                                            type="number"
                                                            onKeyDown={handleKeyDown}
                                                            value={waterDensity}
                                                            name="waterDensity"

                                                            onChange={(e) => onChangePortOperation(index, e)}
                                                        />
                                                    </TableCell>



                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>






                </Grid>
            default:
                return 'Unknown stepIndex';

        }
    }
    return (
        <Fragment>
            <div className="mb-4 flex justify-end w-full">
                <div className={classes.root}>
                    <Stepper activeStep={activeStep} className="stepper-background" style={{ background: "fff" }} alternativeLabel>
                        {steps.map((label) => (
                            <Step style={{ background: "fff" }} key={label}>
                                <StepLabel style={{ background: "fff" }}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>All steps completed</Typography>
                                <Button onClick={handleReset}>Reset</Button>
                            </div>
                        ) : (
                            <div>
                                <Typography className={classes.instructions} style={{ display: "flex", justifyContent: "center" }}>{getStepContent(activeStep)}</Typography>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    {activeStep == 0 ?
                                        <Button
                                            onClick={onCloseModal}
                                            className={classes.backButton}
                                        >
                                            Don't Agree!
                                        </Button>
                                        : <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.backButton}
                                        >
                                            Back
                                        </Button>}
                                    {activeStep == 0 ?
                                        <Button variant="contained" color="primary" onClick={handleNext}>
                                            Okay, I understand
                                        </Button>
                                        :
                                        <Button variant="contained" color="primary" onClick={handleNext}>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </Fragment>
    );
});

export default CreateCargoAnalysis;
