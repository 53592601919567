import React, { useState } from "react";
import { Link } from "@material-ui/core";
import CustomTextField from "../../components/Common/CustomTextField";
import CustomButton from "../../components/Common/CustomButton";
import * as actions from "./actions";
import { saveObject, toastError, toastSuccess } from "../../utils";
import { useHistory } from "react-router-dom";

const initialUser = {
  email: "",
};

function ForgotPassword() {
  const history = useHistory();

  const [user, setUser] = useState({ ...initialUser });
  const [loginLoader, setLoginLoader] = useState(false);
  // const [errors, setErrors] = useState({})

  const onChangeHandler = (e) => {
    const { name = "", value = "" } = e.target;
    setUser({ ...user, [name]: value });
    // setErrors({ ...errors, [name]: "" })
  };

  const keyPressed = (e) => {
    if (e.key === "Enter") {
      if (!loginLoader) {
        onConfirmEmail();
      }
    }
  };

  const onConfirmEmail = () => {
    if (!user.email) return;
    setLoginLoader(true);
    actions
      .forgetPassword(user)
      .then((res) => {
        setLoginLoader(false);
        toastSuccess("Sent a reset password link on your email address");
        setUser({ ...initialUser });
      })
      .catch((err) => {
        setLoginLoader(false);
      });
  };

  return (
    <div className={"authBgImage bg-cover bg-no-repeat flex bg-center"}>
      <div className="flex flex-col justify-center md:items-start items-center m-3 gap-10 w-full">
        <div className="border border-neutral-100 px-6 w-full sm:w-[500px] bg-white py-[26px] rounded-[48px] border-solid flex flex-col gap-10">
          <div className="my-2 text-center flex flex-col gap-2">
            <div className="typo-30-500">Forgot Password</div>
          </div>
          <div className="max-w-[344px] flex flex-col gap-10 mx-auto my-0 w-full">
            <CustomTextField
              name="email"
              placeholder="Email"
              label="Email"
              value={user.email}
              onChange={onChangeHandler}
              onKeyPress={keyPressed}
            />
            <div className="flex justify-center flex-col gap-1 items-center">
              <CustomButton
                onClick={onConfirmEmail}
                loader={loginLoader}
                disabled={loginLoader}
                className="bg-blue py-7 rounded-full w-60 mt-2"
              >
                Confirm Email
              </CustomButton>
              <div className="text-center mt-5">
                <div className="typo-16-400">
                  Remember Password?{" "}
                  <Link href="/login" className="text-blue">
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
