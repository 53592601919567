import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AddNewVessel from "../../../../components/Dashboard/AddNewVessel";
import DeleteIcon from "@material-ui/icons/Delete";

import { toastError, toastSuccess } from "../../../../utils";
import * as calculatorActions from "../../Calculator/actions";
import * as dashboardActions from "../../Dashboard/actions";
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import {
  vesselCalculatorMainFields,
  vesselDetailsOption1,
  vesselDetailsOption2,
} from "../../../../constants";
import { handleKeyDown } from "../../../../common/common";
import CustomButton from "../../../../components/Common/CustomButton";

const initialRowData = {
  grainCapacity: 0,
  baleCapacity: 0,
  length: 0,
  width: 0,
  hatchLength: 0,
  hatchWidth: 0,
  tankTopStrength: 0,
  deckStrength: 0,
  hatchCoverStrength: 0,
};

function SingleVessel(props) {
  const params = useParams();
  const { setLoader = () => { }, onClickAddFleet = () => { }, isFromCreateVessel = false } = props;
  const [vessel, setVessel] = useState({});

  useEffect(() => {
    if (params?.id) {
      setLoader(true);
      calculatorActions
        .getVesselsWithId(params.id, true)
        .then((res) => {
          setLoader(false);
          setVessel(res.data);
        })
        .catch((err) => {
          setLoader(false);
          toastError("Vessel information error");
        });
    }
  }, []);
  const onChangeHandlerExtraRow = (e, i) => {
    const { name = "", value = "" } = e.target;
    const updatedVessel = { ...vessel };
    const { vesselExtraDetails = {} } = updatedVessel;

    vesselExtraDetails.holdCapacity[i][name] = value;

    setVessel({
      ...updatedVessel,
      vesselExtraDetails: {
        ...vesselExtraDetails,
      },
    });
  };
  const onChangeHandler = (e, isExtra = false, isCheckbox) => {
    const { name = "", value = "", checked } = e.target;
    const updatedVessel = { ...vessel };
    const { vesselExtraDetails = {} } = updatedVessel;

    if (isExtra) {
      vesselExtraDetails[name] = isCheckbox ? checked : value;
    } else {
      updatedVessel[name] = isCheckbox ? checked : value;
    }
    setVessel({
      ...updatedVessel,
      vesselExtraDetails: {
        ...vesselExtraDetails,
      },
    });
  };

  const onAddNewRow = () => {
    const updatedVessel = { ...vessel };
    const { vesselExtraDetails = {} } = updatedVessel;
    const { holdCapacity = [] } = vesselExtraDetails;
    setVessel({
      ...updatedVessel,
      vesselExtraDetails: {
        ...vesselExtraDetails,
        holdCapacity: [...holdCapacity, { ...initialRowData }],
      },
    });
  };

  const onDeleteRow = (index) => {
    const updatedVessel = { ...vessel };
    const { vesselExtraDetails = {} } = updatedVessel;
    let { holdCapacity = [] } = vesselExtraDetails;
    holdCapacity.splice(index, 1);

    setVessel({
      ...updatedVessel,
      vesselExtraDetails: {
        ...vesselExtraDetails,
        holdCapacity: [...holdCapacity],
      },
    });
  };
  const onSubmitCreateVessel = () => {
    if (!vessel.vesselName) return toastError("Vessel Name is required");
    if (vessel) {
      setLoader(true);
      dashboardActions
        .createVessel(vessel)
        .then((res) => {
          toastSuccess("Vessel Created Successfully");
          setLoader(false);
          onClickAddFleet(false)
        })
        .catch((err) => {
          setLoader(false);
          toastError();
          onClickAddFleet(false)
        });
    }
  }
  const onSubmitUpdateVessel = () => {
    if (vessel) {
      setLoader(true);
      dashboardActions
        .updateVesselWithFleetDetails(vessel)
        .then((res) => {
          toastSuccess("Vessel Updated Successfully");
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          toastError();
        });
    }
  };

  const {
    vesselName = "",
    vesselDwt = 0,
    vesselIMO = 0,
    vesselBalSpd1 = 0,
    vesselBalConsmHS1 = 0,
    vesselBalConsmLS1 = 0,
    vesselBalConsmMGO1 = 0,
    vesselBalSpd2 = 0,
    vesselBalConsmHS2 = 0,
    vesselBalConsmLS2 = 0,
    vesselBalConsmMGO2 = 0,
    vesselBalSpd3 = 0,
    vesselBalConsmHS3 = 0,
    vesselBalConsmLS3 = 0,
    vesselBalConsmMGO3 = 0,
    vesselBalSpd4 = 0,
    vesselBalConsmHS4 = 0,
    vesselBalConsmLS4 = 0,
    vesselBalConsmMGO4 = 0,
    vesselLdnSpd1 = 0,
    vesselLdnConsmHS1 = 0,
    vesselLdnConsmLS1 = 0,
    vesselLdnConsmMGO1 = 0,
    vesselLdnSpd2 = 0,
    vesselLdnConsmHS2 = 0,
    vesselLdnConsmLS2 = 0,
    vesselLdnConsmMGO2 = 0,
    vesselLdnSpd3 = 0,
    vesselLdnConsmHS3 = 0,
    vesselLdnConsmLS3 = 0,
    vesselLdnConsmMGO3 = 0,
    vesselLdnSpd4 = 0,
    vesselLdnConsmHS4 = 0,
    vesselLdnConsmLS4 = 0,
    vesselLdnConsmMGO4 = 0,
    vesselPIdleConsmHS = 0,
    vesselPIdleConsmLS = 0,
    vesselPIdleConsmMGO = 0,
    vesselPLdgConsmHS = 0,
    vesselPLdgConsmLS = 0,
    vesselPLdgConsmMGO = 0,
    vesselPDischConsmHS = 0,
    vesselPDischConsmLS = 0,
    vesselPDischConsmMGO = 0,
    vesselVExtraConsmHS = 0,
    vesselVExtraConsmLS = 0,
    vesselVExtraConsmMGO = 0,
    vesselBalComb1Selected = false,
    vesselBalComb2Selected = false,
    vesselBalComb3Selected = false,
    vesselBalComb4Selected = false,
    vesselLadComb1Selected = false,
    vesselLadComb2Selected = false,
    vesselLadComb3Selected = false,
    vesselLadComb4Selected = false,
    vesselExtraDetails = {},
  } = vessel || {};

  const {
    holdCapacity = [],
    vesselTextDescription = "",
    tropicalFreshDraft = 0,
    tropicalFreshDisplacement = 0,
    tropicalFreshDwt = 0,
    tropicalFreshFreeBoard = 0,
    freshDraft = 0,
    freshDisplacement = 0,
    freshDwt = 0,
    freshFreeBoard = 0,
    tropicalDraft = 0,
    tropicalDisplacement = 0,
    tropicalDwt = 0,
    tropicalFreeBoard = 0,
    summerDraft = 0,
    summerDisplacement = 0,
    summerDwt = 0,
    summerFreeBoard = 0,
    winterDraft = 0,
    winterDisplacement = 0,
    winterDwt = 0,
    winterFreeBoard = 0,
    winterNorthAtlanticDraft = 0,
    winterNorthAtlanticDisplacement = 0,
    winterNorthAtlanticDwt = 0,
    winterNorthAtlanticFreeBoard = 0,
  } = vesselExtraDetails;
  return (
    <Grid container style={{ height: "calc(100vh -50px)" }}>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
        {isFromCreateVessel ?
          <div style={{ width: "135px" }}>
            <CustomButton onClick={() => onSubmitCreateVessel()}>Create Vessel</CustomButton>
          </div>
          // <button onClick={onSubmitCreateVessel}>Create Vessel</button> 
          :
          <button onClick={onSubmitUpdateVessel}>Update Vessel</button>}
      </Grid>
      <Grid item xs={12} md={8} style={{ height: "100%" }}>
        {/* <Button>Add Vessel</Button> */}
        <Grid container>
          <Grid item xs={12} md={6} style={{ padding: 5 }}>
            <div style={{ background: "lightgrey" }}>
              <Paper>
                <Grid container>
                  {/* <Grid item xs={12}>
                                        <div className="d-flex">
                                            <h4 className="result-heading" style={{ textDecoration: "underline" }}><Link to={`/vessel/${vessel.id}`} target="_blank" >Vessel Details</Link></h4>
                                            <div><button color="primary" className="add-vessel-calculator" onClick={() => setOpen(true)}>+</button></div>
                                        </div>
                                    </Grid> */}

                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="vessel-name-div">
                          <span>Vessel</span>
                          <span>
                            <input
                              disabled={!isFromCreateVessel}
                              value={vesselName}
                              type="text"
                              name="vesselName"
                              onChange={onChangeHandler}
                            />
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="vessel-name-div">
                          <span>Dwt</span>
                          <span>
                            <input
                              disabled={!isFromCreateVessel}
                              value={vesselDwt}
                              name="vesselDwt"
                              onChange={onChangeHandler}
                            />
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="vessel-name-div">
                          <span>IMO</span>
                          <span>
                            <input
                              disabled={!isFromCreateVessel}
                              value={vesselIMO}
                              name="vesselIMO"
                              onChange={onChangeHandler}
                            />
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={5}>
                    <table className="fleet-result-table vesselDetails">
                      <tbody>
                        {vesselCalculatorMainFields.map((item, key) => {
                          if (
                            item.name === "fuelType" ||
                            item.name === "vesselScrubber"
                          )
                            return null;
                          return (
                            <tr key={key}>
                              <th>{item.label}</th>
                              <td>
                                <input
                                  value={vessel[item.name] || ""}
                                  type={item.type}
                                  onKeyDown={handleKeyDown}
                                  name={item.name}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container>
                      <Grid item xs={12}>
                        <p className="m-b-5" style={{ fontSize: 14 }}>
                          Ballast Sea Consumptions
                        </p>
                        <table className="vessel-details-tables">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Speed</th>
                              <th>HS</th>
                              <th>LS</th>
                              <th>MGO</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  className="input-type-checkbox"
                                  name="vesselBalComb1Selected"
                                  checked={vesselBalComb1Selected || false}
                                  onChange={(e) => onChangeHandler(e, false, true)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalSpd1"
                                  value={vesselBalSpd1 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalConsmHS1"
                                  value={vesselBalConsmHS1 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalConsmLS1"
                                  value={vesselBalConsmLS1 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalConsmMGO1"
                                  value={vesselBalConsmMGO1 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  className="input-type-checkbox"
                                  name="vesselBalComb2Selected"
                                  checked={vesselBalComb2Selected || false}
                                  // onChange={onChangeHandler}
                                  onChange={(e) => onChangeHandler(e, false, true)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalSpd2"
                                  value={vesselBalSpd2 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalConsmHS2"
                                  value={vesselBalConsmHS2 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalConsmLS2"
                                  value={vesselBalConsmLS2 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalConsmMGO2"
                                  value={vesselBalConsmMGO2 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  className="input-type-checkbox"
                                  name="vesselBalComb3Selected"
                                  checked={vesselBalComb3Selected || false}
                                  // onChange={onChangeHandler}
                                  onChange={(e) => onChangeHandler(e, false, true)}

                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalSpd3"
                                  value={vesselBalSpd3 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalConsmHS3"
                                  value={vesselBalConsmHS3 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalConsmLS3"
                                  value={vesselBalConsmLS3 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalConsmMGO3"
                                  value={vesselBalConsmMGO3 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  className="input-type-checkbox"
                                  name="vesselBalComb4Selected"
                                  checked={vesselBalComb4Selected || false}
                                  // onChange={onChangeHandler}
                                  onChange={(e) => onChangeHandler(e, false, true)}

                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalSpd4"
                                  value={vesselBalSpd4 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalConsmHS4"
                                  value={vesselBalConsmHS4 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalConsmLS4"
                                  value={vesselBalConsmLS4 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselBalConsmMGO4"
                                  value={vesselBalConsmMGO4 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid>

                      <Grid item xs={12}>
                        <p className="m-t-5 m-b-5" style={{ fontSize: 14 }}>
                          Laden Sea Consumptions
                        </p>
                        <table className="vessel-details-tables">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Speed</th>
                              <th>HS</th>
                              <th>LS</th>
                              <th>MGO</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  className="input-type-checkbox"
                                  name="vesselLadComb1Selected"
                                  checked={vesselLadComb1Selected || false}
                                  // onChange={onChangeHandler}
                                  onChange={(e) => onChangeHandler(e, false, true)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnSpd1"
                                  value={vesselLdnSpd1 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnConsmHS1"
                                  value={vesselLdnConsmHS1 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnConsmLS1"
                                  value={vesselLdnConsmLS1 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnConsmMGO1"
                                  value={vesselLdnConsmMGO1 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  className="input-type-checkbox"
                                  name="vesselLadComb2Selected"
                                  checked={vesselLadComb2Selected || false}
                                  // onChange={onChangeHandler}
                                  onChange={(e) => onChangeHandler(e, false, true)}

                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnSpd2"
                                  value={vesselLdnSpd2 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnConsmHS2"
                                  value={vesselLdnConsmHS2 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnConsmLS2"
                                  value={vesselLdnConsmLS2 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnConsmMGO2"
                                  value={vesselLdnConsmMGO2 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  className="input-type-checkbox"
                                  name="vesselLadComb3Selected"
                                  checked={vesselLadComb3Selected || false}
                                  // onChange={onChangeHandler}
                                  onChange={(e) => onChangeHandler(e, false, true)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnSpd3"
                                  value={vesselLdnSpd3 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnConsmHS3"
                                  value={vesselLdnConsmHS3 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnConsmLS3"
                                  value={vesselLdnConsmLS3 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnConsmMGO3"
                                  value={vesselLdnConsmMGO3 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  className="input-type-checkbox"
                                  name="vesselLadComb4Selected"
                                  checked={vesselLadComb4Selected || false}
                                  // onChange={onChangeHandler}
                                  onChange={(e) => onChangeHandler(e, false, true)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnSpd4"
                                  value={vesselLdnSpd4 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnConsmHS4"
                                  value={vesselLdnConsmHS4 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnConsmLS4"
                                  value={vesselLdnConsmLS4 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselLdnConsmMGO4"
                                  value={vesselLdnConsmMGO4 || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid>
                      <Grid item xs={12}>
                        <p className="m-t-5 m-b-5" style={{ fontSize: 14 }}>
                          Port Consumptions
                        </p>
                        <table className="vessel-details-tables">
                          <thead>
                            <tr>
                              <th></th>
                              <th>HS</th>
                              <th>LS</th>
                              <th>MGO</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Idle</th>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselPIdleConsmHS"
                                  value={vesselPIdleConsmHS || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselPIdleConsmLS"
                                  value={vesselPIdleConsmLS || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselPIdleConsmMGO"
                                  value={vesselPIdleConsmMGO || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Loading</th>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselPLdgConsmHS"
                                  value={vesselPLdgConsmHS || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselPLdgConsmLS"
                                  value={vesselPLdgConsmLS || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselPLdgConsmMGO"
                                  value={vesselPLdgConsmMGO || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Disch</th>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselPDischConsmHS"
                                  value={vesselPDischConsmHS || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselPDischConsmLS"
                                  value={vesselPDischConsmLS || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselPDischConsmMGO"
                                  value={vesselPDischConsmMGO || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Extra</th>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselVExtraConsmHS"
                                  value={vesselVExtraConsmHS || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselVExtraConsmLS"
                                  value={vesselVExtraConsmLS || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  placeholder="0"
                                  name="vesselVExtraConsmMGO"
                                  value={vesselVExtraConsmMGO || ""}
                                  onChange={onChangeHandler}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12} md={6} style={{ padding: 5 }}>
                <Paper>
                  <table className="vessel-details-tables">
                    <tbody>
                      {vesselDetailsOption1.map((item) => {
                        const value = item.isExtra
                          ? vesselExtraDetails[item.name]
                          : vessel[item.name];
                        const isDisabled = item.isDisabled || false;
                        return (
                          <tr>
                            <th style={{ width: 120 }}>{item.label}</th>
                            <td>
                              <input
                                type="number"
                                onKeyDown={handleKeyDown}
                                placeholder="0"
                                disabled={isDisabled}
                                name={item.name}
                                value={value || ""}
                                onChange={(e) =>
                                  onChangeHandler(e, item.isExtra)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: 5 }}>
                <Paper>
                  <table className="vessel-details-tables">
                    <tbody>
                      {vesselDetailsOption2.map((item) => {
                        const value = item.isExtra
                          ? vesselExtraDetails[item.name]
                          : vessel[item.name];
                        const isDisabled = item.isDisabled || false;
                        return (
                          <tr>
                            <th style={{ width: 120 }}>{item.label}</th>
                            <td>
                              <input
                                type="number"
                                onKeyDown={handleKeyDown}
                                placeholder="0"
                                disabled={isDisabled}
                                name={item.name}
                                value={value || ""}
                                onChange={(e) =>
                                  onChangeHandler(e, item.isExtra)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} style={{ padding: 5 }}>
                <Paper>
                  <table className="vessel-details-tables">
                    <thead>
                      <tr>
                        <th>Load Line</th>
                        <th>Draft</th>
                        <th>Displacement</th>
                        <th>Dwt</th>
                        <th>Freeboard</th>
                        <th>TPC</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: 180 }}>
                          TF - Tropical Fresh Water
                        </th>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="tropicalFreshDraft"
                            value={tropicalFreshDraft || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="tropicalFreshDisplacement"
                            value={tropicalFreshDisplacement || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="tropicalFreshDwt"
                            value={tropicalFreshDwt || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="tropicalFreshFreeBoard"
                            value={tropicalFreshFreeBoard || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0" /* name="tropicalFreshDraft" value={tropicalFreshDraft || ''} onChange={(e) => onChangeHandler(e, true)} */
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: 150 }}>F - Fresh Water</th>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="freshDraft"
                            value={freshDraft || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="freshDisplacement"
                            value={freshDisplacement || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="freshDwt"
                            value={freshDwt || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="freshFreeBoard"
                            value={freshFreeBoard || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0" /* name="tropicalFreshDraft" value={tropicalFreshDraft || ''} onChange={(e) => onChangeHandler(e, true)} */
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: 180 }}>T - Tropical</th>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="tropicalDraft"
                            value={tropicalDraft || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="tropicalDisplacement"
                            value={tropicalDisplacement || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="tropicalDwt"
                            value={tropicalDwt || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="tropicalFreeBoard"
                            value={tropicalFreeBoard || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0" /* name="tropicalFreshDraft" value={tropicalFreshDraft || ''} onChange={(e) => onChangeHandler(e, true)} */
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: 180 }}>S - Summer</th>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="summerDraft"
                            value={summerDraft || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="summerDisplacement"
                            value={summerDisplacement || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="summerDwt"
                            value={summerDwt || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="summerFreeBoard"
                            value={summerFreeBoard || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0" /* name="tropicalFreshDraft" value={tropicalFreshDraft || ''} onChange={(e) => onChangeHandler(e, true)} */
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: 180 }}>W - Winter</th>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="winterDraft"
                            value={winterDraft || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="winterDisplacement"
                            value={winterDisplacement || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="winterDwt"
                            value={winterDwt || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="winterFreeBoard"
                            value={winterFreeBoard || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0" /* name="tropicalFreshDraft" value={tropicalFreshDraft || ''} onChange={(e) => onChangeHandler(e, true)} */
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: 180 }}>
                          WNA - Winter North Atlantic
                        </th>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="winterNorthAtlanticDraft"
                            value={winterNorthAtlanticDraft || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="winterNorthAtlanticDisplacement"
                            value={winterNorthAtlanticDisplacement || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="winterNorthAtlanticDwt"
                            value={winterNorthAtlanticDwt || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0"
                            name="winterNorthAtlanticFreeBoard"
                            value={winterNorthAtlanticFreeBoard || ""}
                            onChange={(e) => onChangeHandler(e, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            placeholder="0" /* name="tropicalFreshDraft" value={tropicalFreshDraft || ''} onChange={(e) => onChangeHandler(e, true)} */
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: "5px 5px 0px 5px", display: "flex", justifyContent: "end" }}>
          <button style={{
            background: "#e7e7e7",
            color: "#000",
            borderRadius: "4px",
            padding: "4px",
            fontSize: "14px",
            fontWeight: "400"
          }} onClick={onAddNewRow}>+Add Row</button>

        </Grid>
        <Grid item xs={12} style={{ padding: 5 }}>
          <Paper>
            <table className="vessel-details-tables">
              <thead>
                <tr>
                  <th></th>
                  <th>Grain Capacity</th>
                  <th>Bale Capacity</th>
                  <th>Length (m)</th>
                  <th>Width (m)</th>
                  <th>Hatch Length (m)</th>
                  <th>Hatch Width (m)</th>
                  <th>Tanktop Strength (MT)</th>
                  <th>W/Deck Strength(MT)</th>
                  <th>Hatch Cover Strength(MT/m2)</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(holdCapacity) &&
                  holdCapacity.map((holds, index) => {
                    const {
                      grainCapacity = 0,
                      baleCapacity = 0,
                      length = 0,
                      width = 0,
                      hatchLength = 0,
                      hatchWidth = 0,
                      tankTopStrength = 0,
                      deckStrength = 0,
                      hatchCoverStrength = 0,
                    } = holds;
                    return isFromCreateVessel ?
                      (<tr key={index}>
                        <td></td>
                        <td>
                          {/* {grainCapacity} */}

                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => onChangeHandlerExtraRow(e, index)}
                            placeholder="0"
                            value={grainCapacity}
                            name="grainCapacity"
                          />
                        </td>
                        <td>
                          {/* {baleCapacity} */}
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => onChangeHandlerExtraRow(e, index)}
                            placeholder="0"
                            value={baleCapacity}
                            name="baleCapacity"
                          />
                        </td>
                        <td>
                          {/* {length} */}
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => onChangeHandlerExtraRow(e, index)}
                            placeholder="0"
                            value={length}
                            name="length"
                          />
                        </td>
                        <td>
                          {/* {width} */}
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => onChangeHandlerExtraRow(e, index)}
                            placeholder="0"
                            value={width}
                            name="width"
                          />
                        </td>
                        <td>
                          {/* {hatchLength} */}
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => onChangeHandlerExtraRow(e, index)}
                            placeholder="0"
                            value={hatchLength}
                            name="hatchLength"
                          />
                        </td>
                        <td>
                          {/* {hatchWidth} */}
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => onChangeHandlerExtraRow(e, index)}
                            placeholder="0"
                            value={hatchWidth}
                            name="hatchWidth"
                          />
                        </td>
                        <td>
                          {/* {tankTopStrength} */}
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => onChangeHandlerExtraRow(e, index)}
                            placeholder="0"
                            value={tankTopStrength}
                            name="tankTopStrength"
                          />
                        </td>
                        <td>
                          {/* {deckStrength} */}
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => onChangeHandlerExtraRow(e, index)}
                            placeholder="0"
                            value={deckStrength}
                            name="deckStrength"
                          />
                        </td>
                        <td >
                          {/* {hatchCoverStrength} */}
                          <input
                            type="number"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => onChangeHandlerExtraRow(e, index)}
                            placeholder="0"
                            value={hatchCoverStrength}
                            name="hatchCoverStrength"
                          />
                        </td>
                        <td style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "16px",
                          cursor:"pointer"
                        }} >
                          <DeleteIcon
                            color="primary"
                            fontSize="inherit"
                            onClick={() => onDeleteRow(index)}
                          /></td>

                      </tr>)
                      : (<tr key={index}>
                        <td></td>
                        <td>{grainCapacity}</td>
                        <td>{baleCapacity}</td>
                        <td>{length}</td>
                        <td>{width}</td>
                        <td>{hatchLength}</td>
                        <td>{hatchWidth}</td>
                        <td>{tankTopStrength}</td>
                        <td>{deckStrength}</td>
                        <td>{hatchCoverStrength}</td>
                        <td style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "16px",
                          cursor:"pointer"
                        }} >
                          <DeleteIcon
                            color="primary"
                            fontSize="inherit"
                            onClick={() => onDeleteRow(index)}
                          /></td>
                      </tr>)

                  })}
              </tbody>
            </table>
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: 5 }}>
        <Paper style={{ background: "white" }}>
          {/* <h4>Text Description</h4> */}
          {/* <p style={{ whiteSpace: "pre-line", marginTop: 8 }}> */}
          {/* {vesselTextDescription} */}
          {/* <input
            type="textarea"

            // onKeyDown={handleKeyDown}
            placeholder="Text Description"
            name="vesselTextDescription"
            value={vesselTextDescription || ""}
            onChange={(e) => onChangeHandler(e, true)}
          /> */}
          <TextField
            disabled={!isFromCreateVessel}
            id="fullSizeTextarea"
            label="Text Description"
            name="vesselTextDescription"
            value={vesselTextDescription}
            multiline
            rows={25} // Adjust the number of rows as needed
            fullWidth
            className="vessel-text-descriptions"
            onChange={(e) => onChangeHandler(e, true)}
            variant="outlined"
          />
          {/* </p> */}
        </Paper>
      </Grid>
    </Grid >
  );
}
{
  /* <AddNewVessel vessel={vessel} onChangeHandler={() => { }} showDetails /> */
}

export default SingleVessel;
