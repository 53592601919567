import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import {
  portOperationHeader,
  portOperationDisabledFields,
  operationsOptions,
  loadlineOptions,
  termsCoEfficientTable,
  portEmissionOptions,
} from "../../constants";
import errorIcon from '../../assets/images/info-circle-line-icon.svg'
import PlusIcon from '../../assets/icons/plus.png'

import { handleKeyDown } from "../../common/common";

const DragHandle = SortableHandle(() => (
  <span>
    <MenuIcon />
  </span>
));

const SortableItem = SortableElement((props) => {
  return <div className="sortable-row-tr">{props.children}</div>;
});

const styles = (index) => {
  const width = portOperationHeader[index].width;
  return { minWidth: width, width };
};

const DraggableList = SortableContainer((props) => {
  let {
    combinedOperation = [],
    onDeleteOperations = () => { },
    onChangePortOperations = () => { },
    onClickSinglePort = () => { },
    showFreightSelection = false,
    cargoTxOverride = false,
    dischargedPortIndexes = [],
    setOpenAddOperation = ()=>{},
    setOpenAddOperationindex = ()=>{},
    disableEdit = false
  } = props;

  return (
    <div className="sortable-div">
      {combinedOperation &&
        combinedOperation.map((operations, index) => {
          const checkDisabled =
            portOperationDisabledFields[operations.operation] || {};
          const {
            bunkerSuppliedHSFO = 0,
            bunkerSuppliedLSFO = 0,
            bunkerSuppliedMGO = 0,
            distNormal = 0,
            distEca = 0,
          } = operations.cargoDetails || {};
          const totalSupply =
            Number(bunkerSuppliedHSFO) +
            Number(bunkerSuppliedLSFO) +
            Number(bunkerSuppliedMGO);

          let dischargedPort = dischargedPortIndexes.includes(index);
          let operationLbmarker =
            operations.lbmarker === "ballast"
              ? "B"
              : operations.lbmarker === "laden"
                ? "L"
                : "";
          return (
            <SortableItem key={index} index={index}>
             {!disableEdit? <div className="sortable-div-operation operation-input-div custom-tooltip-div" onClick={() => {setOpenAddOperation(true); setOpenAddOperationindex(index+1);}} style={styles(0)}>
                <img src={PlusIcon} className="operation-plus-icon" slt="" />
                <div className="tooltip-notification">
                  <p>Add operation in next row</p>
                </div>
              </div>:""}
              <div className="operation-input-div" style={styles(1)}>
                {index === 0 ? <MenuIcon /> : <DragHandle />}
              </div>
              <div className="operation-input-div" style={styles(2)}>
                {operationLbmarker}
              </div>{" "}
              {/* NO */}
              <div className="operation-input-div" style={styles(3)}>
                {/* {operations.operation || 0} */}
                <select
                  value={operations.operation || ""}
                  name="operation"
                  onChange={(e) => onChangePortOperations(index, e)}
                  disabled={checkDisabled.operation}
                >
                  {operationsOptions.map((option, key) => (
                    <option
                      key={key}
                      value={option.value}
                      disabled={option.isDisabled}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="operation-input-div" style={styles(4)}>
                <p
                  className="add-three-dots"
                  onClick={() => {
                    if (
                      index === 0 ||
                      operations.operation === "Loading" ||
                      operations.operation === "Discharging"
                    )
                      return;
                    onClickSinglePort(operations);
                  }}
                >
                  {operations.port || ""}
                </p>
              </div>
              <div className="operation-input-div" style={styles(5)}>
                {/* dist normal */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={distNormal || ""}
                  placeholder="0"
                  disabled={!!checkDisabled.distNormal}
                  name="distNormal"
                  onChange={(e) => onChangePortOperations(index, e, true)}
                />
              </div>
              <div className="operation-input-div" style={styles(6)}>
                {/* {(operations.cargoDetails && operations.cargoDetails.distEca) || 0} */}
                <input
                  type="number"
                  value={distEca || ""}
                  onKeyDown={handleKeyDown}
                  placeholder="0"
                  disabled={!!checkDisabled.distEca}
                  name="distEca"
                  onChange={(e) => onChangePortOperations(index, e, true)}
                />
              </div>
              <div className="operation-input-div" style={styles(7)}>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.dwf || ""}
                  disabled={!!checkDisabled.dwf}
                  name="dwf"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div" style={styles(8)}>
                {/*  speed normal */}
                {/* <input
                                type="number"
                                value={operations.dwf}
                                onKeyDown={handleKeyDown}
                                disabled={!!checkDisabled.dwf}
                                name="dwf"
                                onChange={e => onChangePortOperations(index, e)}
                            /> */}
                {operations.speedNormal || 0}
              </div>
              <div className="operation-input-div" style={styles(9)}>
                {/*  speed eca */}
                {/* <input
                                type="number"
                                value={operations.dwf}
                                onKeyDown={handleKeyDown}
                                disabled={!!checkDisabled.dwf}
                                name="dwf"
                                onChange={e => onChangePortOperations(index, e)}
                            /> */}
                {operations.speedECA || 0}
              </div>
              <div className="operation-input-div" style={styles(10)}>
                {/*  cargo tx */}
                {cargoTxOverride && dischargedPort ? (
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.cargoTxOverriddenValue || ""}
                    // disabled={!!checkDisabled.dwf}
                    placeholder="0"
                    name="cargoTxOverriddenValue"
                    onChange={(e) => onChangePortOperations(index, e)}
                  />
                ) : (
                  operations.cargoTx || ""
                )}
              </div>
              <div className="operation-input-div" style={styles(11)}>
                {/*  rate MT day */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.rate || ""}
                  disabled={!!checkDisabled.rate}
                  name="rate"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div" style={styles(12)}>
                {/* {operations.terms}  */}
                <select
                  value={operations.terms || ""}
                  name="terms"
                  onChange={(e) => onChangePortOperations(index, e)}
                  disabled={checkDisabled.terms}
                >
                  {termsCoEfficientTable.map((option, key) => (
                    <option key={key} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="operation-input-div" style={styles(13)}>
                <p>{operations.factor}</p>
              </div>
              <div className="operation-input-div" style={styles(14)}>
                {/* {operations.turnTime} */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.turnTime || ""}
                  disabled={!!checkDisabled.turnTime}
                  name="turnTime"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              {showFreightSelection ? (
                <div className="operation-input-div" style={styles(14)}>
                  {/* {operations.turnTime} */}
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.freight || ""}
                    disabled={!!checkDisabled.freight}
                    name="freight"
                    placeholder="0"
                    onChange={(e) => onChangePortOperations(index, e)}
                  />
                </div>
              ) : null}
              <div className="operation-input-div" style={styles(15)}>
                {/* {operations.extraDays} */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.extraDays || ""}
                  disabled={!!checkDisabled.extraDays}
                  name="extraDays"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div" style={styles(16)}>
                {/* {operations.portExp} */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.portExp || ""}
                  disabled={!!checkDisabled.portExp}
                  name="portExp"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div" style={styles(17)}>
                <p> {operations.portStay || 0}</p>
              </div>
              <div className="operation-input-div" style={styles(18)}>
                {/*  Port Emission */}
                <select
                  value={operations.sulfur || ""}
                  name="sulfur"
                  onChange={(e) => onChangePortOperations(index, e)}
                  disabled={checkDisabled.sulfur}
                >
                  {portEmissionOptions.map((option, key) => (
                    <option key={key} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="operation-input-div" style={styles(19)}>
                {/* {operations.loadline} */}
                <select
                  value={operations.loadline || ""}
                  name="loadline"
                  onChange={(e) => onChangePortOperations(index, e)}
                  disabled={checkDisabled.loadline}
                >
                  {loadlineOptions.map((option, key) => (
                    <option key={key} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="operation-input-div" style={styles(20)}>
                {/* {operations.draftRes} */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.draftRes || ""}
                  disabled={!!checkDisabled.draftRes}
                  name="draftRes"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div" style={styles(21)}>
                {/* {operations.waterDensity} */}
                <div className={`custom-input-outer ${(Number(operations.waterDensity) > 0 &&
                  (Number(operations.waterDensity) < 0.9 ||
                    Number(operations.waterDensity) > 1.05)) && "req-error"}`}>
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={operations.waterDensity || ""}
                    placeholder="0"
                    disabled={!!checkDisabled.waterDensity}
                    name="waterDensity"
                    onChange={(e) => onChangePortOperations(index, e)}
                  />
                  <div className="error-notification">
                    <img src={errorIcon} alt="" />
                    <p>{operations?.operation} Water Density seems impractical</p>
                  </div>
                </div>
              </div>
              <div className="operation-input-div" style={styles(22)}>
                {/* {operations.supply} */}
                <input type="number" onKeyDown={handleKeyDown} value={totalSupply} disabled />
              </div>
              <div className="operation-input-div" style={styles(23)}>
                {!!checkDisabled.canDelete ? (
                  <DeleteIcon
                    onClick={() => onDeleteOperations(index, operations._id,operations?.index)}
                  />
                ) : null}
              </div>
            </SortableItem>
          );
        })}
    </div>
  );
});

export default DraggableList;
