import {
  addAddressDetailsAPI,
  deleteAddressDetailsAPI,
  getAddressDetailsAPI,
  getSingleAddressDetailsAPI,
  updateAddressDetailsAPI,
} from "./api";

export const addAddressDetails = (id, data) => {
  return new Promise((resolve, reject) => {
    addAddressDetailsAPI(id, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateAddressDetails = (data) => {
  return new Promise((resolve, reject) => {
    updateAddressDetailsAPI( data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAddressDetails = (data) => {
  return new Promise((resolve, reject) => {
    getAddressDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSingleAddressDetails = (data) => {
  return new Promise((resolve, reject) => {
    getSingleAddressDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};



export const deleteAddressDetails = (data) => {
  return new Promise((resolve, reject) => {
    deleteAddressDetailsAPI(data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};