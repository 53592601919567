import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  Box,
  Paper,
  makeStyles,
  Tabs,
  Tab,
  useTheme,
} from "@material-ui/core";
import CustomButton from "../../../components/Common/CustomButton";
import CustomAddModal from "../../../components/Common/CustomAddModal";
import CustomConfirmModal from "../../../components/Common/CustomConfirmModal";
import AddNewOrder from "./AddNewOrder";
import * as actions from "./actions";
import { useDispatch, useSelector } from "react-redux";
import * as calculatorActions from "../Calculator/actions";
import {
  getCurrentUserOrganizationId,
  toastError,
  toastSuccess,
} from "../../../utils";
import AllOrdersTable from "./AllOrdersTable";
import MyOrdersTable from "./MyOrdersTable";
import { sortedPortsByFormat } from "./dataMapping";
import moment from "moment";
import SwipeableViews from "react-swipeable-views";
import CustomShowModal from "../../../components/Common/CustomShowModal";
import CreateNewCargo from "./CreateNewCargo";
import ShareWithOrg from "../../../components/Common/ShareWithOrg";
import SaveCargoLabel from "../../../components/Calculator/SaveCargoLabel";
import VesselList from "./VesselList";
import { useHistory } from "react-router-dom";
import SearchBar from "../../../common/Search";
import _ from "lodash";
import CreateCargoAnalysis from "./CreateCargoAnalysis";

const useStyles = makeStyles({
  root: {
    width: "fit-content",
  },
});

const initialSearchType = {
  type: "",
  name: "",
  firstLoadPort: "",
  lastDischargePort: "",
  cargo: "",
  loadQty: "",
  voyType: "",
  account: "",
  createdDate: "",
  createdUserName: "",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function Orders(props) {
  const { setLoader = () => { } } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    allClientOrders = [],
    allOrganizationOrders = [],
    queryOrderList = [],
    allClientOrdersPagination = {},
    allOrganizationOrdersPagination = {},
  } = useSelector((state) => state.orders) || {};
  const organizationId = getCurrentUserOrganizationId();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState({});
  const [selectedPorts, setSelectedPorts] = useState([]);
  const [selectedPortsCommence, setSelectedPortsCommence] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isStepper, setIsStepper] = useState(false);
  const [isDetailView, setIsDetailView] = useState(false);
  const [searchType, setSearchType] = useState({ ...initialSearchType });
  const [myFilters, setMyFilters] = useState(allClientOrdersPagination);
  const [allFilters, setAllFilters] = useState(allOrganizationOrdersPagination);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState({});
  const [isShareConfirm, setShareConfirm] = useState(false);
  const [shareLabel, setShareLabel] = useState("");
  const [saveLabel, setSaveLabel] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  const [selectedCargo, setSelectedCargo] = useState(null);

  const [saveLabelCargoVessel, setSaveLabelCargoVessel] = useState("");
  const [saveModalCargoVessel, setSaveModalCargoVessel] = useState(false);
  const [selectedCargoVessel, setSelectedCargoVessel] = useState(null);
  const [modelVessel, setModelVessel] = useState(false);
  const [vesselFilters, setVesselFilters] = useState({
    offset: 1,
    limit: 10,
  });
  const [fleets, setFleets] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState({});
  const [isCargoAnalysisStepper, setCargoAnalysisStepper] = useState(false);
  const [analysisVessel, setAnalysisVessel] = useState([]);
  const [cargoAnalysisData, setCargoAnalysisData] = useState({});


  const history = useHistory();

  const [searchValue, setSearchValue] = useState("");
  const onSearchCargo = (search) => {
    setLoader(true);
    dispatch(actions.clientQueryUser({ search: search }))
      .then(() => {
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
    if (organizationId) dispatch(actions.clientQueryOrg({ search: search }))
  };
  const delaySearch = useCallback(
    _.debounce((data) => onSearchCargo(data), 500),
    []
  );
  const onSearchChange = (e) => {
    setSearchValue(e.target.value)
    delaySearch(e.target.value);
  }

  const addQueryModalRef = useRef();
  useEffect(() => {
    setMyFilters(allClientOrdersPagination)
  }, [allClientOrdersPagination])
  useEffect(() => {
    setAllFilters(allOrganizationOrdersPagination)
  }, [allOrganizationOrdersPagination])
  useEffect(() => {
    setLoader(true);
    Promise.all([
      !!organizationId ? dispatch(actions.clientQueryOrg()) : null,
      dispatch(actions.clientQueryUser()),
      dispatch(actions.getQueryFormatForUser()),
    ])
      .then(() => {
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong.");
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchType({ ...initialSearchType });
  };

  const onCloseModal = () => {
    setOpen(false);
    setIsEdit(false);
    setIsStepper(false)
    setIsDetailView(false);
    setSelectedQuery({});
    setSelectedPorts([]);
    setCargoAnalysisStepper(false)
  };

  const onClickEditQuery = (query) => {
    setLoader(true);

    calculatorActions
      .cargoByQuery(query._id)
      .then((res) => {
        setLoader(false);
        setSelectedPorts(
          res.data.cargoList.filter((item) => item.operation !== "Commence")
        );
        setSelectedPortsCommence(
          res.data.cargoList
            .filter((item) => item.operation === "Commence")
        );
        setSelectedQuery(res.data);
        setOpen(true);
        setIsEdit(true);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onCargoAnalysisClick = (id, clientQuery) => {
    setLoader(true);

    calculatorActions
      .getGeneralMasterData("cargo-analysis-vessel-list")
      .then((res) => {
        setLoader(false);
        setCargoAnalysisStepper(true)
        setAnalysisVessel(res?.data?.list)
        setSelectedPorts(
          clientQuery?.cargoList?.filter((item) => item.operation !== "Commence")
        );
        setCargoAnalysisData({ clientQuery })
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };
  const onStepperQuery = (queryid) => {
    setLoader(true);

    calculatorActions
      .cargoByQuery(queryid)
      .then((res) => {
        setLoader(false);
        setSelectedPorts(
          res.data.cargoList.filter((item) => item.operation !== "Commence")
        );
        setSelectedPortsCommence(
          res.data.cargoList
            .filter((item) => item.operation === "Commence")
        );
        setSelectedQuery(res.data);
        // setOpen(true);
        setIsStepper(true)

      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onChangeSwitchQuery = (queryDetails) => {
    const queryId = queryDetails._id;
    const isFindIndex = queryOrderList.findIndex((item) => item === queryId);
    let updatedQueryList = [...queryOrderList];
    if (isFindIndex > -1) {
      updatedQueryList.splice(isFindIndex, 1);
    } else {
      updatedQueryList.push(queryId);
    }
    setLoader(true);
    if (updatedQueryList.length === 0) updatedQueryList = [null];
    const queryAccess = queryDetails.isShowInCalculator ? false : true;
    calculatorActions
      .updateChangeSwitchQuery({
        id: queryId,
        isShowInCalculator: queryAccess,
      })
      .then(async (res) => {
        toastSuccess(`Query access updated to ${queryAccess}.`);
        Promise.all([
          !!organizationId ? dispatch(actions.clientQueryOrg()) : null,
          dispatch(actions.clientQueryUser()),
          dispatch(actions.getQueryFormatForUser()),
        ]).catch((err) => {
          toastError(err?.data?.message || "Something went wrong.");
        });
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  };

  const onShareOrgSaveClick = () => {
    if (!shareLabel) return
    onClickCreateCopy()
    setShareConfirm(false)
    setShareLabel("")
  }
  const onShareOrgClick = () => {
    setShareConfirm(!isShareConfirm)
  }
  const onChangePinQuery = (queryDetails) => {
    const queryId = queryDetails._id;
    const isFindIndex = queryOrderList.findIndex((item) => item === queryId);
    let updatedQueryList = [...queryOrderList];
    if (isFindIndex > -1) {
      updatedQueryList.splice(isFindIndex, 1);
    } else {
      updatedQueryList.push(queryId);
    }
    setLoader(true);
    if (updatedQueryList.length === 0) updatedQueryList = [null];
    const queryAccess = queryDetails.access === "global" ? "private" : "global";
    calculatorActions
      .updateClientQueryAccess({
        id: queryId,
        access: queryAccess,
      })
      .then(async (res) => {
        toastSuccess(`Query access updated to ${queryAccess}.`);
        Promise.all([
          !!organizationId ? dispatch(actions.clientQueryOrg()) : null,
          dispatch(actions.clientQueryUser()),
          dispatch(actions.getQueryFormatForUser()),
        ]).catch((err) => {
          toastError(err?.data?.message || "Something went wrong.");
        });
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  };

  const getAllFleets = async (fromCheck = false, fromPagination) => {
    setLoader(true);

    await calculatorActions.userFleets(fromPagination ? fromPagination : vesselFilters)
      .then((res) => {
        setLoader(false);
        setFleets(
          (res.data && Array.isArray(res.data.list) && res.data.list) || []
        );
        setVesselFilters({
          totalPages: res?.data?.totalItems,
          offset: res?.data?.currentPage,
          pageCount: res?.data?.totalPages,
          limit: res?.data?.perPage
        })
      })
      .catch(() => {
        setLoader(false);
        toastError();
      });
  };

  const onClickSaveCargoVesselLabel = async () => {

    const calculatedData = {
      name: saveLabelCargoVessel,
      vessel: selectedVessel,
      clientQuery: selectedCargoVessel,
      fleet: selectedVessel,
      calculatedFleetResult: selectedVessel,
    };
    const resultLink = await calculatorActions.saveCalculatedCostResult(calculatedData);
    onCloseSaveCargo();
    onCloseCargoVessel()
    history.push(`/saved/${resultLink?.data?._id}?code=cargo`);
  }




  const onClickSaveCargoLabel = async () => {

    if (!!saveLabel) {
      const cargolistdetails = []
      selectedCargo?.cargoList?.forEach(element => {
        if (element?.operation?.toLowerCase() === "loading" || element?.operation?.toLowerCase() === "discharging") {
          cargolistdetails.push({
            label: element?.label,
            despatch: element?.despatch,
            voyType: element?.voyType,
            account: element?.account,
            firstLoadPort: element?.firstLoadPort,
            lastDischargePort: element?.lastDischargePort,
            cargo: element?.cargo,
            freight: element?.freight,
            profit: element?.profit,
            duration: element?.duration,
            freightMode: element?.freightMode,
            laycanBegin: element?.laycanBegin,
            laycanEnd: element?.laycanEnd,
            broker: element?.broker,
            lumpsumFreight: element?.lumpsumFreight,
            stowFactor: element?.stowFactor,
            multiParcelMode: element?.multiParcelMode,
            operation: element?.operation,
            port: element?.port,
            cargoQty: element?.cargoQty,
            tolerance: element?.tolerance,
            rate: element?.rate,
            terms: element?.terms,
            turnTime: element?.turnTime,
            pdaRemarks: element?.pdaRemarks,
            grade: element?.grade,
            adcom: element?.adcom,
            brokerage: element?.brokerage,
            ttlComm: element?.ttlComm,
            netFrt: element?.netFrt,
            factor: element?.factor,
            other: element?.other,
            index: element?.index,
            country: element?.country,
            portId: element?.portId,
            notes: element?.notes,
            demurrageRate: element?.demurrageRate || 0,
            despatchRate: element?.despatchRate || 0,
          })
        }
      });
      const vesselVoyageData = []
      selectedCargo?.cargoList?.forEach(element => {
        if (element?.operation?.toLowerCase() === "loading" || element?.operation?.toLowerCase() === "discharging") {
          vesselVoyageData.push({
            operation: element?.operation,
            port: element?.port,
            lat: element?.lat,
            lng: element?.lng,
            cargoQty: element?.cargoQty,
            cargo: element?.cargo,
            min: element?.min,
            max: element?.max,
            laycanStartDate: null,
            laycanEndDate: null,
            stowFactor: element?.stowFactor,
            actualCargoQty: 0,
            tolerance: element?.tolerance || 0,
            arrivalTime: null,
            departureTime: null,
            portStay: 0
          })
        }
      });

      const customerCargo = {
        qtyMode: selectedCargo?.qtyMode,
        freightMode: selectedCargo?.freightMode,
        cargoList: cargolistdetails,
        Tag1: selectedCargo?.Tag1,
        Tag2: selectedCargo?.Tag2,
        Tag3: selectedCargo?.Tag3,
        Tag4: selectedCargo?.Tag4,
        message: selectedCargo?.message,
      }
      const cargocargo = {
        customerCargo: customerCargo,
        vendorCargo: customerCargo,
        vesselVoyageData: vesselVoyageData,
        queryId: selectedCargo?._id,
        cargoCargoName: saveLabel,
        organizationId: selectedCargo?.organizationId,
        createdDate: selectedCargo?.createdDate,
        version: selectedCargo?.version,
        system: selectedCargo?.system,
        name: selectedCargo?.name,
        miscExp: selectedCargo?.miscExp,
        miscRevenue: selectedCargo?.miscRevenue,
        laycanStartDate: selectedCargo?.laycanStartDate,
        laycanEndDate: selectedCargo?.laycanEndDate,
        remarkMessage: selectedCargo?.remarkMessage,
        qtyMode: selectedCargo?.qtyMode,
        freightMode: selectedCargo?.freightMode,
        access: selectedCargo?.access,
        firstLoadPort: selectedCargo?.firstLoadPort,
        lastDischargePort: selectedCargo?.lastDischargePort,
        cargo: selectedCargo?.cargo,
        loadQty: selectedCargo?.loadQty,
        createdUser: selectedCargo?.createdUser,
        label: selectedCargo?.label,
        voyType: selectedCargo?.voyType,
        account: selectedCargo?.account,
        lastModifiedDate: selectedCargo?.lastModifiedDate,

      }
      setLoader(true);
      actions.addCargoCargoDetails(cargocargo)
        .then(() => {
          setLoader(false);
          onCloseSaveCargo();
        })
        .catch((err) => {
          setLoader(false);
          onCloseSaveCargo();
          toastError(err?.data?.message || "Something went wrong");
        });

    } else {
      toastError("Please enter cargo label")
    }
  };

  const onCloseSaveCargo = () => {
    setSaveModal(false);
    setSelectedCargo(null);
    setSaveLabel("");
  };
  const onClickSaveCargo = (id, cargo) => {
    setSelectedCargo(cargo);
    setSaveLabel(saveLabel);
    setSaveModal(true);
  };


  const onChangeVesselPagination = (type = "") => {
    let newVesselFilters = {};
    if (type === "inc") {
      newVesselFilters = { ...vesselFilters, offset: vesselFilters.offset + 1 };
    } else if (type === "first") {
      newVesselFilters = { ...vesselFilters, offset: 1 };
    } else {
      if (vesselFilters.offset === 1) return;
      newVesselFilters = { ...vesselFilters, offset: vesselFilters.offset - 1 };
    }
    setLoader(true);
    getAllFleets(false, newVesselFilters)

  };
  const onCreateVesselClick = () => {
    if (!selectedVessel?._id) {
      return toastError("Please select one vessel")
    }
    setSaveModalCargoVessel(true)
  }
  const onClickVessel = (e, item) => {
    if (e?.target?.checked) {
      setSelectedVessel(item)
    } else {
      setSelectedVessel({})
    }
  }
  const onCloseSaveCargoCargoVessel = () => {

    setSelectedCargoVessel(null);
    setSaveLabelCargoVessel("");
    // setSaveModalCargoVessel(false);
    setModelVessel(false)
    setSelectedVessel({})

  };
  const onCloseCargoVessel = () => {

    setSaveModalCargoVessel(false);

  };
  const onClickSaveCargoVessel = (id, cargo) => {
    getAllFleets()
    setSelectedCargoVessel(cargo);
    setSaveLabelCargoVessel(saveLabelCargoVessel);
    // setSaveModalCargoVessel(true);
    setModelVessel(true);


  };

  const onDeleteQuery = () => {
    setLoader(true);
    Promise.all([actions.querydelete(deleteId._id)])
      .then(async () => {
        toastSuccess("Query deleted successfully");
        setDeleteModal(false);
        await dispatch(actions.clientQueryUser());
        setLoader(false);
      })
      .catch((err) => {
        setDeleteModal(false);
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onChangeSearchType = (e, key = "") => {
    let { name = "", value = "" } = e.target || {};
    if (!value) name = "";
    let updatedSearchType = { ...searchType };
    if (name === "loadQty") {
      let valueSplit = updatedSearchType[name].split(",");
      let startValue = valueSplit[0] || "";
      let endValue = valueSplit[1] || "";
      if (key === "start") startValue = value;
      else endValue = value;
      value = `${startValue},${endValue}`;
    } else if (name === "createdDate") {
      let valueSplit = updatedSearchType[name].split(",");
      let startValue = valueSplit[0] || "";
      let endValue = valueSplit[1] || "";
      if (key === "start") startValue = moment(value).valueOf();
      else endValue = moment(value).valueOf();
      value = `${startValue},${endValue}`;
    }
    setSearchType({ ...initialSearchType, type: name, [name]: value });
  };

  const onClickSubmitSearch = async () => {
    setLoader(true);
    if (searchType.type) {
      let searchRequest = actions.allQueryByCharForUser;
      if (value !== 1) searchRequest = actions.allQueryByCharForOrg;
      await dispatch(
        searchRequest({
          searchParam: searchType[searchType.type],
          type: searchType.type,
          offset: 10,
        })
      );
    } else {
      let searchRequest = actions.clientQueryUser;
      if (!organizationId) return null;
      if (value !== 1) searchRequest = actions.clientQueryOrg;
      await dispatch(searchRequest());
    }
    setLoader(false);
  };

  const onSubmitConfirm = () => {
    if (addQueryModalRef.current.onSubmitQuery) {
      addQueryModalRef.current.onSubmitQuery();
    }
  };

  const onClickCreateCopy = () => {
    if (addQueryModalRef.current.onSubmitQuery) {
      addQueryModalRef.current.onSubmitQuery(true, shareLabel);
    }
  };

  const onChangeMyPagination = (type = "") => {
    let newMyFilters = {};
    if (type === "inc") {
      newMyFilters = { ...myFilters, offset: myFilters.offset + 1 };
    } else if (type === "first") {
      newMyFilters = { ...myFilters, offset: 1 };
    } else {
      if (myFilters.offset === 1) return;
      newMyFilters = { ...myFilters, offset: myFilters.offset - 1 };
    }
    setLoader(true);
    dispatch(actions.clientQueryUser(newMyFilters))
      .then(() => {
        setLoader(false);
        setMyFilters(newMyFilters);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onChangeAllPagination = (type = "") => {
    let newallFilters = {};
    if (type === "inc") {
      newallFilters = { ...allFilters, offset: allFilters.offset + 1 };
    } else if (type === "first") {
      newallFilters = { ...allFilters, offset: 1 };
    } else {
      if (allFilters.offset === 1) return;
      newallFilters = { ...allFilters, offset: allFilters.offset - 1 };
    }
    setLoader(true);
    if (!organizationId) return null;
    dispatch(actions.clientQueryOrg(newallFilters))
      .then(() => {
        setLoader(false);
        setAllFilters(newallFilters);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onClickClearMyOrders = () => {
    setLoader(true);
    dispatch(actions.clientQueryUser())
      .then(() => {
        setSearchType({ ...initialSearchType });
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onClickClearAllOrders = () => {
    if (!organizationId) return null;
    setLoader(true);
    dispatch(actions.clientQueryOrg())
      .then(() => {
        setSearchType({ ...initialSearchType });
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onOpenDeleteModal = (query) => {
    setDeleteId(query);
    setDeleteModal(true);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleDateRangeChange = async (newDateRange) => {
    const { startDate, endDate } = newDateRange;

    // Ensure dates are in ISO string format
    const formattedStartDate = startDate?.toISOString() || '';
    const formattedEndDate = endDate?.toISOString() || '';
    if (value === 1) {
      dispatch(actions.clientQueryOrg({
        organizationId,
        startDate: formattedStartDate,
        endDate: formattedEndDate
      }))
    } else {
      dispatch(actions.clientQueryUser({
        startDate: formattedStartDate,
        endDate: formattedEndDate
      }))
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between w-full items-center">
        <Paper className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab value={0} label="My Orders" />
            <Tab value={1} label="All Orders" />
          </Tabs>
        </Paper>

        <div className="desktop-searchbar">
          <SearchBar onDateRangeChange={handleDateRangeChange} placeholder={"Search with Name..."} setSearchValue={onSearchChange} searchValue={searchValue} />
        </div>

        <div>
          <CustomButton onClick={() => setOpen(true)}>New Order</CustomButton>
        </div>
      </div>

      <div className="mobile-searchbar">
        <SearchBar onDateRangeChange={handleDateRangeChange} isMobile placeholder={"Search with Name..."} setSearchValue={onSearchChange} searchValue={searchValue} />
      </div>

      <div className="w-full max-w-screen-xl">
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <Box pt={2}>
            <MyOrdersTable
              allClientOrders={allClientOrders}
              queryOrderList={queryOrderList}
              onClickEditQuery={onClickEditQuery}
              onChangePinQuery={onChangeSwitchQuery}
              onDeleteQuery={onOpenDeleteModal}
              searchType={searchType}
              onChangeSearchType={onChangeSearchType}
              onClickSubmitSearch={onClickSubmitSearch}
              filters={myFilters}
              onChangePagination={onChangeMyPagination}
              onClickClear={onClickClearMyOrders}
              onStepperQuery={onStepperQuery}
              onClickSaveCargo={onClickSaveCargo}
              onClickSaveCargoVessel={onClickSaveCargoVessel}
              onCargoAnalysisClick={onCargoAnalysisClick}
            />
          </Box>
          <Box pt={2}>
            {organizationId ? <AllOrdersTable
              allOrganizationOrders={allOrganizationOrders}
              onClickEditQuery={onClickEditQuery}
              setIsDetailView={setIsDetailView}
              searchType={searchType}
              onChangeSearchType={onChangeSearchType}
              onClickSubmitSearch={onClickSubmitSearch}
              filters={allFilters}
              onChangePagination={onChangeAllPagination}
              onClickClear={onClickClearAllOrders}
            /> :
              <>
                You are not part of any organization.
              </>
            }
          </Box>
        </SwipeableViews>
      </div>
      <CustomAddModal
        open={open}
        title={isEdit ? "Update Cargo" : "New Cargo"}
        maxWidth="xl"
        saveLabel={isEdit ? "Update" : "Create"}
        addThirdBtn={isEdit}
        thirdBtnAction={onClickCreateCopy}
        thirdBtnLabel="Create New Order"
        handleClose={onCloseModal}
        isDetailView={isDetailView}
        onSubmitConfirm={onSubmitConfirm}
        isDowloadPdf
      >
        <AddNewOrder
          ref={addQueryModalRef}
          setLoader={setLoader}
          onCloseModal={onCloseModal}
          selectedQuery={selectedQuery}
          selectedPorts={selectedPorts}
          isEdit={isEdit}
          selectedPortsCommence={selectedPortsCommence}
          onChangePinQuery={onChangePinQuery}
          isDetailView={isDetailView}
          setIsEdit={setIsEdit}
          onShareOrgClick={onShareOrgClick}
          tabvalue={value}
        />
      </CustomAddModal>
      <CustomShowModal
        open={isStepper}
        title={"Update Cargo"}
        maxWidth="lg"
        saveLabel={"Update"}
        // addThirdBtn={isStepper}
        thirdBtnAction={onClickCreateCopy}
        // thirdBtnLabel="Create New Order"
        handleClose={onCloseModal}
        isDetailView={isDetailView}
        onSubmitConfirm={onSubmitConfirm}
        isDowloadPdf
      >
        <CreateNewCargo
          ref={addQueryModalRef}
          setLoader={setLoader}
          onCloseModal={onCloseModal}
          selectedQuery={selectedQuery}
          selectedPorts={selectedPorts}
          selectedPortsCommence={selectedPortsCommence}
          isEdit={isStepper}
          onChangePinQuery={onChangePinQuery}
          isDetailView={isDetailView}
          setIsEdit={setIsStepper}
        />
      </CustomShowModal>

      <CustomConfirmModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        message="Are you sure to delete this order?"
        onSubmitConfirm={onDeleteQuery}
      />

      <CustomAddModal
        open={isShareConfirm}
        handleClose={onShareOrgClick}
        onSubmitConfirm={onShareOrgSaveClick}
        maxWidth="xs"
        saveLabel="Share"
        title="Share with Team"
      >
        <ShareWithOrg
          value={shareLabel}
          onChange={(e) => setShareLabel(e.target.value)}
          placeholder="Enter Cargo name*"
        />
      </CustomAddModal>

      <CustomAddModal
        open={saveModal}
        handleClose={onCloseSaveCargo}
        onSubmitConfirm={onClickSaveCargoLabel}
        maxWidth="xs"
        saveLabel="Save"
        title="Save to Cargo Cargo Matrix"
      >
        <SaveCargoLabel
          value={saveLabel}
          onChange={(e) => setSaveLabel(e.target.value)}
        />
      </CustomAddModal>


      <CustomAddModal
        open={saveModalCargoVessel}
        handleClose={onCloseCargoVessel}
        onSubmitConfirm={onClickSaveCargoVesselLabel}
        // maxWidth="xs"
        saveLabel="Save"
        title="Save to Single Cargo Vessel Matrix"
      >
        <SaveCargoLabel
          value={saveLabelCargoVessel}
          onChange={(e) => setSaveLabelCargoVessel(e.target.value)}
          placeholder="Enter Cargo-Vessel label"
        />
      </CustomAddModal>

      <CustomShowModal
        open={modelVessel}
        title={"Vessel List"}
        maxWidth="lg"
        saveLabel={"Create"}
        handleClose={onCloseSaveCargoCargoVessel}
        onSubmitConfirm={onSubmitConfirm}
      >
        <VesselList
          setLoader={setLoader}
          selectedQuery={selectedQuery}
          fleets={fleets}
          onChangeVesselPagination={onChangeVesselPagination}
          vesselFilters={vesselFilters}
          onCloseSaveCargoCargoVessel={onCloseSaveCargoCargoVessel}
          onClickVessel={onClickVessel}
          selectedVessel={selectedVessel}
          onCreateVesselClick={onCreateVesselClick}
        />
      </CustomShowModal>

      <CustomShowModal
        open={isCargoAnalysisStepper}
        title={"Cargo Analysis"}
        maxWidth="lg"
        saveLabel={"Create"}
        // addThirdBtn={isStepper}
        // thirdBtnAction={onClickCreateCopy}
        // thirdBtnLabel="Create New Order"
        handleClose={onCloseModal}
        isDetailView={isDetailView}
        onSubmitConfirm={onSubmitConfirm}
        isDowloadPdf
      >
        <CreateCargoAnalysis
          // ref={addQueryModalRef}
          setLoader={setLoader}
          analysisVessel={analysisVessel}
          cargoAnalysisData={cargoAnalysisData}
          setCargoAnalysisData={setCargoAnalysisData}
          onCloseModal={onCloseModal}
          selectedQuery={selectedQuery}
          selectedPorts={selectedPorts}
          selectedPortsCommence={selectedPortsCommence}
          isEdit={isCargoAnalysisStepper}
          onChangePinQuery={onChangePinQuery}
          isDetailView={isDetailView}
          setIsEdit={setCargoAnalysisStepper}
        />
      </CustomShowModal>

    </div>
  );
}

export default Orders;
