import React from 'react'
import { Grid, Switch, TableContainer, Paper, Table, TableBody, TableHead, TableCell, TableRow } from "@material-ui/core"
import { fuelPriceOptions } from '../../constants'
import { handleKeyDown } from '../../common/common'
import moment from 'moment'

function BunkerSupplyMarginAllowance(props) {
    const {
        portOperation = [],
        onChangeBunkerScrubber = () => { },
        selectedVessels = {},
        onChangeSyncBunker = () => { },
        clientQuery = () => { },
        onChangePortOperations = () => { },
        disableEdit = false,
        syncBunker = false,
        calculatorData = {},
        setCalculatorData = () => { },

    } = props

    const { fuelType = "", } = clientQuery || {}


    let selectedFuelType = fuelPriceOptions.find(item => item.value === fuelType)
    if (selectedFuelType) selectedFuelType = selectedFuelType.label
    else selectedFuelType = 'Type-1'

    const onChangeMarginAllowance = (e, index) => {
        const { name = "", value = "" } = e.target
        let updatedPortOperation = [...portOperation]
        if (name === "dwf") {
            updatedPortOperation[index].dwf = Number(value)
        } else {
            updatedPortOperation[index].cargoDetails[name] = Number(value)
        }
        setCalculatorData({
            ...calculatorData,
            portOperation: updatedPortOperation
        })
    }
    return (
        <Grid container>
            {disableEdit ? <h1 className="modal-head-text">Bunker Supply Table</h1> : null}
            <Grid className='sync-bunker-switch-wrap' item xs={12}>
                <div className="sync-bunker-switch" style={{ position: "absolute" }}>
                    <label>Allow Fuel Price Adjustment</label>
                    <Switch
                        checked={syncBunker}
                        onChange={e => onChangeSyncBunker(e.target.checked)}
                        name="checkedA"
                        color="primary"
                    />
                </div>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper} className="bunker-supply-table">

                    <Table>
                        <TableHead>

                            <TableRow>
                                <TableCell colSpan={2}></TableCell>
                                <TableCell style={{ fontWeight: "500" }} colSpan={6}><span style={{ display: "flex", justifyContent: "center" }}>Margin Allowances Table</span></TableCell>
                                <TableCell style={{ fontWeight: "500" }} colSpan={12}><span style={{ display: "flex", justifyContent: "center" }}>Bunker Supply Table</span></TableCell>

                            </TableRow>
                            <TableRow >
                                <TableCell colSpan={6}></TableCell>
                                <TableCell colSpan={4}>Arrival Bunkers</TableCell>
                                <TableCell colSpan={4}>Bunker Supplied at port</TableCell>
                                <TableCell colSpan={2}></TableCell>
                                <TableCell colSpan={4}>Selected Fuel Type: {selectedFuelType}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Operation</TableCell>
                                <TableCell>Port</TableCell>

                                <TableCell>Dwf (%)</TableCell>
                                <TableCell>Speed Factor (%)</TableCell>
                                <TableCell>Speed Reduce (Knots)</TableCell>
                                <TableCell>BnkrFackor (%)</TableCell>

                                <TableCell>Arrival</TableCell>
                                <TableCell>HS(0.5)</TableCell>
                                <TableCell>LS(0.1)</TableCell>
                                <TableCell>MGO</TableCell>
                                <TableCell>HS(0.5)</TableCell>
                                <TableCell>LS(0.1)</TableCell>
                                <TableCell>MGO</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Reserve</TableCell>
                                <TableCell>Paid By</TableCell>
                                <TableCell>HS(0.5)</TableCell>
                                <TableCell>LS(0.1)</TableCell>
                                <TableCell>MGO</TableCell>
                                <TableCell>Departure</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                portOperation.map((operations, index) => {
                                    const { operation = '', port = '', cargoDetails = {}, index: operationIndex } = operations
                                    const { reserve = '', bunkerSuppliedHSFO = 0, bunkerSuppliedLSFO = 0, bunkerSuppliedMGO = 0, paidBy = 'Owner' } = cargoDetails || {}

                                    const selectedArrivalBunker = (selectedVessels && selectedVessels.portToPortOperationResultMap && selectedVessels.portToPortOperationResultMap[operationIndex]) || {}
                                    const { arrivalHsfo = 0, arrivalLsfo = 0, arrivalMgo = 0 } = selectedArrivalBunker || {}
                                    const { dwf = 0, arrivalTime = '', departureTime = '' } = operations
                                    const { bnkrFactor = 0, speedReduce = 0, speedFactor = 0 } = cargoDetails || {}

                                    const currentType = fuelType.replace("TYPE", "")
                                    const currentHsfo = cargoDetails[`fuelPriceType${currentType}Hsfo`] || 0
                                    const currentLsFo = cargoDetails[`fuelPriceType${currentType}Lsfo`] || 0
                                    const currentMgo = cargoDetails[`fuelPriceType${currentType}Mgo`] || 0

                                    return <TableRow key={index}>
                                        <TableCell><p>{operation}</p></TableCell>
                                        <TableCell><p>{port}</p></TableCell>

                                        <TableCell><input onKeyDown={handleKeyDown} type="number" className="margin-allowance-input" value={dwf} name="dwf" onChange={(e) => onChangeMarginAllowance(e, index)} /></TableCell>
                                        <TableCell><input onKeyDown={handleKeyDown} type="number" className="margin-allowance-input" value={speedFactor} name="speedFactor" onChange={(e) => onChangeMarginAllowance(e, index)} /></TableCell>
                                        <TableCell><input onKeyDown={handleKeyDown} type="number" className="margin-allowance-input" value={speedReduce} name="speedReduce" onChange={(e) => onChangeMarginAllowance(e, index)} /></TableCell>
                                        <TableCell><input onKeyDown={handleKeyDown} type="number" className="margin-allowance-input" value={bnkrFactor} name="bnkrFactor" onChange={(e) => onChangeMarginAllowance(e, index)} /></TableCell>
                                        <TableCell><p>{arrivalTime ? moment(arrivalTime).format('DD-MM-yyyy HH:mm') : ''}</p></TableCell>



                                        <TableCell><p>{Number(arrivalHsfo).toFixed(2)}</p></TableCell>
                                        <TableCell><p>{Number(arrivalLsfo).toFixed(2)}</p></TableCell>
                                        <TableCell><p>{Number(arrivalMgo).toFixed(2)}</p></TableCell>
                                        <TableCell style={{ width: 60 }}>{index === 0 ? <p>{bunkerSuppliedHSFO}</p> : <input onKeyDown={handleKeyDown} type="number" value={bunkerSuppliedHSFO} name="bunkerSuppliedHSFO" onChange={e => onChangePortOperations(index, e, true)} />}</TableCell>
                                        <TableCell style={{ width: 60 }}>{index === 0 ? <p>{bunkerSuppliedLSFO}</p> : <input onKeyDown={handleKeyDown} type="number" value={bunkerSuppliedLSFO} name="bunkerSuppliedLSFO" onChange={e => onChangePortOperations(index, e, true)} />}</TableCell>
                                        <TableCell style={{ width: 60 }}>{index === 0 ? <p>{bunkerSuppliedMGO}</p> : <input onKeyDown={handleKeyDown} type="number" value={bunkerSuppliedMGO} name="bunkerSuppliedMGO" onChange={e => onChangePortOperations(index, e, true)} />}</TableCell>
                                        <TableCell><p>{Number(bunkerSuppliedHSFO) + Number(bunkerSuppliedLSFO) + Number(bunkerSuppliedMGO)}</p></TableCell>
                                        <TableCell>
                                            <select
                                                className="select-color-orange"
                                                name="reserve"
                                                value={reserve}
                                                onChange={e => onChangePortOperations(index, e, true)}
                                            >
                                                <option value="Reserve">Reserve</option>
                                                <option value="Supply">Supply</option>
                                            </select>
                                            {/* <p>{reserve}</p> */}
                                        </TableCell>
                                        <TableCell>
                                            <select
                                                className="select-color-orange"
                                                name="paidBy"
                                                value={paidBy}
                                                onChange={e => onChangePortOperations(index, e, true)}
                                            >
                                                <option value="Operator">External</option>
                                                <option value="Owner">Internal</option>
                                            </select>
                                            {/* <p>{paidBy}</p> */}
                                        </TableCell>
                                        <TableCell className="custom-editable-tablecell">{syncBunker ? index === 0 ? <p>{currentHsfo}</p> : <input onKeyDown={handleKeyDown} type="number" value={currentHsfo} name={`fuelPriceType${currentType}Hsfo`} onChange={e => onChangeBunkerScrubber(index, e)} /> : <p>{currentHsfo}</p>}</TableCell>
                                        <TableCell className="custom-editable-tablecell">{syncBunker ? index === 0 ? <p>{currentLsFo}</p> : <input onKeyDown={handleKeyDown} type="number" value={currentLsFo} name={`fuelPriceType${currentType}Lsfo`} onChange={e => onChangeBunkerScrubber(index, e)} /> : <p>{currentLsFo}</p>}</TableCell>
                                        <TableCell className="custom-editable-tablecell">{syncBunker ? index === 0 ? <p>{currentMgo}</p> : <input onKeyDown={handleKeyDown} type="number" value={currentMgo} name={`fuelPriceType${currentType}Mgo`} onChange={e => onChangeBunkerScrubber(index, e)} /> : <p>{currentMgo}</p>}</TableCell>

                                        <TableCell><p>{departureTime ? moment(departureTime).format('DD-MM-yyyy HH:mm') : ''}</p></TableCell>

                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

        </Grid>
    )
}

export default BunkerSupplyMarginAllowance
