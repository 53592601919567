import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  Box,
  Paper,
  makeStyles,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Switch,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import CustomButton from "../../../components/Common/CustomButton";
import * as actions from "./actions";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  getCurrentUserId,
  getCurrentUserOrganizationId,
  toastError,
  toastSuccess,
} from "../../../utils";
import CustomPagination from "../../../components/Common/CustomPagination";
import CustomConfirmModal from "../../../components/Common/CustomConfirmModal";
import CustomAddModal from "../../../components/Common/CustomAddModal";

import AddUpdateDetails from "./AddUpdateComplaints";
import _ from "lodash";
import SearchBar from "../../../common/Search";
import AddUpdateComplaints from "./AddUpdateComplaints";
const useStyles = makeStyles({
  root: {
    width: "fit-content",
  },
});

const initialSearchType = {
  type: "",
  saveLabel: "",
  name: "",
  firstLoadPort: "",
  lastDischargePort: "",
  loadQuantity: "",
  createdDate: "",
};

function Support(props) {
  const { setLoader = () => { } } = props;
  const classes = useStyles();

  const [value, setValue] = useState(1);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [savedResults, setSavedResults] = useState([]);
  const [searchType, setSearchType] = useState({ ...initialSearchType });

  const [singleResults, setSingleResults] = useState({});

  const [myFilters, setMyFilters] = useState({
    offset: 1,
    limit: 10,
  });

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState({});

  // const organizationId = getCurrentUserOrganizationId();
  const addDetailsModalRef = useRef();

  const [searchValue, setSearchValue] = useState("");
  const onSearchVessel = (search) => {
    getAllSavedResults(search);
  };
  const delaySearch = useCallback(
    _.debounce((data) => onSearchVessel(data), 500),
    []
  );
  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
    delaySearch(e.target.value);
  };
  useEffect(() => {
    getAllSavedResults();
  }, []);
  const onCloseModal = () => {
    setOpen(false);
    setIsEdit(false);
  };
  const getAllSavedResults = async (search) => {
    setLoader(true);
    await actions
      .getComplaints({ userId: getCurrentUserId(), search: search })
      .then((res) => {
        setLoader(false);
        setSavedResults(res?.data?.list || []);
        setMyFilters({
          totalPages: res?.data?.totalItems,
          offset: res?.data?.currentPage,
          pageCount: res?.data?.totalPages,
          limit: res?.data?.perPage,
        });
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchType({ ...initialSearchType });
  };

  const onClickDeletAddress = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const onChangeMyPagination = (type = "") => {
    let newMyFilters = {};
    if (type === "inc") {
      newMyFilters = { ...myFilters, offset: myFilters.offset + 1 };
    } else if (type === "first") {
      newMyFilters = { ...myFilters, offset: 1 };
    } else {
      if (myFilters.offset === 1) return;
      newMyFilters = { ...myFilters, offset: myFilters.offset - 1 };
    }
    setLoader(true);
    actions
      .getComplaints({ ...newMyFilters, userId: getCurrentUserId() })
      .then((res) => {
        setLoader(false);
        setMyFilters({
          totalPages: res?.data?.totalItems,
          offset: res?.data?.currentPage,
          pageCount: res?.data?.totalPages,
          limit: res?.data?.perPage,
        });
        setSavedResults(
          (res?.data?.list &&
            Array.isArray(res?.data?.list) &&
            res?.data?.list) ||
          []
        );
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onDeleteAddress = () => {
    setLoader(true);
    actions
      .deleteComplaints(deleteId)
      .then((res) => {
        toastSuccess("Result deleted successfully");
        setDeleteModal(false);
        setDeleteId("");
        getAllSavedResults();
      })
      .catch((err) => {
        toastError();
        setLoader(false);
      });
  };
  const onSubmitConfirm = () => {
    if (addDetailsModalRef.current.onSubmitQuery) {
      addDetailsModalRef.current.onSubmitQuery();
    }
  };

  const onClickEditAddress = async (item) => {
    setLoader(true);
    await actions
      .getSingleComplaints({ complaintsId: item?._id })
      .then((res) => {
        setSingleResults(res?.data || {});
        setIsEdit(true);
        setLoader(false);
        setOpen(true);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  };
  const handleDateRangeChange = async (newDateRange) => {
    const { startDate, endDate } = newDateRange;

    // Ensure dates are in ISO string format
    const formattedStartDate = startDate?.toISOString() || '';
    const formattedEndDate = endDate?.toISOString() || '';

    const { data } = await actions.getComplaints({
      userId: getCurrentUserId(),
      startDate: formattedStartDate,
      endDate: formattedEndDate
    });
    setSavedResults(data?.list);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Paper className={classes.root}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab value={1} label="My complaints" />
              </Tabs>
            </Paper>
          </Box>
          <div className="desktop-searchbar">
            <SearchBar
              placeholder={
                "Search with title, page,  email, mobile or status..."
              }
              onDateRangeChange={handleDateRangeChange}
              setSearchValue={onSearchChange}
              searchValue={searchValue}
            />
          </div>
          <div style={{ width: "138px" }}>
            <CustomButton onClick={() => setOpen(true)}>
              Add complaints
            </CustomButton>
          </div>
        </Box>
        <div className="mobile-searchbar">
          <SearchBar
            isMobile
            onDateRangeChange={handleDateRangeChange}
            placeholder={"Search with title, page,  email, mobile or status..."}
            setSearchValue={onSearchChange}
            searchValue={searchValue}
          />
        </div>
      </Grid>

      <Grid item xs={12}></Grid>

      <Grid item xs={12}>
        <Box pt={2}>
          <TableContainer component={Paper}>
            <Table className="universal-table">
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Page</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile no</TableCell>
                  <TableCell>Created on</TableCell>
                  <TableCell>Current Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(savedResults) && savedResults.length ? (
                  savedResults.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item?.title}</TableCell>
                        <TableCell>{item?.pageName}</TableCell>
                        <TableCell>{item?.emailAddress || ""}</TableCell>
                        <TableCell>{item?.mobileNumber || ""}</TableCell>
                        <TableCell>
                          {moment(item?.createdAt).format("DD/MMM/yyyy")}
                        </TableCell>
                        <TableCell>{item?.current_status || ""}</TableCell>
                        <TableCell>
                          <div className="query-actions">
                            <VisibilityIcon
                              color="primary"
                              onClick={() => {
                                onClickEditAddress(item);
                              }}
                            />
                            <DeleteIcon
                              color="secondary"
                              onClick={() => onClickDeletAddress(item?._id)}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="8" align="center" className="!bg-white">
                      No complaints Found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <CustomPagination
              onChangePagination={onChangeMyPagination}
              filters={myFilters}
              totalPage={myFilters?.totalPages}
            />
          </TableContainer>
        </Box>
      </Grid>

      <CustomConfirmModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        message="Are you sure to delete this Address Details?"
        onSubmitConfirm={onDeleteAddress}
      />

      <CustomAddModal
        open={open}
        title={isEdit ? "Update Complaints" : "Add Complaints"}
        maxWidth="lg"
        saveLabel={isEdit ? "Update" : "Create"}
        handleClose={onCloseModal}
        onSubmitConfirm={onSubmitConfirm}
        isDetailView={singleResults?.current_status === "resolved" ? true : false}
      >
        <AddUpdateComplaints
          ref={addDetailsModalRef}
          setLoader={setLoader}
          onCloseModal={onCloseModal}
          getAllSavedResults={getAllSavedResults}
          singleResults={singleResults}
          isEdit={isEdit}
        />
      </CustomAddModal>

      {/* <CustomAddModal
                open={isCopyConfirm}
                handleClose={onCopyClick}
                onSubmitConfirm={onCopySaveClick}
                maxWidth="xs"
                saveLabel="Create"
                title="Copy to Org"
            >
                <ShareWithOrg
                    value={shareLabel}
                    onChange={(e) => setShareLabel(e.target.value)}
                    placeholder="Enter Voyages name*"
                />
            </CustomAddModal> */}
    </Grid>
  );
}

export default Support;
