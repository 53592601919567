
export const handleKeyDown = (event) => {
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' ||   event.key === '.'|| event.key === 'Tab' ||
    (event.ctrlKey && event.key === 'Control') || 
    (event.ctrlKey && event.key === 'r') ||
    (event.metaKey && event.key === 'r')) {
      return;
    }
    if (event.key === "ArrowUp" || event.key === "ArrowDown" || (!/\d/.test(event.key))) {
      event.preventDefault();
    }
  };