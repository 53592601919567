import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  getOrganizationId,
} from "../../../utils";

export const usersForOrgAPI = (data) => {
  const { organizationId = "", allUsers = false, offset = 1, limit = 10 } = data || {};
  const params = {
    skipPagination: true,
    offset,
    limit,
    allUsers: allUsers,
  };
  if (organizationId) params.organizationId = organizationId;
  return apiGet(`/api/getUsersByOrganization`, params);
};

export const createUserAPI = (data) => {
  return apiPost("/api/auth/registerUserbyAdmin", data);
};

export const deleteUserAPI = (data) => {
  const { id = "" } = data || {};
  return apiDelete(`/api/user/${id}`);
};

export const updateActiveUserAPI = (data) => {
  const { isActivated = true, userId = "" } = data || {};
  return apiPut(`/api/access/activate`, { isActivated, userId });
};

export const allRolesAPI = () => {
  return apiGet("/api/v1/allRoles");
};

export const getAllRolesAPI = () => {
  return apiGet("/api/v1/getRoles");
};

export const usersWithIdAPI = (data) => {
  const { id = "" } = data || {};
  return apiGet(`/api/v1/usersWithId?id=${id}`);
};

export const getUserRolesAPI = (data) => {
  const { id = "" } = data || {};
  return apiGet(`/api/v1/getUserRoles?id=${id}`);
};

export const getAllUsersAPI = (data) => {
  return apiGet(`/api/getAllUsers`, { skipPagination: true });
};

export const getAllAdminUsersAPI = (data) => {
  return apiGet(`/api/getAllUsers`, {
    skipPagination: true,
    isAdminUsers: true,
  });
};

export const updateUserAPI = (data) => {
  return apiPut(`/api/profileDetails`, data);
};

export const allVesselsAPI = (data) => {
  const { offset = 0, limit = 25, skipUserId = false } = data || {};
  return apiGet(`/api/vessel/getAllVessel`, {
    perPage: limit,
    page: offset + 1,
    skipUserId: skipUserId,
  });
};

export const getAllOrganizationsAPI = (data) => {
  const { organizationAdmin = "" } = data;
  const filter = {};
  if (!!organizationAdmin) {
    filter.organizationAdmin = organizationAdmin;
  }
  return apiGet(`/api/organizations`, { ...filter });
};

export const getFleetByOrgAPI = (filters) => {
  // const data = { orgId: getOrganizationId() }
  const {
    offset = 0,
    limit = 25,
    organizationId = "",
    isValid,
  } = filters || {};
  const params = {
    page: offset + 1,
    perPage: limit,
    skipUserId: true,
    isValid,
  };
  if (organizationId) {
    params.organizationId = organizationId;
  }
  return apiGet(`/api/fleet/getAllFleet`, params);
};
// http://fleetsigma.psychoin.icu/api/v1/paginatedFleet?orgId=7ba5fcbf-c0ff-485c-9f6d-7e58cc854c28&offset=0

export const createVesselAPI = (data) => {
  return apiPost("/api/vessel", data);
};

export const allVesselsByCharAPI = (data) => {
  const {
    vessel = "",
    offset = 0,
    limit = 25,
    skipUserId = false,
  } = data || {};
  return apiGet(`/api/vessel/getAllVessel`, {
    vesselName: vessel,
    perPage: limit,
    page: offset + 1,
    skipUserId: skipUserId,
  });
};

export const updateVesselWithFleetDetailsAPI = (data) => {
  return apiPut(`/api/vessel/${data._id}`, data);
};

export const markFleetAPI = (data) => {
  return apiPost(`/api/fleet/valid/${data.id}/` + data.status);
};

export const createOrganizationAPI = (data) => {
  return apiPost("/api/organizations", data);
};

export const updateOrganizationAPI = (data) => {
  return apiPut(`/api/organizations/${data.id}`, data.data);
};

export const deleteOrganizationAPI = (id) => {
  return apiDelete(`/api/organizations/${id}`);
};

export const updateOrganizationStatusAPI = (data) => {
  const { id, action } = data;
  return apiPut(`/api/organizations/${id}/status/` + action);
};
