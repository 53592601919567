import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

const SidebarItems = ({ item, drawerOpen, selectedRoute, handleDrawerClose = () => { } }) => {
  const history = useHistory();
  const currentPath = history.location.pathname;

  const handleClickNav = (item) => {
    const pageDestination = item.url;

    if (currentPath !== pageDestination) {
      if (drawerOpen) {
        handleDrawerClose()
      }
      return history.push(pageDestination);

    }
  };

  return (
    <>
      <ListItem
        button
        onClick={() => handleClickNav(item)}
        className="flex justify-center gap-6"
      >
        <Tooltip
          title={item.label}
          arrow
          placement="right"
          disableHoverListener={drawerOpen}
        >
          <ListItemIcon
            className={`min-w-max !flex !justify-center items-center ${selectedRoute
              ? "bg-lightGray2 rounded-md border-[1px] border-black"
              : ""
              }`}
            style={{ padding: "3px 6px" }}
          >
            <img src={item.blackImg} alt={item.icon} className="h-7" />
          </ListItemIcon>
        </Tooltip>

        {drawerOpen && (
          <ListItemText
            primary={item.label}
            className={clsx("typo-14-600", { "page-name": selectedRoute })}
          />
        )}
      </ListItem>
    </>
  );
};

export default SidebarItems;
