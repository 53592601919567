import React from "react";
import CloudDownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import Papa from "papaparse";
import { Button } from "@material-ui/core";

const CsvDownloader = ({ data }) => {
  const downloadCSV = () => {
    try {
      const csv = Papa.unparse(data);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");

      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `DemoCsv${Date.now()}.csv`);
        link.style.visibility = "hidden";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading CSV:", error.message);
      alert("Failed to download CSV.");
    }
  };

  return (
    <Button
      className="mr-3"
      variant="contained"
      component="label"
      startIcon={<CloudDownloadIcon />}
      onClick={downloadCSV}
    >
      Download demo CSV
    </Button>
  );
};

export default CsvDownloader;
