import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    Grid,
    Box,
    Paper,
    makeStyles,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Switch,
} from "@material-ui/core";

import * as actions from "./actions";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { getCurrentUserId, getCurrentUserOrganizationId, toastError, toastSuccess } from "../../../utils";
import CustomPagination from "../../../components/Common/CustomPagination";
import CustomConfirmModal from "../../../components/Common/CustomConfirmModal";
import ShareIcon from '@material-ui/icons/Share';
import ShareWithOrg from "../../../components/Common/ShareWithOrg";
import CustomAddModal from "../../../components/Common/CustomAddModal";
import SearchBar from "../../../common/Search";
import _ from "lodash";

const useStyles = makeStyles({
    root: {
        width: "fit-content",
    },
});

const initialSearchType = {
    type: "",
    saveLabel: "",
    name: "",
    firstLoadPort: "",
    lastDischargePort: "",
    loadQuantity: "",
    createdDate: "",
};

function CargoAnalysis(props) {
    const { setLoader = () => { } } = props;
    const classes = useStyles();

    const [value, setValue] = useState(1);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [savedResults, setSavedResults] = useState([]);
    const [allSavedResults, setAllSavedResults] = useState([]);

    const [searchType, setSearchType] = useState({ ...initialSearchType });

    const [myFilters, setMyFilters] = useState({
        offset: 1,
        limit: 10,
    });
    const [allFilters, setAllFilters] = useState({
        offset: 1,
        limit: 10,
    });

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState({});

    const [isShareConfirm, setShareConfirm] = useState(false);
    const [getSingleCargoData, setSingleCargoData] = useState("");
    const [shareLabel, setShareLabel] = useState("");

    const organizationId = getCurrentUserOrganizationId();
    // const addDetailsModalRef = useRef();
    const [searchValue, setSearchValue] = useState("");
    const onSearchVessel = (search) => {
        getAllSavedResults(search);
        getAllOrgSavedResults(search);
    };
    const delaySearch = useCallback(
        _.debounce((data) => onSearchVessel(data), 500),
        []
    );
    const onSearchChange = (e) => {
        setSearchValue(e.target.value)
        delaySearch(e.target.value);
    }

    useEffect(() => {
        getAllSavedResults();
        getAllOrgSavedResults();
    }, []);

    const getAllSavedResults = async (search) => {
        setLoader(true);
        await actions
            .getCargoAnalysisDetails({ userId: getCurrentUserId(), search: search })
            .then((res) => {
                setLoader(false);
                setSavedResults(res?.data?.list || []);
                setMyFilters({
                    totalPages: res?.data?.totalItems,
                    offset: res?.data?.currentPage,
                    pageCount: res?.data?.totalPages,
                    limit: res?.data?.perPage
                })
            })
            .catch((err) => {
                setLoader(false);
                toastError(err?.data?.message);
            });
    };

    const getAllOrgSavedResults = async (search) => {
        setLoader(true);
        await actions
            .getAllCargoAnalysisDetails({ organizationId: organizationId, search: search })
            .then((res) => {
                setLoader(false);
                setAllSavedResults(res?.data?.list || []);
                setAllFilters({
                    totalPages: res?.data?.totalItems,
                    offset: res?.data?.currentPage,
                    pageCount: res?.data?.totalPages,
                    limit: res?.data?.perPage
                })
            })
            .catch((err) => {
                setLoader(false);
                toastError(err?.data?.message);
            });
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchType({ ...initialSearchType });
    };

    const onClickDeletAddress = (id) => {
        setDeleteId(id);
        setDeleteModal(true);
    };



    const onChangeMyPagination = (type = "") => {
        let newMyFilters = {};
        if (type === "inc") {
            newMyFilters = { ...myFilters, offset: myFilters.offset + 1 };
        } else if (type === "first") {
            newMyFilters = { ...myFilters, offset: 1 };
        } else {
            if (myFilters.offset === 1) return;
            newMyFilters = { ...myFilters, offset: myFilters.offset - 1 };
        }
        setLoader(true);
        actions
            .getCargoAnalysisDetails({ ...newMyFilters, userId: getCurrentUserId(), search: searchValue })
            .then((res) => {
                setLoader(false);
                setMyFilters({
                    totalPages: res?.data?.totalItems,
                    offset: res?.data?.currentPage,
                    pageCount: res?.data?.totalPages,
                    limit: res?.data?.perPage
                })
                setSavedResults(
                    (res?.data?.list &&
                        Array.isArray(res?.data?.list) &&
                        res?.data?.list) ||
                    []
                );
            })
            .catch((err) => {
                setLoader(false);
            });
    };

    const onChangeAllPagination = (type = "") => {
        let newallFilters = {}
        if (type === 'inc') {
            newallFilters = { ...allFilters, offset: allFilters.offset + 1 }
        } else if (type === 'first') {
            newallFilters = { ...allFilters, offset: 1 }
        } else {
            if (allFilters.offset === 1) return
            newallFilters = { ...allFilters, offset: allFilters.offset - 1 }
        }
        setLoader(true);
        actions
            .getAllCargoAnalysisDetails({ ...newallFilters, organizationId: organizationId, search: searchValue })
            .then((res) => {
                setLoader(false);
                setAllFilters({
                    totalPages: res?.data?.totalItems,
                    offset: res?.data?.currentPage,
                    pageCount: res?.data?.totalPages,
                    limit: res?.data?.perPage
                })
                setAllSavedResults(
                    (res?.data?.list &&
                        Array.isArray(res?.data?.list) &&
                        res?.data?.list) ||
                    []
                );
            })
            .catch((err) => {
                setLoader(false);
            });
    };

    const onDeleteCargoAnalysis = () => {
        setLoader(true);
        actions
            .deleteCargoAnalysis(deleteId)
            .then((res) => {
                toastSuccess("Result deleted successfully");
                setDeleteModal(false);
                setDeleteId("");
                getAllSavedResults();
            })
            .catch((err) => {
                toastError();
                setLoader(false);
            });
    };

    const onShareOrgSaveClick = async () => {
        if (!organizationId) {
            toastError("You are not part of any organization!");
            return
        }
        if (!shareLabel || !getSingleCargoData) return
        let { _id, ...data } = getSingleCargoData
        data.access = "public"
        data.organizationId = organizationId
        data.cargoAnalysisName = shareLabel
        data.userId = null

        await actions
            .addTeamCargoAnalysisDetails(data)

        setShareConfirm(false)
        setShareLabel("")
        getAllOrgSavedResults()
    }
    const onShareOrgClick = (item) => {
        setShareConfirm(!isShareConfirm)
        setSingleCargoData(item ? item : "")
        setShareLabel("")
    }
    const handleDateRangeChange = async (newDateRange) => {
        const { startDate, endDate } = newDateRange;

        // Ensure dates are in ISO string format
        const formattedStartDate = startDate?.toISOString() || '';
        const formattedEndDate = endDate?.toISOString() || '';

        const { data } = await actions.getCargoAnalysisDetails({
            userId: getCurrentUserId(),
            startDate: formattedStartDate,
            endDate: formattedEndDate
        });
        setSavedResults(data.list);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box
                    display="flex"
                    alignItems="center"
                    position="relative"
                    width="100%"
                >
                    <Box>
                        <Paper className={classes.root}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab value={1} label="My Cargo" />
                                <Tab value={2} label="All Cargo" />
                            </Tabs>
                        </Paper>
                    </Box>

                    <Box
                        position="absolute"
                        left="50%"
                        sx={{
                            transform: 'translateX(-50%)',
                            display: { xs: 'none', md: 'block' } // Desktop view
                        }}
                    >
                        <div className="desktop-searchbar">
                            <SearchBar
                                onDateRangeChange={handleDateRangeChange}
                                placeholder={"Search with Cargo Name..."}
                                setSearchValue={onSearchChange}
                                searchValue={searchValue}
                            />
                        </div>
                    </Box>

                </Box>

                {/* Mobile search bar */}
                <Box
                    className="md:hidden"
                    display="flex"
                    justifyContent="center"
                    mt={2}
                >
                    <SearchBar
                        isMobile
                        onDateRangeChange={handleDateRangeChange}
                        placeholder={"Search with Cargo Name..."}
                        setSearchValue={onSearchChange}
                        searchValue={searchValue}
                    />
                </Box>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
                {value === 1 ? <Box pt={2}>
                    <TableContainer component={Paper}>
                        <Table className="universal-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>No.</TableCell>
                                    <TableCell>Cargo Name</TableCell>
                                    <TableCell>Vessel Name</TableCell>
                                    <TableCell>First Load Port	</TableCell>
                                    <TableCell>Last Discharge Port	</TableCell>
                                    <TableCell>Cargo</TableCell>
                                    <TableCell>Customer</TableCell>
                                    <TableCell>Created on</TableCell>
                                    <TableCell>Created By</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(savedResults) && savedResults.length ? (
                                    savedResults.map((item, index) => {
                                        return <TableRow

                                            key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>

                                                <a
                                                    style={{ color: "blue" }}
                                                    href={`/cargo-analysis/matrix/${item._id}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {item?.clientQuery?.name || ""}
                                                </a>
                                            </TableCell>
                                            <TableCell>{item?.vessel?.vesselName || ''}</TableCell>
                                            <TableCell>
                                                {item?.clientQuery?.cargoList?.find(item => item.operation?.toLowerCase() === "loading")?.port}
                                            </TableCell>
                                            <TableCell>
                                                {item?.clientQuery?.cargoList?.slice()?.reverse()?.find(item => item.operation?.toLowerCase() === "discharging")?.port}
                                            </TableCell>
                                            <TableCell>
                                                {item?.clientQuery?.cargoList?.find(item => item.operation?.toLowerCase() === "loading")?.cargo}
                                            </TableCell>
                                            <TableCell>{item?.clientQuery?.cargoList?.find(item => item.operation?.toLowerCase() === "loading")?.account}</TableCell>
                                            <TableCell>{moment(item.createdAt).format("DD/MMM/yyyy")}</TableCell>
                                            <TableCell>{item?.createdUserName || ''}</TableCell>

                                            <TableCell>
                                                <div className="query-actions">
                                                    <>
                                                        <span className="pending-steper validate-icon-vessel-manager">
                                                            <ShareIcon
                                                                color="primary"
                                                                onClick={() => onShareOrgClick(item)}
                                                            />
                                                        </span>
                                                        <div className="tooltip-notification-vessel-manager">
                                                            <p>Share with your Team</p>
                                                        </div>
                                                    </>
                                                    {/* <VisibilityIcon
                                                        color="primary"
                                                        onClick={() => {
                                                            onClickEditAddress(item);
                                                        }}
                                                    /> */}
                                                    <DeleteIcon
                                                        color="secondary"
                                                        onClick={() =>
                                                            onClickDeletAddress(item?._id)
                                                        }
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow>

                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan="8"
                                            align="center"
                                            className="!bg-white"
                                        >
                                            No Cargo Details Found.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <CustomPagination
                            onChangePagination={onChangeMyPagination}
                            filters={myFilters}
                            totalPage={myFilters?.totalPages}
                        />
                    </TableContainer>
                </Box>
                    : <Box pt={2}>
                        <TableContainer component={Paper}>
                            <Table className="universal-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No.</TableCell>
                                        <TableCell>Cargo Name</TableCell>
                                        <TableCell>Vessel Name</TableCell>
                                        <TableCell>First Load Port	</TableCell>
                                        <TableCell>Last Discharge Port	</TableCell>
                                        <TableCell>Cargo</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell>Created on</TableCell>
                                        <TableCell>Created By</TableCell>
                                        {/* <TableCell>Action</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(allSavedResults) && allSavedResults.length ? (
                                        allSavedResults.map((item, index) => {
                                            return <TableRow

                                                key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>

                                                    <a
                                                        style={{ color: "blue" }}
                                                        href={`/cargo-analysis/matrix/${item?._id}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {item?.clientQuery?.name || ""}
                                                    </a>
                                                </TableCell>
                                                <TableCell>{item?.vessel?.vesselName || ''}</TableCell>

                                                <TableCell>
                                                    {item?.clientQuery?.cargoList?.find(item => item.operation?.toLowerCase() === "loading")?.port}
                                                </TableCell>
                                                <TableCell>
                                                    {item?.clientQuery?.cargoList?.slice()?.reverse()?.find(item => item.operation?.toLowerCase() === "discharging")?.port}
                                                </TableCell>
                                                <TableCell>
                                                    {item?.clientQuery?.cargoList?.find(item => item.operation?.toLowerCase() === "loading")?.cargo}
                                                </TableCell>
                                                <TableCell>{item?.clientQuery?.cargoList?.find(item => item.operation?.toLowerCase() === "loading")?.account}</TableCell>
                                                <TableCell>{moment(item.createdAt).format("DD/MMM/yyyy")}</TableCell>
                                                <TableCell>{item?.createdUserName || ''}</TableCell>

                                                {/* <TableCell>
                                                    <div className="query-actions">
                                                        
                                                        <DeleteIcon
                                                            color="secondary"
                                                            onClick={() =>
                                                                onClickDeletAddress(item?._id)
                                                            }
                                                        />
                                                    </div>
                                                </TableCell> */}
                                            </TableRow>

                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan="8"
                                                align="center"
                                                className="!bg-white"
                                            >
                                                No Cargo Details Found.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <CustomPagination
                                onChangePagination={onChangeAllPagination}
                                filters={allFilters}
                                totalPage={allFilters?.totalPages}
                            />
                        </TableContainer>
                    </Box>}
            </Grid>

            <CustomConfirmModal
                open={deleteModal}
                handleClose={() => setDeleteModal(false)}
                message="Are you sure to delete this cargo analysis?"
                onSubmitConfirm={onDeleteCargoAnalysis}
            />
            <CustomAddModal
                open={isShareConfirm}
                handleClose={onShareOrgClick}
                onSubmitConfirm={onShareOrgSaveClick}
                maxWidth="xs"
                saveLabel="Share"
                title="Share with Team"
            >
                <ShareWithOrg
                    value={shareLabel}
                    onChange={(e) => setShareLabel(e.target.value)}
                    placeholder="Enter Cargo Analysis name*"
                />
            </CustomAddModal>
        </Grid>
    );
}

export default CargoAnalysis;
