import {
  apiGet,
  getCurrentUserId,
  apiPost,
  apiPut,
  getCurrentUserOrganizationId,
  apiDelete,
} from "../../../utils";

export const clientQueryUserAPI = (data = {}) => {
  return apiGet("/api/clientQuery", { ...data });
};

export const clientQueryOrgAPI = (mdata) => {
  const organizationId = getCurrentUserOrganizationId();
  let data = { ...mdata, organizationId: organizationId };

  return apiGet("/api/clientQuery", { ...data });
};

export const getComplaintsAPI = (query) => {
  let data = { ...query, userId: getCurrentUserId() };
  return apiGet("/api/complaints/getComplaints", data);
};

export const getAllComplaintsAPI = (query) => {
  let data = { ...query, userId: getCurrentUserId() };
  return apiGet("/api/complaints/", data);
};

export const getSingleComplaintsAPI = (query) => {
  let { complaintsId } = { ...query };
  return apiGet(`/api/complaints/getComplaints/${complaintsId}`);
};

export const addComplaintsAPI = (data) => {
  return apiPost("/api/complaints/addComplaints", data);
};

export const updateComplaintsAPI = (data) => {
  const complaintId = data.get("_id");
  return apiPost(`/api/complaints/updateComplaints/${complaintId}`, data);
};

export const deleteComplaintsAPI = (id) => {
  return apiDelete(`/api/complaints/deleteComplaints/${id}`);
};
