import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  Fragment,
} from "react";
import { Grid, Select, MenuItem, Button } from "@material-ui/core";
import CustomTextField from "../../../components/Common/CustomTextField";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  individualDummyData,
  organisationDummyData,
  shortNameForAddressBook,
} from "../../../constants";
import * as actions from "./actions";
import { toastSuccess } from "../../../utils";
import { useDispatch } from "react-redux";
import * as commonActions from "../../Common/actions";

import PlusIcon from "../../../assets/icons/plus.png";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { validateDetails } from "./addDetailsValidation";
import CsvDownloader from "../../../components/Common/CsvDownloader";
import { CsvUploader } from "../../../components/Common/CsvUploader";


const AddUpdateDetails = forwardRef((props, ref) => {
  const {
    setLoader = () => { },
    onCloseModal = () => { },
    getAllSavedResults = () => { },
    singleResults = {},
    isEdit = false,
  } = props;
  // const organizationId = getCurrentUserOrganizationId();

  const dispatch = useDispatch();
  const [currentData, setCurrentData] = useState(isEdit ? singleResults : {});
  const [selectedValue, setSelectedValue] = useState(
    isEdit ? singleResults?.type : "Individual"
  );

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const onOpenErrorAlert = (errors = []) => {
    dispatch(commonActions.openErrorAlert({ errorList: errors }));
  };

  useImperativeHandle(ref, () => ({
    async onSubmitQuery() {
      const { errors, isValid } = validateDetails(currentData, selectedValue);
      if (!isValid) return onOpenErrorAlert(errors);
      setLoader(true);

      if (isEdit) {
        try {
          let newQuery = {
            ...currentData,
            type: selectedValue,
            individual_gender: currentData?.individual_gender || "male",
          };

          actions
            .updateAddressDetails(newQuery)
            .then((res) => {
              toastSuccess("Details Updated successfully");
              onCloseModal();
              getAllSavedResults();

              setLoader(false);
            })
            .catch(() => {
              setLoader(false);
            });
        } catch (ex) {
          setLoader(false);
        }
        onCloseModal();
        return;
      }

      let newQuery = {
        ...currentData,
        type: selectedValue,
        individual_gender: currentData?.individual_gender || "male",
      };

      actions
        .addAddressDetails(newQuery)
        .then((res) => {
          toastSuccess("Details added successfully");
          onCloseModal();
          getAllSavedResults();
          // Promise.all([
          //   !!organizationId ? dispatch(actions.clientQueryOrg()) : null,
          //   dispatch(actions.clientQueryUser()),
          //   dispatch(actions.getQueryFormatForUser()),
          // ]);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    },
  }));

  const onChangeHandler = (e) => {
    const { value = "", name = "" } = e.target || {};
    setCurrentData({ ...currentData, [name]: value });
  };

  const onChangeDate = (type, date) => {
    // if (isDetailView) return
    setCurrentData({ ...currentData, [type]: date });
  };

  const handleCsvData = (structuredData) => {
    setCurrentData({ ...currentData, ...structuredData });
  };
  const {
    individual_title = "",
    individual_firstName = "",
    individual_middleName = "",
    individual_lastName = "",
    individual_webPage = "",
    individual_gender = "male",
    individual_birthdate = null,
    individual_anniversary = "",
    individual_location = "",
    individual_language = "",
    individual_emailPersonal = "",
    individual_emailPersonal2 = "",
    individual_mobilePersonal = "",
    individual_mobilePersonal2 = "",
    individual_homeAddress = "",
    individual_personal_city = "",
    individual_personal_state = "",
    individual_personal_postalCode = "",
    individual_personal_country = "",
    individual_shortName = "",
    individual_message = "",

    individual_company = "",
    individual_jobTitle = "",
    individual_department = "",
    individual_officeLocation = "",
    individual_mobileNoOfficial = "",
    individual_mobileNoOfficial2 = "",
    individual_emailOfficial = "",
    individual_emailOfficial2 = "",
    individual_emailOfficial3 = "",
    individual_officeAddress = "",
    individual_professional_city = "",
    individual_professional_state = "",
    individual_professional_postalCode = "",
    individual_professional_country = "",

    organisation_fullName = "",
    organisation_fullStyle = "",
    organisation_typeTags = "",
    organisation_officeLocation = "",
    organisation_contactPerson = "",
    organisation_contactPerson2 = "",
    organisation_mobileOfficial = "",
    organisation_mobileOfficial2 = "",
    organisation_officeAddress = "",
    organisation_emailOfficial = "",
    organisation_emailOfficial2 = "",
    organisation_emailOfficial3 = "",
    organisation_city = "",
    organisation_state = "",
    organisation_postalCode = "",
    organisation_country = "",
    organisation_shortName = "",
    organisation_notes = "",
  } = currentData || {};

  return (
    <Fragment>
      <Grid container className="add-order-container">
        <div className="w-full flex justify-center items-center mt-4">
          <div className="typo-18-500  text-neutrals4 flex justify-between w-full">
            <RadioGroup row value={selectedValue} onChange={handleChange}>
              <FormControlLabel
                value="Individual"
                control={<Radio color="primary" />}
                label="Individual"
              />
              <FormControlLabel
                value="Organisation"
                control={<Radio color="primary" />}
                label="Organisation"
              />
            </RadioGroup>
            <div>
              <CsvDownloader
                data={
                  selectedValue === "Individual"
                    ? individualDummyData
                    : organisationDummyData
                }
              />
              <CsvUploader onDataProcessed={handleCsvData} />

            </div>
          </div>
        </div>
        {selectedValue === "Individual" ? (
          <Grid
            item
            xs={12}
            className="lower-input-grid mt-4 cargo-details-wrap"
          >
            <div className="input-div" style={{ width: "50%" }}>
              <span className="p-1 underline">Personal Info</span>

              <div className="upper-item-div pt-4">
                <p>Title*</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Title"
                    value={individual_title}
                    name="individual_title"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
              <div className="upper-item-div">
                <p>First Name*</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter First Name"
                    value={individual_firstName}
                    name="individual_firstName"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Middle Name</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Middle Name"
                    value={individual_middleName}
                    name="individual_middleName"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Last Name</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Last Name"
                    value={individual_lastName}
                    name="individual_lastName"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Web Page</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Web Page"
                    value={individual_webPage}
                    name="individual_webPage"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Gender</p>
                <div className="inputs">
                  <Select
                    value={individual_gender || ""}
                    onChange={onChangeHandler}
                    name="individual_gender"
                    size="small"
                    variant="outlined"
                    fullWidth
                    className="custom-select"
                  >
                    <MenuItem dense value="male">
                      Male
                    </MenuItem>
                    <MenuItem dense value="female">
                      Female
                    </MenuItem>
                    <MenuItem dense value="other">
                      Other
                    </MenuItem>
                  </Select>
                </div>
              </div>
              <div className="upper-item-div">
                <p>Birth date</p>
                <div className="inputs">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      autoOk
                      fullWidth
                      // variant="inline"
                      inputVariant="outlined"
                      format="DD MMM yyyy HH:mm A"
                      placeholder="birthday date"
                      value={individual_birthdate || null}
                      InputAdornmentProps={{ position: "end" }}
                      onChange={(date) =>
                        onChangeDate("individual_birthdate", date)
                      }
                      className="custom-date-time-picker"
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div className="upper-item-div">
                <p>Anniversary date</p>
                <div className="inputs">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      autoOk
                      fullWidth
                      // variant="inline"
                      inputVariant="outlined"
                      format="DD MMM yyyy HH:mm A"
                      placeholder="Anniversary date"
                      value={individual_anniversary || null}
                      InputAdornmentProps={{ position: "end" }}
                      onChange={(date) =>
                        onChangeDate("individual_anniversary", date)
                      }
                      className="custom-date-time-picker"
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div className="upper-item-div">
                <p>Location</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Location"
                    value={individual_location}
                    name="individual_location"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Language</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Language"
                    value={individual_language}
                    name="individual_language"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>E-mail (personal)*</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter e-mail (personal)"
                    value={individual_emailPersonal}
                    name="individual_emailPersonal"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>E-mail (personal)2</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter e-mail (personal)2"
                    value={individual_emailPersonal2}
                    name="individual_emailPersonal2"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Mobile No (Personal)</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Mobile No (Personal)"
                    value={individual_mobilePersonal}
                    name="individual_mobilePersonal"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Mobile No (Personal)2</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Mobile No (Personal)2"
                    value={individual_mobilePersonal2}
                    name="individual_mobilePersonal2"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Home Address</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Home Address"
                    value={individual_homeAddress}
                    name="individual_homeAddress"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>City</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter City"
                    value={individual_personal_city}
                    name="individual_personal_city"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>State</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter State"
                    value={individual_personal_state}
                    name="individual_personal_state"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Postal Code</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Postal Code"
                    value={individual_personal_postalCode}
                    name="individual_personal_postalCode"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Country</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Country"
                    value={individual_personal_country}
                    name="individual_personal_country"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>ShortName*</p>
                <div className="inputs">
                  <Select
                    value={individual_shortName || ""}
                    onChange={onChangeHandler}
                    name="individual_shortName"
                    size="small"
                    variant="outlined"
                    fullWidth
                    className="custom-select"
                  >
                    {shortNameForAddressBook?.map((itm, i) => {
                      return (
                        <MenuItem dense value={itm?.value}>
                          {itm?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="textarea-div upper-item-div">
                <p>Notes/Remarks:</p>
                <textarea
                  className="add-order-textarea"
                  style={{ minWidth: "auto" }}
                  placeholder="Please enter your remarks here...."
                  value={individual_message}
                  name="individual_message"
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            <div className="input-div" style={{ width: "50%" }}>
              <span className="p-1 underline">Professional Info</span>
              <div className="upper-item-div pt-4">
                <p>Company</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Company"
                    value={individual_company}
                    name="individual_company"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Job Title</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Job Title"
                    value={individual_jobTitle}
                    name="individual_jobTitle"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Department</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Department"
                    value={individual_department}
                    name="individual_department"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
              <div className="upper-item-div">
                <p>Office Location</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Office Location"
                    value={individual_officeLocation}
                    name="individual_officeLocation"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
              <div className="upper-item-div">
                <p>Mobile No (official)</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Mobile No (official)"
                    value={individual_mobileNoOfficial}
                    name="individual_mobileNoOfficial"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Mobile No (official)2</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Mobile No (official)2"
                    value={individual_mobileNoOfficial2}
                    name="individual_mobileNoOfficial2"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>E-mail (official)</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter e-mail (official)"
                    value={individual_emailOfficial}
                    name="individual_emailOfficial"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>E-mail (official)2</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter e-mail (official)2"
                    value={individual_emailOfficial2}
                    name="individual_emailOfficial2"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>E-mail (official)3</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter e-mail (official)3"
                    value={individual_emailOfficial3}
                    name="individual_emailOfficial3"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Office Address</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Office Address"
                    value={individual_officeAddress}
                    name="individual_officeAddress"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
              <div className="upper-item-div">
                <p>City</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter City"
                    value={individual_professional_city}
                    name="individual_professional_city"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>State</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter State"
                    value={individual_professional_state}
                    name="individual_professional_state"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Postal Code</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Postal Code"
                    value={individual_professional_postalCode}
                    name="individual_professional_postalCode"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Country</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Country"
                    value={individual_professional_country}
                    name="individual_professional_country"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            className="lower-input-grid mt-4 cargo-details-wrap"
          >
            <div className="input-div" style={{ width: "50%" }}>
              <div className="upper-item-div pt-4">
                <p>Full Name*</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Full Name"
                    value={organisation_fullName}
                    name="organisation_fullName"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
              <div className="upper-item-div">
                <p>Full Style</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Full Style"
                    value={organisation_fullStyle}
                    name="organisation_fullStyle"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Type/Tags </p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Type/Tags"
                    value={organisation_typeTags}
                    name="organisation_typeTags"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Office Location</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Office Location"
                    value={organisation_officeLocation}
                    name="organisation_officeLocation"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Contact Person</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Contact Person"
                    value={organisation_contactPerson}
                    name="organisation_contactPerson"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Contact Person2</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Contact Person2"
                    value={organisation_contactPerson2}
                    name="organisation_contactPerson2"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Mobile No (official)</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Mobile No (official)"
                    value={organisation_mobileOfficial}
                    name="organisation_mobileOfficial"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Mobile No (official)2</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Mobile No (official)2"
                    value={organisation_mobileOfficial2}
                    name="organisation_mobileOfficial2"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
              <div className="upper-item-div">
                <p>Office Address</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Office Address"
                    value={organisation_officeAddress}
                    name="organisation_officeAddress"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="input-div" style={{ width: "50%" }}>
              <div className="upper-item-div pt-4">
                <p>E-mail (official)*</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter e-mail (official)"
                    value={organisation_emailOfficial}
                    name="organisation_emailOfficial"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>E-mail (official)2</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter e-mail (official)2"
                    value={organisation_emailOfficial2}
                    name="organisation_emailOfficial2"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>E-mail (official)3</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter e-mail (official)3"
                    value={organisation_emailOfficial3}
                    name="organisation_emailOfficial3"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>City</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter City"
                    value={organisation_city}
                    name="organisation_city"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>State</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter State"
                    value={organisation_state}
                    name="organisation_state"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Postal Code</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Postal Code"
                    value={organisation_postalCode}
                    name="organisation_postalCode"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="upper-item-div">
                <p>Country</p>
                <div className="inputs" style={{ width: "100%" }}>
                  <CustomTextField
                    size="small"
                    placeholder="Enter Country"
                    value={organisation_country}
                    name="organisation_country"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
              <div className="upper-item-div">
                <p>ShortName*</p>
                <div className="inputs">
                  <Select
                    value={organisation_shortName || ""}
                    onChange={onChangeHandler}
                    name="organisation_shortName"
                    size="small"
                    variant="outlined"
                    fullWidth
                    className="custom-select"
                  >
                    {shortNameForAddressBook?.map((itm, i) => {
                      return (
                        <MenuItem dense value={itm?.value}>
                          {itm?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="textarea-div upper-item-div">
                <p>Notes/Remarks:</p>
                <textarea
                  className="add-order-textarea"
                  style={{ minWidth: "auto" }}
                  placeholder="Please enter your remarks here...."
                  value={organisation_notes}
                  name="organisation_notes"
                  onChange={onChangeHandler}
                />
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
});

export default AddUpdateDetails;




