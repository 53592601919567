import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AddNewVessel from "../../../../components/Dashboard/AddNewVessel";
import DeleteIcon from "@material-ui/icons/Delete";

import { checkValidation, toastError, toastSuccess } from "../../../../utils";
import * as calculatorActions from "../../Calculator/actions";
import * as dashboardActions from "../../Dashboard/actions";
import { Button, Grid, Paper, Switch, TextField } from "@material-ui/core";
import {
    vesselCalculatorMainFields,
    vesselDetailsOption1,
    vesselDetailsOption2,
} from "../../../../constants";
import { handleKeyDown } from "../../../../common/common";
import CustomButton from "../../../../components/Common/CustomButton";
import SplitHireModal from "../../../../components/Calculator/SplitHireModal";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useDispatch } from "react-redux";
import * as commonActions from "../../../Common/actions";

export const initialRowData = {
    vesselName: "",
    vesselDwt: 0,
    vesselIMO: 0,
    vesselBalSpd1: 0,
    vesselBalConsmHS1: 0,
    vesselBalConsmLS1: 0,
    vesselBalConsmMGO1: 0,
    vesselBalSpd2: 0,
    vesselBalConsmHS2: 0,
    vesselBalConsmLS2: 0,
    vesselBalConsmMGO2: 0,
    vesselBalSpd3: 0,
    vesselBalConsmHS3: 0,
    vesselBalConsmLS3: 0,
    vesselBalConsmMGO3: 0,
    vesselBalSpd4: 0,
    vesselBalConsmHS4: 0,
    vesselBalConsmLS4: 0,
    vesselBalConsmMGO4: 0,
    vesselLdnSpd1: 0,
    vesselLdnConsmHS1: 0,
    vesselLdnConsmLS1: 0,
    vesselLdnConsmMGO1: 0,
    vesselLdnSpd2: 0,
    vesselLdnConsmHS2: 0,
    vesselLdnConsmLS2: 0,
    vesselLdnConsmMGO2: 0,
    vesselLdnSpd3: 0,
    vesselLdnConsmHS3: 0,
    vesselLdnConsmLS3: 0,
    vesselLdnConsmMGO3: 0,
    vesselLdnSpd4: 0,
    vesselLdnConsmHS4: 0,
    vesselLdnConsmLS4: 0,
    vesselLdnConsmMGO4: 0,
    vesselPIdleConsmHS: 0,
    vesselPIdleConsmLS: 0,
    vesselPIdleConsmMGO: 0,
    vesselPLdgConsmHS: 0,
    vesselPLdgConsmLS: 0,
    vesselPLdgConsmMGO: 0,
    vesselPDischConsmHS: 0,
    vesselPDischConsmLS: 0,
    vesselPDischConsmMGO: 0,
    vesselVExtraConsmHS: 0,
    vesselVExtraConsmLS: 0,
    vesselVExtraConsmMGO: 0,
    vesselBalComb1Selected: false,
    vesselBalComb2Selected: false,
    vesselBalComb3Selected: false,
    vesselBalComb4Selected: false,
    vesselLadComb1Selected: false,
    vesselLadComb2Selected: false,
    vesselLadComb3Selected: false,
    vesselLadComb4Selected: false,
    vesselExtraDetails: {}
};

export const initialVessel = {
    bnkDelhs: 0,
    bnkDells: 0,
    bnkDelmgo: 0,
    bnkPriceDeliveryhs: 0,
    bnkPriceDeliveryls: 0,
    bnkPriceDeliverymgo: 0,
    bnkPriceRedeliveryhs: 0,
    bnkPriceRedeliveryls: 0,
    bnkPriceRedeliverymgo: 0,
    bnkPriceSettlemenths: 0,
    bnkPriceSettlementls: 0,
    bnkPriceSettlementmgo: 0,
    bnkQtyRedeliveryhs: 0,
    bnkQtyRedeliveryls: 0,
    bnkQtyRedeliverymgo: 0,
    bnkRedeliveryQtyTolerancehs: 0,
    bnkRedeliveryQtyTolerancels: 0,
    bnkRedeliveryQtyTolerancemgo: 0,
    bunkerPriceOverridden: false,
    dateOpen: Date.now()
}
function EditVesselOnFleetAdd(props) {
    const { setLoader = () => { }, setValidationVessel, setAddFleetsArray = () => { }, addFleetsArray, onValidationVesselIndex = 0, onClickAddFleet = () => { }, isFromCreateVessel = false } = props;
    const [vessel, setVessel] = useState({});
    const [isOverride, setIsOverride] = useState(false);
    useEffect(() => {
        setVessel({ ...initialVessel, selectedVessel: { ...initialRowData, ...addFleetsArray[onValidationVesselIndex]?.selectedVessel }, ...addFleetsArray[onValidationVesselIndex] });
    }, []);

    const onChangeHandler = (e, isExtra = false, isCheckbox) => {
        const { name = "", value = "", checked } = e.target;
        const updatedVessel = { ...vessel };
        const { vesselExtraDetails = {} } = updatedVessel.selectedVessel;

        if (isExtra) {
            vesselExtraDetails[name] = isCheckbox ? checked : value;
        } else {
            updatedVessel.selectedVessel[name] = isCheckbox ? checked : value;
        }
        setVessel({
            ...updatedVessel,
            vesselExtraDetails: {
                ...vesselExtraDetails,
            },
        });
    };
    const dispatch = useDispatch();
    const onOpenErrorAlert = (errors = []) => {
        dispatch(commonActions.openErrorAlert({ errorList: errors }));
    };
    const onSubmitUpdateVessel = () => {
        if (vessel?.selectedVessel) {
            let updatedVal = addFleetsArray
            updatedVal[onValidationVesselIndex] = vessel
            const error = checkValidation(vessel, vessel?.selectedVessel, 4)
            if (error?.length) {
                return onOpenErrorAlert(error);
            }
            updatedVal[onValidationVesselIndex].isValidate = true;
            setAddFleetsArray(updatedVal);
            setValidationVessel(false)
        }
    };


    const {
        vesselName = "",
        vesselDwt = 0,
        vesselIMO = 0,
        vesselBalSpd1 = 0,
        vesselBalConsmHS1 = 0,
        vesselBalConsmLS1 = 0,
        vesselBalConsmMGO1 = 0,
        vesselBalSpd2 = 0,
        vesselBalConsmHS2 = 0,
        vesselBalConsmLS2 = 0,
        vesselBalConsmMGO2 = 0,
        vesselBalSpd3 = 0,
        vesselBalConsmHS3 = 0,
        vesselBalConsmLS3 = 0,
        vesselBalConsmMGO3 = 0,
        vesselBalSpd4 = 0,
        vesselBalConsmHS4 = 0,
        vesselBalConsmLS4 = 0,
        vesselBalConsmMGO4 = 0,
        vesselLdnSpd1 = 0,
        vesselLdnConsmHS1 = 0,
        vesselLdnConsmLS1 = 0,
        vesselLdnConsmMGO1 = 0,
        vesselLdnSpd2 = 0,
        vesselLdnConsmHS2 = 0,
        vesselLdnConsmLS2 = 0,
        vesselLdnConsmMGO2 = 0,
        vesselLdnSpd3 = 0,
        vesselLdnConsmHS3 = 0,
        vesselLdnConsmLS3 = 0,
        vesselLdnConsmMGO3 = 0,
        vesselLdnSpd4 = 0,
        vesselLdnConsmHS4 = 0,
        vesselLdnConsmLS4 = 0,
        vesselLdnConsmMGO4 = 0,
        vesselPIdleConsmHS = 0,
        vesselPIdleConsmLS = 0,
        vesselPIdleConsmMGO = 0,
        vesselPLdgConsmHS = 0,
        vesselPLdgConsmLS = 0,
        vesselPLdgConsmMGO = 0,
        vesselPDischConsmHS = 0,
        vesselPDischConsmLS = 0,
        vesselPDischConsmMGO = 0,
        vesselVExtraConsmHS = 0,
        vesselVExtraConsmLS = 0,
        vesselVExtraConsmMGO = 0,
        vesselBalComb1Selected = false,
        vesselBalComb2Selected = false,
        vesselBalComb3Selected = false,
        vesselBalComb4Selected = false,
        vesselLadComb1Selected = false,
        vesselLadComb2Selected = false,
        vesselLadComb3Selected = false,
        vesselLadComb4Selected = false,
        vesselExtraDetails = {},
    } = vessel?.selectedVessel || {};

    const addNewSplitHire = () => {
        let splitHire =
            (vessel &&
                vessel.splitHire &&
                JSON.parse(vessel.splitHire)) ||
            [];
        if (!!splitHire.find((item) => item.NoDays === 0)) return;
        splitHire.push({ NoDays: 0, applied: 0, disable: false, hireRate: 0 });
        setVessel({
            ...vessel,
            splitHire: JSON.stringify(splitHire),
        });
    };
    const onChangeBunkerPriceOverridden = (e) => {
        let {
            name = "",
            checked = "",
        } = e.target || {};
        let newUpdatedFleet = { ...vessel };
        newUpdatedFleet[name] = checked;
        setVessel(newUpdatedFleet);
    }
    const onChangeSplitHire = (index, e, isDelete = false) => {
        let splitHire =
            (vessel &&
                vessel.splitHire &&
                JSON.parse(vessel.splitHire)) ||
            [];
        if (isDelete) splitHire.splice(index, 1);
        else {
            const { name = "", value = "" } = e.target;
            splitHire[index][name] = Number(value);
        }
        const udpatedvessel = {
            ...vessel,
            splitHire: JSON.stringify(splitHire),
        };
        setVessel(udpatedvessel);
    };

    const onChangeSelectedVessel = (e, fieldname = false, isDate = false) => {
        let {
            name = "",
            value = "",
        } = e.target || {};
        if (isDate) {
            value = e
            name = fieldname
        }
        let newUpdatedFleet = { ...vessel };
        newUpdatedFleet[name] = value;
        setVessel(newUpdatedFleet);
    };

    const {
        bnkDelhs = 0,
        bnkDells = 0,
        bnkDelmgo = 0,
        bnkPriceDeliveryhs = 0,
        bnkPriceDeliveryls = 0,
        bnkPriceDeliverymgo = 0,
        bnkPriceRedeliveryhs = 0,
        bnkPriceRedeliveryls = 0,
        bnkPriceRedeliverymgo = 0,
        bnkPriceSettlemenths = 0,
        bnkPriceSettlementls = 0,
        bnkPriceSettlementmgo = 0,
        bnkQtyRedeliveryhs = 0,
        bnkQtyRedeliveryls = 0,
        bnkQtyRedeliverymgo = 0,
        bnkRedeliveryQtyTolerancehs = 0,
        bnkRedeliveryQtyTolerancels = 0,
        bnkRedeliveryQtyTolerancemgo = 0,
        bunkerPriceOverridden = false,
        constants = 0,
        extraExpense = 0
    } = vessel || {}
    return (
        <Grid container style={{ height: "calc(100vh -50px)" }}>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>

                <div style={{ width: "135px" }}>
                    <CustomButton onClick={() => onSubmitUpdateVessel()}>Update</CustomButton>
                </div>

            </Grid>
            <Grid item xs={12} md={12} style={{ height: "100%" }}>
                <Grid container>
                    <Grid item xs={12} md={6} style={{ padding: 5 }}>
                        <div style={{ background: "lightgrey" }}>
                            <Paper>
                                <Grid container>
                                    {/* <Grid item xs={12}>
                                        <div className="d-flex">
                                            <h4 className="result-heading" style={{ textDecoration: "underline" }}><Link to={`/vessel/${vessel.id}`} target="_blank" >Vessel Details</Link></h4>
                                            <div><button color="primary" className="add-vessel-calculator" onClick={() => setOpen(true)}>+</button></div>
                                        </div>
                                    </Grid> */}

                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <div className="vessel-name-div">
                                                    <span>Vessel</span>
                                                    <span>
                                                        <input
                                                            disabled={true}
                                                            value={vesselName}
                                                            type="text"
                                                            name="vesselName"
                                                            onChange={onChangeHandler}
                                                        />
                                                    </span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <div className="vessel-name-div">
                                                    <span>Dwt</span>
                                                    <span>
                                                        <input
                                                            disabled={!isFromCreateVessel}
                                                            value={vesselDwt}
                                                            name="vesselDwt"
                                                            onChange={onChangeHandler}
                                                        />
                                                    </span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <div className="vessel-name-div">
                                                    <span>IMO</span>
                                                    <span>
                                                        <input
                                                            disabled={!isFromCreateVessel}
                                                            value={vesselIMO}
                                                            name="vesselIMO"
                                                            onChange={onChangeHandler}
                                                        />
                                                    </span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <table className="fleet-result-table vesselDetails">
                                            <tbody>
                                                {vesselCalculatorMainFields.map((item, key) => {
                                                    if (
                                                        item.name === "fuelType" ||
                                                        item.name === "vesselScrubber"
                                                    )
                                                        return null;
                                                    return (
                                                        <tr key={key}>
                                                            <th>{item.label}</th>
                                                            <td>
                                                                <input
                                                                    value={vessel?.selectedVessel?.[item.name] || ""}
                                                                    type={item.type}
                                                                    onKeyDown={handleKeyDown}
                                                                    name={item.name}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p className="m-b-5" style={{ fontSize: 14 }}>
                                                    Ballast Sea Consumptions
                                                </p>
                                                <table className="vessel-details-tables">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Speed</th>
                                                            <th>HS</th>
                                                            <th>LS</th>
                                                            <th>MGO</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselBalComb1Selected"
                                                                    checked={vesselBalComb1Selected || false}
                                                                    onChange={(e) => onChangeHandler(e, false, true)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalSpd1"
                                                                    value={vesselBalSpd1 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmHS1"
                                                                    value={vesselBalConsmHS1 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmLS1"
                                                                    value={vesselBalConsmLS1 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmMGO1"
                                                                    value={vesselBalConsmMGO1 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselBalComb2Selected"
                                                                    checked={vesselBalComb2Selected || false}
                                                                    // onChange={onChangeHandler}
                                                                    onChange={(e) => onChangeHandler(e, false, true)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalSpd2"
                                                                    value={vesselBalSpd2 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmHS2"
                                                                    value={vesselBalConsmHS2 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmLS2"
                                                                    value={vesselBalConsmLS2 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmMGO2"
                                                                    value={vesselBalConsmMGO2 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselBalComb3Selected"
                                                                    checked={vesselBalComb3Selected || false}
                                                                    // onChange={onChangeHandler}
                                                                    onChange={(e) => onChangeHandler(e, false, true)}

                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalSpd3"
                                                                    value={vesselBalSpd3 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmHS3"
                                                                    value={vesselBalConsmHS3 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmLS3"
                                                                    value={vesselBalConsmLS3 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmMGO3"
                                                                    value={vesselBalConsmMGO3 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselBalComb4Selected"
                                                                    checked={vesselBalComb4Selected || false}
                                                                    // onChange={onChangeHandler}
                                                                    onChange={(e) => onChangeHandler(e, false, true)}

                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalSpd4"
                                                                    value={vesselBalSpd4 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmHS4"
                                                                    value={vesselBalConsmHS4 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmLS4"
                                                                    value={vesselBalConsmLS4 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselBalConsmMGO4"
                                                                    value={vesselBalConsmMGO4 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <p className="m-t-5 m-b-5" style={{ fontSize: 14 }}>
                                                    Laden Sea Consumptions
                                                </p>
                                                <table className="vessel-details-tables">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Speed</th>
                                                            <th>HS</th>
                                                            <th>LS</th>
                                                            <th>MGO</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselLadComb1Selected"
                                                                    checked={vesselLadComb1Selected || false}
                                                                    // onChange={onChangeHandler}
                                                                    onChange={(e) => onChangeHandler(e, false, true)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnSpd1"
                                                                    value={vesselLdnSpd1 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmHS1"
                                                                    value={vesselLdnConsmHS1 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmLS1"
                                                                    value={vesselLdnConsmLS1 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmMGO1"
                                                                    value={vesselLdnConsmMGO1 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselLadComb2Selected"
                                                                    checked={vesselLadComb2Selected || false}
                                                                    // onChange={onChangeHandler}
                                                                    onChange={(e) => onChangeHandler(e, false, true)}

                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnSpd2"
                                                                    value={vesselLdnSpd2 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmHS2"
                                                                    value={vesselLdnConsmHS2 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmLS2"
                                                                    value={vesselLdnConsmLS2 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmMGO2"
                                                                    value={vesselLdnConsmMGO2 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselLadComb3Selected"
                                                                    checked={vesselLadComb3Selected || false}
                                                                    // onChange={onChangeHandler}
                                                                    onChange={(e) => onChangeHandler(e, false, true)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnSpd3"
                                                                    value={vesselLdnSpd3 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmHS3"
                                                                    value={vesselLdnConsmHS3 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmLS3"
                                                                    value={vesselLdnConsmLS3 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmMGO3"
                                                                    value={vesselLdnConsmMGO3 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-type-checkbox"
                                                                    name="vesselLadComb4Selected"
                                                                    checked={vesselLadComb4Selected || false}
                                                                    // onChange={onChangeHandler}
                                                                    onChange={(e) => onChangeHandler(e, false, true)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnSpd4"
                                                                    value={vesselLdnSpd4 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmHS4"
                                                                    value={vesselLdnConsmHS4 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmLS4"
                                                                    value={vesselLdnConsmLS4 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselLdnConsmMGO4"
                                                                    value={vesselLdnConsmMGO4 || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p className="m-t-5 m-b-5" style={{ fontSize: 14 }}>
                                                    Port Consumptions
                                                </p>
                                                <table className="vessel-details-tables">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>HS</th>
                                                            <th>LS</th>
                                                            <th>MGO</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th>Idle</th>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPIdleConsmHS"
                                                                    value={vesselPIdleConsmHS || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPIdleConsmLS"
                                                                    value={vesselPIdleConsmLS || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPIdleConsmMGO"
                                                                    value={vesselPIdleConsmMGO || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Loading</th>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPLdgConsmHS"
                                                                    value={vesselPLdgConsmHS || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPLdgConsmLS"
                                                                    value={vesselPLdgConsmLS || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPLdgConsmMGO"
                                                                    value={vesselPLdgConsmMGO || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Disch</th>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPDischConsmHS"
                                                                    value={vesselPDischConsmHS || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPDischConsmLS"
                                                                    value={vesselPDischConsmLS || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselPDischConsmMGO"
                                                                    value={vesselPDischConsmMGO || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Extra</th>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselVExtraConsmHS"
                                                                    value={vesselVExtraConsmHS || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselVExtraConsmLS"
                                                                    value={vesselVExtraConsmLS || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="0"
                                                                    name="vesselVExtraConsmMGO"
                                                                    value={vesselVExtraConsmMGO || ""}
                                                                    onChange={onChangeHandler}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3} style={{ padding: 5 }}>
                        <Grid item xs={12} md={12}>
                            <span style={{ fontSize: "14px", fontWeight: "500" }}>Split hire</span>
                            <SplitHireModal
                                addNewSplitHire={addNewSplitHire}
                                selectedSplitHire={vessel}
                                onChangeSplitHire={onChangeSplitHire}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>

                            {/* <SplitHireModal
                                addNewSplitHire={addNewSplitHire}
                                selectedSplitHire={vessel}
                                onChangeSplitHire={onChangeSplitHire}
                            /> */}

                            <div className="show-bunker-lower-prices-from-single-vessel" style={{ width: "300px", float: "unset", marginTop: "10px" }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Bunker Qty </th>
                                            <th>HS(0.5)</th>
                                            <th>LS(0.1)</th>
                                            <th>MGO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>On Delivery</th>
                                            <td><input type="number" onKeyDown={handleKeyDown} value={bnkDelhs} name="bnkDelhs" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                            <td><input type="number" onKeyDown={handleKeyDown} value={bnkDells} name="bnkDells" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                            <td><input type="number" onKeyDown={handleKeyDown} value={bnkDelmgo} name="bnkDelmgo" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                        </tr>
                                        <tr>
                                            <th>Exp on Re-Delivery</th>
                                            <td><input type="number" onKeyDown={handleKeyDown} value={bnkQtyRedeliveryhs} name="bnkQtyRedeliveryhs" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                            <td><input type="number" onKeyDown={handleKeyDown} value={bnkQtyRedeliveryls} name="bnkQtyRedeliveryls" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                            <td><input type="number" onKeyDown={handleKeyDown} value={bnkQtyRedeliverymgo} name="bnkQtyRedeliverymgo" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                        </tr>
                                        <tr>
                                            <th>Tolerance Acceptable</th>
                                            <td><input type="number" onKeyDown={handleKeyDown} value={bnkRedeliveryQtyTolerancehs} name="bnkRedeliveryQtyTolerancehs" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                            <td><input type="number" onKeyDown={handleKeyDown} value={bnkRedeliveryQtyTolerancels} name="bnkRedeliveryQtyTolerancels" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                            <td><input type="number" onKeyDown={handleKeyDown} value={bnkRedeliveryQtyTolerancemgo} name="bnkRedeliveryQtyTolerancemgo" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>

                            <div style={{ marginBottom: 0, }} className="sync-bunker-switch">
                                <label>Override</label>
                                <Switch
                                    checked={bunkerPriceOverridden}
                                    onChange={e => onChangeBunkerPriceOverridden(e)}
                                    name="bunkerPriceOverridden"
                                    color="primary"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>

                            <div className="show-bunker-lower-prices-from-single-vessel" style={{ width: "300px", float: "unset", display: "flex" }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Bunker Prices</th>
                                            <th>HS(0.5)</th>
                                            <th>LS(0.1)</th>
                                            <th>MGO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th> On Delivery</th>
                                            <td>{!bunkerPriceOverridden ? <p>{bnkPriceDeliveryhs}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceDeliveryhs} disabled={!bunkerPriceOverridden} name="bnkPriceDeliveryhs" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                            <td>{!bunkerPriceOverridden ? <p>{bnkPriceDeliveryls}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceDeliveryls} disabled={!bunkerPriceOverridden} name="bnkPriceDeliveryls" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                            <td>{!bunkerPriceOverridden ? <p>{bnkPriceDeliverymgo}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceDeliverymgo} disabled={!bunkerPriceOverridden} name="bnkPriceDeliverymgo" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                        </tr>
                                        <tr>
                                            <th> On Re-Delivery</th>
                                            <td>{!bunkerPriceOverridden ? <p>{bnkPriceRedeliveryhs}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceRedeliveryhs} name="bnkPriceRedeliveryhs" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                            <td>{!bunkerPriceOverridden ? <p>{bnkPriceRedeliveryls}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceRedeliveryls} name="bnkPriceRedeliveryls" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                            <td>{!bunkerPriceOverridden ? <p>{bnkPriceRedeliverymgo}</p> : <input onKeyDown={handleKeyDown} type="number" value={bnkPriceRedeliverymgo} name="bnkPriceRedeliverymgo" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                        </tr>
                                        <tr>
                                            <th> For Settlement</th>
                                            <td>{!bunkerPriceOverridden ? <p>{bnkPriceSettlemenths}</p> : <input type="number" onKeyDown={handleKeyDown} value={bnkPriceSettlemenths} name="bnkPriceSettlemenths" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                            <td>{!bunkerPriceOverridden ? <p>{bnkPriceSettlementls}</p> : <input type="number" onKeyDown={handleKeyDown} value={bnkPriceSettlementls} name="bnkPriceSettlementls" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                            <td>{!bunkerPriceOverridden ? <p>{bnkPriceSettlementmgo}</p> : <input type="number" onKeyDown={handleKeyDown} value={bnkPriceSettlementmgo} name="bnkPriceSettlementmgo" onChange={e => onChangeSelectedVessel(e, true)} />}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} style={{ padding: 5, paddingTop: "25px", paddingLeft: "10px" }}>
                        <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
                            <table className="fleet-result-table" style={{ marginBottom: "10px" }}>
                                <tr>
                                    <th>Extra Expenses</th>
                                    <td>
                                        <input
                                            type="number"
                                            onKeyDown={handleKeyDown}
                                            placeholder="0"
                                            onChange={(e) => onChangeSelectedVessel(e, true)}
                                            value={
                                                (vessel && vessel.extraExpense) || 0
                                            }
                                            name="extraExpense"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Constants</th>
                                    <td>
                                        <input
                                            type="number"
                                            onKeyDown={handleKeyDown}
                                            placeholder="0"
                                            onChange={(e) => onChangeSelectedVessel(e, true)}
                                            value={
                                                (vessel && vessel.constants) || 0
                                            }
                                            name="constants"
                                        />
                                    </td>
                                </tr>

                            </table>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>

                            <table className="fleet-result-table" style={{ marginTop: "10px" }}>


                                {/* <tr>
                                <th>Hire GBB</th>
                                <td>
                                    <input
                                        type="number"
                                        onKeyDown={handleKeyDown}
                                        value={hireGbb}
                                        name="hireGbb"
                                        className="custom-input-field"
                                        onChange={(e) =>
                                            onChangeSelectedVessel(

                                                e.target.value,
                                                true
                                            )
                                        }
                                    />
                                </td>
                            </tr> */}
                                <tr>
                                    <th>Date Opening</th>
                                    <td>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DateTimePicker
                                                inputVariant="outlined"
                                                size="small"
                                                name="dateOpen"
                                                className="date-time-picker-update-fleet"
                                                style={{ width: 135, background: "#fff" }}

                                                value={(vessel && vessel.dateOpen) || Date.now()}
                                                onChange={(date) =>
                                                    onChangeSelectedVessel(date, "dateOpen", true)
                                                }
                                            />
                                        </MuiPickersUtilsProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <th>GBB</th>
                                    <td>
                                        <input
                                            type="number"
                                            onKeyDown={handleKeyDown}
                                            value={(vessel && vessel.gbb) || 0}
                                            name="gbb"
                                            className="custom-input-field"
                                            onChange={(e) =>
                                                onChangeSelectedVessel(
                                                    e,
                                                    true
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Hire Per Day</th>
                                    <td>
                                        <input
                                            type="number"
                                            onKeyDown={handleKeyDown}
                                            value={(vessel && vessel.hirePerDay) || 0}
                                            name="hirePerDay"
                                            className="custom-input-field"
                                            onChange={(e) =>
                                                onChangeSelectedVessel(
                                                    e,
                                                    true
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Hire GBB</th>
                                    <td>
                                        <input
                                            type="number"
                                            onKeyDown={handleKeyDown}
                                            value={(vessel && vessel.hireGbb) || 0}
                                            name="hireGbb"
                                            className="custom-input-field"
                                            onChange={(e) =>
                                                onChangeSelectedVessel(
                                                    e,
                                                    true
                                                )
                                            }
                                        />
                                    </td>
                                </tr>

                            </table>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>

        </Grid >
    );
}

export default EditVesselOnFleetAdd;
