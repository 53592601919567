import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import * as actions from "../api";
import * as calculationAction from "../actions"
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import AccountCargoDetails from "../../../../components/Calculator/AccountCargoDetails";
import CalculatorVesselDetails from "../../../../components/Calculator/CalculatorVesselDetails";
import MarginAndAllowances from "../../../../components/Calculator/MarginAndAllowances";
import Expenses from "../../../../components/Calculator/Expenses";
import Result from "../../../../components/Calculator/Result";
import PortOperations from "../../../../components/Calculator/PortOperations";
import BunkerSupply from "../../../../components/Calculator/BunkerSupply";
import MarginAllowancesTable from "../../../../components/Calculator/MarginAllowancesTable";
import { toastError, toastSuccess } from "../../../../utils";
import { addPortDistanceToOperations, addPortDistanceToSelectedFleet, calculationValidation, convertPortOperationToDistanceString, fleetWithCalculatorDataMapping } from "../dataMapping";
import ActionButtons from "../../../../components/Calculator/ActionButtons";
import _, { cloneDeep } from "lodash";
import { initialCreatePortOperation, termsCoEfficientTable } from "../../../../constants";
import BunkerSupplyMarginAllowance from "../../../../components/Calculator/BunkerSupplyMarginAllowancesTable";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function SingleVesselCalcy(props) {
  const fetchPortDistance = useRef(true);

  const { setLoader = () => { }, loader = false, setIsFullScreen = () => { }, } = props;
  const { id = "", queryid = "", vesselid = "" } = useParams();
  const location = useLocation();
  const [calculatorData, setCalculatorData] = useState({
    portOperation: [],
    fleetList: [],
    clientQuery: {},
  });
  const [selectedVessels, setSelectedVessels] = useState({});
  const [portDistanceResponse, setPortDistanceResponse] = useState({});
  const [syncBunker, setSyncBunker] = useState(false);
  const [supplyModal, setSupplyModal] = useState(false);
  const [isOverride, setIsOverride] = useState(false);
  const [allQueryFormat, setAllQueryFormat] = useState([]);
  const [openAddOperation, setOpenAddOperation] = useState(false);
  const [openAddOperationIndex, setOpenAddOperationindex] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const history = useHistory();



  const [fleetCheckbox, setFleetCheckbox] = useState({
    ballest: {
      1: true,
      2: true,
      3: true,
      4: true,
    },
    laden: {
      1: true,
      2: true,
      3: true,
      4: true,
    },
  });
  const delayUpdateAndSaveOperation = useCallback(
    _.debounce((data) => onUpdateAndSavePortDistance(data), 500),
    []
  );
  useEffect(() => {
    setIsFullScreen(true)
    // getInitalQueryAndFleetData(queryid, vesselid)
    if (id) {
      getInitialData(id)
    }
    return () => {
      setIsFullScreen(false);
    };
  }, []);
  const getInitialData = (id) => {
    setLoader(true, "Fetching data...");
    actions
      .saveResultByIdAPI(id)
      .then((res) => {
        setLoader(false);
        const {
          calculatedFleetResult = {},
          fleet,
          clientQuery,
        } = res.data || {};

        const calculatorResult = calculatedFleetResult;
        const portOperation = clientQuery?.cargoList.map((item, index) => ({
          ...item,
          index,
        }));

        setCalculatorData({
          portOperation: portOperation,
          fleetList: [calculatorResult],
          clientQuery: clientQuery,
        });
        setSelectedVessels({ ...fleet, ...calculatorResult });
        setIsInitialLoad(true)
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  useEffect(() => {
    if (isInitialLoad && new URLSearchParams(location?.search)?.get("code"))
      onClickCalculateAndSave().then(() => {
        history.replace(`${location.pathname}`);
      })

  }, [isInitialLoad])
  // const getInitalQueryAndFleetData = (queryid, vesselid) => {
  //   setLoader(true);
  //   const params = {
  //     queryid,
  //     vesselid
  //   }
  //   Promise.all([
  //     actions.getSingleVesselCalcyAPI(params),
  //   ])
  //     .then((res) => {
  //       const {
  //         calculatedFleetResult = {},
  //         fleet,
  //         clientQuery,
  //       } = res?.[0].data || {};
  //       console.log("saveResultByIdAPI", fleet, clientQuery);
  //       const calculatorResult = calculatedFleetResult;

  //       const portOperation = clientQuery?.cargoList.map((item, index) => ({
  //         ...item,
  //         index,
  //       }));

  //       setCalculatorData({
  //         portOperation: portOperation,
  //         fleetList: [calculatorResult],
  //         clientQuery: clientQuery,
  //       });
  //       setSelectedVessels({ ...fleet, ...calculatorResult });
  //       setLoader(false);
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //       toastError(err?.data?.message);
  //     });
  // };
  let splitHireArray =
    calculatorData.fleetList &&
      calculatorData.fleetList[0] &&
      calculatorData.fleetList[0].splitHire
      ? JSON.parse(calculatorData.fleetList[0].splitHire)
      : [] || [];

  const onChangeSelectedVessel = (e, isFleetEdit = false) => {
    const {
      name = "",
      value = "",
      type = "number",
      checked = false,
    } = e.target || {};
    let newUpdatedFleet = { ...selectedVessels };
    const { fuelType = "" } = calculatorData.clientQuery || {};
    let updatedvessel = { ...JSON.parse(newUpdatedFleet.vessel) };

    if (isFleetEdit) {
      newUpdatedFleet[name] = value;
    } else {
      if (type === "checkbox") {
        updatedvessel[name] = checked;
        if (name === "vesselBalComb1Selected" || name === "vesselBalComb2Selected" || name === "vesselBalComb3Selected" || name === "vesselBalComb4Selected") {
          if (!updatedvessel["vesselBalComb1Selected"] && !updatedvessel["vesselBalComb2Selected"] && !updatedvessel["vesselBalComb3Selected"] && !updatedvessel["vesselBalComb4Selected"]) {
            return
          }
        }
        else if (name === "vesselLadComb1Selected" || name === "vesselLadComb2Selected" || name === "vesselLadComb3Selected" || name === "vesselLadComb4Selected") {
          if (!updatedvessel["vesselLadComb1Selected"] && !updatedvessel["vesselLadComb2Selected"] && !updatedvessel["vesselLadComb3Selected"] && !updatedvessel["vesselLadComb4Selected"]) {
            return
          }
        }
      } else {
        updatedvessel[name] = value;
      }
    }
    const updatedFleet = {
      ...newUpdatedFleet,
      vessel: JSON.stringify(updatedvessel),
    };
    setSelectedVessels(updatedFleet);
    const updatedFleetList = calculatorData.fleetList.map((item) => {
      if (item._id === updatedFleet._id) {
        return updatedFleet;
      }
      return item;
    });
    let updatedPortOperation = [...calculatorData.portOperation];
    if (
      name === "bnkPriceDeliveryhs" ||
      name === "bnkPriceDeliveryls" ||
      name === "bnkPriceDeliverymgo"
    ) {
      const currentType = fuelType.replace("TYPE", "");
      if (name === "bnkPriceDeliveryhs")
        updatedPortOperation[0].cargoDetails[
          `fuelPriceType${currentType}Hsfo`
        ] = value;
      if (name === "bnkPriceDeliveryls")
        updatedPortOperation[0].cargoDetails[
          `fuelPriceType${currentType}Lsfo`
        ] = value;
      if (name === "bnkPriceDeliverymgo")
        updatedPortOperation[0].cargoDetails[`fuelPriceType${currentType}Mgo`] =
          value;
    }
    if (name === "bnkDelhs" || name === "bnkDells" || name === "bnkDelmgo") {
      if (updatedPortOperation.length) {
        if (name === "bnkDelhs")
          updatedPortOperation[0].cargoDetails.bunkerSuppliedHSFO = value;
        if (name === "bnkDells")
          updatedPortOperation[0].cargoDetails.bunkerSuppliedLSFO = value;
        if (name === "bnkDelmgo")
          updatedPortOperation[0].cargoDetails.bunkerSuppliedMGO = value;
      }
    }
    setCalculatorData({
      ...calculatorData,
      portOperation: updatedPortOperation,
      fleetList: updatedFleetList,
    });
  };

  const onUpdateAndSavePortDistance = ({
    updateData = {},
    saveData = {},
    updatedCalculatorData = {},
  }) => {
    setLoader(true);
    Promise.all([
      // calculationAction.updateDistance(updateData),
      calculationAction.savePortDistance(saveData),
    ])
      .then(() => {
        setLoader(false);
        sendCalculationRequest(updatedCalculatorData);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong.");
      });
  };
  const onFetchAndSetIntitalPortDistance = (propCalculator) => {
    if (!fetchPortDistance.current) return;
    let updatedCalculatorData = { ...propCalculator };
    const { getPortDistanceString, routeList } =
      convertPortOperationToDistanceString(updatedCalculatorData);
    setLoader(true);

    return new Promise((resolve, reject) => {
      const { clientQuery = {} } = updatedCalculatorData;
      const {
        eca = false,
        hra = false,
        jwc = false,
        suez = false,
        panama = false,
        singapore = false,
      } = clientQuery;
      const portDataObj = {
        eca,
        hra,
        jwc,
        suez,
        panama,
        singapore,
        routeOverride: false,
        routeList: routeList,
        portList: getPortDistanceString,
        clientQueryId: updatedCalculatorData?.clientQuery?._id,
        queryId: updatedCalculatorData?.clientQuery?._id,
        getGeometry: false,
      };
      calculationAction
        .getPortDistanceByPortList(portDataObj)
        .then((res) => {
          fetchPortDistance.current = false;
          setPortDistanceResponse(res.data);
          resolve(res);
        })
        .catch((err) => {
          setLoader(false);
          resolve({});
          toastError(err?.data?.message || "Something went wrong");
        });
    });
  };
  // const onChangePortCanalInQueryState = (portCanalChecks) => {
  //   const findIndexQuery = allQueryFormat.findIndex(
  //     (query) => query._id === selectedQuery?._id
  //   );

  //   if (findIndexQuery > -1) {
  //     const updatedAllQueryFormat = [...allQueryFormat];
  //     updatedAllQueryFormat[findIndexQuery] = {
  //       ...updatedAllQueryFormat[findIndexQuery],
  //       ...portCanalChecks,
  //     };
  //     setAllQueryFormat(updatedAllQueryFormat);
  //     // setSelectedQuery({
  //     //   ...updatedAllQueryFormat[findIndexQuery],
  //     //   ...portCanalChecks,
  //     // });
  //   }
  // };
  const onFetchPortOperationDistance = (
    reset = false,
    propCalculator = null,
    portCanalChecks = {}
  ) => {
    let updatedCalculatorData = { ...calculatorData };
    if (propCalculator) {
      updatedCalculatorData = { ...propCalculator };
    }
    const { getPortDistanceString, routeList } =
      convertPortOperationToDistanceString(updatedCalculatorData);

    const portDataObj = {
      ...portCanalChecks,
      routeList: routeList,
      portList: getPortDistanceString,
      clientQueryId: updatedCalculatorData.clientQuery._id,
      queryId: updatedCalculatorData.clientQuery._id,
      getGeometry: false,
    };
    let request = "";

    // change port selection the query as well
    // onChangePortCanalInQueryState(portCanalChecks);

    if (reset) request = calculationAction.resetDistance(portDataObj);
    else request = calculationAction.getPortDistanceByPortList(portDataObj);

    if (!loader) setLoader(true);
    request
      .then((res) => {
        setLoader(false);
        setPortDistanceResponse(res.data);
        const newPortOperations = addPortDistanceToOperations(res.data, [
          ...updatedCalculatorData.portOperation,
        ]);
        sendCalculationRequest({
          ...updatedCalculatorData,
          portOperation: newPortOperations,
        });
      })
      .catch((err) => setLoader(false));
  };
  const onChangeBunkerScrubber = (index, e) => {
    const { name = "", value = "" } = e.target;
    let newPortOperation = [...calculatorData?.portOperation];
    newPortOperation[index].cargoDetails[name] = Number(value);
    setCalculatorData({ ...calculatorData, portOperation: newPortOperation });
  };

  const onChangeSyncBunker = (checked) => {
    let updatedClientQuery = { ...calculatorData.clientQuery };

    if (!checked && updatedClientQuery.fuelCalculationMethod !== "DEFAULT")
      return toastError("Please change fuel calculation method");
    setSyncBunker(checked)
    let updatedCalculatorData = { ...calculatorData };

    let clientQuery = {
      ...updatedCalculatorData.clientQuery,
      fuelPriceOverridden: checked,
    };
    if (!checked) {
      clientQuery = {
        ...updatedCalculatorData.clientQuery,
        fuelPriceOverridden: checked,
        fuelCalculationMethod: "DEFAULT",
      };
    }

    updatedCalculatorData = {
      ...updatedCalculatorData,
      clientQuery,
    };
    setCalculatorData(updatedCalculatorData);
  };
  const sendCalculatorResponse = (modifiedData = {}) => {
    return new Promise((resolve, reject) => {
      if (modifiedData.fleetList.length === 0) return resolve({});
      const updatedCargoList = modifiedData?.cargoList?.map((cargoDetails) => {
        const { _id, ...restCargoMainDetails } = cargoDetails;
        const { _id: cargoDetailId, ...restCargoDetail } =
          cargoDetails?.cargoDetails;
        return {
          ...restCargoMainDetails,
          cargoDetails: { ...restCargoDetail },
        };
      });

      const {
        _id,
        createdUser,
        lastModifiedUser,
        userId,
        calculationFormat,
        __v,
        createdAt,
        updatedAt,
        isCalculate,
        ...restClientQuery
      } = modifiedData.clientQuery;
      const updatedClientQuery = {
        ...restClientQuery,
        cargoList: modifiedData?.cargoList,
      };

      const updatedFleetListData =
        modifiedData?.fleetList?.length > 0
          ? modifiedData.fleetList.map((fleetDetails, index) => {
            const vesselDetails = !!fleetDetails?.vessel
              ? JSON.parse(fleetDetails.vessel)
              : {};
            const {
              _id: parseVesselId,
              userId: parseVesselUserId,
              createdUser: parseVesselCreatedUser,
              lastModifiedUser: parseVesselModifiedUser,
              vesselExtraDetails,
              __v: parseVessel__v,
              createdAt,
              createdDate,
              fuelType,
              lastModifiedDate,
              pattern,
              updatedAt,

              vesselLabel,
              vesselAHL,
              vesselCO2,

              vesselCountry,
              vesselGrabs,
              vesselIceClass,
              vesselIMO,
              vesselLdnConsmMGO1,
              vesselLdnConsmMGO2,
              vesselLightShip,
              vesselMMSI,
              vesselType,
              vesselSource,
              vesselName,
              ...parseVesselDetails
            } = vesselDetails;
            const {
              _id,
              vesselId,
              createdUser,
              organizationId,
              userId,
              lastModifiedUser,
              vesselUniqueId,
              __v,
              ...rest
            } = fleetDetails;
            const uniqueId = vesselUniqueId;

            return {
              ...rest,
              vessel: JSON.stringify({
                id: uniqueId,
                ...parseVesselDetails,
                system: true,
              }),
              vesselId: uniqueId,
            };
          })
          : [];
      const updatedCargoExtraDetails = modifiedData.cargoExtraDetails.map(
        (cargoextraDetails) => {
          const { _id, ...restCargoMainDetails } = cargoextraDetails;
          const { _id: cargoDetailId, ...restCargoDetail } =
            cargoextraDetails.cargoDetails;
          return {
            ...restCargoMainDetails,
            cargoDetails: { ...restCargoDetail },
          };
        }
      );

      const calculationData = {
        autoSave: false,
        cargoExtraDetails: updatedCargoExtraDetails,
        cargoList: modifiedData?.cargoList,
        clientQuery: updatedClientQuery,
        fleetList: updatedFleetListData,
      };

      calculationAction
        .sendCalculatorData(calculationData)
        // calculationAction
        //   .updateCalculatedCostDetails(id,calculationData)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          resolve({});
          toastError(err?.data?.message || "Something went wrong");
        });
    });
  };
  const onChangeFleetRowSelect = async (
    fleet = {},
    resultCalculatorData = null,
    changeVessel = false,
    isPortDistanceChanged = false
  ) => {
    let updatedCalculatorData = {};
    if (resultCalculatorData) updatedCalculatorData = resultCalculatorData;
    else updatedCalculatorData = { ...calculatorData };
    let commencePort = (fleet && fleet.port) || "";
    let fleetFuelType =
      (fleet && fleet.vessel && JSON.parse(fleet.vessel)) || {};
    fleetFuelType = fleetFuelType.fuelType || "TYPE0";
    let portOperation = [...updatedCalculatorData.portOperation];

    const portToPortOperationResultMap =
      (fleet && fleet.portToPortOperationResultMap) || [];
    portOperation = portOperation.map((operation, index) => {
      const selectedResultMap = portToPortOperationResultMap[index] || {};
      const removeDistanceFromResultMap = ({
        distanceECA = "",
        distanceNormal = "",
        ...rest
      }) => {
        return { ...rest };
      };
      if (index !== 0) commencePort = (operation && operation.port) || "";
      let isTermExists = termsCoEfficientTable[0];
      if (operation.terms) {
        isTermExists = termsCoEfficientTable.find(
          (item) => item.label === operation.terms
        );
      }
      let cargoDetails = { ...operation.cargoDetails };

      if (index === 0) {
        const currentType = fleetFuelType.replace("TYPE", "");
        const {
          bnkDelhs = 0,
          bnkDells = 0,
          bnkDelmgo = 0,
          bnkPriceDeliveryhs = 0,
          bnkPriceDeliveryls = 0,
          bnkPriceDeliverymgo = 0,
        } = fleet;
        cargoDetails = {
          ...cargoDetails,
          bunkerSuppliedHSFO: bnkDelhs,
          bunkerSuppliedLSFO: bnkDells,
          bunkerSuppliedMGO: bnkDelmgo,
          [`fuelPriceType${currentType}Hsfo`]: bnkPriceDeliveryhs,
          [`fuelPriceType${currentType}Lsfo`]: bnkPriceDeliveryls,
          [`fuelPriceType${currentType}Mgo`]: bnkPriceDeliverymgo,
        };
      }
      return {
        ...operation,
        ...removeDistanceFromResultMap(selectedResultMap),
        cargoDetails,
        port: commencePort,
        terms: isTermExists.label,
        factor: isTermExists.value,
      };
    });

    if (!isPortDistanceChanged) {
      let portDistanceRes = portDistanceResponse;
      if (fetchPortDistance.current) {
        portDistanceRes = await onFetchAndSetIntitalPortDistance(
          updatedCalculatorData
        );
      }
      portOperation = addPortDistanceToOperations(portDistanceRes, [
        ...portOperation,
      ]);
    }
    // add post distance to selected fleet as well
    fleet = addPortDistanceToSelectedFleet(portOperation, fleet);

    if (changeVessel || !selectedVessels._id) {
      setSelectedVessels(fleet);
    }

    setCalculatorData({
      ...updatedCalculatorData,
      clientQuery: {
        ...updatedCalculatorData.clientQuery,
        fuelType: fleetFuelType,
      },
      fleetList: updatedCalculatorData.fleetList.map((singleFleet) => {
        if (singleFleet._id === fleet._id) return fleet;
        else return singleFleet;
      }),
      portOperation,
    });
  };
  const onClickCalculateAndSave = async () => {
    await sendCalculationRequest({ ...calculatorData, autoSave: true });
  };
  const sendCalculationRequest = async (sendData) => {
    let portDistanceRes = portDistanceResponse;
    if (fetchPortDistance.current) {
      portDistanceRes = await onFetchAndSetIntitalPortDistance(sendData);
    }
    sendData = {
      ...sendData,
      portOperation: addPortDistanceToOperations(portDistanceRes, [
        ...sendData.portOperation,
      ]),
    };

    let fleetSelected = {};
    const updatedFleedIds = sendData.fleetList.map((fleetDetails) => ({
      ...fleetDetails,
      vesselUniqueId: uuidv4(),
    }));

    let modifiedData = {
      cargoList: sendData.portOperation.filter((item) => !item.isExtra),
      cargoExtraDetails: sendData.portOperation.filter(
        (item) => !!item.isExtra
      ),
      fleetList: cloneDeep(updatedFleedIds),
      clientQuery: {
        ...sendData.clientQuery,
        calculationFormat: sendData.portOperation
          .map((item) => item._id)
          .join(","),
      },
      autoSave: false,
    };
    await calculationValidation(modifiedData, selectedVessels)
    if (!loader) setLoader(true);
    const res = await sendCalculatorResponse(modifiedData);
    setLoader(false);
    const fleetData = fleetWithCalculatorDataMapping(
      cloneDeep(updatedFleedIds),
      res?.data
    );


    if (selectedVessels && selectedVessels._id)
      fleetSelected =
        fleetData.find((item) => item._id === selectedVessels._id) || {};
    else fleetSelected = fleetData[0];
    onChangeFleetRowSelect(
      fleetSelected,
      { ...sendData, fleetList: fleetData },
      true,
      true
    );

    // const {
    //   __v,
    //   version,
    //   system,
    //   createdUser,
    //   vesselId,
    //   vesselName,
    //   userId,
    //   lastModifiedUser,
    //   lastModifiedDate,
    //   ...restClientQuery
    // } = calculatorData;

    const newUpdatedQuery = {
      ...sendData,
      cargoList: sendData.portOperation,
      clientQuery: { ...sendData.clientQuery, cargoList: sendData.portOperation },
      createdUser: calculatorData?.createdUser?._id,
      fleet: fleetData[0],
      vessel: fleetData[0],
      calculatedFleetResult: fleetSelected
    };

    // const newUpdatedFleet = { ...fleetSelected };
    if (sendData.autoSave) {
      saveAllResultUpdatedData({
        // newUpdatedFleet,
        newUpdatedQuery,
      });
    }
  };
  const saveAllResultUpdatedData = ({ newUpdatedQuery }) => {
    Promise.all([
      calculationAction.updateCalculatedCostDetails(id, newUpdatedQuery),
      // calculationAction.updateFleetData(newUpdatedFleet),
    ])
      .then((res) => {
        if (res?.[0]?.success && res?.[1]?.success) {
          toastSuccess("Calculation updated and saved successfully!");
        }
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  };
  const onDeleteOperations = (index, cid, opIndex) => {
    setLoader(true);
    calculationAction
      .deleteCargoExtraDetailForSingleVessel({ calculatedCostId: id, cargoId: opIndex })
      .then((res) => {
        setLoader(false);
        let portOperation = [...calculatorData.portOperation];
        portOperation.splice(index, 1);
        const updatedSelectedQuery = {
          ...calculatorData?.clientQuery,
          calculationFormat: res.data.calculationFormat,
        };

        setCalculatorData({
          ...calculatorData,
          portOperation,
          clientQuery: updatedSelectedQuery?.clientQuery,
        });
      })
      .catch((err) => setLoader(false));
  };

  const onAddOperations = (requiredData = {}) => {
    setLoader(true);
    fetchPortDistance.current = true;
    calculationAction
      .addCargoExtraDetailsForSingleVessel({
        details: {
          ...initialCreatePortOperation,
          ...requiredData,
        },
        calculatedCostId: id,
        addIndex: openAddOperationIndex,
      })
      .then((response) => {
        const updatedSelectedQuery = {
          ...calculatorData,
          calculationFormat: response.data?.clientQuery?.calculationFormat,
        };
        setLoader(false);

        const updatedCalculatorData = {
          ...calculatorData,
          clientQuery: updatedSelectedQuery?.clientQuery,
          portOperation: response?.data?.clientQuery?.cargoList,
        };

        sendCalculationRequest(updatedCalculatorData);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const onChangePortOperations = (index, e, cargoUpdate = false) => {
    let { value = "", name = "" } = e.target;
    let portOperation = [...calculatorData.portOperation];

    if (name === "terms") {
      const termFactor = termsCoEfficientTable.find(
        (item) => item.label === value
      );
      portOperation[index]["terms"] = value;
      portOperation[index]["factor"] = (termFactor && termFactor.value) || 0;
    } else if (cargoUpdate) {
      portOperation[index].cargoDetails[name] = value;
    } else {
      portOperation[index][name] = value;
    }

    let updatedCalculatorData = { ...calculatorData, portOperation };
    if (name === "distanceNormal" || name === "distanceECA") {
      const updateData = {
        distEca: portOperation[index].distanceECA,
        distNormal: portOperation[index].distanceNormal,
        id: portOperation[index]._id,
      };
      const saveData = {
        distEca: portOperation[index].distanceECA,
        distNormal: portOperation[index].distanceNormal,
        openingPort:
          (portOperation[index - 1] && portOperation[index - 1]["port"]) || "",
        endingPort: portOperation[index]["port"] || "",
      };
      delayUpdateAndSaveOperation({
        updateData,
        saveData,
        updatedCalculatorData,
      });
    }
    setCalculatorData(updatedCalculatorData);
  };
  const calculationErrors = useMemo(() => {
    let errors = [];
    if (
      selectedVessels &&
      calculatorData.portOperation.length &&
      calculatorData.fleetList.length
    ) {
      errors = calculationValidation(calculatorData, selectedVessels);
    }
    return errors;
  }, [calculatorData, selectedVessels]);
  const onClickCalculate = () => {
    sendCalculationRequest({ ...calculatorData, autoSave: false });
  };

  const onSetOpenMap = () => {
    if (id) {
      window.open(`/map/single${id}`);
    }
  };
  return (
    <Grid container className="calculator-section">
      <Grid item xs={12}>
        <div className="quick-menu-bar">
          <ActionButtons
            isFromSingleVesselCalcy={true}
            // hideOrder={hideOrder}
            // setHideOrder={setHideOrder}
            onSetOpenMap={onSetOpenMap}
            onClickCalculate={onClickCalculate}
            // onSetHideCalculator={onSetHideCalculator}
            onClickCalculateAndSave={onClickCalculateAndSave}
          />
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={2}>
            <div className="upper-section-grid">
              <AccountCargoDetails
                isFromSingleVesselCalcy={true}
                calculatorData={calculatorData}
                setLoader={setLoader}
                getInitialData={getInitialData}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={4}>
            <div className="upper-section-grid">
              <CalculatorVesselDetails

                setLoader={setLoader}
                setSelectedVessels={setSelectedVessels}
                selectedVessels={selectedVessels}
                calculatorData={calculatorData}
                setCalculatorData={setCalculatorData}
                onChangeHandler={onChangeSelectedVessel}
                fleetCheckbox={fleetCheckbox}
                setFleetCheckbox={setFleetCheckbox}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <div className="upper-section-grid">
              <MarginAndAllowances
                isFromSingleVesselCalcy={true}
                onChangeSelectedVessel={onChangeSelectedVessel}
                selectedVessels={selectedVessels}
                setSelectedVessels={setSelectedVessels}
                // setMarginModal={setMarginModal}
                calculatorData={calculatorData}
                setCalculatorData={setCalculatorData}
                syncBunker={syncBunker}
                setSyncBunker={setSyncBunker}
                setSupplyModal={setSupplyModal}
                onChangeSyncBunker={onChangeSyncBunker}
                isOverride={isOverride}
                setIsOverride={setIsOverride}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <div className="upper-section-grid">
              <Expenses
                isFromSingleVesselCalcy={true}
                onChangeSelectedVessel={onChangeSelectedVessel}
                selectedVessels={selectedVessels}
                // openSplitHireModal={openSplitHireModal}
                calculatorData={calculatorData}
                setCalculatorData={setCalculatorData}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <div className="upper-section-grid">
              <Result

                selectedVessels={selectedVessels}
                calculationErrors={calculationErrors}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="middle-section-grid">
              <PortOperations

                // marginModal={marginModal}
                // setMarginModal={setMarginModal}
                sendCalculationRequest={sendCalculationRequest}
                onChangePortOperations={onChangePortOperations}
                calculatorData={calculatorData}
                setCalculatorData={setCalculatorData}
                // syncBunker={syncBunker}
                // setSyncBunker={setSyncBunker}
                onAddOperations={onAddOperations}
                onDeleteOperations={onDeleteOperations}
                // selectedVessels={selectedVessels}
                // supplyModal={supplyModal}
                // setSupplyModal={setSupplyModal}
                onChangeSyncBunker={onChangeSyncBunker}
                onFetchPortOperationDistance={onFetchPortOperationDistance}
                fetchPortDistance={fetchPortDistance}
                openAddOperation={openAddOperation}
                setOpenAddOperation={setOpenAddOperation}
                setOpenAddOperationindex={setOpenAddOperationindex}
              // onChangeSelectedVessel={onChangeSelectedVessel}
              // isOverride={isOverride}
              // setIsOverride={onChangeOverride}
              />
            </div>
          </Grid>
          {/* <Grid item xs={6}>
            <div className="lower-section-grid">
              <MarginAllowancesTable
                disableEdit
                calculatorData={calculatorData}
                setCalculatorData={setCalculatorData}
                syncBunker={syncBunker}
                setSyncBunker={setSyncBunker}
                setSupplyModal={setSupplyModal}
                onChangeSyncBunker={onChangeSyncBunker}
                isOverride={isOverride}
              />
            </div>
          </Grid> */}


          <Grid item xs={12}>
            <div className="lower-section-grid">
              <BunkerSupplyMarginAllowance
                // disableEdit
                portOperation={calculatorData.portOperation}
                syncBunker={syncBunker}
                setSyncBunker={setSyncBunker}
                clientQuery={selectedVessels?.clientQuery}
                onChangeBunkerScrubber={onChangeBunkerScrubber}
                selectedVessels={selectedVessels}
                onChangeSyncBunker={onChangeSyncBunker}
                onChangeSelectedVessel={onChangeSelectedVessel}
                onChangePortOperations={onChangePortOperations}
                isOverride={isOverride}
                setIsOverride={setIsOverride}
                calculatorData={calculatorData}
                setCalculatorData={setCalculatorData}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="lower-section-grid">
              <div className="selected-vessel-remark">
                <p>
                  Remarks:
                  <span>
                    {(selectedVessels && selectedVessels.remark) || ""}
                  </span>
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="lower-section-grid">
              <Paper>
                <h2>Split hire</h2>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Hire Rate</TableCell>
                      <TableCell>No. of Days</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(splitHireArray) &&
                      splitHireArray.map((item) => {
                        return (
                          <TableRow>
                            <TableCell>{item.hireRate}</TableCell>
                            <TableCell>{item.NoDays}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Paper>
              {/* <div className="detail-map-view">
                <p>Space For Map</p>
              </div> */}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
