import React, { memo, useState } from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import {
  fuelPriceOptions,
  initialVesselState,
  scrubberOptions,
  vesselCalculatorMainFields,
} from "../../constants";
import { useHistory, Link } from "react-router-dom";
import CustomAddModal from "../Common/CustomAddModal";
import AddNewVessel from "../Dashboard/AddNewVessel";
import * as actions from "../../containers/User/Dashboard/actions";
import { toastError, toastSuccess } from "../../utils";
import { MdAdd } from "react-icons/md";
import { handleKeyDown } from "../../common/common";
import errorIcon from '../../assets/images/info-circle-line-icon.svg'

function CalculatorVesselDetails(props) {
  const history = useHistory();
  const {
    setLoader = () => { },
    selectedVessels = {},
    setSelectedVessels = () => { },
    calculatorData = {},
    setCalculatorData = () => { },
    onChangeHandler = () => { },
    fleetCheckbox = () => { },
    setFleetCheckbox = () => { },
    disableEdit = false,
  } = props || {};

  const [newVessel, setNewVessel] = useState({ ...initialVesselState });
  const [open, setOpen] = useState(false);

  const vessel =
    (selectedVessels &&
      selectedVessels.vessel &&
      JSON.parse(selectedVessels.vessel)) ||
    {};

  const {
    vesselBalConsmHS1 = 0,
    vesselBalConsmHS2 = 0,
    vesselBalConsmHS3 = 0,
    vesselBalConsmHS4 = 0,
    vesselBalConsmLS1 = 0,
    vesselBalConsmLS2 = 0,
    vesselBalConsmLS3 = 0,
    vesselBalConsmLS4 = 0,
    vesselBalConsmMGO1 = 0,
    vesselBalConsmMGO2 = 0,
    vesselBalConsmMGO3 = 0,
    vesselBalConsmMGO4 = 0,
    vesselBalSpd1 = 0,
    vesselBalSpd2 = 0,
    vesselBalSpd3 = 0,
    vesselBalSpd4 = 0,
    vesselDwt = 0,
    vesselName = "",
    vesselGrainCap = 0,
    vesselDraftS = 0,
    vesselLdnConsmHS1 = 0,
    vesselLdnConsmHS2 = 0,
    vesselLdnConsmHS3 = 0,
    vesselLdnConsmHS4 = 0,
    vesselLdnConsmLS1 = 0,
    vesselLdnConsmLS2 = 0,
    vesselLdnConsmLS3 = 0,
    vesselLdnConsmLS4 = 0,
    vesselLdnConsmMGO1 = 0,
    vesselLdnConsmMGO2 = 0,
    vesselLdnConsmMGO3 = 0,
    vesselLdnConsmMGO4 = 0,
    vesselLdnSpd1 = 0,
    vesselLdnSpd2 = 0,
    vesselLdnSpd3 = 0,
    vesselLdnSpd4 = 0,
    vesselPDischConsmHS = 0,
    vesselPDischConsmLS = 0,
    vesselPDischConsmMGO = 0,
    vesselPIdleConsmHS = 0,
    vesselPIdleConsmLS = 0,
    vesselPIdleConsmMGO = 0,
    vesselPLdgConsmHS = 0,
    vesselPLdgConsmLS = 0,
    vesselPLdgConsmMGO = 0,
    vesselVExtraConsmHS = 0,
    vesselVExtraConsmLS = 0,
    vesselVExtraConsmMGO = 0,
    vesselIMO = "",
    fuelType = "",
    vesselBalComb1Selected,
    vesselBalComb2Selected,
    vesselBalComb3Selected,
    vesselBalComb4Selected,
    vesselLadComb1Selected,
    vesselLadComb2Selected,
    vesselLadComb3Selected,
    vesselLadComb4Selected,
    vesselScrubber,
  } = vessel || {};

  const { clientQuery = {} } = calculatorData || {};

  const onChangeVesselScrubber = (e) => {
    const { name = "", value = "" } = e.target;
    let newVessel = { ...vessel };
    newVessel[name] = value;
    const updatedFleet = {
      ...selectedVessels,
      vessel: JSON.stringify(newVessel),
    };
    setSelectedVessels(updatedFleet);
    let updatedCalculatorData = { ...calculatorData };

    let newCargoDetails = {};
    if (name === "fuelType") {
      const currentType = value.replace("TYPE", "");
      newCargoDetails[`fuelPriceType${currentType}Hsfo`] = Number(
        clientQuery[`hsFuelPriceType${currentType}`]
      );
      newCargoDetails[`fuelPriceType${currentType}Lsfo`] = Number(
        clientQuery[`lsFuelPriceType${currentType}`]
      );
      newCargoDetails[`fuelPriceType${currentType}Mgo`] = Number(
        clientQuery[`mgoFuelPriceType${currentType}`]
      );
    }

    updatedCalculatorData = {
      ...calculatorData,
      clientQuery: {
        ...clientQuery,
        ...newCargoDetails,
        [name]: value,
      },
      fleetList: updatedCalculatorData.fleetList.map((item) => {
        if (item._id === updatedFleet._id) {
          return updatedFleet;
        }
        return item;
      }),
    };
    setCalculatorData(updatedCalculatorData);
  };

  // const onChangeFleetCheckBox = (type, e) => {
  //     const { name = '', checked = '' } = e.target || {}

  //     setFleetCheckbox({
  //         ...fleetCheckbox, [type]: {
  //             ...fleetCheckbox[type],
  //             [name]: checked
  //         }
  //     })
  // }

  const onSubmitConfirm = () => {
    if (!newVessel.vesselName) return toastError("Vessel Name is required");
    setLoader(true);
    actions
      .createVessel(newVessel)
      .then((res) => {
        onCloseModal();
        setLoader(false);
        toastSuccess("Vessel Created Successfully");
        setNewVessel({ ...initialVesselState });
      })
      .catch((err) => {
        setLoader(false);
        toastError(err.error);
        setOpen(true);
      });
  };

  const onChangeHandlerForNew = (e) => {
    // let { name = '', value = '' } = e.target
    // if (value && (value.toString().split('')[0] === 0)) return
    let {
      name = "",
      value = "",
      type = "checkbox",
      checked = false,
    } = e.target;
    let updatedVessel = { ...newVessel };
    if (type === "checkbox") {
      updatedVessel[name] = checked;
    } else {
      if (value && value.toString().split("")[0] === 0) return;
      updatedVessel[name] = value;
    }
    setNewVessel(updatedVessel);
  };

  const onCloseModal = () => {
    setOpen(false);
    setNewVessel({ ...initialVesselState });
  };
  const checkBallastError = (vesselBal1, vesselBal2, vesselBal3, vesselBal4) => {
    let vesselBalCheck = []
    if (vesselBalComb1Selected) {
      vesselBalCheck.push(Number(vesselBal1) ? true : false)
    }
    if (vesselBalComb2Selected) {
      vesselBalCheck.push(Number(vesselBal2) ? true : false)
    }
    if (vesselBalComb3Selected) {
      vesselBalCheck.push(Number(vesselBal3) ? true : false)
    }
    if (vesselBalComb4Selected) {
      vesselBalCheck.push(Number(vesselBal4) ? true : false)
    }
    if (vesselBalCheck.every(value => value === false)) {
      return true
    }
  }
  const checkLadenError = (vesselLdn1, vesselLdn2, vesselLdn3, vesselLdn4) => {
    let vesselLdnSpdCheck = []
    if (vesselLadComb1Selected) {
      vesselLdnSpdCheck.push(Number(vesselLdn1) ? true : false)
    }
    if (vesselLadComb2Selected) {
      vesselLdnSpdCheck.push(Number(vesselLdn2) ? true : false)
    }
    if (vesselLadComb3Selected) {
      vesselLdnSpdCheck.push(Number(vesselLdn3) ? true : false)
    }
    if (vesselLadComb4Selected) {
      vesselLdnSpdCheck.push(Number(vesselLdn4) ? true : false)
    }
    if (vesselLdnSpdCheck.every(value => value === false)) {
      return true
    }
  }


  const disableSelection = clientQuery.fuelCalculationMethod !== "DEFAULT";

  return (
    <Grid container>
      <Grid item xs={12}>
        <div>
          <Grid container>
            <Grid item xs={12} className="calculator-section-header">
              <div
                className="items-center"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: 8,
                }}
              >
                <h4 className="result-heading cursor-pointer underline">
                  <Link to={`/vessel/${vessel._id}`} target="_blank">
                    Vessel Details
                  </Link>
                </h4>
                {disableEdit ? null : (
                  <div>
                    <button
                      color="primary"
                      className="py-1 px-2.5 rounded-md bg-neutrals6 text-black mt-[2px]"
                      onClick={() => setOpen(true)}
                    >
                      <MdAdd />
                    </button>
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <div className="vessel-name-div">
                    <span>Vessel</span>
                    <span>
                      <input
                        disabled
                        value={vesselName}
                        name="vesselName"
                        onChange={onChangeHandler}
                      />
                    </span>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="vessel-name-div">
                    <span>Dwt</span>
                    <span>
                      <input
                        disabled
                        value={vesselDwt}
                        name="vesselDwt"
                        onChange={onChangeHandler}
                      />
                    </span>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="vessel-name-div">
                    <span>IMO</span>
                    <span>
                      <input
                        disabled
                        value={vesselIMO}
                        name="vesselIMO"
                        onChange={onChangeHandler}
                      />
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={5}>
              <table className="fleet-result-table">
                {vesselCalculatorMainFields.map((item, key) => {
                  if (item.name === "fuelType") {
                    return (
                      <tr key={key}>
                        <th>{item.label}</th>
                        <select
                          value={fuelType || "Type-1"}
                          name="fuelType"
                          className="vessel-scrubber-select"
                          onChange={onChangeVesselScrubber}
                          disabled={disableSelection}
                        >
                          {fuelPriceOptions.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </tr>
                    );
                  }
                  if (item.name === "vesselScrubber") {
                    return (
                      <tr key={key}>
                        <th>{item.label}</th>
                        <select
                          value={vesselScrubber || "Yes"}
                          name="vesselScrubber"
                          className="vessel-scrubber-select"
                          onChange={onChangeVesselScrubber}
                        >
                          {scrubberOptions.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </tr>
                    );
                  }
                  return (
                    <tr key={key}>
                      <th>{item.label}</th>
                      <td>
                        <input
                          value={vessel[item.name] || ""}
                          type={item.type}
                          onKeyDown={handleKeyDown}
                          name={item.name}
                          onChange={onChangeHandler}
                        />
                      </td>
                    </tr>
                  );
                })}
              </table>
            </Grid>
            <Grid item xs={7}>
              <Grid container>
                <Grid item xs={12}>
                  <p className="m-b-5">Ballast Sea Consumptions</p>
                  <div className="consumptions-headers">
                    <div style={{ width: 25 }}></div>
                    <p>Speed*</p>
                    <p>HS</p>
                    <p>LS</p>
                    <p>MGO</p>
                  </div>
                  <div className="consumptions-content">
                    <input
                      type="checkbox"
                      className="input-type-checkbox"
                      name="vesselBalComb1Selected"
                      checked={vesselBalComb1Selected || false}
                      onChange={onChangeHandler}
                    />
                    <div className={`custom-input-outer ${vesselBalComb1Selected && checkBallastError(vesselBalSpd1, vesselBalSpd2, vesselBalSpd3, vesselBalSpd4) && "req-error"}`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselBalSpd1"
                        value={vesselBalSpd1 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Ballast Speed-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselBalComb1Selected && checkBallastError(vesselBalConsmHS1, vesselBalConsmHS2, vesselBalConsmHS3, vesselBalConsmHS4)) && "req-error"}`}>
                      <input
                        type="number"
                        placeholder="0"
                        onKeyDown={handleKeyDown}
                        name="vesselBalConsmHS1"
                        value={vesselBalConsmHS1 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Ballast HS-Consumption should be non-zero.</p>
                      </div>

                    </div>
                    <div className={`custom-input-outer ${(vesselBalComb1Selected && checkBallastError(vesselBalConsmLS1, vesselBalConsmLS2, vesselBalConsmLS3, vesselBalConsmLS4)) && "req-error"}`}>
                      <input
                        type="number"
                        placeholder="0"
                        onKeyDown={handleKeyDown}
                        name="vesselBalConsmLS1"
                        value={vesselBalConsmLS1 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Ballast LS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer`}>
                      <input
                        type="number"
                        placeholder="0"
                        onKeyDown={handleKeyDown}
                        name="vesselBalConsmMGO1"
                        value={vesselBalConsmMGO1 || ""}
                        onChange={onChangeHandler}
                      />
                    </div>
                  </div>
                  <div className="consumptions-content">
                    <input
                      type="checkbox"
                      className="input-type-checkbox"
                      name="vesselBalComb2Selected"
                      checked={vesselBalComb2Selected || false}
                      onChange={onChangeHandler}
                    />
                    <div className={`custom-input-outer ${(vesselBalComb2Selected && checkBallastError(vesselBalSpd1, vesselBalSpd2, vesselBalSpd3, vesselBalSpd4)) && "req-error"}`}>
                      <input
                        type="number"
                        placeholder="0"
                        onKeyDown={handleKeyDown}
                        name="vesselBalSpd2"
                        value={vesselBalSpd2 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Ballast Speed-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselBalComb2Selected && checkBallastError(vesselBalConsmHS1, vesselBalConsmHS2, vesselBalConsmHS3, vesselBalConsmHS4)) && "req-error"}`}>
                      <input
                        type="number"
                        placeholder="0"
                        onKeyDown={handleKeyDown}
                        name="vesselBalConsmHS2"
                        value={vesselBalConsmHS2 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Ballast HS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselBalComb2Selected && checkBallastError(vesselBalConsmLS1, vesselBalConsmLS2, vesselBalConsmLS3, vesselBalConsmLS4)) && "req-error"}`}>
                      <input
                        type="number"
                        placeholder="0"
                        onKeyDown={handleKeyDown}
                        name="vesselBalConsmLS2"
                        value={vesselBalConsmLS2 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Ballast LS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer`}>
                      <input
                        type="number"
                        placeholder="0"
                        onKeyDown={handleKeyDown}
                        name="vesselBalConsmMGO2"
                        value={vesselBalConsmMGO2 || ""}
                        onChange={onChangeHandler}
                      /></div>
                  </div>
                  <div className="consumptions-content">
                    <input
                      type="checkbox"
                      className="input-type-checkbox"
                      name="vesselBalComb3Selected"
                      checked={vesselBalComb3Selected || false}
                      onChange={onChangeHandler}
                    />
                    <div className={`custom-input-outer ${(vesselBalComb3Selected && checkBallastError(vesselBalSpd1, vesselBalSpd2, vesselBalSpd3, vesselBalSpd4)) && "req-error"}`}>
                      <input
                        type="number"
                        placeholder="0"
                        onKeyDown={handleKeyDown}
                        name="vesselBalSpd3"
                        value={vesselBalSpd3 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Ballast Speed-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselBalComb3Selected && checkBallastError(vesselBalConsmHS1, vesselBalConsmHS2, vesselBalConsmHS3, vesselBalConsmHS4)) && "req-error"}`}>
                      <input
                        type="number"
                        placeholder="0"
                        onKeyDown={handleKeyDown}
                        name="vesselBalConsmHS3"
                        value={vesselBalConsmHS3 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Ballast HS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselBalComb3Selected && checkBallastError(vesselBalConsmLS1, vesselBalConsmLS2, vesselBalConsmLS3, vesselBalConsmLS4)) && "req-error"}`}>
                      <input
                        type="number"
                        placeholder="0"
                        onKeyDown={handleKeyDown}
                        name="vesselBalConsmLS3"
                        value={vesselBalConsmLS3 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Ballast LS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer`}>
                      <input
                        type="number"
                        placeholder="0"
                        onKeyDown={handleKeyDown}
                        name="vesselBalConsmMGO3"
                        value={vesselBalConsmMGO3 || ""}
                        onChange={onChangeHandler}
                      />
                    </div>
                  </div>
                  <div className="consumptions-content">
                    <input
                      type="checkbox"
                      className="input-type-checkbox"
                      name="vesselBalComb4Selected"
                      checked={vesselBalComb4Selected || false}
                      onChange={onChangeHandler}
                    />
                    <div className={`custom-input-outer ${(vesselBalComb4Selected && checkBallastError(vesselBalSpd1, vesselBalSpd2, vesselBalSpd3, vesselBalSpd4)) && "req-error"}`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselBalSpd4"
                        value={vesselBalSpd4 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Ballast Speed-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselBalComb4Selected && checkBallastError(vesselBalConsmHS1, vesselBalConsmHS2, vesselBalConsmHS3, vesselBalConsmHS4)) && "req-error"}`}>

                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselBalConsmHS4"
                        value={vesselBalConsmHS4 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Ballast HS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselBalComb4Selected && checkBallastError(vesselBalConsmLS1, vesselBalConsmLS2, vesselBalConsmLS3, vesselBalConsmLS4)) && "req-error"}`}>

                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselBalConsmLS4"
                        value={vesselBalConsmLS4 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Ballast LS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer`}>

                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselBalConsmMGO4"
                        value={vesselBalConsmMGO4 || ""}
                        onChange={onChangeHandler}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <p className="m-t-5 m-b-5">Laden Sea Consumptions</p>
                  <div className="consumptions-headers">
                    <div style={{ width: 25 }}></div>
                    <p>Speed*</p>
                    <p>HS</p>
                    <p>LS</p>
                    <p>MGO</p>
                  </div>
                  <div className="consumptions-content">
                    <input
                      type="checkbox"
                      className="input-type-checkbox"
                      name="vesselLadComb1Selected"
                      checked={vesselLadComb1Selected || false}
                      onChange={onChangeHandler}
                    />
                    <div className={`custom-input-outer ${(vesselLadComb1Selected && checkLadenError(vesselLdnSpd1, vesselLdnSpd2, vesselLdnSpd3, vesselLdnSpd4)) && "req-error"}`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnSpd1"
                        value={vesselLdnSpd1 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Laden Speed-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselLadComb1Selected && checkLadenError(vesselLdnConsmHS1, vesselLdnConsmHS2, vesselLdnConsmHS3, vesselLdnConsmHS4)) && "req-error"}`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnConsmHS1"
                        value={vesselLdnConsmHS1 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Laden HS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselLadComb1Selected && checkLadenError(vesselLdnConsmLS1, vesselLdnConsmLS2, vesselLdnConsmLS3, vesselLdnConsmLS4)) && "req-error"}`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnConsmLS1"
                        value={vesselLdnConsmLS1 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Laden LS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnConsmMGO1"
                        value={vesselLdnConsmMGO1 || ""}
                        onChange={onChangeHandler}
                      /></div>
                  </div>
                  <div className="consumptions-content">
                    <input
                      type="checkbox"
                      className="input-type-checkbox"
                      name="vesselLadComb2Selected"
                      checked={vesselLadComb2Selected || false}
                      onChange={onChangeHandler}
                    />
                    <div className={`custom-input-outer ${(vesselLadComb2Selected && checkLadenError(vesselLdnSpd1, vesselLdnSpd2, vesselLdnSpd3, vesselLdnSpd4)) && "req-error"}`}>

                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnSpd2"
                        value={vesselLdnSpd2 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Laden Speed-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselLadComb2Selected && checkLadenError(vesselLdnConsmHS1, vesselLdnConsmHS2, vesselLdnConsmHS3, vesselLdnConsmHS4)) && "req-error"}`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnConsmHS2"
                        value={vesselLdnConsmHS2 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Laden HS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselLadComb2Selected && checkLadenError(vesselLdnConsmLS1, vesselLdnConsmLS2, vesselLdnConsmLS3, vesselLdnConsmLS4)) && "req-error"}`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnConsmLS2"
                        value={vesselLdnConsmLS2 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Laden LS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnConsmMGO2"
                        value={vesselLdnConsmMGO2 || ""}
                        onChange={onChangeHandler}
                      /></div>
                  </div>
                  <div className="consumptions-content">
                    <input
                      type="checkbox"
                      className="input-type-checkbox"
                      name="vesselLadComb3Selected"
                      checked={vesselLadComb3Selected || false}
                      onChange={onChangeHandler}
                    />
                    <div className={`custom-input-outer ${(vesselLadComb3Selected && checkLadenError(vesselLdnSpd1, vesselLdnSpd2, vesselLdnSpd3, vesselLdnSpd4)) && "req-error"}`}>

                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnSpd3"
                        value={vesselLdnSpd3 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Laden Speed-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselLadComb3Selected && checkLadenError(vesselLdnConsmHS1, vesselLdnConsmHS2, vesselLdnConsmHS3, vesselLdnConsmHS4)) && "req-error"}`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnConsmHS3"
                        value={vesselLdnConsmHS3 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Laden HS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselLadComb3Selected && checkLadenError(vesselLdnConsmLS1, vesselLdnConsmLS2, vesselLdnConsmLS3, vesselLdnConsmLS4)) && "req-error"}`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnConsmLS3"
                        value={vesselLdnConsmLS3 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Laden LS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnConsmMGO3"
                        value={vesselLdnConsmMGO3 || ""}
                        onChange={onChangeHandler}
                      /></div>
                  </div>
                  <div className="consumptions-content">
                    <input
                      type="checkbox"
                      className="input-type-checkbox"
                      name="vesselLadComb4Selected"
                      checked={vesselLadComb4Selected || false}
                      onChange={onChangeHandler}
                    />
                    <div className={`custom-input-outer ${(vesselLadComb4Selected && checkLadenError(vesselLdnSpd1, vesselLdnSpd2, vesselLdnSpd3, vesselLdnSpd4)) && "req-error"}`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnSpd4"
                        value={vesselLdnSpd4 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Laden Speed-Consumption should be non-zero.</p>
                      </div>
                    </div>

                    <div className={`custom-input-outer ${(vesselLadComb4Selected && checkLadenError(vesselLdnConsmHS1, vesselLdnConsmHS2, vesselLdnConsmHS3, vesselLdnConsmHS4)) && "req-error"}`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnConsmHS4"
                        value={vesselLdnConsmHS4 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Laden HS-Consumption should be non-zero.</p>
                      </div>
                    </div>
                    <div className={`custom-input-outer ${(vesselLadComb4Selected && checkLadenError(vesselLdnConsmLS1, vesselLdnConsmLS2, vesselLdnConsmLS3, vesselLdnConsmLS4)) && "req-error"}`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnConsmLS4"
                        value={vesselLdnConsmLS4 || ""}
                        onChange={onChangeHandler}
                      />
                      <div className="error-notification">
                        <img src={errorIcon} alt="" />
                        <p>Laden LS-Consumption should be non-zero.</p>
                      </div>
                    </div><div className={`custom-input-outer`}>
                      <input
                        type="number"
                        onKeyDown={handleKeyDown}
                        placeholder="0"
                        name="vesselLdnConsmMGO4"
                        value={vesselLdnConsmMGO4 || ""}
                        onChange={onChangeHandler}
                      /></div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <p className="m-t-5 m-b-5">Port Consumptions</p>
                  <div className="consumptions-headers">
                    <p></p>
                    <p>HS</p>
                    <p>LS</p>
                    <p>MGO</p>
                  </div>
                  <div className="consumptions-content">
                    <p className="port-consumptions-label">Idle</p>
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="vesselPIdleConsmHS"
                      value={vesselPIdleConsmHS || ""}
                      onChange={onChangeHandler}
                    />
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="vesselPIdleConsmLS"
                      value={vesselPIdleConsmLS || ""}
                      onChange={onChangeHandler}
                    />
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="vesselPIdleConsmMGO"
                      value={vesselPIdleConsmMGO || ""}
                      onChange={onChangeHandler}
                    />
                  </div>
                  <div className="consumptions-content">
                    <p className="port-consumptions-label">Loading</p>
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="vesselPLdgConsmHS"
                      value={vesselPLdgConsmHS || ""}
                      onChange={onChangeHandler}
                    />
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="vesselPLdgConsmLS"
                      value={vesselPLdgConsmLS || ""}
                      onChange={onChangeHandler}
                    />
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="vesselPLdgConsmMGO"
                      value={vesselPLdgConsmMGO || ""}
                      onChange={onChangeHandler}
                    />
                  </div>
                  <div className="consumptions-content">
                    <p className="port-consumptions-label">Disch</p>
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="vesselPDischConsmHS"
                      value={vesselPDischConsmHS || ""}
                      onChange={onChangeHandler}
                    />
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="vesselPDischConsmLS"
                      value={vesselPDischConsmLS || ""}
                      onChange={onChangeHandler}
                    />
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="vesselPDischConsmMGO"
                      value={vesselPDischConsmMGO || ""}
                      onChange={onChangeHandler}
                    />
                  </div>
                  <div className="consumptions-content">
                    <p className="port-consumptions-label">Extra</p>
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="vesselVExtraConsmHS"
                      value={vesselVExtraConsmHS || ""}
                      onChange={onChangeHandler}
                    />
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="vesselVExtraConsmLS"
                      value={vesselVExtraConsmLS || ""}
                      onChange={onChangeHandler}
                    />
                    <input
                      type="number"
                      onKeyDown={handleKeyDown}
                      placeholder="0"
                      name="vesselVExtraConsmMGO"
                      value={vesselVExtraConsmMGO || ""}
                      onChange={onChangeHandler}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid >
      <CustomAddModal
        open={open}
        saveLabel={"Add"}
        title="Create New Vessel"
        maxWidth="sm"
        handleClose={onCloseModal}
        onSubmitConfirm={onSubmitConfirm}
      >
        <AddNewVessel
          vessel={newVessel}
          onChangeHandler={onChangeHandlerForNew}
        />
      </CustomAddModal>
    </Grid >
  );
}

export default memo(CalculatorVesselDetails);
