import { validateEmail } from "../utils";

const registerValidation = (data) => {
  let errors = {};
  if (!data.firstName) {
    errors.firstName = "First Name is required";
  }
  if (!data.lastName) {
    errors.lastName = "Last Name is required";
  }
  if (!data.email) {
    errors.email = "Email is required";
  }
  if (data.email && !validateEmail(data.email)) {
    errors.email = "Invalid Email";
  }
  if (!data.password) {
    errors.password = "Password is required";
  }
  if (!data.confirmPassword) {
    errors.confirmPassword = "Confirm Password is required";
  }

  if (data.password !== data.confirmPassword) {
    errors.password = "Password and Confirm Password should be same.";
  }
  if (!data.username) {
    errors.username = "Username is required";
  }

  if (!data.phoneNumber) {
    errors.phoneNumber = "Phone Number is required";
  }
  if (data.phoneNumber.length !== 10) {
    errors.phoneNumber = "Please enter valid Phone Number";
  }
  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

export default registerValidation;
