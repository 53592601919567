import { Button } from '@material-ui/core'
import React from 'react'

function SaveCargoLabel(props) {
    const {
        value = "",
        onChange = () => { },
        onClickSaveLabel = () => { },
        placeholder = "Enter Cargo label",
    } = props
    return (
        <div className="saved-fleet-wrapper">
            <input type="text" placeholder={placeholder} value={value} onChange={onChange} />
            {/* <p>Note: Click on "Calculate and Save" to save this result</p> */}
        </div>
    )
}

export default SaveCargoLabel
