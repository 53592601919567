import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  Paper,
  makeStyles,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Switch,
} from "@material-ui/core";
import CustomButton from "../../../components/Common/CustomButton";
import * as actions from "../Calculator/actions";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { getCurrentUserId, getCurrentUserOrganizationId, toastError, toastSuccess } from "../../../utils";
import CustomPagination from "../../../components/Common/CustomPagination";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CancelIcon from "@material-ui/icons/Cancel";
import CustomConfirmModal from "../../../components/Common/CustomConfirmModal";
import ShareIcon from '@material-ui/icons/Share';
import CustomAddModal from "../../../components/Common/CustomAddModal";
import ShareWithOrg from "../../../components/Common/ShareWithOrg";
import SaveIcon from '@material-ui/icons/Save';
import SearchBar from "../../../common/Search";
import _ from "lodash";
const useStyles = makeStyles({
  root: {
    width: "fit-content",
  },
});

const initialSearchType = {
  type: "",
  saveLabel: "",
  name: "",
  firstLoadPort: "",
  lastDischargePort: "",
  loadQuantity: "",
  createdDate: "",
};

function Voyages(props) {
  const { setLoader = () => { } } = props;
  const classes = useStyles();

  const [value, setValue] = useState(1);
  const [open, setOpen] = useState(false);

  const [savedResults, setSavedResults] = useState([]);
  const [allSavedResults, setAllSavedResults] = useState([]);
  const [searchType, setSearchType] = useState({ ...initialSearchType });

  const [myFilters, setMyFilters] = useState({
    offset: 1,
    limit: 10,
  });
  const [allFilters, setAllFilters] = useState({
    offset: 1,
    limit: 10,
  });

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState({});
  const [isShareConfirm, setShareConfirm] = useState(false);
  const [getSingleVoyagesData, setSingleVoyagesData] = useState("");

  const [shareLabel, setShareLabel] = useState("");
  const [isCopyConfirm, setCopyConfirm] = useState(false);

  const organizationId = getCurrentUserOrganizationId();
  const [searchValue, setSearchValue] = useState("");
  const onSearchVessel = (search) => {
    getAllSavedResults(search);
    getAllOrgSavedResults(search);
  };
  const delaySearch = useCallback(
    _.debounce((data) => onSearchVessel(data), 500),
    []
  );
  const onSearchChange = (e) => {
    setSearchValue(e.target.value)
    delaySearch(e.target.value);
  }
  useEffect(() => {
    getAllSavedResults();
    getAllOrgSavedResults()
  }, []);

  const getAllSavedResults = async (search) => {
    setLoader(true);
    await actions
      .allSavedResults({ userId: getCurrentUserId(), search: search })
      .then((res) => {
        setLoader(false);
        setSavedResults(res?.data?.list || []);
        setMyFilters({
          totalPages: res?.data?.totalItems,
          offset: res?.data?.currentPage,
          pageCount: res?.data?.totalPages,
          limit: res?.data?.perPage
        })
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  };

  const getAllOrgSavedResults = async (search) => {
    setLoader(true);
    await actions
      .allSavedResults({ organizationId: organizationId, search: search })
      .then((res) => {
        setLoader(false);
        setAllSavedResults(res?.data?.list || []);
        setAllFilters({
          totalPages: res?.data?.totalItems,
          offset: res?.data?.currentPage,
          pageCount: res?.data?.totalPages,
          limit: res?.data?.perPage
        })
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchType({ ...initialSearchType });
  };

  const onClickDeleteSavedFleet = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const onShareOrgClick = (item) => {
    setShareConfirm(!isShareConfirm)
    setSingleVoyagesData(item ? item : "")
    setShareLabel("")
  }
  const onCopyClick = (item) => {
    setCopyConfirm(!isCopyConfirm)
    setSingleVoyagesData(item ? item : "")
    setShareLabel("")
  }

  const onShareOrgSaveClick = async () => {
    if (!shareLabel || !getSingleVoyagesData || !organizationId) return
    let { _id, ...data } = getSingleVoyagesData
    data.access = "public"
    data.organizationId = organizationId
    data.name = shareLabel
    await actions.saveCalculatedCostResult(data);

    setShareConfirm(false)
    setShareLabel("")
    getAllOrgSavedResults()
  }

  const onCopySaveClick = async () => {
    if (!shareLabel || !getSingleVoyagesData || !organizationId) return
    let { _id, ...data } = getSingleVoyagesData
    data.access = "private"
    data.organizationId = organizationId
    data.name = shareLabel
    await actions.saveCalculatedCostResult(data);

    setCopyConfirm(false)
    setShareLabel("")
    getAllSavedResults()
  }

  const onChangeSearchType = (e, key = "") => {
    let { name = "", value = "" } = e.target || {};
    if (!value) name = "";
    let updatedSearchType = { ...searchType };
    if (name === "loadQuantity") {
      let valueSplit = updatedSearchType[name].split(",");
      let startValue = valueSplit[0] || "";
      let endValue = valueSplit[1] || "";
      if (key === "start") startValue = value;
      else endValue = value;
      value = `${startValue},${endValue}`;
    } else if (name === "createdDate") {
      let valueSplit = updatedSearchType[name].split(",");
      let startValue = valueSplit[0] || "";
      let endValue = valueSplit[1] || "";
      if (key === "start") startValue = moment(value).valueOf();
      else endValue = moment(value).valueOf();
      value = `${startValue},${endValue}`;
    }
    setSearchType({ ...initialSearchType, type: name, [name]: value });
  };

  const onClickSubmitSearch = async () => {
    setLoader(true);
    if (searchType.type) {
      let searchRequest = actions.allVoyageResultsByChar;
      if (value !== 1) searchRequest = () => { };
      await searchRequest({
        searchParam: searchType[searchType.type],
        type: searchType.type,
        offset: 10,
      }).then((res) => {
        setSavedResults((Array.isArray(res) && res) || []);
      });
    } else {
      let searchRequest = actions.allSavedResults;
      if (value !== 1) searchRequest = actions.allSavedResults;
      await searchRequest().then((res) => {
        setSavedResults(
          (res?.data?.list &&
            Array.isArray(res?.data?.list) &&
            res?.data?.list) ||
          []
        );
      });
    }
    setLoader(false);
  };

  const onChangeMyPagination = (type = "") => {
    let newMyFilters = {};
    if (type === "inc") {
      newMyFilters = { ...myFilters, offset: myFilters.offset + 1 };
    } else if (type === "first") {
      newMyFilters = { ...myFilters, offset: 1 };
    } else {
      if (myFilters.offset === 1) return;
      newMyFilters = { ...myFilters, offset: myFilters.offset - 1 };
    }
    setLoader(true);
    actions
      .allSavedResults({ ...newMyFilters, userId: getCurrentUserId(), search: searchValue })
      .then((res) => {
        setLoader(false);
        setMyFilters({
          totalPages: res?.data?.totalItems,
          offset: res?.data?.currentPage,
          pageCount: res?.data?.totalPages,
          limit: res?.data?.perPage
        })
        setSavedResults(
          (res?.data?.list &&
            Array.isArray(res?.data?.list) &&
            res?.data?.list) ||
          []
        );
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onChangeAllPagination = (type = "") => {
    let newallFilters = {}
    if (type === 'inc') {
      newallFilters = { ...allFilters, offset: allFilters.offset + 1 }
    } else if (type === 'first') {
      newallFilters = { ...allFilters, offset: 1 }
    } else {
      if (allFilters.offset === 1) return
      newallFilters = { ...allFilters, offset: allFilters.offset - 1 }
    }
    setLoader(true);
    actions
      .allSavedResults({ ...newallFilters, organizationId: organizationId, search: searchValue })
      .then((res) => {
        setLoader(false);
        setAllFilters({
          totalPages: res?.data?.totalItems,
          offset: res?.data?.currentPage,
          pageCount: res?.data?.totalPages,
          limit: res?.data?.perPage
        })
        setAllSavedResults(
          (res?.data?.list &&
            Array.isArray(res?.data?.list) &&
            res?.data?.list) ||
          []
        );
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onClickClearMyVoyages = async () => {
    await getAllSavedResults();
    setSearchType({ ...initialSearchType });
  };

  const onDeleteVoyages = () => {
    setLoader(true);
    actions
      .deleteSavedResultById(deleteId)
      .then((res) => {
        toastSuccess("Result deleted successfully");
        setDeleteModal(false);
        setDeleteId("");
        getAllSavedResults();
      })
      .catch((err) => {
        toastError();
        setLoader(false);
      });
  };

  const loadSliderValue = String(searchType.loadQuantity).split(",");
  const createdDateValue = String(searchType.createdDate).split(",");
  const handleDateRangeChange = async (newDateRange) => {
    const { startDate, endDate } = newDateRange;

    // Ensure dates are in ISO string format
    const formattedStartDate = startDate?.toISOString() || '';
    const formattedEndDate = endDate?.toISOString() || '';

    if (value === 2) {
      const { data } = await actions.allSavedResults({
        organizationId: organizationId,
        startDate: formattedStartDate,
        endDate: formattedEndDate
      });
      setAllSavedResults(data?.list);
    }
    else {
      const { data } = await actions.allSavedResults({
        userId: getCurrentUserId(),
        startDate: formattedStartDate,
        endDate: formattedEndDate
      });
      setSavedResults(data?.list);
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          position="relative"
          width="100%"
        >
          <Box>
            <Paper className={classes.root}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab value={1} label="My Voyages" />
                <Tab value={2} label="All Voyages" />
              </Tabs>
            </Paper>
          </Box>

          <Box
            position="absolute"
            left="50%"
            sx={{
              transform: 'translateX(-50%)',
              display: { xs: 'none', md: 'block' } // Desktop view
            }}
          >
            <div className="desktop-searchbar">
              <SearchBar
                onDateRangeChange={handleDateRangeChange}
                placeholder={"Search with Voyage Label or Voyage Name..."}
                setSearchValue={onSearchChange}
                searchValue={searchValue}
              />
            </div>
          </Box>


        </Box>

        {/* Mobile search bar */}
        <Box
          className="md:hidden"
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <SearchBar
            isMobile
            onDateRangeChange={handleDateRangeChange}
            placeholder={"Search with Voyage Label or Voyage Name..."}
            setSearchValue={onSearchChange}
            searchValue={searchValue}
          />
        </Box>
      </Grid>

      <Grid item xs={12}></Grid>

      <Grid item xs={12}>
        {value === 1 ? (
          <Box pt={2}>
            <TableContainer component={Paper}>
              <Table className="universal-table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Voyage Label</TableCell>
                    <TableCell>Vessel Name</TableCell>
                    <TableCell>Opening Port</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>First Load Port</TableCell>
                    <TableCell>Last Discharge Port</TableCell>
                    <TableCell>Load Qty</TableCell>
                    <TableCell>Created on</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow className="searchable-tablerow">
                    <TableCell>
                      {searchType.type ? (
                        <CancelIcon
                          style={{ color: "#009EF7" }}
                          onClick={onClickClearMyVoyages}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <input
                        name="saveLabel"
                        value={searchType.saveLabel}
                        onChange={onChangeSearchType}
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        name="name"
                        value={searchType.name}
                        onChange={onChangeSearchType}
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        name="name"
                        value={searchType.name}
                        onChange={onChangeSearchType}
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        name="name"
                        value={searchType.name}
                        onChange={onChangeSearchType}
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        name="firstLoadPort"
                        value={searchType.firstLoadPort}
                        onChange={onChangeSearchType}
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        name="lastDischargePort"
                        value={searchType.lastDischargePort}
                        onChange={onChangeSearchType}
                      />
                    </TableCell>
                    <TableCell>
                      <div className="range-load-quantity">
                        <input
                          type="number"
                          name="loadQuantity"
                          value={loadSliderValue[0] || ""}
                          onChange={(e) => onChangeSearchType(e, "start")}
                        />
                        <input
                          type="number"
                          name="loadQuantity"
                          value={loadSliderValue[1] || ""}
                          onChange={(e) => onChangeSearchType(e, "end")}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          autoOk
                          fullWidth
                          variant="inline"
                          inputVariant="outlined"
                          format="DD/MMM/yyyy"
                          className="small-date-picker"
                          placeholder="Start"
                          size="small"
                          name="createdDate"
                          clearable
                          value={
                            (createdDateValue &&
                              createdDateValue[0] &&
                              Number(createdDateValue[0])) ||
                            null
                          }
                          onChange={(date) =>
                            onChangeSearchType(
                              { target: { name: "createdDate", value: date } },
                              "start"
                            )
                          }
                        />
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          autoOk
                          fullWidth
                          variant="inline"
                          inputVariant="outlined"
                          format="DD/MMM/yyyy"
                          className="small-date-picker"
                          style={{ marginLeft: 10 }}
                          placeholder="End"
                          size="small"
                          clearable
                          name="createdDate"
                          value={
                            (createdDateValue &&
                              createdDateValue[1] &&
                              Number(createdDateValue[1])) ||
                            null
                          }
                          onChange={(date) =>
                            onChangeSearchType(
                              { target: { name: "createdDate", value: date } },
                              "end"
                            )
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </TableCell>
                    <TableCell>
                      <button onClick={onClickSubmitSearch}>Search</button>
                    </TableCell>
                  </TableRow> */}
                  {Array.isArray(savedResults) && savedResults.length ? (
                    savedResults.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <a
                              style={{ color: "blue" }}
                              href={`/saved/${item._id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.name || ""}
                            </a>
                          </TableCell>
                          <TableCell>
                            {item?.vessel?.vesselName || ""}
                          </TableCell>
                          <TableCell>{"-"}</TableCell>
                          <TableCell>{item.name || ""}</TableCell>
                          <TableCell>{item.firstLoadPort || ""}</TableCell>
                          <TableCell>{item.lastDischargePort || ""}</TableCell>
                          <TableCell>{item.loadQuantity || 0}</TableCell>
                          <TableCell>
                            {item.createdDate
                              ? moment(item.createdDate).format("DD/MMM/yyyy")
                              : ""}
                          </TableCell>
                          <TableCell>
                            <div className="query-actions">
                              <>
                                <span className="pending-steper validate-icon-vessel-manager">
                                  <ShareIcon
                                    color="primary"
                                    onClick={() => onShareOrgClick(item)}
                                  />
                                </span>
                                <div className="tooltip-notification-vessel-manager">
                                  <p>Share with your Team</p>
                                </div>
                              </>
                              <DeleteIcon
                                color="secondary"
                                onClick={() =>
                                  onClickDeleteSavedFleet(item._id)
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan="8"
                        align="center"
                        className="!bg-white"
                      >
                        No Voyages Found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <CustomPagination
                onChangePagination={onChangeMyPagination}
                filters={myFilters}
                totalPage={myFilters?.totalPages}
              />
            </TableContainer>
          </Box>
        ) : (
          <Box pt={2}>
            <TableContainer component={Paper}>
              <Table className="universal-table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Vessel Name</TableCell>
                    {/* <TableCell>Name</TableCell> */}
                    <TableCell>First Load Port</TableCell>
                    <TableCell>Last Discharge Port</TableCell>
                    <TableCell>Load Qty</TableCell>
                    <TableCell>Created on</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(allSavedResults) && allSavedResults.length ? (
                    allSavedResults.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell> <a
                            style={{ color: "blue" }}
                            href={`/saved/${item._id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.name || ""}
                          </a></TableCell>
                          <TableCell>
                            {JSON.parse(item?.vessel?.vessel)?.vesselName || ""}
                          </TableCell>
                          <TableCell>{item.firstLoadPort || ""}</TableCell>
                          <TableCell>{item.lastDischargePort || ""}</TableCell>
                          <TableCell>{item.loadQty || ""}</TableCell>
                          <TableCell>
                            {item.createdDate
                              ? moment(item.createdDate).format("DD/MMM/yyyy")
                              : ""}
                          </TableCell>
                          <TableCell>
                            <>
                              <span className="pending-steper validate-icon-vessel-manager">
                                <SaveIcon
                                  color="primary"
                                  onClick={() => onCopyClick(item)}
                                />
                              </span>
                              <div className="tooltip-notification-vessel-manager">
                                <p>Copy to My Voyages</p>
                              </div>
                            </>

                            {/* <VisibilityIcon color="primary" onClick={() => { onClickEditQuery(item); setIsDetailView(true) }} /> */}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan="8"
                        align="center"
                        className="!bg-white"
                      >
                        No Voyages Found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <CustomPagination
                onChangePagination={onChangeAllPagination}
                filters={allFilters}
                totalPage={allFilters?.totalPages}
              />
            </TableContainer>
          </Box>
        )}
      </Grid>

      <CustomConfirmModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        message="Are you sure to delete this voyage?"
        onSubmitConfirm={onDeleteVoyages}
      />

      <CustomAddModal
        open={isShareConfirm}
        handleClose={onShareOrgClick}
        onSubmitConfirm={onShareOrgSaveClick}
        maxWidth="xs"
        saveLabel="Share"
        title="Share with Team"
      >
        <ShareWithOrg
          value={shareLabel}
          onChange={(e) => setShareLabel(e.target.value)}
          placeholder="Enter Voyages name*"
        />
      </CustomAddModal>

      <CustomAddModal
        open={isCopyConfirm}
        handleClose={onCopyClick}
        onSubmitConfirm={onCopySaveClick}
        maxWidth="xs"
        saveLabel="Create"
        title="Copy to My Voyages"
      >
        <ShareWithOrg
          value={shareLabel}
          onChange={(e) => setShareLabel(e.target.value)}
          placeholder="Enter Voyages name*"
        />
      </CustomAddModal>
    </Grid>
  );
}

export default Voyages;
