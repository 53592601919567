import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { resultsFields } from "../../constants";
const _ = require('lodash');

function Result(props) {
  const { selectedVessels = {}, calculationErrors = [], getCurrentResult = {}, calculatorData = {} } = props;
  const isEqual = _.isEqual(calculatorData, getCurrentResult)
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className="calculator-section-header result-section"
        style={{ paddingRight: 10 }}
      >
        <h4 className="result-heading">Result Details</h4>
      </Grid>
      <Grid item xs={12}>
        <div>
          <table className="fleet-result-table">
            {
            // isEqual ? 
            resultsFields.map((item, key) => {
              return (
                <tr key={key}>
                  <th>{item.label}</th>
                  <td>
                    {selectedVessels[item.value] &&
                      Number(selectedVessels[item.value]).toFixed(2)}
                  </td>
                </tr>
              );
            }) 
            // : "Please click calculate button."
            }
          </table>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Paper className="result-details-errors">
          {calculationErrors.length ? <p className="errors-found-head">List of Errors</p> : ""}
          <div className="error-list-main">
            {calculationErrors.length ? (
              calculationErrors.map((item, index) => {
                // if return
                return (
                  <React.Fragment key={index}>

                    <p className="errors-found">
                      {index + 1}. {item.message}
                    </p>
                  </React.Fragment>
                );
              })
            ) : (
              <p className="no-errors-found">No Error Found.</p>
            )}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Result;
