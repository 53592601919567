import React, {
  useEffect,
  useState,
  memo,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Box, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import * as actions from "./actions";
import * as dashboardActions from "../Dashboard/actions";
import OrdersList from "../../../components/Calculator/OrdersList";
import PortOperations from "../../../components/Calculator/PortOperations";
import VesselSelection from "../../../components/Calculator/VesselSelection";
import {
  initialAddFleet,
  initialCreatePortOperation,
  termsCoEfficientTable,
  vesselVesselExpenseResultsFields,
  vesselVesselResultsFields,
  vesselVesselRevenueResultsFields,
} from "../../../constants";
import {
  addVesselVesselPortDistanceToOperations,
  convertPortOperationToDistanceString,
  fleetWithCalculatorDataMapping,
  sendConsumptionsDataByCheckBox,
  calculationValidation,
  addPortDistanceToSelectedFleet,
} from "./dataMapping";
import Result from "../../../components/Calculator/Result";
import Expenses from "../../../components/Calculator/Expenses";
import AccountCargoDetails from "../../../components/Calculator/AccountCargoDetails";
import CalculatorVesselDetails from "../../../components/Calculator/CalculatorVesselDetails";
import MarginAndAllowances from "../../../components/Calculator/MarginAndAllowances";
import ActionButtons from "../../../components/Calculator/ActionButtons";
import { getOrganizationId, toastError, toastSuccess } from "../../../utils";
import _, { cloneDeep } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import VesselVesselExpenses from "../../../components/Calculator/VesselVesselDeliveryOut";
import VesselVesselDeliveryOut from "../../../components/Calculator/VesselVesselDeliveryOut";
import VesselVesselReDeliveryIn from "../../../components/Calculator/VesselVesselReDeliveryIn";
import CustomShowModal from "../../../components/Common/CustomShowModal";
import SplitHireModal from "../../../components/Calculator/SplitHireModal";
import VesselVesselPortOperations from "../../../components/Calculator/VesselVesselPortOperations";
import VesselVesselResult from "../../../components/Calculator/VesselVesselResult";

function VesselVesselMain(props) {
  const {
    setIsFullScreen = () => { },
    setLoader = () => { },
    loader = false,
  } = props;
  const dispatch = useDispatch();
  const [hideOrder, setHideOrder] = useState(false);
  const [isPublic, setPublic] = useState(false);

  const [selectedQuery, setSelectedQuery] = useState({});

  const [calculatorData, setCalculatorData] = useState({
    portOperation: [],
    clientQuery: {},
    fleetList: [],
    cargoList: [],
    cargoExtraDetails: [],
    autoSave: false,
  });

  // reducer data in state
  const [allQueryFormat, setAllQueryFormat] = useState([]);

  // vessel
  const [selectedVessels, setSelectedVessels] = useState({});
  const [syncBunker, setSyncBunker] = useState(false);
  const [isOverride, setIsOverride] = useState(false);
  const [hideCalculator, setHideCalculator] = useState(false);
  const [fleetCheckbox, setFleetCheckbox] = useState({
    ballest: {
      1: true,
      2: true,
      3: true,
      4: true,
    },
    laden: {
      1: true,
      2: true,
      3: true,
      4: true,
    },
  });
  // const [startBunkerSync, setStartBunkerSync] = useState(false)
  const [addFleetsArray, setAddFleetsArray] = useState([
    { ...initialAddFleet },
  ]);
  const [portDistanceResponse, setPortDistanceResponse] = useState({});
  const [selectedSplitHire, setSelectedSplitHire] = useState({});
  const [selectedSplitHireVesselOut, setSelectedSplitHireVesselOut] = useState({});


  const [initialFleetList, setInitialFleetList] = useState([]);

  //modals
  const [marginModal, setMarginModal] = useState(false);
  const [supplyModal, setSupplyModal] = useState(false);
  const [addFleetModal, setAddFleetModal] = useState(false);
  const [splitHireModal, setSplitHireModal] = useState(false);
  const [splitHireModalVesselOut, setSplitHireModalVesselOut] = useState(false);

  const delayUpdateAndSaveOperation = useCallback(
    _.debounce((data) => onUpdateAndSavePortDistance(data), 500),
    []
  );
  const [openAddOperation, setOpenAddOperation] = useState(false);
  const [openAddOperationIndex, setOpenAddOperationindex] = useState(false);
  const [getCurrentResult, setCurrentResult] = useState({});
  const { id = "" } = useParams();


  // const [openMap, setOpenMap] = useState(false)

  //refs
  const fetchPortDistance = useRef(true);

  useEffect(() => {
    setIsFullScreen(true);
    getInitalQueryAndFleetData(id);
    onChangeOverride(true)
    return () => {
      setIsFullScreen(false);
    };
  }, []);

  const getInitalQueryAndFleetData = (id) => {
    setLoader(true);
    Promise.all([
      actions.getSingleVesselVesselDetails(id),
      // actions.allFleetCalculator(),
      // dispatch(
      //   dashboardActions.usersForOrg({ organizationId: getOrganizationId() })
      // ),
    ])
      .then((res) => {

        const response1 = res[0]?.data;
        setPublic(response1?.access === "public" ? true : false)
        // const response2 = res[1].data.list;
        // const newResponse1 =
        //   (Array.isArray(response1) && response1.filter((item) => !!item)) ||
        //   [];
        setInitialFleetList(response1?.fleetList);
        setInitialFetchedData(response1, true);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  };

  const calculationErrors = useMemo(() => {
    let errors = [];
    if (
      selectedVessels &&
      calculatorData.portOperation.length &&
      calculatorData.fleetList.length
    ) {
      errors = calculationValidation(calculatorData, selectedVessels);
    }
    return errors;
  }, [calculatorData, selectedVessels]);

  const setInitialFetchedData = async (mainRes1, initial = false) => {
    fetchPortDistance.current = true;
    let selectedQuery = {};
    // if (initial && Array.isArray(mainRes1) && mainRes1.length) {
    setAllQueryFormat(mainRes1?.clientQuery);
    // selectedQuery = mainRes1[0];
    // }
    // if (!initial) 
    selectedQuery = mainRes1?.clientQuery;
    setSelectedQuery(selectedQuery);
    if (mainRes1?._id) {
      if (!loader) setLoader(true);

      let combinedCargo = [];
      let formattedPortOperations = [];

      combinedCargo = mainRes1?.clientQuery?.cargoList

      // if (portOperationOrder.length > 0) {
      //   for (let i = 0; i < portOperationOrder.length; i++) {
      //     const portElement = combinedCargo.find(
      //       (item) => item._id === portOperationOrder[i]
      //     );
      //     if (!!portElement) {
      //       formattedPortOperations.push({ ...portElement, index: i });
      //     }
      //   }
      // } else {
      // }
      formattedPortOperations = combinedCargo.map((portElement, i) => ({
        ...portElement,
        index: i,
      }));
      if (!selectedQuery.fuelCalculationMethod)
        selectedQuery.fuelCalculationMethod = "DEFAULT";
      if (!selectedQuery.fuelType) selectedQuery.fuelType = "TYPE0";

      if (
        selectedQuery.fuelCalculationMethod === "LIFO" ||
        selectedQuery.fuelCalculationMethod === "FIFO" ||
        selectedQuery.fuelCalculationMethod === "AVERAGE"
      ) {
        setSyncBunker(true);
      }

      if (selectedQuery.fuelCalculationMethod === "DEFAULT") {
        const currentType = selectedQuery.fuelType.replace("TYPE", "");
        formattedPortOperations = formattedPortOperations.map((item) => {
          return {
            ...item,
            cargoDetails: {
              ...item?.cargoDetails,
              fuelPriceType0Hsfo: 0,
              fuelPriceType0Lsfo: 0,
              fuelPriceType0Mgo: 0,
              fuelPriceType1Hsfo: 0,
              fuelPriceType1Lsfo: 0,
              fuelPriceType1Mgo: 0,
              fuelPriceType2Hsfo: 0,
              fuelPriceType2Lsfo: 0,
              fuelPriceType2Mgo: 0,
              fuelPriceType3Hsfo: 0,
              fuelPriceType3Lsfo: 0,
              fuelPriceType3Mgo: 0,
              fuelPriceType4Hsfo: 0,
              fuelPriceType4Lsfo: 0,
              fuelPriceType4Mgo: 0,
            },
          };
        });
      }

      // make calculator data add port operations
      const updatedData = {
        ...calculatorData,
        clientQuery: selectedQuery,
        portOperation: formattedPortOperations,
      };
      if (initial) updatedData.fleetList = cloneDeep(mainRes1?.fleetList);
      // setCalculatorData(updatedDataMain)
      sendCalculationRequest(updatedData);
    } else {
      setLoader(false);
    }
  };

  const onChangePortOperations = (index, e, cargoUpdate = false, isDate = false, datename) => {
    // let { value = "", name = "" } = e.target;
    let value = ""
    let name = ""
    if (isDate) {
      value = e;
      name = datename
    }
    else {
      value = e.target?.value
      name = e.target?.name
    }
    let portOperation = [...calculatorData.portOperation];

    if (name === "terms") {
      const termFactor = termsCoEfficientTable.find(
        (item) => item.label === value
      );
      portOperation[index]["terms"] = value;
      portOperation[index]["factor"] = (termFactor && termFactor.value) || 0;
    } else if (cargoUpdate) {
      portOperation[index].cargoDetails[name] = value;
    } else {
      portOperation[index][name] = value;
    }

    let updatedCalculatorData = { ...calculatorData, portOperation };
    if (updatedCalculatorData?.clientQuery?.portToPortDistance.output && portOperation[index - 1] && portOperation[index].port)
      updatedCalculatorData.clientQuery.portToPortDistance.output[`${portOperation[index - 1]?.port}_${portOperation[index].port}`][name] = value
    if (name === "distanceNormal" || name === "distanceECA") {
      const updateData = {
        distEca: portOperation[index].distanceECA,
        distNormal: portOperation[index].distanceNormal,
        id: portOperation[index]._id,
      };
      const saveData = {
        distEca: portOperation[index].distanceECA,
        distNormal: portOperation[index].distanceNormal,
        openingPort:
          (portOperation[index - 1] && portOperation[index - 1]["port"]) || "",
        endingPort: portOperation[index]["port"] || "",
      };
      delayUpdateAndSaveOperation({
        updateData,
        saveData,
        updatedCalculatorData,
      });
    }
    setCalculatorData(updatedCalculatorData);
  };

  const onUpdateAndSavePortDistance = ({
    updateData = {},
    saveData = {},
    updatedCalculatorData = {},
  }) => {
    setLoader(true);
    Promise.all([
      // actions.updateDistance(updateData),
      actions.savePortDistance(saveData),
    ])
      .then(() => {
        setLoader(false);
        sendCalculationRequest(updatedCalculatorData);
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong.");
      });
  };

  const sendCalculationRequest = async (sendData) => {
    let portDistanceRes = portDistanceResponse;
    if (fetchPortDistance.current) {
      portDistanceRes = await onFetchAndSetIntitalPortDistance(sendData);
    }
    if (!sendData?.clientQuery?.portToPortDistance) {
      getInitalQueryAndFleetData(id);
      return
    }
    sendData = {
      ...sendData,
      portOperation: addVesselVesselPortDistanceToOperations(portDistanceRes, [
        ...sendData?.portOperation,
      ]),
    };

    let fleetSelected = {};
    const updatedFleedIds = sendData.fleetList.map((fleetDetails) => ({
      ...fleetDetails,
      vesselUniqueId: uuidv4(),
    }));

    let modifiedData = {
      cargoList: sendData.portOperation.filter((item) => !item.isExtra),
      cargoExtraDetails: sendData.portOperation.filter(
        (item) => !!item.isExtra
      ),
      fleetList: cloneDeep(updatedFleedIds),
      clientQuery: {
        ...sendData.clientQuery,
        calculationFormat: sendData.portOperation
          .map((item) => item._id)
          .join(","),
      },
      autoSave: false,
    };
    await calculationValidation(modifiedData, selectedVessels)
    if (!loader) setLoader(true);
    const res = await sendCalculatorResponse(modifiedData);
    setLoader(false);
    const fleetData = fleetWithCalculatorDataMapping(
      cloneDeep(updatedFleedIds),
      res?.data
    );
    if (selectedVessels && selectedVessels._id)
      fleetSelected =
        fleetData.find((item) => item._id === selectedVessels._id) || {};
    else fleetSelected = fleetData[0];
    onChangeFleetRowSelect(
      fleetSelected,
      { ...sendData, fleetList: fleetData },
      true,
      true,
      true
    );
    const {
      __v,
      version,
      system,
      createdUser,
      vesselId,
      vesselName,
      userId,
      lastModifiedUser,
      lastModifiedDate,
      ...restClientQuery
    } = calculatorData.clientQuery;

    const newUpdatedQuery = {
      ...restClientQuery,
      cargoList: calculatorData.portOperation,
      createdUser: createdUser?._id,
    };
    const newUpdatedFleet = { ...fleetSelected };

    if (sendData.autoSave) {
      saveAllResultUpdatedData({
        fleetList: [newUpdatedFleet],
        clientQuery: newUpdatedQuery,
      });
    }
  };

  const saveAllResultUpdatedData = (updatedData) => {
    actions.updateVesselVesselDetails(id, updatedData)
      .then((res) => {
        if (res) {
          getInitalQueryAndFleetData(id);
          toastSuccess("Calculation updated and saved successfully!");
        }
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message);
      });
  };

  const sendCalculatorResponse = (modifiedData = {}) => {
    return new Promise((resolve, reject) => {
      if (modifiedData.fleetList.length === 0) return resolve({});
      const updatedCargoList = modifiedData.cargoList.map((cargoDetails, i) => {
        const { _id, ...restCargoMainDetails } = cargoDetails;
        const { _id: cargoDetailId, ...restCargoDetail } =
          cargoDetails?.cargoDetails;
        return {
          ...restCargoMainDetails,

          cargoDetails: { ...restCargoDetail },
        };
      });

      const {
        _id,
        cargoList,
        createdUser,
        lastModifiedUser,
        userId,
        calculationFormat,
        __v,
        createdAt,
        updatedAt,
        isCalculate,
        ...restClientQuery
      } = modifiedData.clientQuery;

      const updatedClientQuery = {
        ...restClientQuery,
        cargoList: null,
      };

      const updatedFleetListData =
        modifiedData?.fleetList?.length > 0
          ? modifiedData.fleetList.map((fleetDetails, index) => {
            const vesselDetails = !!fleetDetails?.vessel
              ? JSON.parse(fleetDetails.vessel)
              : {};
            const {
              _id: parseVesselId,
              userId: parseVesselUserId,
              createdUser: parseVesselCreatedUser,
              lastModifiedUser: parseVesselModifiedUser,
              vesselExtraDetails,
              __v: parseVessel__v,
              createdAt,
              createdDate,
              fuelType,
              lastModifiedDate,
              pattern,
              updatedAt,

              vesselLabel,
              vesselAHL,
              vesselCO2,

              vesselCountry,
              vesselGrabs,
              vesselIceClass,
              vesselIMO,
              vesselLdnConsmMGO1,
              vesselLdnConsmMGO2,
              vesselLightShip,
              vesselMMSI,
              vesselType,
              vesselSource,
              vesselName,
              ...parseVesselDetails
            } = vesselDetails;
            const {
              _id,
              vesselId,
              createdUser,
              organizationId,
              userId,
              lastModifiedUser,
              vesselUniqueId,
              __v,
              ...rest
            } = fleetDetails;
            const uniqueId = vesselUniqueId;

            return {
              ...rest,
              vessel: JSON.stringify({
                id: uniqueId,
                ...parseVesselDetails,
                system: true,
              }),
              vesselId: uniqueId,
            };
          })
          : [];
      const updatedCargoExtraDetails = modifiedData.cargoExtraDetails.map(
        (cargoextraDetails, i) => {
          const { _id, ...restCargoMainDetails } = cargoextraDetails;
          const { _id: cargoDetailId, ...restCargoDetail } =
            cargoextraDetails.cargoDetails;
          return {
            ...restCargoMainDetails,
            index: i + 1,
            cargoDetails: {
              ...restCargoDetail,
            },
          };
        }
      );

      const calculationData = {
        autoSave: false,
        cargoExtraDetails: updatedCargoExtraDetails,
        cargoList: updatedCargoList,
        clientQuery: updatedClientQuery,
        fleetList: updatedFleetListData,
      };

      actions
        .sendCalculatorData(calculationData)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          resolve({});
          toastError(err?.data?.message || "Something went wrong");
        });
    });
  };

  const onChangeFleetRowSelect = async (
    fleet = {},
    resultCalculatorData = null,
    changeVessel = false,
    isPortDistanceChanged = false,
    isFromCalculateButton = false
  ) => {
    let updatedCalculatorData = {};
    if (resultCalculatorData) updatedCalculatorData = resultCalculatorData;
    else updatedCalculatorData = { ...calculatorData };
    let commencePort = (fleet && fleet.port) || "";
    let fleetFuelType =
      (fleet && fleet.vessel && JSON.parse(fleet.vessel)) || {};
    fleetFuelType = fleetFuelType.fuelType || "TYPE0";
    let portOperation = [...updatedCalculatorData.portOperation];

    const portToPortOperationResultMap =
      (fleet && fleet.portToPortOperationResultMap) || [];
    portOperation = portOperation.map((operation, index) => {
      const selectedResultMap = portToPortOperationResultMap[index] || {};
      const removeDistanceFromResultMap = ({
        distanceECA = "",
        distanceNormal = "",
        ...rest
      }) => {
        return { ...rest };
      };
      if (index !== 0) commencePort = (operation && operation.port) || "";
      let isTermExists = termsCoEfficientTable[0];
      if (operation.terms) {
        isTermExists = termsCoEfficientTable.find(
          (item) => item.label === operation.terms
        );
      }
      let cargoDetails = { ...operation.cargoDetails };

      if (index === 0) {
        const currentType = fleetFuelType.replace("TYPE", "");
        const {
          bnkDelhs = 0,
          bnkDells = 0,
          bnkDelmgo = 0,
          bnkPriceDeliveryhs = 0,
          bnkPriceDeliveryls = 0,
          bnkPriceDeliverymgo = 0,
        } = fleet;
        cargoDetails = {
          ...cargoDetails,
          bunkerSuppliedHSFO: bnkDelhs,
          bunkerSuppliedLSFO: bnkDells,
          bunkerSuppliedMGO: bnkDelmgo,
          [`fuelPriceType${currentType}Hsfo`]: bnkPriceDeliveryhs,
          [`fuelPriceType${currentType}Lsfo`]: bnkPriceDeliveryls,
          [`fuelPriceType${currentType}Mgo`]: bnkPriceDeliverymgo,
        };
      }
      return {
        ...operation,
        ...removeDistanceFromResultMap(selectedResultMap),
        cargoDetails,
        port: commencePort,
        terms: isTermExists.label,
        factor: isTermExists.value,
      };
    });

    if (!isPortDistanceChanged) {
      let portDistanceRes = portDistanceResponse;
      if (fetchPortDistance.current) {
        portDistanceRes = await onFetchAndSetIntitalPortDistance(
          updatedCalculatorData
        );
      }
      portOperation = addVesselVesselPortDistanceToOperations(portDistanceRes, [
        ...portOperation,
      ]);
    }
    // add post distance to selected fleet as well
    fleet = addPortDistanceToSelectedFleet(portOperation, fleet);
    const FuelHandOverProfitCount = VesselOutFuelCalculate(updatedCalculatorData, fleet)
    fleet.FuelHandOverProfitCount = FuelHandOverProfitCount
    if (changeVessel || !selectedVessels._id) {
      setSelectedVessels(fleet);
    }
    if (isFromCalculateButton) {
      setCurrentResult({
        ...updatedCalculatorData,
        clientQuery: {
          ...updatedCalculatorData.clientQuery,
          fuelType: fleetFuelType,
        },
        fleetList: updatedCalculatorData.fleetList.map((singleFleet) => {
          if (singleFleet._id === fleet._id) return fleet;
          else return singleFleet;
        }),
        portOperation,
      })

    }

    setCalculatorData({
      ...updatedCalculatorData,
      clientQuery: {
        ...updatedCalculatorData.clientQuery,
        fuelType: fleetFuelType,
      },
      fleetList: updatedCalculatorData.fleetList.map((singleFleet) => {
        if (singleFleet._id === fleet._id) return fleet;
        else return singleFleet;
      }),
      portOperation,
    });
  };

  const VesselOutFuelCalculate = (updatedCalculatorData, fleet) => {
    const portOperation = updatedCalculatorData?.portOperation;
    const fleetDetails = fleet;

    let bunkerSuppliedHSFOTotal = 0
    let bunkerSuppliedLSFOTotal = 0
    let bunkerSuppliedMGOTotal = 0

    let bunkerSuppliedHSFOSupplyInternalTotal = 0
    let bunkerSuppliedLSFOSupplyInternalTotal = 0
    let bunkerSuppliedMGOSupplyInternalTotal = 0

    let hsReserveInternalTotal = 0
    let lsReserveInternalTotal = 0
    let mgoReserveInternalTotal = 0

    let hsReserveExternalTotal = 0
    let lsReserveExternalTotal = 0
    let mgoReserveExternalTotal = 0

    let hsFuelPriceReserveInternalTotal = 0
    let lsFuelPriceReserveInternalTotal = 0
    let mgoFuelPriceReserveInternalTotal = 0

    const { fuelType = "" } = updatedCalculatorData?.clientQuery || {}
    const currentType = fuelType.replace("TYPE", "")


    let hsFuelPriceTotal = 0
    let lsFuelPriceTotal = 0
    let mgoFuelPriceTotal = 0

    let hsFuelPriceSupplyInternalTotal = 0
    let lsFuelPriceSupplyInternalTotal = 0
    let mgoFuelPriceSupplyInternalTotal = 0

    let totalPortExpense = 0
    for (let i = 0; i < portOperation?.length; i++) {
      bunkerSuppliedHSFOTotal += Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedHSFO)
      bunkerSuppliedLSFOTotal += Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedLSFO)
      bunkerSuppliedMGOTotal += Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedMGO)

      bunkerSuppliedHSFOSupplyInternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Owner" && portOperation?.[i]?.cargoDetails?.reserve === "Supply") ? Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedHSFO) : 0
      bunkerSuppliedLSFOSupplyInternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Owner" && portOperation?.[i]?.cargoDetails?.reserve === "Supply") ? Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedLSFO) : 0
      bunkerSuppliedMGOSupplyInternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Owner" && portOperation?.[i]?.cargoDetails?.reserve === "Supply") ? Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedMGO) : 0

      hsReserveInternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Owner" && portOperation?.[i]?.cargoDetails?.reserve === "Reserve") ? Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedHSFO) : 0
      lsReserveInternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Owner" && portOperation?.[i]?.cargoDetails?.reserve === "Reserve") ? Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedLSFO) : 0
      mgoReserveInternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Owner" && portOperation?.[i]?.cargoDetails?.reserve === "Reserve") ? Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedMGO) : 0

      hsReserveExternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Operator" && portOperation?.[i]?.cargoDetails?.reserve === "Reserve") ? Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedHSFO) : 0
      lsReserveExternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Operator" && portOperation?.[i]?.cargoDetails?.reserve === "Reserve") ? Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedLSFO) : 0
      mgoReserveExternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Operator" && portOperation?.[i]?.cargoDetails?.reserve === "Reserve") ? Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedMGO) : 0

      //fuel price
      const currentHsfo = portOperation?.[i]?.cargoDetails[`fuelPriceType${currentType}Hsfo`] || 0
      const currentLsfo = portOperation?.[i]?.cargoDetails[`fuelPriceType${currentType}Lsfo`] || 0
      const currentMgo = portOperation?.[i]?.cargoDetails[`fuelPriceType${currentType}Mgo`] || 0
      hsFuelPriceTotal += (Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedHSFO) * Number(currentHsfo))
      lsFuelPriceTotal += (Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedLSFO) * Number(currentLsfo))
      mgoFuelPriceTotal += (Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedMGO) * Number(currentMgo))

      hsFuelPriceSupplyInternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Owner" && portOperation?.[i]?.cargoDetails?.reserve === "Supply") ? (Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedHSFO) * Number(currentHsfo)) : 0
      lsFuelPriceSupplyInternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Owner" && portOperation?.[i]?.cargoDetails?.reserve === "Supply") ? (Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedLSFO) * Number(currentLsfo)) : 0
      mgoFuelPriceSupplyInternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Owner" && portOperation?.[i]?.cargoDetails?.reserve === "Supply") ? (Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedMGO) * Number(currentMgo)) : 0

      hsFuelPriceReserveInternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Owner" && portOperation?.[i]?.cargoDetails?.reserve === "Reserve") ? (Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedHSFO) * Number(currentHsfo)) : 0
      lsFuelPriceReserveInternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Owner" && portOperation?.[i]?.cargoDetails?.reserve === "Reserve") ? (Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedLSFO) * Number(currentLsfo)) : 0
      mgoFuelPriceReserveInternalTotal += (portOperation?.[i]?.cargoDetails?.paidBy === "Owner" && portOperation?.[i]?.cargoDetails?.reserve === "Reserve") ? (Number(portOperation?.[i]?.cargoDetails?.bunkerSuppliedMGO) * Number(currentMgo)) : 0

      totalPortExpense += Number(portOperation?.[i]?.portExp)
    }
    const voyConsumptionFuelQty = {
      hsQuantity: fleetDetails?.hsQuantity,
      lsQuantity: fleetDetails?.lsQuantity,
      mgoQuantity: fleetDetails?.mgoQuantity
    }
    const supplyBalanceFuelQty = {
      hsSupplyBalance: bunkerSuppliedHSFOSupplyInternalTotal + Number(fleetDetails?.hsQuantity),
      lsSupplyBalance: bunkerSuppliedLSFOSupplyInternalTotal + Number(fleetDetails?.lsQuantity),
      mgoSupplyBalance: bunkerSuppliedMGOSupplyInternalTotal + Number(fleetDetails?.mgoQuantity)
    }
    const balanceFuelQty = {
      hsBalance: supplyBalanceFuelQty?.hsSupplyBalance + hsReserveInternalTotal,
      lsBalance: supplyBalanceFuelQty?.lsSupplyBalance + lsReserveInternalTotal,
      mgoBalance: supplyBalanceFuelQty?.mgoSupplyBalance + mgoReserveInternalTotal
    }
    //fuel price

    const voyConsumptionFuelPrice = {
      hsFuelPrice: Number(fleetDetails?.fuelHsExpense) / (voyConsumptionFuelQty?.hsQuantity || 1),
      lsFuelPrice: Number(fleetDetails?.fuelLsExpense) / (voyConsumptionFuelQty?.lsQuantity || 1),
      mgoFuelPrice: Number(fleetDetails?.fuelMgoExpense) / (voyConsumptionFuelQty?.mgoQuantity || 1),
    }
    const supplyBalanceFuelCost = {
      hsSupplyBalanceFuelCost: hsFuelPriceSupplyInternalTotal - parseInt(fleetDetails?.fuelHsExpense),
      lsSupplyBalanceFuelCost: lsFuelPriceSupplyInternalTotal - parseInt(fleetDetails?.fuelLsExpense),
      mgoSupplyBalanceFuelCost: mgoFuelPriceSupplyInternalTotal - parseInt(fleetDetails?.fuelMgoExpense)
    }

    const balanceFuelCost = {
      hsBalanceFuelCost: supplyBalanceFuelCost?.hsSupplyBalanceFuelCost + hsFuelPriceReserveInternalTotal,
      lsBalanceFuelCost: supplyBalanceFuelCost?.lsSupplyBalanceFuelCost + lsFuelPriceReserveInternalTotal,
      mgoBalanceFuelCost: supplyBalanceFuelCost?.mgoSupplyBalanceFuelCost + mgoFuelPriceReserveInternalTotal
    }

    const fuelPrice = {
      hsFuelPrice: balanceFuelCost?.hsBalanceFuelCost / balanceFuelQty?.hsBalance,
      lsFuelPrice: balanceFuelCost?.lsBalanceFuelCost / balanceFuelQty?.lsBalance,
      mgoFuelPrice: balanceFuelCost?.mgoBalanceFuelCost / balanceFuelQty?.mgoBalance,
    }

    const deliveryOutFuelCost = {
      hsDeliveryOutFuelCost: balanceFuelQty?.hsBalance * Number(fleetDetails?.vesselOut?.bnkPriceDeliveryhs),
      lsDeliveryOutFuelCost: balanceFuelQty?.lsBalance * Number(fleetDetails?.vesselOut?.bnkPriceDeliveryls),
      mgoDeliveryOutFuelCost: balanceFuelQty?.mgoBalance * Number(fleetDetails?.vesselOut?.bnkPriceDeliverymgo),
    }

    const fuelHandOverProfit = {
      hsFuelHandOverProfit: deliveryOutFuelCost?.hsDeliveryOutFuelCost - balanceFuelCost?.hsBalanceFuelCost,
      lsFuelHandOverProfit: deliveryOutFuelCost?.lsDeliveryOutFuelCost - balanceFuelCost?.lsBalanceFuelCost,
      mgoFuelHandOverProfit: deliveryOutFuelCost?.mgoDeliveryOutFuelCost - balanceFuelCost?.mgoBalanceFuelCost,
    }
    const TotalFuelHandOverProfit = fuelHandOverProfit?.hsFuelHandOverProfit + fuelHandOverProfit?.lsFuelHandOverProfit + fuelHandOverProfit?.mgoFuelHandOverProfit

    //result 

    const totalDuration = fleetDetails?.vesselOut?.DurationOut;
    const splitHire = fleetDetails?.vesselOut?.splitHire ? JSON.parse(
      fleetDetails?.vesselOut?.splitHire
    ) : [];

    let remainingDays = totalDuration;
    let splitHireAmount = 0;

    splitHire.forEach((item) => {
      if (remainingDays <= 0) return; // Stop processing if no days are left

      const daysToApply = Math.min(remainingDays, item.NoDays); // Take only the available days
      splitHireAmount += daysToApply * item.hireRate; // Calculate the split hire amount
      remainingDays -= daysToApply; // Reduce the remaining days
    });

    const balanceDaysAfterSplit = remainingDays;
    const mainHireAmount = balanceDaysAfterSplit * Number(fleetDetails?.vesselOut?.hirePerDay)

    //revenue
    const TCOGrossHire = (splitHireAmount ? splitHireAmount : 0) + (mainHireAmount ? mainHireAmount : 0) + (fleetDetails?.vesselOut?.hireGbb ? Number(fleetDetails?.vesselOut?.hireGbb) : 0)
    const TCOHireCommission = (TCOGrossHire * Number(fleetDetails?.vesselOut?.tcEq || 1)) / 100
    const miscRevenue = fleetDetails?.vesselOut?.miscRevenue ? Number(fleetDetails?.vesselOut?.miscRevenue) : 0
    const FuelSettlementProfit = fleetDetails?.fuelSettlementProfit ? Number(fleetDetails?.fuelSettlementProfit) : 0
    const FuelHandOverProfit = TotalFuelHandOverProfit
    const totalRevenue = TCOGrossHire + TCOHireCommission + miscRevenue + FuelSettlementProfit + FuelHandOverProfit
    //expense
    const fuelExpenses = fleetDetails?.fuelExpense ? Number(fleetDetails?.fuelExpense) : 0
    const hireExpenses = fleetDetails?.hireExpense ? Number(fleetDetails?.hireExpense) : 0
    const portExpenses = Number(totalPortExpense)
    const others = fleetDetails?.otherExpense ? Number(fleetDetails?.otherExpense) : 0
    const totalExpenses = fuelExpenses + hireExpenses + portExpenses + others

    //profit
    const totalDur = Number(fleetDetails?.vesselOut?.DurationOut || 0) + Number(fleetDetails?.voyageDuration || 0)

    const profit = totalRevenue - (totalExpenses < 0 ? (totalExpenses * -1) : totalExpenses)
    const profitPerDay = profit / ((totalDur < 0 ? (totalDur * -1) : totalDur) || 1)
    const voyDuration = totalDur
    const hsQty = fleetDetails?.hsQuantity
    const lsQty = fleetDetails?.lsQuantity
    const mgoQty = fleetDetails?.mgoQuantity

    const FuelHandOverProfitCount = {
      bunkerSuppliedTotal: {
        bunkerSuppliedHSFOTotal,
        bunkerSuppliedLSFOTotal,
        bunkerSuppliedMGOTotal
      },
      bunkerSuppliedInternalTotal: {
        bunkerSuppliedHSFOSupplyInternalTotal,
        bunkerSuppliedLSFOSupplyInternalTotal,
        bunkerSuppliedMGOSupplyInternalTotal
      },
      reserveInternalTotal: {
        hsReserveInternalTotal,
        lsReserveInternalTotal,
        mgoReserveInternalTotal
      },
      voyConsumptionFuelQty,
      supplyBalanceFuelQty,
      balanceFuelQty,
      voyConsumptionFuelPrice,
      supplyBalanceFuelCost,
      balanceFuelCost,
      fuelPrice,
      deliveryOutFuelCost,
      fuelHandOverProfit,
      TotalFuelHandOverProfit,
      TCOGrossHire,
      TCOHireCommission,
      miscRevenue,
      FuelSettlementProfit,
      FuelHandOverProfit,
      totalRevenue,
      fuelExpenses,
      others,
      portExpenses,
      hireExpenses,
      totalExpenses,
      profit,
      profitPerDay,
      voyDuration,
      hsQty,
      lsQty,
      mgoQty
    }


    return FuelHandOverProfitCount
  }
  const onChangePortCanalInQueryState = (portCanalChecks) => {

    setAllQueryFormat([{
      ...allQueryFormat,
      ...portCanalChecks,
    }]);
    setSelectedQuery({
      ...allQueryFormat,
      ...portCanalChecks,
    });

  };

  const onFetchPortOperationDistance = (
    reset = false,
    propCalculator = null,
    portCanalChecks = {}
  ) => {
    let updatedCalculatorData = { ...calculatorData };
    if (propCalculator) {
      updatedCalculatorData = { ...propCalculator };
    }

    const { getPortDistanceString, routeList } =
      convertPortOperationToDistanceString(updatedCalculatorData);

    const { clientQuery = {} } = updatedCalculatorData;
    const {
      eca = false,
      hra = false,
      jwc = false,
      suez = false,
      panama = false,
      singapore = false,
    } = clientQuery;
    const portDataObj = {
      eca,
      hra,
      jwc,
      suez,
      panama,
      singapore,
      routeOverride: false,

      getGeometry: false,
      ...portCanalChecks,
      routeList: routeList,
      portList: getPortDistanceString,
      clientQueryId: id,
      id: id,
    };

    let request = "";

    // change port selection the query as well
    onChangePortCanalInQueryState(portCanalChecks);

    if (reset) request = actions.resetDistance(portDataObj);
    else request = actions.getPortDistanceVesselVesselByPortList(portDataObj);
    if (!loader) setLoader(true);
    request
      .then((res) => {
        setLoader(false);
        setPortDistanceResponse(res.data);
        const newPortOperations = addVesselVesselPortDistanceToOperations(res.data, [
          ...updatedCalculatorData.portOperation,
        ]);
        sendCalculationRequest({
          ...updatedCalculatorData,
          portOperation: newPortOperations,
        });
      })
      .catch((err) => setLoader(false));
  };

  const onFetchAndSetIntitalPortDistance = (propCalculator) => {
    if (!fetchPortDistance.current) return;
    let updatedCalculatorData = { ...propCalculator };
    const { getPortDistanceString, routeList } =
      convertPortOperationToDistanceString(updatedCalculatorData);
    setLoader(true);

    return new Promise((resolve, reject) => {
      const { clientQuery = {} } = updatedCalculatorData;
      const {
        eca = false,
        hra = false,
        jwc = false,
        suez = false,
        panama = false,
        singapore = false,
      } = clientQuery;
      const portDataObj = {
        eca,
        hra,
        jwc,
        suez,
        panama,
        singapore,
        routeOverride: false,
        routeList: routeList,
        portList: getPortDistanceString,
        id: id,
        queryId: id,
        getGeometry: false,
      };
      actions
        .getPortDistanceVesselVesselByPortList(portDataObj)
        .then((res) => {
          fetchPortDistance.current = false;
          setPortDistanceResponse(res.data);
          resolve(res?.data);
        })
        .catch((err) => {
          setLoader(false);
          resolve({});
          toastError(err?.data?.message || "Something went wrong");
        });
    });
  };

  const onAddOperations = (requiredData = {}) => {
    setLoader(true);
    fetchPortDistance.current = true;
    actions
      .addCargoDetails({
        details: {
          ...initialCreatePortOperation,
          ...requiredData,
        },
        id: id,
        addIndex: openAddOperationIndex,
      })
      .then((response) => {
        const updatedSelectedQuery = {
          ...selectedQuery,
          calculationFormat: response.data.clientQuery?.calculationFormat,
        };
        setLoader(false);

        const updatedCalculatorData = {
          ...calculatorData,
          clientQuery: updatedSelectedQuery,
          portOperation: response.data?.clientQuery?.cargoList,
        };

        sendCalculationRequest(updatedCalculatorData);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onDeleteOperations = (index, cargoId) => {
    setLoader(true);
    actions
      .deleteCargoExtraDetailVesselVessel({ id: id, cargoId: cargoId })
      .then((res) => {
        setLoader(false);
        let portOperation = [...calculatorData.portOperation];
        portOperation.splice(index, 1);
        const updatedSelectedQuery = {
          ...selectedQuery,
          calculationFormat: res.data.calculationFormat,
        };

        setCalculatorData({
          ...calculatorData,
          portOperation,
          clientQuery: updatedSelectedQuery,
        });
      })
      .catch((err) => setLoader(false));
  };

  const onClickCalculate = () => {
    sendCalculationRequest({ ...calculatorData, autoSave: false });
  };
  const onClickCalculateAndSave = async () => {
    await sendCalculationRequest({ ...calculatorData, autoSave: true });
  };

  const onChangeSelectedVessel = (e, isFleetEdit = false) => {
    const {
      name = "",
      value = "",
      type = "number",
      checked = false,
    } = e.target || {};
    let newUpdatedFleet = { ...selectedVessels };
    const { fuelType = "" } = calculatorData.clientQuery || {};
    let updatedvessel = { ...JSON.parse(newUpdatedFleet.vessel) };

    if (isFleetEdit) {
      newUpdatedFleet[name] = value;
    } else {
      if (type === "checkbox") {
        updatedvessel[name] = checked;
        if (name === "vesselBalComb1Selected" || name === "vesselBalComb2Selected" || name === "vesselBalComb3Selected" || name === "vesselBalComb4Selected") {
          if (!updatedvessel["vesselBalComb1Selected"] && !updatedvessel["vesselBalComb2Selected"] && !updatedvessel["vesselBalComb3Selected"] && !updatedvessel["vesselBalComb4Selected"]) {
            return
          }
        }
        else if (name === "vesselLadComb1Selected" || name === "vesselLadComb2Selected" || name === "vesselLadComb3Selected" || name === "vesselLadComb4Selected") {
          if (!updatedvessel["vesselLadComb1Selected"] && !updatedvessel["vesselLadComb2Selected"] && !updatedvessel["vesselLadComb3Selected"] && !updatedvessel["vesselLadComb4Selected"]) {
            return
          }
        }
      } else {
        updatedvessel[name] = value;
      }
    }
    const updatedFleet = {
      ...newUpdatedFleet,
      vessel: JSON.stringify(updatedvessel),
    };
    setSelectedVessels(updatedFleet);
    const updatedFleetList = calculatorData.fleetList.map((item) => {
      if (item._id === updatedFleet._id) {
        return updatedFleet;
      }
      return item;
    });
    let updatedPortOperation = [...calculatorData.portOperation];
    if (
      name === "bnkPriceDeliveryhs" ||
      name === "bnkPriceDeliveryls" ||
      name === "bnkPriceDeliverymgo"
    ) {
      const currentType = fuelType.replace("TYPE", "");
      if (name === "bnkPriceDeliveryhs")
        updatedPortOperation[0].cargoDetails[
          `fuelPriceType${currentType}Hsfo`
        ] = value;
      if (name === "bnkPriceDeliveryls")
        updatedPortOperation[0].cargoDetails[
          `fuelPriceType${currentType}Lsfo`
        ] = value;
      if (name === "bnkPriceDeliverymgo")
        updatedPortOperation[0].cargoDetails[`fuelPriceType${currentType}Mgo`] =
          value;
    }
    if (name === "bnkDelhs" || name === "bnkDells" || name === "bnkDelmgo") {
      if (updatedPortOperation.length) {
        if (name === "bnkDelhs")
          updatedPortOperation[0].cargoDetails.bunkerSuppliedHSFO = value;
        if (name === "bnkDells")
          updatedPortOperation[0].cargoDetails.bunkerSuppliedLSFO = value;
        if (name === "bnkDelmgo")
          updatedPortOperation[0].cargoDetails.bunkerSuppliedMGO = value;
      }
    }
    setCalculatorData({
      ...calculatorData,
      portOperation: updatedPortOperation,
      fleetList: updatedFleetList,
    });
  };

  const onChangeSelectedVesselOut = (e, isFleetEdit = false) => {
    const {
      name = "",
      value = "",
      type = "number",
      checked = false,
    } = e.target || {};
    let updatedvessel = { ...selectedVessels?.vesselOut };
    const { fuelType = "" } = calculatorData.clientQuery || {};


    updatedvessel[name] = value;

    const updatedFleet = {
      ...selectedVessels,
      vesselOut: updatedvessel,
    };
    setSelectedVessels(updatedFleet);
    // const updatedFleetList = calculatorData.fleetList.map((item) => {
    //   if (item._id === updatedFleet._id) {
    //     return updatedFleet;
    //   }
    //   return item;
    // });
    // let updatedPortOperation = [...calculatorData.portOperation];
    // if (
    //   name === "bnkPriceDeliveryhs" ||
    //   name === "bnkPriceDeliveryls" ||
    //   name === "bnkPriceDeliverymgo"
    // ) {
    //   const currentType = fuelType.replace("TYPE", "");
    //   if (name === "bnkPriceDeliveryhs")
    //     updatedPortOperation[0].cargoDetails[
    //       `fuelPriceType${currentType}Hsfo`
    //     ] = value;
    //   if (name === "bnkPriceDeliveryls")
    //     updatedPortOperation[0].cargoDetails[
    //       `fuelPriceType${currentType}Lsfo`
    //     ] = value;
    //   if (name === "bnkPriceDeliverymgo")
    //     updatedPortOperation[0].cargoDetails[`fuelPriceType${currentType}Mgo`] =
    //       value;
    // }
    // if (name === "bnkDelhs" || name === "bnkDells" || name === "bnkDelmgo") {
    //   if (updatedPortOperation.length) {
    //     if (name === "bnkDelhs")
    //       updatedPortOperation[0].cargoDetails.bunkerSuppliedHSFO = value;
    //     if (name === "bnkDells")
    //       updatedPortOperation[0].cargoDetails.bunkerSuppliedLSFO = value;
    //     if (name === "bnkDelmgo")
    //       updatedPortOperation[0].cargoDetails.bunkerSuppliedMGO = value;
    //   }
    // }
    setCalculatorData({
      ...calculatorData,
      fleetList: [updatedFleet],
    });
  };
  // const onChangeSelectedFleet = (e) => {
  //     const { name = '', value = '' } = e.target || {}
  //     let updatedFleet = { ...selectedVessels }
  //     updatedFleet[name] = value
  //     setSelectedVessels(updatedFleet)
  // }

  const onRemoveOrderQuery = (id) => {
    // const queryIdMap = allQueryFormat
    //   .map((item) => item._id)
    //   .filter((item) => item._id !== id);
    // setLoader(true);
    // actions
    //   .updateQueryFormat(queryIdMap)
    //   .then((res) => {
    //     setLoader(false);
    //     if (id === selectedQuery?._id) {
    //       setSelectedQuery({});
    //     }
    //     setAllQueryFormat(allQueryFormat.filter((item) => item._id !== id));
    //   })
    actions.updateChangeSwitchQuery({
      id: id,
      isShowInCalculator: false,
    })
      .then(async (res) => {
        getInitalQueryAndFleetData();
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onDeleteFleet = (fleetId) => {
    setLoader(true);
    actions
      .deleteFleet(fleetId)
      .then((res) => {
        toastSuccess("Vessel deleted successfully");
        actions
          .allFleetCalculator()
          .then((res) => {
            setLoader(false);
            // if (Array.isArray(res)) setAllFleets(res)
            if (Array.isArray(res.data.list)) {
              sendCalculationRequest({
                ...calculatorData,
                fleetList: res.data.list,
              });
            }
          })
          .catch((err) => {
            setLoader(false);
            toastError(err?.data?.message || "Something went wrong");
          });
      })
      .catch((err) => {
        setLoader(false);
        toastError(err?.data?.message || "Something went wrong");
      });
  };

  const onChangeSyncBunker = (checked) => {
    let updatedClientQuery = { ...calculatorData.clientQuery };
    if (!checked && updatedClientQuery.fuelCalculationMethod !== "DEFAULT")
      return toastError("Please change fuel calculation method");
    // setSyncBunker(checked)

    let updatedCalculatorData = { ...calculatorData };

    let clientQuery = {
      ...updatedCalculatorData.clientQuery,
      fuelPriceOverridden: checked,
    };
    if (!checked) {
      clientQuery = {
        ...updatedCalculatorData.clientQuery,
        fuelPriceOverridden: checked,
        fuelCalculationMethod: "DEFAULT",
      };
    }

    updatedCalculatorData = {
      ...updatedCalculatorData,
      clientQuery,
    };
    setCalculatorData(updatedCalculatorData);
  };

  const onClickAddFleet = () => {
    setAddFleetModal((prev) => !prev);
  };

  const openSplitHireModal = (fleet) => {
    setSelectedSplitHire(fleet);
    setSplitHireModal(true);
  };
  const openSplitHireModalVesselOut = (fleet) => {
    setSelectedSplitHireVesselOut(fleet);
    setSplitHireModalVesselOut(true);
  };

  const addNewSplitHire = () => {
    let splitHire =
      (selectedSplitHire &&
        selectedSplitHire.splitHire &&
        JSON.parse(selectedSplitHire.splitHire)) ||
      [];
    if (!!splitHire.find((item) => item.NoDays === 0)) return;
    splitHire.push({ NoDays: 0, applied: 0, disable: false, hireRate: 0 });
    setSelectedSplitHire({
      ...selectedSplitHire,
      splitHire: JSON.stringify(splitHire),
    });
  };

  const addNewSplitHireVesselOut = () => {
    let splitHire =
      (selectedSplitHireVesselOut &&
        selectedSplitHireVesselOut.vesselOut.splitHire &&
        JSON.parse(selectedSplitHireVesselOut.vesselOut.splitHire)) ||
      [];
    if (!!splitHire.find((item) => item.NoDays === 0)) return;
    splitHire.push({ NoDays: 0, applied: 0, disable: false, hireRate: 0 });
    setSelectedSplitHireVesselOut({
      ...selectedSplitHireVesselOut,
      vesselOut: { ...selectedSplitHireVesselOut?.vesselOut, splitHire: JSON.stringify(splitHire) },
      vesselOut: { ...selectedSplitHireVesselOut?.vesselOut, splitHire: JSON.stringify(splitHire) },
    });
  };

  const onSetHideCalculator = () => {
    setHideCalculator(!hideCalculator);
  };


  const onChangeSplitHireVesselOut = (index, e, isDelete = false) => {
    let splitHire =
      (selectedSplitHireVesselOut &&
        selectedSplitHireVesselOut.vesselOut.splitHire &&
        JSON.parse(selectedSplitHireVesselOut.vesselOut.splitHire)) ||
      [];
    if (isDelete) splitHire.splice(index, 1);
    else {
      const { name = "", value = "" } = e.target;
      splitHire[index][name] = Number(value);
    }
    const udpatedSelectedSplitHire = {
      ...selectedSplitHireVesselOut,
      vesselOut: { ...selectedSplitHireVesselOut?.vesselOut, splitHire: JSON.stringify(splitHire) },
    };
    setSelectedSplitHireVesselOut(udpatedSelectedSplitHire);

    setCalculatorData({
      ...calculatorData,
      fleetList: calculatorData.fleetList.map((item) => {
        if (item._id === udpatedSelectedSplitHire._id) {
          return udpatedSelectedSplitHire;
        }
        return item;
      }),
    });
    if (udpatedSelectedSplitHire._id === selectedVessels._id)
      setSelectedVessels(udpatedSelectedSplitHire);
  };
  const onChangeSplitHire = (index, e, isDelete = false) => {
    let splitHire =
      (selectedSplitHire &&
        selectedSplitHire.splitHire &&
        JSON.parse(selectedSplitHire.splitHire)) ||
      [];
    if (isDelete) splitHire.splice(index, 1);
    else {
      const { name = "", value = "" } = e.target;
      splitHire[index][name] = Number(value);
    }
    const udpatedSelectedSplitHire = {
      ...selectedSplitHire,
      splitHire: JSON.stringify(splitHire),
    };
    setSelectedSplitHire(udpatedSelectedSplitHire);

    setCalculatorData({
      ...calculatorData,
      fleetList: calculatorData.fleetList.map((item) => {
        if (item._id === udpatedSelectedSplitHire._id) {
          return udpatedSelectedSplitHire;
        }
        return item;
      }),
    });
    if (udpatedSelectedSplitHire._id === selectedVessels._id)
      setSelectedVessels(udpatedSelectedSplitHire);
  };

  const onChangeOverride = (value) => {
    if (!value) {
      const {
        clientQuery = {},
        portOperation,
        fleetList,
      } = { ...calculatorData } || {};
      let {
        fuelCalculationMethod = "",
        fuelType = "TYPE0",
        lsFuelPriceType0 = 0,
        lsFuelPriceType1 = 0,
        lsFuelPriceType2 = 0,
        lsFuelPriceType3 = 0,
        lsFuelPriceType4 = 0,
        hsFuelPriceType0 = 0,
        hsFuelPriceType1 = 0,
        hsFuelPriceType2 = 0,
        hsFuelPriceType3 = 0,
        hsFuelPriceType4 = 0,
        mgoFuelPriceType0 = 0,
        mgoFuelPriceType1 = 0,
        mgoFuelPriceType2 = 0,
        mgoFuelPriceType3 = 0,
        mgoFuelPriceType4 = 0,
      } = clientQuery || {};

      let fuelTypePrices = {
        TYPE0: {
          normal: hsFuelPriceType0,
          eca: lsFuelPriceType0,
          mgo: mgoFuelPriceType0,
        },
        TYPE1: {
          normal: hsFuelPriceType1,
          eca: lsFuelPriceType1,
          mgo: mgoFuelPriceType1,
        },
        TYPE2: {
          normal: hsFuelPriceType2,
          eca: lsFuelPriceType2,
          mgo: mgoFuelPriceType2,
        },
        TYPE3: {
          normal: hsFuelPriceType3,
          eca: lsFuelPriceType3,
          mgo: mgoFuelPriceType3,
        },
        TYPE4: {
          normal: hsFuelPriceType4,
          eca: lsFuelPriceType4,
          mgo: mgoFuelPriceType4,
        },
      };

      const selectedFuelPrice = fuelTypePrices[fuelType];
      const newPortOperation = [...portOperation];
      const currentType = fuelType.replace("TYPE", "");
      newPortOperation[0].cargoDetails[`fuelPriceType${currentType}Hsfo`] =
        selectedFuelPrice.normal;
      newPortOperation[0].cargoDetails[`fuelPriceType${currentType}Lsfo`] =
        selectedFuelPrice.eca;
      newPortOperation[0].cargoDetails[`fuelPriceType${currentType}Mgo`] =
        selectedFuelPrice.mgo;

      let newSelectedVessel = {
        ...selectedVessels,
        bunkerPriceOverridden: false,
        bnkPriceDeliveryhs: selectedFuelPrice.normal,
        bnkPriceDeliveryls: selectedFuelPrice.eca,
        bnkPriceDeliverymgo: selectedFuelPrice.mgo,
        bnkPriceRedeliveryhs: selectedFuelPrice.normal,
        bnkPriceRedeliveryls: selectedFuelPrice.eca,
        bnkPriceRedeliverymgo: selectedFuelPrice.mgo,
        bnkPriceSettlemenths: selectedFuelPrice.normal,
        bnkPriceSettlementls: selectedFuelPrice.eca,
        bnkPriceSettlementmgo: selectedFuelPrice.mgo,
      };

      let updatedFleets = fleetList.map((item) => {
        if (item._id === newSelectedVessel._id) return newSelectedVessel;
        return item;
      });
      setSelectedVessels(newSelectedVessel);
      setCalculatorData({
        ...calculatorData,
        portOperation: newPortOperation,
        fleetList: updatedFleets,
      });
    } else {
      const { fleetList } = { ...calculatorData } || {};
      let newSelectedVessel = {
        ...selectedVessels,
        bunkerPriceOverridden: true,
      };

      let updatedFleets = fleetList.map((item) => {
        if (item._id === newSelectedVessel._id) return newSelectedVessel;
        return item;
      });
      setSelectedVessels(newSelectedVessel);
      setCalculatorData({ ...calculatorData, fleetList: updatedFleets });
    }
    setIsOverride(value);
  };

  const onSetOpenMap = () => {
    if (selectedQuery?._id) {
      window.open(`/map/${selectedQuery?._id}`);
    }
  };

  return (
    <div className="calculator-section flex flex-col w-full">
      {/* first UI part orders selector */}
      <div className="flex flex-row">
        {/* header */}
        {/* {hideOrder ? null : ( */}

        <div
          className={clsx("flex flex-col main-content-wrapper-inner w-full", {
            // "w-full": hideOrder,
            // "w-[87%]": !hideOrder,
          })}
        >
          <div className="quick-menu-bar">
            <ActionButtons
              hideOrder={hideOrder}
              setHideOrder={setHideOrder}
              onSetOpenMap={onSetOpenMap}
              onClickCalculate={onClickCalculate}
              onSetHideCalculator={onSetHideCalculator}
              onClickCalculateAndSave={onClickCalculateAndSave}
              isFromSingleVesselCalcy
              isPublic={isPublic}
            />
          </div>
          {/* secound UI part */}
          <div>
            {!id ? (
              <div className="h-100">
                <div className="flex">
                  <p className="m-a text-xl">Please select an id.</p>
                </div>
              </div>
            ) : (
              <div className="calc-secound-section p-2 pt-1">
                {/* <div>
                  <div className="bottom-section-grid">
                    <VesselSelection
                      selectedVessels={selectedVessels}
                      onFetchPortOperationDistance={
                        onFetchPortOperationDistance
                      }
                      selectedQuery={selectedQuery}
                      onChangeFleetRowSelect={onChangeFleetRowSelect}
                      onDeleteFleet={onDeleteFleet}
                      onClickAddFleet={onClickAddFleet}
                      addFleetModal={addFleetModal}
                      addFleetsArray={addFleetsArray}
                      setAddFleetsArray={setAddFleetsArray}
                      calculatorData={calculatorData}
                      setCalculatorData={setCalculatorData}
                      setLoader={setLoader}
                      setAddFleetModal={setAddFleetModal}
                      sendCalculationRequest={sendCalculationRequest}
                      setSelectedVessels={setSelectedVessels}
                      splitHireModal={splitHireModal}
                      setSplitHireModal={setSplitHireModal}
                      selectedSplitHire={selectedSplitHire}
                      setSelectedSplitHire={setSelectedSplitHire}
                      openSplitHireModal={openSplitHireModal}
                      getInitalQueryAndFleetData={getInitalQueryAndFleetData}
                      initialFleetList={initialFleetList}

                      onChangeSelectedVessel={onChangeSelectedVessel}
                      fleetCheckbox={fleetCheckbox}
                      setFleetCheckbox={setFleetCheckbox}

                      setMarginModal={setMarginModal}
                      syncBunker={syncBunker}
                      setSyncBunker={setSyncBunker}
                      setSupplyModal={setSupplyModal}
                      onChangeSyncBunker={onChangeSyncBunker}
                      isOverride={isOverride}

                      calculationErrors={calculationErrors}


                      marginModal={marginModal}
                      onChangePortOperations={onChangePortOperations}
                      onAddOperations={onAddOperations}
                      onDeleteOperations={onDeleteOperations}
                      supplyModal={supplyModal}
                      fetchPortDistance={fetchPortDistance}
                      openAddOperation={openAddOperation}
                      setOpenAddOperation={setOpenAddOperation}
                      setOpenAddOperationindex={setOpenAddOperationindex}
                      onChangePortCanalInQueryState={onChangePortCanalInQueryState}
                      setIsOverride={onChangeOverride}

                      hideOrder={hideOrder}
                      setHideOrder={setHideOrder}
                      hideCalculator={hideCalculator}
                      getCurrentResult={getCurrentResult}
                    />
                  </div>
                </div> */}
                <div className="flex flex-col gap-2">
                  {hideCalculator ? null : (
                    <div className="grid grid-cols-6 gap-3 all-content-tables">

                      <div className="upper-section-grid col-span-2">
                        <CalculatorVesselDetails
                          setLoader={setLoader}
                          setSelectedVessels={setSelectedVessels}
                          selectedVessels={selectedVessels}
                          calculatorData={calculatorData}
                          setCalculatorData={setCalculatorData}
                          onChangeHandler={onChangeSelectedVessel}
                          fleetCheckbox={fleetCheckbox}
                          setFleetCheckbox={setFleetCheckbox}
                          disableEdit
                        />
                      </div>

                      <div className="upper-section-grid">
                        <MarginAndAllowances
                          onChangeSelectedVessel={onChangeSelectedVessel}
                          selectedVessels={selectedVessels}
                          setSelectedVessels={setSelectedVessels}
                          setMarginModal={setMarginModal}
                          calculatorData={calculatorData}
                          setCalculatorData={setCalculatorData}
                          syncBunker={syncBunker}
                          setSyncBunker={setSyncBunker}
                          setSupplyModal={setSupplyModal}
                          onChangeSyncBunker={onChangeSyncBunker}
                          isOverride={isOverride}
                          // isFromSingleVesselCalcy
                          isFromVesselVessel
                        />
                      </div>

                      <div className="upper-section-grid">
                        <VesselVesselDeliveryOut
                          onChangeSelectedVessel={onChangeSelectedVessel}
                          selectedVessels={selectedVessels}
                          openSplitHireModal={openSplitHireModal}
                          calculatorData={calculatorData}
                          setCalculatorData={setCalculatorData}
                          addNewSplitHire={addNewSplitHire}
                          onChangeSplitHire={onChangeSplitHire}
                          isOverride={isOverride}
                          // setIsOverride={setIsOverride}
                          isFromSingleVesselCalcy
                          label="Vessel-in"
                        />
                      </div>

                      <div className="upper-section-grid">
                        <VesselVesselReDeliveryIn
                          onChangeSelectedVessel={onChangeSelectedVesselOut}
                          selectedVessels={selectedVessels}
                          openSplitHireModal={openSplitHireModalVesselOut}
                          calculatorData={calculatorData}
                          setCalculatorData={setCalculatorData}
                          addNewSplitHire={addNewSplitHire}
                          onChangeSplitHire={onChangeSplitHire}
                          isOverride={isOverride}
                          // setIsOverride={setIsOverride}
                          isFromSingleVesselCalcy
                          label="Vessel-out"

                        />
                      </div>
                      <div className="upper-section-grid">
                        <VesselVesselResult
                          selectedVessels={selectedVessels}
                          calculationErrors={calculationErrors}
                          label="Revenue"
                          fields={vesselVesselRevenueResultsFields}
                        />
                        <VesselVesselResult
                          selectedVessels={selectedVessels}
                          calculationErrors={calculationErrors}
                          label="Expense"
                          fields={vesselVesselExpenseResultsFields}
                        />
                        <VesselVesselResult
                          selectedVessels={selectedVessels}
                          calculationErrors={calculationErrors}
                          label="Result"
                          fields={vesselVesselResultsFields}
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    {calculatorData && <div className="middle-section-grid">
                      <VesselVesselPortOperations
                        marginModal={marginModal}
                        setMarginModal={setMarginModal}
                        sendCalculationRequest={sendCalculationRequest}
                        onChangePortOperations={onChangePortOperations}
                        calculatorData={calculatorData}
                        setCalculatorData={setCalculatorData}
                        syncBunker={syncBunker}
                        setSyncBunker={setSyncBunker}
                        onAddOperations={onAddOperations}
                        onDeleteOperations={onDeleteOperations}
                        selectedVessels={selectedVessels}
                        supplyModal={supplyModal}
                        setSupplyModal={setSupplyModal}
                        onChangeSyncBunker={onChangeSyncBunker}
                        onFetchPortOperationDistance={
                          onFetchPortOperationDistance
                        }
                        fetchPortDistance={fetchPortDistance}
                        openAddOperation={openAddOperation}
                        setOpenAddOperation={setOpenAddOperation}
                        setOpenAddOperationindex={setOpenAddOperationindex}
                        onChangeSelectedVessel={onChangeSelectedVessel}
                        isOverride={isOverride}
                        onChangePortCanalInQueryState={
                          onChangePortCanalInQueryState
                        }
                        setIsOverride={onChangeOverride}
                      />
                    </div>}
                  </div>


                </div>
              </div>
            )}
          </div>
          {/* <CustomShowModal
                open={openMap}
                handleClose={onSetOpenMap}
                maxWidth="lg"
                title="Map View"
            >
                <CalculatorPortMapView
                    calculatorData={calculatorData}
                />
            </CustomShowModal> */}
          {/* <CustomShowModal
                open={openResult}
                handleClose={onSetOpenResult}
                maxWidth="lg"
                title="Load Saved Result"
            >
                <LoadSavedResults setLoader={setLoader} />
            </CustomShowModal> */}
          <CustomShowModal
            open={splitHireModal}
            handleClose={() => setSplitHireModal(false)}
            maxWidth="xs"
            title="Split Hire"
          >
            <SplitHireModal
              addNewSplitHire={addNewSplitHire}
              selectedSplitHire={selectedSplitHire}
              onChangeSplitHire={onChangeSplitHire}
            />
          </CustomShowModal>
          <CustomShowModal
            open={splitHireModalVesselOut}
            handleClose={() => setSplitHireModalVesselOut(false)}
            maxWidth="xs"
            title="Split Hire"
          >
            <SplitHireModal
              addNewSplitHire={addNewSplitHireVesselOut}
              selectedSplitHire={selectedSplitHireVesselOut?.vesselOut}
              onChangeSplitHire={onChangeSplitHireVesselOut}
            />
          </CustomShowModal>
        </div>{" "}
      </div>
    </div>
  );
}

export default memo(VesselVesselMain);
