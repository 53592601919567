import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";

const CssTextField = withStyles({
  root: {
    // background: "transparent",
    // fontFamily: "'DM Sans', sans-serif",
    color: "#fff",
    width: "100%",
    "& label": {
      // fontFamily: "'DM Sans', sans-serif",
      // color: '#10baa8',
      color: "#3f51b5",
    },
    "& label.Mui-focused": {
      // color: '#10baa8',
      color: "#3f51b5",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e1e1e1",
      },
      "&:hover fieldset": {
        borderColor: "#e1e1e1",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e1e1e1",
        borderWidth: 1,
      },
    },
  },
})(TextField);

function CustomTextField(props) {
  const {
    inputType = "text",
    name = "",
    value = "",
    error = false,
    errorMsg = "",
    onChange = () => {},
    ...others
  } = props;
  switch (inputType) {
    case "text":
      return (
        <div style={{ position: "relative", width: "100%" }}>
          <CssTextField
            name={name}
            value={value}
            error={error}
            onChange={onChange}
            variant="outlined"
            fullWidth
            {...others}
          />
          {error ? <span className="error-messages">{errorMsg}</span> : null}
        </div>
      );
    case "password":
      return (
        <div style={{ position: "relative", width: "100%" }}>
          <CssTextField
            type="password"
            name={name}
            value={value}
            error={error}
            onChange={onChange}
            variant="outlined"
            fullWidth
            {...others}
          />
          {error ? <span className="error-messages">{errorMsg}</span> : null}
        </div>
      );
    case "number":
      return (
        <div style={{ position: "relative", width: "100%" }}>
          <CssTextField
            name={name}
            type="number"
            value={value}
            error={error}
            onChange={onChange}
            variant="outlined"
            fullWidth
            {...others}
          />
          {error ? <span className="error-messages">{errorMsg}</span> : null}
        </div>
      );
    default:
      return <TextField />;
  }
}

export default CustomTextField;
