import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  getCurrentUserId,
} from "../../../utils";


export function getVesselVesselDetailsAPI(data) {
  return apiGet(`/api/vesselVessel/getVesselVesselDetails`, data);
}


export function getAllVesselVesselDetailsAPI(data) {
  return apiGet(`/api/vesselVessel/getAllVesselVesselDetails`, data);
}

export function addTeamVesselVesselDetailsAPI(data) {
  return apiPost(`/api/vesselVessel/addTeamVesselVesselDetails`, data);
}

export function getSingleVesselVesselDetailsAPI(id, data) {
  return apiGet(`/api/vesselVessel/getVesselVesselDetail/${id}`, data);
}
export function addCargoDetailsAPI(data) {
  return apiPost(
    `/api/vesselVessel/addCargo/${data?.id}`,
    data
  );
}

export function getPortDistanceVesselVesselByPortListAPI(portData) {
  // const data = {
  //     portList,
  //     userId: getCurrentUserId()
  // }
  // return apiGet(`/api/v1/getPortDistance`, data)
  const data = {
    ...portData,
    userId: getCurrentUserId(),
  };
  return apiPost(`/api/portDistance/calculate/portDistanceVesselVessel`, data);
}

export function updateVesselVesselDetailsAPI(id, data) {
  return apiPut(`/api/vesselVessel/updateDetails/${id}`, data);
}

export function deleteCargoExtraDetailVesselVesselAPI(data) {
  return apiDelete(`/api/vesselVessel/deleteCargoExtraDetail/${data.id}/${data.cargoId}`);
}


export function savePortDistanceAPI(data) {
  return apiPost(`/api/v1/savePortDistance`, {
    ...data,
    userId: getCurrentUserId(),
  });
}


export function sendCalculatorDataAPI(data) {
  return apiPost(`/api/clientQuery/calculate/cargoPrice`, data);
}

export function resetDistanceAPI(portData) {
  const data = {
    ...portData,
    userId: getCurrentUserId(),
  };
  return apiGet(`/api/portDistance/calculate/resetDistance`, data);
}


export function updateChangeSwitchQueryAPI(data) {
  return apiPut(`/api/clientQuery/switch/${data.id}`, { isShowInCalculator: data.isShowInCalculator });
}


export function deleteFleetAPI(id) {
  return apiDelete(`/api/fleet/${id}`);
}

export function allFleetCalculatorAPI() {
  return apiGet(`/api/fleet/getAllFleetForCacl`);
}

export function copyToMyVesselVesselDetailsAPI(data) {
  return apiPost(`/api/vesselVessel/copyToMyVesselVesselDetails`, data);
}

export function deleteVesselVesselAPI(id) {
  return apiDelete(`/api/vesselVessel/deleteVesselVessel/${id}`);
}
