import React from "react";
import { userDrawerList } from "../../../constants";
import { getRolePermisionRoutes } from "../../../utils";
import { useHistory } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";
import welcomeImage from "../../../assets/images/welcomeImage.webp";
import CustomButton from "../../../components/Common/CustomButton";

function Welcome() {
  const history = useHistory();

  const validRoutes = userDrawerList.filter((item) => {
    return getRolePermisionRoutes().includes(item.url);
  });

  const goToRoute = (path) => {
    history.push(path);
  };

  return (
    <div>
      <div className="flex justify-between flex-col lg:flex-row gap-5">
        <div className="md:w-2/3 lg:w-1/2 flex flex-col justify-between">
          <div>
            <BsStars className="w-6 h-6 text-blue" />
            <h2 className="my-6 typo-24-400 text-gray-700 md:typo-60-800 leading-[70px]">
              This is a smart ship chartering platform
            </h2>
            <p className="text-gray-600 max-w-[560px]">
              Efficiency is the key to thrive in a competitive market. This
              application will assist ship chartering professionals to achieve
              their best by complete automation and renewed workflow.
            </p>
          </div>
          <CustomButton
            onClick={() => goToRoute(validRoutes[0].url)}
            className="bg-blue py-6 rounded-full w-52 mt-2"
          >
            Get Started
          </CustomButton>
        </div>
        <img
          src={welcomeImage}
          alt="welcomeImage"
          className="h-96 rounded-xl shadow-md"
        />
      </div>
      <div className="mt-16">
        <div className="typo-36-700 mb-6 text-gray-700">Instructions</div>
        <div className="welcome-notes bg-neutrals6 px-10 py-5 rounded-xl">
          <ul className="list-outside list-disc">
            <li className="font-semibold">
              IMO 2020 has categorised distances into two types : Distance
              Normal (0.5% Compliant) and Distance ECA (0.1% compliant).
            </li>
            <li>
              {" "}
              In our scheme of calculations, the fuel consumed for 0.5%
              (Distance Normal) is called HS Fuel; and that for 0.1% (Distance
              ECA) is called LS Fuel. Accordingly, Vessel consumption has been
              defined for any speed as HS/LS/MGO.{" "}
            </li>
            <li>
              {" "}
              While entering fuel prices, the user should enter HS Price, LS
              Price and MGO Price:: Here HS Price will be used for all Distance
              Normal, LS Price will be used for all Distance ECA, MGO Price for
              any light fuel needed on board.
            </li>
            <li>
              Similarly for Port Consumptions:: HS fuel in Normal Ports, LS Fuel
              for ECA ports, MGO fuel always. Thus any one of HS or LS fuel is
              consumed and MGO is always consumed as per the vessel description.
            </li>
            <li>
              {" "}
              Depending on types of fuels consumed, users have option to
              categorise vessels into 5-types (Fuel Price Combinations):{" "}
            </li>
            <div className="ml-5">
              <p> 1. Type 1 = Non-Scrubber</p>
              <p> 2. Type 2 = Scrubber Fitted vessels </p>
              <p> 3. Type 3 = LNG vessels </p>
              <p> 4. Type 4 = Custom user defined </p>
              <p> 5. Type 5 = Custom user defined </p>
            </div>
            <li>
              {" "}
              Please ensure vessel description is defined with HS, LS and MGO
              Consumptions accordingly.
            </li>
            <li>
              If vessel uses same fuel for HS and LS, ensure fuel prices for HS
              and LS are same for her.{" "}
            </li>
            <li>
              Please ensure the fuel prices are entered as per vessel type
              selected.
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-16 grid divide-x divide-y divide-gray-100 overflow-hidden rounded-3xl border border-gray-100 text-gray-600 sm:grid-cols-2 lg:grid-cols-4 lg:divide-y-0 xl:grid-cols-4">
        {validRoutes.map((routes, index) => {
          return (
            <div
              className="group relative bg-white transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10"
              key={index}
            >
              <div className="relative space-y-8 py-12 p-8 flex flex-col">
                <img
                  src={routes.blackImg}
                  className="w-12 flex self-center"
                  width="512"
                  height="512"
                  alt="burger illustration"
                />

                <div className="space-y-2">
                  <h5 className="typo-20-700 text-gray-700 transition group-hover:text-blue">
                    {routes.label}
                  </h5>
                  <p className="text-gray-600">
                    {routes?.description ||
                      "Neque Dolor, fugiat non cum doloribus aperiam voluptates nostrum."}
                  </p>
                </div>
                <div
                  className="flex items-center justify-between group-hover:text-blue cursor-pointer"
                  onClick={() => goToRoute(routes.url)}
                >
                  <span className="typo-14-400">Redirect To Page</span>
                  <FaArrowRight className="w-5 h-5 -translate-x-4 typo-24-400 opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Welcome;
