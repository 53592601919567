import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import userManagmentLogo from "../../../assets/images/user.png";
// import vesselInformation from "../../../assets/images/vesselicon1.png";
// import organizationIcon from "../../../assets/images/organization.png";
import { isAdminLogin, isSuperAdminLogin } from "../../../utils";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { GiCargoShip } from "react-icons/gi";
import { FaCircleUser } from "react-icons/fa6";
import { GrOrganization } from "react-icons/gr";

let initialOptions = [
  {
    label: "SETUP ORGANIZATION",
    url: "/organization",
    svg: <GrOrganization />,
  },
  {
    label: "USER MANAGEMENT FOR ORGANIZATION",
    url: "/userOrganization",
    svg: <FaCircleUser />,
  },
  {
    label: "VESSEL INFORMATION",
    url: "/vesselInformation",
    svg: <GiCargoShip />,
  },
];

export default function Dashboard() {
  const history = useHistory();
  const [dashboardOperations, setDashboardOperations] = useState([
    ...initialOptions,
  ]);

  useEffect(() => {
    if (!isSuperAdminLogin()) {
      let newDashboardOperations = dashboardOperations.filter(
        (item) => item.url !== "/vesselInformation"
      );
      setDashboardOperations(newDashboardOperations);
    }
  }, []);

  return (
    <div className="dashboard-container">
      <div className="flex gap-2 items-center">
        <MdOutlineAdminPanelSettings className="text-3xl page-name" />
        <div className="typo-30-500">Admin Dashboard</div>
      </div>
      <div className="flex flex-wrap gap-5 mt-3">
        {dashboardOperations.map((item, index) => {
          const moreItems = dashboardOperations.length > 2;
          return (
            <div key={index} className="h-full">
              <div className="w-96 h-44">
                <div
                  onClick={() => history.push(item.url)}
                  className="group click-on-paper h-full bg-neutrals9 rounded-md hover:bg-neutral-50 flex items-center justify-center flex-col"
                >
                  <div className="p-0 typo-54-400 page-name group-hover:text-blue ">
                    {item.svg}
                  </div>
                  <p className="dashboard-label typo-14-600 text-neutrals4">
                    {item.label}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
