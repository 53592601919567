import React, { useState } from "react";
import { InputBase, makeStyles, Dialog, DialogTitle, DialogContent, Typography, IconButton } from "@material-ui/core";
import CalendarMonthIcon from "@material-ui/icons/CalendarTodayTwoTone";
import CloseIcon from "@material-ui/icons/Close";
import { DateRangePicker } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const useStyles = makeStyles((theme) => ({
    searchContainer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#ffffff",
        border: `1px solid #3f51b5`,
        borderRadius: "8px",
        padding: "4px 8px",
        boxShadow: "0px 4px 6px rgba(63, 81, 181, 0.1)",
        zIndex: 9999,
        transition: "border-color 0.2s ease",
        "&:focus-within": {
            border: `2px solid #3f51b5`,
            boxShadow: "0 0 0 3px rgba(63, 81, 181, 0.2)",
        }
    },
    input: {
        fontSize: "1rem",
        color: "#3f51b5",
        width: "500px",
        outline: "none",
        border: "none",
        "&:focus": {
            outline: "none",
        }
    },
    inputMobile: {
        fontSize: "1rem",
        color: "#3f51b5",
        width: "200px",
        outline: "none",
        border: "none",
        "&:focus": {
            outline: "none",
        }
    },
    calendarIcon: {
        color: "#3f51b5",
        cursor: "pointer",
        fontSize: "24px",
        marginLeft: "8px",
        transition: "transform 0.2s ease",
        "&:hover": {
            transform: "scale(1.1)",
        }
    },
    dateDisplay: {
        marginLeft: "8px",
        color: "#3f51b5",
        fontSize: "0.9rem",
        display: "flex",
        alignItems: "center",
    },
    dialogPaper: {
        backgroundColor: "#f0f4f8",
        borderRadius: "12px",
    },
    resetButton: {
        marginLeft: "8px",
        color: "#f44336", // Red color for the reset button
    },
    dialogTitle: {
        backgroundColor: "#3f51b5",
        color: "white",
        textAlign: "center",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
    },
    dateRangePicker: {
        "& .rdrDefinedRangesWrapper": {
            backgroundColor: "#f0f4f8",
        },
        " & .rdrDateDisplayItemActive": {
            borderColor: "#3f51b5",
        },
        "& .rdrMonth": {
            backgroundColor: "white",
        },
        "& .rdrDay": {
            "&:hover": {
                backgroundColor: "rgba(6, 25, 95, 0.1)",
            },
        },
        "& .rdrDayNumber": {
            color: "rgb(63, 81, 181)",
        },
        "& .rdrSelected, & .rdrInRange": {
            backgroundColor: "rgb(63, 81, 181) !important",
            color: "white !important",
        },
        "& .rdrStartEdge, & .rdrEndEdge": {
            backgroundColor: "rgb(63, 81, 181) !important",
            color: "white !important",
        },
    }
}));

function SearchBar(props) {
    const classes = useStyles();
    const {
        placeholder = "Search...",
        setSearchValue = () => { },
        searchValue,
        isMobile = false,
        onDateRangeChange = () => { },
        locale = 'enUS' // default locale
    } = props;

    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [dateRange, setDateRange] = useState([{
        startDate: null,
        endDate: null,
        key: 'selection'
    }]);
    const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);

    const handleDateRangeSelect = (ranges) => {
        const selectedRange = ranges.selection;

        // Update the dateRange state
        setDateRange([selectedRange]);

        // Check if both start and end dates are selected
        if (selectedRange.startDate && selectedRange.endDate !== selectedRange.startDate) {
            setIsDateRangeSelected(true);

            // Ensure the callback function is triggered with the correct date range
            onDateRangeChange({
                startDate: selectedRange.startDate,
                endDate: selectedRange.endDate
            });

            setOpenDatePicker(false);
        }
    };

    // Format date to a readable string
    const formatDate = (date) => {
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const handleDatePicker = () => {
        const shouldSetNewDateRange = 
            !dateRange || 
            !dateRange[0]?.startDate;
    
        if (shouldSetNewDateRange) {
            const newDateRange = [{
                startDate: new Date(),
                endDate: null,
                key: 'selection'
            }];
            setDateRange(newDateRange);
        }
        
        setOpenDatePicker(true);
    };

    // Reset function
    const handleReset = () => {
        // Reset date range to blank
        const newDateRange = [{
            startDate: null,
            endDate: null,
            key: 'selection'
        }];

        setDateRange(newDateRange);
        setIsDateRangeSelected(false);
        onDateRangeChange({ startDate: null, endDate: null });
    };


    return (
        <div>
            <div className={classes.searchContainer}>
                <InputBase
                    placeholder={placeholder}
                    value={searchValue}
                    onChange={setSearchValue}
                    classes={{ input: isMobile ? classes.inputMobile : classes.input }}
                    inputProps={{ "aria-label": "search" }}
                />
                <CalendarMonthIcon
                    className={classes.calendarIcon}
                    onClick={handleDatePicker}
                />
                {isDateRangeSelected && dateRange[0].startDate && dateRange[0].endDate && (
                    <Typography className={classes.dateDisplay}>
                        {formatDate(dateRange[0].startDate)} - {formatDate(dateRange[0].endDate)}
                    </Typography>
                )}
                {isDateRangeSelected && (
                    <IconButton className={classes.resetButton} onClick={handleReset}>
                        <CloseIcon />
                    </IconButton>
                )}
            </div>

            <Dialog
                open={openDatePicker}
                onClose={() => setOpenDatePicker(false)}
                maxWidth="md"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle className={classes.dialogTitle}>Select Date Range</DialogTitle>
                <DialogContent>
                    <DateRangePicker
                        className={classes.dateRangePicker}
                        onChange={handleDateRangeSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        locale={locales[locale]}
                        months={1}
                        direction="horizontal"
                        showSelectionPreview={true}
                        editableDateInputs={true}
                        dragSelectionEnabled={true}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default SearchBar;
