import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import * as Components from "./containers";
import { getRolePermisionRoutes, isLoggedIn } from "./utils";
import App from "./app/App";
import OAuth2RedirectHandler from "./user/oauth2/OAuth2RedirectHandler";

const UserRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        let isValidRoute = getRolePermisionRoutes().includes(rest.path);

        if (rest.path === "/welcome") {
          isValidRoute = true;
        }
        console.log(isValidRoute, "isValidRoute");
        const secoundaryRoute = getRolePermisionRoutes()[0];
        return isLoggedIn() ? (
          isValidRoute ? (
            <Components.UserMain {...props} {...rest}>
              <Component {...props} />
            </Components.UserMain>
          ) : (
            <Components.UserMain {...props} {...rest}>
              <Redirect to={secoundaryRoute} />
            </Components.UserMain>
          )
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};

function Routes() {
  return (
    <Router>
      <Switch>
        <UserRoute
          exact
          path="/"
          component={Components.Dashboard}
          routeName="Admin Dashboard"
        />
         <UserRoute
          exact
          path="/dashboard"
          component={Components.UserDashboard}
          routeName="Dashboard"
        />
        <UserRoute
          exact
          path="/welcome"
          component={Components.Welcome}
          routeName="Welcome"
        />
        <UserRoute
          exact
          path="/userOrganization"
          component={Components.UserMgntOrg}
          routeName="User Managment Organization"
        />
         <UserRoute
          exact
          path="/addressBook"
          component={Components.AddressBook}
          routeName="Address Book"
        />
         <UserRoute
          exact
          path="/cargo-cargo-matrix"
          component={Components.CargoCargoMatrix}
          routeName="Cargo Cargo Matrix"
        />
        <UserRoute
          exact
          path="/cargo-analysis"
          component={Components.CargoAnalysis}
          routeName="Cargo Analysis"
        />
         <UserRoute
          exact
          path="/vessel-vessel-matrix"
          component={Components.VesselVesselMatrix}
          routeName="Vessel Vessel Matrix"
        />
         <UserRoute
          exact
          path="/vessel-vessel/matrix/:id"
          component={Components.VesselVesselMatrixMain}
          routeName="Vessel Vessel Matrix"
        />
         <UserRoute
          exact
          path="/cargo-analysis/matrix/:id"
          component={Components.CargoAnalysisMatrix}
          routeName="Cargo Analysis Matrix"
        />
         <UserRoute
          exact
          path="/distance-matrix"
          component={Components.DistanceMatrix}
          routeName="Distance Matrix"
        />
        
         <UserRoute
          exact
          path="/cargo-cargo/matrix/:id"
          component={Components.CargoCargoMatrixMain}
          routeName="Cargo Cargo Matrix"
        />
         <UserRoute
          exact
          path="/support"
          component={Components.Support}
          routeName="Support"
        />
         <UserRoute
          exact
          path="/supportinformation"
          component={Components.Supportinformation}
          routeName="Support"
        />
        <UserRoute
          exact
          path="/vesselInformation"
          component={Components.VesselInformation}
          routeName="Vessel Information"
        />
        <UserRoute
          exact
          path="/organization"
          component={Components.SetupOrganization}
          routeName="Setup Organization"
        />
        <UserRoute
          exact
          path="/orders"
          component={Components.Orders}
          routeName="Orders"
        />
        <UserRoute
          exact
          path="/voyages"
          component={Components.Voyages}
          routeName="Voyages"
        />
        <UserRoute
          exact
          path="/calculator"
          component={Components.Calculator}
          routeName="Matrix"
        />
        <UserRoute
          exact
          path="/emailParser"
          component={Components.EmailParser}
          routeName="Email Parser"
        />
        <UserRoute
          exact
          path="/vessel/:id"
          component={Components.SingleVessel}
          routeName="Vessel Information"
        />
        <UserRoute
          exact
          path="/saved/:id"
          // component={Components.SingleSavedResult}
          component={Components.SingleVesselCalcy}
          routeName="Saved Results"
        />
        {/* <UserRoute
          exact
          path="/singlevessel/:queryid/calculater/:vesselid"
          component={Components.SingleVesselCalcy}
          routeName="Single Vessel Cargo Calculator"
        /> */}
        <UserRoute
          exact
          path="/saved"
          component={Components.SavedResults}
          routeName="Saved Results"
        />
        <UserRoute
          exact
          path="/map/:id"
          component={Components.ShowMap}
          routeName="Map"
        />
        <UserRoute
          exact
          path="/vesselManager"
          component={Components.Fleet}
          routeName="Vessel Manager"
        />
        <PublicRoute exact path="/login" component={Components.Auth} />
        <PublicRoute
          exact
          path="/forgotPassword"
          component={Components.ForgotPassword}
        />
        <PublicRoute exact path="/signUp" component={Components.Signup} />
        <PublicRoute
          exact
          path="/resetPassword"
          component={Components.ResetPassword}
        />
        <PublicRoute exact path="/google" component={App} />
        <PublicRoute
          exact
          path="/oauth2/redirect"
          component={OAuth2RedirectHandler}
        />
        <PublicRoute exact path="/profile" component={App} />
      </Switch>
    </Router>
  );
}

export default Routes;
