import React from "react";
import clsx from "clsx";
import SidebarItems from "./SidebarItems";
import { useHistory } from "react-router-dom";
import { ChevronLeft as ChevronLeftIcon } from "@material-ui/icons/";
import { ChevronRight as ChevronRightIcon } from "@material-ui/icons/";
import {
  Drawer,
  Divider,
  IconButton,
  Tooltip,
  ListItemText,
  ListItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { MdOutlineLogout } from "react-icons/md";
import { removeAuthObjects } from "../../utils";
// import { ReactComponent as Logo1 } from "../../assets/images/logo1.svg";
import shipGif from "../../img/cargo-ship.gif";
import Logo from "../Logo";

const drawerWidth = 240;

const styles = (theme) => ({
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: "100vh",
    overflow: "hidden",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
});

const Sidebar = ({
  classes,
  drawerOpen,
  drawerClose,
  validRoutes,
  activeRoute,
  setDrawerOpen
}) => {
  const history = useHistory();

  // Function to close drawer
  const handleDrawerClose = () => {
    drawerClose(false);
  };

  // Logout function
  const onClickLogout = () => {
    removeAuthObjects();
    history.push("/");
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          " md:flex justify-between top-0 sticky",
          classes.drawerPaper,
          !drawerOpen && classes.drawerPaperClose
        ),
      }}
      open={drawerOpen}
      onMouseEnter={() => setDrawerOpen(true)} 
      onMouseLeave={() => setDrawerOpen(false)}
    >
      <div>
        <div className="h-[10px]" />
        {drawerOpen ? (
          <>
            <Logo className="w-10 h-10 inline-block pb-2 pl-2" />
            <span className="text-xl pr-1 text-[#3f51b5] font-bold">
              Smart Sea Freight
            </span>
          </>
        ) : (
          <Logo className="w-10 h-10 inline-block pb-2 pl-2" />
        )}
        <Divider />

        <div
          className={clsx("h-5", classes.toolbarIcon, {
            "justify-end": drawerOpen,
            "justify-center": !drawerOpen,
          })}
        >
          {drawerOpen && (
            <img src={shipGif} alt="ship" className="w-20 h-20 mr-5" />
          )}
          <span>
            <IconButton onClick={handleDrawerClose}>
              {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </span>
        </div>
        <Divider />

        {/* Sidebar Items */}
        {validRoutes.map((item, i) => {
          const selectedRoute = item.activeRoutes.includes(activeRoute);
          return (
            <>
              <SidebarItems
                item={item}
                key={i}
                handleDrawerClose={handleDrawerClose}
                drawerOpen={drawerOpen}
                selectedRoute={selectedRoute}
              />
              <Divider />
            </>
          );
        })}
      </div>
      <div>
        <Divider />
        <div
          className={clsx("p-0 m-0", {
            "justify-start flex gap-6": drawerOpen,
            "justify-center": !drawerOpen,
          })}
        >
          <Tooltip
            title="Logout"
            arrow
            placement="right"
            disableHoverListener={drawerOpen}
          >
            <ListItem
              button
              className="flex justify-center gap-6"
              onClick={onClickLogout}
            >
              <MdOutlineLogout className="h-12 text-xl" />

              {drawerOpen && <ListItemText primary="Logout" />}
            </ListItem>
          </Tooltip>
        </div>
      </div>
    </Drawer>
  );
};

export default withStyles(styles, { withTheme: true })(Sidebar);
