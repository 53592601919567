import { termsCoEfficientTable } from "../../../constants";

export const vesselDataMappaing = (allVessels) => {
  return allVessels.map((item) => {
    const {
      id = "",
      organizationId = "",
      createdDate = "",
      lastModifiedDate = "",
      createdUser = "",
      lastModifiedUser = "",
      version = "",
      system = "",
      vesselName = "",
      vesselDwt = 0,
      vesselDraftS = 0,
      vesselTPC = 0,
      vesselGrainCap = 0,
      vesselBalSpd1 = 0,
      vesselBalConsmHS1 = 0,
      vesselBalConsmLS1 = 0,
      vesselBalConsmMGO1 = 0,
      vesselBalSpd2 = 0,
      vesselBalConsmHS2 = 0,
      vesselBalConsmLS2 = 0,
      vesselBalConsmMGO2 = 0,
      vesselBalSpd3 = 0,
      vesselBalConsmHS3 = 0,
      vesselBalConsmLS3 = 0,
      vesselBalConsmMGO3 = 0,
      vesselBalSpd4 = 0,
      vesselBalConsmHS4 = 0,
      vesselBalConsmLS4 = 0,
      vesselBalConsmMGO4 = 0,
      vesselLdnSpd1 = 0,
      vesselLdnConsmHS1 = 0,
      vesselLdnConsmLS1 = 0,
      vesselLdnConsmMGO1 = 0,
      vesselLdnSpd2 = 0,
      vesselLdnConsmHS2 = 0,
      vesselLdnConsmLS2 = 0,
      vesselLdnConsmMGO2 = 0,
      vesselLdnSpd3 = 0,
      vesselLdnConsmHS3 = 0,
      vesselLdnConsmLS3 = 0,
      vesselLdnConsmMGO3 = 0,
      vesselLdnSpd4 = 0,
      vesselLdnConsmHS4 = 0,
      vesselLdnConsmLS4 = 0,
      vesselLdnConsmMGO4 = 0,
      vesselPIdleConsmHS = 0,
      vesselPIdleConsmLS = 0,
      vesselPIdleConsmMGO = 0,
      vesselPLdgConsmHS = 0,
      vesselPLdgConsmLS = 0,
      vesselPLdgConsmMGO = 0,
      vesselPDischConsmHS = 0,
      vesselPDischConsmLS = 0,
      vesselPDischConsmMGO = 0,
      vesselVExtraConsmHS = 0,
      vesselVExtraConsmLS = 0,
      vesselVExtraConsmMGO = 0,
      vesselLightShip = 0,
      vesselBuilt = 0,
      vesselFlag = 0,
      vesselLOA = 0,
      vesselBeam = 0,
      vesselGRT = 0,
      vesselNRT = 0,
      vesselIMO = 0,
      vesselClass = 0,
      vesselBaleCap = 0,
      vesselGears = 0,
      vesselGrabs = 0,
      vesselHolds = 0,
      vesselHatches = 0,
      vesselCO2 = 0,
      vesselAHL = 0,
      vesselIceClass = 0,
      vesselShipyard = "",
      vesselShipowner = "",
      fuelType = "",
      vesselMMSI = 0,
      vesselType = 0,
      vesselSource = 0,
      vesselCountry = 0,
    } = item;
    return {
      name: vesselName,
      vesselId: id,
      dwt: vesselDwt,
      draft: vesselDraftS,
      tPC: vesselTPC,
      grainCapacity: vesselGrainCap,
      lightShip: vesselLightShip,
      built: vesselBuilt,
      flag: vesselFlag,
      lOA: vesselLOA,
      beam: vesselBeam,
      gRT: vesselGRT,
      nRT: vesselNRT,
      baleCapacity: vesselBaleCap,
      gears: vesselGears,
      grabs: vesselGrabs,
      holds: vesselHolds,
      scrubberFittedType: fuelType,
      type: vesselType,
      subType: "",
      constants: "",
      expenses: "",
      ballastTravelConsumptionList: [
        {
          speed: vesselBalSpd1,
          hsfo: vesselBalConsmHS1,
          lsfo: vesselBalConsmLS1,
          mgo: vesselBalConsmMGO1,
        },
        {
          speed: vesselBalSpd2,
          hsfo: vesselBalConsmHS2,
          lsfo: vesselBalConsmLS2,
          mgo: vesselBalConsmMGO2,
        },
        {
          speed: vesselBalSpd3,
          hsfo: vesselBalConsmHS3,
          lsfo: vesselBalConsmLS3,
          mgo: vesselBalConsmMGO3,
        },
      ],
      ladenTravelConsumption: [
        {
          speed: vesselLdnSpd1,
          hsfo: vesselLdnConsmHS1,
          lsfo: vesselLdnConsmLS1,
          mgo: vesselLdnConsmMGO1,
        },
        {
          speed: vesselLdnSpd2,
          hsfo: vesselLdnConsmHS2,
          lsfo: vesselLdnConsmLS2,
          mgo: vesselLdnConsmMGO2,
        },
        {
          speed: vesselLdnSpd3,
          hsfo: vesselLdnConsmHS3,
          lsfo: vesselLdnConsmLS3,
          mgo: vesselLdnConsmMGO3,
        },
      ],
      portConsumptions: {
        portDischargeConsumption: {
          hsfo: vesselPDischConsmHS,
          lsfo: vesselPDischConsmLS,
          mgo: vesselPDischConsmMGO,
        },
        portIdleConsumption: {
          hsfo: vesselPIdleConsmHS,
          lsfo: vesselPIdleConsmLS,
          mgo: vesselPIdleConsmMGO,
        },
        portLoadingConsumption: {
          hsfo: vesselPLdgConsmHS,
          lsfo: vesselPLdgConsmLS,
          mgo: vesselPLdgConsmMGO,
        },
        portMiscPerVoyageConsumption: {
          hsfo: vesselVExtraConsmHS,
          lsfo: vesselVExtraConsmLS,
          mgo: vesselVExtraConsmMGO,
        },
      },
      fuel: {
        fuelConstant: 0,
        fuelQtyOnDelivery: {
          hsfo: 0,
          lsfo: 0,
          mgo: 0,
        },
        fuelQtyOnRedelivery: {
          hsfo: 0,
          lsfo: 0,
          mgo: 0,
        },
        fuelRedeliveryTolerance: {
          hsfo: 0,
          lsfo: 0,
          mgo: 0,
        },
        fuelPricesOnDelivery: {
          hsfo: 100.0,
          lsfo: 200.0,
          mgo: 300.0,
        },
        fuelPriceforTolerableSettlement: {
          hsfo: 400.0,
          lsfo: 500.0,
          mgo: 600.0,
        },
        fuelPriceForNonTolerableSettlement: {
          hsfo: 700.0,
          lsfo: 800.0,
          mgo: 900.0,
        },
      },
      tc_gbb: 100000.0,
      dateAndTime: "",
      hirePerDay: {
        hirePerDayHireDefault: 3500.0,
        hireGBB: 100000.0,
        splitHire: [
          {
            hireRate: 8000.0,
            days: 10.0,
          },
          {
            hireRate: 9000.0,
            days: 0.0,
          },
          {
            hireRate: 10000.0,
            days: 0.0,
          },
          {
            hireRate: 11000.0,
            days: 10.0,
          },
        ],
      },
    };
  });
};

export const portOperationDataMapping = (combinedCargo) => {
  return combinedCargo.map((item) => {
    const {
      operation = 0,
      cargoQty = 0,
      tolerance,
      terms = "",
      other_tax = 0,
      brokerage = 0,
      account = 0,
      cargo = 0,
      freight = 0,
      rate = 0,
      turnTime = 0,
      adcom = 0,
      min = 0,
      max = 0,
      port = "",
      draftRes = 0,
      waterDensity = 0,
      dischargeQty = 0,
      dwf = 0,
      portExp = 0,
      sulfur = "",
      bnkrFactor = 0,
      speedFactor = 0,
      speedReduce = 0,
      extraDays = 0,
      loadline = "",
      cargoDetails = {},
    } = item;
    const {
      distNormal = 0,
      distEca = 0,
      reserve = "",
      bunkerSuppliedHSFO = 0,
      bunkerSuppliedLSFO = 0,
      bunkerSuppliedMGO = 0,
      bunkerNonScrubberHSFO = 0,
      bunkerNonScrubberLSFO = 0,
      bunkerNonScrubberMGO = 0,
      bunkerScrubberHSFO = 0,
      bunkerScrubberLSFO = 0,
      bunkerScrubberMGO = 0,
      paidBy = "",
    } = cargoDetails || {};

    const termsFactor =
      (terms && termsCoEfficientTable.find((item) => item.label === terms)) ||
      0;

    return {
      operation,
      cargoQuantity: cargoQty,
      tolerance,
      terms,
      port,
      other_tax,
      brokerage,
      account,
      cargo,
      stowFactor: (termsFactor && termsFactor.value) || 0,
      freight,
      rate,
      turnTime,
      adcom,
      min,
      max,
      draftRes,
      waterDensity,
      dwf,
      consumptionMargin: bnkrFactor,
      speedMargin: speedFactor,
      speedReduction: speedReduce,
      extraDays,
      loadline,
      distanceFromPreviousPort: distNormal,
      ecaLowSulphurDistanceFromLastPort: distEca,
      portEmission: sulfur,
      portExpenses: portExp,
      reserve,
      fuelSuppliedAtPort: {
        hsfo: bunkerSuppliedHSFO,
        lsfo: bunkerSuppliedLSFO,
        mgo: bunkerSuppliedMGO,
      },
      cargoTxQtyActual: dischargeQty,
      cargoTxQtyAuto: 0,
      fuelPrice: {
        "Type 1": {
          hsfo: bunkerNonScrubberHSFO,
          lsfo: bunkerNonScrubberLSFO,
          mgo: bunkerNonScrubberMGO,
        },
        "Type 2": {
          hsfo: bunkerScrubberHSFO,
          lsfo: bunkerScrubberLSFO,
          mgo: bunkerScrubberMGO,
        },
        "Type 3": {
          hsfo: 0,
          lsfo: 0,
          mgo: 0,
        },
      },
      paidByAccount: paidBy,
    };
  });
};

export const fleetWithCalculatorDataMapping = (fleetList, response) => {
  let updatedFleetList = [];
  if (!!response) {
    for (let i = 0; i < fleetList.length; i++) {
      const fleet = fleetList[i];
      const matchedFleet = response[fleet.vesselUniqueId];
      updatedFleetList.push({
        ...fleet,
        ...matchedFleet,
        vesselId: fleet.vesselId,
        selectedForSave: false,
      });
    }
  } else {
    updatedFleetList = fleetList.map((item) => ({
      ...item,
      selectedForSave: false,
    }));
  }
  return updatedFleetList;
};

export const sendConsumptionsDataByCheckBox = (selectedVessel, checkBoxes) => {
  let vessel = JSON.parse(selectedVessel.vessel);
  if (!checkBoxes.ballest[1]) {
    vessel.vesselBalSpd1 = 0;
  }
  if (!checkBoxes.ballest[2]) {
    vessel.vesselBalSpd2 = 0;
  }
  if (!checkBoxes.ballest[3]) {
    vessel.vesselBalSpd3 = 0;
  }
  if (!checkBoxes.ballest[4]) {
    vessel.vesselBalSpd4 = 0;
  }
  if (!checkBoxes.laden[1]) {
    vessel.vesselLdnSpd1 = 0;
  }
  if (!checkBoxes.laden[2]) {
    vessel.vesselLdnSpd2 = 0;
  }
  if (!checkBoxes.laden[3]) {
    vessel.vesselLdnSpd3 = 0;
  }
  if (!checkBoxes.laden[4]) {
    vessel.vesselLdnSpd4 = 0;
  }
  return { ...selectedVessel, vessel: JSON.stringify(vessel) };
};

// export const convertPortOperationToDistanceString = (calculatorData = {}) => {
//   const { portOperation = [], fleetList = [] } = calculatorData || {};
//   let str = "";
//   let routeList = "";
//   for (let i = 0; i < fleetList.length; i++) {
//     let portName = fleetList[i].port || "";
//     let secondPort = portOperation?.[1]?.port || "";
//     if (!str.length) str = portName + "," + secondPort;
//     else str = str + "," + portName + "," + secondPort;
//   }

//   for (let i = 0; i < portOperation.length; i++) {
//     let portName = portOperation[i].port || "";
//     if (!routeList.length) routeList = portName;
//     else routeList = routeList + "," + portName;
//     if (i === 0 || i === 1) continue;
//     if (!str.length) str = portName;
//     else str = str + "," + portName;
//   }
//   return { getPortDistanceString: str, routeList };
// };

export const convertPortOperationToDistanceString = (calculatorData = {}) => {
  const { portOperation = [], fleetList = [], clientQueryId = '', queryId = '', userId = '' } = calculatorData;

  const routes = []
  const routes2 = []

  // Process fleetList to populate routeList and portList
  for (let i = 0; i < fleetList.length; i++) {
    const { port = "", lat = 0, lng = 0 } = fleetList[i];
    let secondPort = { name: portOperation?.[1]?.port || "", lat: portOperation?.[1]?.lat, lng: portOperation?.[1]?.lng };
    const portEntry = { name: port, lat, lng };
    routes.push({ from: portEntry, to: secondPort })
    // routes.push(secondPort)

  }

  // Process portOperation to populate routeList
  for (let i = 1; i < portOperation.length - 1; i++) {
    const { port = "", lat = 0, lng = 0 } = portOperation[i];
    if (port) {
      const portEntry = { name: port, lat, lng };
      const portEntry2 = { name: portOperation[i + 1]?.port, lat: portOperation[i + 1]?.lat, lng: portOperation[i + 1]?.lng };

      routes.push({ from: portEntry, to: portEntry2 })

    }
  }

  return { routeList: routes, getPortDistanceString: routes2 };
};

export const convertPortOperationToDistanceMatrixCalc = (addPortsArray = {}) => {

  const routes = []
  // Process portOperation to populate routeList
  for (let i = 0; i < addPortsArray.length - 1; i++) {
    const { port = "", lat = 0, lng = 0 } = addPortsArray[i];
    if (port) {
      const portEntry = { name: port, lat, lng };
      const portEntry2 = { name: addPortsArray[i + 1]?.port, lat: addPortsArray[i + 1]?.lat, lng: addPortsArray[i + 1]?.lng };

      routes.push({ from: portEntry, to: portEntry2 })

    }
  }

  return { routeList: routes };
};

export const addPortDistanceToOperations = (
  res = {},
  portOperations = [],
  addGeometry = false
) => {
  for (let i = 0; i < portOperations.length; i++) {
    if (i === portOperations.length) break;
    let currentPortDistance = portOperations[i].port || "";
    let nextPortDistance =
      (portOperations[i + 1] && portOperations[i + 1].port) || "";
    let combPortName = currentPortDistance + "_" + nextPortDistance;
    if (res[combPortName] && portOperations[i + 1]?.operation) {
      portOperations[i + 1] = {
        ...portOperations[i + 1],
        cargoDetails: {
          ...portOperations[i + 1].cargoDetails,
          distEca: res[combPortName].distEca || 0,
          distNormal: res[combPortName].distNormal || 0,
        },
      };
      if (addGeometry) {
        // console.log(res[combPortName], "res[combPortName]")
        const geoJSON = JSON.parse(res[combPortName].geometry) || {};
        // const geoJSON = res[combPortName].geometry
        // console.log(geoJSON, "geoJSON")
        portOperations[i + 1] = {
          ...portOperations[i + 1],
          geoJSON,
        };
      }
    }
  }
  return portOperations;
};

export const addPortDistanceForDistanceMatrix = (
  res = {},
  portOperations = [],
  addGeometry = false
) => {
  for (let i = 0; i < portOperations?.length; i++) {
    if (i === portOperations.length) break;
    let currentPortDistance = portOperations[i].port || "";
    let nextPortDistance =
      (portOperations[i + 1] && portOperations[i + 1].port) || "";
    let combPortName = currentPortDistance + "_" + nextPortDistance;
    if (res[combPortName]) {
      portOperations[i + 1] = {
        ...portOperations[i + 1],
        cargoDetails: {
          ...portOperations[i + 1]?.cargoDetails,
          distEca: res[combPortName].distEca || 0,
          distNormal: res[combPortName].distNormal || 0,
        },
      };
      if (addGeometry) {
        const geoJSON = JSON.parse(res[combPortName].geometry) || {};    
        portOperations[i + 1] = {
          ...portOperations[i + 1],
          geoJSON,
        };
      }
    }
  }
  return portOperations;
};

export const calculationValidation = (calculatorData, selectedVessels) => {
  let errors = [];
  const vessel =
    (selectedVessels &&
      selectedVessels.vessel &&
      JSON.parse(selectedVessels.vessel)) ||
    {};
  if (
    selectedVessels.stowage &&
    !isNaN(selectedVessels.stowage) &&
    Number(selectedVessels.stowage) > 98
  ) {
    errors.push({ message: "Stowage exceeds 98%", type: "error" });
  }

  let {
    vesselBalSpd1 = 0,
    vesselBalSpd2 = 0,
    vesselBalSpd3 = 0,
    vesselBalSpd4 = 0,
    vesselLdnSpd1 = 0,
    vesselLdnSpd2 = 0,
    vesselLdnSpd3 = 0,
    vesselLdnSpd4 = 0,
  } = vessel || {};

  if (
    Number(vesselBalSpd1) +
    Number(vesselBalSpd2) +
    Number(vesselBalSpd3) +
    Number(vesselBalSpd4) ===
    0
  ) {
    // errors.push({
    //   message: `Ballast Speed-Consumption should be non-zero.`,
    //   type: "error",
    // });
  }

  if (
    Number(vesselLdnSpd1) +
    Number(vesselLdnSpd2) +
    Number(vesselLdnSpd3) +
    Number(vesselLdnSpd4) ===
    0
  ) {
    // errors.push({
    //   message: `Laden Speed-Consumption should be non-zero.`,
    //   type: "error",
    // });
  }
  const portOperation = calculatorData.portOperation || [];
  // const fleetList = calculatorData.fleetList || []

  for (let i = 0; i < portOperation.length; i++) {
    const singlePort = portOperation[i];
    const isLoadingDischarging =
      singlePort.operation === "Loading" ||
      singlePort.operation === "Discharging";
    if (
      Number(singlePort.waterDensity) > 0 &&
      (Number(singlePort.waterDensity) < 0.9 ||
        Number(singlePort.waterDensity) > 1.05)
    ) {
      // errors.push({
      //   message: `${singlePort.operation} Water Density seems impractical`,
      //   type: "alert",
      // });
    }
    if (isLoadingDischarging && isNaN(Number(singlePort.cargoTx))) {
      errors.push({
        message: `${singlePort.operation} Cargo Tx is Invalid`,
        type: "alert",
      });
    }
    if (
      isLoadingDischarging &&
      Number(singlePort.cargoTx) < Number(singlePort.min)
    ) {
      errors.push({
        message: `${singlePort.operation} Cargo less than minimum contractual quantity.`,
        type: "alert",
      });
    }
    if (Number(singlePort.draftRes) && Number(singlePort.draftRes) < 5) {
      errors.push({
        message: `${singlePort.operation} Draft restriction seems too low.`,
        type: "alert",
      });
    }
    if (isLoadingDischarging && Number(singlePort.rate) <= 0) {
      errors.push({
        message: `${singlePort.operation} Load Rate cannot be zero!.`,
        type: "error",
      });
    }
  }

  return errors;
};

export const addPortDistanceToSelectedFleet = (
  portDistance = {},
  selectedFleet = {}
) => {
  const { cargoDetails = {} } = (portDistance && portDistance[1]) || {};
  const { distNormal = 0, distEca = 0 } = cargoDetails;

  selectedFleet = {
    ...selectedFleet,
    distanceECA: distEca,
    distanceNormal: distNormal,
  };

  // console.log(selectedFleet)
  return selectedFleet;
};

// export const getGeoJsonLineAndCoordinates = (str) => {
//   const length = str.length;

//   const typeIndex = str.indexOf("type");
//   const typeStartIndex = typeIndex + 5;
//   const coordinatesIndex = str.indexOf("coordinates");
//   const typeEndIndex = coordinatesIndex - 2;
//   const coordinatesStartIndex = coordinatesIndex + 12;
//   const coordinatesEndIndex = length - 1;

//   return {
//     type: str.substring(typeStartIndex, typeEndIndex),
//     coordinates: JSON.parse(
//       str.substring(coordinatesStartIndex, coordinatesEndIndex)
//     ),
//   };
// };

export const getGeoJsonLineAndCoordinates = (geoObject) => {
  // Directly return the type and coordinates from the object
  return {
    type: geoObject?.type,
    coordinates: geoObject?.coordinates
  };
};