import {
  apiDelete,
  apiGet,
  apiPost,
  getCurrentUserId,
} from "../../../utils";

export const getCargoAnalysisDetailsAPI = (query) => {
  let data = { ...query, userId: getCurrentUserId() };
  return apiGet("/api/cargoAnalysis/getCargoAnalysisDetails", data);
};

export const getAllCargoAnalysisDetailsAPI = (query) => {
  let data = { ...query, userId: getCurrentUserId() };
  return apiGet("/api/cargoAnalysis/getAllCargoAnalysisDetails", data);
};

export const getSingleCargoAnalysisDetailsAPI = (query) => {
  return apiGet(`/api/cargoAnalysis/getCargoAnalysisDetail/${query}`,);
};


export const updateCargoAnalysisDetailsAPI = (id, data) => {
  return apiPost(`/api/cargoAnalysis/updateCargoAnalysisDetails/${id}`, data);
};

export const addTeamCargoAnalysisDetailsAPI = (data) => {
  return apiPost(`/api/cargoAnalysis/addTeamCargoAnalysisDetails`, data);
};

export const deleteCargoAnalysisAPI = (id) => {
  return apiDelete(`/api/cargoAnalysis/delete/${id}`);
};