import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import {
  portOperationDisabledFields,
  operationsOptions,
  loadlineOptions,
  termsCoEfficientTable,
  portEmissionOptions,
  vesselVesselPortOperationHeader,
} from "../../constants";
import errorIcon from '../../assets/images/info-circle-line-icon.svg'
import PlusIcon from '../../assets/icons/plus.png'

import { handleKeyDown } from "../../common/common";
import MomentUtils from "@date-io/moment";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'; // Import Material-UI Icon

const DragHandle = SortableHandle(() => (
  <span>
    <MenuIcon />
  </span>
));

const SortableItem = SortableElement((props) => {
  return <div className="sortable-row-tr">{props.children}</div>;
});

const styles = (index) => {
  const width = vesselVesselPortOperationHeader[index]?.width;
  return { minWidth: width, width };
};

const VesselVesselDraggableList = SortableContainer((props) => {
  let {
    combinedOperation = [],
    onDeleteOperations = () => { },
    onChangePortOperations = () => { },
    onClickSinglePort = () => { },
    showFreightSelection = false,
    cargoTxOverride = false,
    dischargedPortIndexes = [],
    setOpenAddOperation = () => { },
    setOpenAddOperationindex = () => { },
    disableEdit = false,
    selectedVessels,
    clientQuery,
    onChangeBunkerScrubber,

  } = props;

  return (
    <div className="sortable-div">
      {combinedOperation &&
        combinedOperation.map((operations, index) => {
          const checkDisabled =
            portOperationDisabledFields[operations.operation] || {};
          const {
            bunkerSuppliedHSFO = 0,
            bunkerSuppliedLSFO = 0,
            bunkerSuppliedMGO = 0,
            distNormal = 0,
            distEca = 0,
            paidBy = 'Owner',
            reserve = "",
          } = operations.cargoDetails || {};
          const totalSupply =
            Number(bunkerSuppliedHSFO) +
            Number(bunkerSuppliedLSFO) +
            Number(bunkerSuppliedMGO);

          let dischargedPort = dischargedPortIndexes.includes(index);
          let operationLbmarker =
            operations.lbmarker === "ballast"
              ? "B"
              : operations.lbmarker === "laden"
                ? "L"
                : "";
          const selectedArrivalBunker = (selectedVessels && selectedVessels.portToPortOperationResultMap && selectedVessels.portToPortOperationResultMap[index]) || {}
          const { arrivalHsfo = 0, arrivalLsfo = 0, arrivalMgo = 0 } = selectedArrivalBunker || {}
          const { fuelType = "" } = clientQuery || {}
          const currentType = fuelType.replace("TYPE", "")
          const currentHsfo = operations?.cargoDetails[`fuelPriceType${currentType}Hsfo`] || 0
          const currentLsfo = operations?.cargoDetails[`fuelPriceType${currentType}Lsfo`] || 0
          const currentMgo = operations?.cargoDetails[`fuelPriceType${currentType}Mgo`] || 0
          return (
            <SortableItem key={index} index={index}>
              {!disableEdit ? <div className="sortable-div-operation operation-input-div custom-tooltip-div" onClick={() => { setOpenAddOperation(true); setOpenAddOperationindex(index + 1); }} style={styles(0)}>
                <img src={PlusIcon} className="operation-plus-icon" slt="" />
                <div className="tooltip-notification">
                  <p>Add operation in next row</p>
                </div>
              </div> : ""}
              <div className="operation-input-div" style={styles(1)}>
                {index === 0 ? <MenuIcon /> : <DragHandle />}
              </div>
              {/* <div className="operation-input-div" style={styles(2)}>
                {operationLbmarker}
              </div>{" "} */}
              {/* NO */}
              <div className="operation-input-div" style={styles(2)}>
                {/* {operations.operation || 0} */}
                <select
                  value={operations.operation || ""}
                  name="operation"
                  onChange={(e) => onChangePortOperations(index, e)}
                  disabled={checkDisabled.operation}
                >
                  {operationsOptions.map((option, key) => (
                    <option
                      key={key}
                      value={option.value}
                      disabled={option.isDisabled}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="operation-input-div" style={styles(3)}>
                <p
                  className="add-three-dots"
                  onClick={() => {
                    if (
                      index === 0 ||
                      operations.operation === "Loading" ||
                      operations.operation === "Discharging"
                    )
                      return;
                    onClickSinglePort(operations);
                  }}
                >
                  {operations.port || ""}
                </p>
              </div>
              <div className="operation-input-div" style={styles(4)}>
                {/* dist normal */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={distNormal || ""}
                  placeholder="0"
                  disabled={!!checkDisabled.distNormal}
                  name="distNormal"
                  onChange={(e) => onChangePortOperations(index, e, true)}
                />
              </div>
              <div className="operation-input-div" style={styles(5)}>
                {/* {(operations.cargoDetails && operations.cargoDetails.distEca) || 0} */}
                <input
                  type="number"
                  value={distEca || ""}
                  onKeyDown={handleKeyDown}
                  placeholder="0"
                  disabled={!!checkDisabled.distEca}
                  name="distEca"
                  onChange={(e) => onChangePortOperations(index, e, true)}
                />
              </div>
              <div className="operation-input-div" style={styles(6)}>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.dwf || ""}
                  disabled={!!checkDisabled.dwf}
                  name="dwf"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div cargo-cargo-div" style={styles(7)}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    size="small"
                    disabled={true}
                    className="date-time-picker-cargo-cargo"
                    format="DD/MM/YYYY HH:mm"
                    style={{ width: 160 }}
                    value={operations.arrivalTime !== "N/A" ? operations.arrivalTime : null}
                    onChange={(date) => onChangePortOperations(index, date, false, true, "arrivalTime")}
                    inputProps={{
                      placeholder: "DD/MM/YYYY HH:MM",
                    }}
                    InputProps={{
                      style: { padding: 0 }, // Remove input padding
                    }}
                    keyboardIcon={
                      <CalendarTodayIcon
                        style={{
                          padding: 0,
                          margin: 0,
                          color: "gray",
                          display: "block",
                        }}
                      />
                    }
                    InputAdornmentProps={{
                      position: "end",
                      style: {
                        margin: 0
                      },
                    }}
                    KeyboardButtonProps={{
                      style: {
                        padding: 0,
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      },
                    }}
                    disableMaskedInput={!!checkDisabled.arrivalTime}
                  />
                </MuiPickersUtilsProvider>

              </div>
              <div className="operation-input-div" style={styles(8)}>
                {Number(arrivalHsfo).toFixed(2)}
              </div>

              <div className="operation-input-div" style={styles(9)}>
                {Number(arrivalLsfo).toFixed(2)}
              </div>
              <div className="operation-input-div" style={styles(10)}>
                {Number(arrivalMgo).toFixed(2)}

              </div>


              <div className="operation-input-div" style={styles(11)}>
                {/*  speed eca */}
                <input
                  type="number"
                  value={bunkerSuppliedHSFO}
                  onKeyDown={handleKeyDown}
                  disabled={!!checkDisabled.bunkerSuppliedHSFO}
                  name="bunkerSuppliedHSFO"
                  onChange={e => onChangePortOperations(index, e, true)}
                />
              </div>

              <div className="operation-input-div" style={styles(12)}>
                {/*  speed eca */}
                <input
                  type="number"
                  value={bunkerSuppliedLSFO}
                  onKeyDown={handleKeyDown}
                  disabled={!!checkDisabled.bunkerSuppliedLSFO}
                  name="bunkerSuppliedLSFO"
                  onChange={e => onChangePortOperations(index, e, true)}
                />
              </div>
              <div className="operation-input-div" style={styles(13)}>
                {/*  speed eca */}
                <input
                  type="number"
                  value={bunkerSuppliedMGO}
                  onKeyDown={handleKeyDown}
                  disabled={!!checkDisabled.bunkerSuppliedMGO}
                  name="bunkerSuppliedMGO"
                  onChange={e => onChangePortOperations(index, e, true)}
                />
              </div>

              <div className="operation-input-div" style={styles(14)}>
                {/* {operations.terms}  */}
                <select
                  className="select-color-orange"
                  name="reserve"
                  value={reserve}
                  onChange={e => onChangePortOperations(index, e, true)}
                >
                  <option value="Reserve">Reserve</option>
                  <option value="Supply">Supply</option>
                </select>
              </div>
              <div className="operation-input-div" style={styles(15)}>
                <select
                  className="select-color-orange"
                  name="paidBy"
                  value={paidBy}
                  onChange={e => onChangePortOperations(index, e, true)}
                >
                  <option value="Operator">External</option>
                  <option value="Owner">Internal</option>
                </select>
              </div>
              <div className="operation-input-div" style={styles(16)}>
                <input type="number" onKeyDown={handleKeyDown} value={totalSupply} disabled />
              </div>

              <div className="operation-input-div" style={styles(17)}>
                <input
                  type="number"
                  value={currentHsfo}
                  onKeyDown={handleKeyDown}
                  disabled={!!checkDisabled.currentHsfo}
                  name={`fuelPriceType${currentType}Hsfo`}
                  onChange={e => onChangeBunkerScrubber(index, e, true)}
                />
              </div>

              <div className="operation-input-div" style={styles(18)}>
                <input
                  type="number"
                  value={currentLsfo}
                  onKeyDown={handleKeyDown}
                  disabled={!!checkDisabled.currentLsfo}
                  name={`fuelPriceType${currentType}Lsfo`}
                  onChange={e => onChangeBunkerScrubber(index, e, true)}
                />
              </div>
              <div className="operation-input-div" style={styles(19)}>
                <input
                  type="number"
                  value={currentMgo}
                  onKeyDown={handleKeyDown}
                  disabled={!!checkDisabled.currentMgo}
                  name={`fuelPriceType${currentType}Mgo`}
                  onChange={e => onChangeBunkerScrubber(index, e, true)}
                />
              </div>
              <div className="operation-input-div" style={styles(20)}>
                {/* {operations.extraDays} */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.extraDays || ""}
                  disabled={!!checkDisabled.extraDays}
                  name="extraDays"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div" style={styles(21)}>
                {/* {operations.portExp} */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={operations.portExp || ""}
                  disabled={!!checkDisabled.portExp}
                  name="portExp"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div" style={styles(22)}>
                {/* {operations.loadline} */}
                <select
                  value={operations.loadline || ""}
                  name="loadline"
                  onChange={(e) => onChangePortOperations(index, e)}
                  disabled={checkDisabled.loadline}
                >
                  {loadlineOptions.map((option, key) => (
                    <option key={key} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="operation-input-div" style={styles(23)}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    size="small"
                    className="date-time-picker-cargo-cargo"
                    format="DD/MM/YYYY HH:mm"
                    disabled={true}
                    value={operations.departureTime !== "N/A" ? operations.departureTime : null}
                    onChange={(date) => onChangePortOperations(index, date, false, true, "departureTime")}
                    inputProps={{
                      placeholder: "DD/MM/YYYY HH:MM",
                    }}
                    InputProps={{
                      style: { padding: 0 }, // Remove input padding
                    }}
                    keyboardIcon={
                      <CalendarTodayIcon
                        style={{
                          padding: 0,
                          margin: 0,
                          color: "gray",
                          display: "block",
                        }}
                      />
                    }
                    InputAdornmentProps={{
                      position: "end",
                      style: {
                        margin: 0
                      },
                    }}
                    KeyboardButtonProps={{
                      style: {
                        padding: 0,
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      },
                    }}
                    disableMaskedInput={false}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div className="operation-input-div" style={styles(24)}>
                {/* {operations.supply} */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={(operations.departureHsfo)?.toFixed(2) || ""}
                  disabled={!!checkDisabled.departureHsfo}
                  name="departureHsfo"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div" style={styles(25)}>
                {/* {operations.supply} */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={(operations.departureLsfo)?.toFixed(2) || ""}
                  disabled={!!checkDisabled.departureLsfo}
                  name="departureLsfo"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div" style={styles(26)}>
                {/* {operations.supply} */}
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={(operations.departureMgo)?.toFixed(2) || ""}
                  disabled={!!checkDisabled.departureMgo}
                  name="departureMgo"
                  placeholder="0"
                  onChange={(e) => onChangePortOperations(index, e)}
                />
              </div>
              <div className="operation-input-div" style={styles(27)}>
                {!!checkDisabled.canDelete ? (
                  <DeleteIcon
                    onClick={() => onDeleteOperations(index, operations._id, operations?.index)}
                  />
                ) : null}
              </div>
            </SortableItem>
          );
        })}
    </div>
  );
});

export default VesselVesselDraggableList;
