import React, { Component, useEffect, useState } from "react";
import { Link } from "@material-ui/core";
import CustomTextField from "../../components/Common/CustomTextField";
import CustomButton from "../../components/Common/CustomButton";
import loginValidation from "../../validations/loginValidation";
import * as actions from "./actions";
import { saveObject, toastError } from "../../utils";
import { useHistory } from "react-router-dom";
import { CargoVideoUrl, GOOGLE_AUTH_URL } from "../../constants";
import googleLogo from "../../img/google-logo.png";
import { useLocation } from "react-router-dom";
import Logo from "../../common/Logo";
// import cargovideo from "../../img/cargovideo.webm"
// import { ReactComponent as Logo1 } from "../../assets/images/logo1.svg";
const initialUser = {
  email: "",
  password: "",
};

function Auth() {
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState({ ...initialUser });
  const [loginLoader, setLoginLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const onChangeHandler = (e) => {
    const { name = "", value = "" } = e.target;
    setUser({ ...user, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const keyPressed = (e) => {
    if (e.key === "Enter") {
      if (!loginLoader) {
        onLogin();
      }
    }
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailid = queryParams.get('mailid');
    const password = queryParams.get('pas');
    if (emailid && password) {
      onLogin({ email: emailid, password: password, source: "google" })
    }
  }, [])

  const onLogin = (data) => {
    if (data) {
      setLoginLoader(true);
      actions
        .login(data)
        .then((res) => {
          setLoginLoader(false);
          saveObject("auth", res.data);
          history.push("/welcome");
        })
        .catch((err) => {
          setLoginLoader(false);
          toastError(err?.data?.message);
        });
      return
    }
    const { isValid, errors } = loginValidation(user);
    if (!isValid) return setErrors(errors);
    setLoginLoader(true);
    actions
      .login(user)
      .then((res) => {
        setLoginLoader(false);
        saveObject("auth", res.data);
        history.push("/welcome");
      })
      .catch((err) => {
        setLoginLoader(false);
        toastError(err?.data?.message);
      });
  };
  const onLoginWithGoogle = () => {
    setLoginLoader(false);
    history.push("/google");
  };
  return (

    <div className="bg-cover bg-no-repeat flex bg-center relative w-full h-screen overflow-hidden">
      <div className="authBgImage bg-cover bg-no-repeat flex bg-center">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src={CargoVideoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="relative flex flex-col justify-center md:items-start items-center m-3 gap-10 w-full">
        <Logo className="w-10 h-10 inline-block left-0 top-0 absolute" />

        <div className="border border-neutral-100 px-6 w-full sm:w-[500px] bg-white py-[26px] rounded-[48px] border-solid flex flex-col gap-10">
          <div className="my-2 text-center flex flex-col gap-2">

            <div className="typo-16-500 text-neutrals4">
              Hello! Welcome to Smart Sea Freight
            </div>
            <div className="typo-30-500">
              {/* <Logo className="w-10 h-10 inline-block" /> */}

              <span className="pr-1">Login To Your Account</span>
              {/* 👨‍💻 */}
            </div>
          </div>
          <div className="max-w-[344px] flex flex-col gap-4 mx-auto my-0 w-full">
            <CustomTextField
              name="email"
              placeholder="Email"
              label="Email"
              value={user.email}
              error={!!errors.email}
              errorMsg={errors.email}
              onChange={onChangeHandler}
            />
            <CustomTextField
              inputType="password"
              name="password"
              placeholder="Password"
              label="Password"
              value={user.password}
              error={!!errors.password}
              errorMsg={errors.password}
              onChange={onChangeHandler}
              onKeyPress={keyPressed}
            />
            <div className="flex flex-grow justify-end typo-12-400 underline">
              <Link href="/forgotPassword" className="page-name">
                Forgot Password?
              </Link>
            </div>
            <div className="flex justify-center">
              <CustomButton
                onClick={() => onLogin()}
                loader={loginLoader}
                disabled={loginLoader}
                className="bg-blue py-7 rounded-full mt-2 w-60"
              >
                Login
              </CustomButton>
            </div>
            <div className="text-center mt-5">
              <div className="typo-16-400">
                Not having account?{" "}
                <Link href="/signUp" className="text-blue">
                  Sign Up
                </Link>
              </div>
            </div>
            <GoogleLogin />
          </div>
        </div>
      </div>
    </div>
  );

}


export default Auth;

class GoogleLogin extends Component {
  render() {
    return (
      <div className="social-login social-login-wrap">
        <a className="btn btn-block social-btn google social-login-wrap" href={GOOGLE_AUTH_URL}>
          <span>
            <img src={googleLogo} alt="Google" />
          </span>
          <span className="pt-2">Log in with Google</span>
        </a>
      </div>
    );
  }
}