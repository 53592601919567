import { Button } from '@material-ui/core'
import React from 'react'

function ShareWithOrg(props) {
    const {
        value = "",
        onChange = () => { },
        placeholder=""
    } = props
    return (
        <div className="saved-fleet-wrapper">
            <input type="text" placeholder={placeholder} value={value} onChange={onChange} />
        </div>
    )
}
export default ShareWithOrg
