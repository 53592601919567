import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
} from "@material-ui/core";
import EmailParserUI from "../../../components/EmailParser/EmailParserUI";
import EmailTableContainer from "../../../components/EmailParser/EmailTableContainer";
import * as calculatorActions from "../Calculator/actions";
import moment from "moment";
import { getOrganizationId, toastError } from "../../../utils";
import * as actions from "./actions";
import _ from "lodash";

const initialAutoFilters = {
  updateSince: { label: "1", value: "1" },
  portRegion: [
    // { label: "Unknown", value: "Unknown" }
  ],
  vesselType: { label: "All", value: "0_99999999" },
  gear: { label: "All", value: "All" },
};

function EmailParser(props) {
  const { setLoader = () => { } } = props;

  const [addFleetRegionOptions, setAddFleetRegionOptions] = useState([]);
  const [autoFilters, setAutoFilters] = useState({ ...initialAutoFilters });
  const [emailParserData, setEmailParserData] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState({});
  const [selectedEmailData, setSelectedEmailData] = useState([]);
  const [selectedSingleEmail, setSelectedSingleEmail] = useState({});
  const [savePortListByIndex, setSavePortListByIndex] = useState({});
  const [portLoading, setPortLoading] = useState({});
  const [totalPage, setTotalPage] = useState("");

  const [myFilters, setMyFilters] = useState({
    offset: 1,
    limit: 10,
  });

  const delayPortSearch = useCallback(
    _.debounce((index, search) => onSearchPortList(index, search), 500),
    []
  );

  const onSearchPortList = (index, value) => {
    if (value) {
      setPortLoading({ ...portLoading, [index]: true });
      calculatorActions
        .portsByChar(value)
        .then((res) => {
          setPortLoading({ ...portLoading, [index]: false });
          setSavePortListByIndex({
            ...savePortListByIndex,
            [index]:
              (Array.isArray(res.data.list) &&
                res.data.list.map((item) => ({
                  ...item,
                  portLabel: `${item.name} ${item.country ? "(" + item.country + ")" : ""
                    }`,
                }))) ||
              [],
          });
        })
        .catch((err) => {
          setPortLoading({ ...portLoading, [index]: false });
        });
    }
  };

  useEffect(() => {
    calculatorActions.allPortRegions().then((res) => {
      const regionOptions =
        (Array.isArray(res?.data?.list) &&
          res?.data?.list?.map((region) => ({ label: region, value: region }))) ||
        [];
      setAddFleetRegionOptions([
        // { label: "Unknown", value: "Unknown" },
        ...regionOptions,
      ]);
    });
    onSubmitAutoSearch()
  }, []);

  const onChangeNewPortValue = async (index, value, isDate) => {
    let newAddPortsArray = [...emailParserData];
    setLoader(true);
    const data = {
      id: selectedEmailData?._id,
      value: value,
      isDate
    }
    await actions
      .changeMaildetails(data)
      .then((res) => {
        if (isDate) {
          newAddPortsArray[index].nearestDate = { date: value };
        } else {
          newAddPortsArray[index].nearestport = value;
        }
        setEmailParserData(newAddPortsArray);

        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toastError();
      });
  };
  const onChangePagination = async(type="") => {
    
    let newMyFilters = {};
    if (type === "inc") {
      newMyFilters = { ...myFilters, offset: myFilters.offset + 1 };
    } else if (type === "first") {
      newMyFilters = { ...myFilters, offset: 1 };
    } else {
      if (myFilters.offset === 1) return;
      newMyFilters = { ...myFilters, offset: myFilters.offset - 1 };
    }
   await onSubmitAutoSearch(newMyFilters)
    setMyFilters(newMyFilters);
  }
  const onSubmitAutoSearch = (newMyFilters="") => {
    const { updateSince, portRegion, vesselType, gear } = autoFilters || {};
    const pageFilters=newMyFilters?newMyFilters:myFilters

    const modifiedFilters = {
      emailDateLower: moment()
        .subtract(Number(updateSince?.value), "d")
        .startOf()
        .format(),
      emailDateUpper: moment().startOf().format(),
      dwtLower: Number(vesselType.value.split("_")[0]),
      dwtUpper: Number(vesselType.value.split("_")[1]),
      portRegion: portRegion.map((item) => item.value).join(","),
      orgId: getOrganizationId(),
      gear: gear.label,
      ...pageFilters
    };

    setLoader(true);

    actions
      .getDataByCumulativeSearch(modifiedFilters)
      .then((res) => {
        if (Array.isArray(res?.data)) setEmailParserData(res?.data);
        setTotalPage(res?.totalPage)
        setSelectedEmailData({})
        setSelectedSingleEmail({})
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toastError();
      });
  };

  const onSearchByOrgId = (email) => {
    const { updateSince } = autoFilters || {};
    const searchFilters = {
      emailDateLower: moment()
        .subtract(Number(updateSince.value), "d")
        .startOf()
        .format("yyyy-MM-DD hh:mm"),
      emailDateUpper: moment().startOf().format("yyyy-MM-DD hh:mm"),
      vesselId: email.vesselId || "",
      sender: email?.sender,
      orgId: getOrganizationId(),
    };
    setLoader(true);
    setSelectedEmail(email);
    actions
      .getEmailsByVessel(searchFilters)
      .then((res) => {
        setSelectedEmailData(res);
        setSelectedSingleEmail({})
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toastError();
      });
  };

  const onChangeAutoVessel = async (mail_id, show, i) => {
    const data = {
      mail_id,
      show
    }

    actions
      .onChangeAutoVessel(data)
      .then((res) => {
        const tmpData = emailParserData
        tmpData[i].showInAutoVessel = show
        setLoader(true);

        setEmailParserData(tmpData)
        setLoader(false);
        // onSearchByOrgId(mail)
        // onSubmitAutoSearch()
      })
      .catch((err) => {
        toastError();
      });
  }

  // const {
  //   sender = "",
  //   subject = "",
  //   deliveredTo = "",
  //   createdDate = "",
  //   emailContent = "",
  // } = selectedSingleEmail;

  return (
    <Grid container>
      <Grid item xs={12}>
        <h3>Position Reader</h3>
      </Grid>

      <Grid item xs={12}>
        <Paper style={{ marginTop: 10 }}>
          <EmailParserUI
            addFleetRegionOptions={addFleetRegionOptions}
            setAutoFilters={setAutoFilters}
            autoFilters={autoFilters}
            onSubmitAutoSearch={onSubmitAutoSearch}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <EmailTableContainer
          emailParserData={emailParserData}
          onSearchByOrgId={onSearchByOrgId}
          selectedEmail={selectedEmail}
          savePortListByIndex={savePortListByIndex}
          delayPortSearch={delayPortSearch}
          portLoading={portLoading}
          onChangeNewPortValue={onChangeNewPortValue}
          onChangeAutoVessel={onChangeAutoVessel}
          onChangePagination={onChangePagination}
          filters={myFilters}
          totalPage={totalPage}
        />
      </Grid>

      <Grid item xs={12} md={8} style={{ paddingTop: 10 }}>
        <TableContainer
          component={Paper}
          className="vessel-selection-table selected-email-table mui-selected-grey"
        >
          <Table >
            <TableHead>
              <TableRow>
                <TableCell>Sender</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Time</TableCell>
                {/* <TableCell>Add to Auto Vessel</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedEmailData?.mails?.length ? (
                selectedEmailData?.mails?.map((singleEmail, i) => {
                  const {
                    _id,
                    data = "",
                    createdAt = "",
                    showInAutoVessel = false,
                  } = singleEmail;
                  return (
                    <TableRow
                      key={_id}
                      hover
                      style={{ cursor: "pointer" }}
                      selected={selectedSingleEmail?.index === i + 1}
                      onClick={() => setSelectedSingleEmail({ ...singleEmail, index: i + 1 })}
                    >
                      <TableCell>{selectedEmailData?.sender}</TableCell>
                      <TableCell>{data?.subject}</TableCell>
                      <TableCell>{moment(createdAt).format("DD MMM YYYY HH:mm:A")}</TableCell>
                      {/* <TableCell onClick={(e) => e.stopPropagation()}>
                        <Switch
                          checked={showInAutoVessel}
                          onChange={(e) => onChangeAutoVessel(selectedEmailData?._id, i, e.target.checked, selectedEmailData)}
                          color="primary"
                        />
                      </TableCell> */}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center" className="!bg-white">
                    No data found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} md={4} className="m-t-10">
        <Paper className="email-content-container">
          <div>
            <div>
              <p>
                Subject: <span>{selectedSingleEmail?.data?.subject}</span>
              </p>
            </div>
            <div>
              <p>
                From: {selectedSingleEmail?.data?.from?.[0]?.name && <span>{selectedSingleEmail?.data?.from?.[0]?.name} &lt;{selectedSingleEmail?.data?.from?.[0]?.address}&gt;</span>}
              </p>
            </div>
            {/* <div>
              <p>
                To: <span>{selectedSingleEmail?.data?.deliveredTo}</span>
              </p>
            </div> */}
            <div>
              <p>
                Time Received:{" "}
                <span>
                  {selectedSingleEmail?.createdAt ? moment(selectedSingleEmail?.createdAt).format("hh:mm:A") : ""}
                </span>
              </p>
            </div>
          </div>
          <div className="email-content">
            <p style={{ whiteSpace: "pre-line" }}> {selectedSingleEmail?.data?.text}</p>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default EmailParser;
