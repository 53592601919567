import React, { useState } from 'react'
import { Grid, TextField, Checkbox } from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete'
import { addFleetGearsOptions, addFleetVesselTypeOptions } from '../../constants'

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function EmailParserUI(props) {

    const {
        addFleetRegionOptions = [],
        setAutoFilters = () => { },
        autoFilters = [],
        onSubmitAutoSearch = () => { }
    } = props

    const onChangeAutoFilters = (name, value) => {
        setAutoFilters({ ...autoFilters, [name]: value })
    }

    const updatedSinceLastOptions = () => {
        const arr = []
        for (let i = 0; i < 30; i++) {
            arr.push({ label: String(i + 1), value: String(i + 1) })
        }
        return arr
    }

    return (
        <Grid container spacing={1} className="email-parser-container" >
            <Grid item xs={2}>
                <label className="auto-add-fleet-label">Updated since last</label>
                <Autocomplete
                    id="combo-box-demo"
                    options={updatedSinceLastOptions()}
                    value={autoFilters.updateSince || {}}
                    onChange={(e, value) => onChangeAutoFilters("updateSince", value)}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    fullWidth
                    disableClearable
                    renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={2} sm={4}>
                <label className="auto-add-fleet-label">Region</label>
                <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={addFleetRegionOptions}
                    value={autoFilters.portRegion || {}}
                    fullWidth
                    onChange={(e, value) => onChangeAutoFilters("portRegion", value)}
                    limitTags={2}
                    disableClearable
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                size="small"
                                checkedIcon={checkedIcon}
                                checked={selected}
                            />
                            <span style={{ fontSize: 12 }}>{option.label}</span>
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" size="small" />
                    )}
                />
            </Grid>
            <Grid item xs={2}>
                <label className="auto-add-fleet-label">Vessel Type</label>
                <Autocomplete
                    id="combo-box-demo"
                    options={addFleetVesselTypeOptions}
                    value={autoFilters.vesselType || {}}
                    onChange={(e, value) => onChangeAutoFilters("vesselType", value)}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    fullWidth
                    disableClearable
                    renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={2}>
                <label className="auto-add-fleet-label">Gears</label>
                <Autocomplete
                    id="combo-box-demo"
                    options={addFleetGearsOptions}
                    value={autoFilters.gear || {}}
                    onChange={(e, value) => onChangeAutoFilters("gear", value)}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    fullWidth
                    disableClearable
                    renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={2} className="d-flex">
                <span className="search-by-auto m-a" onClick={()=>onSubmitAutoSearch()}>Search</span>
            </Grid>
        </Grid>
    )
}

export default EmailParserUI
