import React from "react";
import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
} from "@material-ui/core";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomPagination from "../../../components/Common/CustomPagination";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { findLast } from "lodash";
import UpdateIcon from '@material-ui/icons/Update';
import ShareIcon from '@material-ui/icons/OpenInNew';
import SearchIcon from "../../../assets/flaticons/cargo-analysis.png"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
function MyOrdersTable(props) {
  const {
    allClientOrders = [],
    queryOrderList = [],
    onClickEditQuery = () => { },
    onChangePinQuery = () => { },
    onStepperQuery = () => { },
    onDeleteQuery = () => { },
    searchType = {},
    onChangeSearchType = () => { },
    onClickSubmitSearch = () => { },
    onChangePagination = () => { },
    filters = () => { },
    onClickClear = () => { },
    onClickSaveCargo = () => { },
    onClickSaveCargoVessel = () => { },
    onCargoAnalysisClick = () => { }
  } = props;
  const loadSliderValue = String(searchType.loadQty).split(",");
  const createdDateValue = String(searchType.createdDate).split(",");

  return (
    <TableContainer component={Paper}>
      <Table className="universal-table">
        <TableHead>
          <TableRow style={{ background: "#dedede" }}>
            <TableCell>No.</TableCell>
            <TableCell>Name</TableCell>
            <TableCell style={{ minWidth: "120px" }}>First Load Port</TableCell>
            <TableCell style={{ minWidth: "130px" }}>
              Last Discharge Port
            </TableCell>
            <TableCell>Cargo</TableCell>
            <TableCell>Load Qty</TableCell>
            <TableCell>Voy Type</TableCell>
            <TableCell>Account</TableCell>
            <TableCell>Created on</TableCell>
            <TableCell>Created by</TableCell>
            <TableCell style={{ width: 110 }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/*  <TableRow className="searchable-tablerow">
            <TableCell>
              {searchType.type ? (
                <CancelIcon
                  style={{ color: "#009EF7" }}
                  onClick={onClickClear}
                />
              ) : null}
            </TableCell>
            <TableCell>
              <input
                name="name"
                value={searchType.name}
                onChange={onChangeSearchType}
              />
            </TableCell>
            <TableCell>
              <input
                name="firstLoadPort"
                value={searchType.firstLoadPort}
                onChange={onChangeSearchType}
              />
            </TableCell>
            <TableCell>
              <input
                name="lastDischargePort"
                value={searchType.lastDischargePort}
                onChange={onChangeSearchType}
              />
            </TableCell>
            <TableCell>
              <input
                name="cargo"
                value={searchType.cargo}
                onChange={onChangeSearchType}
              />
            </TableCell>
            <TableCell>
              <div className="range-load-quantity">
                <input
                  type="number"
                  name="loadQty"
                  value={loadSliderValue[0] || ""}
                  onChange={(e) => onChangeSearchType(e, "start")}
                />
                <input
                  type="number"
                  name="loadQty"
                  value={loadSliderValue[1] || ""}
                  onChange={(e) => onChangeSearchType(e, "end")}
                />
              </div>
            </TableCell>
            <TableCell>
              <input
                name="voyType"
                value={searchType.voyType}
                onChange={onChangeSearchType}
              />
            </TableCell>
            <TableCell>
              <input
                name="account"
                value={searchType.account}
                onChange={onChangeSearchType}
              />
            </TableCell>
            <TableCell>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format="DD/MMM/yyyy"
                    className="small-date-picker"
                    placeholder="Start"
                    size="small"
                    name="createdDate"
                    clearable
                    value={
                      (createdDateValue &&
                        createdDateValue[0] &&
                        Number(createdDateValue[0])) ||
                      null
                    }
                    onChange={(date) =>
                      onChangeSearchType(
                        { target: { name: "createdDate", value: date } },
                        "start"
                      )
                    }
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format="DD/MMM/yyyy"
                    className="small-date-picker"
                    style={{ marginLeft: 10 }}
                    placeholder="End"
                    size="small"
                    clearable
                    name="createdDate"
                    value={
                      (createdDateValue &&
                        createdDateValue[1] &&
                        Number(createdDateValue[1])) ||
                      null
                    }
                    onChange={(date) =>
                      onChangeSearchType(
                        { target: { name: "createdDate", value: date } },
                        "end"
                      )
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
            </TableCell>
            <TableCell>
              <input
                name="createdUserName"
                value={searchType.createdUserName}
                onChange={onChangeSearchType}
              />
            </TableCell>
            <TableCell>
              <button onClick={onClickSubmitSearch}>Search</button>
            </TableCell>
          </TableRow> */}
          {Array.isArray(allClientOrders) && allClientOrders.length ? (
            allClientOrders.map((item, index) => {
              const isPinned = item.access === "global" ? true : false;
              const firstLoadDetails =
                item.cargoList.length > 0
                  ? item.cargoList.find(
                    (itemDetail) => itemDetail.operation === "Loading"
                  )
                  : [];
              const lastLoadDetails =
                item.cargoList.length > 0
                  ? findLast(item.cargoList, { operation: "Discharging" })
                  : [];

              return (
                <TableRow
                  key={index}
                  onDoubleClick={() => {
                    onClickEditQuery(item);
                  }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.name || ""}</TableCell>
                  <TableCell style={{ minWidth: "120px" }}>
                    {firstLoadDetails.port || ""}
                  </TableCell>
                  <TableCell style={{ minWidth: "130px" }}>
                    {lastLoadDetails.port || ""}
                  </TableCell>
                  <TableCell>{firstLoadDetails.cargo || ""}</TableCell>
                  <TableCell>{firstLoadDetails.cargoQty || ""}</TableCell>
                  <TableCell>{item.voyType || ""}</TableCell>
                  <TableCell>{firstLoadDetails.account || ""}</TableCell>
                  <TableCell>
                    {item.createdDate
                      ? moment(item.createdDate).format("DD/MMM/yyyy")
                      : ""}
                  </TableCell>
                  <TableCell>{item.createdUserName || ""}</TableCell>
                  <TableCell style={{ minWidth: 150 }}>
                    <div className="query-actions min-w-max">
                      <VisibilityIcon
                        color="primary"
                        onClick={() => onClickEditQuery(item)}
                      />
                      {item?.isAllStepClear ?
                        <Switch
                          checked={item?.isShowInCalculator}
                          onChange={() => onChangePinQuery(item)}
                          color="primary"
                          size="small"
                        /> :
                        <>
                          <span className="pending-steper validate-icon-vessel-manager">
                            <UpdateIcon onClick={() => onStepperQuery(item._id)} />
                          </span>
                          <div className="tooltip-notification-vessel-manager">
                            <p>Complete steps to show this query in the calculator</p>
                          </div>
                        </>
                      }
                      <>
                        <span className="pending-steper validate-icon-vessel-manager">

                          <ExitToAppIcon
                            style={{
                              color: "black",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() => onClickSaveCargo(item?._id, item)}
                          />
                        </span>
                        <div className="tooltip-notification-vessel-manager mr-0">
                          <p>Add to cargo cargo matrix</p>
                        </div>
                      </>
                      <>
                        <span className="pending-steper validate-icon-vessel-manager">

                          <ShareIcon
                            style={{
                              color: "black",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() => onClickSaveCargoVessel(item?._id, item)}
                          />
                        </span>
                        <div className="tooltip-notification-vessel-manager mr-0">
                          <p>Add to single cargo-vessel matrix</p>
                        </div>
                      </>
                      <>
                        <span className="pending-steper validate-icon-vessel-manager" style={{ display: "ruby" }}>

                          <img
                            src={SearchIcon}
                            style={{
                              color: "black",
                              // fontSize: "16px",
                              cursor: "pointer",
                              width: "16px"
                            }}
                            onClick={() => onCargoAnalysisClick(item?._id, item)}
                          />
                        </span>
                        <div className="tooltip-notification-vessel-manager mr-0">
                          <p>Cargo analysis</p>
                        </div>
                      </>

                      <DeleteIcon
                        color="secondary"
                        onClick={() => onDeleteQuery(item)}
                      />

                    </div>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan="10" align="center" className="!bg-white">
                No Order Found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <CustomPagination
        onChangePagination={onChangePagination}
        filters={filters}
        totalPage={filters?.totalPages}
      />
    </TableContainer>
  );
}

export default MyOrdersTable;
