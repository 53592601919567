import React from "react";
import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  CircularProgress,
  Button,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded';

import MomentUtils from "@date-io/moment";
import {
  addFleetGearsOptions,
  addFleetVesselTypeOptions,
} from "../../../constants";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import moment from "moment";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
export default function ManualAddFleet(props) {
  const {
    updatedSinceLastOptions,
    autoFilters,
    onChangeAutoFilters,
    onSubmitAutoSearch,
    emailFleetList,
    selectedAutoFleet,
    onChangeDateOpening,
    onAutoFleetListCheckBox,
    allSelected,
    addFleetRegionOptions,
    onSelectAllAutoFleet,
    setValidationVesselFromAuto,
    setValidationVesselFromAutoIndex,
    savePortListByIndex,
    portLoading,
    onChangeNewPortValue,
    delayPortSearch,
    onSaveAllVessels
  } = props;

  const indeterminateChecked =
    selectedAutoFleet.length &&
    selectedAutoFleet.length !== emailFleetList.length;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1} className="m-t-20">
          <Grid item xs={2}>
            <label className="auto-add-fleet-label">Updated since last</label>
            <Autocomplete
              id="combo-box-demo"
              options={updatedSinceLastOptions()}
              value={autoFilters.updateSince || {}}
              onChange={(e, value) => onChangeAutoFilters("updateSince", value)}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              fullWidth
              disableClearable
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <label className="auto-add-fleet-label">Region</label>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={addFleetRegionOptions}
              value={autoFilters.portRegion || {}}
              fullWidth
              onChange={(e, value) => onChangeAutoFilters("portRegion", value)}
              limitTags={2}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    size="small"
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  <span style={{ fontSize: 12 }}>{option.label}</span>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <label className="auto-add-fleet-label">Vessel Type</label>
            <Autocomplete
              id="combo-box-demo"
              options={addFleetVesselTypeOptions}
              value={autoFilters.vesselType || {}}
              onChange={(e, value) => onChangeAutoFilters("vesselType", value)}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              fullWidth
              disableClearable
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <label className="auto-add-fleet-label">Gears</label>
            <Autocomplete
              id="combo-box-demo"
              options={addFleetGearsOptions}
              value={autoFilters.gear || {}}
              onChange={(e, value) => onChangeAutoFilters("gear", value)}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              fullWidth
              disableClearable
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={2} className="d-flex">
            <span className="search-by-auto m-a" onClick={onSubmitAutoSearch}>
              Search
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <TableContainer
          className="auto-add-from-mail"
          component={Paper}
          style={emailFleetList.length ? { minHeight: 350 } : {}}
        >
          <Table className="fleet-list-table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    indeterminate={indeterminateChecked}
                    checked={allSelected}
                    onChange={onSelectAllAutoFleet}
                  />
                </TableCell>
                <TableCell>Vessel Name</TableCell>
                <TableCell>DWT</TableCell>
                <TableCell>Port
                  <LightTooltip title="Required">
                    <span className="required-span">*</span>
                  </LightTooltip>
                </TableCell>
                <TableCell>Port Region</TableCell>

                {/* <TableCell>Email Date Opening</TableCell> */}
                <TableCell>Date Opening
                  <LightTooltip title="Required">
                    <span className="required-span">*</span>
                  </LightTooltip>
                </TableCell>
                <TableCell>Gears</TableCell>
                <TableCell>Email Subject</TableCell>
                <TableCell>Email Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emailFleetList.length
                ? emailFleetList.map((item, index) => {
                  const {
                    id,
                    vesselName = "",
                    email_date = "",
                    vesselDwt = "",
                    email_subject = "",
                    fleet_id=""
                  } = item;
                  const isChecked =
                    selectedAutoFleet.find((itm) => itm?._id === item?._id) ||
                    false;
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          style={{ padding: 0 }}
                          color="primary"
                          checked={!!isChecked}
                          disabled={!!fleet_id}
                          onChange={() => onAutoFleetListCheckBox(item)}
                        />
                      </TableCell>
                      <TableCell>{vesselName}</TableCell>
                      <TableCell>{item?.vesseldata?.vesselDwt}</TableCell>

                      {/* <TableCell>{item?.nearestport?.name}</TableCell> */}
                      <TableCell>
                        <Autocomplete
                          options={
                            (savePortListByIndex?.[index] &&
                              savePortListByIndex?.[index]) ||
                            []
                          }
                          value={item?.nearestport}
                          getOptionLabel={(option) => option.portLabel}
                          disableClearable
                          onChange={(e, value) =>
                            onChangeNewPortValue(index, value, item?._id)
                          }
                          className="autocomplete-mui"
                          getOptionSelected={(option, value) =>
                            option.portLabel === value.portLabel
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Search Port"
                              value={item?.nearestport && item?.nearestport?.portLabel}
                              onChange={(e) =>
                                delayPortSearch(index, e.target.value)
                              }
                              size="small"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {(portLoading?.[index] &&
                                      portLoading?.[index]) ||
                                      false ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={15}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>{item?.nearestport?.area}</TableCell>

                      {/* <TableCell>{dateOpening}</TableCell> */}
                      <TableCell>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            inputVariant="outlined"
                            size="small"
                            className="date-time-picker"
                            format="DD/MMM/YYYY HH:mm:A"
                            style={{ width: 160, height: 2 }}
                            value={item?.nearestDate?.date || null}
                            onChange={(date) =>
                              onChangeNewPortValue(index, date, item?._id, true)
                            }
                          />
                        </MuiPickersUtilsProvider>
                        {/* {moment(item?.nearestDate?.date).format("DD/MMM/YYYY HH:mm:A")} */}
                      </TableCell>
                      <TableCell>{item?.vesseldata?.vesselGears}</TableCell>
                      <TableCell>{email_subject}</TableCell>
                      <TableCell>{moment(email_date).format("DD/MMM/YYYY HH:mm:A")}</TableCell>
                      <TableCell>
                        <PlaylistAddCheckRoundedIcon
                          className="action-buttons action-buttons-validate-icon validate-icon-vessel-manager"
                          color="primary"
                          fontSize="large"
                          onClick={() => {
                            setValidationVesselFromAuto(true);
                            setValidationVesselFromAutoIndex(index);
                          }}
                        />
                        <div className="tooltip-notification-vessel-manager">
                          <p>Validate or Update fleet details</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
                : null}
            </TableBody>

          </Table>
          <div className="flex justify-end my-2">
            <Button
              onClick={onSaveAllVessels}
              color="inherit"
              variant="contained"
              className="bg-page-name text-neutrals9 font-family !bg-blue px-8 rounded-md mt-10"
            >
              Add Fleet
            </Button>
          </div>
        </TableContainer>

      </Grid>
    </Grid>
  );
}
