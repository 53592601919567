import {
  apiGet,
  getCurrentUserId,
  apiPost,
  apiPut,
  getCurrentUserOrganizationId,
  apiDelete,
} from "../../../utils";

export const clientQueryUserAPI = (data = {}) => {
  return apiGet("/api/clientQuery", { ...data });
};

export const clientQueryOrgAPI = (mdata) => {
  const organizationId = getCurrentUserOrganizationId();
  let data = { ...mdata, organizationId: organizationId };

  return apiGet("/api/clientQuery", { ...data });
};

export const getQueryFormatForUserAPI = () => {
  let data = { userId: getCurrentUserId() };
  return apiGet("/api/clientQuery", data);
};

export const addClientQueryAPI = (data) => {
  return apiPost("/api/clientQuery", data);
};

export const addDownloadPdfAPI = (data) => {
  return apiPost("/api/clientQuery/addDownloadPdf", data);
};

export const addCargoToQueryListAPI = (data, queryId) => {
  return apiPost(`/api/clientQuery/cargoList/addCargo/${queryId}`, data);
};

export const querydeleteAPI = (id) => {
  return apiDelete(`/api/clientQuery/${id}`);
};

export const updateCargoDetailsAPI = (data) => {
  return apiPost(`/api/v1/updateCargoDetails`, data);
};

export const updateQueryAPI = (data) => {
  return apiPut(`​/api​/clientQuery/${data._id}`, data);
};

export const updateClientQueryAPI = (data) => {
  let QueryData = JSON.parse(data.get("QueryData"));
  return apiPut(`/api/clientQuery/${QueryData?._id}`, data);
};

export const updateClientQueryForSingleVesselAPI = (id,data) => {
  return apiPut(`/api/calculatedCost/updateQueryForSinglevessel/${id}`, data);
};

export const allQueryByCharForOrgAPI = (data) => {
  return apiGet(`/api/v1/allQueryByCharForOrg`, data);
};

export const allQueryByCharForUserAPI = (data) => {
  return apiGet(`/api/v1/allQueryByCharForUser`, data);
};

export const addCargoCargoDetailsAPI = (data) => {
  return apiPost(`/api/cargoCargo/addCargoCargoDetails`, data);
};

export const addCargoAnalysisDetailsAPI = (data) => {
  return apiPost(`/api/cargoAnalysis/addCargoDetails`, data);
};