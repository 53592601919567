// module.exports = {
//   redirectUri: "http://localhost:3000/oauth2/redirect",
//   apiBaseUrl: "http://localhost:8080",
// };

// module.exports = {
  // redirectUri: "https://voy-calc-ui-git-13may24-manoj-kumars-projects-0a428b84.vercel.app/oauth2/redirect",
  // apiBaseUrl: "https://voy-calc-be-git-13may24-manoj-kumars-projects-0a428b84.vercel.app",
// };

module.exports = {
  redirectUri: "https://dry.smartseafreight.com/oauth2/redirect",
  apiBaseUrl: "https://dryapi.smartseafreight.com",
};
